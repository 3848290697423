<ppl-card-medium>
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="EntityNameEnum.Appointment"
                [kind]="AvatarKind.Medium"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    {{entity.subject}}
  </ng-template>

  <ng-template #descriptionTemplate>
    {{entity.startDate | pplDateTimeRange:entity.endDate}}
  </ng-template>
</ppl-card-medium>