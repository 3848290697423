import { gql } from '@ppl/utils';

export const AccountCardLargeFields = gql`
  fragment AccountCardLargeFields on AccountEntity {
    created
    email1
    email2
    email3
    email4
    email5
    homePage
    id
    instancePermissions(permissions: [Write])
    modified
    name
    phone1
    picture {
      id
      url
    }
    socialMedia {
      id
      facebookUrl
      linkedinUrl
      twitterUrl
      youtubeUrl
    }
  }
`;

export const AppointmentCardLargeFields = gql`
  fragment AppointmentCardLargeFields on AppointmentEntity {
    created
    endDate
    id
    instancePermissions(permissions: [Write])
    modified
    owner {
      email
      firstName
      id
      middleName
      lastName
    }
    location
    startDate
    status
    subject
  }
`;

export const ClientCardLargeFields = gql`
  fragment ClientCardLargeFields on ClientEntity {
    email
    firstName
    id
    lastName
    middleName
    pictureUrl
  }
`;

export const ContactCardLargeFields = gql`
  fragment ContactCardLargeFields on ContactEntity {
    address
    city
    country
    created
    email1
    email2
    email3
    email4
    email5
    firstName
    id
    instancePermissions(permissions: [Write])
    lastName
    middleName
    modified
    phone1
    picture {
      id
      url
    }
    primaryAccount {
      id
      name
    }
    primaryAccountPosition
    socialMedia {
      id
      facebookUrl
      linkedinUrl
      twitterUrl
      youtubeUrl
    }
    stateProvince
    title
  }
`;

export const LeadCardLargeFields = gql`
  fragment LeadCardLargeFields on LeadEntity {
    created
    daysInStep
    id
    instancePermissions(permissions: [Write])
    modified
    name
    owner {
      email
      firstName
      id
      middleName
      lastName
    }
    status
  }
`;

export const OpportunityCardLargeFields = gql`
  fragment OpportunityCardLargeFields on OpportunityEntity {
    closingDate
    created
    id
    instancePermissions(permissions: [Write])
    lostDate
    modified
    name
    owner {
      email
      firstName
      id
      middleName
      lastName
    }
    status
    step {
      id
      name
      pipeline {
        id
        name
        steps {
          id
          name
          sortOrder
        }
      }
      sortOrder
    }
    value {
      baseValue
      currencyId
      valueForeign
    }
    wonDate
  }
`;

export const TaskCardLargeFields = gql`
  fragment TaskCardLargeFields on TaskEntity {
    activityType {
      id
      isReadonly
      name
    }
    created
    dueDate
    id
    instancePermissions(permissions: [Write])
    modified
    owner {
      email
      firstName
      id
      middleName
      lastName
    }
    priority
    status
    subject
  }
`;

export const AccountCardMediumFields = gql`
  fragment AccountCardMediumFields on AccountEntity {
    address
    country
    city
    id
    isDeleted
    name
    picture {
      id
      url
    }
    stateProvince
  }
`;

export const AppointmentCardMediumFields = gql`
  fragment AppointmentCardMediumFields on AppointmentEntity {
    endDate
    id
    isDeleted
    startDate
    status
    subject
  }
`;

export const ClientCardMediumFields = gql`
  fragment ClientCardMediumFields on ClientEntity {
    email
    id
    isDeleted
    firstName
    middleName
    lastName
    pictureUrl
  }
`;

export const ContactCardMediumFields = gql`
  fragment ContactCardMediumFields on ContactEntity {
    id
    isDeleted
    firstName
    middleName
    lastName
    picture {
      id
      url
    }
    title
  }
`;

export const LeadCardMediumFields = gql`
  fragment LeadCardMediumFields on LeadEntity {
    id
    isDeleted
    name
    owner {
      id
      name
    }
    status
  }
`;

export const OpportunityCardMediumFields = gql`
  fragment OpportunityCardMediumFields on OpportunityEntity {
    id
    isDeleted
    name
    owner {
      id
      name
    }
    status
    value {
      baseValue
      currencyId
      valueForeign
    }
  }
`;

export const TaskCardMediumFields = gql`
  fragment TaskCardMediumFields on TaskEntity {
    dueDate
    id
    isDeleted
    status
    subject
  }
`;
