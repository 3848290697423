import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import type { AbstractControl, UntypedFormArray } from '@angular/forms';
import { PplDialogRef, PPL_DIALOG_DATA } from '@ppl/ui/dialog';
import type { TFormGroup } from '@ppl/ui/form';
import { TFormBuilder } from '@ppl/ui/form';
import type { EmailAttachment } from '../../domain/email';

@Component({
  selector: 'ppl-select-attachments',
  templateUrl: './select-attachments.component.html',
  styleUrls: ['./select-attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectAttachmentsComponent {

  formGroup: TFormGroup<SelectAttachmentsFormValue>;

  constructor(
    private dialogRef: PplDialogRef<SelectAttachmentsComponent, EmailAttachment[]>,
    @Inject(PPL_DIALOG_DATA) public data: SelectAttachmentsData,
    private formBuilder: TFormBuilder
  ) {
    this.formGroup = this.formBuilder.group<SelectAttachmentsFormValue>({
      attachments: this.formBuilder.array(this.data.attachments.map(attachment => {
        return this.formBuilder.group<SelectAttachmentsFormValueAttachment>({
          enabled: [true],
          fileName: [attachment.fileName],
          id: [attachment.id],
          originalFileName: [attachment.fileName]
        });
      }), (control: AbstractControl) => {
        if (control.value.every((value: SelectAttachmentsFormValueAttachment) => !value.enabled)) {
          return { atLeastOneOption: true };
        }

        return null;
      })
    });
  }

  get formGroupAttachmentsControls() {
    return (this.formGroup.get('attachments') as UntypedFormArray).controls;
  }

  onSave() {
    const formValue = this.formGroup.getRawValue();

    this.dialogRef.close(formValue.attachments.filter(attachment => {
      return attachment.enabled;
    }).map(attachment => ({
      content: null,
      id: attachment.id,
      fileName: attachment.fileName || attachment.originalFileName
    })));
  }

}

interface SelectAttachmentsFormValue {
  attachments: SelectAttachmentsFormValueAttachment[];
}

interface SelectAttachmentsFormValueAttachment {
  enabled: boolean;
  fileName: string;
  id: string;
  originalFileName: string;
}

export interface SelectAttachmentsData {
  attachments: EmailAttachment[];
}
