import { Injectable } from '@angular/core';

@Injectable()
export class CacheService {

  dataRevision = 0;

  reset() {
    this.dataRevision++;
  }

}
