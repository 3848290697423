import { EntityNameEnum } from '@ppl/graphql-space-api';
import { assertNever, normalizeToObject } from '@ppl/utils';
import type { PplNamePipe } from '@ppl/auth';
import type {
  AccountEntity,
  AppointmentEntity,
  ClientEntity,
  ContactEntity,
  EntityCustomNameSetting,
  LeadEntity,
  OpportunityEntity,
  ProjectEntity,
  QuoteEntity,
  TaskEntity
} from '@ppl/graphql-space-api';

export function getEntityName(
  entity: AccountEntity
    | AppointmentEntity
    | ClientEntity
    | ContactEntity
    | LeadEntity
    | OpportunityEntity
    | TaskEntity
    | ProjectEntity
    | QuoteEntity,
  namePipe: PplNamePipe) {
  switch (entity.__typename) {
    case 'AccountEntity':
    case 'LeadEntity':
    case 'OpportunityEntity':
    case 'ProjectEntity':
    case 'QuoteEntity':
      return entity.name;
    case 'ClientEntity':
    case 'ContactEntity':
      return namePipe.transform(entity);
    case 'AppointmentEntity':
    case 'TaskEntity':
      return entity.subject;
    default:
      assertNever(entity);
  }
}

export const ACCOUNT_TYPE_ID = 'a456236f-6f23-0908-abfd-384aa0f62f9e';

export const CONTACT_TYPE_ID = '44f0f3a0-e998-03d8-88ac-69f61e213b02';

export const LEAD_TYPE_ID = '051d0778-8e59-05f1-aa33-10e5f793d893';

export const QUOTE_TYPE_ID = 'aa6a8ede-0315-462d-a792-5adfc8030278';

export const OPPORTUNITY_TYPE_ID = '0c03f112-4544-06a8-b162-1f14524c149c';

export const CALL_TYPE_ID = '5d451c07-a9a8-0df9-4812-d50057a0d491';
export const EMAIL_TYPE_ID = '3b6a66e9-568d-0ace-abcb-174b85b8fb4c';
export const TASK_TYPE_ID = '45e3f3cf-4a46-0159-1fd1-9314c4dcd2dc';

export const APPOINTMENT_TYPE_ID = '8baef00e-a17a-0905-f212-06a1280de149';
export const RECURRENT_APPOINTMENT_TYPE_ID = '714722b2-9111-0af3-2a4a-100a76c69285';

export enum EntityCustomNameMarker {
  Account_s = 'Account_s',
  Account_p = 'Account_p',
  Contact_s = 'Contact_s',
  Contact_p = 'Contact_p',
  Lead_s = 'Lead_s',
  Lead_p = 'Lead_p',
  Opportunity_s = 'Opportunity_s',
  Opportunity_p = 'Opportunity_p',
  Quote_s = 'Quote_s',
  Quote_p = 'Quote_p'
}

interface EntityCustomName {
  singular: string;
  plural: string;
}

export type EntityCustomNames = { [K in EntityNameEnum]?: EntityCustomName };

export function entityNamesToMap(
  entityNames: EntityCustomNameSetting[]
): EntityCustomNames {
  return entityNames.reduce<EntityCustomNames>((prev, next) => ({
    ...prev,
    [next.entityName]: {
      singular: next.singular.current,
      plural: next.plural.current,
    }
  }), {} as any);
}

export function entityNamesToMarkers(
  entityNames: EntityCustomNameSetting[]
): { [key in EntityCustomNameMarker]: string } {
  const entityNamesMap = normalizeToObject(entityNames, 'entityName');

  const markers = {
    [EntityCustomNameMarker.Account_s]: entityNamesMap[EntityNameEnum.Account].singular.current,
    [EntityCustomNameMarker.Account_p]: entityNamesMap[EntityNameEnum.Account].plural.current,
    [EntityCustomNameMarker.Contact_s]: entityNamesMap[EntityNameEnum.Contact].singular.current,
    [EntityCustomNameMarker.Contact_p]: entityNamesMap[EntityNameEnum.Contact].plural.current,
    [EntityCustomNameMarker.Lead_s]: entityNamesMap[EntityNameEnum.Lead].singular.current,
    [EntityCustomNameMarker.Lead_p]: entityNamesMap[EntityNameEnum.Lead].plural.current,
    [EntityCustomNameMarker.Opportunity_s]: entityNamesMap[EntityNameEnum.Opportunity].singular.current,
    [EntityCustomNameMarker.Opportunity_p]: entityNamesMap[EntityNameEnum.Opportunity].plural.current,
    [EntityCustomNameMarker.Quote_s]: entityNamesMap[EntityNameEnum.Quote].singular.current,
    [EntityCustomNameMarker.Quote_p]: entityNamesMap[EntityNameEnum.Quote].plural.current,
  };

  return markers;
}
