<ppl-popover-dropdown>
  <ppl-menu [hasIcons]="hasIcons"
            [hasChildren]="hasChildren"
            [markSelected]="value !== undefined"
            [value]="value"
            (valueChanged)="onLeafItemClick($event)">
    <ng-container *ngFor="let item of items; trackBy: trackByItem">
      <ppl-menu-item *ngIf="item && (!item.disabled || item.disableOnly)"
                     #popoverDirective=pplPopoverDirective
                     [value]="item.id"
                     [disabled]="item.disabled"
                     [hover]="openItemId === item.id"
                     [hasChildren]="!!item.children"
                     [pplPopover]="item.children ? (item.children.items ? (item.children.items.length ? childItemsPopover : childItemsEmptyPopover) : childComponentPopover) : null"
                     [pplPopoverEvent]="null"
                     pplPopoverAlignStart
                     pplPopoverDirection="right"
                     [pplPopoverData]="item.children ? (item.children.items ? (item.children.items.length ? item.children.items : item.children.emptyLabel) : { component: item.children.items, injector: getInjector(item) }) : undefined"
                     [pplPopoverExactCalculations]="true"
                     [attr.title]="item.title || null"
                     [attr.ppl-menu-items-prevent-close]="item.children ? 'true' : 'false'"
                     [attr.data-test]="'MenuItems_Item_' + item.id"
                     (mouseenter)="onItemMouseEnter(item, popoverDirective)">
        <ppl-icon *ngIf="item.icon"
                  [svgIcon]="item.icon"
                  width="18px"
                  height="18px"></ppl-icon>
        <ng-container *ngIf="!item.template">
          {{item.label}}
        </ng-container>

        <ng-container *ngIf="item.template">
          <ng-container *ngTemplateOutlet="item.template; context: { $implicit: item, data: item.data }"></ng-container>
        </ng-container>
      </ppl-menu-item>

      <div *ngIf="!item"
           class="separator"
           [attr.ppl-menu-items-prevent-close]="'true'"></div>
    </ng-container>
  </ppl-menu>
</ppl-popover-dropdown>

<ng-template #childItemsPopover
             let-data="data"
             let-popover="popover">
  <ppl-menu-items [items]="data"
                  [parentPopover]="popover"
                  [openPopovers]="openPopovers"
                  (itemClick)="onItemClick($event)"></ppl-menu-items>
</ng-template>

<ng-template #childItemsEmptyPopover
             let-data="data">
  <ppl-popover-dropdown>
    <div class="empty-label">
      <ng-container *ngIf="data">{{data}}</ng-container>
      <ng-container *ngIf="!data"
                    i18n="@@No_items">No items</ng-container>
    </div>
  </ppl-popover-dropdown>
</ng-template>

<ng-template #childComponentPopover
             let-data="data">
  <ppl-popover-dropdown [attr.ppl-menu-items-prevent-close]="'true'">
    <ng-container *ngComponentOutlet="data.component; injector: data.injector"></ng-container>
  </ppl-popover-dropdown>
</ng-template>