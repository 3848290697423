import { LocationService } from '../../../../services/location.service';
import { EntityService } from '../../../shared/entity/services/entity.service';
import { AttachDocumentService } from '../../services/attach-document.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { AsyncState } from '@ppl/store';
import { TFormBuilder } from '@ppl/ui/form';
import {
  isFormValid,
  MemoizeLast,
  PplValidators,
  unsubscribe
} from '@ppl/utils';
import type { CloudObjectEntity } from '@ppl/graphql-space-api';
import type { AsyncValue } from '@ppl/store';
import type { TFormGroup } from '@ppl/ui/form';
import type { Subscription } from 'rxjs';
import type { EntityListTypeItem } from '../../../shared/entity/components/entity-list-type/entity-list-type.component';

@Component({
  selector: 'ppl-attach-document',
  templateUrl: './attach-document.component.html',
  styleUrls: ['./attach-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachDocumentComponent {

  formGroup: TFormGroup<AttachDocumentFormValue>;

  availableEntityTypes = this.entityService.filterEntityTypes([
    EntityNameEnum.Account,
    EntityNameEnum.Contact,
    EntityNameEnum.Lead,
    EntityNameEnum.Opportunity
  ]);

  entityItem: EntityListTypeItem | null = null;

  cloudObjects: AsyncValue<CloudObjectEntity[]> = { state: AsyncState.IDLE };
  cloudObjectsSubscription: Subscription;

  @MemoizeLast<AttachDocumentComponent>(['cloudObjects', 'entityItem'])
  get filesMessage() {
    switch (this.cloudObjects.state) {
      case AsyncState.FETCHING:
        return this.i18nService.translate('Loading_ellipsis');
      case AsyncState.FETCHED:
        if (!this.cloudObjects.value.length) {
          return this.i18nService.translate(({
            [EntityNameEnum.Account]: 'Selected_account_has_no_related_documents',
            [EntityNameEnum.Contact]: 'Selected_contact_has_no_related_documents',
            [EntityNameEnum.Lead]: 'Selected_lead_has_no_related_documents',
            [EntityNameEnum.Opportunity]: 'Selected_opportunity_has_no_related_documents'
          })[this.entityItem.entityType]);
        }
        break;
      case AsyncState.ERROR:
        return this.i18nService.translate('Unable_to_load_documents');
    }

    return null;
  }

  constructor(
    private attachDocumentService: AttachDocumentService,
    private changeDetectorRef: ChangeDetectorRef,
    private entityService: EntityService,
    private formBuilder: TFormBuilder,
    private i18nService: I18nService,
    private locationService: LocationService
  ) {
    this.formGroup = this.formBuilder.group<AttachDocumentFormValue>({
      fileIds: [[], PplValidators.nonEmptyOptionArray]
    });
  }

  onEntityItemChange(entityItem: EntityListTypeItem) {
    this.entityItem = entityItem;

    this.formGroup.get('fileIds').setValue([]);
    this.formGroup.get('fileIds').markAsUntouched();
    this.formGroup.get('fileIds').markAsPristine();

    this.cloudObjects = {
      state: AsyncState.FETCHING
    };

    unsubscribe(this.cloudObjectsSubscription);

    this.cloudObjectsSubscription = this.attachDocumentService.fetchCloudObjects({
      entityType: entityItem.entityType,
      entityId: entityItem.entityId
    }).subscribe(cloudObjects => {
      this.cloudObjects = {
        state: AsyncState.FETCHED,
        value: cloudObjects
      };

      this.changeDetectorRef.detectChanges();
    }, () => {
      this.cloudObjects = {
        state: AsyncState.ERROR
      };
    });
  }

  onCancelClick() {
    this.locationService.goBack();
  }

  onAttachClick() {
    if (!isFormValid(this.formGroup)) {
      return;
    }

    const fileIds = this.formGroup.get('fileIds').value;
    const cloudObjects = this.cloudObjects.value.filter(cloudObject => fileIds.includes(cloudObject.id));

    this.attachDocumentService.attachFiles(cloudObjects).subscribe(() => {
      this.locationService.goBack();
    });
  }

}

interface AttachDocumentFormValue {
  fileIds: string[];
}
