<ppl-card-fields>
  <ppl-card-field label="Closing Date"
                  i18n-label="@@Closing_Date">
    {{entity.closingDate | pplDate}}
  </ppl-card-field>

  <ng-container *ngIf="entity.status === OpptyStatusEnum.Open">
    <ppl-card-field *ngIf="!isOverdue"
                    label="Remaining"
                    i18n-label="@@Remaining">
      {daysOverdueAbs, plural, =1 {1 day} other {{{daysOverdueAbs}} days}}
    </ppl-card-field>

    <ppl-card-field *ngIf="isOverdue"
                    label="Overdue"
                    i18n-label="@@Overdue">
      <span class="overdue">{daysOverdueAbs, plural, =1 {1 day} other {{{daysOverdueAbs}} days}}</span>
    </ppl-card-field>
  </ng-container>

  <ppl-card-field *ngIf="entity.status === OpptyStatusEnum.Won"
                  label="Won Date"
                  i18n-label="@@Won_Date">
    {{entity.wonDate | pplDate}}
  </ppl-card-field>

  <ppl-card-field *ngIf="entity.status === OpptyStatusEnum.Lost"
                  label="Lost Date"
                  i18n-label="@@Lost_Date">
    {{entity.lostDate | pplDate}}
  </ppl-card-field>

  <ppl-card-field label="Business Value"
                  i18n-label="@@Business_Value">
    {{entity.value.valueForeign | pplCurrency:valueCurrencyCode}}
  </ppl-card-field>
</ppl-card-fields>