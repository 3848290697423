import { Injectable } from '@angular/core';
import type { CanDeactivate } from '@angular/router';
import { I18nService } from '@ppl/i18n';
import { PplDialogService } from '@ppl/ui/dialog';
import type { Observable } from 'rxjs';

export interface CanLeaveGuardComponent {
  valueChanged: boolean;
}

@Injectable()
export class CanLeaveEntityFormGuard implements CanDeactivate<CanLeaveGuardComponent> {

  constructor(
    private dialogService: PplDialogService,
    private i18nService: I18nService
  ) {}

  canDeactivate(component: CanLeaveGuardComponent): Observable<boolean> | boolean {
    if (component.valueChanged) {
      return this.dialogService.confirm({
        text: this.i18nService.translate('Do_you_want_to_discard_changes?'),
        onlyPositive: false
      });
    }

    return true;
  }

}
