import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AccountEntity, EntityNameEnum } from '@ppl/graphql-space-api';
import { AvatarKind } from '../../../avatar/domain/avatar';

@Component({
  selector: 'ppl-account-card-small',
  templateUrl: './account-card-small.component.html',
  styleUrls: ['./account-card-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountCardSmallComponent {

  @Input() entity: AccountEntity;
  @Input() active?: boolean;

  AvatarKind = AvatarKind;
  EntityNameEnum = EntityNameEnum;

}
