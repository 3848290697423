<ppl-page [flexContent]="true">
  <ng-template #headerTemplate>
    <ppl-page-form-header *ngIf="!id"
                          icon="entity-white-new-appointment"
                          label="Create Appointment"
                          i18n-label="@@Create_Appointment"
                          (closeClick)="onCancelClick()"></ppl-page-form-header>

    <ppl-page-form-header *ngIf="id"
                          icon="entity-white-new-appointment"
                          label="Edit Appointment"
                          i18n-label="@@Edit_Appointment"
                          (closeClick)="onCancelClick()"></ppl-page-form-header>
  </ng-template>

  <ng-container *ngIf="data$ | async; let data">
    <ng-container *ngIf="data.state === AsyncState.FETCHED">
      <ppl-error-toast *ngIf="!valid"></ppl-error-toast>

      <ppl-entity-form [controls]="controls"
                       [value]="value"
                       (valueChange)="onValueChange(data.value, $event)">
        <ng-template #controlsLayout
                     let-controls
                     let-controlRenderer="controlRenderer">
          <ppl-entity-form-layout [controls]="controls"
                                  [controlRenderer]="controlRenderer"
                                  [customFieldsVisible]="customFieldsVisible"
                                  (customFieldsVisibleChange)="customFieldsVisible = !customFieldsVisible"></ppl-entity-form-layout>
        </ng-template>
      </ppl-entity-form>

      <ppl-page-actions (cancelClick)="onCancelClick()"
                        (saveClick)="onSaveClick(data.value)"></ppl-page-actions>
    </ng-container>

    <ppl-page-loading *ngIf="data.state === AsyncState.FETCHING"></ppl-page-loading>

    <ppl-entity-error *ngIf="data.state === AsyncState.ERROR"
                      [error]="data.error"></ppl-entity-error>
  </ng-container>
</ppl-page>