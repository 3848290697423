<div class="header">
  <div class="selection"
       (click)="onHeaderClick()">
    <ppl-checkbox [value]="items.length !== 0 && selection.length === items.length"></ppl-checkbox>
  </div>

  <div *ngFor="let column of columns; trackBy: trackById"
       [style.width.px]="column.width"
       class="value">
    {{column.name}}
  </div>
</div>

<div class="items">
  <div *ngFor="let item of items; trackBy: trackById"
       class="item"
       (click)="onItemClick(item)">
    <ppl-checkbox [value]="selection.includes(item.id)"></ppl-checkbox>

    <div *ngFor="let column of columns; trackBy: trackById"
         [style.width.px]="column.width"
         class="value"
         [title]="item[column.id]">
      <div class="value-text">
        {{item[column.id]}}
      </div>

      <div *ngIf="column.inlineEdit"
           class="value-edit"
           (click)="onInlineEditClick(item, column, $event)">
        <ppl-icon svgIcon="inline-edit"
                  width="16px"
                  height="16px"></ppl-icon>
      </div>
    </div>
  </div>

  <div *ngIf="message"
       class="message">
    {{message}}
  </div>
</div>