import { gql } from '@ppl/utils';

export const gqlFetchTaskFormData = gql`
  query fetchTaskFormData($id: ID!) {
    entities {
      task {
        getById(entityId: $id) {
          accountRelations {
            edges {
              node {
                id
                accountId
              }
            }
          }
          activityTypeId
          contactRelations {
            edges {
              node {
                id
                contactId
              }
            }
          }
          customFields
          description
          dueDate
          id
          leadRelations {
            edges {
              node {
                id
                leadOpptyId
              }
            }
          }
          opportunityRelations {
            edges {
              node {
                id
                leadOpptyId
              }
            }
          }
          ownerId
          priority
          status
          subject
          unitId
        }
      }
    }
  }
`;

export const gqlCreateTaskMutation = gql`
  mutation createTask($input: CreateTaskInput!, $validationLevel: [ValidationLevel!]) {
    createTask(input: $input, validationLevel: $validationLevel) {
      task {
        id
      }
    }
  }
`;

export const gqlUpdateTaskMutation = gql`
  mutation updateTask($input: UpdateTaskInput!, $validationLevel: [ValidationLevel!]) {
    updateTask(input: $input, validationLevel: $validationLevel) {
      clientMutationId
    }
  }
`;
