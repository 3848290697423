/** DO NOT EDIT - THIS FILE IS AUTOGENERATED BY graphql-to-typescript-converter */
import { gql } from '@ppl/utils';

export type JSONString = string;

export type Base64 = string;

export type UUID = string;

export type DateTime = string;

export type GqlDate = string;

export type ID = string;

export interface TeamPipelineHubInterface {
  __typename?: string;
  id: ID;
  instancePermissions: LicEntityPermsEnum[];
  isEnabled: boolean;
  status: TeamPipelineHubItemStatusEnum;
  teamSpace: TeamSpaceEntity | null;
  teamSpaceId: string;
}

export type TeamPipelineHubInterfaceImplementors = TeamPipelineHubItemEntity;

export enum ResetSpaceEnum {
  DataAndConfiguration = 'DataAndConfiguration',
  DataOnly = 'DataOnly'
}

export enum ErrorReportPriority {
  High = 'High',
  Low = 'Low',
  Normal = 'Normal',
  Urgent = 'Urgent'
}

export enum TemplateDbType {
  Empty = 'Empty',
  Trial = 'Trial'
}

export enum ValidationLevel {
  FULL = 'FULL',
  SKIP_ALL = 'SKIP_ALL',
  SKIP_FIELD_VALUE_VALIDATION = 'SKIP_FIELD_VALUE_VALIDATION',
  SKIP_INVALID_RECALCULATIONS = 'SKIP_INVALID_RECALCULATIONS',
  SKIP_READONLY_VALIDATION = 'SKIP_READONLY_VALIDATION',
  SKIP_RECALCULATION = 'SKIP_RECALCULATION',
  SKIP_RELATIONSHIP_VALIDATION = 'SKIP_RELATIONSHIP_VALIDATION',
  SKIP_STEP_CHECKLIST_VALIDATION = 'SKIP_STEP_CHECKLIST_VALIDATION',
  SKIP_UNCHANGED_FIELDS = 'SKIP_UNCHANGED_FIELDS',
  SKIP_USER_DEFINED_VALIDATIONS = 'SKIP_USER_DEFINED_VALIDATIONS'
}

export enum SyncDirectionEnum {
  BiDirectional = 'BiDirectional',
  ExternalToPipeliner = 'ExternalToPipeliner',
  PipelinerToExternal = 'PipelinerToExternal'
}

export enum MatchingOperatorEnum {
  eq = 'eq',
  ieq = 'ieq'
}

export enum SpaceAccessStatus {
  Allowed = 'Allowed',
  IdpLoginRequired = 'IdpLoginRequired',
  TwoFARequired = 'TwoFARequired'
}

export enum NameFormat {
  FirstMiddleLast = 'FirstMiddleLast',
  LastFirstMiddle = 'LastFirstMiddle'
}

export enum DayName {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export enum HowToType {
  Article = 'Article',
  Ebook = 'Ebook',
  Intercom = 'Intercom',
  SupportForm = 'SupportForm',
  Video = 'Video'
}

export enum HowToCategory {
  AccountsHierarchy = 'AccountsHierarchy',
  AccountsRelations = 'AccountsRelations',
  Applications = 'Applications',
  AutomationHub = 'AutomationHub',
  BaseCurrency = 'BaseCurrency',
  ContactsRelations = 'ContactsRelations',
  Currencies = 'Currencies',
  EmailSequenceEditor = 'EmailSequenceEditor',
  ExchangeRates = 'ExchangeRates',
  FieldsAndForms = 'FieldsAndForms',
  GlobalSettings = 'GlobalSettings',
  Pipelines = 'Pipelines',
  PriceLists = 'PriceLists',
  ProcessEditor = 'ProcessEditor',
  ProductCategories = 'ProductCategories',
  Products = 'Products',
  Roles = 'Roles',
  SalesRoles = 'SalesRoles',
  Units = 'Units',
  Users = 'Users'
}

export enum VatCheckResultEnum {
  ResultInvalid = 'ResultInvalid',
  ResultUnavailable = 'ResultUnavailable',
  ResultValid = 'ResultValid'
}

export enum SubscriptionProductTier {
  Business = 'Business',
  BusinessCloud = 'BusinessCloud',
  Enterprise = 'Enterprise',
  EnterpriseCloud = 'EnterpriseCloud',
  Freemium = 'Freemium',
  MobileStandalone = 'MobileStandalone',
  MobileStandaloneCloud = 'MobileStandaloneCloud',
  OldContinuityBusiness = 'OldContinuityBusiness',
  OldContinuityStarter = 'OldContinuityStarter',
  OldLegacyProductArithmeticaWithMobile = 'OldLegacyProductArithmeticaWithMobile',
  OldLegacyProductArithmeticaWithoutMobile = 'OldLegacyProductArithmeticaWithoutMobile',
  OldProduct = 'OldProduct',
  PersonalPlus = 'PersonalPlus',
  Starter = 'Starter',
  StarterCloud = 'StarterCloud',
  UnlimitedCloud = 'UnlimitedCloud'
}

export enum SubscriptionStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Disabled = 'Disabled'
}

export enum TwoFAType {
  AUTH_APP = 'AUTH_APP',
  BACKUP_CODES = 'BACKUP_CODES',
  DUMMY = 'DUMMY',
  EMAIL = 'EMAIL',
  SMS = 'SMS'
}

export enum NotificationSettingType {
  Action = 'Action',
  ActionWithNotice = 'ActionWithNotice',
  Notice = 'Notice'
}

export enum NotificationSettingStatus {
  Disabled = 'Disabled',
  Enabled = 'Enabled'
}

export enum NotificationSettingService {
  OrderDunning = 'OrderDunning',
  SubscriptionRenewal = 'SubscriptionRenewal'
}

export enum NotificationSettingPermsEnum {
  Read = 'Read',
  Write = 'Write'
}

export enum NotificationRenewalPermsEnum {
  ChangeProcessed = 'ChangeProcessed'
}

export enum PartnerTargetCustomers {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
  Soho = 'Soho'
}

export enum UserRolePermsEnum {
  Create = 'Create',
  Delete = 'Delete',
  Read = 'Read',
  Write = 'Write'
}

export enum UserLanguage {
  de = 'de',
  en = 'en'
}

export enum UserProfilePermsEnum {
  ApiAdmin = 'ApiAdmin',
  Create = 'Create',
  Delete = 'Delete',
  Disable = 'Disable',
  EditPicture = 'EditPicture',
  Enable = 'Enable',
  Read = 'Read',
  Restore = 'Restore',
  SetSuperAdmin = 'SetSuperAdmin',
  Write = 'Write'
}

export enum BasicLicEntityPermsEnum {
  ApiAdmin = 'ApiAdmin',
  Create = 'Create',
  Delete = 'Delete',
  Read = 'Read',
  Restore = 'Restore',
  Write = 'Write'
}

export enum PartnerCompanySize {
  Size1001to5000 = 'Size1001to5000',
  Size11to50 = 'Size11to50',
  Size1to10 = 'Size1to10',
  Size201to500 = 'Size201to500',
  Size5001to10000 = 'Size5001to10000',
  Size501to1000 = 'Size501to1000',
  Size51to200 = 'Size51to200',
  SizeAbove10000 = 'SizeAbove10000'
}

export enum PartnerCompanyFocus {
  Accounting = 'Accounting',
  Crm = 'Crm',
  HelpDesk = 'HelpDesk',
  HostedApps = 'HostedApps',
  Marketing = 'Marketing',
  Process = 'Process',
  Training = 'Training'
}

export enum PartnerActiveCustomers {
  Size101to200 = 'Size101to200',
  Size11to50 = 'Size11to50',
  Size1to10 = 'Size1to10',
  Size201to1000 = 'Size201to1000',
  Size51to100 = 'Size51to100',
  SizeAbove1000 = 'SizeAbove1000'
}

export enum OrderTypeOfSaleEnum {
  New = 'New',
  OneTimeFee = 'OneTimeFee',
  Other = 'Other',
  RecurringUpsell = 'RecurringUpsell',
  Renewal = 'Renewal',
  TierUpdate = 'TierUpdate'
}

export enum OrderStatusEnum {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Open = 'Open',
  OpenOverdue = 'OpenOverdue'
}

export enum OrderRelatedDocumentTypeEnum {
  Credit = 'Credit',
  Payment = 'Payment'
}

export enum OrderRelatedDocumentPermsEnum {
  Delete = 'Delete',
  Read = 'Read'
}

export enum OrderHistoryStatusEnum {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  New = 'New',
  PayOrderRepeat = 'PayOrderRepeat',
  PaymentPending = 'PaymentPending',
  PaymentReview = 'PaymentReview',
  Unknown = 'Unknown'
}

export enum OrderPermsEnum {
  Cancel = 'Cancel',
  ConfirmPayment = 'ConfirmPayment',
  FixMrr = 'FixMrr',
  Pay = 'Pay',
  Read = 'Read',
  ViewHistory = 'ViewHistory'
}

export enum OrderActionEnum {
  AdditionalServices = 'AdditionalServices',
  Downgrade = 'Downgrade',
  DowngradeAdmin = 'DowngradeAdmin',
  Purchase = 'Purchase',
  PurchaseAdmin = 'PurchaseAdmin',
  PurchaseLoggedUser = 'PurchaseLoggedUser',
  Renew = 'Renew',
  TierUpdate = 'TierUpdate',
  Upgrade = 'Upgrade',
  UpgradeAdmin = 'UpgradeAdmin',
  UpgradeCommercial = 'UpgradeCommercial',
  UpgradeCommercialAdmin = 'UpgradeCommercialAdmin'
}

export enum NumberOfSalesPeople {
  Group2to4 = 'Group2to4',
  Individual = 'Individual',
  Team11to25 = 'Team11to25',
  Team26to50 = 'Team26to50',
  Team5to10 = 'Team5to10',
  TeamOver50 = 'TeamOver50'
}

export enum SubscriptionPermsEnum {
  Cancel = 'Cancel',
  ChangeProcessedNotifications = 'ChangeProcessedNotifications',
  Create = 'Create',
  CreateSpace = 'CreateSpace',
  CreateSpaceRequest = 'CreateSpaceRequest',
  Delete = 'Delete',
  EditPicture = 'EditPicture',
  EditSubscriptionAdmins = 'EditSubscriptionAdmins',
  Enable = 'Enable',
  PurchaseAdditionalLicenses = 'PurchaseAdditionalLicenses',
  PurchaseCallingCredit = 'PurchaseCallingCredit',
  PurchaseExtras = 'PurchaseExtras',
  Read = 'Read',
  RenewalEstimate = 'RenewalEstimate',
  Restore = 'Restore',
  UpgradeToCommercial = 'UpgradeToCommercial',
  Write = 'Write',
  WriteBasic = 'WriteBasic'
}

export enum IdentityProviderType {
  GoogleAccount = 'GoogleAccount',
  MSADFS = 'MSADFS',
  MicrosoftAccount = 'MicrosoftAccount'
}

export enum SubscriptionCompanyFocus {
  IT = 'IT',
  NotSet = 'NotSet',
  Other = 'Other',
  SalesManagement = 'SalesManagement',
  SalesOperations = 'SalesOperations',
  Selling = 'Selling'
}

export enum SubscriptionClassification {
  Freemium = 'Freemium',
  Internal = 'Internal',
  Partner = 'Partner',
  Standard = 'Standard',
  Testing = 'Testing',
  Trial = 'Trial'
}

export enum CRUDLicEntityPermsEnum {
  Create = 'Create',
  Delete = 'Delete',
  Read = 'Read',
  Write = 'Write'
}

export enum TeamSpacePermsEnum {
  AdminAccess = 'AdminAccess',
  ApiAdmin = 'ApiAdmin',
  Create = 'Create',
  Delete = 'Delete',
  EditPicture = 'EditPicture',
  Reactivate = 'Reactivate',
  Read = 'Read',
  ResetSpace = 'ResetSpace',
  Restore = 'Restore',
  Suspend = 'Suspend',
  UpdateName = 'UpdateName',
  Write = 'Write'
}

export enum TeamSpaceStatusReason {
  STATUS_REASON_DISABLED_BY_ADMIN_ACTION = 'STATUS_REASON_DISABLED_BY_ADMIN_ACTION',
  STATUS_REASON_DISABLED_BY_AUTOMATIC_CLEANUP = 'STATUS_REASON_DISABLED_BY_AUTOMATIC_CLEANUP',
  STATUS_REASON_DISABLED_BY_DUNNING = 'STATUS_REASON_DISABLED_BY_DUNNING',
  STATUS_REASON_DISABLED_BY_RESET = 'STATUS_REASON_DISABLED_BY_RESET',
  STATUS_REASON_DISABLED_BY_TAKEOVER = 'STATUS_REASON_DISABLED_BY_TAKEOVER',
  STATUS_REASON_PENDING_UPGRADE = 'STATUS_REASON_PENDING_UPGRADE',
  STATUS_REASON_READY = 'STATUS_REASON_READY',
  STATUS_REASON_SCHEDULED_MAINTENANCE = 'STATUS_REASON_SCHEDULED_MAINTENANCE',
  STATUS_REASON_UPGRADE_FAILED = 'STATUS_REASON_UPGRADE_FAILED',
  STATUS_REASON_UPGRADING = 'STATUS_REASON_UPGRADING'
}

export enum TeamSpaceFlags {
  DoNotAllowAutoupgrade = 'DoNotAllowAutoupgrade',
  HighSecurity = 'HighSecurity',
  NewEnvironment = 'NewEnvironment'
}

export enum ReadOnlyPermsEnum {
  Read = 'Read'
}

export enum TeamSpaceStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  MAINTENANCE = 'MAINTENANCE'
}

export enum TeamPipelineHubItemStatusEnum {
  Active = 'Active',
  Error = 'Error',
  PendingConfiguration = 'PendingConfiguration',
  RequestedAdd = 'RequestedAdd',
  RequestedRemove = 'RequestedRemove'
}

export enum AutomationHubTierEnum {
  Custom = 'Custom',
  Free = 'Free',
  Paid = 'Paid'
}

export enum AutomationHubScope {
  Space = 'Space',
  User = 'User'
}

export enum OrderByEnum {
  Asc = 'Asc',
  Desc = 'Desc'
}

export enum EntityNameEnum {
  Account = 'Account',
  AccountDataExRelation = 'AccountDataExRelation',
  AccountKPI = 'AccountKPI',
  AccountRole = 'AccountRole',
  AccountSharingClientRelation = 'AccountSharingClientRelation',
  AccountSharingSalesUnitRelation = 'AccountSharingSalesUnitRelation',
  AccountSocialRelation = 'AccountSocialRelation',
  AccountType = 'AccountType',
  Activity = 'Activity',
  ActivityComment = 'ActivityComment',
  ActivityDataExRelation = 'ActivityDataExRelation',
  ActivityDetail = 'ActivityDetail',
  ActivityKPI = 'ActivityKPI',
  ActivityRelation = 'ActivityRelation',
  ActivityType = 'ActivityType',
  AllowedAccountType = 'AllowedAccountType',
  AllowedCompanyPhone = 'AllowedCompanyPhone',
  AllowedContactType = 'AllowedContactType',
  AllowedLeadType = 'AllowedLeadType',
  AllowedPipeline = 'AllowedPipeline',
  ApiAccess = 'ApiAccess',
  Application = 'Application',
  Appointment = 'Appointment',
  AppointmentClientInviteesRelation = 'AppointmentClientInviteesRelation',
  AppointmentContactInviteesRelation = 'AppointmentContactInviteesRelation',
  AppointmentInviteeNotifyQueue = 'AppointmentInviteeNotifyQueue',
  AppointmentReminder = 'AppointmentReminder',
  AppointmentSchedule = 'AppointmentSchedule',
  AppointmentType = 'AppointmentType',
  AutomationHubApp = 'AutomationHubApp',
  AutomationHubCategory = 'AutomationHubCategory',
  AutomationHubItem = 'AutomationHubItem',
  Client = 'Client',
  ClientExcludedOppty = 'ClientExcludedOppty',
  ClientFavorite = 'ClientFavorite',
  ClientSetting = 'ClientSetting',
  ClientStoryRead = 'ClientStoryRead',
  CloudObject = 'CloudObject',
  CloudObjectFolder = 'CloudObjectFolder',
  CloudObjectRelation = 'CloudObjectRelation',
  CloudObjectStepRelation = 'CloudObjectStepRelation',
  CloudObjectTemplate = 'CloudObjectTemplate',
  CompanyEmail = 'CompanyEmail',
  Contact = 'Contact',
  ContactAccountAccountRoleRelation = 'ContactAccountAccountRoleRelation',
  ContactAccountRelation = 'ContactAccountRelation',
  ContactDataExRelation = 'ContactDataExRelation',
  ContactKPI = 'ContactKPI',
  ContactRelation = 'ContactRelation',
  ContactRelationType = 'ContactRelationType',
  ContactSharingClientRelation = 'ContactSharingClientRelation',
  ContactSharingSalesUnitRelation = 'ContactSharingSalesUnitRelation',
  ContactSocialRelation = 'ContactSocialRelation',
  ContactType = 'ContactType',
  Country = 'Country',
  CpActivity = 'CpActivity',
  CpCallLog = 'CpCallLog',
  CpCalling = 'CpCalling',
  CpClient = 'CpClient',
  CpProfile = 'CpProfile',
  CpTeamSpaceClient = 'CpTeamSpaceClient',
  Currency = 'Currency',
  CurrencyExchangeRate = 'CurrencyExchangeRate',
  CurrencyExchangeRatesList = 'CurrencyExchangeRatesList',
  CustomEntityRelation = 'CustomEntityRelation',
  CustomEntityRelationData = 'CustomEntityRelationData',
  Data = 'Data',
  DataRelation = 'DataRelation',
  Email = 'Email',
  EmailContentSharingClientRelation = 'EmailContentSharingClientRelation',
  EmailContentSharingSalesUnitRelation = 'EmailContentSharingSalesUnitRelation',
  EmailSequence = 'EmailSequence',
  EmailSequenceActivityLog = 'EmailSequenceActivityLog',
  EmailSequenceActivityLogLine = 'EmailSequenceActivityLogLine',
  EmailSequenceEnrolled = 'EmailSequenceEnrolled',
  EmailSequenceEnrolledStatus = 'EmailSequenceEnrolledStatus',
  EmailSequenceSettings = 'EmailSequenceSettings',
  EmailTemplate = 'EmailTemplate',
  EmailTemplateFolder = 'EmailTemplateFolder',
  EmailTemplateSharingClientRelation = 'EmailTemplateSharingClientRelation',
  EmailTemplateSharingSalesUnitRelation = 'EmailTemplateSharingSalesUnitRelation',
  Entity = 'Entity',
  EntityFitness = 'EntityFitness',
  EntityFitnessIndicator = 'EntityFitnessIndicator',
  EntityHealth = 'EntityHealth',
  EntityHealthSettingsHistory = 'EntityHealthSettingsHistory',
  EntityScoring = 'EntityScoring',
  EntityType = 'EntityType',
  Field = 'Field',
  FieldPermission = 'FieldPermission',
  FieldSequence = 'FieldSequence',
  Forecast = 'Forecast',
  ForecastSharingClientRelation = 'ForecastSharingClientRelation',
  ForecastSharingSalesUnitRelation = 'ForecastSharingSalesUnitRelation',
  FormView = 'FormView',
  FormViewSharingClientRelation = 'FormViewSharingClientRelation',
  FormViewSharingSalesUnitRelation = 'FormViewSharingSalesUnitRelation',
  GhostContact = 'GhostContact',
  GhostContactAccountAccountRoleRelation = 'GhostContactAccountAccountRoleRelation',
  GhostContactAccountRelation = 'GhostContactAccountRelation',
  GhostLeadOpptyContactRelation = 'GhostLeadOpptyContactRelation',
  GhostLeadOpptyContactSalesRoleRelation = 'GhostLeadOpptyContactSalesRoleRelation',
  HowTo = 'HowTo',
  ImportMappingMask = 'ImportMappingMask',
  InterfacePreview = 'InterfacePreview',
  Lead = 'Lead',
  LeadOppty = 'LeadOppty',
  LeadOpptyAccountRelation = 'LeadOpptyAccountRelation',
  LeadOpptyContactGroupRelation = 'LeadOpptyContactGroupRelation',
  LeadOpptyContactRelation = 'LeadOpptyContactRelation',
  LeadOpptyContactSalesRoleRelation = 'LeadOpptyContactSalesRoleRelation',
  LeadOpptyDataExRelation = 'LeadOpptyDataExRelation',
  LeadOpptyKPI = 'LeadOpptyKPI',
  LeadOpptyProjectRelation = 'LeadOpptyProjectRelation',
  LeadOpptySharingClientRelation = 'LeadOpptySharingClientRelation',
  LeadOpptySharingSalesUnitRelation = 'LeadOpptySharingSalesUnitRelation',
  LeadOpptyStepCheckListRelation = 'LeadOpptyStepCheckListRelation',
  LeadOpptyType = 'LeadOpptyType',
  LeadProcess = 'LeadProcess',
  LeadType = 'LeadType',
  MasterRight = 'MasterRight',
  Memo = 'Memo',
  MemoComment = 'MemoComment',
  Message = 'Message',
  MessageRelation = 'MessageRelation',
  MessageSharingClientRelation = 'MessageSharingClientRelation',
  MessageSharingSalesUnitRelation = 'MessageSharingSalesUnitRelation',
  Note = 'Note',
  NotificationSetting = 'NotificationSetting',
  Opportunity = 'Opportunity',
  OpportunityType = 'OpportunityType',
  OpptyProductRelation = 'OpptyProductRelation',
  OpptyProductRelationDataExRelation = 'OpptyProductRelationDataExRelation',
  OpptyProductRelationRevenueSchedule = 'OpptyProductRelationRevenueSchedule',
  OpptyProductRelationRevenueSchedulePeriod = 'OpptyProductRelationRevenueSchedulePeriod',
  OpptyProductRelationType = 'OpptyProductRelationType',
  OpptyRecurrence = 'OpptyRecurrence',
  OpptyRevenueSchedule = 'OpptyRevenueSchedule',
  OpptyRevenueSchedulePeriod = 'OpptyRevenueSchedulePeriod',
  Order = 'Order',
  OrderHistory = 'OrderHistory',
  OrderItem = 'OrderItem',
  OrderRelatedDocument = 'OrderRelatedDocument',
  OrderTempData = 'OrderTempData',
  OrderTempDataItem = 'OrderTempDataItem',
  ParentAccountRelationType = 'ParentAccountRelationType',
  Partner = 'Partner',
  Payment = 'Payment',
  PaymentAdditionalData = 'PaymentAdditionalData',
  PaymentTerm = 'PaymentTerm',
  Phone = 'Phone',
  Pipeline = 'Pipeline',
  Process = 'Process',
  ProcessActivityLog = 'ProcessActivityLog',
  ProcessActivityLogLine = 'ProcessActivityLogLine',
  ProcessScheduledAction = 'ProcessScheduledAction',
  ProcessTemplate = 'ProcessTemplate',
  Product = 'Product',
  ProductCategory = 'ProductCategory',
  ProductDataExRelation = 'ProductDataExRelation',
  ProductPipelineRelation = 'ProductPipelineRelation',
  ProductPriceList = 'ProductPriceList',
  ProductPriceListPrice = 'ProductPriceListPrice',
  ProductType = 'ProductType',
  Profile = 'Profile',
  ProfileDraft = 'ProfileDraft',
  ProfileSharingClientRelation = 'ProfileSharingClientRelation',
  ProfileSharingSalesUnitRelation = 'ProfileSharingSalesUnitRelation',
  Project = 'Project',
  ProjectContactRelation = 'ProjectContactRelation',
  ProjectDataExRelation = 'ProjectDataExRelation',
  ProjectKPI = 'ProjectKPI',
  ProjectObjective = 'ProjectObjective',
  ProjectSharingClientRelation = 'ProjectSharingClientRelation',
  ProjectSharingSalesUnitRelation = 'ProjectSharingSalesUnitRelation',
  ProjectType = 'ProjectType',
  Report = 'Report',
  ReportFolder = 'ReportFolder',
  ReportSchedule = 'ReportSchedule',
  ReportSharingClientRelation = 'ReportSharingClientRelation',
  ReportSharingSalesUnitRelation = 'ReportSharingSalesUnitRelation',
  SalesRole = 'SalesRole',
  SalesUnit = 'SalesUnit',
  SalesUnitClientRelation = 'SalesUnitClientRelation',
  SapiUser = 'SapiUser',
  SpaceSetting = 'SpaceSetting',
  State = 'State',
  Step = 'Step',
  StepChecklist = 'StepChecklist',
  StepChecklistActivityTemplate = 'StepChecklistActivityTemplate',
  StepChecklistActivityTplSharingClientRelation = 'StepChecklistActivityTplSharingClientRelation',
  StepChecklistActivityTplSharingSalesUnitRelation = 'StepChecklistActivityTplSharingSalesUnitRelation',
  Subscription = 'Subscription',
  SubscriptionHubItem = 'SubscriptionHubItem',
  SubscriptionNotification = 'SubscriptionNotification',
  SubscriptionRepresentative = 'SubscriptionRepresentative',
  SubscriptionSDR = 'SubscriptionSDR',
  Supplier = 'Supplier',
  Tag = 'Tag',
  TagRelation = 'TagRelation',
  Task = 'Task',
  TaskRecurrence = 'TaskRecurrence',
  TaskReminder = 'TaskReminder',
  TaskSharingClientRelation = 'TaskSharingClientRelation',
  TaskSharingSalesUnitRelation = 'TaskSharingSalesUnitRelation',
  TaskType = 'TaskType',
  TeamPipelineHubItem = 'TeamPipelineHubItem',
  TeamSpace = 'TeamSpace',
  TeamSpaceClient = 'TeamSpaceClient',
  TeamSpaceClientSetting = 'TeamSpaceClientSetting',
  TeamSpaceProperty = 'TeamSpaceProperty',
  TeamSpaceRegion = 'TeamSpaceRegion',
  TextMessage = 'TextMessage',
  TextMessageConversation = 'TextMessageConversation',
  TextMessageConversationRelations = 'TextMessageConversationRelations',
  Timeframe = 'Timeframe',
  Type = 'Type',
  UnsubscribedPhone = 'UnsubscribedPhone',
  UserProfile = 'UserProfile',
  UserRole = 'UserRole',
  Webhook = 'Webhook',
  WebhookEvent = 'WebhookEvent',
  Webresource = 'Webresource'
}

export enum LicEntityPermsEnum {
  AdminAutomationHub = 'AdminAutomationHub',
  AdminSetAutomationHub = 'AdminSetAutomationHub',
  ApiAdmin = 'ApiAdmin',
  Create = 'Create',
  Delete = 'Delete',
  EditPicture = 'EditPicture',
  Reactivate = 'Reactivate',
  Read = 'Read',
  ResetSpace = 'ResetSpace',
  Restore = 'Restore',
  Suspend = 'Suspend',
  UpdateName = 'UpdateName',
  Write = 'Write'
}

export enum LicEntityNameEnum {
  ActivityDetail = 'ActivityDetail',
  AutomationHubApp = 'AutomationHubApp',
  AutomationHubCategory = 'AutomationHubCategory',
  AutomationHubItem = 'AutomationHubItem',
  Country = 'Country',
  CpActivity = 'CpActivity',
  CpCallLog = 'CpCallLog',
  CpCalling = 'CpCalling',
  CpClient = 'CpClient',
  CpProfile = 'CpProfile',
  CpTeamSpaceClient = 'CpTeamSpaceClient',
  HowTo = 'HowTo',
  IdentityProvider = 'IdentityProvider',
  NotificationSetting = 'NotificationSetting',
  Order = 'Order',
  OrderHistory = 'OrderHistory',
  OrderItem = 'OrderItem',
  OrderRelatedDocument = 'OrderRelatedDocument',
  OrderTempData = 'OrderTempData',
  OrderTempDataItem = 'OrderTempDataItem',
  Partner = 'Partner',
  PasswordChangeRequest = 'PasswordChangeRequest',
  Payment = 'Payment',
  PaymentAdditionalData = 'PaymentAdditionalData',
  PaymentTerm = 'PaymentTerm',
  Region = 'Region',
  SapiUser = 'SapiUser',
  State = 'State',
  Subscription = 'Subscription',
  SubscriptionHubItem = 'SubscriptionHubItem',
  SubscriptionNotification = 'SubscriptionNotification',
  SubscriptionRepresentative = 'SubscriptionRepresentative',
  Supplier = 'Supplier',
  TeamPipelineHubItemBase = 'TeamPipelineHubItemBase',
  TeamSpace = 'TeamSpace',
  TeamSpaceClient = 'TeamSpaceClient',
  TeamSpaceClientSetting = 'TeamSpaceClientSetting',
  TeamSpaceProperty = 'TeamSpaceProperty',
  TeamSpaceRegion = 'TeamSpaceRegion',
  TwilioCallerId = 'TwilioCallerId',
  UserOnlineServices = 'UserOnlineServices',
  UserProfile = 'UserProfile',
  UserRole = 'UserRole',
  VatCheck = 'VatCheck'
}

export enum AppsEnum {
  BusinessCenter = 'BusinessCenter'
}

export interface UpdateUserSettingInput {
  __typename?: 'UpdateUserSettingInput';
  autoCreateAccount?: boolean | null;
  autoCreateContact?: boolean | null;
  autoHighlightFieldValue?: boolean | null;
  autoLinkAccount?: boolean | null;
  autoPopulate?: boolean | null;
  bccPipelineEmail?: boolean | null;
  clientMutationId?: string | null;
  defaultAppointmentDuration?: number | null;
  defaultAppointmentReminder?: number | null;
  defaultTaskDueDate?: number | null;
  defaultTaskReminder?: number | null;
  defaultTaskReminderDayOffset?: number | null;
  defaultTaskReminderDueDate?: number | null;
  defaultTaskReminderTimeOffset?: number | null;
  emailHandlerInNewTab?: boolean | null;
  emailSignature?: string | null;
  firstWeekDay?: DayName | null;
  language?: string | null;
  locale?: string | null;
  nameFormat?: NameFormat | null;
  navigatorHints?: boolean | null;
  salespopWidgetCategories?: string | null;
  salespopWidgetClosedDate?: GqlDate | null;
  salespopWidgetLastArticleDate?: string | null;
  salespopWidgetUnsubscribed?: boolean | null;
  showFollowActionMessage?: boolean | null;
  showReminderNotifications?: boolean | null;
  workWeekFrom?: DayName | null;
  workWeekTo?: DayName | null;
}

export interface UpdateUserProfileInput {
  __typename?: 'UpdateUserProfileInput';
  businessFunction?: string | null;
  city?: string | null;
  clientMutationId?: string | null;
  company?: string | null;
  country?: string | null;
  created?: DateTime | null;
  defaultIdpId?: number | null;
  email?: string | null;
  firstName?: string | null;
  id: ID;
  language?: UserLanguage | null;
  lastName?: string | null;
  locale?: string | null;
  middleName?: string | null;
  modified?: DateTime | null;
  newsletter?: boolean | null;
  stateProvince?: string | null;
  telephone?: string | null;
  timezoneAutodetect?: boolean | null;
  timezoneName?: string | null;
}

export interface UpdateTeamSpaceNameInput {
  __typename?: 'UpdateTeamSpaceNameInput';
  clientMutationId?: string | null;
  id: ID;
  name: string;
}

export interface UpdateTeamSpaceInput {
  __typename?: 'UpdateTeamSpaceInput';
  clientMutationId?: string | null;
  id: ID;
  name?: string | null;
  size?: number | null;
  subscriptionId?: ID | null;
}

export interface UpdateSubscriptionHubItemInput {
  __typename?: 'UpdateSubscriptionHubItemInput';
  automationHubItemId?: number | null;
  clientMutationId?: string | null;
  subscriptionId?: number | null;
}

export interface UpdateAutomationHubItemInput {
  __typename?: 'UpdateAutomationHubItemInput';
  automationHubAppId?: number | null;
  automationHubCategoryId?: number | null;
  clientMutationId?: string | null;
  configurationUrl?: string | null;
  deprecatedByItemId?: number | null;
  description?: string | null;
  hasLastRun?: boolean | null;
  hasLogs?: boolean | null;
  id: ID;
  isBeta?: boolean | null;
  isBundle?: boolean | null;
  logoUrl?: string | null;
  minApiVersion?: number | null;
  name?: string | null;
  ranking?: number | null;
  websiteUrl?: string | null;
}

export interface UpdateAutomationHubCategoryInput {
  __typename?: 'UpdateAutomationHubCategoryInput';
  clientMutationId?: string | null;
  id: ID;
  name?: string | null;
}

export interface UpdateAutomationHubAppInput {
  __typename?: 'UpdateAutomationHubAppInput';
  clientMutationId?: string | null;
  id: ID;
  logoUrl?: string | null;
  name?: string | null;
  supportPrivacyUrl?: string | null;
  supportTermsUrl?: string | null;
  supportUrl?: string | null;
  websiteUrl?: string | null;
}

export interface ToggleStateTeamPipelineHubItemInput {
  __typename?: 'ToggleStateTeamPipelineHubItemInput';
  clientMutationId?: string | null;
  id: ID;
  isEnabled: boolean;
}

export interface TakeOverTeamSpaceInput {
  __typename?: 'TakeOverTeamSpaceInput';
  clientMutationId?: string | null;
  hash: string;
  sourceDbUrl: string;
  sourceS3Bucket: string;
  sourceS3Region: string;
  storage: string;
  targetDbUrl: string;
  teamSpaceId: string;
}

export interface SuspendTeamSpaceInput {
  __typename?: 'SuspendTeamSpaceInput';
  clientMutationId?: string | null;
  id: ID;
}

export interface SetUserProfilePictureInput {
  __typename?: 'SetUserProfilePictureInput';
  clientMutationId?: string | null;
  data: Base64;
  id: ID;
}

export interface SetTeamSpacePictureInput {
  __typename?: 'SetTeamSpacePictureInput';
  clientMutationId?: string | null;
  data: Base64;
  id: ID;
}

export interface SetAsFailedAutomationHubItemInput {
  __typename?: 'SetAsFailedAutomationHubItemInput';
  clientMutationId?: string | null;
  id: ID;
}

export interface ScheduleHubspotInitSyncInput {
  __typename?: 'ScheduleHubspotInitSyncInput';
  clientMutationId?: string | null;
  ruleId: UUID;
  syncDirection: SyncDirectionEnum;
}

export interface ResetTeamSpaceInput {
  __typename?: 'ResetTeamSpaceInput';
  appendSuffix?: boolean | null;
  clientMutationId?: string | null;
  inviteAdditionalAdmins?: string[] | null;
  inviteAdditionalEmails?: string[] | null;
  keepOriginalActive?: boolean | null;
  resetMode: ResetSpaceEnum;
  skipClientIds: UUID[];
  spaceName?: string | null;
  teamSpaceId: string;
}

export interface RequestRemoveAutomationHubItemInput {
  __typename?: 'RequestRemoveAutomationHubItemInput';
  clientMutationId?: string | null;
  id: ID;
}

export interface RequestCustomSolutionAutomationHubItemInput {
  __typename?: 'RequestCustomSolutionAutomationHubItemInput';
  clientMutationId?: string | null;
  details: string;
  name: string;
}

export interface RequestAddAutomationHubItemInput {
  __typename?: 'RequestAddAutomationHubItemInput';
  clientMutationId?: string | null;
  configuration?: JSONString | null;
  id: ID;
}

export interface ReportErrorInput {
  __typename?: 'ReportErrorInput';
  appVersion: string;
  clientMutationId?: string | null;
  description?: string | null;
  id: ID;
  logFileContent?: Base64 | null;
  osVersion: string;
  priority: ErrorReportPriority;
  subject?: string | null;
}

export interface RemoveUserProfilePictureInput {
  __typename?: 'RemoveUserProfilePictureInput';
  clientMutationId?: string | null;
  id: ID;
}

export interface RemoveTeamSpacePictureInput {
  __typename?: 'RemoveTeamSpacePictureInput';
  clientMutationId?: string | null;
  id: ID;
}

export interface RemoveAutomationHubItemInput {
  __typename?: 'RemoveAutomationHubItemInput';
  clientMutationId?: string | null;
  id: ID;
}

export interface RegisterOauth2ClientMutationInput {
  __typename?: 'RegisterOauth2ClientMutationInput';
  applicationConfigurationUrl: string;
  applicationDescription: string;
  applicationIconUrl: string;
  applicationName: string;
  applicationWebsiteUrl: string;
  automationHubCategoryId: number;
  clientMutationId?: string | null;
  companyIconUrl: string;
  companyName: string;
  companyWebsiteUrl: string;
  redirectUris: string[];
  scope: string[];
  supportPrivacyUrl: string;
  supportTermsUrl: string;
  supportUrl: string;
}

export interface MoveTeamSpaceInput {
  __typename?: 'MoveTeamSpaceInput';
  clientMutationId?: string | null;
  newTeamSpaceUrl: string;
  teamSpaceId: string;
}

export interface ImportServicesDataInput {
  __typename?: 'ImportServicesDataInput';
  clientMutationId?: string | null;
  entitiesJson: string;
  targetDbUrl: string;
  teamSpaceId: string;
}

export interface FinishMigrationInput {
  __typename?: 'FinishMigrationInput';
  clientMutationId?: string | null;
  cloudDbName: string;
  force: boolean;
  hybridDbName: string;
}

export interface FinalizeConfigurationAutomationHubItemInput {
  __typename?: 'FinalizeConfigurationAutomationHubItemInput';
  clientMutationId?: string | null;
  id: ID;
}

export interface DropTeamSpaceInput {
  __typename?: 'DropTeamSpaceInput';
  clientMutationId?: string | null;
  dbName: string;
  failWhenNotExists: boolean;
}

export interface DiagnoseTeamSpaceInput {
  __typename?: 'DiagnoseTeamSpaceInput';
  cleanAfterFinish?: boolean | null;
  clientMutationId?: string | null;
  recreateTemplateDb?: boolean | null;
  teamSpaceId: string;
}

export interface DeleteSubscriptionHubItemInput {
  __typename?: 'DeleteSubscriptionHubItemInput';
  clientMutationId?: string | null;
}

export interface DeleteAutomationHubItemInput {
  __typename?: 'DeleteAutomationHubItemInput';
  clientMutationId?: string | null;
  id: ID;
}

export interface DeleteAutomationHubCategoryInput {
  __typename?: 'DeleteAutomationHubCategoryInput';
  clientMutationId?: string | null;
  id: ID;
}

export interface DeleteAutomationHubAppInput {
  __typename?: 'DeleteAutomationHubAppInput';
  clientMutationId?: string | null;
  id: ID;
}

export interface CreateTeamSpaceDatabaseTeamSpaceInput {
  __typename?: 'CreateTeamSpaceDatabaseTeamSpaceInput';
  clientMutationId?: string | null;
  inviteeUsers?: string[] | null;
  spaceName: string;
  spaceSize?: number | null;
  subscriptionId: number;
  teamSpaceRegionId?: string | null;
  templateType: TemplateDbType;
}

export interface CreateTeamSpaceInput {
  __typename?: 'CreateTeamSpaceInput';
  clientMutationId?: string | null;
  spaceName: string;
  spaceSize?: number | null;
  subscriptionId: number;
  templateType: TemplateDbType;
  userId: number;
}

export interface CreateSubscriptionHubItemInput {
  __typename?: 'CreateSubscriptionHubItemInput';
  automationHubItemId: number;
  clientMutationId?: string | null;
  subscriptionId: number;
}

export interface CreateAutomationHubItemInput {
  __typename?: 'CreateAutomationHubItemInput';
  automationHubAppId: number;
  automationHubCategoryId: number;
  clientMutationId?: string | null;
  configurationUrl?: string | null;
  deprecatedByItemId?: number | null;
  description?: string | null;
  hasLastRun: boolean;
  hasLogs: boolean;
  isBeta: boolean;
  isBundle: boolean;
  logoUrl: string;
  minApiVersion: number;
  name: string;
  ranking: number;
  scope: AutomationHubScope;
  tier: AutomationHubTierEnum;
  websiteUrl?: string | null;
}

export interface CreateAutomationHubCategoryInput {
  __typename?: 'CreateAutomationHubCategoryInput';
  clientMutationId?: string | null;
  name: string;
}

export interface CreateAutomationHubAppInput {
  __typename?: 'CreateAutomationHubAppInput';
  clientMutationId?: string | null;
  logoUrl: string;
  name: string;
  supportPrivacyUrl?: string | null;
  supportTermsUrl?: string | null;
  supportUrl?: string | null;
  websiteUrl?: string | null;
}

export interface CopyTeamSpaceInput {
  __typename?: 'CopyTeamSpaceInput';
  clientMutationId?: string | null;
  dbName: string;
  skipCloudObjects?: boolean | null;
  spaceName: string;
}

export interface ConfigureTeamPipelineHubItemInput {
  __typename?: 'ConfigureTeamPipelineHubItemInput';
  clientMutationId?: string | null;
  configuration?: JSONString | null;
  id: ID;
}

export interface HubItemRelationRuleSettingsParams {
  __typename?: 'HubItemRelationRuleSettingsParams';
  deduplication?: HubItemDeduplicationSettingParams | null;
  externalRuleSetting: HubItemExternalRuleSettingParams;
  fieldMappings: HubItemFieldMappingParams[];
  id: UUID;
  localRuleSetting: HubItemLocalRuleSettingParams;
  name: string;
  ownerMapping?: HubItemFieldMappingParams | null;
  pipelines?: HubItemFieldMappingParams[] | null;
  relationFieldMappings: HubItemFieldMappingParams[];
  salesUnitMapping?: HubItemFieldMappingParams | null;
  syncDirection: SyncDirectionEnum;
}

export interface HubItemLocalRuleSettingParams {
  __typename?: 'HubItemLocalRuleSettingParams';
  entity: EntityNameEnum;
  events: HubItemEventSettingParams;
}

export interface HubItemValueMappingParams {
  __typename?: 'HubItemValueMappingParams';
  direction: SyncDirectionEnum;
  externalValue?: string | null;
  localValue?: string | null;
}

export interface HubItemFieldMappingSettingParams {
  __typename?: 'HubItemFieldMappingSettingParams';
  externalFallbackValue?: JSONString | null;
  localFallbackValue?: JSONString | null;
  valuesMapping?: HubItemValueMappingParams[] | null;
}

export interface HubItemFieldMappingParams {
  __typename?: 'HubItemFieldMappingParams';
  externalFieldId?: ID | null;
  localFieldId?: UUID | null;
  setting?: HubItemFieldMappingSettingParams | null;
  syncDirection: SyncDirectionEnum;
}

export interface HubItemEventSettingParams {
  __typename?: 'HubItemEventSettingParams';
  create: boolean;
  delete: boolean;
  update: boolean;
}

export interface HubItemExternalRuleSettingParams {
  __typename?: 'HubItemExternalRuleSettingParams';
  entity: string;
  events: HubItemEventSettingParams;
}

export interface HubItemDeduplicationFieldMappingParams {
  __typename?: 'HubItemDeduplicationFieldMappingParams';
  externalFieldId?: ID | null;
  localFieldId?: UUID | null;
  operator: MatchingOperatorEnum;
}

export interface HubItemDeduplicationSettingParams {
  __typename?: 'HubItemDeduplicationSettingParams';
  fields: HubItemDeduplicationFieldMappingParams[];
}

export interface HubItemRuleSettingsParams {
  __typename?: 'HubItemRuleSettingsParams';
  autoSync: boolean;
  deduplication?: HubItemDeduplicationSettingParams | null;
  externalRuleSetting: HubItemExternalRuleSettingParams;
  fieldMappings: HubItemFieldMappingParams[];
  id: UUID;
  localRuleSetting: HubItemLocalRuleSettingParams;
  name: string;
  ownerMapping?: HubItemFieldMappingParams | null;
  pipelines?: HubItemFieldMappingParams[] | null;
  relationSettings: HubItemRelationRuleSettingsParams[];
  salesUnitMapping?: HubItemFieldMappingParams | null;
  syncDirection: SyncDirectionEnum;
}

export interface HubItemIntegrationSyncConfigParams {
  __typename?: 'HubItemIntegrationSyncConfigParams';
  rules: HubItemRuleSettingsParams[];
}

export interface ConfigureSyncIntegrationTeamPipelineHubItemInput {
  __typename?: 'ConfigureSyncIntegrationTeamPipelineHubItemInput';
  clientMutationId?: string | null;
  configuration?: HubItemIntegrationSyncConfigParams | null;
  id: ID;
}

export interface CleanupTrialMonitoringInput {
  __typename?: 'CleanupTrialMonitoringInput';
  clientMutationId?: string | null;
}

export interface ChangeUserProfilePasswordAndIssueTokensInput {
  __typename?: 'ChangeUserProfilePasswordAndIssueTokensInput';
  clientMutationId?: string | null;
  id: ID;
  newPassword: string;
  oldPassword: string;
}

export interface ChangeUserProfilePasswordInput {
  __typename?: 'ChangeUserProfilePasswordInput';
  clientMutationId?: string | null;
  id: ID;
  newPassword: string;
  oldPassword: string;
}

export interface AddAutomationHubItemInput {
  __typename?: 'AddAutomationHubItemInput';
  clientMutationId?: string | null;
  id: ID;
}

export interface ActivateTeamSpaceInput {
  __typename?: 'ActivateTeamSpaceInput';
  clientMutationId?: string | null;
  id: ID;
}

export interface UserRoleOrderByNestedInput {
  __typename?: 'UserRoleOrderByNestedInput';
  assigmentPossibilities?: OrderByEnum | null;
  auditAccess?: OrderByEnum | null;
  dunningServiceAccess?: OrderByEnum | null;
  dunningServiceChange?: OrderByEnum | null;
  dunningServiceEmailRecipient?: OrderByEnum | null;
  id?: OrderByEnum | null;
  kpiDashboardAccess?: OrderByEnum | null;
  name?: OrderByEnum | null;
  notificationRenewalServiceAccess?: OrderByEnum | null;
  notificationRenewalServiceChange?: OrderByEnum | null;
  notificationRenewalServiceEmailRecipient?: OrderByEnum | null;
  orderManagementAccess?: OrderByEnum | null;
  orderManagementCancel?: OrderByEnum | null;
  orderManagementChange?: OrderByEnum | null;
  orderManagementConfirm?: OrderByEnum | null;
  orderManagementDelete?: OrderByEnum | null;
  orderManagementDeleteConfirmation?: OrderByEnum | null;
  partnerAccess?: OrderByEnum | null;
  partnerAssignment?: OrderByEnum | null;
  partnerChange?: OrderByEnum | null;
  partnerDisable?: OrderByEnum | null;
  paymentTermsManagement?: OrderByEnum | null;
  sapiUserManagementAccess?: OrderByEnum | null;
  sapiUserManagementChange?: OrderByEnum | null;
  sapiUserManagementDelete?: OrderByEnum | null;
  subscriptionAssignment?: OrderByEnum | null;
  subscriptionSdr?: OrderByEnum | null;
  subscriptionSpaceAdminView?: OrderByEnum | null;
  subscriptionSpaceCancel?: OrderByEnum | null;
  subscriptionSpaceChange?: OrderByEnum | null;
  subscriptionSpaceModulesManagement?: OrderByEnum | null;
  systemMaintenance?: OrderByEnum | null;
  userManagementAccess?: OrderByEnum | null;
  userManagementChange?: OrderByEnum | null;
  userManagementDefineRoles?: OrderByEnum | null;
  userManagementDisable?: OrderByEnum | null;
}

export interface IdentityProviderOrderByNestedInput {
  __typename?: 'IdentityProviderOrderByNestedInput';
  acceptUntrusted?: OrderByEnum | null;
  description?: OrderByEnum | null;
  enabled?: OrderByEnum | null;
  id?: OrderByEnum | null;
  name?: OrderByEnum | null;
  subscriptionId?: OrderByEnum | null;
  type?: OrderByEnum | null;
  url?: OrderByEnum | null;
  xml?: OrderByEnum | null;
}

export interface UserProfileOrderByInput {
  __typename?: 'UserProfileOrderByInput';
  administrator?: OrderByEnum | null;
  businessFunction?: OrderByEnum | null;
  city?: OrderByEnum | null;
  company?: OrderByEnum | null;
  country?: OrderByEnum | null;
  created?: OrderByEnum | null;
  defaultIdp?: IdentityProviderOrderByNestedInput[] | null;
  defaultIdpId?: OrderByEnum | null;
  email?: OrderByEnum | null;
  firstName?: OrderByEnum | null;
  id?: OrderByEnum | null;
  isDeleted?: OrderByEnum | null;
  lastLogin?: OrderByEnum | null;
  lastName?: OrderByEnum | null;
  locale?: OrderByEnum | null;
  middleName?: OrderByEnum | null;
  modified?: OrderByEnum | null;
  name?: OrderByEnum | null;
  newsletter?: OrderByEnum | null;
  partnerId?: OrderByEnum | null;
  passwordChangedAt?: OrderByEnum | null;
  pictureUrl?: OrderByEnum | null;
  stateProvince?: OrderByEnum | null;
  telephone?: OrderByEnum | null;
  timezoneAutodetect?: OrderByEnum | null;
  timezoneName?: OrderByEnum | null;
  userRole?: UserRoleOrderByNestedInput[] | null;
  userRoleId?: OrderByEnum | null;
}

export interface UserLanguageFilterInput {
  __typename?: 'UserLanguageFilterInput';
  NOT?: UserLanguageFilterInput | null;
  eq?: UserLanguage[] | null;
}

export interface UserProfileFilterInput {
  __typename?: 'UserProfileFilterInput';
  AND?: UserProfileFilterInput[] | null;
  NOT?: UserProfileFilterInput | null;
  OR?: UserProfileFilterInput[] | null;
  administrator?: boolean | null;
  businessFunction?: EntityFilterStringField | null;
  city?: EntityFilterStringField | null;
  company?: EntityFilterStringField | null;
  country?: EntityFilterStringField | null;
  created?: EntityFilterDateTimeField | null;
  defaultIdpId?: EntityFilterNumberField | null;
  email?: EntityFilterStringField | null;
  firstName?: EntityFilterStringField | null;
  id?: EntityFilterNumberField | null;
  isDeleted?: boolean | null;
  language?: UserLanguageFilterInput | null;
  lastLogin?: EntityFilterDateTimeField | null;
  lastName?: EntityFilterStringField | null;
  locale?: EntityFilterStringField | null;
  middleName?: EntityFilterStringField | null;
  modified?: EntityFilterDateTimeField | null;
  name?: EntityFilterStringField | null;
  newsletter?: boolean | null;
  partnerId?: EntityFilterNumberField | null;
  passwordChangedAt?: EntityFilterDateTimeField | null;
  pictureUrl?: EntityFilterStringField | null;
  stateProvince?: EntityFilterStringField | null;
  telephone?: EntityFilterStringField | null;
  timezoneAutodetect?: boolean | null;
  timezoneName?: EntityFilterStringField | null;
  userRoleId?: EntityFilterNumberField | null;
}

export interface TeamSpaceOrderByNestedInput {
  __typename?: 'TeamSpaceOrderByNestedInput';
  dbVersion?: OrderByEnum | null;
  flags?: OrderByEnum | null;
  id?: OrderByEnum | null;
  name?: OrderByEnum | null;
  regionName?: OrderByEnum | null;
  size?: OrderByEnum | null;
  status?: OrderByEnum | null;
  subscriptionId?: OrderByEnum | null;
  version?: OrderByEnum | null;
}

export interface TeamSpaceClientOrderByInput {
  __typename?: 'TeamSpaceClientOrderByInput';
  created?: OrderByEnum | null;
  dbGuid?: OrderByEnum | null;
  id?: OrderByEnum | null;
  isDeleted?: OrderByEnum | null;
  modified?: OrderByEnum | null;
  teamSpace?: TeamSpaceOrderByNestedInput[] | null;
  teamSpaceId?: OrderByEnum | null;
  userProfile?: UserProfileOrderByNestedInput[] | null;
  userProfileId?: OrderByEnum | null;
}

export interface TeamSpaceClientFilterInput {
  __typename?: 'TeamSpaceClientFilterInput';
  AND?: TeamSpaceClientFilterInput[] | null;
  NOT?: TeamSpaceClientFilterInput | null;
  OR?: TeamSpaceClientFilterInput[] | null;
  created?: EntityFilterDateTimeField | null;
  dbGuid?: EntityFilterStringField | null;
  id?: EntityFilterNumberField | null;
  isDeleted?: boolean | null;
  modified?: EntityFilterDateTimeField | null;
  teamSpaceId?: EntityFilterStringField | null;
  userProfileId?: EntityFilterNumberField | null;
}

export interface AutomationHubItemOrderByNestedInput {
  __typename?: 'AutomationHubItemOrderByNestedInput';
  aclPermissionKey?: OrderByEnum | null;
  automationHubAppId?: OrderByEnum | null;
  automationHubCategoryId?: OrderByEnum | null;
  configurationUrl?: OrderByEnum | null;
  deprecatedByItemId?: OrderByEnum | null;
  description?: OrderByEnum | null;
  hasLastRun?: OrderByEnum | null;
  hasLogs?: OrderByEnum | null;
  id?: OrderByEnum | null;
  isBeta?: OrderByEnum | null;
  isBundle?: OrderByEnum | null;
  isDeleted?: OrderByEnum | null;
  logoUrl?: OrderByEnum | null;
  minApiVersion?: OrderByEnum | null;
  name?: OrderByEnum | null;
  ranking?: OrderByEnum | null;
  websiteUrl?: OrderByEnum | null;
}

export interface SubscriptionHubItemOrderByInput {
  __typename?: 'SubscriptionHubItemOrderByInput';
  automationHubItem?: AutomationHubItemOrderByNestedInput[] | null;
  automationHubItemId?: OrderByEnum | null;
  subscription?: SubscriptionOrderByNestedInput[] | null;
  subscriptionId?: OrderByEnum | null;
}

export interface SubscriptionHubItemFilterInput {
  __typename?: 'SubscriptionHubItemFilterInput';
  AND?: SubscriptionHubItemFilterInput[] | null;
  NOT?: SubscriptionHubItemFilterInput | null;
  OR?: SubscriptionHubItemFilterInput[] | null;
  automationHubItemId?: EntityFilterNumberField | null;
  subscriptionId?: EntityFilterNumberField | null;
}

export interface SupplierOrderByNestedInput {
  __typename?: 'SupplierOrderByNestedInput';
  id?: OrderByEnum | null;
  name?: OrderByEnum | null;
}

export interface PartnerOrderByNestedInput {
  __typename?: 'PartnerOrderByNestedInput';
  address?: OrderByEnum | null;
  agreedToGtac?: OrderByEnum | null;
  city?: OrderByEnum | null;
  companiesRepresented?: OrderByEnum | null;
  country?: OrderByEnum | null;
  created?: OrderByEnum | null;
  email?: OrderByEnum | null;
  firstName?: OrderByEnum | null;
  id?: OrderByEnum | null;
  landingPage?: OrderByEnum | null;
  lastName?: OrderByEnum | null;
  modified?: OrderByEnum | null;
  name?: OrderByEnum | null;
  phone?: OrderByEnum | null;
  postalCode?: OrderByEnum | null;
  representativeId?: OrderByEnum | null;
  state?: OrderByEnum | null;
  status?: OrderByEnum | null;
  type?: OrderByEnum | null;
  url?: OrderByEnum | null;
  vatcode?: OrderByEnum | null;
  yearFounded?: OrderByEnum | null;
}

export interface SubscriptionOrderByInput {
  __typename?: 'SubscriptionOrderByInput';
  agreedToGtac?: OrderByEnum | null;
  anniversaryDate?: OrderByEnum | null;
  billingCountryName?: OrderByEnum | null;
  classification?: OrderByEnum | null;
  contactEmail?: OrderByEnum | null;
  created?: OrderByEnum | null;
  crmAccountId?: OrderByEnum | null;
  customPrice?: OrderByEnum | null;
  discount?: OrderByEnum | null;
  endDate?: OrderByEnum | null;
  fromCampaign?: OrderByEnum | null;
  id?: OrderByEnum | null;
  lastVatCheckDate?: OrderByEnum | null;
  licensesPurchased?: OrderByEnum | null;
  licensesUsed?: OrderByEnum | null;
  manualInstructions?: OrderByEnum | null;
  manualOrderDunning?: OrderByEnum | null;
  manuallyHandled?: OrderByEnum | null;
  name?: OrderByEnum | null;
  numberOfSalesPeople?: OrderByEnum | null;
  partnerName?: OrderByEnum | null;
  paymentTermsId?: OrderByEnum | null;
  referralPartner?: PartnerOrderByNestedInput[] | null;
  referralPartnerId?: OrderByEnum | null;
  representativeEmail?: OrderByEnum | null;
  representativeId?: OrderByEnum | null;
  representativeName?: OrderByEnum | null;
  requiredIdpId?: OrderByEnum | null;
  sdrEmail?: OrderByEnum | null;
  sdrId?: OrderByEnum | null;
  sdrName?: OrderByEnum | null;
  startDate?: OrderByEnum | null;
  status?: OrderByEnum | null;
  supplier?: SupplierOrderByNestedInput[] | null;
  supplierId?: OrderByEnum | null;
  tier?: OrderByEnum | null;
  vatCheckResult?: OrderByEnum | null;
  vatCode?: OrderByEnum | null;
}

export interface SubscriptionProductTierFilterInput {
  __typename?: 'SubscriptionProductTierFilterInput';
  NOT?: SubscriptionProductTierFilterInput | null;
  eq?: SubscriptionProductTier[] | null;
}

export interface SubscriptionStatusFilterInput {
  __typename?: 'SubscriptionStatusFilterInput';
  NOT?: SubscriptionStatusFilterInput | null;
  eq?: SubscriptionStatus[] | null;
}

export interface NumberOfSalesPeopleFilterInput {
  __typename?: 'NumberOfSalesPeopleFilterInput';
  NOT?: NumberOfSalesPeopleFilterInput | null;
  eq?: NumberOfSalesPeople[] | null;
}

export interface SubscriptionCompanyFocusFilterInput {
  __typename?: 'SubscriptionCompanyFocusFilterInput';
  NOT?: SubscriptionCompanyFocusFilterInput | null;
  eq?: SubscriptionCompanyFocus[] | null;
}

export interface SubscriptionClassificationFilterInput {
  __typename?: 'SubscriptionClassificationFilterInput';
  NOT?: SubscriptionClassificationFilterInput | null;
  eq?: SubscriptionClassification[] | null;
}

export interface SubscriptionFilterInput {
  __typename?: 'SubscriptionFilterInput';
  AND?: SubscriptionFilterInput[] | null;
  NOT?: SubscriptionFilterInput | null;
  OR?: SubscriptionFilterInput[] | null;
  agreedToGtac?: boolean | null;
  anniversaryDate?: GqlDate | null;
  billingAddress?: EntityFilterStringField | null;
  billingCity?: EntityFilterStringField | null;
  billingCompany?: EntityFilterStringField | null;
  billingCountryId?: EntityFilterStringField | null;
  billingCountryName?: string | null;
  billingFax?: EntityFilterStringField | null;
  billingFirstName?: EntityFilterStringField | null;
  billingLastName?: EntityFilterStringField | null;
  billingPhone?: EntityFilterStringField | null;
  billingPostalCode?: EntityFilterStringField | null;
  billingStateId?: EntityFilterStringField | null;
  billingVatCode?: EntityFilterStringField | null;
  classification?: SubscriptionClassificationFilterInput | null;
  contactEmail?: EntityFilterStringField | null;
  created?: EntityFilterDateTimeField | null;
  crmAccountId?: EntityFilterStringField | null;
  customPrice?: EntityFilterNumberField | null;
  discount?: EntityFilterNumberField | null;
  endDate?: EntityFilterDateTimeField | null;
  focus?: SubscriptionCompanyFocusFilterInput | null;
  fromCampaign?: EntityFilterStringField | null;
  id?: EntityFilterNumberField | null;
  lastVatCheckDate?: DateTime | null;
  licensesPurchased?: EntityFilterNumberField | null;
  licensesUsed?: number | null;
  manualInstructions?: EntityFilterStringField | null;
  manualOrderDunning?: boolean | null;
  manuallyHandled?: boolean | null;
  name?: EntityFilterStringField | null;
  numberOfSalesPeople?: NumberOfSalesPeopleFilterInput | null;
  partnerName?: string | null;
  paymentTermsId?: EntityFilterNumberField | null;
  referralPartnerId?: EntityFilterNumberField | null;
  representativeEmail?: string | null;
  representativeId?: EntityFilterNumberField | null;
  representativeName?: string | null;
  requiredIdpId?: EntityFilterNumberField | null;
  sdrEmail?: string | null;
  sdrId?: EntityFilterNumberField | null;
  sdrName?: string | null;
  startDate?: EntityFilterDateTimeField | null;
  status?: SubscriptionStatusFilterInput | null;
  supplierId?: EntityFilterNumberField | null;
  tier?: SubscriptionProductTierFilterInput | null;
  vatCheckResult?: VatCheckResultEnum | null;
  vatCode?: string | null;
}

export interface UserProfileOrderByNestedInput {
  __typename?: 'UserProfileOrderByNestedInput';
  administrator?: OrderByEnum | null;
  businessFunction?: OrderByEnum | null;
  city?: OrderByEnum | null;
  company?: OrderByEnum | null;
  country?: OrderByEnum | null;
  created?: OrderByEnum | null;
  defaultIdpId?: OrderByEnum | null;
  email?: OrderByEnum | null;
  firstName?: OrderByEnum | null;
  id?: OrderByEnum | null;
  isDeleted?: OrderByEnum | null;
  lastLogin?: OrderByEnum | null;
  lastName?: OrderByEnum | null;
  locale?: OrderByEnum | null;
  middleName?: OrderByEnum | null;
  modified?: OrderByEnum | null;
  name?: OrderByEnum | null;
  newsletter?: OrderByEnum | null;
  partnerId?: OrderByEnum | null;
  passwordChangedAt?: OrderByEnum | null;
  pictureUrl?: OrderByEnum | null;
  stateProvince?: OrderByEnum | null;
  telephone?: OrderByEnum | null;
  timezoneAutodetect?: OrderByEnum | null;
  timezoneName?: OrderByEnum | null;
  userRoleId?: OrderByEnum | null;
}

export interface PartnerOrderByInput {
  __typename?: 'PartnerOrderByInput';
  address?: OrderByEnum | null;
  agreedToGtac?: OrderByEnum | null;
  city?: OrderByEnum | null;
  companiesRepresented?: OrderByEnum | null;
  country?: OrderByEnum | null;
  created?: OrderByEnum | null;
  email?: OrderByEnum | null;
  firstName?: OrderByEnum | null;
  id?: OrderByEnum | null;
  landingPage?: OrderByEnum | null;
  lastName?: OrderByEnum | null;
  modified?: OrderByEnum | null;
  name?: OrderByEnum | null;
  phone?: OrderByEnum | null;
  postalCode?: OrderByEnum | null;
  representative?: UserProfileOrderByNestedInput[] | null;
  representativeId?: OrderByEnum | null;
  state?: OrderByEnum | null;
  status?: OrderByEnum | null;
  type?: OrderByEnum | null;
  url?: OrderByEnum | null;
  vatcode?: OrderByEnum | null;
  yearFounded?: OrderByEnum | null;
}

export interface PartnerTargetCustomersFilterInput {
  __typename?: 'PartnerTargetCustomersFilterInput';
  NOT?: PartnerTargetCustomersFilterInput | null;
  eq?: PartnerTargetCustomers[] | null;
}

export interface PartnerCompanySizeFilterInput {
  __typename?: 'PartnerCompanySizeFilterInput';
  NOT?: PartnerCompanySizeFilterInput | null;
  eq?: PartnerCompanySize[] | null;
}

export interface PartnerCompanyFocusFilterInput {
  __typename?: 'PartnerCompanyFocusFilterInput';
  NOT?: PartnerCompanyFocusFilterInput | null;
  eq?: PartnerCompanyFocus[] | null;
}

export interface PartnerActiveCustomersFilterInput {
  __typename?: 'PartnerActiveCustomersFilterInput';
  NOT?: PartnerActiveCustomersFilterInput | null;
  eq?: PartnerActiveCustomers[] | null;
}

export interface PartnerFilterInput {
  __typename?: 'PartnerFilterInput';
  AND?: PartnerFilterInput[] | null;
  NOT?: PartnerFilterInput | null;
  OR?: PartnerFilterInput[] | null;
  activeCustomers?: PartnerActiveCustomersFilterInput | null;
  address?: EntityFilterStringField | null;
  agreedToGtac?: EntityFilterNumberField | null;
  city?: EntityFilterStringField | null;
  companiesRepresented?: EntityFilterStringField | null;
  companyFocus?: PartnerCompanyFocusFilterInput | null;
  companySize?: PartnerCompanySizeFilterInput | null;
  country?: EntityFilterStringField | null;
  created?: EntityFilterDateTimeField | null;
  email?: EntityFilterStringField | null;
  firstName?: EntityFilterStringField | null;
  id?: EntityFilterNumberField | null;
  landingPage?: EntityFilterStringField | null;
  lastName?: EntityFilterStringField | null;
  modified?: EntityFilterDateTimeField | null;
  name?: EntityFilterStringField | null;
  phone?: EntityFilterStringField | null;
  postalCode?: EntityFilterStringField | null;
  representativeId?: EntityFilterNumberField | null;
  state?: EntityFilterStringField | null;
  status?: EntityFilterStringField | null;
  targetCustomers?: PartnerTargetCustomersFilterInput | null;
  type?: EntityFilterNumberField | null;
  url?: EntityFilterStringField | null;
  vatcode?: EntityFilterStringField | null;
  yearFounded?: EntityFilterNumberField | null;
}

export interface IdentityProviderOrderByInput {
  __typename?: 'IdentityProviderOrderByInput';
  acceptUntrusted?: OrderByEnum | null;
  description?: OrderByEnum | null;
  enabled?: OrderByEnum | null;
  id?: OrderByEnum | null;
  name?: OrderByEnum | null;
  subscription?: SubscriptionOrderByNestedInput[] | null;
  subscriptionId?: OrderByEnum | null;
  type?: OrderByEnum | null;
  url?: OrderByEnum | null;
  xml?: OrderByEnum | null;
}

export interface IdentityProviderTypeFilterInput {
  __typename?: 'IdentityProviderTypeFilterInput';
  NOT?: IdentityProviderTypeFilterInput | null;
  eq?: IdentityProviderType[] | null;
}

export interface IdentityProviderFilterInput {
  __typename?: 'IdentityProviderFilterInput';
  AND?: IdentityProviderFilterInput[] | null;
  NOT?: IdentityProviderFilterInput | null;
  OR?: IdentityProviderFilterInput[] | null;
  acceptUntrusted?: boolean | null;
  description?: EntityFilterStringField | null;
  enabled?: boolean | null;
  id?: EntityFilterNumberField | null;
  name?: EntityFilterStringField | null;
  subscriptionId?: EntityFilterNumberField | null;
  type?: IdentityProviderTypeFilterInput | null;
  url?: EntityFilterStringField | null;
}

export interface HowToOrderByInput {
  __typename?: 'HowToOrderByInput';
  actionText?: OrderByEnum | null;
  actionUrl?: OrderByEnum | null;
  description?: OrderByEnum | null;
  resourceUrl?: OrderByEnum | null;
  sortOrder?: OrderByEnum | null;
  title?: OrderByEnum | null;
}

export interface HowToTypeFilterInput {
  __typename?: 'HowToTypeFilterInput';
  NOT?: HowToTypeFilterInput | null;
  eq?: HowToType[] | null;
}

export interface HowToCategoryFilterInput {
  __typename?: 'HowToCategoryFilterInput';
  NOT?: HowToCategoryFilterInput | null;
  eq?: HowToCategory[] | null;
}

export interface HowToFilterInput {
  __typename?: 'HowToFilterInput';
  AND?: HowToFilterInput[] | null;
  NOT?: HowToFilterInput | null;
  OR?: HowToFilterInput[] | null;
  actionText?: EntityFilterStringField | null;
  actionUrl?: EntityFilterStringField | null;
  category?: HowToCategoryFilterInput | null;
  description?: EntityFilterStringField | null;
  resourceUrl?: EntityFilterStringField | null;
  sortOrder?: EntityFilterNumberField | null;
  title?: EntityFilterStringField | null;
  type?: HowToTypeFilterInput | null;
}

export interface OrderRelatedDocumentOrderByInput {
  __typename?: 'OrderRelatedDocumentOrderByInput';
  created?: OrderByEnum | null;
  id?: OrderByEnum | null;
  remainingBalance?: OrderByEnum | null;
}

export interface OrderRelatedDocumentFilterInput {
  __typename?: 'OrderRelatedDocumentFilterInput';
  AND?: OrderRelatedDocumentFilterInput[] | null;
  NOT?: OrderRelatedDocumentFilterInput | null;
  OR?: OrderRelatedDocumentFilterInput[] | null;
  created?: EntityFilterDateTimeField | null;
  id?: EntityFilterNumberField | null;
  remainingBalance?: EntityFilterNumberField | null;
}

export interface OrderHistoryOrderByInput {
  __typename?: 'OrderHistoryOrderByInput';
  comment?: OrderByEnum | null;
  created?: OrderByEnum | null;
  id?: OrderByEnum | null;
  orderId?: OrderByEnum | null;
}

export interface EntityFilterDateTimeField {
  __typename?: 'EntityFilterDateTimeField';
  between?: DateTime[] | null;
  eq?: string[] | null;
  gt?: DateTime | null;
  gte?: DateTime | null;
  lt?: DateTime | null;
  lte?: DateTime | null;
  null?: boolean | null;
}

export interface OrderHistoryFilterInput {
  __typename?: 'OrderHistoryFilterInput';
  AND?: OrderHistoryFilterInput[] | null;
  NOT?: OrderHistoryFilterInput | null;
  OR?: OrderHistoryFilterInput[] | null;
  comment?: EntityFilterStringField | null;
  created?: EntityFilterDateTimeField | null;
  id?: EntityFilterNumberField | null;
  orderId?: EntityFilterNumberField | null;
}

export interface SubscriptionOrderByNestedInput {
  __typename?: 'SubscriptionOrderByNestedInput';
  agreedToGtac?: OrderByEnum | null;
  anniversaryDate?: OrderByEnum | null;
  billingCountryName?: OrderByEnum | null;
  classification?: OrderByEnum | null;
  contactEmail?: OrderByEnum | null;
  created?: OrderByEnum | null;
  crmAccountId?: OrderByEnum | null;
  customPrice?: OrderByEnum | null;
  discount?: OrderByEnum | null;
  endDate?: OrderByEnum | null;
  fromCampaign?: OrderByEnum | null;
  id?: OrderByEnum | null;
  lastVatCheckDate?: OrderByEnum | null;
  licensesPurchased?: OrderByEnum | null;
  licensesUsed?: OrderByEnum | null;
  manualInstructions?: OrderByEnum | null;
  manualOrderDunning?: OrderByEnum | null;
  manuallyHandled?: OrderByEnum | null;
  name?: OrderByEnum | null;
  numberOfSalesPeople?: OrderByEnum | null;
  partnerName?: OrderByEnum | null;
  paymentTermsId?: OrderByEnum | null;
  referralPartnerId?: OrderByEnum | null;
  representativeEmail?: OrderByEnum | null;
  representativeId?: OrderByEnum | null;
  representativeName?: OrderByEnum | null;
  requiredIdpId?: OrderByEnum | null;
  sdrEmail?: OrderByEnum | null;
  sdrId?: OrderByEnum | null;
  sdrName?: OrderByEnum | null;
  startDate?: OrderByEnum | null;
  status?: OrderByEnum | null;
  supplierId?: OrderByEnum | null;
  tier?: OrderByEnum | null;
  vatCheckResult?: OrderByEnum | null;
  vatCode?: OrderByEnum | null;
}

export interface TeamSpaceOrderByInput {
  __typename?: 'TeamSpaceOrderByInput';
  dbVersion?: OrderByEnum | null;
  flags?: OrderByEnum | null;
  id?: OrderByEnum | null;
  name?: OrderByEnum | null;
  regionName?: OrderByEnum | null;
  size?: OrderByEnum | null;
  status?: OrderByEnum | null;
  subscription?: SubscriptionOrderByNestedInput[] | null;
  subscriptionId?: OrderByEnum | null;
  version?: OrderByEnum | null;
}

export interface TeamSpaceStatusReasonFilterInput {
  __typename?: 'TeamSpaceStatusReasonFilterInput';
  NOT?: TeamSpaceStatusReasonFilterInput | null;
  eq?: TeamSpaceStatusReason[] | null;
}

export interface TeamSpaceStatusFilterInput {
  __typename?: 'TeamSpaceStatusFilterInput';
  NOT?: TeamSpaceStatusFilterInput | null;
  eq?: TeamSpaceStatus[] | null;
}

export interface TeamSpaceFlagsFilterInput {
  __typename?: 'TeamSpaceFlagsFilterInput';
  NOT?: TeamSpaceFlagsFilterInput | null;
  eq?: TeamSpaceFlags[] | null;
}

export interface TeamSpaceFilterInput {
  __typename?: 'TeamSpaceFilterInput';
  AND?: TeamSpaceFilterInput[] | null;
  NOT?: TeamSpaceFilterInput | null;
  OR?: TeamSpaceFilterInput[] | null;
  dbVersion?: EntityFilterNumberField | null;
  flags?: TeamSpaceFlagsFilterInput | null;
  id?: EntityFilterStringField | null;
  name?: EntityFilterStringField | null;
  pictureUrl?: EntityFilterStringField | null;
  regionName?: string | null;
  size?: EntityFilterNumberField | null;
  status?: TeamSpaceStatusFilterInput | null;
  statusReason?: TeamSpaceStatusReasonFilterInput | null;
  subscriptionId?: EntityFilterNumberField | null;
  version?: EntityFilterNumberField | null;
}

export interface CpClientOrderByInput {
  __typename?: 'CpClientOrderByInput';
  email?: OrderByEnum | null;
  id?: OrderByEnum | null;
}

export interface CpClientFilterInput {
  __typename?: 'CpClientFilterInput';
  AND?: CpClientFilterInput[] | null;
  NOT?: CpClientFilterInput | null;
  OR?: CpClientFilterInput[] | null;
  email?: EntityFilterStringField | null;
  id?: EntityFilterNumberField | null;
}

export interface AutomationHubCategoryOrderByNestedInput {
  __typename?: 'AutomationHubCategoryOrderByNestedInput';
  id?: OrderByEnum | null;
  isDeleted?: OrderByEnum | null;
  name?: OrderByEnum | null;
}

export interface AutomationHubAppOrderByNestedInput {
  __typename?: 'AutomationHubAppOrderByNestedInput';
  id?: OrderByEnum | null;
  isDeleted?: OrderByEnum | null;
  logoUrl?: OrderByEnum | null;
  name?: OrderByEnum | null;
  supportPrivacyUrl?: OrderByEnum | null;
  supportTermsUrl?: OrderByEnum | null;
  supportUrl?: OrderByEnum | null;
  websiteUrl?: OrderByEnum | null;
}

export interface AutomationHubItemOrderByInput {
  __typename?: 'AutomationHubItemOrderByInput';
  aclPermissionKey?: OrderByEnum | null;
  automationHubApp?: AutomationHubAppOrderByNestedInput[] | null;
  automationHubAppId?: OrderByEnum | null;
  automationHubCategory?: AutomationHubCategoryOrderByNestedInput[] | null;
  automationHubCategoryId?: OrderByEnum | null;
  configurationUrl?: OrderByEnum | null;
  deprecatedByItemId?: OrderByEnum | null;
  description?: OrderByEnum | null;
  hasLastRun?: OrderByEnum | null;
  hasLogs?: OrderByEnum | null;
  id?: OrderByEnum | null;
  isBeta?: OrderByEnum | null;
  isBundle?: OrderByEnum | null;
  isDeleted?: OrderByEnum | null;
  logoUrl?: OrderByEnum | null;
  minApiVersion?: OrderByEnum | null;
  name?: OrderByEnum | null;
  ranking?: OrderByEnum | null;
  websiteUrl?: OrderByEnum | null;
}

export interface AutomationHubTierEnumFilterInput {
  __typename?: 'AutomationHubTierEnumFilterInput';
  NOT?: AutomationHubTierEnumFilterInput | null;
  eq?: AutomationHubTierEnum[] | null;
}

export interface AutomationHubScopeFilterInput {
  __typename?: 'AutomationHubScopeFilterInput';
  NOT?: AutomationHubScopeFilterInput | null;
  eq?: AutomationHubScope[] | null;
}

export interface AutomationHubItemFilterInput {
  __typename?: 'AutomationHubItemFilterInput';
  AND?: AutomationHubItemFilterInput[] | null;
  NOT?: AutomationHubItemFilterInput | null;
  OR?: AutomationHubItemFilterInput[] | null;
  aclPermissionKey?: EntityFilterStringField | null;
  automationHubAppId?: EntityFilterNumberField | null;
  automationHubCategoryId?: EntityFilterNumberField | null;
  configurationUrl?: EntityFilterStringField | null;
  deprecatedByItemId?: EntityFilterNumberField | null;
  description?: EntityFilterStringField | null;
  hasLastRun?: boolean | null;
  hasLogs?: boolean | null;
  id?: EntityFilterNumberField | null;
  isBeta?: boolean | null;
  isBundle?: boolean | null;
  isDeleted?: boolean | null;
  logoUrl?: EntityFilterStringField | null;
  minApiVersion?: EntityFilterNumberField | null;
  name?: EntityFilterStringField | null;
  ranking?: EntityFilterNumberField | null;
  scope?: AutomationHubScopeFilterInput | null;
  tier?: AutomationHubTierEnumFilterInput | null;
  websiteUrl?: EntityFilterStringField | null;
}

export interface AutomationHubCategoryOrderByInput {
  __typename?: 'AutomationHubCategoryOrderByInput';
  id?: OrderByEnum | null;
  isDeleted?: OrderByEnum | null;
  name?: OrderByEnum | null;
}

export interface AutomationHubCategoryFilterInput {
  __typename?: 'AutomationHubCategoryFilterInput';
  AND?: AutomationHubCategoryFilterInput[] | null;
  NOT?: AutomationHubCategoryFilterInput | null;
  OR?: AutomationHubCategoryFilterInput[] | null;
  id?: EntityFilterNumberField | null;
  isDeleted?: boolean | null;
  name?: EntityFilterStringField | null;
}

export interface AutomationHubAppOrderByInput {
  __typename?: 'AutomationHubAppOrderByInput';
  id?: OrderByEnum | null;
  isDeleted?: OrderByEnum | null;
  logoUrl?: OrderByEnum | null;
  name?: OrderByEnum | null;
  supportPrivacyUrl?: OrderByEnum | null;
  supportTermsUrl?: OrderByEnum | null;
  supportUrl?: OrderByEnum | null;
  websiteUrl?: OrderByEnum | null;
}

export interface EntityFilterStringField {
  __typename?: 'EntityFilterStringField';
  contains?: string[] | null;
  empty?: boolean | null;
  ends?: string[] | null;
  eq?: string[] | null;
  icontains?: string[] | null;
  iends?: string[] | null;
  ieq?: string[] | null;
  istarts?: string[] | null;
  null?: boolean | null;
  starts?: string[] | null;
}

export interface EntityFilterNumberField {
  __typename?: 'EntityFilterNumberField';
  between?: number[] | null;
  eq?: string[] | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  null?: boolean | null;
}

export interface AutomationHubAppFilterInput {
  __typename?: 'AutomationHubAppFilterInput';
  AND?: AutomationHubAppFilterInput[] | null;
  NOT?: AutomationHubAppFilterInput | null;
  OR?: AutomationHubAppFilterInput[] | null;
  id?: EntityFilterNumberField | null;
  isDeleted?: boolean | null;
  logoUrl?: EntityFilterStringField | null;
  name?: EntityFilterStringField | null;
  supportPrivacyUrl?: EntityFilterStringField | null;
  supportTermsUrl?: EntityFilterStringField | null;
  supportUrl?: EntityFilterStringField | null;
  websiteUrl?: EntityFilterStringField | null;
}

export interface TeamPipelineHubItemEntity extends TeamPipelineHubInterface {
  __typename?: 'TeamPipelineHubItemEntity';
  configurationUrlFormatted: string | null;
  id: ID;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: LicEntityPermsEnum[];
  isEnabled: boolean;
  status: TeamPipelineHubItemStatusEnum;
  teamSpace: TeamSpaceEntity | null;
  teamSpaceId: string;
}

export interface UpdateUserSettingPayload  {
  __typename?: 'UpdateUserSettingPayload';
  clientMutationId: string | null;
}

export interface UpdateUserProfilePayload  {
  __typename?: 'UpdateUserProfilePayload';
  clientMutationId: string | null;
  userProfile: UserProfileEntity | null;
}

export interface UpdateTeamSpaceNamePayload  {
  __typename?: 'UpdateTeamSpaceNamePayload';
  clientMutationId: string | null;
  result: TeamSpaceEntity | null;
}

export interface UpdateTeamSpacePayload  {
  __typename?: 'UpdateTeamSpacePayload';
  clientMutationId: string | null;
  teamSpace: TeamSpaceEntity | null;
}

export interface UpdateSubscriptionHubItemPayload  {
  __typename?: 'UpdateSubscriptionHubItemPayload';
  clientMutationId: string | null;
  subscriptionHubItem: SubscriptionHubItemEntity | null;
}

export interface UpdateAutomationHubItemPayload  {
  __typename?: 'UpdateAutomationHubItemPayload';
  automationHubItem: AutomationHubItemEntity | null;
  clientMutationId: string | null;
}

export interface UpdateAutomationHubCategoryPayload  {
  __typename?: 'UpdateAutomationHubCategoryPayload';
  automationHubCategory: AutomationHubCategoryEntity | null;
  clientMutationId: string | null;
}

export interface UpdateAutomationHubAppPayload  {
  __typename?: 'UpdateAutomationHubAppPayload';
  automationHubApp: AutomationHubAppEntity | null;
  clientMutationId: string | null;
}

export interface ToggleStateTeamPipelineHubItemPayload  {
  __typename?: 'ToggleStateTeamPipelineHubItemPayload';
  clientMutationId: string | null;
  result: TeamPipelineHubInterfaceImplementors | null;
}

export interface TakeOverTeamSpacePayload  {
  __typename?: 'TakeOverTeamSpacePayload';
  clientMutationId: string | null;
  result: boolean | null;
}

export interface SuspendTeamSpacePayload  {
  __typename?: 'SuspendTeamSpacePayload';
  clientMutationId: string | null;
  result: TeamSpaceEntity | null;
}

export interface SetUserProfilePicturePayload  {
  __typename?: 'SetUserProfilePicturePayload';
  clientMutationId: string | null;
  result: UserProfileEntity | null;
}

export interface SetTeamSpacePicturePayload  {
  __typename?: 'SetTeamSpacePicturePayload';
  clientMutationId: string | null;
  result: TeamSpaceEntity | null;
}

export interface SetAsFailedAutomationHubItemPayload  {
  __typename?: 'SetAsFailedAutomationHubItemPayload';
  clientMutationId: string | null;
  result: AutomationHubItemEntity | null;
}

export interface ScheduleHubspotInitSyncPayload  {
  __typename?: 'ScheduleHubspotInitSyncPayload';
  clientMutationId: string | null;
  result: boolean | null;
}

export interface ResetTeamSpacePayload  {
  __typename?: 'ResetTeamSpacePayload';
  clientMutationId: string | null;
  result: TeamSpaceEntity | null;
}

export interface RequestRemoveAutomationHubItemPayload  {
  __typename?: 'RequestRemoveAutomationHubItemPayload';
  clientMutationId: string | null;
  result: AutomationHubItemEntity | null;
}

export interface RequestCustomSolutionAutomationHubItemPayload  {
  __typename?: 'RequestCustomSolutionAutomationHubItemPayload';
  clientMutationId: string | null;
}

export interface RequestAddAutomationHubItemPayload  {
  __typename?: 'RequestAddAutomationHubItemPayload';
  clientMutationId: string | null;
  result: AutomationHubItemEntity | null;
}

export interface ReportErrorPayload  {
  __typename?: 'ReportErrorPayload';
  clientMutationId: string | null;
}

export interface RemoveUserProfilePicturePayload  {
  __typename?: 'RemoveUserProfilePicturePayload';
  clientMutationId: string | null;
  result: UserProfileEntity | null;
}

export interface RemoveTeamSpacePicturePayload  {
  __typename?: 'RemoveTeamSpacePicturePayload';
  clientMutationId: string | null;
  result: TeamSpaceEntity | null;
}

export interface RemoveAutomationHubItemPayload  {
  __typename?: 'RemoveAutomationHubItemPayload';
  clientMutationId: string | null;
  result: AutomationHubItemEntity | null;
}

export interface RegisterOauth2ClientMutationPayload  {
  __typename?: 'RegisterOauth2ClientMutationPayload';
  automationHubAppId: number | null;
  automationHubItemId: number | null;
  clientId: string | null;
  clientMutationId: string | null;
  clientSecret: string | null;
}

export interface MoveTeamSpacePayload  {
  __typename?: 'MoveTeamSpacePayload';
  clientMutationId: string | null;
  result: boolean | null;
}

export interface ImportServicesDataPayload  {
  __typename?: 'ImportServicesDataPayload';
  clientMutationId: string | null;
  result: boolean | null;
}

export interface FinishMigrationPayload  {
  __typename?: 'FinishMigrationPayload';
  clientMutationId: string | null;
  result: TeamSpaceEntity | null;
}

export interface FinalizeConfigurationAutomationHubItemPayload  {
  __typename?: 'FinalizeConfigurationAutomationHubItemPayload';
  clientMutationId: string | null;
  result: AutomationHubItemEntity | null;
}

export interface DropTeamSpacePayload  {
  __typename?: 'DropTeamSpacePayload';
  clientMutationId: string | null;
}

export interface DiagnoseTeamSpacePayload  {
  __typename?: 'DiagnoseTeamSpacePayload';
  clientMutationId: string | null;
  result: string[] | null;
}

export interface DeleteSubscriptionHubItemPayload  {
  __typename?: 'DeleteSubscriptionHubItemPayload';
  clientMutationId: string | null;
  subscriptionHubItem: SubscriptionHubItemEntity | null;
}

export interface DeleteAutomationHubItemPayload  {
  __typename?: 'DeleteAutomationHubItemPayload';
  automationHubItem: AutomationHubItemEntity | null;
  clientMutationId: string | null;
}

export interface DeleteAutomationHubCategoryPayload  {
  __typename?: 'DeleteAutomationHubCategoryPayload';
  automationHubCategory: AutomationHubCategoryEntity | null;
  clientMutationId: string | null;
}

export interface DeleteAutomationHubAppPayload  {
  __typename?: 'DeleteAutomationHubAppPayload';
  automationHubApp: AutomationHubAppEntity | null;
  clientMutationId: string | null;
}

export interface CreateTeamSpaceDatabaseTeamSpacePayload  {
  __typename?: 'CreateTeamSpaceDatabaseTeamSpacePayload';
  clientMutationId: string | null;
  result: TeamSpaceEntity | null;
}

export interface CreateTeamSpacePayload  {
  __typename?: 'CreateTeamSpacePayload';
  clientMutationId: string | null;
  result: TeamSpaceEntity | null;
}

export interface CreateSubscriptionHubItemPayload  {
  __typename?: 'CreateSubscriptionHubItemPayload';
  clientMutationId: string | null;
  subscriptionHubItem: SubscriptionHubItemEntity | null;
}

export interface CreateAutomationHubItemPayload  {
  __typename?: 'CreateAutomationHubItemPayload';
  automationHubItem: AutomationHubItemEntity | null;
  clientMutationId: string | null;
}

export interface CreateAutomationHubCategoryPayload  {
  __typename?: 'CreateAutomationHubCategoryPayload';
  automationHubCategory: AutomationHubCategoryEntity | null;
  clientMutationId: string | null;
}

export interface CreateAutomationHubAppPayload  {
  __typename?: 'CreateAutomationHubAppPayload';
  automationHubApp: AutomationHubAppEntity | null;
  clientMutationId: string | null;
}

export interface CopyTeamSpacePayload  {
  __typename?: 'CopyTeamSpacePayload';
  clientMutationId: string | null;
  result: TeamSpaceEntity | null;
}

export interface ConfigureTeamPipelineHubItemPayload  {
  __typename?: 'ConfigureTeamPipelineHubItemPayload';
  clientMutationId: string | null;
  result: TeamPipelineHubInterfaceImplementors | null;
}

export interface ConfigureSyncIntegrationTeamPipelineHubItemPayload  {
  __typename?: 'ConfigureSyncIntegrationTeamPipelineHubItemPayload';
  clientMutationId: string | null;
  result: TeamPipelineHubInterfaceImplementors | null;
}

export interface CleanupTrialMonitoringPayload  {
  __typename?: 'CleanupTrialMonitoringPayload';
  clientMutationId: string | null;
}

export interface ChangePasswordAndIssueTokensOutput  {
  __typename?: 'ChangePasswordAndIssueTokensOutput';
  refreshToken: string | null;
  result: boolean;
  token: string | null;
}

export interface ChangeUserProfilePasswordPayload  {
  __typename?: 'ChangeUserProfilePasswordPayload';
  clientMutationId: string | null;
  result: boolean | null;
}

export interface AddAutomationHubItemPayload  {
  __typename?: 'AddAutomationHubItemPayload';
  clientMutationId: string | null;
  result: AutomationHubItemEntity | null;
}

export interface ActivateTeamSpacePayload  {
  __typename?: 'ActivateTeamSpacePayload';
  clientMutationId: string | null;
  result: TeamSpaceEntity | null;
}

export interface Subscription  {
  __typename?: 'Subscription';
  admins: UserProfileEntity[] | null;
  classification: SubscriptionClassification;
  domain: string;
  endDate: GqlDate | null;
  expiredDate: GqlDate | null;
  id: ID;
  isTrial: boolean;
  licensesPurchased: number;
  licensesUsed: number;
  mostDuePaymentDate: GqlDate | null;
  name: string;
  productTier: SubscriptionProductTier;
  representativeEmail: string | null;
  startDate: GqlDate | null;
}

export interface FinancialDateStart  {
  __typename?: 'FinancialDateStart';
  day: number;
  month: number;
}

export interface AttachmentFileExtension  {
  __typename?: 'AttachmentFileExtension';
  denied: boolean;
  list: string[];
}

export interface SpaceSettings  {
  __typename?: 'SpaceSettings';
  attachmentFileExtensions: AttachmentFileExtension;
  attachmentMaxSize: number;
  chunkSizeExchange: number;
  cloudEnabled: boolean;
  dynamicFinancialYear: FinancialDateStart;
  emailAttachmentEnabled: boolean;
  emailAttachmentMaxSize: number;
  emailDomainBlacklisting: string[] | null;
  fileAttachmentUploadEnabled: boolean | null;
  firstDayOfWeek: DayName;
  intercomIntegration: boolean | null;
  sharepointIntegration: string | null;
  timezone: string;
}

export interface Space  {
  __typename?: 'Space';
  accessStatus: SpaceAccessStatus;
  appBaseUrl: string | null;
  appVersion: string;
  bccMailto: string;
  clientId: ID | null;
  graphqlUrl: string;
  id: ID;
  idp: IdentityProviderEntity | null;
  isSuspended: boolean;
  name: string;
  pictureUrl: string | null;
  regionUrl: string;
  restUrl: string;
  settings: SpaceSettings;
  size: number;
  sizeUsed: number;
  socketioUrl: string;
  subscription: Subscription;
  url: string;
  version: number;
}

export interface UserSettings  {
  __typename?: 'UserSettings';
  autoCreateAccount: boolean | null;
  autoCreateContact: boolean | null;
  autoHighlightFieldValue: boolean | null;
  autoLinkAccount: boolean | null;
  autoPopulate: boolean | null;
  bccPipelineEmail: boolean | null;
  defaultAppointmentDuration: number | null;
  defaultAppointmentReminder: number | null;
  defaultTaskDueDate: number | null;
  defaultTaskReminder: number | null;
  defaultTaskReminderDayOffset: number | null;
  defaultTaskReminderDueDate: number | null;
  defaultTaskReminderTimeOffset: number | null;
  emailHandlerInNewTab: boolean | null;
  emailSignature: string | null;
  firstWeekDay: DayName | null;
  language: string | null;
  locale: string | null;
  nameFormat: NameFormat | null;
  navigatorHints: boolean | null;
  salespopWidgetCategories: string | null;
  salespopWidgetClosedDate: GqlDate | null;
  salespopWidgetLastArticleDate: string | null;
  salespopWidgetUnsubscribed: boolean | null;
  showFollowActionMessage: boolean | null;
  showReminderNotifications: boolean | null;
  workWeekFrom: DayName | null;
  workWeekTo: DayName | null;
}

export interface HubspotPortalId  {
  __typename?: 'HubspotPortalId';
  portalId: ID;
  spaceId: ID;
}

export interface GeoLocationEntity  {
  __typename?: 'GeoLocationEntity';
  address: string | null;
  city: string | null;
  country: string | null;
  lat: number;
  lng: number;
  placeId: string;
  searchKey: string;
  state: string | null;
  zipCode: string | null;
}

export interface UserProfileEntityEdge  {
  __typename?: 'UserProfileEntityEdge';
  cursor: string;
  node: UserProfileEntity | null;
}

export interface UserProfileEntityConnection  {
  __typename?: 'UserProfileEntityConnection';
  edges: UserProfileEntityEdge[];
  pageInfo: PageInfo;
  totalCount: number | null;
}

export interface UserProfileRepository  {
  __typename?: 'UserProfileRepository';
  getAll: UserProfileEntity[];
  getByCriteria: UserProfileEntityConnection | null;
  getById: UserProfileEntity | null;
  getByIds: UserProfileEntity[];
}

export interface TeamSpaceClientEntityEdge  {
  __typename?: 'TeamSpaceClientEntityEdge';
  cursor: string;
  node: TeamSpaceClientEntity | null;
}

export interface TeamSpaceClientEntityConnection  {
  __typename?: 'TeamSpaceClientEntityConnection';
  edges: TeamSpaceClientEntityEdge[];
  pageInfo: PageInfo;
  totalCount: number | null;
}

export interface TeamSpaceClientEntity  {
  __typename?: 'TeamSpaceClientEntity';
  created: DateTime | null;
  dbGuid: string | null;
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: LicEntityPermsEnum[];
  isDeleted: boolean | null;
  modified: DateTime | null;
  teamSpace: TeamSpaceEntity | null;
  teamSpaceId: ID | null;
  userProfile: UserProfileEntity | null;
  userProfileId: ID | null;
}

export interface TeamSpaceClientRepository  {
  __typename?: 'TeamSpaceClientRepository';
  getAll: TeamSpaceClientEntity[];
  getByCriteria: TeamSpaceClientEntityConnection | null;
  getByEmail: TeamSpaceClientEntity | null;
  getById: TeamSpaceClientEntity | null;
  getByIds: TeamSpaceClientEntity[];
}

export interface TeamSpaceRepository  {
  __typename?: 'TeamSpaceRepository';
  getAll: TeamSpaceEntity[];
  getByCriteria: TeamSpaceEntityConnection | null;
  getById: TeamSpaceEntity | null;
  getByIds: TeamSpaceEntity[];
}

export interface SubscriptionHubItemEntityEdge  {
  __typename?: 'SubscriptionHubItemEntityEdge';
  cursor: string;
  node: SubscriptionHubItemEntity | null;
}

export interface SubscriptionHubItemEntityConnection  {
  __typename?: 'SubscriptionHubItemEntityConnection';
  edges: SubscriptionHubItemEntityEdge[];
  pageInfo: PageInfo;
  totalCount: number | null;
}

export interface SubscriptionHubItemEntity  {
  __typename?: 'SubscriptionHubItemEntity';
  automationHubItem: AutomationHubItemEntity | null;
  automationHubItemId: number | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: LicEntityPermsEnum[];
  subscription: SubscriptionEntity | null;
  subscriptionId: number | null;
}

export interface SubscriptionHubItemRepository  {
  __typename?: 'SubscriptionHubItemRepository';
  getAll: SubscriptionHubItemEntity[];
  getByCriteria: SubscriptionHubItemEntityConnection | null;
  getById: SubscriptionHubItemEntity | null;
  getByIds: SubscriptionHubItemEntity[];
}

export interface SubscriptionEntityEdge  {
  __typename?: 'SubscriptionEntityEdge';
  cursor: string;
  node: SubscriptionEntity | null;
}

export interface SubscriptionEntityConnection  {
  __typename?: 'SubscriptionEntityConnection';
  edges: SubscriptionEntityEdge[];
  pageInfo: PageInfo;
  totalCount: number | null;
}

export interface SubscriptionRepository  {
  __typename?: 'SubscriptionRepository';
  getAdministeredByMe: SubscriptionEntityConnection | null;
  getAll: SubscriptionEntity[];
  getByCriteria: SubscriptionEntityConnection | null;
  getById: SubscriptionEntity | null;
  getByIds: SubscriptionEntity[];
}

export interface PartnerEntityEdge  {
  __typename?: 'PartnerEntityEdge';
  cursor: string;
  node: PartnerEntity | null;
}

export interface PartnerEntityConnection  {
  __typename?: 'PartnerEntityConnection';
  edges: PartnerEntityEdge[];
  pageInfo: PageInfo;
  totalCount: number | null;
}

export interface PartnerRepository  {
  __typename?: 'PartnerRepository';
  getAll: PartnerEntity[];
  getByCriteria: PartnerEntityConnection | null;
  getById: PartnerEntity | null;
  getByIds: PartnerEntity[];
}

export interface IdentityProviderEntityEdge  {
  __typename?: 'IdentityProviderEntityEdge';
  cursor: string;
  node: IdentityProviderEntity | null;
}

export interface IdentityProviderEntityConnection  {
  __typename?: 'IdentityProviderEntityConnection';
  edges: IdentityProviderEntityEdge[];
  pageInfo: PageInfo;
  totalCount: number | null;
}

export interface IdentityProviderRepository  {
  __typename?: 'IdentityProviderRepository';
  getAll: IdentityProviderEntity[];
  getByCriteria: IdentityProviderEntityConnection | null;
  getById: IdentityProviderEntity | null;
  getByIds: IdentityProviderEntity[];
}

export interface HowToEntityEdge  {
  __typename?: 'HowToEntityEdge';
  cursor: string;
  node: HowToEntity | null;
}

export interface HowToEntityConnection  {
  __typename?: 'HowToEntityConnection';
  edges: HowToEntityEdge[];
  pageInfo: PageInfo;
  totalCount: number | null;
}

export interface HowToEntity  {
  __typename?: 'HowToEntity';
  actionText: string | null;
  actionUrl: string | null;
  category: HowToCategory | null;
  description: string | null;
  id: string | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: BasicLicEntityPermsEnum[];
  resourceUrl: string | null;
  sortOrder: number | null;
  title: string | null;
  type: HowToType | null;
}

export interface HowToRepository  {
  __typename?: 'HowToRepository';
  getAll: HowToEntity[];
  getByCriteria: HowToEntityConnection | null;
  getById: HowToEntity | null;
  getByIds: HowToEntity[];
}

export interface AutomationHubItemEntityEdge  {
  __typename?: 'AutomationHubItemEntityEdge';
  cursor: string;
  node: AutomationHubItemEntity | null;
}

export interface AutomationHubItemEntityConnection  {
  __typename?: 'AutomationHubItemEntityConnection';
  edges: AutomationHubItemEntityEdge[];
  pageInfo: PageInfo;
  totalCount: number | null;
}

export interface SubscriptionRepresentativeEntity  {
  __typename?: 'SubscriptionRepresentativeEntity';
  email: string | null;
  firstName: string | null;
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: ReadOnlyPermsEnum[];
  isDeleted: boolean | null;
  isValid: boolean | null;
  lastName: string | null;
  middleName: string | null;
  name: string | null;
}

export interface NotificationSettingEntity  {
  __typename?: 'NotificationSettingEntity';
  created: DateTime | null;
  daysAfterPaymentDue: number | null;
  daysPriorEnd: number | null;
  emailBody: string | null;
  emailSubject: string | null;
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: NotificationSettingPermsEnum[];
  modified: DateTime | null;
  name: string | null;
  serviceType: NotificationSettingService | null;
  status: NotificationSettingStatus | null;
  type: NotificationSettingType | null;
}

export interface NotificationRenewal  {
  __typename?: 'NotificationRenewal';
  id: string;
  instancePermissions: NotificationRenewalPermsEnum[];
  isProcessed: boolean;
  notificationSetting: NotificationSettingEntity;
  processedDate: GqlDate;
}

export interface RegionEntity  {
  __typename?: 'RegionEntity';
  code: string | null;
  currency: string | null;
  currencySymbol: string | null;
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: ReadOnlyPermsEnum[];
  name: number | null;
  unitPrice: number | null;
  unitPriceBasic: number | null;
  unitPriceEnterprise: number | null;
  unitPriceStarter: number | null;
  unitPriceUnlimited: number | null;
}

export interface UserRoleEntity  {
  __typename?: 'UserRoleEntity';
  assigmentPossibilities: boolean | null;
  auditAccess: boolean | null;
  dunningServiceAccess: boolean | null;
  dunningServiceChange: boolean | null;
  dunningServiceEmailRecipient: boolean | null;
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: UserRolePermsEnum[];
  isDeleteProtected: boolean | null;
  kpiDashboardAccess: boolean | null;
  name: string | null;
  notificationRenewalServiceAccess: boolean | null;
  notificationRenewalServiceChange: boolean | null;
  notificationRenewalServiceEmailRecipient: boolean | null;
  orderManagementAccess: boolean | null;
  orderManagementCancel: boolean | null;
  orderManagementChange: boolean | null;
  orderManagementConfirm: boolean | null;
  orderManagementDelete: boolean | null;
  orderManagementDeleteConfirmation: boolean | null;
  partnerAccess: boolean | null;
  partnerAssignment: boolean | null;
  partnerChange: boolean | null;
  partnerDisable: boolean | null;
  paymentTermsManagement: boolean | null;
  sapiUserManagementAccess: boolean | null;
  sapiUserManagementChange: boolean | null;
  sapiUserManagementDelete: boolean | null;
  subscriptionAssignment: boolean | null;
  subscriptionSdr: boolean | null;
  subscriptionSpaceAdminView: boolean | null;
  subscriptionSpaceCancel: boolean | null;
  subscriptionSpaceChange: boolean | null;
  subscriptionSpaceModulesManagement: boolean | null;
  systemMaintenance: boolean | null;
  userManagementAccess: boolean | null;
  userManagementChange: boolean | null;
  userManagementDefineRoles: boolean | null;
  userManagementDisable: boolean | null;
}

export interface UserProfileEntity  {
  __typename?: 'UserProfileEntity';
  administrator: boolean | null;
  availableIdentityProviders: IdentityProviderEntity[] | null;
  businessFunction: string | null;
  city: string | null;
  company: string | null;
  country: string | null;
  created: DateTime | null;
  currentIdp: IdentityProviderEntity | null;
  defaultIdp: IdentityProviderEntity | null;
  defaultIdpId: number | null;
  email: string | null;
  firstName: string | null;
  firstname: string | null;
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: UserProfilePermsEnum[];
  intercomUserHash: string | null;
  isDeleted: boolean | null;
  language: UserLanguage | null;
  lastLogin: DateTime | null;
  lastName: string | null;
  lastname: string | null;
  locale: string | null;
  middleName: string | null;
  middlename: string | null;
  modified: DateTime | null;
  name: string | null;
  newsletter: boolean | null;
  partnerId: number | null;
  passwordChangedAt: DateTime | null;
  pictureUrl: string | null;
  spaces: TeamSpaceEntity[] | null;
  stateProvince: string | null;
  subscriptions: SubscriptionEntity[] | null;
  telephone: string | null;
  timezone: number | null;
  timezoneAutodetect: boolean | null;
  timezoneName: string | null;
  userRole: UserRoleEntity | null;
  userRoleId: number | null;
}

export interface PartnerEntity  {
  __typename?: 'PartnerEntity';
  activeCustomers: PartnerActiveCustomers | null;
  address: string | null;
  agreedToGtac: number | null;
  city: string | null;
  companiesRepresented: string | null;
  companyFocus: PartnerCompanyFocus | null;
  companySize: PartnerCompanySize | null;
  country: string | null;
  created: DateTime | null;
  email: string | null;
  firstName: string | null;
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: BasicLicEntityPermsEnum[];
  landingPage: string | null;
  lastName: string | null;
  modified: DateTime | null;
  name: string | null;
  phone: string | null;
  postalCode: string | null;
  representative: UserProfileEntity | null;
  representativeId: ID | null;
  state: string | null;
  status: string | null;
  targetCustomers: PartnerTargetCustomers | null;
  type: number | null;
  url: string | null;
  vatcode: string | null;
  yearFounded: number | null;
}

export interface Imprint  {
  __typename?: 'Imprint';
  companyNumber: string;
  description: string;
  email: string;
  phone: string;
  vatCode: string;
  web: string;
}

export interface SupplierEntity  {
  __typename?: 'SupplierEntity';
  id: ID | null;
  imprint: Imprint | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: ReadOnlyPermsEnum[];
  name: string | null;
}

export interface OrderRelatedDocumentEntity  {
  __typename?: 'OrderRelatedDocumentEntity';
  amount: string | null;
  created: DateTime | null;
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: OrderRelatedDocumentPermsEnum[];
  invoice: Invoice | null;
  number: string | null;
  remainingBalance: number | null;
  type: OrderRelatedDocumentTypeEnum | null;
}

export interface OrderRelatedDocumentEntityEdge  {
  __typename?: 'OrderRelatedDocumentEntityEdge';
  cursor: string;
  node: OrderRelatedDocumentEntity | null;
}

export interface OrderRelatedDocumentEntityConnection  {
  __typename?: 'OrderRelatedDocumentEntityConnection';
  edges: OrderRelatedDocumentEntityEdge[];
  pageInfo: PageInfo;
  totalCount: number | null;
}

export interface OrderHistoryEntity  {
  __typename?: 'OrderHistoryEntity';
  comment: string | null;
  created: DateTime | null;
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: ReadOnlyPermsEnum[];
  orderId: number | null;
  status: OrderHistoryStatusEnum | null;
}

export interface OrderHistoryEntityEdge  {
  __typename?: 'OrderHistoryEntityEdge';
  cursor: string;
  node: OrderHistoryEntity | null;
}

export interface OrderHistoryEntityConnection  {
  __typename?: 'OrderHistoryEntityConnection';
  edges: OrderHistoryEntityEdge[];
  pageInfo: PageInfo;
  totalCount: number | null;
}

export interface FeeItem  {
  __typename?: 'FeeItem';
  comment: string | null;
  discount: number | null;
  name: string;
  price: string;
  quantity: number;
  sku: string | null;
  subtotal: string;
  unitPrice: number;
}

export interface Invoice  {
  __typename?: 'Invoice';
  html: string;
  id: string;
}

export interface Issuer  {
  __typename?: 'Issuer';
  id: ID;
  name: string | null;
}

export interface OrderEntity  {
  __typename?: 'OrderEntity';
  action: OrderActionEnum | null;
  amount: string | null;
  created: DateTime | null;
  createdBy: Issuer | null;
  createdByUserId: string | null;
  currency: string | null;
  extrasIncluded: boolean | null;
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: OrderPermsEnum[];
  invoice: Invoice | null;
  items: FeeItem[] | null;
  licensesCount: number | null;
  mrrComment: string | null;
  mrrCount: number | null;
  mrrIsCounted: boolean | null;
  mrrMonthsCount: number | null;
  mrrTotal: number | null;
  mrrValidFrom: DateTime | null;
  netAmount: string | null;
  number: string | null;
  orderHistory: OrderHistoryEntityConnection | null;
  orderRelatedDocuments: OrderRelatedDocumentEntityConnection | null;
  paymentDueDate: GqlDate | null;
  status: OrderStatusEnum | null;
  subscription: SubscriptionEntity | null;
  subscriptionId: ID | null;
  supplier: SupplierEntity | null;
  supplierId: ID | null;
  taxAmount: string | null;
  typeOfSale: OrderTypeOfSaleEnum | null;
}

export interface Module  {
  __typename?: 'Module';
  id: string;
  isEnabled: boolean;
  name: string;
}

export interface IdentityProviderEntity  {
  __typename?: 'IdentityProviderEntity';
  acceptUntrusted: boolean | null;
  description: string | null;
  enabled: boolean | null;
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: LicEntityPermsEnum[];
  metadataUrl: string | null;
  name: string | null;
  subscription: SubscriptionEntity | null;
  subscriptionId: number | null;
  type: IdentityProviderType | null;
  url: string | null;
  xml: Base64 | null;
}

export interface CrmOpportunityEntity  {
  __typename?: 'CrmOpportunityEntity';
  created: DateTime;
  id: UUID;
  name: string;
  owner: CpClientEntity;
  stepName: string;
}

export interface CrmAccountEntity  {
  __typename?: 'CrmAccountEntity';
  billingDetail: BillingDetail;
  crmOpportunityEntity: CrmOpportunityEntity[];
  id: UUID;
}

export interface CpCallingEntity  {
  __typename?: 'CpCallingEntity';
  credit: number | null;
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: CRUDLicEntityPermsEnum[];
  remainingCredit: number | null;
}

export interface BillingDetail  {
  __typename?: 'BillingDetail';
  address: string | null;
  city: string | null;
  company: string | null;
  countryId: string | null;
  fax: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  postalCode: string | null;
  stateId: string | null;
  vatCode: string | null;
}

export interface SubscriptionEntity  {
  __typename?: 'SubscriptionEntity';
  agreedToGtac: boolean | null;
  anniversaryDate: GqlDate | null;
  billingCountryName: string | null;
  billingDetail: BillingDetail | null;
  calling: CpCallingEntity | null;
  classification: SubscriptionClassification | null;
  classificationId: number | null;
  contactEmail: string | null;
  created: DateTime | null;
  crmAccount: CrmAccountEntity | null;
  crmAccountId: string | null;
  crmSpaceId: string | null;
  customPrice: number | null;
  discount: number | null;
  endDate: DateTime | null;
  expiredDate: GqlDate | null;
  focus: SubscriptionCompanyFocus | null;
  fromCampaign: string | null;
  id: ID | null;
  idps: IdentityProviderEntity[] | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: SubscriptionPermsEnum[];
  lastVatCheckDate: DateTime | null;
  licensesPurchased: number | null;
  licensesUsed: number | null;
  manualInstructions: string | null;
  manualOrderDunning: boolean | null;
  manuallyHandled: boolean | null;
  modules: Module[] | null;
  mostDuePaymentDate: GqlDate | null;
  name: string | null;
  noOfSalesPeople: NumberOfSalesPeople | null;
  numberOfSalesPeople: NumberOfSalesPeople | null;
  orders: OrderEntity[] | null;
  originalUnitPrice: number | null;
  partnerName: string | null;
  paymentTermsId: ID | null;
  primaryContactEmail: string | null;
  productTier: number | null;
  rabat: number | null;
  referralPartner: PartnerEntity | null;
  referralPartnerId: ID | null;
  region: RegionEntity | null;
  renewal: NotificationRenewal[] | null;
  renewalEstimate: Invoice | null;
  representative: SubscriptionRepresentativeEntity | null;
  representativeEmail: string | null;
  representativeId: ID | null;
  representativeName: string | null;
  required2faTypes: TwoFAType[] | null;
  requiredIdpId: number | null;
  sdr: SubscriptionRepresentativeEntity | null;
  sdrEmail: string | null;
  sdrId: ID | null;
  sdrName: string | null;
  spaces: TeamSpaceEntity[] | null;
  startDate: DateTime | null;
  status: SubscriptionStatus | null;
  subscriptionAdmins: UserProfileEntity[] | null;
  supplier: SupplierEntity | null;
  supplierId: ID | null;
  tier: SubscriptionProductTier | null;
  unitPrice: number | null;
  vatCheckResult: VatCheckResultEnum | null;
  vatCode: string | null;
}

export interface TeamSpaceEntityEdge  {
  __typename?: 'TeamSpaceEntityEdge';
  cursor: string;
  node: TeamSpaceEntity | null;
}

export interface TeamSpaceEntityConnection  {
  __typename?: 'TeamSpaceEntityConnection';
  edges: TeamSpaceEntityEdge[];
  pageInfo: PageInfo;
  totalCount: number | null;
}

export interface CpClientEntity  {
  __typename?: 'CpClientEntity';
  email: string | null;
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: ReadOnlyPermsEnum[];
  spaces: TeamSpaceEntityConnection | null;
}

export interface CpClientEntityEdge  {
  __typename?: 'CpClientEntityEdge';
  cursor: string;
  node: CpClientEntity | null;
}

export interface CpClientEntityConnection  {
  __typename?: 'CpClientEntityConnection';
  edges: CpClientEntityEdge[];
  pageInfo: PageInfo;
  totalCount: number | null;
}

export interface TeamSpaceAutomationHubItem  {
  __typename?: 'TeamSpaceAutomationHubItem';
  id: number;
  name: string;
  status: TeamPipelineHubItemStatusEnum;
}

export interface TeamSpaceEntity  {
  __typename?: 'TeamSpaceEntity';
  active: TeamSpaceStatus | null;
  adminUrl: string | null;
  appBaseUrl: string | null;
  appVersion: string | null;
  automationHubItems: TeamSpaceAutomationHubItem[] | null;
  clients: CpClientEntityConnection | null;
  dbVersion: number | null;
  flags: number | null;
  formattedDbVersion: string | null;
  graphqlUrl: string | null;
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: TeamSpacePermsEnum[];
  name: string | null;
  pictureUrl: string | null;
  publicRestUrl: string | null;
  regionName: string | null;
  regionUrl: string | null;
  restUrl: string | null;
  size: number | null;
  status: TeamSpaceStatus | null;
  statusReason: TeamSpaceStatusReason | null;
  subscription: SubscriptionEntity | null;
  subscriptionId: ID | null;
  usedSize: number | null;
  version: number | null;
}

export interface AutomationHubItemEntity  {
  __typename?: 'AutomationHubItemEntity';
  aclPermissionKey: string | null;
  authUrl: string | null;
  automationHubApp: AutomationHubAppEntity | null;
  automationHubAppId: number | null;
  automationHubCategory: AutomationHubCategoryEntity | null;
  automationHubCategoryId: number | null;
  configurationUrl: string | null;
  deprecatedByItemId: number | null;
  description: string | null;
  hasLastRun: boolean | null;
  hasLogs: boolean | null;
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: LicEntityPermsEnum[];
  isBeta: boolean | null;
  isBundle: boolean | null;
  isDeleted: boolean | null;
  logoUrl: string | null;
  minApiVersion: number | null;
  name: string | null;
  ranking: number | null;
  scope: AutomationHubScope | null;
  teamSpaceItem: TeamPipelineHubInterfaceImplementors | null;
  tier: AutomationHubTierEnum | null;
  websiteUrl: string | null;
}

export interface AutomationHubItemRepository  {
  __typename?: 'AutomationHubItemRepository';
  getAll: AutomationHubItemEntity[];
  getByCriteria: AutomationHubItemEntityConnection | null;
  getById: AutomationHubItemEntity | null;
  getByIds: AutomationHubItemEntity[];
}

export interface AutomationHubCategoryEntityEdge  {
  __typename?: 'AutomationHubCategoryEntityEdge';
  cursor: string;
  node: AutomationHubCategoryEntity | null;
}

export interface AutomationHubCategoryEntityConnection  {
  __typename?: 'AutomationHubCategoryEntityConnection';
  edges: AutomationHubCategoryEntityEdge[];
  pageInfo: PageInfo;
  totalCount: number | null;
}

export interface AutomationHubCategoryEntity  {
  __typename?: 'AutomationHubCategoryEntity';
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: LicEntityPermsEnum[];
  isDeleted: boolean | null;
  name: string | null;
}

export interface AutomationHubCategoryRepository  {
  __typename?: 'AutomationHubCategoryRepository';
  getAll: AutomationHubCategoryEntity[];
  getByCriteria: AutomationHubCategoryEntityConnection | null;
  getById: AutomationHubCategoryEntity | null;
  getByIds: AutomationHubCategoryEntity[];
}

export interface PageInfo  {
  __typename?: 'PageInfo';
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

export interface AutomationHubAppEntityEdge  {
  __typename?: 'AutomationHubAppEntityEdge';
  cursor: string;
  node: AutomationHubAppEntity | null;
}

export interface AutomationHubAppEntityConnection  {
  __typename?: 'AutomationHubAppEntityConnection';
  edges: AutomationHubAppEntityEdge[];
  pageInfo: PageInfo;
  totalCount: number | null;
}

export interface FieldLevelPermissionEnum  {
  __typename?: 'FieldLevelPermissionEnum';
  fullAccess: string[];
  readOnly: string[];
}

export interface AutomationHubAppEntity  {
  __typename?: 'AutomationHubAppEntity';
  id: ID | null;
  instanceFieldPermissions: FieldLevelPermissionEnum;
  instancePermissions: LicEntityPermsEnum[];
  isDeleted: boolean | null;
  logoUrl: string | null;
  name: string | null;
  supportPrivacyUrl: string | null;
  supportTermsUrl: string | null;
  supportUrl: string | null;
  websiteUrl: string | null;
}

export interface AutomationHubAppRepository  {
  __typename?: 'AutomationHubAppRepository';
  getAll: AutomationHubAppEntity[];
  getByCriteria: AutomationHubAppEntityConnection | null;
  getById: AutomationHubAppEntity | null;
  getByIds: AutomationHubAppEntity[];
}

export interface UserEntityRepositoryRegistry  {
  __typename?: 'UserEntityRepositoryRegistry';
  automationHubApp: AutomationHubAppRepository | null;
  automationHubCategory: AutomationHubCategoryRepository | null;
  automationHubItem: AutomationHubItemRepository | null;
  howTo: HowToRepository | null;
  identityProvider: IdentityProviderRepository | null;
  partner: PartnerRepository | null;
  subscription: SubscriptionRepository | null;
  subscriptionHubItem: SubscriptionHubItemRepository | null;
  teamSpace: TeamSpaceRepository | null;
  teamSpaceClient: TeamSpaceClientRepository | null;
  userProfile: UserProfileRepository | null;
}

export interface LicMutationPermissions  {
  __typename?: 'LicMutationPermissions';
  mutation: string;
  permission: LicEntityPermsEnum;
}

export interface LicEntityClassPermissions  {
  __typename?: 'LicEntityClassPermissions';
  allowedPermissions: LicEntityPermsEnum[];
  entity: LicEntityNameEnum;
  permissions: LicEntityPermsEnum[];
}

export interface LicEntityClassPermissionsListing  {
  __typename?: 'LicEntityClassPermissionsListing';
  activityDetail: LicEntityClassPermissions;
  automationHubApp: LicEntityClassPermissions;
  automationHubCategory: LicEntityClassPermissions;
  automationHubItem: LicEntityClassPermissions;
  country: LicEntityClassPermissions;
  cpActivity: LicEntityClassPermissions;
  cpCallLog: LicEntityClassPermissions;
  cpCalling: LicEntityClassPermissions;
  cpClient: LicEntityClassPermissions;
  cpProfile: LicEntityClassPermissions;
  cpTeamSpaceClient: LicEntityClassPermissions;
  howTo: LicEntityClassPermissions;
  identityProvider: LicEntityClassPermissions;
  notificationSetting: LicEntityClassPermissions;
  order: LicEntityClassPermissions;
  orderHistory: LicEntityClassPermissions;
  orderItem: LicEntityClassPermissions;
  orderRelatedDocument: LicEntityClassPermissions;
  orderTempData: LicEntityClassPermissions;
  orderTempDataItem: LicEntityClassPermissions;
  partner: LicEntityClassPermissions;
  passwordChangeRequest: LicEntityClassPermissions;
  payment: LicEntityClassPermissions;
  paymentAdditionalData: LicEntityClassPermissions;
  paymentTerm: LicEntityClassPermissions;
  region: LicEntityClassPermissions;
  sapiUser: LicEntityClassPermissions;
  state: LicEntityClassPermissions;
  subscription: LicEntityClassPermissions;
  subscriptionHubItem: LicEntityClassPermissions;
  subscriptionNotification: LicEntityClassPermissions;
  subscriptionRepresentative: LicEntityClassPermissions;
  supplier: LicEntityClassPermissions;
  teamPipelineHubItemBase: LicEntityClassPermissions;
  teamSpace: LicEntityClassPermissions;
  teamSpaceClient: LicEntityClassPermissions;
  teamSpaceClientSetting: LicEntityClassPermissions;
  teamSpaceProperty: LicEntityClassPermissions;
  teamSpaceRegion: LicEntityClassPermissions;
  twilioCallerId: LicEntityClassPermissions;
  userOnlineServices: LicEntityClassPermissions;
  userProfile: LicEntityClassPermissions;
  userRole: LicEntityClassPermissions;
  vatCheck: LicEntityClassPermissions;
}

export interface LicEntityInstancePermissions  {
  __typename?: 'LicEntityInstancePermissions';
  id: ID;
  permissions: LicEntityPermsEnum[];
}

export interface Acl  {
  __typename?: 'Acl';
  apps: AppsEnum[];
  bulkInstancePermissions: LicEntityInstancePermissions[];
  entities: LicEntityClassPermissionsListing;
  instancePermissions: LicEntityPermsEnum[];
  mutations: LicMutationPermissions[];
}

export const UpdateUserSettingPayloadFragment = gql`
  fragment UpdateUserSettingPayloadFragment on UpdateUserSettingPayload {
  clientMutationId
  }`;

export const updateUserSettingMutation = gql`
  mutation updateUserSetting($input: UpdateUserSettingInput!) {
      updateUserSetting(input: $input) {
          ...UpdateUserSettingPayloadFragment
      }
  }
  ${UpdateUserSettingPayloadFragment}
`;

export const ReportErrorPayloadFragment = gql`
  fragment ReportErrorPayloadFragment on ReportErrorPayload {
  clientMutationId
  }`;

export const reportErrorMutation = gql`
  mutation reportError($input: ReportErrorInput!) {
      reportError(input: $input) {
          ...ReportErrorPayloadFragment
      }
  }
  ${ReportErrorPayloadFragment}
`;

export const ChangeUserProfilePasswordPayloadFragment = gql`
  fragment ChangeUserProfilePasswordPayloadFragment on ChangeUserProfilePasswordPayload {
  clientMutationId
  result
  }`;

export const changeUserProfilePasswordMutation = gql`
  mutation changeUserProfilePassword($input: ChangeUserProfilePasswordInput!) {
      changeUserProfilePassword(input: $input) {
          ...ChangeUserProfilePasswordPayloadFragment
      }
  }
  ${ChangeUserProfilePasswordPayloadFragment}
`;

export const FinancialDateStartFragment = gql`
  fragment FinancialDateStartFragment on FinancialDateStart {
    day
    month
  }`;

export const AttachmentFileExtensionFragment = gql`
  fragment AttachmentFileExtensionFragment on AttachmentFileExtension {
    denied
    list
  }`;

export const SpaceSettingsFragment = gql`
  fragment SpaceSettingsFragment on SpaceSettings {
    attachmentFileExtensions {
      ...AttachmentFileExtensionFragment
    }
    attachmentMaxSize
    chunkSizeExchange
    cloudEnabled
    dynamicFinancialYear {
      ...FinancialDateStartFragment
    }
    emailAttachmentEnabled
    emailAttachmentMaxSize
    emailDomainBlacklisting
    fileAttachmentUploadEnabled
    firstDayOfWeek
    intercomIntegration
    sharepointIntegration
    timezone
  }
  ${AttachmentFileExtensionFragment}
  ${FinancialDateStartFragment}
`;

export const SpaceFragment = gql`
  fragment SpaceFragment on Space {
    accessStatus
    appBaseUrl
    appVersion
    bccMailto
    clientId
    graphqlUrl
    id
    idp {
      acceptUntrusted
      description
      enabled
      id
      metadataUrl
      name
      subscriptionId
      type
      url
      xml
    }
    isSuspended
    name
    pictureUrl
    regionUrl
    restUrl
    settings {
      attachmentMaxSize
      chunkSizeExchange
      cloudEnabled
      emailAttachmentEnabled
      emailAttachmentMaxSize
      emailDomainBlacklisting
      fileAttachmentUploadEnabled
      firstDayOfWeek
      intercomIntegration
      sharepointIntegration
      timezone
    }
    size
    socketioUrl
    subscription {
      classification
      domain
      endDate
      expiredDate
      id
      isTrial
      licensesPurchased
      licensesUsed
      mostDuePaymentDate
      name
      productTier
      representativeEmail
      startDate
    }
    url
    version
  }`;

export const UserSettingsFragment = gql`
  fragment UserSettingsFragment on UserSettings {
    autoCreateAccount
    autoCreateContact
    autoHighlightFieldValue
    autoLinkAccount
    autoPopulate
    bccPipelineEmail
    defaultAppointmentDuration
    defaultAppointmentReminder
    defaultTaskDueDate
    defaultTaskReminder
    defaultTaskReminderDayOffset
    defaultTaskReminderDueDate
    defaultTaskReminderTimeOffset
    emailHandlerInNewTab
    emailSignature
    firstWeekDay
    language
    locale
    nameFormat
    navigatorHints
    salespopWidgetCategories
    salespopWidgetClosedDate
    salespopWidgetLastArticleDate
    salespopWidgetUnsubscribed
    showFollowActionMessage
    showReminderNotifications
    workWeekFrom
    workWeekTo
  }`;

export interface Query  {
  __typename?: 'Query';
  accessControl: Acl;
  entities: UserEntityRepositoryRegistry | null;
  geoLocations: GeoLocationEntity[] | null;
  hubspotSpacesByPortalIds: HubspotPortalId[];
  profile: UserProfileEntity;
  settings: UserSettings;
  space: Space | null;
  spaces: Space[];
}
