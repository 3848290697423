// ***
// Based on: https://github.com/chovy/humanparser/blob/master/index.js
// ***

export const NameParser = {
  parseName: (name: string, ignoreSuffix?: string[]) => {
    if (!ignoreSuffix) {
      ignoreSuffix = [];
    }

    const suffixes = allSuffixes.filter(suffix => !ignoreSuffix.includes(suffix));

    const partsTogether = name
      .trim()
      .replace(/\b\s+(,\s+)\b/, '$1') // fix name , suffix -> name, suffix
      .replace(/\b,\b/, ', ');        // fix name,suffix -> name, suffix

    let parts = (partsTogether.match(/[^\s"]+|"[^"]+"/g) || partsTogether.split(/\s+/)).map(n => n.match(/^".*"$/) ? n.slice(1, -1) : n);
    const attrs: {
      firstName?: string,
      suffix?: string,
      lastName?: string,
      middleName?: string,
      fullName?: string,
      salutation?: string
    } = {};

    if (!parts.length) {
      return attrs;
    }

    if (parts.length === 1) {
      attrs.firstName = parts[0];
    }

    // handle suffix first always, remove trailing comma if there is one
    if (parts.length > 1 && suffixes.indexOf(parts[parts.length - 1].toLowerCase().replace(/\./g, '')) > -1) {
      attrs.suffix = parts.pop();
      parts[parts.length - 1] = parts[parts.length - 1].replace(',', '');
    }

    // look for a comma to know we have last name first format
    const firstNameFirstFormat = parts.every(part => {
      return part.indexOf(',') === -1;
    });

    if (!firstNameFirstFormat) {
      // last name first format
      // assuming salutations are never used in this format

      // tracker variable for where first name begins in parts array
      let firstNameIndex;

      // location of first comma will separate last name from rest
      // join all parts leading to first comma as last name
      // eslint:disable-next-line: no-shadowed-variable
      const lastName = parts.reduce((lastName, current, index) => {
        if (!Array.isArray(lastName)) {
          return lastName;
        }
        if (current.indexOf(',') === -1) {
          lastName.push(current);
          return lastName;
        } else {
          current = current.replace(',', '');

          // handle case where suffix is included in part of last name (ie: 'Hearst Jr., Willian Randolph')
          if (suffixes.indexOf(current.toLowerCase().replace(/\./g, '')) > -1) {
            attrs.suffix = current;
          } else {
            lastName.push(current);
          }

          firstNameIndex = index + 1;
          return lastName.join(' ');
        }
      }, []);

      attrs.lastName = lastName as any;

      const remainingParts = parts.slice(firstNameIndex);
      if (remainingParts.length > 1) {
        attrs.firstName = remainingParts.shift();
        attrs.middleName = remainingParts.join(' ');
      } else if (remainingParts.length) {
        attrs.firstName = remainingParts[0];
      }

      // create full name from attrs object
      const nameWords = [];
      if (attrs.firstName) {
        nameWords.push(attrs.firstName);
      }
      if (attrs.middleName) {
        nameWords.push(attrs.middleName);
      }
      nameWords.push(attrs.lastName);
      if (attrs.suffix) {
        nameWords.push(attrs.suffix);
      }
      attrs.fullName = nameWords.join(' ');


    } else {
      // first name first format
      if (parts.length > 1 && salutations.indexOf(parts[0].toLowerCase().replace(/\./g, '')) > -1) {
        attrs.salutation = parts.shift();

        // if we have a salutation assume 2nd part is last name
        if (parts.length === 1) {
          attrs.lastName = parts.shift();
        } else {
          attrs.firstName = parts.shift();
        }
      } else {
        attrs.firstName = parts.shift();
      }

      if (!attrs.lastName) {
        attrs.lastName = parts.length ? parts.pop() : '';
      }

      // test for compound last name, we reverse because middle name is last bit to be defined.
      // We already know lastname, so check next word if its part of a compound last name.
      const revParts = parts.slice(0).reverse();
      const compoundParts = [];

      revParts.every(part => {
        const test = part.toLowerCase().replace(/\./g, '');

        if (compound.indexOf(test) > -1) {
          compoundParts.push(part);

          return true;
        }

        // break on first non compound word
        return false;
      });

      // join compound parts with known last name
      if (compoundParts.length) {
        attrs.lastName = compoundParts.reverse().join(' ') + ' ' + attrs.lastName;

        parts = diff(parts, compoundParts);
      }

      if (parts.length) {
        attrs.middleName = parts.join(' ');
      }

      // remove comma like "<lastName>, Jr."
      if (attrs.lastName) {
        attrs.lastName = attrs.lastName.replace(',', '');
      }

      // save a copy of original
      attrs.fullName = name;

    }
    // console.log('attrs:', JSON.stringify(attrs));

    for (const [k, v] of Object.entries(attrs)) {
      attrs[k] = v.trim();
    }


    return attrs;
  },

  parse: (name: string) => {
    const result = NameParser.parseName(name);
    return {
      first: result.firstName || '',
      last: ((result.lastName || '') + ' ' + (result.suffix || '')).trim(),
      middle: result.middleName || '',
      leadingInit: result.salutation || ''
    };
  }
};

function diff(a1: any[], a2: any[]) {
  return a1.concat(a2).filter((val, index, arr) => {
    return arr.indexOf(val) === arr.lastIndexOf(val);
  });
}

const salutations = [
  'dr',
  'doctor',
  'miss',
  'misses',
  'mr',
  'mister',
  'mrs',
  'ms',
  'sir',
  'dame',
  'rev',
  'madam',
  'madame',
  'ab',
  '2ndlt',
  'amn',
  '1stlt',
  'a1c',
  'capt',
  'sra',
  'maj',
  'ssgt',
  'ltcol',
  'tsgt',
  'col',
  'briggen',
  '1stsgt',
  'majgen',
  'smsgt',
  'ltgen',
  '1stsgt',
  'cmsgt',
  '1stsgt',
  'ccmsgt',
  'cmsaf',
  'pvt',
  '2lt',
  'pv2',
  '1lt',
  'pfc',
  'cpt',
  'spc',
  'maj',
  'cpl',
  'ltc',
  'sgt',
  'ssg',
  'bg',
  'sfc',
  'mg',
  'msg',
  'ltg',
  '1sgt',
  'sgm',
  'csm',
  'sma',
  'wo1',
  'wo2',
  'wo3',
  'wo4',
  'wo5',
  'ens',
  'sa',
  'ltjg',
  'sn',
  'lt',
  'po3',
  'lcdr',
  'po2',
  'cdr',
  'po1',
  'cpo',
  'radm(lh)',
  'scpo',
  'radm(uh)',
  'mcpo',
  'vadm',
  'mcpoc',
  'adm',
  'mpco-cg',
  'pvt',
  '2ndlt',
  'pfc',
  '1stlt',
  'lcpl',
  'cpl',
  'sgt',
  'ssgt',
  'gysgt',
  'bgen',
  'msgt',
  'majgen',
  '1stsgt',
  'ltgen',
  'mgysgt',
  'gen',
  'sgtmaj',
  'sgtmajmc',
  'wo-1',
  'cwo-2',
  'cwo-3',
  'cwo-4',
  'cwo-5',
  'rdml',
  'radm',
  'mcpon',
  'fadm',
  'wo1',
  'cwo2',
  'cwo3',
  'cwo4',
  'cwo5',
  'rt',
  'lord',
  'lady',
  'duke',
  'dutchess',
  'master',
  'maid',
  'uncle',
  'auntie',
  'aunt',
  'representative',
  'senator',
  'king',
  'queen',
  'cardinal',
  'secretary',
  'state',
  'foreign',
  'minister',
  'speaker',
  'president',
  'deputy',
  'executive',
  'vice',
  'councillor',
  'alderman',
  'delegate',
  'mayor',
  'lieutenant',
  'governor',
  'prefect',
  'prelate',
  'premier',
  'burgess',
  'ambassador',
  'envoy',
  'secretary',
  'attaché',
  // eslint:disable-next-line: quotemark
  "chargé d'affaires",
  'provost',
  'marquis',
  'marquess',
  'marquise',
  'marchioness',
  'archduke',
  'archduchess',
  'viscount',
  'baron',
  'emperor',
  'empress',
  'tsar',
  'tsarina',
  'leader',
  'abbess',
  'abbot',
  'brother',
  'sister',
  'friar',
  'mother',
  'superior',
  'reverend',
  'bishop',
  'archbishop',
  'metropolitan',
  'presbyter',
  'priest',
  'high',
  'priestess',
  'father',
  'patriarch',
  'pope',
  'catholicos',
  'vicar',
  'chaplain',
  'canon',
  'pastor',
  'prelate',
  'primate',
  'chaplain',
  'cardinal',
  'servant',
  'venerable',
  'blessed',
  'saint',
  'member',
  'solicitor',
  'mufti',
  'grand',
  'chancellor',
  'barrister',
  'bailiff',
  'attorney',
  'advocate',
  'deacon',
  'archdeacon',
  'acolyte',
  'elder',
  'minister',
  'monsignor',
  'almoner',
  'prof',
  'colonel',
  'general',
  'commodore',
  'air',
  'corporal',
  'staff',
  'mate',
  'chief',
  'first',
  'sergeant',
  'sergeant',
  'admiral',
  'high',
  'rear',
  'brigadier',
  'captain',
  'group',
  'commander',
  'commander-in-chief',
  'wing',
  'general',
  'adjutant',
  'director',
  'generalissimo',
  'resident',
  'surgeon',
  'officer',
  'academic',
  'analytics',
  'business',
  'credit',
  'financial',
  'information',
  'security',
  'knowledge',
  'marketing',
  'operating',
  'petty',
  'risk',
  'security',
  'strategy',
  'technical',
  'warrant',
  'corporate',
  'customs',
  'field',
  'flag',
  'flying',
  'intelligence',
  'pilot',
  'police',
  'political',
  'revenue',
  'senior',
  'staff',
  'private',
  'principal',
  'coach',
  'nurse',
  'nanny',
  'docent',
  'lama',
  'druid',
  'archdruid',
  'rabbi',
  'rebbe',
  'buddha',
  'ayatollah',
  'imam',
  'bodhisattva',
  'mullah',
  'mahdi',
  'saoshyant',
  'tirthankar',
  'vardapet',
  'pharaoh',
  'sultan',
  'sultana',
  'maharajah',
  'maharani',
  'elder',
  'vizier',
  'chieftain',
  'comptroller',
  'courtier',
  'curator',
  'doyen',
  'edohen',
  'ekegbian',
  'elerunwon',
  'forester',
  'gentiluomo',
  'headman',
  'intendant',
  'lamido',
  'marcher',
  'matriarch',
  'patriarch',
  'prior',
  'pursuivant',
  'rangatira',
  'ranger',
  'registrar',
  'seigneur',
  'sharif',
  'shehu',
  'sheikh',
  'sheriff',
  'subaltern',
  'subedar',
  'sysselmann',
  'timi',
  'treasurer',
  'verderer',
  'warden',
  'hereditary',
  'woodman',
  'bearer',
  'banner',
  'swordbearer',
  'apprentice',
  'journeyman',
  'adept',
  'akhoond',
  'arhat',
  'bwana',
  'goodman',
  'goodwife',
  'bard',
  'hajji',
  'mullah',
  'baba',
  'effendi',
  'giani',
  'gyani',
  'guru',
  'siddha',
  'pir',
  'murshid',
  'attache',
  'prime',
  'united',
  'states',
  'national',
  'associate',
  'assistant',
  'supreme',
  'appellate',
  'judicial',
  // eslint:disable-next-line: quotemark
  "queen's",
  // eslint:disable-next-line: quotemark
  "king's",
  'bench',
  'right',
  'majesty',
  'his',
  'her',
  'kingdom',
  'royal',
  'ing',
  'fr',
  'judge',
  'honorable',
  'hon',
  // removed per request: https://pipelinersales.atlassian.net/browse/PLW-17826
  // 'tuan',
  'sr',
  'srta',
  'br',
  'pr',
  'mx',
];
const allSuffixes = [
  'esq',
  'esquire',
  '2',
  'i',
  'ii',
  'iii',
  'iv',
  'v',
  'clu',
  'chfc',
  'cfp',
  'senior',
  'junior',
  'jr',
  'sr',
  'phd',
  'apr',
  'rph',
  'pe',
  'md',
  'ma',
  'dmd',
  'cme',
  'qc',
  'kc'
];
const compound = [
  'vere',
  'von',
  'van',
  'de',
  'del',
  'della',
  'der',
  'den',
  'di',
  'da',
  'pietro',
  'vanden',
  'du',
  'st.',
  'st',
  'la',
  'lo',
  'ter',
  'bin',
  'ibn',
  'te',
  'ten',
  'op',
  'ben',
  'al'
];
