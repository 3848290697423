import { Injectable } from '@angular/core';
import type { OpportunityEntity, Query } from '@ppl/graphql-space-api';
import { SpaceService } from '@ppl/space';
import { map } from 'rxjs/operators';
import type { OpportunityData } from '../domain/opportunity';
import { gqlFetchOpportunityData } from './opportunity.graphql';

@Injectable()
export class OpportunityService {

  constructor(
    private spaceService: SpaceService
  ) {}

  fetchData(id: string) {
    return this.spaceService.gqlClient.query<Query>({
      query: gqlFetchOpportunityData,
      fetchPolicy: 'no-cache',
      variables: {
        entityId: id
      }
    }).pipe(map(({ data }) => {
      const entityData = data.screens.extractViews.opportunity;

      if (!entityData) {
        return null;
      }

      const result: OpportunityData = {
        entity: entityData.entity as OpportunityEntity,
        relatedAccounts: entityData.entity.accountRelations.edges.map(edge => edge.node),
        relatedActivities: [
          ...entityData.plannedActivities.edges.map(edge => edge.node.appointment || edge.node.task).filter(entity => !!entity),
          ...entityData.unplannedTasks.edges.map(edge => edge.node.task).filter(entity => !!entity)
        ],
        relatedContacts: entityData.entity.contactRelations.edges.map(edge => edge.node)
      };

      return result;
    }));
  }

}
