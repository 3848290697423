export type Diff<T extends string, U extends string> = ({ [P in T]: P } & { [P in U]: never } & { [x: string]: never })[T];
export type Coalesce<T extends any[]> = Array<T[0]>;
export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};
export type GetElementType<T extends Array<any>> = T extends (infer U)[] ? U : never;
export type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;
export type GetKeysOfType<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];
export type PartiallyOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export function assertNever(x: never): never {
  throw new Error('Unexpected object: ' + x);
}
