<div #pickerDate
     class="picker-date"></div>

<div class="buttons">
  <div *ngIf="displayRemove && value"
       class="button"
       i18n="@@Remove"
       (click)="onRemoveClick()">Remove</div>

  <div i18n="@@Today"
       class="button"
       (click)="onTodayClick()">Today</div>
</div>