import { AboutComponent } from './components/about/about.component';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { TopNavigationAboutButtonComponent } from './components/top-navigation/top-navigation-about-button/top-navigation-about-button.component';
import { TopNavigationButtonComponent } from './components/top-navigation/top-navigation-button/top-navigation-button.component';
import { TopNavigationLayoutComponent } from './components/top-navigation/top-navigation-layout/top-navigation-layout.component';
import { TopNavigationPaymentInfoComponent } from './components/top-navigation/top-navigation-payment-info/top-navigation-payment-info.component';
import { TopNavigationUserButtonComponent } from './components/top-navigation/top-navigation-user-button/top-navigation-user-button.component';
import { TopNavigationUserMenuComponent } from './components/top-navigation/top-navigation-user-menu/top-navigation-user-menu.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { ActionsUtilsService } from './services/actions-utils.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthModule } from '@ppl/auth';
import { PplAppContainerModule } from '@ppl/ui/app-container';
import { PplButtonModule } from '@ppl/ui/button';
import { PplDialogModule } from '@ppl/ui/dialog';
import { PplIconModule } from '@ppl/ui/icon';
import { PplLoadingModule } from '@ppl/ui/loading';
import { PplPopoverModule } from '@ppl/ui/popover';
import { PplPopoverWindowModule } from '@ppl/ui/popover-window';

const moduleComponents = [
  AboutComponent,
  NotFoundComponent,
  TopNavigationButtonComponent,
  TopNavigationUserMenuComponent,
  TopNavigationAboutButtonComponent,
  TopNavigationLayoutComponent,
  TopNavigationUserButtonComponent,
  TopNavigationPaymentInfoComponent
];

const moduleDirectives = [
  AutoFocusDirective
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AuthModule,
    PplIconModule,
    PplDialogModule,
    PplButtonModule,
    PplAppContainerModule,
    PplPopoverModule,
    PplPopoverWindowModule,
    PplLoadingModule
  ],
  declarations: [
    ...moduleComponents,
    ...moduleDirectives,
    LoadingDialogComponent
  ],
  exports: [
    ...moduleComponents,
    ...moduleDirectives
  ],
  providers: [
    ActionsUtilsService
  ]
})
export class CoreModule { }
