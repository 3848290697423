import { gql } from '@ppl/utils';
import { AccountCardLargeFields, AppointmentCardMediumFields, ContactCardMediumFields, LeadCardMediumFields, OpportunityCardMediumFields, TaskCardMediumFields } from '../../shared/entity-card/services/entity-card.graphql';

export const gqlFetchAccountData = gql`
  query fetchAccountData($entityId: UUID!) {
    screens {
      extractViews {
        account(entityId: $entityId) {
          entity {
            ...AccountCardLargeFields
          }
          leads(orderBy: [{ created: Desc }]) {
            edges {
              node {
                id
                lead {
                  ...LeadCardMediumFields
                }
              }
            }
          }
          opportunities(orderBy: [{ created: Desc }]) {
            edges {
              node {
                id
                oppty {
                  ...OpportunityCardMediumFields
                }
              }
            }
          }
          contacts(orderBy: [{ lastName: Asc }]) {
            edges {
              node {
                id
                contact {
                  ...ContactCardMediumFields
                }
              }
            }
          }
          plannedActivities {
            edges {
              node {
                id
                appointment {
                  ...AppointmentCardMediumFields
                }
                task {
                  ...TaskCardMediumFields
                }
              }
            }
          }
          unplannedTasks {
            edges {
              node {
                id
                task {
                  ...TaskCardMediumFields
                }
              }
            }
          }
        }
      }
    }
  }
  ${AccountCardLargeFields}
  ${AppointmentCardMediumFields}
  ${ContactCardMediumFields}
  ${LeadCardMediumFields}
  ${OpportunityCardMediumFields}
  ${TaskCardMediumFields}
`;
