import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { AvatarKind } from '../../../avatar/domain/avatar';
import { OutlookItemDirection } from '../../../outlook/domain/outlook';

@Component({
  selector: 'ppl-user-card-small',
  templateUrl: './user-card-small.component.html',
  styleUrls: ['./user-card-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCardSmallComponent {

  @Input() direction: OutlookItemDirection;
  @Input() active?: boolean;

  AvatarKind = AvatarKind;
  EntityNameEnum = EntityNameEnum;
  OutlookItemDirection = OutlookItemDirection;

}
