import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@ppl/core';
import { CardModule } from '../shared/card/card.module';
import { EntityCardModule } from '../shared/entity-card/entity-card.module';
import { EntityFormApiModule } from '../shared/entity-form-api/entity-form-api.module';
import { EntityFormModule } from '../shared/entity-form/entity-form.module';
import { EntityModule } from '../shared/entity/entity.module';
import { PageModule } from '../shared/page/page.module';
import { ToastModule } from '../shared/toast/toast.module';
import { AppointmentFormComponent } from './components/appointment-form/appointment-form.component';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { AppointmentFormService } from './services/appointment-form.service';
import { AppointmentService } from './services/appointment.service';

@NgModule({
  imports: [
    CardModule,
    CommonModule,
    CoreModule,
    EntityModule,
    EntityCardModule,
    EntityFormModule,
    EntityFormApiModule,
    PageModule,
    ToastModule
  ],
  declarations: [
    AppointmentComponent,
    AppointmentFormComponent
  ],
  exports: [
    AppointmentComponent,
    AppointmentFormComponent
  ],
  providers: [
    AppointmentService,
    AppointmentFormService
  ]
})
export class AppointmentModule {}
