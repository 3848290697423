import type { OnDestroy} from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { KEYCODE_ENTER } from '@ppl/utils';

@Component({
  selector: 'ppl-card-inline-field',
  templateUrl: './card-inline-field.component.html',
  styleUrls: ['./card-inline-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardInlineFieldComponent implements OnDestroy {

  @Input() disabled?: boolean;
  @Input() emptyLabel?: string;
  @Input() icon: string;
  @Input() value: string;

  @Output() valueChange = new EventEmitter<any>();

  @ContentChild('editorTemplate') editorTemplate?: TemplateRef<any>;

  editorVisible = false;

  mouseDownListener: any;
  mouseDownListenerHandle: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    this.onValueChange = this.onValueChange.bind(this);
  }

  ngOnDestroy() {
    this.closeEditor();
  }

  onEditClick() {
    if (this.disabled) {
      return;
    }

    this.editorVisible = true;

    // Delay adding listener so it will not get called for this event handler
    this.mouseDownListenerHandle = setTimeout(() => {
      const editorInput = this.getEditorInput();

      editorInput?.focus();

      document.addEventListener('mousedown', this.mouseDownListener = (event: MouseEvent) => {
        const eventTarget = event.target as HTMLElement;

        // Do not close when clicking on input or option list ("pac-container" is Google Maps autocomplete)
        if (!this.elementRef.nativeElement.querySelector('.input').contains(eventTarget) && !eventTarget.closest('.pac-container')) {
          this.closeEditor();
          this.changeDetectorRef.detectChanges();
        }
      });
    });
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.keyCode === KEYCODE_ENTER) {
      this.onSaveClick();
    }
  }

  onSaveClick() {
    const nextValue = this.getEditorInput().value;

    if (nextValue !== this.value) {
      this.valueChange.emit(nextValue);
    }

    this.closeEditor();
  }

  onValueChange(nextValue: any) {
    this.valueChange.emit(nextValue);

    this.closeEditor();
  }

  private getEditorInput() {
    return this.elementRef.nativeElement.querySelector('input');
  }

  private closeEditor() {
    this.editorVisible = false;

    document.removeEventListener('mousedown', this.mouseDownListener);
    clearTimeout(this.mouseDownListenerHandle);
  }

}
