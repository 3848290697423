import { AsyncState } from '@ppl/store';
import type { CoreState } from '../../../store/core.reducer';

export function shouldDashboardFetchData(state: CoreState) {
  return state.dashboard.data.state === AsyncState.IDLE || state.dashboard.data.state === AsyncState.ERROR;
}

export function getDashboardData(state: CoreState) {
  return state.dashboard.data;
}

export function getDashboardDataRevision(state: CoreState) {
  return state.dashboard.dataRevision;
}
