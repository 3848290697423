import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@ppl/core';
import { CardModule } from '../shared/card/card.module';
import { EntityCardModule } from '../shared/entity-card/entity-card.module';
import { EntityFormApiModule } from '../shared/entity-form-api/entity-form-api.module';
import { EntityFormModule } from '../shared/entity-form/entity-form.module';
import { EntityModule } from '../shared/entity/entity.module';
import { PageModule } from '../shared/page/page.module';
import { ToastModule } from '../shared/toast/toast.module';
import { TaskFormComponent } from './components/task-form/task-form.component';
import { TaskComponent } from './components/task/task.component';
import { TaskFormService } from './services/task-form.service';
import { TaskService } from './services/task.service';

@NgModule({
  imports: [
    CardModule,
    CommonModule,
    CoreModule,
    EntityModule,
    EntityCardModule,
    EntityFormModule,
    EntityFormApiModule,
    PageModule,
    ToastModule
  ],
  declarations: [
    TaskComponent,
    TaskFormComponent
  ],
  exports: [
    TaskComponent,
    TaskFormComponent
  ],
  providers: [
    TaskService,
    TaskFormService
  ]
})
export class TaskModule {}
