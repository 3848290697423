<ppl-icon class="logo"
          svgIcon="logo"
          width="218px"
          height="50px"></ppl-icon>

<div class="title"
     i18n="@@Sign_in">Sign in</div>

<div class="description">{{description}}</div>

<div class="button"
     [class.disabled]="signInProgress$ | async"
     i18n="@@Continue_to_Login_Screen"
     (click)="onSignInClick()">Continue to Login Screen</div>

<div class="trial-account">
  <div i18n="@@Dont_have_an_account">Don't have an account?</div>
</div>

<div class="trial-link">
  <a [href]="signUpUrl"
     i18n="@@Sign_up_for_Trial"
     target="_blank">Sign up for Trial</a>
</div>

<div class="version"
     (click)="onVersionClick($event)">
  <ng-container i18n="@@Version">Version</ng-container> {{version}}
</div>

<div class="legal">
  <a href="https://www.pipelinersales.com/terms/"
     i18n="@@Terms_&_Conditions"
     target="_blank">Terms & Conditions</a>
  <div class="legal-separator"></div>
  <a href="https://www.pipelinersales.com/privacy/"
     i18n="@@Privacy_Policy"
     target="_blank">Privacy Policy</a>
</div>

<div class="legal-year">
  &copy; {{currentYear}} Pipelinersales Inc.
</div>