import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityPermsEnum, TaskEntity, TaskStatusEnum } from '@ppl/graphql-space-api';
import { isOverdue, MemoizeLast } from '@ppl/utils';
import { ActivityService } from '../../../activity/services/activity.service';

@Component({
  selector: 'ppl-task-fields-card',
  templateUrl: './task-fields-card.component.html',
  styleUrls: ['./task-fields-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskFieldsCardComponent {

  @Input() entity: TaskEntity;

  @Output() dueDateChange = new EventEmitter<string>();

  EntityPermsEnum = EntityPermsEnum;

  @MemoizeLast<TaskFieldsCardComponent>(['entity'])
  get isOverdue() {
    return isOverdue(this.entity.dueDate) && this.entity.status !== TaskStatusEnum.Completed;
  }

  constructor(
    private activityService: ActivityService
  ) {}

  onSetClick() {
    this.activityService.setTaskDueDate({
      entity: this.entity
    }).subscribe(dueDate => {
      this.dueDateChange.emit(dueDate);
    });
  }

}
