<ppl-card-large [disabled]="disabled">
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="EntityNameEnum.Account"
                [kind]="AvatarKind.Large"
                [pictureUrl]="entity.picture?.url"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    {{entity.name}}
  </ng-template>

  <ng-template #descriptionTemplate>
    <ppl-card-social *ngIf="entity.socialMedia"
                     [entity]="entity.socialMedia"></ppl-card-social>
  </ng-template>

  <ng-template #actionTemplate>
    <ppl-external-link [entity]="entity"></ppl-external-link>
  </ng-template>
</ppl-card-large>