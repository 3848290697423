import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { EntityNameEnum, LeadEntity } from '@ppl/graphql-space-api';
import { LocationService } from '../../../../../services/location.service';
import { AvatarKind } from '../../../avatar/domain/avatar';

@Component({
  selector: 'ppl-lead-card-large',
  templateUrl: './lead-card-large.component.html',
  styleUrls: ['./lead-card-large.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeadCardLargeComponent {

  @Input() entity: LeadEntity;
  @Input() disabled?: boolean;

  AvatarKind = AvatarKind;
  EntityNameEnum = EntityNameEnum;

  constructor(
    private locationService: LocationService
  ) {}

  @HostListener('click')
  onClick() {
    if (!this.disabled) {
      this.locationService.openLead(this.entity.id);
    }
  }

}
