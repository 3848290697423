import type { ComponentType } from '@angular/cdk/portal';
import { InjectionToken } from '@angular/core';

export enum ToastKind {
  Success = 'Success',
  Error = 'Error'
}

export interface ToastContent<T = any> {
  component: ComponentType<any>;
  data: T;
}

export interface ToastControl {
  close: () => void;
}

export const TOAST_CONTENT_DATA = new InjectionToken<{}>('TOAST_CONTENT_DATA');
export const TOAST_CONTROL = new InjectionToken<{}>('TOAST_CONTROL');

export const ToastCloseTimeout = 5000;
