<ppl-card-small [active]="active"
                [disabled]="true">
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="null"
                [kind]="AvatarKind.Small"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    {{person.email}}
  </ng-template>

  <ng-template #descriptionTemplate>
    <ng-container i18n="@@Unknown_contact">Unknown contact</ng-container>
  </ng-template>

  <ng-template #actionTemplate>
    <div class="action"
         pplPopover
         pplPopoverAlignEnd
         [pplPopoverDisabled]="createDisabled"
         [pplPopoverMenu]="createMenuItems"
         (pplPopoverMenuItemClick)="onCreateMenuItemClick($event)">
      <ppl-icon svgIcon="entity-action-add-blue"
                width="20px"
                height="20px"></ppl-icon>
    </div>
  </ng-template>
</ppl-card-small>
