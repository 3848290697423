<ppl-dashboard-card-list icon="menu-contact"
                         [items]="items"
                         [name]="items.length + ' contacts'">
  <ng-template #cardTemplate
               let-item
               let-active="active">
    <ng-container *ngIf="largeCard">
      <ppl-contact-card-large [entity]="item.entity"></ppl-contact-card-large>

      <ppl-actions-card [entity]="item.entity"></ppl-actions-card>
    </ng-container>

    <ppl-contact-card-small *ngIf="!largeCard"
                            [entity]="item.entity"
                            [active]="active"
                            (click)="onContactClick(item.entity)"></ppl-contact-card-small>
  </ng-template>
</ppl-dashboard-card-list>