<ppl-page>
  <ng-template #headerTemplate
               let-globalSearch="globalSearch">
    <ppl-page-detail-header [label]="'Contact' | t"
                            [globalSearch]="globalSearch"></ppl-page-detail-header>
  </ng-template>

  <ng-container *ngIf="data$ | async; let data">
    <div *ngIf="data.state === AsyncState.FETCHED"
         class="container">
      <ppl-contact-card-large [entity]="data.value.entity"
                              [disabled]="true"></ppl-contact-card-large>

      <ppl-actions-card [entity]="data.value.entity"></ppl-actions-card>

      <ppl-contact-fields-card [entity]="data.value.entity"
                               (emailChange)="onFieldChange(data.value, { email1: $event })"
                               (phoneChange)="onFieldChange(data.value, { phone1: $event })"
                               (locationChange)="onFieldChange(data.value, $event)"></ppl-contact-fields-card>

      <ppl-card-tabs>
        <ppl-card-tab label="Related"
                      i18n-label="@@Related">
          <ppl-opportunities-cards [entities]="data.value.relatedOpportunities"
                                   [parentEntity]="data.value.entity"></ppl-opportunities-cards>

          <ppl-leads-cards [entities]="data.value.relatedLeads"
                           [parentEntity]="data.value.entity"></ppl-leads-cards>

          <ppl-accounts-cards [entities]="data.value.relatedAccounts"
                              [parentEntity]="data.value.entity"></ppl-accounts-cards>
        </ppl-card-tab>

        <ppl-card-tab label="Activity"
                      i18n-label="@@Activity">
          <ppl-activities-cards [entities]="data.value.relatedActivities"
                                [parentEntity]="data.value.entity"></ppl-activities-cards>
        </ppl-card-tab>
      </ppl-card-tabs>

      <ppl-date-card [entity]="data.value.entity"></ppl-date-card>
    </div>

    <ppl-page-loading *ngIf="data.state === AsyncState.FETCHING"></ppl-page-loading>

    <ppl-entity-error *ngIf="data.state === AsyncState.ERROR"
                      [error]="data.error"></ppl-entity-error>
  </ng-container>
</ppl-page>
