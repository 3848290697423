import { Injectable } from '@angular/core';
import { ActionsUtilsService } from '@ppl/core';
import type { InitializeMobileCallAccountInput, InitializeMobileCallContactInput } from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { SpaceService } from '@ppl/space';
import { PplDialogService } from '@ppl/ui/dialog';
import { switchMap } from 'rxjs/operators';
import { PhoneCallComponent } from '../components/phone-call/phone-call.component';
import { gqlInitializeMobileCallAccountMutation, gqlInitializeMobileCallContactMutation } from './phone.graphql';

@Injectable()
export class PhoneService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private dialogService: PplDialogService,
    private spaceService: SpaceService
  ) {}

  startCall(options: StartCallOptions) {
    const input: InitializeMobileCallAccountInput | InitializeMobileCallContactInput = {
      id: options.entityId,
      name: options.entityName,
      phoneNumber: options.phoneNumber
    };

    this.actionsUtilsService.doAction(this.spaceService.gqlClient.mutate({
      mutation: ({
        [EntityNameEnum.Account]: gqlInitializeMobileCallAccountMutation,
        [EntityNameEnum.Contact]: gqlInitializeMobileCallContactMutation
      })[options.entityType],
      variables: {
        input
      }
    }).pipe(
      switchMap(() => {
        return this.dialogService.open<PhoneCallComponent>(PhoneCallComponent).afterClosed();
      })
    ));
  }

}

export interface StartCallOptions {
  entityId: string;
  entityName: string;
  entityType: EntityNameEnum;
  phoneNumber: string;
}
