import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ppl-page-header-button',
  templateUrl: './page-header-button.component.html',
  styleUrls: ['./page-header-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderButtonComponent {

  @Input() icon: string;
  @Input() @HostBinding('class.selected') selected?: boolean;

}
