import { Injectable, NgZone } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Subject } from 'rxjs';

@Injectable()
export class OutlookUtilsService {

  constructor(
    private ngZone: NgZone
  ) {}

  wrapAsync<T>(callback: (resultCallback: (result: Office.AsyncResult<T>) => void) => void, errorHandler?: { defaultValue: T, sentryMessage: string }) {
    const result$ = new Subject<T>();

    // Office 2016/IE 11 sometimes does not resolve the result without runOutsideAngular/setTimeout calls
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        callback(result => {
          this.ngZone.run(() => {
            if (result.status === Office.AsyncResultStatus.Failed) {
              if (errorHandler) {
                Sentry.captureException(new Error(`${errorHandler.sentryMessage} - ${JSON.stringify(result.error)}`));
                result$.next(errorHandler.defaultValue);
              } else {
                result$.error(new Error(JSON.stringify(result.error)));
              }
            } else {
              result$.next(result.value);
            }
          });
        });
      });
    });

    return result$.asObservable();
  }

}
