import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@ppl/core';
import { I18nModule } from '@ppl/i18n';
import { PplDialogModule } from '@ppl/ui/dialog';
import { PhoneCallComponent } from './components/phone-call/phone-call.component';
import { PhoneService } from './services/phone.service';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    I18nModule,
    PplDialogModule
  ],
  declarations: [
    PhoneCallComponent
  ],
  providers: [
    PhoneService
  ]
})
export class PhoneModule {}
