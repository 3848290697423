import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PplLocationPipe, PplNamePipe } from '@ppl/auth';
import { ContactEntity, EntityNameEnum, EntityPermsEnum } from '@ppl/graphql-space-api';
import { MemoizeLast } from '@ppl/utils';
import type { LocationData } from '../../../location/domain/location';
import { PhoneService } from '../../../phone/services/phone.service';

@Component({
  selector: 'ppl-contact-fields-card',
  templateUrl: './contact-fields-card.component.html',
  styleUrls: ['./contact-fields-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactFieldsCardComponent {

  @Input() entity: ContactEntity;

  @Output() emailChange = new EventEmitter<string>();
  @Output() phoneChange = new EventEmitter<string>();
  @Output() locationChange = new EventEmitter<LocationData>();

  EntityPermsEnum = EntityPermsEnum;

  @MemoizeLast<ContactFieldsCardComponent>(['entity'])
  get locationUrl() {
    return `https://bing.com/maps?q=${this.locationPipe.transform(this.entity)}`;
  }

  constructor(
    private locationPipe: PplLocationPipe,
    private namePipe: PplNamePipe,
    private phoneService: PhoneService
  ) {}

  onPhoneClick() {
    this.phoneService.startCall({
      entityId: this.entity.id,
      entityName: this.namePipe.transform(this.entity),
      entityType: EntityNameEnum.Contact,
      phoneNumber: this.entity.phone1
    });
  }

}
