<div class="icon">
  <ppl-icon [svgIcon]="icon"
            width="16px"
            height="16px"></ppl-icon>
</div>

<ng-container *ngIf="!editorVisible">
  <div class="value">
    <div *ngIf="value"
         class="value-text">
      <ng-content></ng-content>
    </div>

    <div *ngIf="!value"
         class="value-empty"
         [class.editable]="!disable"
         (click)="onEditClick()">
      {{emptyLabel}}
    </div>
  </div>

  <div *ngIf="!disabled && value"
       class="edit"
       (click)="onEditClick()">
    <ppl-icon svgIcon="inline-edit"
              width="16px"
              height="16px"></ppl-icon>
  </div>
</ng-container>

<div *ngIf="editorVisible"
     class="input">
  <ng-container *ngIf="!editorTemplate">
    <input pplInput
           autocomplete="off"
           [value]="value || ''"
           (keypress)="onKeyPress($event)" />

    <div class="save"
         (click)="onSaveClick()">
      <ppl-icon svgIcon="confirm-blue"
                width="16px"
                height="16px"></ppl-icon>
    </div>
  </ng-container>

  <ng-container *ngIf="editorTemplate">
    <ng-container *ngTemplateOutlet="editorTemplate; context: { valueChange: onValueChange }"></ng-container>
  </ng-container>
</div>