import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import type { CurrencyEntity, CurrencyForeignField } from '@ppl/graphql-space-api';
import type { PplSelectOption } from '@ppl/ui/select';
import { FormValueControl, getFormControlProvider, MemoizeLast } from '@ppl/utils';

@Component({
  selector: 'ppl-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    getFormControlProvider(() => CurrencyInputComponent)
  ]
})
@FormValueControl()
export class CurrencyInputComponent {

  @Input() currencies: CurrencyEntity[];
  @Input() base?: boolean;
  @Input() value: CurrencyForeignField | number;
  @Input() disabled?: boolean;

  @Output() valueChange = new EventEmitter<CurrencyForeignField | number>();

  Number = Number;

  @MemoizeLast<CurrencyInputComponent>(['base', 'value'])
  get inputValue() {
    return this.base ? this.value : (this.value as CurrencyForeignField).valueForeign;
  }

  @MemoizeLast<CurrencyInputComponent>(['currencies'])
  get currencyOptions() {
    return this.currencies.map<PplSelectOption>(currency => ({
      deleted: currency.isDeleted,
      label: currency.code,
      value: currency.id
    }));
  }

  @MemoizeLast<CurrencyInputComponent>(['currencies'])
  get baseCurrency() {
    return this.currencies.find(currency => currency.isBase);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  onForeignValueChange(valueForeign: number) {
    if (typeof this.value === 'number') {
      this.valueChange.emit(valueForeign);
    } else {
      this.valueChange.emit({
        ...this.value,
        valueForeign
      });
    }
  }

  onCurrencyValueChange(currencyId: string) {
    if (typeof this.value !== 'number') {
      this.valueChange.emit({
        ...this.value,
        currencyId
      });
    }
  }

}
