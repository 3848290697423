import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthModule } from '@ppl/auth';
import { PplEntityListModule } from '@ppl/components/entity-list';
import { PplAutocompletePickerModule } from '@ppl/ui/autocomplete-picker';
import { PplAutocompleteSelectModule } from '@ppl/ui/autocomplete-select';
import { PplCheckboxModule } from '@ppl/ui/checkbox';
import { PplMultipleAutocompleteModule } from '@ppl/ui/multiple-autocomplete';
import { PplSearchModule } from '@ppl/ui/search';
import { AvatarModule } from '../avatar/avatar.module';
import { EntityListComponent } from './components/entity-list/entity-list.component';
import { EntityMultipleSelectComponent } from './components/entity-multiple-select/entity-multiple-select.component';
import { EntityPickerComponent } from './components/entity-picker/entity-picker.component';
import { EntitySelectComponent } from './components/entity-select/entity-select.component';

@NgModule({
  imports: [
    AuthModule,
    AvatarModule,
    CommonModule,
    PplAutocompletePickerModule,
    PplAutocompleteSelectModule,
    PplCheckboxModule,
    PplEntityListModule,
    PplMultipleAutocompleteModule,
    PplSearchModule
  ],
  declarations: [
    EntityListComponent,
    EntityMultipleSelectComponent,
    EntityPickerComponent,
    EntitySelectComponent
  ],
  exports: [
    EntityListComponent,
    EntityMultipleSelectComponent,
    EntityPickerComponent,
    EntitySelectComponent
  ]
})
export class EntityControlModule {}
