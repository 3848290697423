<ppl-page [flexContent]="true">
  <ng-template #headerTemplate>
    <ppl-page-form-header *ngIf="kind === AddRecipientKind.Contact"
                          icon="entity-white-new-email"
                          label="Add contacts"
                          i18n-label="@@Add_contacts"></ppl-page-form-header>

    <ppl-page-form-header *ngIf="kind === AddRecipientKind.SalesTeam"
                          icon="entity-white-new-email"
                          label="Add sales team"
                          i18n-label="@@Add_sales_team"></ppl-page-form-header>
  </ng-template>

  <ng-container *ngIf="availableEntityTypes.length">
    <div class="container"
         [formGroup]="formGroup">
      <ppl-input-container label="Select contacts to add as recipients:"
                           i18n-label="@@Select_contacts_to_add_as_recipients:"
                           [pplFormControl]="formGroup.get('entityItems')">
        <ppl-entity-list-type [entityAdapters]="(kind === AddRecipientKind.SalesTeam) ? AddRecipientSalesTeamEntityListAdapters : AddRecipientContactEntityListAdapters"
                              [entityTypes]="availableEntityTypes"
                              [multipleSelection]="true"
                              [optionDisabled]="isOptionDisabled"
                              [optionTemplate]="entityOptionTemplate"
                              [optionTemplateRowHeight]="30"
                              formControlName="entityItems"></ppl-entity-list-type>
      </ppl-input-container>
    </div>

    <ppl-page-actions saveLabel="Add"
                      i18n-saveLabel="@@Add"
                      (cancelClick)="onCancelClick()"
                      (saveClick)="onAddClick()"></ppl-page-actions>
  </ng-container>

  <ppl-page-error *ngIf="!availableEntityTypes.length"
                  text="You do not have access to any entity"
                  i18n-text="@@You_do_not_have_access_to_any_entity"></ppl-page-error>
</ppl-page>

<ng-template #entityOptionTemplate
             let-option>
  <ppl-entity-control-item [disabled]="isOptionDisabled(option)"
                           [height]="30"
                           [name]="getEntityName(option.data.entity)"></ppl-entity-control-item>
</ng-template>
