import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplIconModule } from '@ppl/ui/icon';
import { AvatarModule } from '../avatar/avatar.module';
import { EntityControlItemComponent } from './components/entity-control-item/entity-control-item.component';

@NgModule({
  imports: [
    AvatarModule,
    CommonModule,
    PplIconModule
  ],
  declarations: [
    EntityControlItemComponent
  ],
  exports: [
    EntityControlItemComponent
  ]
})
export class EntityControlItemModule {}
