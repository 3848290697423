import { ChangeDetectorRef, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl } from '@ppl/utils';
import { PplDatePickerIntl } from '../date-picker';
import type { PplToggleGroupOption } from '../toggle-group';

@Component({
  selector: 'ppl-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplTimeInputComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplTimeInputComponent {

  @Input() value: number;

  @Output() valueChange = new EventEmitter<number>();

  TimePeriods = TimePeriods;

  get is24HourSystem() {
    return !this.datePickerIntl.dateTimeFormat.toLowerCase().endsWith('a');
  }

  get hoursValue() {
    if (this.is24HourSystem) {
      return Math.floor(this.value / 60);
    } else {
      return (Math.floor(this.value / 60) % 12) || 12;
    }
  }

  get minutesValue() {
    return this.value % 60;
  }

  get timePeriod() {
    return (Math.floor(this.value / 60) < 12) ? TimePeriod.AM : TimePeriod.PM;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private datePickerIntl: PplDatePickerIntl
  ) {}

  onHoursChange(value: number) {
    this.valueChange.emit(this.normalizeHours(value) * 60 + this.minutesValue);
  }

  onMinutesChange(value: number) {
    this.valueChange.emit(this.normalizeHours(this.hoursValue) * 60 + value);
  }

  onTimePeriodChange(value: TimePeriod) {
    if (this.timePeriod === TimePeriod.AM && value === TimePeriod.PM) {
      this.valueChange.emit(this.value + 12 * 60);
    } else if (this.timePeriod === TimePeriod.PM && value === TimePeriod.AM) {
      this.valueChange.emit(this.value - 12 * 60);
    }
  }

  private normalizeHours(value: number) {
    if (!this.is24HourSystem) {
      if (this.timePeriod === TimePeriod.PM && value < 12) {
        return value + 12;
      } else if (this.timePeriod === TimePeriod.AM && value === 12) {
        return 0;
      }
    }

    return value;
  }

}

enum TimePeriod {
  AM = 'AM',
  PM = 'PM'
}

const TimePeriods: PplToggleGroupOption[] = [{
  label: 'AM',
  value: TimePeriod.AM
}, {
  label: 'PM',
  value: TimePeriod.PM
}];
