<div class="header">
  {{label}} <ng-container *ngIf="count !== null">({{count}})</ng-container>

  <div *ngIf="displayCreate"
       class="create-button"
       pplPopover
       pplPopoverAlignEnd
       [pplPopoverDisabled]="!menuItems"
       [pplPopoverMenu]="menuItems"
       (pplPopoverMenuItemClick)="menuItemClick.emit($event)"
       (click)="createClick.emit()">
    <ppl-icon svgIcon="entity-action-add-gray"
              width="20px"
              height="20px"></ppl-icon>
  </div>
</div>

<ng-content></ng-content>