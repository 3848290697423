import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { UntypedFormControl} from '@angular/forms';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import type { TFormGroup } from '@ppl/ui/form';
import { TFormBuilder } from '@ppl/ui/form';
import { isFormValid, PplValidators, Unsubscribe } from '@ppl/utils';
import type { Subscription } from 'rxjs';
import { LocationService } from '../../../../services/location.service';
import { AvatarKind } from '../../../shared/avatar/domain/avatar';
import { EmailKind, MaxDocumentNameLength } from '../../../shared/email/domain/email';
import { EmailService } from '../../../shared/email/services/email.service';
import type { EntityListTypeItem } from '../../../shared/entity/components/entity-list-type/entity-list-type.component';
import { EntityListAdapters } from '../../../shared/entity/domain/entity-list';
import { EntityService } from '../../../shared/entity/services/entity.service';
import { OutlookService } from '../../../shared/outlook/services/outlook.service';
import type { SaveEmailAttachment } from '../save-email-attachments/save-email-attachments.component';

@Component({
  selector: 'ppl-save-email',
  templateUrl: './save-email.component.html',
  styleUrls: ['./save-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Unsubscribe()
export class SaveEmailComponent {

  formGroup: TFormGroup<SaveEmailFormValue>;

  kind = this.route.snapshot.queryParamMap.get('kind') as EmailKind;

  attachments = this.outlookService.getAttachments();

  availableEntityTypes = this.entityService.filterEntityTypes([
    EntityNameEnum.Account,
    EntityNameEnum.Contact,
    EntityNameEnum.Lead,
    EntityNameEnum.Opportunity
  ]);

  subjectSubscription: Subscription;

  AvatarKind = AvatarKind;
  EmailKind = EmailKind;
  EntityNameEnum = EntityNameEnum;
  EntityListAdapters = EntityListAdapters;

  constructor(
    private emailService: EmailService,
    private entityService: EntityService,
    private formBuilder: TFormBuilder,
    private locationService: LocationService,
    private outlookService: OutlookService,
    private route: ActivatedRoute
  ) {
    this.formGroup = this.formBuilder.group<SaveEmailFormValue>({
      attachments: [{
        value: this.attachments.map(attachment => ({
          ...attachment,
          selected: true
        })),
        disabled: this.kind !== EmailKind.Attachments
      }, (formControl: UntypedFormControl) => {
        if (formControl.value?.every((attachment: SaveEmailAttachment) => !attachment.selected)) {
          return { atLeastOneOption: true };
        }

        return null;
      }],
      entityItems: [[], PplValidators.nonEmptyOptionArray],
      fileName: [{
        value: '',
        disabled: this.kind !== EmailKind.Document
      }, [
        Validators.required,
        Validators.maxLength(MaxDocumentNameLength)
      ]],
      saveAttachments: [!!this.attachments.length]
    });

    this.subjectSubscription = this.outlookService.getSubject().subscribe(subject => {
      this.formGroup.get('fileName').setValue(subject.slice(0, MaxDocumentNameLength), { emitEvent: false });
    });
  }

  onCancelClick() {
    this.locationService.goBack();
  }

  onSaveClick() {
    if (!isFormValid(this.formGroup)) {
      return;
    }

    const formValue = this.formGroup.getRawValue();

    switch (this.kind) {
      case EmailKind.Message:
        this.emailService.saveAsMessage({
          relations: formValue.entityItems,
          withAttachments: formValue.saveAttachments
        }).subscribe(() => {
          this.locationService.goBack();
        });
        break;
      case EmailKind.Document:
        this.emailService.saveAsDocument({
          relations: formValue.entityItems,
          fileName: formValue.fileName
        }).subscribe(() => {
          this.locationService.goBack();
        });
        break;
      case EmailKind.Attachments:
        this.emailService.saveAttachments({
          attachments: formValue.attachments.filter(attachment => {
            return attachment.selected;
          }),
          relations: formValue.entityItems
        }).subscribe(() => {
          this.locationService.goBack();
        });
    }
  }

}

interface SaveEmailFormValue {
  attachments: SaveEmailAttachment[];
  entityItems: EntityListTypeItem[];
  fileName: string;
  saveAttachments: boolean;
}
