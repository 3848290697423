<div class="date">
  <div class="label"
       i18n="@@Created">Created</div>

  <div class="value">{{entity.created | pplDate}}</div>
</div>

<div class="date">
  <div class="label"
       i18n="@@Modified">Modified</div>

  <div class="value">{{entity.modified | pplDate}}</div>
</div>