<ppl-card-group [label]="'Leads' | t"
                [count]="entities.length"
                [displayCreate]="displayCreate"
                (createClick)="onCreateClick()">
  <ppl-card-filter [value]="filterValue"
                   (valueChange)="onFilterValueChange($event)">
    <ppl-card-filter-tab color="#004578"
                         label="Open"
                         i18n-label="@@EnumLeadStatusEnum_Open"
                         [count]="openEntities.length"
                         [value]="LeadStatusEnum.Open"></ppl-card-filter-tab>

    <ppl-card-filter-tab color="#e81123"
                         label="Lost"
                         i18n-label="@@EnumLeadStatusEnum_Lost"
                         [count]="lostEntities.length"
                         [value]="LeadStatusEnum.Lost"></ppl-card-filter-tab>
  </ppl-card-filter>

  <ppl-card-list *ngIf="filteredEntities.length !== 0"
                 [entities]="filteredEntities">
    <ng-template #cardTemplate
                 let-entity>
      <ppl-lead-card-medium [entity]="entity"
                            (click)="onCardClick(entity)"></ppl-lead-card-medium>
    </ng-template>
  </ppl-card-list>

  <ng-container *ngIf="filteredEntities.length === 0">
    <ng-container *ngIf="parentEntity.__typename === 'AccountEntity'">
      <ppl-card-message *ngIf="!filterValue"
                        i18n="@@Account_has_no_related_leads">{{ 'Account' | t }} has no related {{ 'Leads' | t }}</ppl-card-message>

      <ppl-card-message *ngIf="filterValue === LeadStatusEnum.Open"
                        i18n="@@Account_has_no_related_open_leads">{{ 'Account' | t }} has no related open {{ 'Leads' | t }}</ppl-card-message>

      <ppl-card-message *ngIf="filterValue === LeadStatusEnum.Lost"
                        i18n="@@Account_has_no_related_lost_leads">{{ 'Account' | t }} has no related lost {{ 'Leads' | t }}</ppl-card-message>
    </ng-container>

    <ng-container *ngIf="parentEntity.__typename === 'ContactEntity'">
      <ppl-card-message *ngIf="!filterValue"
                        i18n="@@Contact_has_no_related_leads">{{ 'Contact' | t }} has no related {{ 'Leads' | t }}</ppl-card-message>

      <ppl-card-message *ngIf="filterValue === LeadStatusEnum.Open"
                        i18n="@@Contact_has_no_related_open_leads">{{ 'Contact' | t }} has no related open {{ 'Leads' | t }}</ppl-card-message>

      <ppl-card-message *ngIf="filterValue === LeadStatusEnum.Lost"
                        i18n="@@Contact_has_no_related_lost_leads">{{ 'Contact' | t }} has no related lost {{ 'Leads' | t }}</ppl-card-message>
    </ng-container>
  </ng-container>
</ppl-card-group>
