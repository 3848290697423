import { gql } from '@ppl/utils';
import { AccountCardMediumFields, AppointmentCardMediumFields, ContactCardLargeFields, LeadCardMediumFields, OpportunityCardMediumFields, TaskCardMediumFields } from '../../shared/entity-card/services/entity-card.graphql';

export const gqlFetchContactData = gql`
  query fetchContactData($entityId: UUID!) {
    screens {
      extractViews {
        contact(entityId: $entityId) {
          entity {
            ...ContactCardLargeFields
            accountRelations {
              edges {
                node {
                  id
                  account {
                    ...AccountCardMediumFields
                  }
                  isPrimary
                }
              }
            }
          }
          leads(orderBy: [{ created: Desc }]) {
            edges {
              node {
                id
                lead {
                  ...LeadCardMediumFields
                }
              }
            }
          }
          opportunities(orderBy: [{ created: Desc }]) {
            edges {
              node {
                id
                oppty {
                  ...OpportunityCardMediumFields
                }
              }
            }
          }
          plannedActivities {
            edges {
              node {
                id
                appointment {
                  ...AppointmentCardMediumFields
                }
                task {
                  ...TaskCardMediumFields
                }
              }
            }
          }
          unplannedTasks {
            edges {
              node {
                id
                task {
                  ...TaskCardMediumFields
                }
              }
            }
          }
        }
      }
    }
  }
  ${ContactCardLargeFields}
  ${AccountCardMediumFields}
  ${AppointmentCardMediumFields}
  ${LeadCardMediumFields}
  ${OpportunityCardMediumFields}
  ${TaskCardMediumFields}
`;
