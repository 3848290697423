<ppl-card-medium>
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="EntityNameEnum.Account"
                [kind]="AvatarKind.Medium"
                [pictureUrl]="entity.picture?.url"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    <div class="name">
      <div class="name-text">
        {{entity.name}}
      </div>

      <ppl-badge *ngIf="isPrimary"
                 color="#0078d7"
                 i18n="@@Primary">Primary</ppl-badge>

      <ppl-badge *ngIf="entity.isDeleted"
                 color="#e81123"
                 i18n="@@Deleted">Deleted</ppl-badge>
    </div>
  </ng-template>

  <ng-template #descriptionTemplate>
    {{entity | pplLocation}}
  </ng-template>
</ppl-card-medium>