import type { OnInit} from '@angular/core';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getUserSettings } from '@ppl/auth';
import { fetchAsyncValue } from '@ppl/core';
import { AppointmentFieldsEnum } from '@ppl/domain';
import type { AsyncValue } from '@ppl/store';
import { AsyncState } from '@ppl/store';
import moment from 'moment';
import type { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocationService } from '../../../../services/location.service';
import { CoreStore } from '../../../../store/core.store';
import { mergeFormValue } from '../../../shared/entity-form-api/domain/merge-form-value';
import { EntityFormComponent } from '../../../shared/entity-form/components/entity-form/entity-form.component';
import type { EntityFormControl } from '../../../shared/entity-form/domain/controls';
import type { CanLeaveGuardComponent } from '../../../shared/entity-form/router/can-leave-entity-form.service';
import type { AppointmentFormData, AppointmentFormValue } from '../../domain/appointment-form';
import { AppointmentFormService } from '../../services/appointment-form.service';

@Component({
  selector: 'ppl-appointment-form',
  templateUrl: './appointment-form.component.html',
  styleUrls: ['./appointment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppointmentFormComponent implements OnInit, CanLeaveGuardComponent {

  @ViewChild(EntityFormComponent) entityForm: EntityFormComponent;

  data$: Observable<AsyncValue<AppointmentFormData>>;

  AsyncState = AsyncState;

  controls: EntityFormControl[];
  valid = true;

  value: AppointmentFormValue;
  valueChanged = false;

  customFieldsVisible = true;

  id = this.route.snapshot.paramMap.get('id');

  accountId = this.route.snapshot.queryParamMap.get('accountId');
  contactId = this.route.snapshot.queryParamMap.get('contactId');
  leadId = this.route.snapshot.queryParamMap.get('leadId');
  opportunityId = this.route.snapshot.queryParamMap.get('opportunityId');

  constructor(
    private appointmentFormService: AppointmentFormService,
    private locationService: LocationService,
    private route: ActivatedRoute,
    private store: CoreStore
  ) {}

  ngOnInit() {
    this.data$ = fetchAsyncValue(this.appointmentFormService.fetchData({
      id: this.id,
      relatedAccountId: this.accountId,
      relatedContactId: this.contactId,
      relatedLeadId: this.leadId,
      relatedOpportunityId: this.opportunityId
    }).pipe(
      tap(data => {
        if (data) {
          this.value = this.appointmentFormService.getValue(data, data.entity?.activityTypeId || this.appointmentFormService.getDefaultFormId());
          this.controls = this.appointmentFormService.getControls(data, this.value.activityTypeId);
        }
      })
    ));
  }

  save(data: AppointmentFormData) {
    if (data.entity) {
      return this.appointmentFormService.updateEntity(this.value, data);
    } else {
      return this.appointmentFormService.createEntity(this.value, data);
    }
  }

  onValueChange(data: AppointmentFormData, value: AppointmentFormValue) {
    // Synchronize StartDate and EndDate fields
    const userSettings = this.store.get(getUserSettings);

    const startDate = this.value[AppointmentFieldsEnum.StartDateField];
    let nextStartDate = value[AppointmentFieldsEnum.StartDateField];

    const endDate = this.value[AppointmentFieldsEnum.EndDateField];
    let nextEndDate = value[AppointmentFieldsEnum.EndDateField];

    if (nextStartDate !== startDate) {
      if (nextStartDate) {
        const dtSeconds = (startDate && endDate) ? Math.max(moment(endDate).diff(startDate, 'seconds'), userSettings.defaultAppointmentDuration) : userSettings.defaultAppointmentDuration;

        nextEndDate = moment(nextStartDate).add(dtSeconds, 'seconds').toISOString();
      }

      this.value = {
        ...value,
        [AppointmentFieldsEnum.StartDateField]: nextStartDate,
        [AppointmentFieldsEnum.EndDateField]: nextEndDate
      };
    } else if (nextEndDate !== endDate) {
      if (nextStartDate && nextEndDate && moment(nextEndDate).diff(nextStartDate, 'seconds') < 0) {
        nextStartDate = moment(nextEndDate).subtract(userSettings.defaultAppointmentDuration, 'seconds').toISOString();
      }

      this.value = {
        ...value,
        [AppointmentFieldsEnum.StartDateField]: nextStartDate,
        [AppointmentFieldsEnum.EndDateField]: nextEndDate
      };
    } else if (value.activityTypeId !== this.value.activityTypeId) {
      const prevFormInitValue = this.appointmentFormService.getValue(data, this.value.activityTypeId);

      this.value = mergeFormValue(prevFormInitValue, this.value, this.appointmentFormService.getValue(data, value.activityTypeId));
      this.controls = this.appointmentFormService.getControls(data, value.activityTypeId);
    } else {
      this.value = value;
    }

    this.valueChanged = true;
  }

  onSaveClick(data: AppointmentFormData) {
    this.valid = this.entityForm.isValid();

    if (!this.valid) {
      this.customFieldsVisible = true;

      return;
    }

    this.save(data).subscribe(() => {
      this.valueChanged = false;
      this.locationService.goBack();
    });
  }

  onCancelClick() {
    this.locationService.goBack();
  }

}
