<ppl-card-medium>
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="EntityNameEnum.Lead"
                [kind]="AvatarKind.Medium"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    {{entity.name}}
  </ng-template>

  <ng-template #descriptionTemplate>
    <ppl-icon svgIcon="entity-phantom-contact-16"
              width="12px"
              height="12px"></ppl-icon>

    {{entity.owner?.name}}
  </ng-template>

  <ng-template #actionTemplate>
    <div *ngIf="entity.status === LeadStatusEnum.Open"
         class="status"
         i18n="@@EnumLeadStatusEnum_Open">Open</div>

    <div *ngIf="entity.status === LeadStatusEnum.Lost"
         class="status lost"
         i18n="@@EnumLeadStatusEnum_Lost">Lost</div>
  </ng-template>
</ppl-card-medium>