import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { PplDialogRef } from '@ppl/ui/dialog';
import type { TFormGroup } from '@ppl/ui/form';
import { TFormBuilder } from '@ppl/ui/form';

@Component({
  selector: 'ppl-set-reminder',
  templateUrl: './set-reminder.component.html',
  styleUrls: ['./set-reminder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetReminderComponent {

  formGroup: TFormGroup<SetReminderFormValue>;

  constructor(
    private dialogRef: PplDialogRef<SetReminderComponent, string>,
    private formBuilder: TFormBuilder
  ) {
    this.formGroup = this.formBuilder.group<SetReminderFormValue>({
      dateTime: [null, Validators.required]
    });
  }

  onSave() {
    this.dialogRef.close(this.formGroup.get('dateTime').value);
  }

}

export interface SetReminderFormValue {
  dateTime: string;
}
