import type { OnInit} from '@angular/core';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fetchAsyncValue } from '@ppl/core';
import type { AsyncValue } from '@ppl/store';
import { AsyncState } from '@ppl/store';
import type { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocationService } from '../../../../services/location.service';
import { mergeFormValue } from '../../../shared/entity-form-api/domain/merge-form-value';
import { EntityFormComponent } from '../../../shared/entity-form/components/entity-form/entity-form.component';
import type { EntityFormControl } from '../../../shared/entity-form/domain/controls';
import type { CanLeaveGuardComponent } from '../../../shared/entity-form/router/can-leave-entity-form.service';
import type { LeadFormData, LeadFormValue } from '../../domain/lead-form';
import { LeadFormService } from '../../services/lead-form.service';

@Component({
  selector: 'ppl-lead-form',
  templateUrl: './lead-form.component.html',
  styleUrls: ['./lead-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeadFormComponent implements OnInit, CanLeaveGuardComponent {

  @ViewChild(EntityFormComponent) entityForm: EntityFormComponent;

  data$: Observable<AsyncValue<LeadFormData>>;

  AsyncState = AsyncState;

  controls: EntityFormControl[];
  valid = true;

  value: LeadFormValue;
  valueChanged = false;

  customFieldsVisible = true;

  id = this.route.snapshot.paramMap.get('id');

  accountId = this.route.snapshot.queryParamMap.get('accountId');
  contactId = this.route.snapshot.queryParamMap.get('contactId');

  constructor(
    private leadFormService: LeadFormService,
    private locationService: LocationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.data$ = fetchAsyncValue(this.leadFormService.fetchData({
      id: this.id,
      relatedAccountId: this.accountId,
      relatedContactId: this.contactId
    }).pipe(
      tap(data => {
        if (data) {
          this.value = this.leadFormService.getValue(
            data,
            data.entity ? data.entity.step.leadProcessId : this.leadFormService.getDefaultLeadProcessId(),
            data.entity ? data.entity.leadTypeId : this.leadFormService.getDefaultFormId(this.leadFormService.getDefaultLeadProcessId())
          );

          this.controls = this.leadFormService.getControls(data, this.value.leadProcessId, this.value.leadTypeId);
        }
      })
    ));
  }

  save(data: LeadFormData) {
    if (data.entity) {
      return this.leadFormService.updateEntity(this.value, data);
    } else {
      return this.leadFormService.createEntity(this.value, data);
    }
  }

  onValueChange(data: LeadFormData, value: LeadFormValue) {
    if (value.leadTypeId !== this.value.leadTypeId || value.leadProcessId !== this.value.leadProcessId) {
      const prevFormInitValue = this.leadFormService.getValue(data, this.value.leadProcessId, this.value.leadTypeId);

      const formId = (value.leadTypeId !== this.value.leadTypeId) ? value.leadTypeId : this.leadFormService.getDefaultFormId(value.leadProcessId);

      this.value = mergeFormValue(prevFormInitValue, this.value, this.leadFormService.getValue(data, value.leadProcessId, formId));
      this.controls = this.leadFormService.getControls(data, value.leadProcessId, formId);
    } else {
      this.value = value;
    }

    this.valueChanged = true;
  }

  onSaveClick(data: LeadFormData) {
    this.valid = this.entityForm.isValid();

    if (!this.valid) {
      this.customFieldsVisible = true;

      return;
    }

    this.save(data).subscribe(() => {
      this.valueChanged = false;
      this.locationService.goBack();
    });
  }

  onCancelClick() {
    this.locationService.goBack();
  }

}
