<ppl-card-group label="Linked Items"
                i18n-label="@@Linked_Items"
                [count]="entities.length"
                [displayCreate]="false">
  <ppl-card-list *ngIf="entities.length !== 0"
                 [entities]="entities">
    <ng-template #cardTemplate
                 let-entity>
      <ppl-account-card-medium *ngIf="entity.account"
                               [entity]="entity.account"
                               (click)="onAccountCardClick(entity.account)"></ppl-account-card-medium>

      <ppl-contact-card-medium *ngIf="entity.contact"
                               [entity]="entity.contact"
                               (click)="onContactCardClick(entity.contact)"></ppl-contact-card-medium>

      <ppl-lead-card-medium *ngIf="entity.lead"
                            [entity]="entity.lead"
                            (click)="onLeadCardClick(entity.lead)"></ppl-lead-card-medium>

      <ppl-opportunity-card-medium *ngIf="entity.opportunity"
                                   [entity]="entity.opportunity"
                                   (click)="onOpportunityCardClick(entity.opportunity)"></ppl-opportunity-card-medium>

      <ng-container *ngIf="!entity.account && !entity.contact && !entity.lead && !entity.opportunity">
        <ppl-unavailable-card-medium *ngIf="accounts.includes(entity)"
                                     [entityType]="EntityNameEnum.Account"
                                     [name]="'Unknown_Account' | t"></ppl-unavailable-card-medium>

        <ppl-unavailable-card-medium *ngIf="contacts.includes(entity)"
                                     [entityType]="EntityNameEnum.Contact"
                                     [name]="'Unknown_Contact' | t"></ppl-unavailable-card-medium>

        <ppl-unavailable-card-medium *ngIf="leads.includes(entity)"
                                     [entityType]="EntityNameEnum.Lead"
                                     [name]="'Unknown_Lead' | t"></ppl-unavailable-card-medium>

        <ppl-unavailable-card-medium *ngIf="opportunities.includes(entity)"
                                     [entityType]="EntityNameEnum.Opportunity"
                                     [name]="'Unknown_Opportunity' | t"></ppl-unavailable-card-medium>
      </ng-container>
    </ng-template>
  </ppl-card-list>

  <ng-container *ngIf="entities.length === 0">
    <ppl-card-message *ngIf="parentEntity.__typename === 'AppointmentEntity'"
                      i18n="@@Appointment_has_no_related_linked_items">Appointment has no related linked items</ppl-card-message>

    <ppl-card-message *ngIf="parentEntity.__typename === 'TaskEntity'"
                      i18n="@@Task_has_no_related_linked_items">Task has no related linked items</ppl-card-message>
  </ng-container>
</ppl-card-group>
