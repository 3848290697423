<ppl-card-small [active]="active"
                [disabled]="true">
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="EntityNameEnum.Client"
                [kind]="AvatarKind.Small"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    <ng-container *ngIf="direction === OutlookItemDirection.Received"
                  i18n="@@Me">Me</ng-container>

    <ng-container *ngIf="direction === OutlookItemDirection.Sent"
                  i18n="@@I_wrote_this_email">I wrote this email</ng-container>
  </ng-template>
</ppl-card-small>