import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@ppl/auth';
import { CoreModule } from '@ppl/core';
import { PplCheckboxModule } from '@ppl/ui/checkbox';
import { PplDialogModule } from '@ppl/ui/dialog';
import { PplInputModule } from '@ppl/ui/input';
import { PplSelectModule } from '@ppl/ui/select';
import { EmailModule } from '../shared/email/email.module';
import { EntityControlItemModule } from '../shared/entity-control-item/entity-control-item.module';
import { EntityControlModule } from '../shared/entity-control/entity-control.module';
import { EntityGridModule } from '../shared/entity-grid/entity-grid.module';
import { EntityModule } from '../shared/entity/entity.module';
import { OutlookModule } from '../shared/outlook/outlook.module';
import { PageModule } from '../shared/page/page.module';
import { AttachmentNameComponent } from './components/attachment-name/attachment-name.component';
import { SaveEmailAttachmentsComponent } from './components/save-email-attachments/save-email-attachments.component';
import { SaveEmailComponent } from './components/save-email/save-email.component';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    CoreModule,
    EmailModule,
    EntityControlModule,
    EntityControlItemModule,
    EntityGridModule,
    EntityModule,
    OutlookModule,
    PageModule,
    PplCheckboxModule,
    PplDialogModule,
    PplInputModule,
    PplSelectModule,
    ReactiveFormsModule
  ],
  declarations: [
    AttachmentNameComponent,
    SaveEmailComponent,
    SaveEmailAttachmentsComponent
  ],
  exports: [
    SaveEmailComponent
  ]
})
export class SaveEmailModule {}
