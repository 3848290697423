import { CustomError, hasWriteLockError, UserAlertError } from '@ppl/utils';
import type { Store } from '@ppl/store';
import * as Sentry from '@sentry/browser';

export function initSentry(options: InitSentryOptions) {
  if (!options.enabled) {
    return;
  }

  Sentry.init({
    dsn: options.dsn,
    release: options.release,
    environment: options.environment,
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (breadcrumb.category.startsWith('ui')) {
        // For interaction events, find closest element with data-test attribute and add it to the message
        const target = hint.event.target as HTMLElement;

        if (target?.closest) {
          const closestNamedElement = target.closest('*[data-test]') as HTMLElement;

          if (closestNamedElement?.dataset) {
            breadcrumb.data = breadcrumb.data || {};
            breadcrumb.data['closest'] = closestNamedElement.dataset.test;
          }
        }
      } else if (breadcrumb.category.startsWith('xhr')) {
        // For XHR events, try to add operationName from GQL requests
        if (hint?.input) {
          try {
            const parsedHintInput = JSON.parse(hint.input);

            if (parsedHintInput && parsedHintInput.operationName) {
              breadcrumb.data['operationName'] = parsedHintInput.operationName;
            }
          } catch (ex) { }
        }
      }

      return breadcrumb;
    },
    beforeSend: (event, hint) => {
      if (hint.originalException) {
        const ex: any = hint.originalException;

        // Exclude:
        // - 0 Unknown errors (? network offline)
        // - 401 Unauthorized errors
        // - 400/149 Subscription expired errors
        // - "ChunkLoadError" (? loading stopped by user, or network is offline at the moment of opening lazy loaded module like Insights)
        // - "HttpErrorResponse" (errors thrown by Angular Http module)
        // - UserAlert and Custom errors
        // - Logical and ignored api error codes in GraphQL responses
        // - Write lock errors
        if (
          (ex && ex.rejection && ex.rejection.networkError && (ex.rejection.networkError.status === 0 || ex.rejection.networkError.status === 401))
          || (ex && ex.rejection && ex.rejection.networkError && ex.rejection.networkError.status === 400 && ex.rejection.networkError.error && ex.rejection.networkError.error.errorcode === 149)
          || (ex && ex.networkError && (ex.networkError.status === 0 || ex.networkError.status === 401))
          || (ex && ex.networkError && ex.networkError.status === 400 && ex.networkError.error && ex.networkError.error.errorcode === 149)
          || (ex && ex.rejection && ex.rejection.name === 'ChunkLoadError')
          || (ex && ex.name === 'ChunkLoadError')
          || (ex && ex.name === 'HttpErrorResponse' && ex.status === 0)
          || (ex && (ex instanceof UserAlertError || ex instanceof CustomError))
          || (options.ignoreApiErrorCodes && ex && Array.isArray(ex.graphQLErrors) && ex.graphQLErrors.length && ex.graphQLErrors.every(error => {
            return error && error.api_error && options.ignoreApiErrorCodes.includes(error.api_error.code);
          }))
          || hasWriteLockError(ex)
        ) {
          return null;
        }
      }

      return event;
    },
    ignoreErrors: [
      'ResizeObserver loop limit exceeded', // https://stackoverflow.com/a/50387233
      'ResizeObserver loop completed with undelivered notifications.', // Same as above, but in Firefox
      'NS_ERROR_NOT_INITIALIZED', // Random Firefox error
      'safari-extension',
      'chmln'
    ]
  });
}

export function initSentryStoreListener(store: Store<any, any>) {
  store.setListener((state, action: any) => {
    const data = {
      type: action.type.replace('Auth', 'Au-th')
    };

    if (action.payload) {
      if (typeof action.payload === 'string' || typeof action.payload === 'number' || typeof action.payload === 'boolean') {
        data['payload'] = action.payload;
      } else {
        Object.keys(action.payload).filter(key => ActionsPayloadWhitelist.includes(key)).forEach(key => {
          data[`payload_${key}`] = action.payload[key];
        });
      }
    }

    Sentry.addBreadcrumb({
      category: 'store',
      data
    });
  });
}

const ActionsPayloadWhitelist = ['screenPath', 'state', 'id', 'parentId', 'selection'];

interface InitSentryOptions {
  dsn: string;
  enabled: boolean;
  environment: string;
  release: string;
  ignoreApiErrorCodes?: number[];
}
