import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'ppl-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageComponent {

  @Input() flexContent?: boolean;

  @ContentChild('headerTemplate', { static: false }) headerTemplate: TemplateRef<any>;

  globalSearchVisible = false;

  onGlobalSearchClick() {
    this.globalSearchVisible = true;
  }

  onGlobalSearchListClose() {
    this.globalSearchVisible = false;
  }

}
