import type { EntityNameEnum } from '@ppl/graphql-space-api';

export interface SuccessToastData {
  entityType: EntityNameEnum;
  id: string;
  operation: SuccessToastOperation;
}

export enum SuccessToastOperation {
  Create = 'Create',
  Update = 'Update'
}
