import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { AvatarKind } from '../../../../shared/avatar/domain/avatar';

@Component({
  selector: 'ppl-entity-control-item',
  templateUrl: './entity-control-item.component.html',
  styleUrls: ['./entity-control-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityControlItemComponent {

  @Input() avatarKind?: AvatarKind;
  @Input() @HostBinding('class.disabled') disabled?: boolean;
  @Input() entityType?: EntityNameEnum;
  @Input() @HostBinding('style.height.px') height: number;
  @Input() name: string;
  @Input() description?: string;
  @Input() descriptionIcon?: string;
  @Input() pictureUrl?: string;

}
