import type { Form, FormField } from '@ppl/graphql-space-api';

export function getFormFields(form: Form): FormField[] {
  const formFields: FormField[] = [];

  form.items.forEach(formItem => {
    if (formItem.__typename === 'FormContainer') {
      formItem.columns.forEach(column => {
        column.items.forEach(item => {
          if (item.__typename === 'FormField') {
            formFields.push(item);
          }
        });
      });
    }
  });

  return formFields;
}
