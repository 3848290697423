import { Injectable } from '@angular/core';
import type { Observable} from 'rxjs';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import type { OutlookAttachment, OutlookPerson } from '../domain/outlook';
import { OutlookItemDirection, OutlookItemMode, OutlookItemType } from '../domain/outlook';
import { OutlookUtilsService } from './outlook-utils.service';

@Injectable()
export class OutlookService {

  constructor(
    private outlookUtilsService: OutlookUtilsService
  ) {}

  isItemAvailable(): boolean {
    return !!Office.context.mailbox.item;
  }

  getItemMode(): OutlookItemMode | null {
    if (!this.isItemAvailable()) {
      return null;
    }

    // Office.js does not indicate mode (Read or Compose), so this is a simple hack to detect it
    if (typeof Office.context.mailbox.item.subject === 'string') {
      return OutlookItemMode.Read;
    } else {
      return OutlookItemMode.Compose;
    }
  }

  getItemType(): OutlookItemType | null {
    if (!this.isItemAvailable()) {
      return null;
    }

    if (Office.context.mailbox.item.itemType === 'appointment') {
      return OutlookItemType.Appointment;
    } else {
      return OutlookItemType.Message;
    }
  }

  getItemDirection(): Observable<OutlookItemDirection> {
    return this.isSenderUser().pipe(
      map(isSenderUser => isSenderUser ? OutlookItemDirection.Sent : OutlookItemDirection.Received)
    );
  }

  getId(): string {
    return Office.context.mailbox.item.itemId;
  }

  getSender(): Observable<OutlookPerson> {
    if (this.getItemMode() === OutlookItemMode.Read) {
      return this.getSenderPerson().pipe(
        map(person => {
          if (person.email) {
            return person;
          }

          return this.getUser();
        })
      );
    }

    return of(this.getUser());
  }

  getToRecipients(): Observable<OutlookPerson[]> {
    const itemType = this.getItemType();

    if (itemType === OutlookItemType.Message) {
      if (this.getItemMode() === OutlookItemMode.Read) {
        return of(Office.context.mailbox.item.to.map(value => this.getPerson(value)));
      } else {
        return this.outlookUtilsService.wrapAsync<Office.EmailAddressDetails[]>(callback => {
          // Note: Temporary debug
          if (!Office.context.mailbox.item.to.getAsync) {
            console.log('to', Array.isArray(Office.context.mailbox.item.to), typeof Office.context.mailbox.item.to, this.getItemMode());
          }

          Office.context.mailbox.item.to.getAsync(callback);
        }).pipe(
          map(result => result.map(value => this.getPerson(value)))
        );
      }
    } else if (itemType === OutlookItemType.Appointment) {
      if (this.getItemMode() === OutlookItemMode.Read) {
        return of(Office.context.mailbox.item.requiredAttendees.map(value => this.getPerson(value)));
      } else {
        return this.outlookUtilsService.wrapAsync<Office.EmailAddressDetails[]>(callback => {
          Office.context.mailbox.item.requiredAttendees.getAsync(callback);
        }).pipe(
          map(result => result.map(value => this.getPerson(value)))
        );
      }
    }
  }

  getCcRecipients(): Observable<OutlookPerson[]> {
    const itemType = this.getItemType();

    if (itemType === OutlookItemType.Message) {
      if (this.getItemMode() === OutlookItemMode.Read) {
        return of(Office.context.mailbox.item.cc.map(value => this.getPerson(value)));
      } else {
        return this.outlookUtilsService.wrapAsync<Office.EmailAddressDetails[]>(callback => {
          Office.context.mailbox.item.cc.getAsync(callback);
        }).pipe(
          map(result => result.map(value => this.getPerson(value)))
        );
      }
    } else {
      return of([]);
    }
  }

  getSubject(): Observable<string> {
    if (this.getItemMode() === OutlookItemMode.Read) {
      return of(Office.context.mailbox.item.subject);
    } else {
      return this.outlookUtilsService.wrapAsync<string>(callback => {
        Office.context.mailbox.item.subject.getAsync(callback);
      });
    }
  }

  getHtmlBody(): Observable<string> {
    return this.outlookUtilsService.wrapAsync<string>(callback => {
      Office.context.mailbox.item.body.getAsync(Office.CoercionType.Html, callback);
    }, {
      defaultValue: '',
      sentryMessage: 'body.getAsync(CoercionType.Html) failed'
    });
  }

  getTextBody(): Observable<string> {
    return this.outlookUtilsService.wrapAsync<string>(callback => {
      Office.context.mailbox.item.body.getAsync(Office.CoercionType.Text, callback);
    }, {
      defaultValue: '',
      sentryMessage: 'body.getAsync(CoercionType.Text) failed'
    });
  }

  getDateTimeCreated(): string {
    return Office.context.mailbox.item.dateTimeCreated.toISOString();
  }

  getUser(): OutlookPerson {
    const userProfile = Office.context.mailbox.userProfile;

    return {
      email: userProfile.emailAddress,
      name: userProfile.displayName
    };
  }

  getAttachments(): OutlookAttachment[] {
    return Office.context.mailbox.item.attachments.filter(attachment => {
      return attachment.attachmentType === Office.MailboxEnums.AttachmentType.File;
    }).filter(attachment => {
      return attachment.id;
    }).map(attachment => ({
      content: null,
      fileName: attachment.name,
      id: attachment.id,
      size: attachment.size
    }));
  }

  hasAttachments(): boolean {
    if (this.getItemMode() === OutlookItemMode.Read) {
      return this.getAttachments().length !== 0;
    }

    return false;
  }

  hasAttachmentsWithoutId(): boolean {
    if (this.getItemMode() === OutlookItemMode.Read) {
      return Office.context.mailbox.item.attachments.filter(attachment => {
        return attachment.attachmentType === Office.MailboxEnums.AttachmentType.File;
      }).some(attachment => {
        return !attachment.id;
      });
    }

    return false;
  }

  removeAllAttachments(): Observable<null> {
    if (Office.context.requirements.isSetSupported('MailBox', '1.8')) {
      return this.outlookUtilsService.wrapAsync<Office.AttachmentDetailsCompose[]>(callback => {
        Office.context.mailbox.item.getAttachmentsAsync(callback);
      }).pipe(
        switchMap(details => {
          const attachmentIds: string[] = [];

          details.forEach(detail => {
            if (!detail.isInline) {
              attachmentIds.push(detail.id);
            }
          });

          if (!attachmentIds.length) {
            return of(null);
          }

          return combineLatest(attachmentIds.map(attachmentId => {
            return this.outlookUtilsService.wrapAsync<void>(callback => {
              Office.context.mailbox.item.removeAttachmentAsync(attachmentId, callback);
            });
          }));
        })
      );
    }

    return of(null);
  }

  addRecipients(options: AddRecipientsOptions): Observable<void> {
    const itemType = this.getItemType();

    if (itemType === OutlookItemType.Message) {
      return this.outlookUtilsService.wrapAsync<void>(callback => {
        Office.context.mailbox.item.to.addAsync(options.recipients.map(person => ({
          displayName: person.name,
          emailAddress: person.email
        })), callback);
      });
    } else {
      return this.outlookUtilsService.wrapAsync<void>(callback => {
        Office.context.mailbox.item.requiredAttendees.addAsync(options.recipients.map(person => ({
          displayName: person.name,
          emailAddress: person.email
        })), callback);
      });
    }
  }

  attachFile(options: AttachFileOptions): Observable<string> {
    return this.outlookUtilsService.wrapAsync<string>(callback => {
      Office.context.mailbox.item.addFileAttachmentAsync(options.url, options.fileName.slice(0, MaxFileNameLength), callback);
    });
  }

  saveDraft(): Observable<string> {
    return this.outlookUtilsService.wrapAsync<string>(callback => {
      Office.context.mailbox.item.saveAsync(callback);
    });
  }

  prependHtmlBody(htmlBody: string): Observable<void> {
    return this.outlookUtilsService.wrapAsync<void>(callback => {
      Office.context.mailbox.item.body.prependAsync(htmlBody, { coercionType: Office.CoercionType.Html }, callback);
    });
  }

  setHtmlBody(htmlBody: string): Observable<void> {
    return this.outlookUtilsService.wrapAsync<void>(callback => {
      Office.context.mailbox.item.body.setAsync(htmlBody, { coercionType: Office.CoercionType.Html }, callback);
    });
  }

  setTextBody(textBody: string): Observable<void> {
    return this.outlookUtilsService.wrapAsync<void>(callback => {
      Office.context.mailbox.item.body.setAsync(textBody, { coercionType: Office.CoercionType.Text }, callback);
    });
  }

  isSenderUser(): Observable<boolean> {
    return this.getSender().pipe(
      map(sender => {
        return sender.email === this.getUser().email;
      })
    );
  }

  private getSenderPerson(): Observable<OutlookPerson> {
    const itemType = this.getItemType();

    if (itemType === OutlookItemType.Message) {
      if (this.getItemMode() === OutlookItemMode.Read) {
        return of(this.getPerson(Office.context.mailbox.item.from));
      } else {
        return this.outlookUtilsService.wrapAsync<Office.EmailAddressDetails>(callback => {
          Office.context.mailbox.item.from.getAsync(callback);
        }).pipe(
          map(result => this.getPerson(result))
        );
      }
    } else if (itemType === OutlookItemType.Appointment) {
      if (this.getItemMode() === OutlookItemMode.Read) {
        return of(this.getPerson(Office.context.mailbox.item.organizer));
      } else {
        return this.outlookUtilsService.wrapAsync<Office.EmailAddressDetails>(callback => {
          Office.context.mailbox.item.organizer.getAsync(callback);
        }).pipe(
          map(result => this.getPerson(result))
        );
      }
    }
  }

  private getPerson(person: Office.EmailAddressDetails): OutlookPerson {
    if (person.displayName === person.emailAddress || person.displayName === `'${person.emailAddress}'` || person.displayName === `"${person.emailAddress}"`) {
      return {
        email: person.emailAddress,
        name: ''
      };
    }

    return {
      email: person.emailAddress,
      name: person.displayName
    };
  }

}

const MaxFileNameLength = 255;

export interface AddRecipientsOptions {
  recipients: OutlookPerson[];
}

export interface AttachFileOptions {
  fileName: string;
  url: string;
}
