import { TranslationsEntities, TranslationsErrorCodes, TranslationsFields, TranslationsFormControlErrors } from '@ppl/i18n';

export const Translations = {
  ...TranslationsEntities,
  ...TranslationsErrorCodes,
  ...TranslationsFields,
  ...TranslationsFormControlErrors,
  Remind_me_in_20_minutes: $localize`:@@Remind_me_in_20_minutes:Remind me in 20 minutes`,
  Remind_me_in_1_hour: $localize`:@@Remind_me_in_1_hour:Remind me in 1 hour`,
  'Remind_me_tomorrow_(9:00)': $localize`:@@Remind_me_tomorrow_(9\:00):Remind me tomorrow (9\:00)`,
  'Remind_me_next_week_(Mon,_9:00)': $localize`:@@Remind_me_next_week_(Mon,_9\:00):Remind me next week (Mon, 9\:00)`,
  'Pick_date_&_time': $localize`:@@Pick_date_&_time:Pick date & time`,
  'Unable_to_retrieve_the_email_contents_from_the_server.': $localize`:@@Unable_to_retrieve_the_email_contents_from_the_server.:Unable to retrieve the email contents from the server.`,
  'Unable_to_retrieve_the_email_contents_from_the_Exchange_server.': $localize`:@@Unable_to_retrieve_the_email_contents_from_the_Exchange_server.:Unable to retrieve the email contents from the Exchange server.`,
  'Unable_to_obtain_an_authentication_token_for_the_Exchange_server.': $localize`:@@Unable_to_obtain_an_authentication_token_for_the_Exchange_server.:Unable to obtain an authentication token for the Exchange server.`,
  'Unable_to_authenticate_with_the_Exchange_server.': $localize`:@@Unable_to_authenticate_with_the_Exchange_server.:Unable to authenticate with the Exchange server.`,
  Create_new_task: $localize`:@@Create_new_task:Create new task`,
  Create_new_appointment: $localize`:@@Create_new_appointment:Create new appointment`,
  Add_Account: $localize`:@@Add_Account:Add %Account_s%`,
  Add_Contact: $localize`:@@Add_Contact:Add %Contact_s%`,
  Add_Lead: $localize`:@@Add_Lead:Add %Lead_s%`,
  Add_Opportunity: $localize`:@@Add_Opportunity:Add %Opportunity_s%`,
  Add_Task: $localize`:@@Add_Task:Add Task`,
  Add_Appointment: $localize`:@@Add_Appointment:Add Appointment`,
  'Do_you_want_to_discard_changes?': $localize`:@@Do_you_want_to_discard_changes?:Do you want to discard changes?`,
  Reminder: $localize`:@@Reminder:Reminder`,
  'This_task_has_been_created_with_reminder_in_Outlook_add-in_for': $localize`:@@This_task_has_been_created_with_reminder_in_Outlook_add-in_for:This task has been created with reminder in Outlook add-in for`,
  Reminder_was_set: $localize`:@@Reminder_was_set:Reminder was set`,
  Email_was_added_to_feed: $localize`:@@Email_was_added_to_feed:Email was added to feed`,
  Email_in_feed_was_updated: $localize`:@@Email_in_feed_was_updated:Email in feed was updated`,
  Email_already_exists_in_feed: $localize`:@@Email_already_exists_in_feed:Email already exists in feed`,
  Email_was_saved: $localize`:@@Email_was_saved:Email was saved`,
  Attachments_were_saved: $localize`:@@Attachments_were_saved:Attachments were saved`,
  EnumLeadStatusEnum_Open: $localize`:@@EnumLeadStatusEnum_Open:Open`,
  EnumLeadStatusEnum_Lost: $localize`:@@EnumLeadStatusEnum_Lost:Lost`,
  EnumLeadStatusEnum_Qualified: $localize`:@@EnumLeadStatusEnum_Qualified:Qualified`,
  EnumOpptyStatusEnum_Open: $localize`:@@EnumOpptyStatusEnum_Open:Open`,
  EnumOpptyStatusEnum_Lost: $localize`:@@EnumOpptyStatusEnum_Lost:Lost`,
  EnumOpptyStatusEnum_Won: $localize`:@@EnumOpptyStatusEnum_Won:Won`,
  EnumTaskStatusEnum_Completed: $localize`:@@EnumTaskStatusEnum_Completed:Completed`,
  EnumTaskStatusEnum_Deferred: $localize`:@@EnumTaskStatusEnum_Deferred:Deferred`,
  EnumTaskStatusEnum_InProgress: $localize`:@@EnumTaskStatusEnum_InProgress:In Progress`,
  EnumTaskStatusEnum_NotStarted: $localize`:@@EnumTaskStatusEnum_NotStarted:Not Started`,
  EnumTaskStatusEnum_Waiting: $localize`:@@EnumTaskStatusEnum_Waiting:Waiting`,
  EnumActivityStatusEnum_Completed: $localize`:@@EnumActivityStatusEnum_Completed:Completed`,
  EnumActivityStatusEnum_Deferred: $localize`:@@EnumActivityStatusEnum_Deferred:Deferred`,
  EnumActivityStatusEnum_InProgress: $localize`:@@EnumActivityStatusEnum_InProgress:In Progress`,
  EnumActivityStatusEnum_NotStarted: $localize`:@@EnumActivityStatusEnum_NotStarted:Not Started`,
  EnumActivityStatusEnum_Waiting: $localize`:@@EnumActivityStatusEnum_Waiting:Waiting`,
  EnumActivityStatusEnum_Canceled: $localize`:@@EnumActivityStatusEnum_Canceled:Canceled`,
  EnumPriorityEnum_High: $localize`:@@EnumPriorityEnum_High:High`,
  EnumPriorityEnum_Low: $localize`:@@EnumPriorityEnum_Low:Low`,
  EnumPriorityEnum_Medium: $localize`:@@EnumPriorityEnum_Medium:Medium`,
  Edit_information: $localize`:@@Edit_information:Edit information`,
  Save_contact_to_Pipeliner_as_new_account: $localize`:@@Save_contact_to_Pipeliner_as_new_account:Save %Contact_s% to Pipeliner as new %Account_s%`,
  Save_contact_to_Pipeliner_as_new_contact: $localize`:@@Save_contact_to_Pipeliner_as_new_contact:Save %Contact_s% to Pipeliner as new %Contact_s%`,
  Save_email_to_this_account: $localize`:@@Save_email_to_this_account:Save email to this %Account_s%`,
  Save_email_to_this_contact: $localize`:@@Save_email_to_this_contact:Save email to this %Contact_s%`,
  Save_email_to_this_lead: $localize`:@@Save_email_to_this_lead:Save email to this %Lead_s%`,
  Save_email_to_this_opportunity: $localize`:@@Save_email_to_this_opportunity:Save email to this %Opportunity_s%`,
  Save_email_to_this_account_as_document: $localize`:@@Save_email_to_this_account_as_document:Save email to this %Account_s% as document`,
  Save_email_to_this_appointment_as_document: $localize`:@@Save_email_to_this_appointment_as_document:Save email to this appointment as document`,
  Save_email_to_this_contact_as_document: $localize`:@@Save_email_to_this_contact_as_document:Save email to this %Contact_s% as document`,
  Save_email_to_this_lead_as_document: $localize`:@@Save_email_to_this_lead_as_document:Save email to this %Lead_s% as document`,
  Save_email_to_this_opportunity_as_document: $localize`:@@Save_email_to_this_opportunity_as_document:Save email to this %Opportunity_s% as document`,
  Save_email_to_this_task_as_document: $localize`:@@Save_email_to_this_task_as_document:Save email to this task as document`,
  Save_email_attachments_to_this_account: $localize`:@@Save_email_attachments_to_this_account:Save email attachments to this %Account_s%`,
  Save_email_attachments_to_this_appointment: $localize`:@@Save_email_attachments_to_this_appointment:Save email attachments to this appointment`,
  Save_email_attachments_to_this_contact: $localize`:@@Save_email_attachments_to_this_contact:Save email attachments to this %Contact_s%`,
  Save_email_attachments_to_this_lead: $localize`:@@Save_email_attachments_to_this_lead:Save email attachments to this %Lead_s%`,
  Save_email_attachments_to_this_opportunity: $localize`:@@Save_email_attachments_to_this_opportunity:Save email attachments to this %Opportunity_s%`,
  Save_email_attachments_to_this_task: $localize`:@@Save_email_attachments_to_this_task:Save email attachments to this task`,
  Save_email_attachments_to_new_account: $localize`:@@Save_email_attachments_to_new_account:Save email attachments to new %Account_s%`,
  Save_email_attachments_to_new_contact: $localize`:@@Save_email_attachments_to_new_contact:Save email attachments to new %Contact_s%`,
  Save_email_attachments_to_new_lead: $localize`:@@Save_email_attachments_to_new_lead:Save email attachments to new %Lead_s%`,
  Save_email_attachments_to_new_opportunity: $localize`:@@Save_email_attachments_to_new_opportunity:Save email attachments to new %Opportunity_s%`,
  Save_email_attachments_to_new_task: $localize`:@@Save_email_attachments_to_new_task:Save email attachments to new task`,
  Save_email_attachments_to_new_appointment: $localize`:@@Save_email_attachments_to_new_appointment:Save email attachments to new appointment`,
  No_accounts_found: $localize`:@@No_accounts_found:No %Account_p% found`,
  No_contacts_found: $localize`:@@No_contacts_found:No %Contact_p% found`,
  No_leads_found: $localize`:@@No_leads_found:No %Lead_p% found`,
  No_opportunities_found: $localize`:@@No_opportunities_found:No %Opportunity_p% found`,
  Document_name: $localize`:@@Document_name:Document name`,
  Attachment: $localize`:@@Attachment:Attachment`,
  Size: $localize`:@@Size:Size`,
  Loading_ellipsis: $localize`:@@Loading_ellipsis:Loading…`,
  Unable_to_load_documents: $localize`:@@Unable_to_load_documents:Unable to load documents`,
  No_email_contact: $localize`:@@No_email_contact:No email contact`,
  Selected_account_has_no_related_documents: $localize`:@@Selected_account_has_no_related_documents:Selected %Account_s% has no related documents`,
  Selected_contact_has_no_related_documents: $localize`:@@Selected_contact_has_no_related_documents:Selected %Contact_s% has no related documents`,
  Selected_lead_has_no_related_documents: $localize`:@@Selected_lead_has_no_related_documents:Selected %Lead_s% has no related documents`,
  Selected_opportunity_has_no_related_documents: $localize`:@@Selected_opportunity_has_no_related_documents:Selected %Opportunity_s% has no related documents`,
  No_sales_team: $localize`:@@No_sales_team:No sales team`,
  Add_contacts: $localize`:@@Add_contacts:Add contacts`,
  Add_sales_team: $localize`:@@Add_sales_team:Add sales team`,
  Contacts_were_added_to_email: $localize`:@@Contacts_were_added_to_email:Contacts were added to email`,
  Contacts_were_added_to_appointment: $localize`:@@Contacts_were_added_to_appointment:Contacts were added to appointment`,
  Documents_were_attached_to_email: $localize`:@@Documents_were_attached_to_email:Documents were attached to email`,
  Documents_were_attached_to_appointment: $localize`:@@Documents_were_attached_to_appointment:Documents were attached to appointment`,
  No_results_found: $localize`:@@No_results_found:No results found`,
  Close: $localize`:@@Close:Close`,
  Activity_type: $localize`:@@Activity_type:Activity type`,
  Attendees: $localize`:@@Attendees:Attendees`,
  Facebook_profile: $localize`:@@Facebook_profile:Facebook profile`,
  LinkedIn_profile: $localize`:@@LinkedIn_profile:LinkedIn profile`,
  Twitter_profile: $localize`:@@Twitter_profile:Twitter profile`,
  Lead_process: $localize`:@@Lead_process:%Lead_s% process`,
  Pipeline: $localize`:@@Pipeline:Pipeline`,
  Watchers: $localize`:@@Watchers:Watchers`,
  ReminderOffset_None: $localize`:@@ReminderOffset_None:Never`,
  ReminderOffset_0: $localize`:@@ReminderOffset_0:At start`,
  ReminderOffset_300: $localize`:@@ReminderOffset_300:5 minutes before`,
  ReminderOffset_600: $localize`:@@ReminderOffset_600:10 minutes before`,
  ReminderOffset_900: $localize`:@@ReminderOffset_900:15 minutes before`,
  ReminderOffset_1800: $localize`:@@ReminderOffset_1800:30 minutes before`,
  ReminderOffset_3600: $localize`:@@ReminderOffset_3600:1 hour before`,
  ReminderOffset_7200: $localize`:@@ReminderOffset_7200:2 hours before`,
  ReminderOffset_14400: $localize`:@@ReminderOffset_14400:4 hours before`,
  ReminderOffset_28800: $localize`:@@ReminderOffset_28800:8 hours before`,
  ReminderOffset_43200: $localize`:@@ReminderOffset_43200:12 hours before`,
  ReminderOffset_86400: $localize`:@@ReminderOffset_86400:a day before`,
  ReminderOffset_172800: $localize`:@@ReminderOffset_172800:2 days before`,
  ReminderOffset_604800: $localize`:@@ReminderOffset_604800:a week before`,
  ReminderOffset_1209600: $localize`:@@ReminderOffset_1209600:2 weeks before`,
  secondsAgo: $localize`:@@secondsAgo:%amount% seconds ago`,
  minuteAgo: $localize`:@@minuteAgo:%amount% minute ago`,
  minutesAgo: $localize`:@@minutesAgo:%amount% minutes ago`,
  hourAgo: $localize`:@@hourAgo:%amount% hour ago`,
  hoursAgo: $localize`:@@hoursAgo:%amount% hours ago`,
  dayAgo: $localize`:@@dayAgo:%amount% day ago`,
  daysAgo: $localize`:@@daysAgo:%amount% days ago`,
  monthAgo: $localize`:@@monthAgo:%amount% month ago`,
  monthsAgo: $localize`:@@monthsAgo:%amount% months ago`,
  yearAgo: $localize`:@@yearAgo:%amount% year ago`,
  yearsAgo: $localize`:@@yearsAgo:%amount% years ago`,
  secondsWoAgo: $localize`:@@secondsWoAgo:%amount% seconds`,
  minuteWoAgo: $localize`:@@minuteWoAgo:%amount% minute`,
  minutesWoAgo: $localize`:@@minutesWoAgo:%amount% minutes`,
  hourWoAgo: $localize`:@@hourWoAgo:%amount% hour`,
  hoursWoAgo: $localize`:@@hoursWoAgo:%amount% hours`,
  dayWoAgo: $localize`:@@dayWoAgo:%amount% day`,
  daysWoAgo: $localize`:@@daysWoAgo:%amount% days`,
  monthWoAgo: $localize`:@@monthWoAgo:%amount% month`,
  monthsWoAgo: $localize`:@@monthsWoAgo:%amount% months`,
  yearWoAgo: $localize`:@@yearWoAgo:%amount% year`,
  yearsWoAgo: $localize`:@@yearsWoAgo:%amount% years`,
  Now: $localize`:@@Now:Now`,
  From: $localize`:@@From:From`,
  To: $localize`:@@To:To`,
  Organizer: $localize`:@@Organizer:Organizer`,
  'We_are_preparing_email_tracking,_please_wait.': $localize`:@@We_are_preparing_email_tracking,_please_wait.:We are preparing email tracking, please wait.`,
  'This_email_has_turned_on_tracking.': $localize`:@@This_email_has_turned_on_tracking.:This email has turned on tracking.`,
  'Cannot_turn_on_email_tracking._Please_open_the_Pipeliner_CRM_add-on_to_turn_it_on.': $localize`:@@Cannot_turn_on_email_tracking._Please_open_the_Pipeliner_CRM_add-on_to_turn_it_on.:Cannot turn on email tracking. Please open the Pipeliner CRM add-on to turn it on.`,
  'Cannot_turn_on_email_tracking.': $localize`:@@Cannot_turn_on_email_tracking.:Cannot turn on email tracking.`,
  Recent_Records: $localize`:@@Recent_Records:Recent Records`,
  My: $localize`:@@My:My`,
  All: $localize`:@@All:All`,
  Email_template_was_set: $localize`:@@Email_template_was_set:Email template was set`,
  Account_type: $localize`:@@Account_type:%Account_s% type`,
  Contact_type: $localize`:@@Contact_type:%Contact_s% type`,
  Lead_type: $localize`:@@Lead_type:%Lead_s% type`,
  Account: $localize`:@@Account:%Account_s%`,
  Edit_Account: $localize`:@@Edit_Account:Edit %Account_s%`,
  Account_will_be_linked_with_contact: $localize`:@@Account_will_be_linked_with_contact:%Account_s% will be linked with %Contact_s%`,
  Account_will_be_linked_with_lead: $localize`:@@Account_will_be_linked_with_lead:%Account_s% will be linked with %Lead_s%`,
  Account_will_be_linked_with_opportunity: $localize`:@@Account_will_be_linked_with_opportunity:%Account_s% will be linked with %Opportunity_s%`,
  Contact: $localize`:@@Contact:%Contact_s%`,
  Edit_Contact: $localize`:@@Edit_Contact:Edit %Contact_s%`,
  Contact_will_be_linked_with_lead: $localize`:@@Contact_will_be_linked_with_lead:%Contact_s% will be linked with %Lead_s%`,
  Contact_will_be_linked_with_opportunity: $localize`:@@Contact_will_be_linked_with_opportunity:%Contact_s% will be linked with %Opportunity_s%`,
  'Create_a_new_lead_based_on_current_email.': $localize`:@@Create_a_new_lead_based_on_current_email.:Create a new %Lead_s% based on current email.`,
  Create_lead: $localize`:@@Create_lead:Create %Lead_s%`,
  New_lead: $localize`:@@New_lead:New %Lead_s%`,
  'Create_a_new_opportunity_based_on_current_email.': $localize`:@@Create_a_new_opportunity_based_on_current_email.:Create a new %Opportunity_s% based on current email.`,
  Create_opportunity: $localize`:@@Create_opportunity:Create %Opportunity_s%`,
  New_opportunity: $localize`:@@New_opportunity:New %Opportunity_s%`,
  'Create_a_new_account_based_on_current_email.': $localize`:@@Create_a_new_account_based_on_current_email.:Create a new %Account_s% based on current email.`,
  Create_account: $localize`:@@Create_account:Create %Account_s%`,
  New_account: $localize`:@@New_account:New %Account_s%`,
  'Create_a_new_contact_based_on_current_email.': $localize`:@@Create_a_new_contact_based_on_current_email.:Create a new %Contact_s% based on current email.`,
  Create_contact: $localize`:@@Create_contact:Create %Contact_s%`,
  New_contact: $localize`:@@New_contact:New %Contact_s%`,
  'You_can_add_contacts_or_accounts_individually_or_whole_sales_teams_as_email_recipients.': $localize`:@@You_can_add_contacts_or_accounts_individually_or_whole_sales_teams_as_email_recipients.:You can add %Contact_p% or %Account_p% individually or whole sales teams as email recipients.`,
  Lead: $localize`:@@Lead:%Lead_s%`,
  Edit_Lead: $localize`:@@Edit_Lead:Edit %Lead_s%`,
  Opportunity: $localize`:@@Opportunity:%Opportunity_s%`,
  Edit_Opportunity: $localize`:@@Edit_Opportunity:Edit %Opportunity_s%`,
  Accounts: $localize`:@@Accounts:%Account_p%`,
  Contacts: $localize`:@@Contacts:%Contact_p%`,
  Leads: $localize`:@@Leads:%Lead_p%`,
  Unknown_Lead: $localize`:@@Unknown_Lead:Unknown %Lead_s%`,
  Unknown_Opportunity: $localize`:@@Unknown_Opportunity:Unknown %Opportunity_s%`,
  Opportunities: $localize`:@@Opportunities:%Opportunity_p%`,
};
