import type { AccountEntity, ClientEntity, ContactEntity } from '@ppl/graphql-space-api';
import type { OutlookPerson } from '../../shared/outlook/domain/outlook';
import type { TrackingVisits } from '../../shared/tracking/domain/tracking';

export interface DashboardData {
  sender: DashboardItem;
  recipients: DashboardItem[];
  visits: TrackingVisits | null;
}

export interface DashboardAccountItem {
  entity: AccountEntity;
  person: OutlookPerson;
  kind: DashboardItemKind.Account;
}

export interface DashboardContactItem {
  entity: ContactEntity;
  person: OutlookPerson;
  kind: DashboardItemKind.Contact;
}

export interface DashboardClientItem {
  entity: ClientEntity;
  person: OutlookPerson;
  kind: DashboardItemKind.Client;
}

export interface DashboardUserItem {
  person: OutlookPerson;
  kind: DashboardItemKind.User;
}

export interface DashboardOtherItem {
  person: OutlookPerson;
  kind: DashboardItemKind.Other;
}

export enum DashboardItemKind {
  Account = 'Account',
  Contact = 'Contact',
  Client = 'Client',
  User = 'User',
  Other = 'Other'
}

export type DashboardItem = DashboardAccountItem | DashboardContactItem | DashboardClientItem | DashboardUserItem | DashboardOtherItem;
