import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { PplDateTimePipe } from '@ppl/auth';
import type { CloudObjectEntity } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { FormValueControl, getFormControlProvider, MemoizeLast } from '@ppl/utils';
import type { EntityGridColumn } from '../../../shared/entity-grid/domain/entity-grid';

@Component({
  selector: 'ppl-attach-document-files',
  templateUrl: './attach-document-files.component.html',
  styleUrls: ['./attach-document-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    getFormControlProvider(() => AttachDocumentFilesComponent)
  ]
})
@FormValueControl()
export class AttachDocumentFilesComponent {

  @Input() files: CloudObjectEntity[];
  @Input() message?: string;
  @Input() value: string[] = [];

  @Output() valueChange = new EventEmitter<string[]>();

  gridColumns: EntityGridColumn[] = [{
    id: 'name',
    name: this.i18nService.translate('Document_name'),
    width: 140
  }, {
    id: 'modified',
    name: this.i18nService.translate('Modified')
  }];

  @MemoizeLast<AttachDocumentFilesComponent>(['files'])
  get gridItems() {
    return this.files.map(file => ({
      id: file.id,
      name: file.filename,
      modified: this.dateTimePipe.transform(file.modified)
    }));
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dateTimePipe: PplDateTimePipe,
    private i18nService: I18nService
  ) {}

}
