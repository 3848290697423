import { createReducer } from '@ppl/store';
import type { Actions } from '../../store/core.actions';

export const locationReducer = createReducer<LocationState, Actions>({

  Location_PopUrl(state) {
    return {
      ...state,
      prevUrls: state.prevUrls.slice(0, state.prevUrls.length - 1)
    };
  },

  Location_PushUrl(state, { payload }) {
    return {
      ...state,
      prevUrls: [
        ...state.prevUrls,
        payload
      ]
    };
  },

  Location_Reset(state) {
    return {
      ...state,
      prevUrls: []
    };
  }

}, getLocationDefaultState());

function getLocationDefaultState(): LocationState {
  return {
    prevUrls: []
  };
}

export interface LocationState {
  prevUrls: string[];
}
