<ppl-card-group [label]="'Contacts' | t"
                [count]="entities.length"
                [displayCreate]="displayCreate"
                (createClick)="onCreateClick()">
  <ppl-card-list *ngIf="entities.length !== 0"
                 [entities]="entities">
    <ng-template #cardTemplate
                 let-entity>
      <ng-container *ngIf="entity.__typename === 'ContactEntity'">
        <ppl-contact-card-medium *ngIf="entity"
                                 [entity]="entity"
                                 (click)="onCardClick(entity)"></ppl-contact-card-medium>
      </ng-container>

      <ng-container *ngIf="entity.__typename !== 'ContactEntity'">
        <ppl-contact-card-medium *ngIf="entity.contact"
                                 [entity]="entity.contact"
                                 [isPrimary]="entity.isPrimary"
                                 (click)="onCardClick(entity.contact)"></ppl-contact-card-medium>

        <ppl-unavailable-card-medium *ngIf="!entity.contact"
                                     [entityType]="EntityNameEnum.Contact"
                                     [name]="'Unknown_Contact' | t"
                                     [isPrimary]="entity.isPrimary"></ppl-unavailable-card-medium>
      </ng-container>
    </ng-template>
  </ppl-card-list>

  <ng-container *ngIf="entities.length === 0">
    <ppl-card-message *ngIf="parentEntity.__typename === 'AccountEntity'"
                      i18n="@@Account_has_no_related_contacts">{{ 'Account' | t }} has no related {{ 'Contacts' | t }}</ppl-card-message>

    <ppl-card-message *ngIf="parentEntity.__typename === 'LeadEntity'"
                      i18n="@@Lead_has_no_related_contacts">{{ 'Lead' | t }} has no related {{ 'Contacts' | t }}</ppl-card-message>

    <ppl-card-message *ngIf="parentEntity.__typename === 'OpportunityEntity'"
                      i18n="@@Opportunity_has_no_related_contacts">{{ 'Opportunity' | t }} has no related {{ 'Contacts' | t }}</ppl-card-message>
  </ng-container>
</ppl-card-group>
