import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@ppl/auth';
import { CoreModule } from '@ppl/core';
import { PplInputModule } from '@ppl/ui/input';
import { PplSelectModule } from '@ppl/ui/select';
import { EntityControlItemModule } from '../shared/entity-control-item/entity-control-item.module';
import { EntityGridModule } from '../shared/entity-grid/entity-grid.module';
import { EntityModule } from '../shared/entity/entity.module';
import { OutlookModule } from '../shared/outlook/outlook.module';
import { PageModule } from '../shared/page/page.module';
import { ToastModule } from '../shared/toast/toast.module';
import { AttachDocumentFilesComponent } from './components/attach-document-files/attach-document-files.component';
import { AttachDocumentComponent } from './components/attach-document/attach-document.component';
import { AttachDocumentService } from './services/attach-document.service';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    CoreModule,
    EntityModule,
    EntityGridModule,
    EntityControlItemModule,
    OutlookModule,
    PageModule,
    PplInputModule,
    PplSelectModule,
    ReactiveFormsModule,
    ToastModule
  ],
  declarations: [
    AttachDocumentComponent,
    AttachDocumentFilesComponent
  ],
  exports: [
    AttachDocumentComponent
  ],
  providers: [
    AttachDocumentService
  ]
})
export class AttachDocumentModule {}
