import type { AccountTypeEntity, AppointmentTypeEntity, ClientEntity, ContactTypeEntity, CurrencyEntity, EntityNameEnum, EntityPermsEnum, FeaturePermissions, FieldDescriptor, Form, LeadProcessEntity, LeadTypeEntity, PipelineEntity, SalesUnitEntity, TaskTypeEntity } from '@ppl/graphql-space-api';
import type { Space } from '@ppl/graphql-user-api';
import type { AsyncValue} from '@ppl/store';
import { AsyncState, createReducer } from '@ppl/store';
import { filterObject } from '@ppl/utils';
import type { SpaceActions } from './space.actions';

export const spaceReducer = createReducer<SpaceState, SpaceActions>({

  Space_SetSpace(state, { payload }) {
    return {
      ...state,
      space: payload
    };
  },

  Space_UpdateConfig(state, { payload }) {
    const nextConfig = state.config;

    if (payload.accessControl) {
      nextConfig.accessControl = payload.accessControl;
    }

    if (payload.allowedPipelineIds) {
      nextConfig.allowedPipelineIds = payload.allowedPipelineIds;
    }

    if (payload.entities) {
      nextConfig.entities = {
        ...nextConfig.entities,
        ...payload.entities
      };
    }

    if (payload.fieldDescriptors) {
      nextConfig.fieldDescriptors = {
        ...nextConfig.fieldDescriptors,
        ...payload.fieldDescriptors
      };
    }

    if (payload.forms) {
      nextConfig.forms = {
        ...nextConfig.forms,
        ...payload.forms
      };
    }

    if (payload.user) {
      nextConfig.user = payload.user;
    }

    if (payload.userId) {
      nextConfig.userId = payload.userId;
    }

    return {
      ...state,
      config: nextConfig
    };
  },

  Space_UpdateConfigError(state) {
    const nextConfig: SpaceConfig = {};

    if (state.config.accessControl?.state === AsyncState.FETCHED) {
      nextConfig.accessControl = state.config.accessControl;
    }

    if (state.config.allowedPipelineIds?.state === AsyncState.FETCHED) {
      nextConfig.allowedPipelineIds = state.config.allowedPipelineIds;
    }

    if (state.config.entities) {
      nextConfig.entities = filterObject(state.config.entities, value => {
        return value.state === AsyncState.FETCHED;
      });
    }

    if (state.config.fieldDescriptors) {
      nextConfig.fieldDescriptors = filterObject(state.config.fieldDescriptors, value => {
        return value.state === AsyncState.FETCHED;
      });
    }

    if (state.config.forms) {
      nextConfig.forms = filterObject(state.config.forms, value => {
        return value.state === AsyncState.FETCHED;
      });
    }

    if (state.config.user?.state === AsyncState.FETCHED) {
      nextConfig.user = state.config.user;
    }

    if (state.config.userId) {
      nextConfig.userId = state.config.userId;
    }

    return {
      ...state,
      config: nextConfig
    };
  }

}, {
  config: {},
  space: null
});

export interface SpaceConfig {
  accessControl?: AsyncValue<{
    entities?: { [entityType in EntityNameEnum]?: EntityPermsEnum[] };
    features?: FeaturePermissions;
  }>;
  allowedPipelineIds?: AsyncValue<string[]>;
  entities?: {
    [EntityNameEnum.AccountType]?: AsyncValue<AccountTypeEntity[]>;
    [EntityNameEnum.AppointmentType]?: AsyncValue<AppointmentTypeEntity[]>;
    [EntityNameEnum.Client]?: AsyncValue<ClientEntity[]>;
    [EntityNameEnum.ContactType]?: AsyncValue<ContactTypeEntity[]>;
    [EntityNameEnum.Currency]?: AsyncValue<CurrencyEntity[]>;
    [EntityNameEnum.LeadProcess]?: AsyncValue<LeadProcessEntity[]>;
    [EntityNameEnum.LeadType]?: AsyncValue<LeadTypeEntity[]>;
    [EntityNameEnum.Pipeline]?: AsyncValue<PipelineEntity[]>;
    [EntityNameEnum.SalesUnit]?: AsyncValue<SalesUnitEntity[]>;
    [EntityNameEnum.TaskType]?: AsyncValue<TaskTypeEntity[]>;
  };
  fieldDescriptors?: {
    [entityType in EntityNameEnum]?: AsyncValue<FieldDescriptor[]>;
  };
  forms?: {
    [entityType in EntityNameEnum]?: AsyncValue<{ id: string, formEditApi: Form }[]>;
  };
  user?: AsyncValue<ClientEntity>;
  userId?: string;
}

export interface SpaceState {
  config: SpaceConfig;
  space: Space | null;
}
