import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { LocationService } from '../../../../../services/location.service';
import { ToastControl, TOAST_CONTENT_DATA, TOAST_CONTROL } from '../../../toast/domain/toast';
import { SuccessToastData, SuccessToastOperation } from '../../domain/success-toast';

@Component({
  selector: 'ppl-success-toast',
  templateUrl: './success-toast.component.html',
  styleUrls: ['./success-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuccessToastComponent {

  EntityNameEnum = EntityNameEnum;
  SuccessToastOperation = SuccessToastOperation;

  constructor(
    @Inject(TOAST_CONTROL) public toast: ToastControl,
    @Inject(TOAST_CONTENT_DATA) public data: SuccessToastData,
    private locationService: LocationService
  ) {}

  onLinkClick() {
    this.locationService.openEntity(this.data.entityType, this.data.id);

    this.toast.close();
  }

}
