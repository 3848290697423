import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthModule } from '@ppl/auth';
import { PplAutocompletePickerModule } from '@ppl/ui/autocomplete-picker';
import { EntityControlItemModule } from '../entity-control-item/entity-control-item.module';
import { GlobalSearchComponent } from './components/global-search/global-search.component';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    EntityControlItemModule,
    PplAutocompletePickerModule
  ],
  declarations: [
    GlobalSearchComponent
  ],
  exports: [
    GlobalSearchComponent
  ]
})
export class GlobalSearchModule {}
