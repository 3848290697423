<ppl-dialog title="Set reminder"
            i18n-title="@@Set_reminder"
            actionLabel="Set"
            i18n-actionLabel="@@Set"
            [formGroup]="formGroup"
            [formGroupNoPadding]="true"
            width="80vh"
            (action)="onSave()">
  <ppl-input-container label="Reminder"
                       i18n-label="@@Reminder"
                       [pplFormControl]="formGroup.get('dateTime')">
    <ppl-date-time-input [displayRemove]="false"
                         formControlName="dateTime"></ppl-date-time-input>
  </ppl-input-container>
</ppl-dialog>