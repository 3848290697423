import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import type { FirebaseLogData} from '@ppl/firebase';
import { FirebaseEventsEnum, FirebaseService } from '@ppl/firebase';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { ReplaySubject } from 'rxjs';
import type { AddRecipientKind } from '../modules/add-recipient/domain/add-recipient';
import type { EmailKind } from '../modules/shared/email/domain/email';
import { CoreStore } from '../store/core.store';
import { getLocationPrevUrl } from '../store/location/location.selectors';
import { RecentRecordsService } from './recent-records.service';

@Injectable()
export class LocationService {

  private prevScreen: string | null = null;

  private spaceGuardSuccess$ = new ReplaySubject<boolean>();

  get spaceGuardSuccess() {
    return this.spaceGuardSuccess$.asObservable();
  }

  constructor(
    private firebaseService: FirebaseService,
    private recentRecordsService: RecentRecordsService,
    private router: Router,
    private store: CoreStore
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/dashboard') {
          this.logChangeView('overview');
        } else if (EntityDetailUrlRe.test(event.url)) {
          this.logChangeView(`${event.url.match(EntityDetailUrlRe)[1]}_detail`);
        }
      }
    });
  }

  goBack() {
    const prevUrl = this.store.get(getLocationPrevUrl);

    if (prevUrl) {
      this.router.navigateByUrl(prevUrl).then(result => {
        if (result) {
          this.store.dispatch('Location_PopUrl', null);
        }
      });
    }
  }

  reset() {
    this.store.dispatch('Location_Reset', null);

    this.router.navigateByUrl('/dashboard');
  }

  openAccount(id: string) {
    this.navigateByUrl(`/account/${id}`);

    this.recentRecordsService.add(id);
  }

  createAccount(options: CreateAccountOptions = {}) {
    this.navigateByUrl('/account/create', options);
  }

  updateAccount(id: string) {
    this.navigateByUrl(`/account/${id}/update`);
  }

  openAppointment(id: string) {
    this.navigateByUrl(`/appointment/${id}`);

    this.recentRecordsService.add(id);
  }

  createAppointment(options: CreateAppointmentOptions = {}) {
    this.navigateByUrl('/appointment/create', options);
  }

  updateAppointment(id: string) {
    this.navigateByUrl(`/appointment/${id}/update`);
  }

  openContact(id: string) {
    this.navigateByUrl(`/contact/${id}`);

    this.recentRecordsService.add(id);
  }

  createContact(options: CreateContactOptions = {}) {
    this.navigateByUrl('/contact/create', options);
  }

  updateContact(id: string) {
    this.navigateByUrl(`/contact/${id}/update`);
  }

  openLead(id: string) {
    this.navigateByUrl(`/lead/${id}`);

    this.recentRecordsService.add(id);
  }

  createLead(options: CreateLeadOptions = {}) {
    this.navigateByUrl('/lead/create', options);
  }

  updateLead(id: string) {
    this.navigateByUrl(`/lead/${id}/update`);
  }

  openOpportunity(id: string) {
    this.navigateByUrl(`/opportunity/${id}`);

    this.recentRecordsService.add(id);
  }

  createOpportunity(options: CreateOpportunityOptions = {}) {
    this.navigateByUrl('/opportunity/create', options);
  }

  updateOpportunity(id: string) {
    this.navigateByUrl(`/opportunity/${id}/update`);
  }

  openTask(id: string) {
    this.navigateByUrl(`/task/${id}`);

    this.recentRecordsService.add(id);
  }

  createTask(options: CreateTaskOptions = {}) {
    this.navigateByUrl('/task/create', options);
  }

  updateTask(id: string) {
    this.navigateByUrl(`/task/${id}/update`);
  }

  addRecipient(options: AddRecipientOptions) {
    this.navigateByUrl('/add-recipient', options);
  }

  attachDocument() {
    this.navigateByUrl('/attach-document');
  }

  useTemplate() {
    this.navigateByUrl('/use-template');
  }

  saveEmail(options: SaveEmailOptions) {
    this.navigateByUrl('/save-email', options);
  }

  setSpaceGuardSuccess(success: boolean) {
    this.spaceGuardSuccess$.next(success);
  }

  openEntity(entityType: EntityNameEnum, id: string) {
    switch (entityType) {
      case EntityNameEnum.Account:
        this.openAccount(id);
        break;
      case EntityNameEnum.Appointment:
        this.openAppointment(id);
        break;
      case EntityNameEnum.Contact:
        this.openContact(id);
        break;
      case EntityNameEnum.Lead:
        this.openLead(id);
        break;
      case EntityNameEnum.Opportunity:
        this.openOpportunity(id);
        break;
      case EntityNameEnum.Task:
        this.openTask(id);
        break;
    }
  }

  private navigateByUrl(url: string, queryParams: { [id: string]: any } = {}) {
    if (url !== this.router.url) {
      this.store.dispatch('Location_PushUrl', this.router.url);

      this.router.navigateByUrl(this.router.createUrlTree([url], {
        queryParams
      }));
    }
  }

  private logChangeView(screen: string) {
    if (screen !== this.prevScreen) {
      const data: FirebaseLogData = {
        current_screen: screen,
        current_screen_settings: null,
        previous_screen: this.prevScreen,
        previous_screen_settings: null
      };

      this.prevScreen = screen;

      this.firebaseService.logEvent(FirebaseEventsEnum.ChangeView, data);
    }
  }

}

const EntityDetailUrlRe = /^\/(\w+)\/[a-f0-9-]+$/;

export interface CreateAccountOptions {
  contactId?: string;
  email?: string;
  leadId?: string;
  opportunityId?: string;
}

export interface CreateAppointmentOptions {
  accountId?: string;
  contactId?: string;
  leadId?: string;
  opportunityId?: string;
}

export interface CreateContactOptions {
  accountId?: string;
  email?: string;
  leadId?: string;
  opportunityId?: string;
}

export interface CreateLeadOptions {
  accountId?: string;
  contactId?: string;
}

export interface CreateOpportunityOptions {
  accountId?: string;
  contactId?: string;
}

export interface CreateTaskOptions {
  accountId?: string;
  contactId?: string;
  leadId?: string;
  opportunityId?: string;
}

export interface AddRecipientOptions {
  kind: AddRecipientKind;
}

export interface SaveEmailOptions {
  kind: EmailKind;
}
