import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { MemoizeLast } from '@ppl/utils';
import { AvatarKind } from '../../domain/avatar';

@Component({
  selector: 'ppl-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {

  @Input() entityType: EntityNameEnum | null;
  @Input() kind: AvatarKind;
  @Input() pictureUrl?: string;

  @HostBinding('class.large')
  get kindLarge() {
    return this.kind === AvatarKind.Large;
  }

  @HostBinding('class.medium')
  get kindMedium() {
    return this.kind === AvatarKind.Medium;
  }

  @HostBinding('class.small')
  get kindSmall() {
    return this.kind === AvatarKind.Small;
  }

  @MemoizeLast<AvatarComponent>(['entityType', 'kind'])
  get iconName() {
    switch (this.kind) {
      case AvatarKind.Large:
        if (!this.entityType) {
          return 'entity-phantom-unknown';
        }

        return LargeMediumIconName[this.entityType];
      case AvatarKind.Medium:
        if (!this.entityType) {
          return 'entity-phantom-unknown-16';
        }

        return `${LargeMediumIconName[this.entityType]}-16`;
      case AvatarKind.Small:
        if (!this.entityType) {
          return 'unknown-blue';
        }

        return SmallIconName[this.entityType];
    }
  }

  @MemoizeLast<AvatarComponent>(['kind'])
  get iconSize() {
    switch (this.kind) {
      case AvatarKind.Large:
        return '24px';
      case AvatarKind.Medium:
        return '16px';
      case AvatarKind.Small:
        return '18px';
    }
  }

}

const LargeMediumIconName: { [id in EntityNameEnum]?: string } = {
  [EntityNameEnum.Account]: 'entity-phantom-account',
  [EntityNameEnum.Appointment]: 'entity-phantom-appointment',
  [EntityNameEnum.Client]: 'entity-phantom-contact',
  [EntityNameEnum.Contact]: 'entity-phantom-contact',
  [EntityNameEnum.Lead]: 'entity-phantom-lead',
  [EntityNameEnum.Opportunity]: 'entity-phantom-opportunity',
  [EntityNameEnum.Task]: 'entity-phantom-task'
};

const SmallIconName: { [id in EntityNameEnum]?: string } = {
  [EntityNameEnum.Account]: 'menu-account',
  [EntityNameEnum.Appointment]: 'menu-appointment',
  [EntityNameEnum.Client]: 'menu-contact',
  [EntityNameEnum.Contact]: 'menu-contact',
  [EntityNameEnum.Lead]: 'menu-lead',
  [EntityNameEnum.Opportunity]: 'menu-opportunity',
  [EntityNameEnum.Task]: 'menu-task'
};
