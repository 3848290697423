import { Injectable } from '@angular/core';
import { ActionsUtilsService } from '@ppl/core';
import { LeadFieldsEnum } from '@ppl/domain';
import { FirebaseEventsEnum, FirebaseService } from '@ppl/firebase';
import type { LeadEntity, LeadProcessEntity } from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { getSpaceLeadProcessById, getSpaceLeadProcesses, getSpaceLeadTypes } from '@ppl/space';
import type { PplSelectOption } from '@ppl/ui/select';
import { sortByKey } from '@ppl/utils';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { RecentRecordsService } from '../../../services/recent-records.service';
import { CoreStore } from '../../../store/core.store';
import { EmailService } from '../../shared/email/services/email.service';
import { EntityFormApiService } from '../../shared/entity-form-api/services/entity-form-api.service';
import type { EntityFormControl, EntityFormEntityControl } from '../../shared/entity-form/domain/controls';
import { EntityFormControlKind } from '../../shared/entity-form/domain/controls';
import { EntityFormValidatorKind } from '../../shared/entity-form/domain/validators';
import { SuccessToastComponent } from '../../shared/entity/components/success-toast/success-toast.component';
import type { SuccessToastData} from '../../shared/entity/domain/success-toast';
import { SuccessToastOperation } from '../../shared/entity/domain/success-toast';
import { EntityService } from '../../shared/entity/services/entity.service';
import { OutlookService } from '../../shared/outlook/services/outlook.service';
import { ToastService } from '../../shared/toast/services/toast.service';
import type { LeadFormData, LeadFormDataOutlookItem, LeadFormValue } from '../domain/lead-form';
import { gqlCreateLeadMutation, gqlFetchLeadFormData, gqlUpdateLeadMutation } from './lead-form.graphql';

@Injectable()
export class LeadFormService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private emailService: EmailService,
    private entityService: EntityService,
    private entityFormApiService: EntityFormApiService,
    private firebaseService: FirebaseService,
    private i18nService: I18nService,
    private outlookService: OutlookService,
    private recentRecordsService: RecentRecordsService,
    private store: CoreStore,
    private toastService: ToastService
  ) {}

  fetchData(options: FetchDataOptions) {
    return combineLatest([
      this.entityService.fetchEntity<LeadEntity>({
        entityType: EntityNameEnum.Lead,
        id: options.id,
        query: gqlFetchLeadFormData
      }),
      this.entityFormApiService.fetchContext(EntityNameEnum.Lead, {
        entities: [EntityNameEnum.LeadProcess, EntityNameEnum.LeadType, EntityNameEnum.SalesUnit]
      }),
      this.fetchOutlookItem()
    ]).pipe(
      map(([ entity, context, outlookItem ]) => {
        if (options.id && !entity) {
          return null;
        }

        const result: LeadFormData = {
          entity,
          context,
          outlookItem,
          relatedAccountId: options.relatedAccountId,
          relatedContactId: options.relatedContactId
        };

        return result;
      })
    );
  }

  getControls(data: LeadFormData, leadProcessId: string, formId: string) {
    const leadProcesses = this.getLeadProcesses();
    const leadTypes = this.getLeadTypes();

    const createForm = !data.entity;
    const multipleProcesses = leadProcesses.length > 1;
    const multipleTypes = leadTypes.length > 1;
    const outlookHasAttachments = this.outlookService.hasAttachments();

    const controls: EntityFormControl[] = [
      multipleProcesses && {
        id: 'leadProcessId',
        label: this.i18nService.translate('Lead_process'),
        options: leadProcesses.map<PplSelectOption>(leadProcess => ({
          deleted: leadProcess.isDeleted || !this.getDefaultFormId(leadProcess.id),
          label: leadProcess.name,
          value: leadProcess.id
        })).sort(sortByKey('label')),
        kind: EntityFormControlKind.Dropdown,
        validators: [{
          kind: EntityFormValidatorKind.Required
        }]
      },
      (multipleTypes || multipleProcesses) && {
        id: 'leadTypeId',
        label: this.i18nService.translate('Lead_type'),
        options: leadTypes.map<PplSelectOption>(leadType => ({
          deleted: leadType.isDeleted || leadType.isReadonly || !this.getLeadProcessFormIds(leadProcessId).includes(leadType.id),
          label: leadType.name,
          value: leadType.id
        })).sort(sortByKey('label')),
        kind: EntityFormControlKind.Dropdown,
        validators: [{
          kind: EntityFormValidatorKind.Required
        }]
      },
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: LeadFieldsEnum.NameField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: LeadFieldsEnum.UnitIdField,
        formId
      }),
      {
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId: LeadFieldsEnum.OwnerIdField,
          forceOnForm: true,
          formId
        }) as EntityFormEntityControl,
        disabled: true
      },
      {
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId: LeadFieldsEnum.AccountCardField,
          forceOnForm: !!data.relatedAccountId,
          formId
        }) as EntityFormEntityControl,
        nullDisabled: !!data.entity || !!data.relatedAccountId
      },
      {
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId: LeadFieldsEnum.ContactCardField,
          forceOnForm: !!data.relatedContactId,
          formId
        }) as EntityFormEntityControl,
        nullDisabled: !!data.entity || !!data.relatedContactId
      },
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: LeadFieldsEnum.RankingField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: LeadFieldsEnum.DescriptionField,
        formId
      }),
      createForm && outlookHasAttachments && {
        id: 'saveAttachments',
        label: this.i18nService.translate('Save_email_attachments_to_new_lead'),
        inlineLabel: true,
        kind: EntityFormControlKind.Checkbox
      },
      ...this.entityFormApiService.getCustomFieldIds({
        context: data.context,
        formId
      }).map(fieldId => ({
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId,
          formId
        }),
        data: {
          customField: true
        }
      }))
    ];

    return controls.filter(control => control);
  }

  getValue(data: LeadFormData, leadProcessId: string, formId: string): LeadFormValue {
    const value = this.entityFormApiService.getValue({
      context: data.context,
      entity: data.entity,
      fieldIds: [
        LeadFieldsEnum.NameField,
        LeadFieldsEnum.UnitIdField,
        { fieldId: LeadFieldsEnum.OwnerIdField, forceOnForm: true },
        LeadFieldsEnum.AccountCardField,
        LeadFieldsEnum.ContactCardField,
        LeadFieldsEnum.RankingField,
        LeadFieldsEnum.DescriptionField,
        ...this.entityFormApiService.getCustomFieldIds({
          context: data.context,
          formId
        })
      ],
      formId
    });

    if (data.entity) {
      return {
        ...value,
        leadProcessId: leadProcessId,
        leadTypeId: formId
      };
    } else {
      return {
        ...value,
        [LeadFieldsEnum.NameField]: data.outlookItem.subject,
        [LeadFieldsEnum.AccountCardField]: data.relatedAccountId,
        [LeadFieldsEnum.ContactCardField]: data.relatedContactId,
        leadProcessId: leadProcessId,
        leadTypeId: formId,
        saveAttachments: false
      };
    }
  }

  createEntity(value: LeadFormValue, data: LeadFormData) {
    const leadProcess = this.store.get(getSpaceLeadProcessById(value.leadProcessId));
    const stepId = this.getLeadProcessDefaultStepId(leadProcess);

    return this.actionsUtilsService.doAction(this.entityFormApiService.saveEntity({
      input: {
        ...this.entityFormApiService.getInput({
          context: data.context,
          value
        }),
        leadTypeId: value.leadTypeId,
        stepId
      },
      mutation: gqlCreateLeadMutation
    }).pipe(
      switchMap(result => {
        const leadId = result.createLead.lead.id;

        return of(null).pipe(
          switchMap(() => {
            if (value.saveAttachments) {
              return this.emailService.saveAttachments({
                relations: [{
                  entityId: leadId,
                  entityType: EntityNameEnum.Lead
                }],
                silent: true
              });
            }

            return of(null);
          }),
          tap(() => {
            this.toastService.open<SuccessToastData>({
              content: {
                component: SuccessToastComponent,
                data: {
                  entityType: EntityNameEnum.Lead,
                  id: leadId,
                  operation: SuccessToastOperation.Create
                }
              }
            });

            this.recentRecordsService.add(leadId);

            const hasRelation = data.relatedAccountId || data.relatedContactId;

            this.firebaseService.logEvent(hasRelation ? FirebaseEventsEnum.Create : FirebaseEventsEnum.CreateFromEmail, {
              entity: 'lead'
            });
          })
        );
      })
    ));
  }

  updateEntity(value: LeadFormValue, data: LeadFormData) {
    const leadProcess = this.store.get(getSpaceLeadProcessById(value.leadProcessId));
    const stepId = (value.leadProcessId !== data.entity.step.leadProcessId) ? this.getLeadProcessDefaultStepId(leadProcess) : data.entity.step.id;

    return this.actionsUtilsService.doAction(this.entityFormApiService.saveEntity({
      input: {
        ...this.entityFormApiService.getInput({
          entity: data.entity,
          context: data.context,
          value
        }),
        leadTypeId: value.leadTypeId,
        stepId,
        id: data.entity.id
      },
      mutation: gqlUpdateLeadMutation
    }).pipe(
      tap(() => {
        this.toastService.open<SuccessToastData>({
          content: {
            component: SuccessToastComponent,
            data: {
              entityType: EntityNameEnum.Lead,
              id: data.entity.id,
              operation: SuccessToastOperation.Update
            }
          }
        });

        this.firebaseService.logEvent(FirebaseEventsEnum.Update, {
          entity: 'lead'
        });
      })
    ));
  }

  getDefaultFormId(leadProcessId: string) {
    const leadProcessFormIds = this.getLeadProcessFormIds(leadProcessId);

    return this.getLeadTypes().filter(leadType => !leadType.isDeleted && !leadType.isReadonly && leadProcessFormIds.includes(leadType.id))[0]?.id || null;
  }

  getDefaultLeadProcessId() {
    return this.getLeadProcesses().filter(leadProcess => !leadProcess.isDeleted && this.getDefaultFormId(leadProcess.id))[0]?.id || null;
  }

  getLeadProcessFormIds(leadProcessId: string) {
    const leadProcess = this.store.get(getSpaceLeadProcessById(leadProcessId));

    return leadProcess.leadTypes.map(leadType => leadType.id);
  }

  private fetchOutlookItem() {
    return combineLatest([
      this.outlookService.getSubject()
    ]).pipe(
      map(([ subject ]) => {
        const result: LeadFormDataOutlookItem = {
          subject
        };

        return result;
      })
    );
  }

  private getLeadProcessDefaultStepId(leadProcess: LeadProcessEntity | null) {
    return leadProcess?.steps[0].id || null;
  }

  private getLeadProcesses() {
    return this.store.get(getSpaceLeadProcesses);
  }

  private getLeadTypes() {
    return this.store.get(getSpaceLeadTypes).filter(leadType => leadType.isPublished).sort(sortByKey('name'));
  }

}

export interface FetchDataOptions {
  id: string | null;
  relatedAccountId: string | null;
  relatedContactId: string | null;
}
