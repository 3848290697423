import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import type { DashboardItem } from '../../../domain/dashboard';

@Component({
  selector: 'ppl-dashboard-card-list',
  templateUrl: './dashboard-card-list.component.html',
  styleUrls: ['./dashboard-card-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardCardListComponent {

  @Input() icon: string;
  @Input() items: DashboardItem[];
  @Input() name: string;

  @ContentChild('cardTemplate', { static: false }) cardTemplate: TemplateRef<any>;

  allVisible = false;

}
