import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplIconModule } from '../icon';
import { PplMenuModule } from '../menu';
import { PplPopoverModule } from '../popover';
import { PplPopoverDropdownModule } from '../popover-dropdown';
import { PplMenuItemsComponent } from './menu-items.component';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    PplMenuModule,
    PplPopoverModule,
    PplPopoverDropdownModule
  ],
  declarations: [
    PplMenuItemsComponent
  ],
  exports: [
    PplMenuItemsComponent
  ]
})
export class PplMenuItemsModule {}

export * from './menu-items.component';

