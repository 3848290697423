<ppl-date-input [value]="dateValue"
                [displayRemove]="displayRemove"
                [disabled]="disabled"
                (valueChange)="onDateValueChange($event)"></ppl-date-input>

<ppl-select [options]="timeOptions"
            [displaySearch]="false"
            [value]="timeValue"
            [disabled]="disabled"
            [class.short]="is24HourSystem"
            (valueChange)="onTimeValueChange($event)"></ppl-select>