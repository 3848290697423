<ppl-dashboard-recipients-contact *ngIf="contactItems.length"
                                  [items]="contactItems"
                                  [largeCard]="accountContactOtherItemsLargeCard"></ppl-dashboard-recipients-contact>

<ppl-dashboard-recipients-account *ngIf="accountItems.length"
                                  [items]="accountItems"
                                  [largeCard]="accountContactOtherItemsLargeCard"></ppl-dashboard-recipients-account>

<ppl-dashboard-recipients-other *ngIf="otherItems.length"
                                [items]="otherItems"
                                [largeCard]="accountContactOtherItemsLargeCard"></ppl-dashboard-recipients-other>

<ppl-dashboard-recipients-user-client *ngIf="userItems.length && clientItems.length"
                                      [items]="userClientItems"></ppl-dashboard-recipients-user-client>

<ppl-dashboard-recipients-user *ngIf="userItems.length && !clientItems.length"
                               [items]="userItems"></ppl-dashboard-recipients-user>

<ppl-dashboard-recipients-client *ngIf="clientItems.length && !userItems.length"
                                 [items]="clientItems"
                                 [largeCard]="clientItemsLargeCard"></ppl-dashboard-recipients-client>