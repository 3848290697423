<ppl-page-header-button class="button-back"
                        icon="toolbar-white-back"
                        (click)="onBackClick()"></ppl-page-header-button>

<ng-template [ngTemplateOutlet]="globalSearch"></ng-template>

{{label}}

<ppl-page-header-button class="button-close"
                        icon="toolbar-white-close"
                        (click)="onCloseClick()"></ppl-page-header-button>