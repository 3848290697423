import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountEntity, EntityNameEnum, EntityPermsEnum } from '@ppl/graphql-space-api';
import { formatURL, MemoizeLast } from '@ppl/utils';
import { PhoneService } from '../../../phone/services/phone.service';

@Component({
  selector: 'ppl-account-fields-card',
  templateUrl: './account-fields-card.component.html',
  styleUrls: ['./account-fields-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountFieldsCardComponent {

  @Input() entity: AccountEntity;

  @Output() emailChange = new EventEmitter<string>();
  @Output() phoneChange = new EventEmitter<string>();
  @Output() homePageChange = new EventEmitter<string>();

  EntityPermsEnum = EntityPermsEnum;

  @MemoizeLast<AccountFieldsCardComponent>(['entity'])
  get homePageUrl() {
    return this.entity.homePage ? formatURL(this.entity.homePage) : null;
  }

  constructor(
    private phoneService: PhoneService
  ) {}

  onPhoneClick() {
    this.phoneService.startCall({
      entityId: this.entity.id,
      entityName: this.entity.name,
      entityType: EntityNameEnum.Account,
      phoneNumber: this.entity.phone1
    });
  }

}
