import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { OfficeTokenRedirectRoute, OfficeTokenRoute, RedirectTokenRoute } from '@ppl/auth';
import { BootstrapComponent } from './components/bootstrap/bootstrap.component';
import { AccountFormComponent } from './modules/account/components/account-form/account-form.component';
import { AccountComponent } from './modules/account/components/account/account.component';
import { AddRecipientComponent } from './modules/add-recipient/components/add-recipient/add-recipient.component';
import { AppointmentFormComponent } from './modules/appointment/components/appointment-form/appointment-form.component';
import { AppointmentComponent } from './modules/appointment/components/appointment/appointment.component';
import { AttachDocumentComponent } from './modules/attach-document/components/attach-document/attach-document.component';
import { ContactFormComponent } from './modules/contact/components/contact-form/contact-form.component';
import { ContactComponent } from './modules/contact/components/contact/contact.component';
import { DashboardComponent } from './modules/dashboard/components/dashboard/dashboard.component';
import { LeadFormComponent } from './modules/lead/components/lead-form/lead-form.component';
import { LeadComponent } from './modules/lead/components/lead/lead.component';
import { OpportunityFormComponent } from './modules/opportunity/components/opportunity-form/opportunity-form.component';
import { OpportunityComponent } from './modules/opportunity/components/opportunity/opportunity.component';
import { SaveEmailComponent } from './modules/save-email/components/save-email/save-email.component';
import { CanLeaveEntityFormGuard } from './modules/shared/entity-form/router/can-leave-entity-form.service';
import { TaskFormComponent } from './modules/task/components/task-form/task-form.component';
import { TaskComponent } from './modules/task/components/task/task.component';
import { UseTemplateComponent } from './modules/use-template/components/use-template/use-template.component';
import { SpaceGuardService } from './router/space-guard.service';

const appRoutes: Routes = [{
  path: '',
  canActivate: [SpaceGuardService],
  children: [{
    path: '',
    component: BootstrapComponent
  }, {
    path: 'dashboard',
    component: DashboardComponent
  }, {
    path: 'account/create',
    component: AccountFormComponent,
    canDeactivate: [CanLeaveEntityFormGuard]
  }, {
    path: 'account/:id',
    component: AccountComponent
  }, {
    path: 'account/:id/update',
    component: AccountFormComponent,
    canDeactivate: [CanLeaveEntityFormGuard]
  }, {
    path: 'appointment/create',
    component: AppointmentFormComponent,
    canDeactivate: [CanLeaveEntityFormGuard]
  }, {
    path: 'appointment/:id',
    component: AppointmentComponent
  }, {
    path: 'appointment/:id/update',
    component: AppointmentFormComponent,
    canDeactivate: [CanLeaveEntityFormGuard]
  }, {
    path: 'contact/create',
    component: ContactFormComponent,
    canDeactivate: [CanLeaveEntityFormGuard]
  }, {
    path: 'contact/:id',
    component: ContactComponent
  }, {
    path: 'contact/:id/update',
    component: ContactFormComponent,
    canDeactivate: [CanLeaveEntityFormGuard]
  }, {
    path: 'lead/create',
    component: LeadFormComponent,
    canDeactivate: [CanLeaveEntityFormGuard]
  }, {
    path: 'lead/:id',
    component: LeadComponent
  }, {
    path: 'lead/:id/update',
    component: LeadFormComponent,
    canDeactivate: [CanLeaveEntityFormGuard]
  }, {
    path: 'opportunity/create',
    component: OpportunityFormComponent,
    canDeactivate: [CanLeaveEntityFormGuard]
  }, {
    path: 'opportunity/:id',
    component: OpportunityComponent
  }, {
    path: 'opportunity/:id/update',
    component: OpportunityFormComponent,
    canDeactivate: [CanLeaveEntityFormGuard]
  }, {
    path: 'task/create',
    component: TaskFormComponent,
    canDeactivate: [CanLeaveEntityFormGuard]
  }, {
    path: 'task/:id',
    component: TaskComponent
  }, {
    path: 'task/:id/update',
    component: TaskFormComponent,
    canDeactivate: [CanLeaveEntityFormGuard]
  }, {
    path: 'save-email',
    component: SaveEmailComponent
  }, {
    path: 'add-recipient',
    component: AddRecipientComponent
  }, {
    path: 'attach-document',
    component: AttachDocumentComponent
  }, {
    path: 'use-template',
    component: UseTemplateComponent
  }]
}, RedirectTokenRoute, OfficeTokenRoute, OfficeTokenRedirectRoute];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
  ]
})
export class AppRoutingModule {}
