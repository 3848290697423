<div class="action"
     [class.disabled]="createDisabled"
     pplPopover
     pplPopoverAlignStart
     [pplPopoverDisabled]="createDisabled"
     [pplPopoverMenu]="createMenuItems"
     (pplPopoverMenuItemClick)="onCreateMenuItemClick($event)">
  <ppl-icon [svgIcon]="createDisabled ? 'entity-action-add-gray' : 'entity-action-add-blue'"
            width="20px"
            height="20px"></ppl-icon>
</div>

<div class="action"
     [class.disabled]="reminderDisabled"
     pplPopover
     pplPopoverAlignStart
     [pplPopoverDisabled]="reminderDisabled"
     [pplPopoverMenu]="reminderMenuItems"
     (pplPopoverMenuItemClick)="onReminderMenuItemClick($event)">
  <ppl-icon [svgIcon]="reminderDisabled ? 'entity-action-closing-date-gray' : 'entity-action-closing-date'"
            width="20px"
            height="20px"></ppl-icon>
</div>

<div class="action"
     [class.disabled]="emailDisabled"
     pplPopover
     pplPopoverAlignStart
     [pplPopoverDisabled]="emailDisabled"
     [pplPopoverMenu]="emailMenuItems"
     (pplPopoverMenuItemClick)="onEmailMenuItemClick($event)">
  <ppl-icon [svgIcon]="emailDisabled ? 'entity-action-save-gray' : 'entity-action-save'"
            width="20px"
            height="20px"></ppl-icon>
</div>

<div class="action"
     [class.disabled]="moreDisabled"
     pplPopover
     pplPopoverAlignStart
     [pplPopoverDisabled]="moreDisabled"
     [pplPopoverMenu]="moreMenuItems"
     (pplPopoverMenuItemClick)="onMoreMenuItemClick()">
  <ppl-icon [svgIcon]="moreDisabled ? 'entity-action-more-gray' : 'entity-action-more'"
            width="20px"
            height="20px"></ppl-icon>
</div>