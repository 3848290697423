import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@ppl/core';
import { I18nModule } from '@ppl/i18n';
import { BodyParserModule } from '../shared/body-parser/body-parser.module';
import { CardModule } from '../shared/card/card.module';
import { EntityCardModule } from '../shared/entity-card/entity-card.module';
import { EntityFormApiModule } from '../shared/entity-form-api/entity-form-api.module';
import { EntityFormModule } from '../shared/entity-form/entity-form.module';
import { EntityModule } from '../shared/entity/entity.module';
import { PageModule } from '../shared/page/page.module';
import { ToastModule } from '../shared/toast/toast.module';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContactComponent } from './components/contact/contact.component';
import { ContactFormService } from './services/contact-form.service';
import { ContactService } from './services/contact.service';

@NgModule({
  imports: [
    BodyParserModule,
    CardModule,
    CommonModule,
    CoreModule,
    EntityModule,
    EntityCardModule,
    EntityFormModule,
    EntityFormApiModule,
    I18nModule,
    PageModule,
    ToastModule
  ],
  declarations: [
    ContactComponent,
    ContactFormComponent
  ],
  exports: [
    ContactComponent,
    ContactFormComponent
  ],
  providers: [
    ContactService,
    ContactFormService
  ]
})
export class ContactModule {}
