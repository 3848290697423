import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ppl-button-card',
  templateUrl: './button-card.component.html',
  styleUrls: ['./button-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonCardComponent {

  @Input() label: string;

}
