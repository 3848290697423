import type { OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { PplNamePipe } from '@ppl/auth';
import type { FetchEntitiesSession } from '@ppl/components/entity-list';
import { EntityAdapters, EntityListService } from '@ppl/components/entity-list';
import type { EntityNameEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { SpaceService } from '@ppl/space';
import type { PplAutocompleteOption, PplAutocompleteOptionsRequest } from '@ppl/ui/autocomplete';
import { PplAutocompleteSelectComponent } from '@ppl/ui/autocomplete-select';
import { FormValueControl, getFormControlProvider, MemoizeLast, Unsubscribe, unsubscribe } from '@ppl/utils';
import type { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ppl-entity-select',
  templateUrl: './entity-select.component.html',
  styleUrls: ['./entity-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    getFormControlProvider(() => EntitySelectComponent)
  ]
})
@FormValueControl()
@Unsubscribe()
export class EntitySelectComponent implements OnChanges, OnDestroy {

  @Input() entityTypes: EntityNameEnum[];
  @Input() value: string | null;

  @Input() disabled?: boolean;
  @Input() entityAdapters?: EntityAdapters;
  @Input() forceDisplayClearValue?: boolean;
  @Input() maxContainerHeight?: number;
  @Input() optionTemplate?: TemplateRef<any>;
  @Input() optionTemplateRowHeight?: number;

  @Output() valueChange = new EventEmitter<string | null>();

  @ViewChild('autocomplete', { read: PplAutocompleteSelectComponent, static: true }) autocomplete: PplAutocompleteSelectComponent;

  fetchEntitiesSession: FetchEntitiesSession = {
    loading: false,
    options$: new BehaviorSubject<PplAutocompleteOption[]>([])
  };

  valueLoading = false;
  valueSubscription: Subscription;

  @MemoizeLast<EntitySelectComponent>(['entityTypes'])
  get entitiesCategories() {
    return this.entityTypes.map(entityType => ({
      id: entityType,
      label: this.i18nService.translate(`EnumEntityNameEnum_${entityType}`)
    }));
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private entityListService: EntityListService,
    private i18nService: I18nService,
    private namePipe: PplNamePipe,
    private spaceService: SpaceService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      if (this.value && !this.autocomplete.optionValueCache[this.value]) {
        unsubscribe(this.valueSubscription);
        this.fetchValue(this.value);
      }
    }
  }

  ngOnDestroy() {
    unsubscribe(this.fetchEntitiesSession.subscription);
  }

  onOptionsRequest(event: PplAutocompleteOptionsRequest) {
    this.entityListService.fetchEntities(this.fetchEntitiesSession, event, {
      entityAdapters: this.entityAdapters,
      entityType: (this.entityTypes.length === 1) ? this.entityTypes[0] : null,
      entityTypes: this.entityTypes,
      excludeIds: this.value ? [this.value] : null,
      namePipe: this.namePipe,
      spaceService: this.spaceService
    });
  }

  onClearValueClick() {
    this.valueChange.emit(null);
  }

  onValueChange(value: string) {
    this.valueChange.emit(value);
  }

  private fetchValue(entityId: string) {
    this.valueLoading = true;

    this.valueSubscription = this.entityListService.fetchEntitiesById([entityId], {
      entityAdapters: this.entityAdapters,
      entityTypes: this.entityTypes,
      namePipe: this.namePipe,
      spaceService: this.spaceService
    }).subscribe(options => {
      this.valueLoading = false;

      this.fetchEntitiesSession.options$.next(options);
    });
  }

}
