import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { AccountEntity, AppointmentEntity, ContactEntity, LeadEntity, OpportunityEntity, TaskEntity } from '@ppl/graphql-space-api';
import { getSpace } from '@ppl/space';
import { MemoizeLast } from '@ppl/utils';
import { environment } from '../../../../../../environments/environment';
import { CoreStore } from '../../../../../store/core.store';

@Component({
  selector: 'ppl-external-link',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalLinkComponent {

  @Input() entity: AccountEntity | AppointmentEntity | ContactEntity | LeadEntity | OpportunityEntity | TaskEntity;

  @MemoizeLast<ExternalLinkComponent>(['entity'])
  get entityUrl() {
    const space = this.store.get(getSpace);
    const baseUrl = `${environment.crmUrl}/space/${space.id}`;

    switch (this.entity.__typename) {
      case 'AccountEntity':
        return `${baseUrl}/accounts/${this.entity.id}`;
      case 'ContactEntity':
        return `${baseUrl}/contacts/${this.entity.id}`;
      case 'LeadEntity':
        return `${baseUrl}/leads/${this.entity.id}`;
      case 'OpportunityEntity':
        return `${baseUrl}/opportunities/${this.entity.id}`;
      case 'AppointmentEntity':
      case 'TaskEntity':
        return `${baseUrl}/activities(activityDetail:space/${space.id}/activities/${this.entity.id})`;
    }
  }

  constructor(
    private store: CoreStore
  ) {}

}
