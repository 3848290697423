import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { arrayToggle, trackById } from '@ppl/utils';
import type { EntityGridColumn, EntityGridItem } from '../domain/entity-grid';

@Component({
  selector: 'ppl-entity-grid',
  templateUrl: './entity-grid.component.html',
  styleUrls: ['./entity-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityGridComponent {

  @Input() columns: EntityGridColumn[];
  @Input() items: EntityGridItem[];
  @Input() message?: string;
  @Input() selection: string[];

  @Output() inlineEdit = new EventEmitter<{ item: EntityGridItem, column: EntityGridColumn }>();
  @Output() selectionChange = new EventEmitter<string[]>();

  trackById = trackById;

  onHeaderClick() {
    if (this.selection.length !== this.items.length) {
      this.selectionChange.emit(this.items.map(item => item.id));
    } else {
      this.selectionChange.emit([]);
    }
  }

  onItemClick(item: EntityGridItem) {
    this.selectionChange.emit(arrayToggle(this.selection, item.id));
  }

  onInlineEditClick(item: EntityGridItem, column: EntityGridColumn, event: MouseEvent) {
    this.inlineEdit.emit({
      column,
      item
    });

    event.stopPropagation();
  }

}
