import { Injectable } from '@angular/core';
import type { Query } from '@ppl/graphql-space-api';
import { SpaceService } from '@ppl/space';
import { map } from 'rxjs/operators';
import type { LeadData } from '../domain/lead';
import { gqlFetchLeadData } from './lead.graphql';

@Injectable()
export class LeadService {

  constructor(
    private spaceService: SpaceService
  ) {}

  fetchData(id: string) {
    return this.spaceService.gqlClient.query<Query>({
      query: gqlFetchLeadData,
      fetchPolicy: 'no-cache',
      variables: {
        entityId: id
      }
    }).pipe(map(({ data }) => {
      const entityData = data.screens.extractViews.lead;

      if (!entityData) {
        return null;
      }

      const result: LeadData = {
        entity: entityData.entity,
        relatedAccounts: entityData.entity.accountRelations.edges.map(edge => edge.node),
        relatedActivities: [
          ...entityData.plannedActivities.edges.map(edge => edge.node.appointment || edge.node.task).filter(entity => !!entity),
          ...entityData.unplannedTasks.edges.map(edge => edge.node.task).filter(entity => !!entity)
        ],
        relatedContacts: entityData.entity.contactRelations.edges.map(edge => edge.node)
      };

      return result;
    }));
  }

}
