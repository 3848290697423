import { PriorityEnum } from '@ppl/graphql-space-api';
import { EntityFormControlKind } from '../../../entity-form/domain/controls';
import type { EntityFormApiFieldTypeAdapter } from '../entity-form-api';

export const PriorityFieldTypeAdapter: EntityFormApiFieldTypeAdapter = {
  id: 'priority',
  getControl({ fieldDescriptor, formField, i18nService }) {
    return {
      id: fieldDescriptor.id,
      label: formField?.label ?? fieldDescriptor.name,
      options: [{
        label: i18nService.translate('EnumPriorityEnum_Low'),
        value: PriorityEnum.Low
      }, {
        label: i18nService.translate('EnumPriorityEnum_Medium'),
        value: PriorityEnum.Medium
      }, {
        label: i18nService.translate('EnumPriorityEnum_High'),
        value: PriorityEnum.High
      }],
      kind: EntityFormControlKind.Dropdown
    };
  }
};
