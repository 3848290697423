import type { OnDestroy} from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { PplNamePipe } from '@ppl/auth';
import type { FetchEntitiesSession } from '@ppl/components/entity-list';
import { EntityListService } from '@ppl/components/entity-list';
import type { AccountEntity, AppointmentEntity, ContactEntity, LeadEntity, OpportunityEntity, TaskEntity } from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { SpaceService } from '@ppl/space';
import type { PplAutocompleteCategory, PplAutocompleteOption, PplAutocompleteOptionsRequest } from '@ppl/ui/autocomplete';
import { MemoizeLast, unsubscribe } from '@ppl/utils';
import { BehaviorSubject } from 'rxjs';
import { LocationService } from '../../../../../services/location.service';
import { RecentRecordsService } from '../../../../../services/recent-records.service';
import { AvatarKind } from '../../../avatar/domain/avatar';
import { EntityService } from '../../../entity/services/entity.service';
import { GlobalSearchEntityListAdapters } from '../../domain/global-search';

@Component({
  selector: 'ppl-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalSearchComponent implements OnDestroy {

  @Output() listClose = new EventEmitter();

  entityTypes: EntityNameEnum[] = [];

  fetchEntitiesSession: FetchEntitiesSession = {
    loading: false,
    options$: new BehaviorSubject<PplAutocompleteOption[]>([])
  };

  AvatarKind = AvatarKind;
  EntityNameEnum = EntityNameEnum;

  @MemoizeLast<GlobalSearchComponent>(['entityTypes'])
  get entitiesCategories() {
    return [
      ...this.entityTypes.map<PplAutocompleteCategory>(entityType => ({
        id: entityType,
        label: this.i18nService.translate(`EnumEntityNameEnum_${entityType}`)
      })),
      {
        id: 'RecentRecords',
        label: this.i18nService.translate('Recent_Records')
      }
    ];
  }

  constructor(
    private entityService: EntityService,
    private entityListService: EntityListService,
    private i18nService: I18nService,
    private locationService: LocationService,
    private namePipe: PplNamePipe,
    private recentRecordsService: RecentRecordsService,
    private spaceService: SpaceService
  ) {
    this.entityTypes = this.entityService.filterEntityTypes([
      EntityNameEnum.Account,
      EntityNameEnum.Contact,
      EntityNameEnum.Lead,
      EntityNameEnum.Opportunity,
      EntityNameEnum.Appointment,
      EntityNameEnum.Task
    ]);
  }

  ngOnDestroy() {
    unsubscribe(this.fetchEntitiesSession.subscription);
  }

  onOptionsRequest(event: PplAutocompleteOptionsRequest) {
    if (event.filter) {
      // Global Search
      this.entityListService.fetchEntities(this.fetchEntitiesSession, event, {
        entityAdapters: GlobalSearchEntityListAdapters,
        entityType: (this.entityTypes.length === 1) ? this.entityTypes[0] : null,
        entityTypes: this.entityTypes,
        namePipe: this.namePipe,
        spaceService: this.spaceService
      });
    } else {
      // Recent Records
      if (event.lastOptionData) {
        return;
      }

      const entityIds = this.recentRecordsService.get();

      unsubscribe(this.fetchEntitiesSession.subscription);

      this.fetchEntitiesSession.loading = !!entityIds.length;
      this.fetchEntitiesSession.options$.next([]);

      if (entityIds.length) {
        this.fetchEntitiesSession.subscription = this.entityListService.fetchEntitiesById(entityIds, {
          entityAdapters: GlobalSearchEntityListAdapters,
          entityTypes: this.entityTypes,
          namePipe: this.namePipe,
          spaceService: this.spaceService
        }).subscribe(options => {
          options.sort((a, b) => entityIds.indexOf(a.value) - entityIds.indexOf(b.value));

          this.fetchEntitiesSession.loading = false;
          this.fetchEntitiesSession.options$.next(options.map(option => ({
            ...option,
            categoryId: 'RecentRecords'
          })));
        });
      }
    }
  }

  onOptionSelect(value: string) {
    const options = this.fetchEntitiesSession.options$.getValue();
    const selectedOption = options.find(option => option.value === value);

    this.openEntity(selectedOption.data.entity);
  }

  openEntity(entity: AccountEntity | AppointmentEntity | ContactEntity | LeadEntity | OpportunityEntity | TaskEntity) {
    switch (entity.__typename) {
      case 'AccountEntity':
        this.locationService.openAccount(entity.id);
        break;
      case 'AppointmentEntity':
        this.locationService.openAppointment(entity.id);
        break;
      case 'ContactEntity':
        this.locationService.openContact(entity.id);
        break;
      case 'LeadEntity':
        this.locationService.openLead(entity.id);
        break;
      case 'OpportunityEntity':
        this.locationService.openOpportunity(entity.id);
        break;
      case 'TaskEntity':
        this.locationService.openTask(entity.id);
        break;
    }
  }

}
