import type { EntityAdapters } from '@ppl/components/entity-list';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { gql } from '@ppl/utils';

export const EntityListAdapters: EntityAdapters = {
  [EntityNameEnum.Account]: {
    entityFields: gql`
      fragment AccountEntityListFields on AccountEntity {
        address
        city
        country
        id
        name
        picture {
          id
          url
        }
        stateProvince
      }
    `
  },
  [EntityNameEnum.Client]: {
    entityFields: gql`
      fragment ClientEntityListFields on ClientEntity {
        id
        name
        pictureUrl
      }
    `
  },
  [EntityNameEnum.Contact]: {
    entityFields: gql`
      fragment ContactEntityListFields on ContactEntity {
        id
        firstName
        lastName
        middleName
        picture {
          id
          url
        }
        primaryAccount {
          id
          name
        }
        title
      }
    `
  },
  [EntityNameEnum.Lead]: {
    entityFields: gql`
      fragment LeadEntityListFields on LeadEntity {
        id
        name
        primaryAccount {
          id
          name
        }
      }
    `
  },
  [EntityNameEnum.Opportunity]: {
    entityFields: gql`
      fragment OpportunityEntityListFields on OpportunityEntity {
        id
        name
        primaryAccount {
          id
          name
        }
      }
    `
  }
};
