import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'ppl-card-small',
  templateUrl: './card-small.component.html',
  styleUrls: ['./card-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardSmallComponent {

  @Input() @HostBinding('class.active') active?: boolean;
  @Input() @HostBinding('class.disabled') disabled?: boolean;

  @ContentChild('iconTemplate', { static: false }) iconTemplate: TemplateRef<any>;
  @ContentChild('nameTemplate', { static: false }) nameTemplate: TemplateRef<any>;
  @ContentChild('actionTemplate', { static: false }) actionTemplate?: TemplateRef<any>;
  @ContentChild('descriptionTemplate', { static: false }) descriptionTemplate?: TemplateRef<any>;

}
