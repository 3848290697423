<ppl-card-group [label]="'Accounts' | t"
                [count]="entities.length"
                [displayCreate]="displayCreate"
                (createClick)="onCreateClick()">
  <ppl-card-list *ngIf="entities.length !== 0"
                 [entities]="entities">
    <ng-template #cardTemplate
                 let-entity>
      <ppl-account-card-medium *ngIf="entity.account"
                               [entity]="entity.account"
                               [isPrimary]="entity.isPrimary"
                               (click)="onCardClick(entity.account)"></ppl-account-card-medium>

      <ppl-unavailable-card-medium *ngIf="!entity.account"
                                   [entityType]="EntityNameEnum.Account"
                                   [name]="'Unknown_Account' | t"
                                   [isPrimary]="entity.isPrimary"></ppl-unavailable-card-medium>
    </ng-template>
  </ppl-card-list>

  <ng-container *ngIf="entities.length === 0">
    <ppl-card-message *ngIf="parentEntity.__typename === 'ContactEntity'"
                      i18n="@@Contact_has_no_related_accounts">{{ 'Contact' | t }} has no related {{ 'Accounts' | t }}</ppl-card-message>

    <ppl-card-message *ngIf="parentEntity.__typename === 'LeadEntity'"
                      i18n="@@Lead_has_no_related_accounts">{{ 'Lead' | t }} has no related {{ 'Accounts' | t }}</ppl-card-message>

    <ppl-card-message *ngIf="parentEntity.__typename === 'OpportunityEntity'"
                      i18n="@@Opportunity_has_no_related_accounts">{{ 'Opportunity' | t }} has no related {{ 'Accounts' | t }}</ppl-card-message>
  </ng-container>
</ppl-card-group>
