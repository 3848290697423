import type { ClientEntity, ClientFilterInput, ClientOrderByInput} from '@ppl/graphql-space-api';
import { OrderByEnum } from '@ppl/graphql-space-api';
import { createContactNameFilterInput } from '@ppl/domain';
import { gql } from '@ppl/utils';
import type { EntityAdapter } from './entity.adapter';

export const ClientAdapter: EntityAdapter = {
  entityFields: gql`
    fragment ClientEntityListFields on ClientEntity {
      id
      name
    }
  `,
  getFilterInput(filter, excludeIds, includeIds) {
    const filterInput: ClientFilterInput = {
      ...createContactNameFilterInput(filter) as any,
      NOT: {
        id: { eq: excludeIds }
      }
    };

    if (includeIds.length !== 0) {
      filterInput.id = { eq: includeIds };
    }

    return filterInput;
  },
  getName(entity: ClientEntity) {
    return entity.name;
  },
  getOrderByInput(): ClientOrderByInput[] {
    return [
      { name: OrderByEnum.Asc }
    ];
  }
};
