<ppl-select [options]="entityTypeOptions"
            [displaySearch]="false"
            [value]="entityType"
            (valueChange)="onEntityTypeChange($event)"></ppl-select>

<ppl-entity-list [entityAdapters]="entityAdapters"
                 [entityType]="entityType"
                 [multipleSelection]="multipleSelection"
                 [optionDisabled]="optionDisabled"
                 [optionTemplate]="optionTemplate"
                 [optionTemplateRowHeight]="optionTemplateRowHeight"
                 [noResultsLabel]="noResultsLabel"
                 [value]="entityListValue"
                 (valueChange)="onEntityListValueChange($event)"></ppl-entity-list>

<div *ngIf="Array.isArray(value)"
     class="selection"
     i18n="@@N_entities_selected">
  {value.length, plural, =0 {No entities selected} =1 {1 entity selected} other {{{value.length}} entities selected}}
</div>