import type { AfterViewInit, OnDestroy} from '@angular/core';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { PplDatePickerIntl } from '@ppl/ui/date-picker';
import { getTodayDate, momentToIsoDateString } from '@ppl/utils';
import moment from 'moment';

@Component({
  selector: 'ppl-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerComponent implements AfterViewInit, OnDestroy {

  @Input() displayRemove: boolean;
  @Input() value: string | null;

  @Output() valueChange = new EventEmitter<string>();

  @ViewChild('pickerDate', { static: false }) pickerDate: ElementRef;

  pickerDateElement: any;

  constructor(
    private datePickerIntl: PplDatePickerIntl,
    private ngZone: NgZone
  ) {}

  ngAfterViewInit() {
    this.pickerDateElement = jQuery(this.pickerDate.nativeElement);

    this.pickerDateElement
      .datepicker({
        templates: {
          leftArrow: '<svg width="28" height="28" style="display: block"><path d="m 20 13 l -10 0 l 4.2 -4.25 l -0.7 -0.75 l -5.5 5.5 l 5.5 5.5 l 0.7 -0.75 l -4.2 -4.25 l 10 0" fill="#323130"></path></svg>',
          rightArrow: '<svg width="28" height="28" style="display: block"><path d="m 8 13 l 10 0 l -4.2 -4.25 l 0.7 -0.75 l 5.5 5.5 l -5.5 5.5 l -0.7 -0.75 l 4.2 -4.25 l -10 0" fill="#323130"></path></svg>'
        },
        todayHighlight: false,
        beforeShowDay: (date: Date) => {
          if (momentToIsoDateString(moment(date).local()) === getTodayDate()) {
            return {
              classes: 'today'
            };
          }
        },
        weekStart: this.datePickerIntl.firstWeekDay,
        language: this.datePickerIntl.bootstrapDatepickerLocale,
        locale: this.datePickerIntl.bootstrapDatepickerLocale
      })
      .on('changeDate', event => {
        this.ngZone.run(() => {
          this.valueChange.emit(momentToIsoDateString(moment(event.date).local()));
        });
      });

    this.pickerDateElement.datepicker('update', moment(this.value).toDate());
  }

  ngOnDestroy() {
    if (this.pickerDateElement) {
      this.pickerDateElement.datepicker('destroy');
    }
  }

  onRemoveClick() {
    this.valueChange.emit(null);
  }

  onTodayClick() {
    this.valueChange.emit(getTodayDate());
  }

}
