import { AccountAdapter } from '../domain/account.adapter';
import { AppointmentAdapter } from '../domain/appointment.adapter';
import { ClientAdapter } from '../domain/client.adapter';
import { ContactAdapter } from '../domain/contact.adapter';
import type { EntityAdapter, EntityAdapters } from '../domain/entity.adapter';
import { LeadAdapter } from '../domain/lead.adapter';
import { OpportunityAdapter } from '../domain/opportunity.adapter';
import { ProductAdapter } from '../domain/product.adapter';
import { TargetAdapter } from '../domain/target.adapter';
import { TaskAdapter } from '../domain/task.adapter';
import { Injectable } from '@angular/core';
import type { PplNamePipe } from '@ppl/auth';
import type { PageInfo, Query } from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import type { SpaceService } from '@ppl/space';
import type { PplAutocompleteOption, PplAutocompleteOptionsRequest } from '@ppl/ui/autocomplete';
import { gql, pascalCaseToCamelCase, unsubscribe } from '@ppl/utils';
import type { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuoteAdapter } from '../domain/quote.adapter';

@Injectable()
export class EntityListService {

  fetchEntities(session: FetchEntitiesSession, event: PplAutocompleteOptionsRequest, options: FetchEntitiesOptions) {
    if (event.lastOptionData && !event.lastOptionData.hasNextPage) {
      return;
    }

    unsubscribe(session.subscription);

    session.loading = true;

    if (!event.lastOptionData) {
      session.loadingType = (options.entityType === null) ? options.entityTypes[0] : options.entityType;
      session.options$.next([]);
    }

    session.subscription = this.fetchEntitiesOfType(options.spaceService, session.loadingType, event.lastOptionData ? event.lastOptionData.endCursor : null, event.filter, options).subscribe(({ entities, pageInfo }) => {
      const loadingLastType = options.entityTypes.indexOf(session.loadingType) === options.entityTypes.length - 1;

      if (options.entityType === null && !loadingLastType) {
        if (entities.length < (options.perPage || EntitiesPerPage)) {
          // Multiple entity types - try to fill PER_PAGE contant by loading next entityType
          this.updateSession(session, session.loadingType, entities, pageInfo, options);

          session.loadingType = options.entityTypes[options.entityTypes.indexOf(session.loadingType) + 1];

          this.fetchEntities(session, {
            ...event,
            lastOptionData: {
              endCursor: null,
              hasNextPage: true
            }
          }, options);
        } else {
          // Multiple entity types - override "hasNextPage" to true, so next entity types can be loaded later
          session.loading = false;
          this.updateSession(session, session.loadingType, entities, {
            endCursor: pageInfo.endCursor,
            hasNextPage: true
          }, options);
        }
      } else {
        // Single entity type or Multiple entity types (when loading last entityType, basically same scenario as single entity type)
        session.loading = false;
        this.updateSession(session, session.loadingType, entities, pageInfo, options);
      }
    });
  }

  fetchEntitiesById(entityIds: string[], options: FetchEntitiesByIdOptions) {
    const spaceService: SpaceService = options.spaceService;

    return spaceService.gqlClient.query<Query>({
      query: gql`
        query fetchEntitiesById($entityIds: [ID!]!) {
          entities {
            ${options.entityTypes.map(entityType => {
              const entityName = pascalCaseToCamelCase(entityType);

              return `
                ${entityName} {
                  getByIds(entityIds: $entityIds) {
                    ...${entityType}EntityListFields
                  }
                }
              `;
            }).join('\n')}
          }
        }
        ${options.entityTypes.reduce((entityFields, entityType) => gql`
          ${entityFields}
          ${this.getAdapterFor(entityType, options.entityAdapters).entityFields}
        `() as any, '')}
      `,
      variables: {
        entityIds
      },
      fetchPolicy: 'no-cache'
    }).pipe(map(({ data }) => {
      const result: PplAutocompleteOption[] = [];

      entityIds.forEach(entityId => {
        options.entityTypes.forEach(entityType => {
          const entityName = pascalCaseToCamelCase(entityType);
          const entityAdapter = this.getAdapterFor(entityType, options.entityAdapters);

          const dataEntity = data.entities[entityName].getByIds.find(entity => entity.id === entityId);

          if (dataEntity) {
            result.push({
              label: entityAdapter.getName(dataEntity, options.namePipe),
              value: dataEntity.id,
              data: {
                entity: dataEntity
              }
            });
          }
        });
      });

      return result;
    }));
  }

  private updateSession(session: FetchEntitiesSession, entityType: EntityNameEnum, entities: { id: string }[], pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>, options: FetchEntitiesOptions) {
    if (options.onSessionUpdate) {
      options.onSessionUpdate(session, entityType, entities, pageInfo, options);
    } else {
      const entityAdapter = this.getAdapterFor(entityType, options.entityAdapters);

      session.options$.next([
        ...session.options$.getValue(),
        ...entities.map(entity => ({
          label: entityAdapter.getName(entity, options.namePipe),
          value: entity.id,
          data: {
            entity,
            endCursor: pageInfo.endCursor,
            hasNextPage: pageInfo.hasNextPage
          },
          categoryId: (options.entityType === null) ? entityType : undefined
        }))
      ]);
    }
  }

  private fetchEntitiesOfType(spaceService: SpaceService, entityType: EntityNameEnum, endCursor: string | null, filter: string, options: FetchEntitiesOptions) {
    const entityName = pascalCaseToCamelCase(entityType);
    const entityAdapter = this.getAdapterFor(entityType, options.entityAdapters);

    return spaceService.gqlClient.query<Query>({
      query: gql`
        query fetchEntities($first: Int, $after: String, $filter: ${entityType}FilterInput, $orderBy: [${entityType}OrderByInput!]) {
          entities {
            ${entityName} {
              getByCriteria(first: $first, after: $after, filter: $filter, orderBy: $orderBy) {
                edges {
                  node {
                    ...${entityType}EntityListFields
                  }
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
              }
            }
          }
        }
        ${entityAdapter.entityFields}
      `,
      variables: {
        first: options.perPage || EntitiesPerPage,
        after: endCursor,
        filter: entityAdapter.getFilterInput(filter, options.excludeIds || [], options.includeIds || []),
        orderBy: entityAdapter.getOrderByInput()
      },
      fetchPolicy: 'no-cache'
    }).pipe(map(({ data }) => {
      return {
        entities: [...data.entities[entityName].getByCriteria.edges.map(edge => edge.node)],
        pageInfo: data.entities[entityName].getByCriteria.pageInfo
      };
    }));
  }

  private getAdapterFor(entityType: EntityNameEnum, entityAdapters?: EntityAdapters) {
    if (entityAdapters?.[entityType]) {
      return {
        ...DefaultEntityAdapters[entityType],
        ...entityAdapters[entityType]
      };
    }

    return DefaultEntityAdapters[entityType];
  }

}

export interface FetchEntitiesSession {
  options$: BehaviorSubject<PplAutocompleteOption[]>;
  loading: boolean;
  loadingType?: EntityNameEnum;
  subscription?: Subscription;
}

export interface FetchEntitiesOptions {
  entityAdapters?: EntityAdapters;
  entityType: EntityNameEnum | null;
  entityTypes: EntityNameEnum[];
  excludeIds?: string[];
  includeIds?: string[];
  namePipe: PplNamePipe;
  onSessionUpdate?: (
    session: FetchEntitiesSession,
    entityType: EntityNameEnum,
    entities: { id: string }[],
    pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>,
    options: FetchEntitiesOptions
  ) => void;
  perPage?: number;
  spaceService: any;
}

export interface FetchEntitiesByIdOptions {
  entityAdapters?: EntityAdapters;
  entityTypes: EntityNameEnum[];
  namePipe: PplNamePipe;
  spaceService: any;
}

const EntitiesPerPage = 20;

const DefaultEntityAdapters: { [id in EntityNameEnum]?: EntityAdapter } = {
  [EntityNameEnum.Account]: AccountAdapter,
  [EntityNameEnum.Contact]: ContactAdapter,
  [EntityNameEnum.Lead]: LeadAdapter,
  [EntityNameEnum.Opportunity]: OpportunityAdapter,
  [EntityNameEnum.Task]: TaskAdapter,
  [EntityNameEnum.Appointment]: AppointmentAdapter,
  [EntityNameEnum.Client]: ClientAdapter,
  [EntityNameEnum.Product]: ProductAdapter,
  [EntityNameEnum.Target]: TargetAdapter,
  [EntityNameEnum.Quote]: QuoteAdapter
};
