import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getLoggedInUserProfile, OfficeTokenService } from '@ppl/auth';
import { ActionsUtilsService } from '@ppl/core';
import { FirebaseEventsEnum, FirebaseService } from '@ppl/firebase';
import { I18nService } from '@ppl/i18n';
import * as CryptoJS from 'crypto-js';
import { of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { LocationService } from '../../../../services/location.service';
import { CoreStore } from '../../../../store/core.store';
import { OutlookNotificationsService } from '../../outlook/services/outlook-notifications.service';
import { OutlookService } from '../../outlook/services/outlook.service';
import type { TrackingVisits } from '../domain/tracking';

@Injectable()
export class TrackingService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private firebaseService: FirebaseService,
    private httpClient: HttpClient,
    private i18nService: I18nService,
    private locationService: LocationService,
    private officeTokenService: OfficeTokenService,
    private outlookService: OutlookService,
    private outlookNotificationsService: OutlookNotificationsService,
    private store: CoreStore
  ) {}

  startTracking(options: StartTrackingOptions = {}) {
    return this.actionsUtilsService.doAction(of(null).pipe(
      switchMap(() => {
        return this.outlookNotificationsService.addNotification({
          id: 'saveDraft',
          message: this.i18nService.translate('We_are_preparing_email_tracking,_please_wait.'),
          type: Office.MailboxEnums.ItemNotificationMessageType.ProgressIndicator
        });
      }),
      switchMap(() => {
        return this.outlookService.saveDraft();
      }),
      switchMap(itemId => {
        const messageHash = this.getMessageHash(itemId);

        return this.postRequest(`/track/email-tracking/start-tracking/${messageHash}`);
      }),
      switchMap((imageUrl: string) => {
        return this.parseBody().pipe(
          switchMap(body => {
            const trackingImage = document.createElement('img');

            trackingImage.src = imageUrl;
            trackingImage.alt = '';

            body.appendChild(trackingImage);

            return this.outlookService.setHtmlBody(body.innerHTML);
          })
        );
      }),
      switchMap(() => {
        this.firebaseService.logEvent(FirebaseEventsEnum.TrackEmail);

        return this.outlookNotificationsService.replaceNotification({
          id: 'saveDraft',
          message: this.i18nService.translate('This_email_has_turned_on_tracking.'),
          type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage
        });
      }),
      catchError(error => {
        this.outlookNotificationsService.replaceNotification({
          id: 'saveDraft',
          message: this.i18nService.translate(options.hiddenCall
            ? 'Cannot_turn_on_email_tracking._Please_open_the_Pipeliner_CRM_add-on_to_turn_it_on.'
            : 'Cannot_turn_on_email_tracking.'),
          type: Office.MailboxEnums.ItemNotificationMessageType.ErrorMessage
        });

        return throwError(error);
      })
    ));
  }

  stopTracking() {
    return this.actionsUtilsService.doAction(of(null).pipe(
      switchMap(() => {
        return this.outlookNotificationsService.removeNotification('saveDraft');
      }),
      switchMap(() => {
        return this.outlookService.saveDraft();
      }),
      switchMap(itemId => {
        const messageHash = this.getMessageHash(itemId);

        return this.postRequest(`/track/email-tracking/stop-tracking/${messageHash}`);
      }),
      switchMap(() => {
        return this.parseBody().pipe(
          switchMap(body => {
            const lastTrackingImage = this.getLastTrackingImage(body);

            if (lastTrackingImage) {
              lastTrackingImage.parentNode.removeChild(lastTrackingImage);
            }

            return this.outlookService.setHtmlBody(body.innerHTML);
          })
        );
      })
    ));
  }

  hasTracking() {
    return this.parseBody().pipe(
      map(body => {
        return !!this.getLastTrackingImage(body);
      })
    );
  }

  toggleTracking() {
    // This function is called from Ribbon button "Track email"
    return this.locationService.spaceGuardSuccess.pipe(
      switchMap(success => {
        if (!success) {
          return this.outlookNotificationsService.addNotification({
            id: 'saveDraft',
            message: this.i18nService.translate('Cannot_turn_on_email_tracking._Please_open_the_Pipeliner_CRM_add-on_to_turn_it_on.'),
            type: Office.MailboxEnums.ItemNotificationMessageType.ErrorMessage
          });
        } else {
          return this.hasTracking().pipe(
            switchMap(trackingEnabled => {
              if (trackingEnabled) {
                return this.stopTracking();
              } else {
                return this.startTracking({ hiddenCall: true });
              }
            })
          );
        }
      })
    );
  }

  getTrackingId() {
    return this.parseBody().pipe(
      map(body => {
        const trackingImage = this.getLastTrackingImage(body);

        if (trackingImage) {
          return this.getTrackingIdFromUrl(trackingImage.getAttribute('src'));
        }
      })
    );
  }

  getVisits(trackingId: string) {
    return this.postRequest('/track/email-tracking/retrieve-seen-info', [trackingId]).pipe(
      map(data => {
        const result: TrackingVisits = {
          count: data[trackingId].visits.length,
          firstVisit: (data[trackingId].visits.length > 0) ? `${data[trackingId].visits[0].request_datetime}Z` : null
        };

        return result;
      })
    );
  }

  private postRequest(url: string, body: any = {}) {
    return this.officeTokenService.getToken().pipe(
      switchMap(authToken => {
        return this.httpClient.post(`${environment.trackingUrl}${url}`, body, {
          headers: {
            Authorization: `PipelinerToken ${authToken}`
          }
        });
      })
    );
  }

  private parseBody() {
    return this.outlookService.getHtmlBody().pipe(
      map(htmlBody => {
        const domParser = new DOMParser();

        return domParser.parseFromString(htmlBody, 'text/html').body;
      })
    );
  }

  private getLastTrackingImage(body: HTMLElement) {
    // Find last tracking image created by the currently logged in user
    let lastTrackingImage: HTMLImageElement | null = null;

    const trackingImages = Array.from(body.querySelectorAll(`img[src*="pipelinersales.com/api/record/email"]`));

    trackingImages.forEach(trackingImage => {
      const trackingId = this.getTrackingIdFromUrl(trackingImage.getAttribute('src'));

      if (trackingId) {
        lastTrackingImage = trackingImage as HTMLImageElement;
      }
    });

    return lastTrackingImage;
  }

  private getTrackingIdFromUrl(url: string) {
    // Return tracking ID if the specified URL is tracking image URL created by the currently logged in user
    if (!url) {
      return null;
    }

    const id = url.match(/pipelinersales\.com\/api\/record\/email\/([a-f0-9]+)/);

    if (id && id[1].slice(0, 20) === this.getUserHash()) {
      return id[1];
    }

    return null;
  }

  private getUserHash() {
    const userProfile = this.store.get(getLoggedInUserProfile);

    return CryptoJS.SHA1(userProfile.email).toString().split('').reverse().join('').slice(0, 20);
  }

  private getMessageHash(messageId: string) {
    return this.getUserHash() + CryptoJS.SHA1(messageId).toString().slice(0, 20);
  }

}

export interface StartTrackingOptions {
  hiddenCall?: boolean;
}
