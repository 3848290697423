import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { AccountEntity, AppointmentEntity, ContactEntity, LeadEntity, OpportunityEntity, TaskEntity } from '@ppl/graphql-space-api';
import { EntityNameEnum, EntityPermsEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import type { PplMenuItem } from '@ppl/ui/menu-items';
import { hasSpaceEntityPermission } from '@ppl/space';
import { MemoizeLast, Unsubscribe } from '@ppl/utils';
import type { Subscription } from 'rxjs';
import { HostService } from '../../../../../services/host.service';
import { LocationService } from '../../../../../services/location.service';
import { CoreStore } from '../../../../../store/core.store';
import { ReminderKind } from '../../../activity/domain/reminder';
import { ReminderService } from '../../../activity/services/reminder.service';
import { EmailKind } from '../../../email/domain/email';
import { EmailService } from '../../../email/services/email.service';
import { OutlookItemMode, OutlookItemType } from '../../../outlook/domain/outlook';
import { OutlookService } from '../../../outlook/services/outlook.service';
import { CreateEntityMap, TypeToEntityType, TypeToRelationOption } from '../../domain/entity-card';

@Component({
  selector: 'ppl-actions-card',
  templateUrl: './actions-card.component.html',
  styleUrls: ['./actions-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Unsubscribe()
export class ActionsCardComponent implements OnInit {

  @Input() entity: AccountEntity | AppointmentEntity | ContactEntity | LeadEntity | OpportunityEntity | TaskEntity | null;

  itemDataRevision = 0;

  itemChangeSubscription: Subscription;

  @MemoizeLast<ActionsCardComponent>(['entity'])
  get createMenuItems(): PplMenuItem[] {
    if (!this.entity) {
      return [{
        disabled: !this.store.get(hasSpaceEntityPermission(EntityNameEnum.Contact, EntityPermsEnum.Create)),
        icon: 'menu-contact',
        id: EntityNameEnum.Contact,
        label: this.i18nService.translate('Save_contact_to_Pipeliner_as_new_contact')
      }, {
        disabled: !this.store.get(hasSpaceEntityPermission(EntityNameEnum.Account, EntityPermsEnum.Create)),
        icon: 'menu-account',
        id: EntityNameEnum.Account,
        label: this.i18nService.translate('Save_contact_to_Pipeliner_as_new_account')
      }];
    }

    const isEntityTypeDisabled = (entityType: EntityNameEnum) => {
      return !this.store.get(hasSpaceEntityPermission(entityType, EntityPermsEnum.Create)) || !CreateEntityMap[entityType].includes(this.entity?.__typename);
    };

    return [{
      disabled: isEntityTypeDisabled(EntityNameEnum.Account),
      icon: 'menu-account',
      id: EntityNameEnum.Account,
      label: this.i18nService.translate('Add_Account')
    }, {
      disabled: isEntityTypeDisabled(EntityNameEnum.Contact),
      icon: 'menu-contact',
      id: EntityNameEnum.Contact,
      label: this.i18nService.translate('Add_Contact')
    }, {
      disabled: isEntityTypeDisabled(EntityNameEnum.Lead),
      icon: 'menu-lead',
      id: EntityNameEnum.Lead,
      label: this.i18nService.translate('Add_Lead')
    }, {
      disabled: isEntityTypeDisabled(EntityNameEnum.Opportunity),
      icon: 'menu-opportunity',
      id: EntityNameEnum.Opportunity,
      label: this.i18nService.translate('Add_Opportunity')
    }, {
      disabled: isEntityTypeDisabled(EntityNameEnum.Task),
      icon: 'menu-task',
      id: EntityNameEnum.Task,
      label: this.i18nService.translate('Add_Task')
    }, {
      disabled: isEntityTypeDisabled(EntityNameEnum.Appointment),
      icon: 'menu-appointment',
      id: EntityNameEnum.Appointment,
      label: this.i18nService.translate('Add_Appointment')
    }];
  }

  @MemoizeLast<ActionsCardComponent>(['entity'])
  get reminderMenuItems(): PplMenuItem[] {
    return [{
      id: ReminderKind.InMinutes,
      label: this.i18nService.translate('Remind_me_in_20_minutes')
    }, {
      id: ReminderKind.InHour,
      label: this.i18nService.translate('Remind_me_in_1_hour')
    }, {
      id: ReminderKind.Tomorrow,
      label: this.i18nService.translate('Remind_me_tomorrow_(9:00)')
    }, {
      id: ReminderKind.NextWeek,
      label: this.i18nService.translate('Remind_me_next_week_(Mon,_9:00)')
    }, null, {
      id: ReminderKind.Custom,
      label: this.i18nService.translate('Pick_date_&_time')
    }];
  }

  @MemoizeLast<ActionsCardComponent>(['entity', 'itemDataRevision'])
  get emailMenuItems(): PplMenuItem[] {
    const entityTypeLc = this.entity ? TypeToEntityType[this.entity.__typename].toLowerCase() : '';

    return [{
      disabled: this.entity?.__typename === 'AppointmentEntity' || this.entity?.__typename === 'TaskEntity',
      icon: 'menu-save-email',
      id: EmailKind.Message,
      label: this.i18nService.translate(`Save_email_to_this_${entityTypeLc}`)
    }, {
      icon: 'menu-save-email-document',
      id: EmailKind.Document,
      label: this.i18nService.translate(`Save_email_to_this_${entityTypeLc}_as_document`)
    }, {
      disabled: !this.outlookService.isItemAvailable() || !this.outlookService.hasAttachments(),
      icon: 'menu-attach',
      id: EmailKind.Attachments,
      label: this.i18nService.translate(`Save_email_attachments_to_this_${entityTypeLc}`)
    }];
  }

  @MemoizeLast<ActionsCardComponent>(['entity'])
  get moreMenuItems(): PplMenuItem[] {
    return [{
      icon: 'menu-edit-info',
      id: 'Edit',
      label: this.i18nService.translate('Edit_information')
    }];
  }

  @MemoizeLast<ActionsCardComponent>(['entity'])
  get createDisabled() {
    return this.createMenuItems.every(menuItem => menuItem.disabled);
  }

  @MemoizeLast<ActionsCardComponent>(['entity'])
  get reminderDisabled() {
    return !this.entity
      || this.entity.__typename === 'AppointmentEntity'
      || (this.entity.__typename !== 'TaskEntity' && !this.store.get(hasSpaceEntityPermission(EntityNameEnum.Task, EntityPermsEnum.Create)));
  }

  @MemoizeLast<ActionsCardComponent>(['entity'])
  get emailDisabled() {
    return !this.entity || this.outlookService.getItemMode() === OutlookItemMode.Compose || this.outlookService.getItemType() !== OutlookItemType.Message;
  }

  @MemoizeLast<ActionsCardComponent>(['entity'])
  get moreDisabled() {
    return !this.entity || !this.entity.instancePermissions.includes(EntityPermsEnum.Write);
  }

  constructor(
    private emailService: EmailService,
    private hostService: HostService,
    private i18nService: I18nService,
    private locationService: LocationService,
    private outlookService: OutlookService,
    private reminderService: ReminderService,
    private store: CoreStore
  ) {}

  ngOnInit() {
    this.itemChangeSubscription = this.hostService.itemChange.subscribe(() => {
      this.itemDataRevision++;
    });
  }

  onCreateMenuItemClick(entityType: EntityNameEnum) {
    const options = this.entity ? {
      [TypeToRelationOption[this.entity.__typename]]: this.entity.id
    } : {};

    switch (entityType) {
      case EntityNameEnum.Account:
        this.locationService.createAccount(options);
        break;
      case EntityNameEnum.Appointment:
        this.locationService.createAppointment(options);
        break;
      case EntityNameEnum.Contact:
        this.locationService.createContact(options);
        break;
      case EntityNameEnum.Lead:
        this.locationService.createLead(options);
        break;
      case EntityNameEnum.Opportunity:
        this.locationService.createOpportunity(options);
        break;
      case EntityNameEnum.Task:
        this.locationService.createTask(options);
        break;
    }
  }

  onReminderMenuItemClick(kind: ReminderKind) {
    this.reminderService.setReminder(kind, this.entity);
  }

  onEmailMenuItemClick(kind: EmailKind) {
    const relations = [{
      entityId: this.entity.id,
      entityType: TypeToEntityType[this.entity.__typename]
    }];

    switch (kind) {
      case EmailKind.Message:
        this.emailService.saveAsMessage({
          relations,
          withAttachments: true
        });
        break;
      case EmailKind.Document:
        this.emailService.saveAsDocument({
          relations
        });
        break;
      case EmailKind.Attachments:
        this.emailService.saveAttachments({
          relations
        });
        break;
    }
  }

  onMoreMenuItemClick() {
    switch (this.entity.__typename) {
      case 'AccountEntity':
        this.locationService.updateAccount(this.entity.id);
        break;
      case 'AppointmentEntity':
        this.locationService.updateAppointment(this.entity.id);
        break;
      case 'ContactEntity':
        this.locationService.updateContact(this.entity.id);
        break;
      case 'LeadEntity':
        this.locationService.updateLead(this.entity.id);
        break;
      case 'OpportunityEntity':
        this.locationService.updateOpportunity(this.entity.id);
        break;
      case 'TaskEntity':
        this.locationService.updateTask(this.entity.id);
        break;
    }
  }

}
