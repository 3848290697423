import type { AuthState } from '@ppl/auth';
import { authReducer } from '@ppl/auth';
import type { SpaceState } from '@ppl/space';
import { spaceReducer } from '@ppl/space';
import { combineReducers } from '@ppl/store';
import type { DashboardState } from '../modules/dashboard/store/dashboard.reducer';
import { dashboardReducer } from '../modules/dashboard/store/dashboard.reducer';
import type { LocationState } from './location/location.reducer';
import { locationReducer } from './location/location.reducer';

export const coreReducer = combineReducers<CoreState>({
  auth: authReducer,
  dashboard: dashboardReducer,
  location: locationReducer,
  space: spaceReducer
});

export interface CoreState {
  auth: AuthState;
  dashboard: DashboardState;
  location: LocationState;
  space: SpaceState;
}
