<input #input
       pplInput
       [class.controls]="controls"
       [placeholder]="placeholder"
       [disabled]="disabled"
       (blur)="onBlur()"
       (input)="onChange($event)"
       (focus)="onFocus()"
       (keydown)="onKeyDown($event)" />

<div *ngIf="controls && !disabled"
     #controlUp
     class="control up"
     (click)="setValueRelative(1)">
  <ppl-icon *ngIf="ngUiTheme === 'pipeliner'"
            width="6px"
            height="4px"
            [actionElement]="controlUp"
            [hoverEnabled]="true"
            svgIcon="rowfull-up-darkblue"></ppl-icon>

  <ppl-icon *ngIf="ngUiTheme === 'fabric'"
            width="10px"
            height="5px"
            [actionElement]="controlUp"
            [hoverEnabled]="true"
            svgIcon="small-arrow-gray"></ppl-icon>
</div>

<div *ngIf="controls && !disabled"
     #controlDown
     class="control down"
     (click)="setValueRelative(-1)">
  <ppl-icon *ngIf="ngUiTheme === 'pipeliner'"
            width="6px"
            height="4px"
            [actionElement]="controlDown"
            [hoverEnabled]="true"
            svgIcon="rowfull-down-darkblue"></ppl-icon>

  <ppl-icon *ngIf="ngUiTheme === 'fabric'"
            width="10px"
            height="5px"
            [actionElement]="controlDown"
            [hoverEnabled]="true"
            svgIcon="small-arrow-gray"></ppl-icon>
</div>