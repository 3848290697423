<ppl-page>
  <ng-template #headerTemplate
               let-globalSearch="globalSearch">
    <ppl-page-detail-header label="Task"
                            i18n-label="@@Task"
                            [globalSearch]="globalSearch"></ppl-page-detail-header>
  </ng-template>

  <ng-container *ngIf="data$ | async; let data">
    <div *ngIf="data.state === AsyncState.FETCHED"
         class="container">
      <ppl-task-card-large [entity]="data.value.entity"
                           [disabled]="true"></ppl-task-card-large>

      <ppl-actions-card [entity]="data.value.entity"></ppl-actions-card>

      <ppl-task-fields-card [entity]="data.value.entity"
                            (dueDateChange)="onDueDateChange(data.value, $event)"></ppl-task-fields-card>

      <ppl-button-card *ngIf="data.value.entity.instancePermissions.includes(EntityPermsEnum.Write)"
                       label="Move Task"
                       i18n-label="@@Move_Task"
                       (click)="onMoveTaskClick(data.value)"></ppl-button-card>

      <ppl-description-card *ngIf="data.value.entity.description"
                            [entity]="data.value.entity"></ppl-description-card>

      <ppl-watchers-cards *ngIf="data.value.watchers.length"
                          [entities]="data.value.watchers"></ppl-watchers-cards>

      <ppl-linked-items-cards [accounts]="data.value.relatedAccounts"
                              [contacts]="data.value.relatedContacts"
                              [leads]="data.value.relatedLeads"
                              [opportunities]="data.value.relatedOpportunities"
                              [parentEntity]="data.value.entity"></ppl-linked-items-cards>

      <ppl-date-card [entity]="data.value.entity"></ppl-date-card>
    </div>

    <ppl-page-loading *ngIf="data.state === AsyncState.FETCHING"></ppl-page-loading>

    <ppl-entity-error *ngIf="data.state === AsyncState.ERROR"
                      [error]="data.error"></ppl-entity-error>
  </ng-container>
</ppl-page>