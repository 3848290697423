import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import type { FirebaseConfig} from './firebase.service';
import { FirebaseService, FIREBASE_CONFIG } from './firebase.service';

@NgModule({
  imports: [CommonModule],
  providers: [FirebaseService]
})
export class FirebaseModule {
  static forRoot(firebaseConfig: FirebaseConfig): ModuleWithProviders<FirebaseModule> {
    return {
      ngModule: FirebaseModule,
      providers: [FirebaseService, { provide: FIREBASE_CONFIG, useValue: firebaseConfig }]
    };
  }
}
