import { gql } from '@ppl/utils';

export const gqlFetchAppointmentFormData = gql`
  query fetchAppointmentFormData($id: ID!) {
    entities {
      appointment {
        getById(entityId: $id) {
          accountRelations {
            edges {
              node {
                id
                accountId
              }
            }
          }
          activityTypeId
          contactRelations {
            edges {
              node {
                id
                contactId
              }
            }
          }
          customFields
          description
          endDate
          id
          leadRelations {
            edges {
              node {
                id
                leadOpptyId
              }
            }
          }
          location
          opportunityRelations {
            edges {
              node {
                id
                leadOpptyId
              }
            }
          }
          ownerId
          startDate
          subject
          unitId
        }
      }
    }
  }
`;

export const gqlCreateAppointmentMutation = gql`
  mutation createAppointment($input: CreateAppointmentInput!, $validationLevel: [ValidationLevel!]) {
    createAppointment(input: $input, validationLevel: $validationLevel) {
      appointment {
        id
      }
    }
  }
`;

export const gqlUpdateAppointmentMutation = gql`
  mutation updateAppointment($input: UpdateAppointmentInput!, $validationLevel: [ValidationLevel!]) {
    updateAppointment(input: $input, validationLevel: $validationLevel) {
      clientMutationId
    }
  }
`;
