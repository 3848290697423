import { NgZone, Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { fetchScript } from '@ppl/utils';
import { BehaviorSubject, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export const FIREBASE_CONFIG = new InjectionToken<string>('FireabseConfig');

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  firebaseInitialized$ = new BehaviorSubject<boolean>(false);

  private retryAttepmts = 0;
  private showInConsole = false;

  constructor(
    private ngZone: NgZone,
    @Optional() @Inject(FIREBASE_CONFIG) private firebaseConfig?: FirebaseConfig,
  ) {
    this.showInConsole = this.getConsoleLogValue();
  }

  init() {
    if (this.firebaseConfig && (this.firebaseConfig.enabled || this.showInConsole)) {
      from(this.injectFirebaseApp()).pipe(
        switchMap(() => this.injectFirebaseAnalytics())
      ).subscribe({
        next: () => {
          this.initFirebase();
        }
      });

    }
  }

  logEvent(eventAction: FirebaseEventsEnum, eventParams?: Partial<FirebaseLogData>) {
    if (this.firebaseConfig && (this.firebaseConfig.enabled || this.showInConsole)) {
      if (this.showInConsole) {
        console.log(eventAction, eventParams);
      }

      try {
        const analytics = (window as any).firebase.analytics();
        this.ngZone.runOutsideAngular(() => {
          analytics.logEvent(eventAction, eventParams);
        });
      } catch (error) { }
    }
  }

  setUserProperties(properties: { [key: string]: any; }) {

    if (this.showInConsole) {
      console.log(properties);
    }

    try {
      const analytics = getAnalytics();
      this.ngZone.runOutsideAngular(() => {
        analytics.setUserProperties(properties);
      });
    } catch (error) { }
  }

  private injectFirebaseApp() {
    return fetchScript(`${baseUrl}firebase-app.js`);
  }
  private injectFirebaseAnalytics() {
    return fetchScript(`${baseUrl}firebase-analytics.js`);
  }


  private initFirebase() {
    if (this.firebaseConfig && (this.firebaseConfig.enabled || this.showInConsole)) {
      try {
        const firebase = (window as any).firebase;
        this.ngZone.runOutsideAngular(() => {
          firebase.initializeApp(this.firebaseConfig.configData);
          this.firebaseInitialized$.next(true);
        });
        this.retryAttepmts = maxRetryAttempts;
      } catch (error) {
        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            this.retryAttepmts++;

            if (this.retryAttepmts < maxRetryAttempts) {
              this.initFirebase();
            }
          }, 1000);
        });
      }
    }
  }

  private getConsoleLogValue() {
    const key = 'show_firebase_in_console_log';
    try {
      if (localStorage.hasOwnProperty(key)) {
        const value = localStorage.getItem(key);
        return JSON.parse(value);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

}

const maxRetryAttempts = 3;

const baseUrl = 'https://www.gstatic.com/firebasejs/7.21.1/';

function getAnalytics() {
  return (window as any).firebase.analytics();
}

export enum FirebaseEventsEnum {
  AddContact = 'add_contact',
  AddContactsRecipients = 'add_contacts_recipients',
  AddSalesTeamRecipients = 'add_sales_team_recipients',
  Archive = 'archive',
  BulkUpdate = 'bulk_update',
  ChangeDetailView = 'change_detail_view',
  ChangePipeline = 'change_pipeline',
  ChangeLeadProcess = 'change_lead_process',
  ChangeQuoteProcess = 'change_quote_process',
  ChangeType = 'change_type',
  ChangeView = 'change_view',
  Complete = 'complete',
  ContactSales = 'contact_sales',
  Copy = 'copy',
  CopyAddressFromAccount = 'copy_address_from_account',
  Create = 'create',
  CreateEmail = 'create_email',
  CreateFromEmail = 'create_from_email',
  CreateFromTemplate = 'create_from_template',
  Delete = 'delete',
  Enroll = 'enroll',
  Export = 'export',
  ExportVcf = 'export_vcf',
  FeatureAdoption = 'feature_adoption',
  FeaturePromotion = 'feature_promotion',
  FollowUp = 'follow_up',
  Forward = 'forward',
  Import = 'import',
  Login = 'login',
  MakeFavorite = 'make_favorite',
  Merge = 'merge',
  Move = 'move',
  NewReport = 'new_report',
  Ownership = 'ownership',
  Print = 'print',
  Qualify = 'qualify',
  Reactivate = 'reactivate',
  Reopen = 'reopen',
  Reply = 'reply',
  SaveEmail = 'save_email',
  SaveEmailAsDocument = 'save_email_as_document',
  SaveEmailAttachments = 'save_email_attachments',
  ScheduleReport = 'schedule_report',
  Send = 'send',
  SendReport = 'send_report',
  SessionStart = 'session_start',
  SetReminder = 'set_reminder',
  Sharing = 'sharing',
  TrackEmail = 'track_email',
  TriggerProcess = 'trigger_process',
  Update = 'update',
  Unsubscribe = 'unsubscribe',
  ViewChanges = 'view_changes',
  Win = 'win',
}

export interface FirebaseLogData {
  current_screen: string;
  current_screen_settings: string;
  previous_screen?: string;
  previous_screen_settings?: string;
  entity?: string;
  entity_source?: string;
  entities?: string;
  settings?: string;
  feature?: string;
}

export interface FirebaseConfig {
  enabled: boolean;
  configData: FirebaseConfigData;
}

export interface FirebaseConfigData {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}
