import { fuzzySearch } from '../autocomplete/fuzzy-search';
import type { PplOptionListCategory, PplOptionListOption} from '../option-list/option-list.component';
import { PplOptionListComponent } from '../option-list/option-list.component';
import type {
  OnChanges,
  OnInit,
  SimpleChanges} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
,
  TemplateRef} from '@angular/core';
import { notFirstChange } from '@ppl/utils';

@Component({
  selector: 'ppl-option-list-with-filter',
  templateUrl: './option-list-with-filter.component.html',
  styleUrls: ['./option-list-with-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplOptionListWithFilterComponent implements OnInit, OnChanges {
  @Input() categories: PplOptionListCategory[];
  @Input() displayCategoriesSidebar?: boolean;
  @Input() options: PplOptionListOption[];
  @Input() searchText: string | null = null;
  @Input() autoFocusSearchInput = true;
  @Input() optionTemplate?: TemplateRef<any>;
  @Input() value?: string;
  @Input() maxContainerHeight?: number;
  @Input() optionTemplateRowHeight?: number;
  @Input() categoriesSidebarWidth?: number;
  @Input() displaySearch = true;

  @ViewChild(PplOptionListComponent, { static: true }) list: PplOptionListComponent;

  @Output() optionSelect = new EventEmitter<PplOptionListOption | string>();
  @Output() keyEscapePressed = new EventEmitter<null>();

  filteredOptions: PplOptionListOption[] = [];
  localSearchText = '';
  autoSelectValue: string;

  get displayNoResults() {
    return this.filteredOptions.length === 0;
  }

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (notFirstChange(changes.searchText) || notFirstChange(changes.value) || notFirstChange(changes.options)) {
      this.filterOptions(this.searchText || '');
    }
  }

  ngOnInit() {
    this.filterOptions(this.searchText || '');
  }

  filterOptions(searchText: string) {
    this.filteredOptions = fuzzySearch({ list: this.options, term: searchText });
    if (this.filteredOptions.find(option => option.value === this.value)) {
      this.autoSelectValue = this.value;
    } else {
      this.autoSelectValue = (this.filteredOptions.length !== 0 ? this.filteredOptions[0].value : null);
    }
  }

  onSearch(searchText: string) {
    if (searchText !== this.localSearchText) {
      this.localSearchText = searchText;
      this.filterOptions(searchText);
    }
  }

  onValueChange(value: string) {
    this.autoSelectValue = value;
  }

  onOptionSelect(option: PplOptionListOption) {
    this.optionSelect.emit(option);
  }
}
