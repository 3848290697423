import type { OnInit} from '@angular/core';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fetchAsyncValue } from '@ppl/core';
import { TaskFieldsEnum } from '@ppl/domain';
import type { AsyncValue } from '@ppl/store';
import { AsyncState } from '@ppl/store';
import type { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocationService } from '../../../../services/location.service';
import { mergeFormValue } from '../../../shared/entity-form-api/domain/merge-form-value';
import { EntityFormComponent } from '../../../shared/entity-form/components/entity-form/entity-form.component';
import type { EntityFormControl } from '../../../shared/entity-form/domain/controls';
import type { CanLeaveGuardComponent } from '../../../shared/entity-form/router/can-leave-entity-form.service';
import type { TaskFormData, TaskFormValue } from '../../domain/task-form';
import { TaskFormService } from '../../services/task-form.service';

@Component({
  selector: 'ppl-task-form',
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskFormComponent implements OnInit, CanLeaveGuardComponent {

  @ViewChild(EntityFormComponent) entityForm: EntityFormComponent;

  data$: Observable<AsyncValue<TaskFormData>>;

  AsyncState = AsyncState;

  controls: EntityFormControl[];
  valid = true;

  value: TaskFormValue;
  valueChanged = false;
  valueReminderDateChanged = false;

  customFieldsVisible = true;

  id = this.route.snapshot.paramMap.get('id');

  accountId = this.route.snapshot.queryParamMap.get('accountId');
  contactId = this.route.snapshot.queryParamMap.get('contactId');
  leadId = this.route.snapshot.queryParamMap.get('leadId');
  opportunityId = this.route.snapshot.queryParamMap.get('opportunityId');

  constructor(
    private locationService: LocationService,
    private route: ActivatedRoute,
    private taskFormService: TaskFormService
  ) {}

  ngOnInit() {
    this.data$ = fetchAsyncValue(this.taskFormService.fetchData({
      id: this.id,
      relatedAccountId: this.accountId,
      relatedContactId: this.contactId,
      relatedLeadId: this.leadId,
      relatedOpportunityId: this.opportunityId
    }).pipe(
      tap(data => {
        if (data) {
          this.value = this.taskFormService.getValue(data, data.entity?.activityTypeId || this.taskFormService.getDefaultFormId());
          this.controls = this.taskFormService.getControls(data, this.value.activityTypeId);
        }
      })
    ));
  }

  save(data: TaskFormData) {
    if (data.entity) {
      return this.taskFormService.updateEntity(this.value, data);
    } else {
      return this.taskFormService.createEntity(this.value, data);
    }
  }

  onValueChange(data: TaskFormData, value: TaskFormValue) {
    if (value.reminderDate !== this.value.reminderDate) {
      this.value = value;
      this.valueReminderDateChanged = true;
    } else if (value[TaskFieldsEnum.DueDateField] !== this.value[TaskFieldsEnum.DueDateField] && value.reminderDate !== undefined) {
      this.value = {
        ...value,
        reminderDate: this.valueReminderDateChanged
          ? value.reminderDate
          : this.taskFormService.getDefaultReminderDate(value[TaskFieldsEnum.DueDateField])
      };
    } else if (value.activityTypeId !== this.value.activityTypeId) {
      const prevFormInitValue = this.taskFormService.getValue(data, this.value.activityTypeId);

      this.value = mergeFormValue(prevFormInitValue, this.value, this.taskFormService.getValue(data, value.activityTypeId));
      this.controls = this.taskFormService.getControls(data, value.activityTypeId);
    } else {
      this.value = value;
    }

    this.valueChanged = true;
  }

  onSaveClick(data: TaskFormData) {
    this.valid = this.entityForm.isValid();

    if (!this.valid) {
      this.customFieldsVisible = true;

      return;
    }

    this.save(data).subscribe(() => {
      this.valueChanged = false;
      this.locationService.goBack();
    });
  }

  onCancelClick() {
    this.locationService.goBack();
  }

}
