import { Injectable } from '@angular/core';
import type { CreateEmailInput, CreateEmailPayload, EmailDirectionEnum, EmailFilterInput, Query } from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { SpaceService } from '@ppl/space';
import { gql } from '@ppl/utils';
import { map } from 'rxjs/operators';
import type { EmailRelation } from '../domain/email';
import { gqlCreateEmailMutation, gqlFetchEmails } from './email.graphql';

@Injectable()
export class EmailEntityService {

  constructor(
    private spaceService: SpaceService
  ) {}

  fetchEmail(options: FetchEmailOptions) {
    const filter: EmailFilterInput = {
      uid: { eq: [options.uid] }
    };

    return this.spaceService.gqlClient.query<Query>({
      query: gqlFetchEmails,
      fetchPolicy: 'no-cache',
      variables: {
        filter
      }
    }).pipe(
      map(({ data }) => {
        return data.entities.email.getAll[0] || null;
      })
    );
  }

  createEmail(options: CreateEmailOptions) {
    const input: CreateEmailInput = {
      accountRelations: [],
      body: options.body,
      bodySummary: options.shortBody,
      cc: options.ccRecipients.join(', '),
      contactRelations: [],
      contentType: 'text/html',
      dateSent: options.created,
      direction: options.direction,
      leadRelations: [],
      opportunityRelations: [],
      sender: options.sender,
      subject: options.subject,
      to: options.toRecipients.join(', '),
      uid: options.uid
    };

    options.relations.forEach(relation => {
      switch (relation.entityType) {
        case EntityNameEnum.Account:
          input.accountRelations.push({ accountId: relation.entityId });
          break;
        case EntityNameEnum.Contact:
          input.contactRelations.push({ contactId: relation.entityId });
          break;
        case EntityNameEnum.Lead:
          input.leadRelations.push({ leadOpptyId: relation.entityId });
          break;
        case EntityNameEnum.Opportunity:
          input.opportunityRelations.push({ leadOpptyId: relation.entityId });
          break;
      }
    });

    return this.spaceService.gqlClient.mutate<{ createEmail: CreateEmailPayload }>({
      mutation: gqlCreateEmailMutation,
      variables: {
        input
      }
    }).pipe(
      map(({ data }) => {
        return data.createEmail.email.id;
      })
    );
  }

  createEmailRelations(options: CreateEmailRelationsOptions) {
    return this.spaceService.gqlClient.mutate({
      mutation: gql`
        mutation createMessageRelations($messageId: ID!) {
          ${options.relations.map((relation, relationIndex) => {
            return `
              createMessageRelation${relationIndex}: createMessageRelation(input: {
                messageId: $messageId,
                ${RelationFields[relation.entityType]}: "${relation.entityId}"
              }) {
                clientMutationId
              }
            `;
          })}
        }
      `,
      variables: {
        messageId: options.id
      }
    });
  }

  createCloudObjects(options: CreateCloudObjectsOptions) {
    return this.spaceService.gqlClient.mutate({
      mutation: gql`
        mutation createCloudObjectRelations(${options.files.map((file, fileIndex) => `$fileName${fileIndex}: String!, $content${fileIndex}: Base64`).join(',')}) {
          ${options.files.map((file, fileIndex) => {
            return options.relations.map((relation, relationIndex) => {
              return `
                createRelation${fileIndex}_${relationIndex}: createCloudObjectRelation(input: {
                  cloudObject: { filename: $fileName${fileIndex}, type: S3File, content: $content${fileIndex} },
                  ${RelationFields[relation.entityType]}: "${relation.entityId}"
                }) {
                  clientMutationId
                }
              `;
            }).join('\n');
          }).join('\n')}
        }
      `,
      variables: options.files.reduce((variables, file, fileIndex) => ({
        ...variables,
        [`fileName${fileIndex}`]: file.name,
        [`content${fileIndex}`]: file.content
      }), {})
    });
  }

}

const RelationFields: { [id in EntityNameEnum]?: string } = {
  [EntityNameEnum.Account]: 'accountId',
  [EntityNameEnum.Appointment]: 'activityId',
  [EntityNameEnum.Contact]: 'contactId',
  [EntityNameEnum.Lead]: 'leadOpptyId',
  [EntityNameEnum.Opportunity]: 'leadOpptyId',
  [EntityNameEnum.Message]: 'messageId',
  [EntityNameEnum.Task]: 'activityId'
};

export interface FetchEmailOptions {
  uid: string;
}

export interface CreateEmailOptions {
  body: string;
  ccRecipients: string[];
  created: string;
  direction: EmailDirectionEnum;
  relations: {
    entityId: string;
    entityType: EntityNameEnum;
  }[];
  sender: string;
  shortBody: string;
  subject: string;
  toRecipients: string[];
  uid: string;
}

export interface CreateEmailRelationsOptions {
  id: string;
  relations: EmailRelation[];
}

export interface CreateCloudObjectsOptions {
  files: {
    content: string;
    name: string;
  }[];
  relations: EmailRelation[];
}
