import { DayName } from '@ppl/graphql-user-api';


export const dayNameMap: { [K in DayName]: number } = {
  [DayName.Sunday]: 0,
  [DayName.Monday]: 1,
  [DayName.Tuesday]: 2,
  [DayName.Wednesday]: 3,
  [DayName.Thursday]: 4,
  [DayName.Friday]: 5,
  [DayName.Saturday]: 6
};
