import type { OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { PplNamePipe } from '@ppl/auth';
import type { FetchEntitiesSession } from '@ppl/components/entity-list';
import { EntityAdapters, EntityListService } from '@ppl/components/entity-list';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { SpaceService } from '@ppl/space';
import type { PplAutocompleteOption } from '@ppl/ui/autocomplete';
import type { PplMultipleAutocompleteOption, PplMultipleAutocompleteOptionsRequest } from '@ppl/ui/multiple-autocomplete';
import { FormValueControl, getFormControlProvider, MemoizeLast, Unsubscribe, unsubscribe } from '@ppl/utils';
import type { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ppl-entity-multiple-select',
  templateUrl: './entity-multiple-select.component.html',
  styleUrls: ['./entity-multiple-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    getFormControlProvider(() => EntityMultipleSelectComponent)
  ]
})
@FormValueControl()
@Unsubscribe()
export class EntityMultipleSelectComponent implements OnChanges, OnInit, OnDestroy {

  @Input() entityTypes: EntityNameEnum[];
  @Input() value: EntityMultipleSelectItem[];

  @Input() entityAdapters?: EntityAdapters;
  @Input() maxContainerHeight?: number;
  @Input() maxValues?: number;
  @Input() optionTemplate?: TemplateRef<any>;
  @Input() optionTemplateRowHeight?: number;
  @Input() selectedOptionTemplate?: TemplateRef<any>;

  @Output() valueChange = new EventEmitter<EntityMultipleSelectItem[]>();

  fetchEntitiesSession: FetchEntitiesSession = {
    loading: false,
    options$: new BehaviorSubject<PplMultipleAutocompleteOption[]>([])
  };

  optionValueCache: { [id: string]: PplAutocompleteOption } = {};
  optionsSubscription: Subscription;

  valueLoading = false;
  valueSubscription: Subscription;

  @MemoizeLast<EntityMultipleSelectComponent>(['entityTypes'])
  get entitiesCategories() {
    return this.entityTypes.map(entityType => ({
      id: entityType,
      label: this.i18nService.translate(`EnumEntityNameEnum_${entityType}`)
    }));
  }

  @MemoizeLast<EntityMultipleSelectComponent>(['value'])
  get entityIds() {
    return this.value.map(item => item.entityId);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private entityListService: EntityListService,
    private i18nService: I18nService,
    private namePipe: PplNamePipe,
    private spaceService: SpaceService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      const entityIds = this.entityIds.filter(id => !this.optionValueCache[id]);

      if (entityIds.length) {
        unsubscribe(this.valueSubscription);
        this.fetchValue(entityIds);
      }
    }
  }

  ngOnInit() {
    this.optionsSubscription = this.fetchEntitiesSession.options$.subscribe(options => {
      options.forEach(option => {
        this.optionValueCache[option.value] = option;
      });
    });
  }

  ngOnDestroy() {
    unsubscribe(this.fetchEntitiesSession.subscription);
  }

  onOptionsRequest(event: PplMultipleAutocompleteOptionsRequest) {
    this.entityListService.fetchEntities(this.fetchEntitiesSession, event, {
      entityAdapters: this.entityAdapters,
      entityType: (this.entityTypes.length === 1) ? this.entityTypes[0] : null,
      entityTypes: this.entityTypes,
      excludeIds: this.entityIds,
      namePipe: this.namePipe,
      spaceService: this.spaceService
    });
  }

  onValueChange(value: string[]) {
    this.valueChange.emit(value.map(id => {
      if (this.optionValueCache[id]) {
        return {
          entityId: id,
          entityType: TypeToEntityType[this.optionValueCache[id].data.entity.__typename]
        };
      }

      return this.value.find(item => item.entityId === id);
    }));
  }

  private fetchValue(entityIds: string[]) {
    this.valueLoading = true;

    this.valueSubscription = this.entityListService.fetchEntitiesById(entityIds, {
      entityAdapters: this.entityAdapters,
      entityTypes: this.entityTypes,
      namePipe: this.namePipe,
      spaceService: this.spaceService
    }).subscribe(options => {
      this.valueLoading = false;

      options.forEach(option => {
        this.optionValueCache[option.value] = option;
      });

      this.changeDetectorRef.detectChanges();
    });
  }

}

const TypeToEntityType: { [id: string]: EntityNameEnum } = {
  'AccountEntity': EntityNameEnum.Account,
  'AppointmentEntity': EntityNameEnum.Appointment,
  'ClientEntity': EntityNameEnum.Client,
  'ContactEntity': EntityNameEnum.Contact,
  'LeadEntity': EntityNameEnum.Lead,
  'OpportunityEntity': EntityNameEnum.Opportunity,
  'TaskEntity': EntityNameEnum.Task
};

export interface EntityMultipleSelectItem {
  entityId: string;
  entityType: EntityNameEnum;
}
