import type { ValidationErrors } from '@angular/forms';

export interface EntityFormEmailValidator {
  kind: EntityFormValidatorKind.Email;
}

export interface EntityFormMinDateValidator {
  date: string;
  kind: EntityFormValidatorKind.MinDate;
}

export interface EntityFormMinItemsValidator {
  items: number;
  kind: EntityFormValidatorKind.MinItems;
}

export interface EntityFormMinValueValidator {
  value: number;
  kind: EntityFormValidatorKind.MinValue;
}

export interface EntityFormMaxDateValidator {
  date: string;
  kind: EntityFormValidatorKind.MaxDate;
}

export interface EntityFormMaxLengthValidator {
  length: number;
  kind: EntityFormValidatorKind.MaxLength;
}

export interface EntityFormMaxValueValidator {
  value: number;
  kind: EntityFormValidatorKind.MaxValue;
}

export interface EntityFormRequiredValidator {
  kind: EntityFormValidatorKind.Required;
}

export interface EntityFormRequiredOneOfValidator {
  controlIds: string[];
  error: ValidationErrors;
  kind: EntityFormValidatorKind.RequiredOneOf;
}

export enum EntityFormValidatorKind {
  Email = 'Email',
  MinDate = 'MinDate',
  MinItems = 'MinItems',
  MinValue = 'MinValue',
  MaxDate = 'MaxDate',
  MaxLength = 'MaxLength',
  MaxValue = 'MaxValue',
  Required = 'Required',
  RequiredOneOf = 'RequiredOneOf'
}

export type EntityFormValidator =
  EntityFormEmailValidator
  | EntityFormMinDateValidator
  | EntityFormMinItemsValidator
  | EntityFormMinValueValidator
  | EntityFormMaxDateValidator
  | EntityFormMaxLengthValidator
  | EntityFormMaxValueValidator
  | EntityFormRequiredValidator
  | EntityFormRequiredOneOfValidator;
