import { EntityFormControlKind } from '../../../entity-form/domain/controls';
import type { EntityFormApiFieldTypeAdapter } from '../entity-form-api';

export const IntegerFieldTypeAdapter: EntityFormApiFieldTypeAdapter = {
  id: 'integer',
  getControl({ fieldDescriptor, formField }) {
    return {
      id: fieldDescriptor.id,
      label: formField?.label ?? fieldDescriptor.name,
      step: 1,
      kind: EntityFormControlKind.Number
    };
  }
};
