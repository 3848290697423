import { ApiService } from './services/api.service';
import { SpaceService } from './services/space.service';
import { SPACE_MODULE_OPTIONS } from './space.tokens';
import { SpaceStore } from './store/space.store';
import { CommonModule } from '@angular/common';
import type {
  ModuleWithProviders} from '@angular/core';
import {
  ErrorHandler,
  isDevMode,
  NgModule,
  NgZone
} from '@angular/core';
import {
  API_USER_GQL_URL,
  APP_URL,
  AuthModule,
  AuthStore,
  CUSTOMER_PORTAL_URL,
  ErrorHandlerService,
  IGNORE_SENTRY_ERRORS,
  ignoredSentryErrors,
  LOGICAL_USER_ERRORS,
  logicalUserErrors,
  PIPELINER_TOKEN,
  PplNamePipe,
  RedirectTokenService,
  SENTRY_ENABLED,
  SSO_URL,
  TokenService
} from '@ppl/auth';
import { I18nModule } from '@ppl/i18n';
import type { Reducer, Store } from '@ppl/store';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    I18nModule
  ],
  exports: [
    I18nModule,
    PplNamePipe
  ],
  providers: [
    ApiService,
    SpaceService
  ]
})
export class SpaceModule {

  static withOptions(options: SpaceModuleOptions): ModuleWithProviders<SpaceModule> {
    return {
      ngModule: SpaceModule,
      providers: [
        { provide: SPACE_MODULE_OPTIONS, useValue: options },
        { provide: PIPELINER_TOKEN, useValue: '' },
        { provide: API_USER_GQL_URL, useValue: options.apiUserGqlUrl },
        { provide: APP_URL, useValue: options.appUrl },
        { provide: CUSTOMER_PORTAL_URL, useValue: '' },
        { provide: SSO_URL, useValue: options.ssoUrl },
        { provide: SENTRY_ENABLED, useValue: options.sentryEnabled },
        { provide: LOGICAL_USER_ERRORS, useValue: logicalUserErrors },
        { provide: IGNORE_SENTRY_ERRORS, useValue: ignoredSentryErrors },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: AuthStore, useExisting: options.store },
        { provide: SpaceStore, useExisting: options.store },
        {
          provide: TokenService,
          useFactory: tokenServiceFactory,
          deps: [SPACE_MODULE_OPTIONS, AuthStore, NgZone]
        },
        {
          provide: options.store,
          useFactory: storeFactory,
          deps: [SPACE_MODULE_OPTIONS]
        }
      ]
    };
  }

}

export function tokenServiceFactory(options: SpaceModuleOptions, store: any, ngZone: any) {
  if (isDevMode()) {
    return new RedirectTokenService(store);
  }

  return new options.tokenService(store, ngZone);
}

export function storeFactory(options: SpaceModuleOptions) {
  const store = new options.store();

  store.setRootReducer(options.storeReducerFactory());

  (window as any).store = store;

  return store;
}

export interface SpaceModuleOptions {
  apiUserGqlUrl: string;
  appId: string;
  appUrl: string;
  sentryEnabled: boolean;
  store: { new(): Store<any, any> };
  storeReducerFactory: () => Reducer<any>;
  ssoUrl: string;
  tokenService: any;
}
