import { gql } from '@ppl/utils';
import { AccountCardMediumFields, ContactCardMediumFields, LeadCardMediumFields, OpportunityCardMediumFields, AppointmentCardLargeFields, ClientCardMediumFields } from '../../shared/entity-card/services/entity-card.graphql';

export const gqlFetchAppointmentData = gql`
  query fetchAppointmentData($entityId: UUID!) {
    screens {
      extractViews {
        appointment(entityId: $entityId) {
          entity {
            ...AppointmentCardLargeFields
            accountRelations {
              edges {
                node {
                  id
                  account {
                    ...AccountCardMediumFields
                  }
                }
              }
            }
            contactRelations {
              edges {
                node {
                  id
                  contact {
                    ...ContactCardMediumFields
                  }
                }
              }
            }
            description
            inviteesClients {
              edges {
                node {
                  client {
                    ...ClientCardMediumFields
                  }
                  id
                }
              }
            }
            leadRelations {
              edges {
                node {
                  id
                  lead {
                    ...LeadCardMediumFields
                  }
                }
              }
            }
            opportunityRelations {
              edges {
                node {
                  id
                  oppty {
                    ...OpportunityCardMediumFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${AppointmentCardLargeFields}
  ${AccountCardMediumFields}
  ${ClientCardMediumFields}
  ${ContactCardMediumFields}
  ${LeadCardMediumFields}
  ${OpportunityCardMediumFields}
`;
