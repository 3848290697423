import { gql } from '@ppl/utils';

export const gqlFetchLeadFormData = gql`
  query fetchLeadFormData($id: ID!) {
    entities {
      lead {
        getById(entityId: $id) {
          accountRelations {
            edges {
              node {
                id
                accountId
                isPrimary
              }
            }
          }
          contactRelations {
            edges {
              node {
                id
                contactId
                isPrimary
              }
            }
          }
          customFields
          description
          id
          leadTypeId
          name
          ownerId
          ranking
          step {
            id
            leadProcessId
          }
          unitId
        }
      }
    }
  }
`;

export const gqlCreateLeadMutation = gql`
  mutation createLead($input: CreateLeadInput!, $validationLevel: [ValidationLevel!]) {
    createLead(input: $input, validationLevel: $validationLevel) {
      lead {
        id
      }
    }
  }
`;

export const gqlUpdateLeadMutation = gql`
  mutation updateLead($input: UpdateLeadInput!, $validationLevel: [ValidationLevel!]) {
    updateLead(input: $input, validationLevel: $validationLevel) {
      clientMutationId
    }
  }
`;
