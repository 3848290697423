import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { DashboardOtherItem } from '../../../domain/dashboard';

@Component({
  selector: 'ppl-dashboard-recipients-other',
  templateUrl: './dashboard-recipients-other.component.html',
  styleUrls: ['./dashboard-recipients-other.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardRecipientsOtherComponent {

  @Input() items: DashboardOtherItem[];
  @Input() largeCard: boolean;

}
