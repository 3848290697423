<div class="icon">
  <ng-template [ngTemplateOutlet]="iconTemplate"></ng-template>
</div>

<div class="content">
  <div class="name">
    <ng-template [ngTemplateOutlet]="nameTemplate"></ng-template>
  </div>
  <div class="description">
    <ng-template [ngTemplateOutlet]="descriptionTemplate"></ng-template>
  </div>
</div>

<div *ngIf="actionTemplate"
     class="action">
  <ng-template [ngTemplateOutlet]="actionTemplate"></ng-template>
</div>