import { gql } from '@ppl/utils';
import { AccountCardMediumFields, ClientCardMediumFields, ContactCardMediumFields, LeadCardMediumFields, OpportunityCardMediumFields, TaskCardLargeFields } from '../../shared/entity-card/services/entity-card.graphql';

export const gqlFetchTaskData = gql`
  query fetchTaskData($entityId: UUID!) {
    screens {
      extractViews {
        task(entityId: $entityId) {
          entity {
            ...TaskCardLargeFields
            accountRelations {
              edges {
                node {
                  id
                  account {
                    ...AccountCardMediumFields
                  }
                }
              }
            }
            contactRelations {
              edges {
                node {
                  id
                  contact {
                    ...ContactCardMediumFields
                  }
                }
              }
            }
            description
            leadRelations {
              edges {
                node {
                  id
                  lead {
                    ...LeadCardMediumFields
                  }
                }
              }
            }
            opportunityRelations {
              edges {
                node {
                  id
                  oppty {
                    ...OpportunityCardMediumFields
                  }
                }
              }
            }
            sharingClients {
              edges {
                node {
                  id
                  client {
                    ...ClientCardMediumFields
                  }
                  role
                }
              }
            }
          }
        }
      }
    }
  }
  ${TaskCardLargeFields}
  ${AccountCardMediumFields}
  ${ClientCardMediumFields}
  ${ContactCardMediumFields}
  ${LeadCardMediumFields}
  ${OpportunityCardMediumFields}
`;
