import type { AuthState } from '@ppl/auth';
import { Store } from '@ppl/store';
import type { SpaceActions } from './space.actions';
import type { SpaceState } from './space.reducer';

export interface SpaceStateInCoreState {
  auth: AuthState;
  space: SpaceState;
}

export class SpaceStore extends Store<SpaceStateInCoreState, SpaceActions> {}
