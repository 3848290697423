import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthModule } from '@ppl/auth';
import { OutlookNotificationsService } from './services/outlook-notifications.service';
import { OutlookSoapService } from './services/outlook-soap.service';
import { OutlookUtilsService } from './services/outlook-utils.service';
import { OutlookService } from './services/outlook.service';
// import { OutlookServiceMock } from './services/outlook.service.mock';

@NgModule({
  imports: [
    AuthModule,
    HttpClientModule
  ],
  providers: [
    OutlookNotificationsService,
    OutlookService,
    // { provide: OutlookService, useClass: OutlookServiceMock },
    OutlookSoapService,
    OutlookUtilsService
  ]
})
export class OutlookModule {}
