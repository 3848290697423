<ppl-card-large [disabled]="true">
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="null"
                [kind]="AvatarKind.Large"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    {{person.name}}
  </ng-template>

  <ng-template #descriptionTemplate>
    <ng-container i18n="@@Unknown_contact">Unknown contact</ng-container>

    <br />

    {{person.email}}
  </ng-template>
</ppl-card-large>
