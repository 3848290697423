import type { AfterContentInit} from '@angular/core';
import { ChangeDetectionStrategy, Component, ContentChildren, QueryList } from '@angular/core';
import { CardTabComponent } from './card-tab/card-tab.component';

@Component({
  selector: 'ppl-card-tabs',
  templateUrl: './card-tabs.component.html',
  styleUrls: ['./card-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardTabsComponent implements AfterContentInit {

  @ContentChildren(CardTabComponent) tabs: QueryList<CardTabComponent>;

  ngAfterContentInit() {
    this.selectTab(this.tabs.first);
  }

  selectTab(tab: CardTabComponent) {
    this.tabs.toArray().forEach(otherTab => {
      otherTab.setSelected(false);
    });

    tab.setSelected(true);
  }

}
