import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthModule } from '@ppl/auth';
import { CoreModule } from '@ppl/core';
import { PplDialogModule } from '@ppl/ui/dialog';
import { PplIconModule } from '@ppl/ui/icon';
import { AboutComponent } from './components/about/about.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { UserProfileService } from './services/user-profile.service';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    CoreModule,
    PplDialogModule,
    PplIconModule
  ],
  declarations: [
    AboutComponent,
    SignInComponent,
    UserProfileComponent
  ],
  exports: [
    SignInComponent,
    UserProfileComponent
  ],
  providers: [
    UserProfileService
  ]
})
export class AddinsSharedModule {}
