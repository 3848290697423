import { Injectable } from '@angular/core';
import type { ContactEntity, CreateContactAccountRelationInput, CreateLeadOpptyAccountRelationInput, CreateLeadOpptyContactRelationInput, LeadEntity, OpportunityEntity, Query } from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { hasSpaceFeaturePermission, SpaceService } from '@ppl/space';
import type { DocumentNode } from 'graphql';
import type { Observable} from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreStore } from '../../../../store/core.store';
import { gqlCreateContactAccountRelationMutation, gqlCreateLeadOpptyAccountRelationMutation, gqlCreateLeadOpptyContactRelationMutation, gqlFetchContactName, gqlFetchLeadName, gqlFetchOpportunityName } from './entity.graphql';

@Injectable()
export class EntityService {

  constructor(
    private spaceService: SpaceService,
    private store: CoreStore
  ) {}

  fetchEntity<T>(options: FetchEntityOptions): Observable<T | null> {
    if (!options.id) {
      return of(null);
    }

    return this.spaceService.gqlClient.query<Query>({
      query: options.query,
      fetchPolicy: 'no-cache',
      variables: {
        id: options.id
      }
    }).pipe(
      map(({ data }) => {
        return data.entities[options.entityType.toLowerCase()].getById;
      })
    );
  }

  fetchContactName(id: string | null) {
    return this.fetchEntity<ContactEntity>({
      entityType: EntityNameEnum.Contact,
      id,
      query: gqlFetchContactName
    });
  }

  fetchLeadName(id: string | null) {
    return this.fetchEntity<LeadEntity>({
      entityType: EntityNameEnum.Lead,
      id,
      query: gqlFetchLeadName
    });
  }

  fetchOpportunityName(id: string | null) {
    return this.fetchEntity<OpportunityEntity>({
      entityType: EntityNameEnum.Opportunity,
      id,
      query: gqlFetchOpportunityName
    });
  }

  createContactAccountRelation(options: CreateContactAccountRelationOptions) {
    const input: CreateContactAccountRelationInput = {
      accountId: options.accountId,
      contactId: options.contactId,
      isPrimary: false
    };

    return this.spaceService.gqlClient.mutate({
      mutation: gqlCreateContactAccountRelationMutation,
      variables: {
        input
      }
    });
  }

  createLeadOpptyAccountRelation(options: CreateLeadOpptyAccountRelationOptions) {
    const input: CreateLeadOpptyAccountRelationInput = {
      accountId: options.accountId,
      isPrimary: false,
      leadOpptyId: options.leadOpptyId
    };

    return this.spaceService.gqlClient.mutate({
      mutation: gqlCreateLeadOpptyAccountRelationMutation,
      variables: {
        input
      }
    });
  }

  createLeadOpptyContactRelation(options: CreateLeadOpptyContactRelationOptions) {
    const input: CreateLeadOpptyContactRelationInput = {
      contactId: options.contactId,
      isPrimary: false,
      leadOpptyId: options.leadOpptyId
    };

    return this.spaceService.gqlClient.mutate({
      mutation: gqlCreateLeadOpptyContactRelationMutation,
      variables: {
        input
      }
    });
  }

  filterEntityTypes(entityTypes: EntityNameEnum[]) {
    return entityTypes.filter(entityType => {
      return !(entityType === EntityNameEnum.Account && !this.store.get(hasSpaceFeaturePermission('accounts')))
        && !(entityType === EntityNameEnum.Contact && !this.store.get(hasSpaceFeaturePermission('contacts')))
        && !(entityType === EntityNameEnum.Lead && !this.store.get(hasSpaceFeaturePermission('leads')))
        && !(entityType === EntityNameEnum.Opportunity && !this.store.get(hasSpaceFeaturePermission('opportunities')));
    });
  }

}

export interface FetchEntityOptions {
  entityType: EntityNameEnum;
  id: string | null;
  query: () => DocumentNode;
}

export interface CreateContactAccountRelationOptions {
  accountId: string;
  contactId: string;
}

export interface CreateLeadOpptyAccountRelationOptions {
  accountId: string;
  leadOpptyId: string;
}

export interface CreateLeadOpptyContactRelationOptions {
  contactId: string;
  leadOpptyId: string;
}
