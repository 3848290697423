import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ppl-card-fields',
  templateUrl: './card-fields.component.html',
  styleUrls: ['./card-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardFieldsComponent {

}
