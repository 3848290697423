import type { AfterViewInit, OnDestroy } from '@angular/core';
import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import type { PostAnimationFrameHandle} from '@ppl/utils';
import { requestPostAnimationFrame } from '@ppl/utils';
import type { ToastContent, ToastKind } from '../../domain/toast';

@Component({
  selector: 'ppl-toast-screen',
  templateUrl: './toast-screen.component.html',
  styleUrls: ['./toast-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastScreenComponent implements OnDestroy, AfterViewInit {

  @HostBinding('class.visible') visible = false;

  renderHandle: PostAnimationFrameHandle;

  constructor(
    @Inject(ToastScreenData) public data: ToastScreenData
  ) {}

  ngAfterViewInit() {
    this.renderHandle = requestPostAnimationFrame(() => {
      this.visible = true;
    });
  }

  ngOnDestroy() {
    if (this.renderHandle) {
      this.renderHandle.clear();
    }
  }

}

export class ToastScreenData {
  content?: ToastContent;
  kind: ToastKind;
  text?: string;
}
