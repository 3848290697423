import { ChangeDetectionStrategy, Component, HostBinding, Injector, Input } from '@angular/core';
import { MemoizeLast } from '@ppl/utils';
import { ToastContent, ToastKind, TOAST_CONTENT_DATA } from '../../domain/toast';

@Component({
  selector: 'ppl-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {

  @Input() content?: ToastContent;
  @Input() kind?: ToastKind;
  @Input() text?: string;

  ToastKind = ToastKind;

  @HostBinding('class.success')
  get successKind() {
    return this.kind === ToastKind.Success;
  }

  @HostBinding('class.error')
  get errorKind() {
    return this.kind === ToastKind.Error;
  }

  @MemoizeLast<ToastComponent>(['content'])
  get contentDataInjector() {
    return Injector.create([{
      provide: TOAST_CONTENT_DATA,
      useValue: this.content.data
    }], this.injector);
  }

  constructor(
    private injector: Injector
  ) {}

}
