import type { RendererType2 } from '@angular/core';
import { NgModule } from '@angular/core';
import { BrowserModule, ɵDomRendererFactory2 } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AddinsSharedModule } from '@ppl/addins-shared';
import { OfficeTokenService } from '@ppl/auth';
import { FirebaseModule, FirebaseService, FIREBASE_CONFIG } from '@ppl/firebase';
import { I18nService } from '@ppl/i18n';
import { initSentry } from '@ppl/sentry';
import { SpaceModule } from '@ppl/space';
import { PplIconModule, PplIconService } from '@ppl/ui/icon';
import { PplUiIntl } from '@ppl/ui/intl';
import { PIPELINER_NG_UI_THEME } from '@ppl/ui/tokens';
import { DEPLOY_URL } from '@ppl/utils';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BootstrapComponent } from './components/bootstrap/bootstrap.component';
import { AccountModule } from './modules/account/account.module';
import { AddRecipientModule } from './modules/add-recipient/add-recipient.module';
import { AppointmentModule } from './modules/appointment/appointment.module';
import { AttachDocumentModule } from './modules/attach-document/attach-document.module';
import { ContactModule } from './modules/contact/contact.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { LeadModule } from './modules/lead/lead.module';
import { OpportunityModule } from './modules/opportunity/opportunity.module';
import { SaveEmailModule } from './modules/save-email/save-email.module';
import { OutlookModule } from './modules/shared/outlook/outlook.module';
import { TrackingService } from './modules/shared/tracking/services/tracking.service';
import { TrackingModule } from './modules/shared/tracking/tracking.module';
import { TaskModule } from './modules/task/task.module';
import { UseTemplateModule } from './modules/use-template/use-template.module';
import { SpaceGuardService } from './router/space-guard.service';
import { CacheService } from './services/cache.service';
import { HostService } from './services/host.service';
import { LocationService } from './services/location.service';
import { RecentRecordsService } from './services/recent-records.service';
import { coreReducer } from './store/core.reducer';
import { CoreStore } from './store/core.store';

initSentry({
  dsn: 'https://6c1aa6f22b4d47128570c62553ea7e80@o183298.ingest.sentry.io/5286624',
  enabled: environment.sentryEnabled,
  environment: environment.name,
  release: environment.version
});

@NgModule({
  imports: [
    AccountModule,
    AddRecipientModule,
    AppRoutingModule,
    AddinsSharedModule,
    AppointmentModule,
    AttachDocumentModule,
    BrowserModule,
    ContactModule,
    DashboardModule,
    {
      ngModule: FirebaseModule,
      providers: [FirebaseService, { provide: FIREBASE_CONFIG, useValue: environment.firebase }]
    },
    LeadModule,
    OpportunityModule,
    OutlookModule,
    PplIconModule,
    RouterModule,
    SaveEmailModule,
    SpaceModule.withOptions({
      apiUserGqlUrl: environment.apiUserGqlUrl,
      appId: '00000000-0000-0000-0000-000000000019',
      appUrl: environment.appUrl,
      sentryEnabled: environment.sentryEnabled,
      ssoUrl: environment.ssoUrl,
      store: CoreStore,
      storeReducerFactory: coreReducerFactory,
      tokenService: OfficeTokenService
    }),
    TaskModule,
    TrackingModule,
    UseTemplateModule
  ],
  declarations: [
    AppComponent,
    BootstrapComponent
  ],
  providers: [
    { provide: PIPELINER_NG_UI_THEME, useValue: 'fabric' },
    { provide: PplUiIntl, deps: [I18nService], useFactory: pplUiIntlFactory },
    { provide: DEPLOY_URL, useValue: environment.deployUrl },
    CacheService,
    HostService,
    LocationService,
    PplIconService,
    RecentRecordsService,
    SpaceGuardService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {

  constructor(
    private domRendererFactory: ɵDomRendererFactory2,
    private hostService: HostService,
    private trackingService: TrackingService
  ) {
    this.hostService.initSentry();

    // This function is called from Ribbon button "Track email" (defined in manifest)
    (window as any).toggleTracking = event => {
      this.trackingService.toggleTracking().subscribe(() => {
        event.completed();
      }, () => {
        event.completed();
      });
    };

    // Patch IE and Safari only
    if (['Chrome', 'Firefox', 'Edge'].every(token => !navigator.userAgent.includes(token))) {
      patchRendererSetProperty(this.domRendererFactory);
    }
  }

}

export function coreReducerFactory() {
  return coreReducer;
}

export function pplUiIntlFactory(i18nService: I18nService) {
  const ngUiIntl = new PplUiIntl();

  Object.entries(ngUiIntl).forEach(([key, text]) => ngUiIntl[key] = i18nService.translate(text));

  return ngUiIntl;
}

// Fix for https://github.com/angular/angular/issues/21782
// Using code from https://github.com/angular/angular/blob/b5ab7aff433a67cddaa55e621d17b1a1b07b57c2/packages/core/test/linker/change_detection_integration_spec.ts
// and https://github.com/facebook/react/blob/fc43644/packages/react-dom/src/client/ReactInputSelection.js
// and https://github.com/sveltejs/svelte/pull/4164/files
function patchRendererSetProperty(rendererFactory: ɵDomRendererFactory2) {
  if ((<any>rendererFactory).__patchedSetProperty) {
    return;
  }

  (<any>rendererFactory).__patchedSetProperty = true;
  const origCreateRenderer = rendererFactory.createRenderer;

  rendererFactory.createRenderer = function(element: any, type: RendererType2 | null) {
    const renderer = origCreateRenderer.call(this, element, type);

    if ((<any>renderer).__patchedSetProperty) {
      return renderer;
    }

    (<any>renderer).__patchedSetProperty = true;

    const origSetProperty = renderer.setProperty;

    renderer.setProperty = function(el: any, name: string, value: any): void {
      const textInputFix = el && el.nodeName.toLowerCase() === 'input' && el.type === 'text' && name === 'value' && document.activeElement === el;

      let selectionStart = 0;
      let selectionEnd = 0;

      if (textInputFix) {
        selectionStart = el.selectionStart;
        selectionEnd = el.selectionEnd;
      }

      origSetProperty.call(renderer, el, name, value);

      if (textInputFix) {
        if (selectionEnd === undefined) {
          selectionEnd = selectionStart;
        }

        el.selectionStart = selectionStart;
        el.selectionEnd = Math.min(selectionEnd, el.value.length);
      }
    };

    return renderer;
  };
}
