import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EntityNameEnum, EntityPermsEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import type { PplMenuItem } from '@ppl/ui/menu-items';
import { hasSpaceEntityPermission } from '@ppl/space';
import { LocationService } from '../../../../services/location.service';
import { CoreStore } from '../../../../store/core.store';
import { AddRecipientKind } from '../../../add-recipient/domain/add-recipient';
import { EmailKind } from '../../../shared/email/domain/email';
import { OutlookItemMode, OutlookItemType } from '../../../shared/outlook/domain/outlook';
import { OutlookService } from '../../../shared/outlook/services/outlook.service';

@Component({
  selector: 'ppl-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardMenuComponent {

  displayCreateLead = this.store.get(hasSpaceEntityPermission(EntityNameEnum.Lead, EntityPermsEnum.Create));
  displayCreateOpportunity = this.store.get(hasSpaceEntityPermission(EntityNameEnum.Opportunity, EntityPermsEnum.Create));
  displayCreateAccount = this.store.get(hasSpaceEntityPermission(EntityNameEnum.Account, EntityPermsEnum.Create));
  displayCreateContact = this.store.get(hasSpaceEntityPermission(EntityNameEnum.Contact, EntityPermsEnum.Create));
  displayCreateAppointment = this.store.get(hasSpaceEntityPermission(EntityNameEnum.Appointment, EntityPermsEnum.Create));
  displayCreateTask = this.store.get(hasSpaceEntityPermission(EntityNameEnum.Task, EntityPermsEnum.Create));

  createActivityMenuItems: PplMenuItem[] = [{
    disabled: !this.displayCreateTask,
    icon: 'menu-task',
    id: EntityNameEnum.Task,
    label: this.i18nService.translate('Create_new_task')
  }, {
    disabled: !this.displayCreateAppointment,
    icon: 'menu-appointment',
    id: EntityNameEnum.Appointment,
    label: this.i18nService.translate('Create_new_appointment')
  }];

  addRecipientMenuItems: PplMenuItem[] = [{
    icon: 'menu-contact',
    id: AddRecipientKind.Contact,
    label: this.i18nService.translate('Add_contacts')
  }, {
    icon: 'menu-sales-unit',
    id: AddRecipientKind.SalesTeam,
    label: this.i18nService.translate('Add_sales_team')
  }];

  itemMode = this.outlookService.getItemMode();
  itemType = this.outlookService.getItemType();
  displaySaveAttachments = this.outlookService.hasAttachments();

  EmailKind = EmailKind;
  OutlookItemMode = OutlookItemMode;
  OutlookItemType = OutlookItemType;

  constructor(
    private i18nService: I18nService,
    private locationService: LocationService,
    private outlookService: OutlookService,
    private store: CoreStore
  ) {}

  onSaveEmailClick(kind: EmailKind) {
    this.locationService.saveEmail({
      kind
    });
  }

  onCreateLeadClick() {
    this.locationService.createLead();
  }

  onCreateOpportunityClick() {
    this.locationService.createOpportunity();
  }

  onCreateAccountClick() {
    this.locationService.createAccount();
  }

  onCreateContactClick() {
    this.locationService.createContact();
  }

  onCreateActivityMenuItemClick(entityType: EntityNameEnum) {
    switch (entityType) {
      case EntityNameEnum.Appointment:
        this.locationService.createAppointment();
        break;
      case EntityNameEnum.Task:
        this.locationService.createTask();
        break;
    }
  }

  onAddRecipientMenuItemClick(kind: AddRecipientKind) {
    this.locationService.addRecipient({
      kind
    });
  }

  onAttachDocumentClick() {
    this.locationService.attachDocument();
  }

  onUseTemplateClick() {
    this.locationService.useTemplate();
  }

}
