import { ChangeDetectionStrategy, Component } from '@angular/core';
import { I18nService } from '@ppl/i18n';
import type { PplMenuItem } from '@ppl/ui/menu-items';
import { LocationService } from '../../../../services/location.service';
import { AddRecipientKind } from '../../../add-recipient/domain/add-recipient';

@Component({
  selector: 'ppl-dashboard-add-card',
  templateUrl: './dashboard-add-card.component.html',
  styleUrls: ['./dashboard-add-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardAddCardComponent {

  addRecipientMenuItems: PplMenuItem[] = [{
    icon: 'menu-contact',
    id: AddRecipientKind.Contact,
    label: this.i18nService.translate('Add_contacts')
  }, {
    icon: 'menu-sales-unit',
    id: AddRecipientKind.SalesTeam,
    label: this.i18nService.translate('Add_sales_team')
  }];

  constructor(
    private i18nService: I18nService,
    private locationService: LocationService
  ) {}

  onAddRecipientMenuItemClick(kind: AddRecipientKind) {
    this.locationService.addRecipient({
      kind
    });
  }

}
