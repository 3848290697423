<ppl-autocomplete-picker [options]="fetchEntitiesSession.options$ | async"
                         [categories]="entitiesCategories"
                         [displayOptionsLoading]="fetchEntitiesSession.loading"
                         [autoFocusOnInit]="true"
                         placeholder="Search in Pipeliner"
                         i18n-placeholder="@@Search_in_Pipeliner"
                         [optionTemplate]="entityOptionTemplate"
                         [optionTemplateRowHeight]="50"
                         [maxContainerHeight]="320"
                         (optionsRequest)="onOptionsRequest($event)"
                         (optionSelect)="onOptionSelect($event)"
                         (listClose)="listClose.emit()"></ppl-autocomplete-picker>

<ng-template #entityOptionTemplate
             let-option>
  <ng-container [ngSwitch]="option.data.entity.__typename">
    <ppl-entity-control-item *ngSwitchCase="'AccountEntity'"
                             [avatarKind]="AvatarKind.Medium"
                             [entityType]="EntityNameEnum.Account"
                             [height]="50"
                             [name]="option.data.entity.name"
                             [description]="option.data.entity | pplLocation"
                             [pictureUrl]="option.data.entity.picture?.url"></ppl-entity-control-item>

    <ppl-entity-control-item *ngSwitchCase="'AppointmentEntity'"
                             [avatarKind]="AvatarKind.Medium"
                             [entityType]="EntityNameEnum.Appointment"
                             [height]="50"
                             [name]="option.data.entity.subject"
                             [description]="option.data.entity.owner.name"
                             descriptionIcon="entity-phantom-contact-16"></ppl-entity-control-item>

    <ppl-entity-control-item *ngSwitchCase="'ContactEntity'"
                             [avatarKind]="AvatarKind.Medium"
                             [entityType]="EntityNameEnum.Contact"
                             [height]="50"
                             [name]="option.data.entity | pplName"
                             [description]="option.data.entity.email1"
                             [pictureUrl]="option.data.entity.picture?.url"></ppl-entity-control-item>

    <ppl-entity-control-item *ngSwitchCase="'LeadEntity'"
                             [avatarKind]="AvatarKind.Medium"
                             [entityType]="EntityNameEnum.Lead"
                             [height]="50"
                             [name]="option.data.entity.name"
                             [description]="option.data.entity.owner?.name"
                             descriptionIcon="entity-phantom-contact-16"></ppl-entity-control-item>

    <ppl-entity-control-item *ngSwitchCase="'OpportunityEntity'"
                             [avatarKind]="AvatarKind.Medium"
                             [entityType]="EntityNameEnum.Opportunity"
                             [height]="50"
                             [name]="option.data.entity.name"
                             [description]="option.data.entity.owner.name"
                             descriptionIcon="entity-phantom-contact-16"></ppl-entity-control-item>

    <ppl-entity-control-item *ngSwitchCase="'TaskEntity'"
                             [avatarKind]="AvatarKind.Medium"
                             [entityType]="EntityNameEnum.Task"
                             [height]="50"
                             [name]="option.data.entity.subject"
                             [description]="option.data.entity.owner?.name"
                             descriptionIcon="entity-phantom-contact-16"></ppl-entity-control-item>
  </ng-container>
</ng-template>