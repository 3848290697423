import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { PPL_DIALOG_DATA } from '@ppl/ui/dialog';

@Component({
  selector: 'ppl-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutComponent {

  @Input() version: string;

  constructor(
    @Inject(PPL_DIALOG_DATA) public data: AboutData
  ) {}

  userAgent = navigator.userAgent;
  diagnostics = (window as any).Office ? Office?.context?.diagnostics : undefined;

}

declare const Office: any;

export interface AboutData {
  version: string;
}
