import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OutlookItemDirection } from '../../../shared/outlook/domain/outlook';
import { DashboardItem, DashboardItemKind } from '../../domain/dashboard';

@Component({
  selector: 'ppl-dashboard-sender',
  templateUrl: './dashboard-sender.component.html',
  styleUrls: ['./dashboard-sender.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardSenderComponent {

  @Input() item: DashboardItem;

  DashboardItemKind = DashboardItemKind;
  OutlookItemDirection = OutlookItemDirection;

}
