import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fetchAsyncValue } from '@ppl/core';
import type { AsyncValue } from '@ppl/store';
import { AsyncState } from '@ppl/store';
import type { Observable} from 'rxjs';
import { merge, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import type { AccountData } from '../../domain/account';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'ppl-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountComponent implements OnInit {

  data$: Observable<AsyncValue<AccountData>>;

  updateData$ = new Subject<AccountData>();

  AsyncState = AsyncState;

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.data$ = this.route.params.pipe(
      switchMap(params => {
        return fetchAsyncValue(merge(
          this.accountService.fetchData(params.id),
          this.updateData$.asObservable()
        ));
      })
    );
  }

  onFieldChange(data: AccountData, value: { email1?: string, homePage?: string, phone1?: string }) {
    this.updateData$.next({
      ...data,
      entity: {
        ...data.entity,
        ...value
      }
    });

    this.accountService.updateField(data.entity.id, value).subscribe({
      error: () => {
        this.updateData$.next(data);
      }
    });
  }

}
