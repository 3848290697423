import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EntityNameEnum, LeadEntity, LeadStatusEnum } from '@ppl/graphql-space-api';
import { AvatarKind } from '../../../avatar/domain/avatar';

@Component({
  selector: 'ppl-lead-card-medium',
  templateUrl: './lead-card-medium.component.html',
  styleUrls: ['./lead-card-medium.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeadCardMediumComponent {

  @Input() entity: LeadEntity;

  AvatarKind = AvatarKind;
  EntityNameEnum = EntityNameEnum;
  LeadStatusEnum = LeadStatusEnum;

}
