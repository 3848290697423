import moment from 'moment';

import type { Subscription } from '@ppl/graphql-user-api';

export function isExpired(subscription: Subscription) {
  return moment(subscription.expiredDate).isBefore(new Date(), 'day');
}

export function isPaymentOverdue(subscription: Subscription) {
  return moment(subscription.mostDuePaymentDate).isBefore(new Date(), 'day');
}

export function getSubscriptionBaseUrl(subscription: Subscription) {
  return `/${subscription.id}`;
}

export function getSubscriptionUrl(subscription: Subscription, path = '') {
  const separator = (path[0] && path[0] !== '/') ? '/' : '';
  return getSubscriptionBaseUrl(subscription) + separator + path;
}

export function isSubscriptionAdmin(subscription: Subscription, userEmail: string) {
  return !!subscription.admins.find(admin => admin.email === userEmail);
}
