import { AccountCardFieldTypeAdapter } from './account-card.adapter';
import { AccountClassFieldTypeAdapter } from './account-class.adapter';
import { AccountNameFieldTypeAdapter } from './account-name.adapter';
import { CheckboxFieldTypeAdapter } from './checkbox.adapter';
import { ClosingDateFieldTypeAdapter } from './closing-date.adapter';
import { ContactAccountCardFieldTypeAdapter } from './contact-account-card.adapter';
import { ContactCardFieldTypeAdapter } from './contact-card.adapter';
import { CurrencyForeignFieldTypeAdapter } from './currency-foreign.adapter';
import { CurrencyFieldTypeAdapter } from './currency.adapter';
import { DateFieldTypeAdapter } from './date.adapter';
import { DatetimeFieldTypeAdapter } from './datetime.adapter';
import { DropdownFieldTypeAdapter } from './dropdown.adapter';
import { DueDateFieldTypeAdapter } from './due-date.adapter';
import { EmailFieldTypeAdapter } from './email.adapter';
import { EntityNameFieldTypeAdapter } from './entity-name.adapter';
import { FloatFieldTypeAdapter } from './float.adapter';
import { InputFieldTypeAdapter } from './input.adapter';
import { IntegerFieldTypeAdapter } from './integer.adapter';
import { LinkedItemsFieldTypeAdapter } from './linked-items.adapter';
import { LocationFieldTypeAdapter } from './location.adapter';
import { MultiselectCheckboxFieldTypeAdapter } from './multiselect-checkbox.adapter';
import { OwnerOrUnassignedFieldTypeAdapter } from './owner-or-unassigned.adapter';
import { OwnerFieldTypeAdapter } from './owner.adapter';
import { PhoneFieldTypeAdapter } from './phone.adapter';
import { PriorityFieldTypeAdapter } from './priority.adapter';
import { RadioFieldTypeAdapter } from './radio.adapter';
import { RankingFieldTypeAdapter } from './ranking.adapter';
import { SalesUnitFieldTypeAdapter } from './sales-unit.adapter';
import { TaskStatusFieldTypeAdapter } from './task-status.adapter';
import { TextAreaFieldTypeAdapter } from './text-area.adapter';
import { UrlFieldTypeAdapter } from './url.adapter';

export const FieldTypeAdapters = [
  AccountCardFieldTypeAdapter,
  AccountClassFieldTypeAdapter,
  AccountNameFieldTypeAdapter,
  CheckboxFieldTypeAdapter,
  ClosingDateFieldTypeAdapter,
  ContactAccountCardFieldTypeAdapter,
  ContactCardFieldTypeAdapter,
  CurrencyFieldTypeAdapter,
  CurrencyForeignFieldTypeAdapter,
  DateFieldTypeAdapter,
  DatetimeFieldTypeAdapter,
  DropdownFieldTypeAdapter,
  DueDateFieldTypeAdapter,
  EmailFieldTypeAdapter,
  EntityNameFieldTypeAdapter,
  FloatFieldTypeAdapter,
  InputFieldTypeAdapter,
  IntegerFieldTypeAdapter,
  LinkedItemsFieldTypeAdapter,
  LocationFieldTypeAdapter,
  MultiselectCheckboxFieldTypeAdapter,
  OwnerFieldTypeAdapter,
  OwnerOrUnassignedFieldTypeAdapter,
  PhoneFieldTypeAdapter,
  PriorityFieldTypeAdapter,
  RadioFieldTypeAdapter,
  RankingFieldTypeAdapter,
  SalesUnitFieldTypeAdapter,
  TaskStatusFieldTypeAdapter,
  TextAreaFieldTypeAdapter,
  UrlFieldTypeAdapter
];
