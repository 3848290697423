export function isURL(url: string) {
  return url.startsWith('http');
}

export function formatURL(text: string) {
  if (isURL(text)) {
    return text;
  } else {
    return `http://${text}`;
  }
}
