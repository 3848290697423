import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserProfileService } from '@ppl/addins-shared';
import { getLoggedInUserProfile } from '@ppl/auth';
import type { Space, UserProfileEntity } from '@ppl/graphql-user-api';
import { I18nService } from '@ppl/i18n';
import { getSpace } from '@ppl/space';
import type { AsyncValue } from '@ppl/store';
import { AsyncState } from '@ppl/store';
import { MemoizeLast, Unsubscribe } from '@ppl/utils';
import type { Observable, Subscription } from 'rxjs';
import { VERSION } from '../../../../../environments/config';
import { HostService } from '../../../../services/host.service';
import { LocationService } from '../../../../services/location.service';
import { CoreStore } from '../../../../store/core.store';
import { OutlookItemMode, OutlookItemType } from '../../../shared/outlook/domain/outlook';
import { OutlookService } from '../../../shared/outlook/services/outlook.service';
import type { DashboardData } from '../../domain/dashboard';
import { DashboardService } from '../../services/dashboard.service';
import { getDashboardData } from '../../store/dashboard.selectors';

@Component({
  selector: 'ppl-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Unsubscribe()
export class DashboardComponent implements OnInit {

  data$: Observable<AsyncValue<DashboardData>>;

  itemMode = this.outlookService.getItemMode();
  itemType = this.outlookService.getItemType();
  hasAttachmentsWithoutId = this.outlookService.hasAttachmentsWithoutId();

  menuVisible = false;

  space: Space;
  userProfile: UserProfileEntity;
  version = VERSION;

  itemChangeSubscription: Subscription;
  recipientsChangeSubscription: Subscription;
  userProfileSubscription: Subscription;

  AsyncState = AsyncState;
  OutlookItemMode = OutlookItemMode;

  @MemoizeLast<DashboardComponent>(['itemType'])
  get senderGroupName() {
    return ({
      [OutlookItemType.Message]: this.i18nService.translate('From'),
      [OutlookItemType.Appointment]: this.i18nService.translate('Organizer')
    })[this.itemType];
  }

  @MemoizeLast<DashboardComponent>(['itemType'])
  get recipientsGroupName() {
    return ({
      [OutlookItemType.Message]: this.i18nService.translate('To'),
      [OutlookItemType.Appointment]: this.i18nService.translate('Attendees')
    })[this.itemType];
  }

  constructor(
    private dashboardService: DashboardService,
    private hostService: HostService,
    private i18nService: I18nService,
    private locationService: LocationService,
    private outlookService: OutlookService,
    private store: CoreStore,
    private userProfileService: UserProfileService
  ) {
    this.space = this.store.get(getSpace);
    this.userProfile = this.store.get(getLoggedInUserProfile);

    this.userProfileSubscription = this.userProfileService.authSuccess$.subscribe(() => {
      location.reload();
    });
  }

  ngOnInit() {
    this.data$ = this.store.select(getDashboardData);

    this.itemChangeSubscription = this.hostService.itemChange.subscribe(() => {
      this.itemMode = this.outlookService.getItemMode();
      this.itemType = this.outlookService.getItemType();
      this.hasAttachmentsWithoutId = this.outlookService.hasAttachmentsWithoutId();

      this.dashboardService.fetchData();
    });

    this.recipientsChangeSubscription = this.hostService.recipientsChange.subscribe(() => {
      this.dashboardService.fetchData();
    });

    this.dashboardService.fetchData();
  }

  onTemplatesClick() {
    this.locationService.useTemplate();
  }

}
