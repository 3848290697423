import type { AfterViewInit, OnChanges, SimpleChanges} from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, HostListener, Inject, Input, Output, ViewChild , ChangeDetectorRef, ElementRef, Renderer2} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl, KEYCODE_DOWN, KEYCODE_UP, MemoizeLast } from '@ppl/utils';
import normalizeWheel from 'normalize-wheel';
import Decimal from 'decimal.js-light';
import { NG_UI_THEMES, PIPELINER_NG_UI_THEME } from '../../tokens';

@Component({
  selector: 'ppl-input-number-clamp',
  templateUrl: './input-number-clamp.component.html',
  styleUrls: ['./input-number-clamp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplInputNumberClampComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplInputNumberClampComponent implements OnChanges, AfterViewInit {

  @Input() controls = false;
  @Input() disabled = false;
  @Input() emitNull = false;
  @Input() minValue?: number;
  @Input() maxValue?: number;
  @Input() padValue?: { maxLength: number, fillString: string };
  @Input() placeholder = '';
  @Input() step = 1;
  @Input() value: number | null;
  @Input() wheelDisabled = false;

  @Output() valueChange = new EventEmitter<number | null>();

  @ViewChild('input', { static: false }) input: ElementRef;

  focused = false;
  editedValue = '';

  @MemoizeLast<PplInputNumberClampComponent>(['step'])
  get stepObject() {
    return new Decimal(this.step);
  }

  @MemoizeLast<PplInputNumberClampComponent>(['stepObject'])
  get displayPlaces() {
    return this.stepObject.decimalPlaces();
  }

  @MemoizeLast<PplInputNumberClampComponent>(['displayPlaces', 'minValue'])
  get inputTest() {
    if (this.minValue === undefined || this.minValue < 0) {
      return (this.displayPlaces > 0) ? DecimalNumber : IntegerNumber;
    } else {
      return (this.displayPlaces > 0) ? PositiveDecimalNumber : PositiveIntegerNumber;
    }
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    @Inject(PIPELINER_NG_UI_THEME) public ngUiTheme: NG_UI_THEMES
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value && !changes.value.firstChange && !this.focused) {
      this.renderer.setProperty(this.input.nativeElement, 'value', this.toDisplayValue(this.value));
    }
  }

  ngAfterViewInit() {
    this.renderer.setProperty(this.input.nativeElement, 'value', this.toDisplayValue(this.value));
  }

  toDisplayValue(value: number | null) {
    if (value === null) {
      return '';
    }

    let displayValue = (new Decimal(value)).toDecimalPlaces(this.displayPlaces).toString();

    if (this.padValue) {
      displayValue = displayValue.padStart(this.padValue.maxLength, this.padValue.fillString);
    }

    return displayValue;
  }

  setValue(value: Decimal) {
    if (this.minValue !== undefined && value.comparedTo(this.minValue) === -1) {
      value = new Decimal(this.minValue);
    } else if (this.maxValue !== undefined && value.comparedTo(this.maxValue) === 1) {
      value = new Decimal(this.maxValue);
    }

    const nextValue = value.toDecimalPlaces(this.displayPlaces).toNumber();

    if (nextValue !== this.value) {
      this.valueChange.emit(nextValue);
    }

    return nextValue;
  }

  setValueRelative(dtStep: number) {
    if (this.disabled) {
      return;
    }

    if (this.value !== null) {
      const value = new Decimal(this.value);
      let nextValue = this.value;

      if (dtStep === 1) {
        nextValue = this.setValue(value.add(new Decimal(Math.max(this.step, 1))));
      } else if (dtStep === -1) {
        nextValue = this.setValue(value.sub(new Decimal(Math.max(this.step, 1))));
      }

      // Force update display value, because focused state blocks input updates
      this.setEditedValue(this.toDisplayValue(nextValue));
    }
  }

  setEditedValue(value: string) {
    this.editedValue = value;
    this.renderer.setProperty(this.input.nativeElement, 'value', this.editedValue);
  }

  onFocus() {
    // Start value editing with current input value
    this.focused = true;
    this.setEditedValue(this.toDisplayValue(this.value));
  }

  onBlur() {
    // Display last valid value on blur
    this.focused = false;
    this.setEditedValue(this.toDisplayValue(this.value));
  }

  onChange(event: Event) {
    const eventTarget = event.target as HTMLInputElement;

    if (eventTarget.value === '' && this.emitNull) {
      this.setEditedValue('');
      this.valueChange.emit(null);
    } else if (this.inputTest.test(eventTarget.value)) {
      this.setEditedValue(eventTarget.value);

      const parsedValue = Number.parseFloat(eventTarget.value);

      if (!Number.isNaN(parsedValue)) {
        this.setValue(new Decimal(parsedValue));
      }
    } else {
      this.renderer.setProperty(this.input.nativeElement, 'value', this.editedValue);
    }
  }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    if (this.controls && !this.wheelDisabled) {
      const wheelData = normalizeWheel(event);

      this.setValueRelative(Math.sign(-wheelData.pixelY));
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === KEYCODE_UP) {
      this.setValueRelative(1);
    } else if (event.keyCode === KEYCODE_DOWN) {
      this.setValueRelative(-1);
    }
  }

}

const IntegerNumber = /^-?\d*$/;
const PositiveIntegerNumber = /^\d*$/;
const DecimalNumber = /^-?\d*\.?\d*$/;
const PositiveDecimalNumber = /^\d*\.?\d*$/;
