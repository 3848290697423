import { gql } from '@ppl/utils';

export const gqlFetchCloudObjectRelations = gql`
  query fetchCloudObjectRelations($filter: CloudObjectRelationFilterInput) {
    entities {
      cloudObjectRelation {
        getAll(filter: $filter) {
          id
          cloudObject {
            filename
            id
            modified
            url
          }
        }
      }
    }
  }
`;
