import { Injectable } from '@angular/core';
import { getUserSettings } from '@ppl/auth';
import { AppointmentFieldsEnum } from '@ppl/domain';
import type { EntityNameEnum, FieldDescriptor, FieldValueOption, Query} from '@ppl/graphql-space-api';
import { AccessLevelFieldEnum, FieldSystemType, PrimitiveType, ValidationLevel } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import type { FetchLazyConfigOptions} from '@ppl/space';
import { getSpaceBaseCurrency, getSpaceCurrencies, getSpaceFieldDescriptors, getSpaceForms, getSpaceSalesUnits, getSpaceUser, hasSpaceFeaturePermission, SpaceService } from '@ppl/space';
import { findById } from '@ppl/utils';
import type { DocumentNode } from 'graphql';
import type { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CacheService } from '../../../../services/cache.service';
import { CoreStore } from '../../../../store/core.store';
import type { EntityFormControl } from '../../entity-form/domain/controls';
import type { EntityFormValidator} from '../../entity-form/domain/validators';
import { EntityFormValidatorKind } from '../../entity-form/domain/validators';
import type { EntityFormApiContext } from '../domain/entity-form-api';
import { getFieldDefaultValue } from '../domain/field-default-value';
import { FieldTypeAdapters } from '../domain/field-type';
import { getFormFields } from '../domain/form-field';
import { FieldDescriptorFields, gqlFetchValueOptions } from './entity-form-api.graphql';

@Injectable()
export class EntityFormApiService {

  constructor(
    private cacheService: CacheService,
    private i18nService: I18nService,
    private spaceService: SpaceService,
    private store: CoreStore
  ) {}

  fetchContext(entityType: EntityNameEnum, fetchConfigOptions: FetchLazyConfigOptions = {}): Observable<EntityFormApiContext> {
    return this.spaceService.fetchConfig({
      entities: fetchConfigOptions.entities,
      fieldDescriptors: {
        entityTypes: [entityType],
        fieldFragment: FieldDescriptorFields
      },
      forms: {
        entityTypes: [entityType]
      },
      user: true
    }).pipe(
      map(() => {
        const result: EntityFormApiContext = {
          entityType,
          fieldDescriptors: this.store.get(getSpaceFieldDescriptors(entityType)),
          forms: this.store.get(getSpaceForms(entityType)),
          userClient: this.store.get(getSpaceUser)
        };

        return result;
      })
    );
  }

  getControl(options: GetControlOptions): EntityFormControl | null {
    const fieldDescriptor = findById(options.context.fieldDescriptors, options.fieldId);

    const form = findById(options.context.forms, options.formId);
    const formField = findById(getFormFields(form.formEditApi), options.fieldId) || null;

    if ((!formField || !formField.inAddin) && !options.forceOnForm) {
      return null;
    }

    const fieldTypeAdapter = findById(FieldTypeAdapters, fieldDescriptor.typeId);

    if (!fieldTypeAdapter) {
      throw new Error(`FieldTypeAdapter: ${fieldDescriptor.typeId}`);
    }

    // Control
    const control = fieldTypeAdapter.getControl({
      entities: {
        currencies: this.store.get(getSpaceCurrencies),
        salesUnits: this.store.get(getSpaceSalesUnits)
      },
      entityType: options.context.entityType,
      featurePermissions: {
        activitiesMultiLink: this.store.get(hasSpaceFeaturePermission('activitiesMultiLink'))
      },
      fetchValueOptions: this.fetchValueOptions.bind(this),
      fieldDescriptor,
      formField,
      i18nService: this.i18nService,
      userClient: options.context.userClient
    });

    if (formField?.readonly || fieldDescriptor.permissionLevel === AccessLevelFieldEnum.Readonly) {
      control.disabled = true;
    }

    // Validators
    const controlValidators: EntityFormValidator[] = [];

    const primitiveType = fieldDescriptor.type.primitiveType;
    const typeValidator = fieldDescriptor.type.validator ? JSON.parse(fieldDescriptor.type.validator) : null;

    if (typeValidator?.required || ForceRequiredFieldIds.includes(fieldDescriptor.id) || formField?.validators.required) {
      controlValidators.push({
        kind: EntityFormValidatorKind.Required
      });
    }

    if (typeValidator?.is_email || formField?.validators.isEmail) {
      controlValidators.push({
        kind: EntityFormValidatorKind.Email
      });
    }

    if (formField?.validators.minValue) {
      if (primitiveType === PrimitiveType.DATE || primitiveType === PrimitiveType.DATETIME) {
        controlValidators.push({
          date: formField.validators.minValue,
          kind: EntityFormValidatorKind.MinDate
        });
      } else if (primitiveType === PrimitiveType.INT) {
        controlValidators.push({
          value: parseInt(formField.validators.minValue, 10),
          kind: EntityFormValidatorKind.MinValue
        });
      } else if (primitiveType === PrimitiveType.DOUBLE) {
        controlValidators.push({
          value: parseFloat(formField.validators.minValue),
          kind: EntityFormValidatorKind.MinValue
        });
      }
    }

    if (formField?.validators.maxValue) {
      if (primitiveType === PrimitiveType.DATE || primitiveType === PrimitiveType.DATETIME) {
        controlValidators.push({
          date: formField.validators.maxValue,
          kind: EntityFormValidatorKind.MaxDate
        });
      } else if (primitiveType === PrimitiveType.STRING) {
        controlValidators.push({
          length: parseInt(formField.validators.maxValue, 10),
          kind: EntityFormValidatorKind.MaxLength
        });
      } else if (primitiveType === PrimitiveType.INT) {
        controlValidators.push({
          value: parseInt(formField.validators.maxValue, 10),
          kind: EntityFormValidatorKind.MaxValue
        });
      } else if (primitiveType === PrimitiveType.DOUBLE) {
        controlValidators.push({
          value: parseFloat(formField.validators.maxValue),
          kind: EntityFormValidatorKind.MaxValue
        });
      }
    }

    if (formField?.validators.requiredMinItems) {
      controlValidators.push({
        items: formField.validators.requiredMinItems,
        kind: EntityFormValidatorKind.MinItems
      });
    }

    control.validators = controlValidators;

    return control;
  }

  getValue(options: GetValueOptions): { [id: string]: any } {
    const value = {};

    options.fieldIds.forEach(fieldItem => {
      const fieldId = (typeof fieldItem === 'string') ? fieldItem : fieldItem.fieldId;

      const fieldDescriptor = findById(options.context.fieldDescriptors, fieldId);

      const form = findById(options.context.forms, options.formId);
      const formField = findById(getFormFields(form.formEditApi), fieldId) || null;

      if ((!formField || !formField.inAddin) && (typeof fieldItem === 'string' || !fieldItem.forceOnForm)) {
        return null;
      }

      if (options.entity) {
        const fieldTypeAdapter = findById(FieldTypeAdapters, fieldDescriptor.typeId);

        if (fieldDescriptor.graphqlName) {
          if (fieldDescriptor.systemType === FieldSystemType.CUSTOM) {
            value[fieldId] = JSON.parse(options.entity.customFields)[fieldDescriptor.graphqlName];
          } else {
            value[fieldId] = options.entity[fieldDescriptor.graphqlName];
          }
        }

        if (fieldTypeAdapter?.getValue) {
          value[fieldId] = fieldTypeAdapter.getValue({
            fieldDescriptor,
            entity: options.entity,
            value: value[fieldId]
          });
        }
      } else {
        value[fieldId] = getFieldDefaultValue({
          entities: {
            baseCurrency: this.store.get(getSpaceBaseCurrency)
          },
          fieldDescriptor,
          userClient: options.context.userClient,
          userSettings: this.store.get(getUserSettings)
        });
      }
    });

    return value;
  }

  getInput(options: GetInputOptions): { [id: string]: any } {
    let inputValue = {};
    const customFields = {};

    Object.keys(options.value).forEach(fieldId => {
      const fieldDescriptor = findById(options.context.fieldDescriptors, fieldId);

      if (fieldDescriptor) {
        if (fieldDescriptor.permissionLevel === AccessLevelFieldEnum.Readonly) {
          return;
        }

        const fieldTypeAdapter = findById(FieldTypeAdapters, fieldDescriptor.typeId);

        if (fieldTypeAdapter?.getInput) {
          inputValue = {
            ...inputValue,
            ...fieldTypeAdapter.getInput({
              entity: options.entity,
              value: options.value[fieldId]
            })
          };
        } else {
          if (fieldDescriptor.graphqlName) {
            if (fieldDescriptor.systemType === FieldSystemType.CUSTOM) {
              customFields[fieldDescriptor.graphqlName] = options.value[fieldId];
            } else {
              inputValue[fieldDescriptor.graphqlName] = options.value[fieldId];
            }
          }
        }
      }
    });

    if (Object.keys(customFields).length) {
      return {
        ...inputValue,
        customFields: JSON.stringify(customFields)
      };
    }

    return inputValue;
  }

  getCustomFieldIds(options: GetCustomFieldIdsOptions) {
    const fieldIds: string[] = [];

    getFormFields(findById(options.context.forms, options.formId).formEditApi).filter(formField => {
      return formField.inAddin;
    }).forEach(formField => {
      const fieldDescriptor = findById(options.context.fieldDescriptors, formField.id);

      if (fieldDescriptor.systemType === FieldSystemType.CUSTOM && SupportedCustomFieldTypes.includes(fieldDescriptor.typeId) && !fieldDescriptor.calcFormula && fieldDescriptor.permissionLevel !== AccessLevelFieldEnum.NoAccess) {
        fieldIds.push(fieldDescriptor.id);
      }
    });

    return fieldIds;
  }

  saveEntity(options: SaveEntityOptions): Observable<any> {
    return this.spaceService.gqlClient.mutate({
      mutation: options.mutation,
      variables: {
        input: options.input,
        validationLevel: [ValidationLevel.SKIP_ALL]
      }
    }).pipe(
      tap(() => {
        this.cacheService.reset();
      }),
      map(({ data }) => data)
    );
  }

  private fetchValueOptions(options: FetchValueOptionsOptions): Observable<FieldValueOption[]> {
    return this.spaceService.gqlClient.query<Query>({
      query: gqlFetchValueOptions,
      fetchPolicy: 'no-cache',
      variables: {
        fieldId: options.fieldDescriptor.id,
        entity: options.entityType
      }
    }).pipe(
      map(({ data }) => {
        return data.collections.fieldDescriptor?.valueOptions || [];
      })
    );
  }

}

const ForceRequiredFieldIds: string[] = [
  AppointmentFieldsEnum.StartDateField,
  AppointmentFieldsEnum.EndDateField
];

const SupportedCustomFieldTypes: string[] = [
  'checkbox',
  'currency',
  'currency_foreign',
  'date',
  'datetime',
  'dropdown',
  'email',
  'float',
  'input',
  'integer',
  'multiselect_checkbox',
  'phone',
  'radio',
  'text_area',
  'url'
];

export interface GetControlOptions {
  context: EntityFormApiContext;
  fieldId: string;
  forceOnForm?: boolean;
  formId: string;
}

export interface GetValueOptions {
  context: EntityFormApiContext;
  entity?: any;
  fieldIds: (string | {
    fieldId: string;
    forceOnForm?: boolean;
  })[];
  formId: string;
}

export interface GetInputOptions {
  context: EntityFormApiContext;
  entity?: any;
  value: any;
}

export interface GetCustomFieldIdsOptions {
  context: EntityFormApiContext;
  formId: string;
}

export interface SaveEntityOptions {
  input: { [id: string]: any };
  mutation: () => DocumentNode;
}

export interface FetchValueOptionsOptions {
  entityType: EntityNameEnum;
  fieldDescriptor: FieldDescriptor;
}
