<ppl-icon [svgIcon]="visits.count ? 'entity-tracking-mark-green' : 'entity-tracking-mark'"
          width="16px"
          height="16px"></ppl-icon>

<ng-container *ngIf="displayName">
  <ng-container *ngIf="visits.count === 0"
                i18n="@@PersonName_has_not_read_email_yet.">{{displayName}} has not read email yet.</ng-container>

  <ng-container *ngIf="visits.count !== 0"
                i18n="@@PersonName_read_email_time_delta_ago.">{{displayName}} read email {{visits.firstVisit | pplTimeAgo}}.</ng-container>
</ng-container>

<ng-container *ngIf="!displayName"
              i18n="@@Email_was_read_N_times.">
  {visits.count, plural, =0 {Email has not been read yet.} =1 {Email was read 1 time.} other {Email was read {{visits.count}} times.}}
</ng-container>