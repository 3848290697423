import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContactEntity, EntityNameEnum } from '@ppl/graphql-space-api';
import { AvatarKind } from '../../../avatar/domain/avatar';

@Component({
  selector: 'ppl-contact-card-medium',
  templateUrl: './contact-card-medium.component.html',
  styleUrls: ['./contact-card-medium.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactCardMediumComponent {

  @Input() entity: ContactEntity;
  @Input() isPrimary?: boolean;

  AvatarKind = AvatarKind;
  EntityNameEnum = EntityNameEnum;

}
