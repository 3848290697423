import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplIconModule } from '@ppl/ui/icon';
import { EntityTreeComponent } from './components/entity-tree/entity-tree.component';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule
  ],
  declarations: [
    EntityTreeComponent
  ],
  exports: [
    EntityTreeComponent
  ]
})
export class EntityTreeModule {}
