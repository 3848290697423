import type { AsyncValue} from '@ppl/store';
import { AsyncState, createReducer } from '@ppl/store';
import type { Actions } from '../../../store/core.actions';
import type { DashboardData } from '../domain/dashboard';

export const dashboardReducer = createReducer<DashboardState, Actions>({

  Dashboard_FetchData(state, { payload }) {
    if (payload.state === AsyncState.FETCHED) {
      return {
        ...state,
        data: {
          state: payload.state,
          value: payload.value
        },
        dataRevision: payload.dataRevision
      };
    }

    return {
      ...getDashboardDefaultState(),
      data: {
        state: payload.state
      },
      dataRevision: payload.dataRevision
    };
  }

}, getDashboardDefaultState());

function getDashboardDefaultState(): DashboardState {
  return {
    data: { state: AsyncState.IDLE },
    dataRevision: 0
  };
}

export interface DashboardState {
  data: AsyncValue<DashboardData>;
  dataRevision: number;
}
