import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PplLocationPipe } from '@ppl/auth';
import { AppointmentEntity, EntityPermsEnum } from '@ppl/graphql-space-api';
import { MemoizeLast } from '@ppl/utils';
import type { LocationData } from '../../../location/domain/location';

@Component({
  selector: 'ppl-appointment-fields-card',
  templateUrl: './appointment-fields-card.component.html',
  styleUrls: ['./appointment-fields-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppointmentFieldsCardComponent {

  @Input() entity: AppointmentEntity;

  @Output() locationChange = new EventEmitter<string>();

  EntityPermsEnum = EntityPermsEnum;

  @MemoizeLast<AppointmentFieldsCardComponent>(['entity'])
  get locationUrl() {
    return `https://bing.com/maps?q=${this.entity.location}`;
  }

  constructor(
    private locationPipe: PplLocationPipe
  ) {}

  onLocationChange(location: LocationData) {
    this.locationChange.emit(this.locationPipe.transform(location));
  }

}
