import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@ppl/auth';
import { CoreModule } from '@ppl/core';
import { PplInputModule } from '@ppl/ui/input';
import { PplSearchModule } from '@ppl/ui/search';
import { PplSelectModule } from '@ppl/ui/select';
import { EntityTreeModule } from '../shared/entity-tree/entity-tree.module';
import { OutlookModule } from '../shared/outlook/outlook.module';
import { PageModule } from '../shared/page/page.module';
import { UseTemplateComponent } from './components/use-template/use-template.component';
import { UseTemplateService } from './services/use-template.service';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    CoreModule,
    EntityTreeModule,
    OutlookModule,
    PageModule,
    PplInputModule,
    PplSearchModule,
    PplSelectModule,
    ReactiveFormsModule
  ],
  declarations: [
    UseTemplateComponent
  ],
  exports: [
    UseTemplateComponent
  ],
  providers: [
    UseTemplateService
  ]
})
export class UseTemplateModule {}
