import { gql } from '@ppl/utils';

export const gqlFetchContactFormData = gql`
  query fetchContactFormData($id: ID!) {
    entities {
      contact {
        getById(entityId: $id) {
          accountRelations {
            edges {
              node {
                id
                accountId
                isPrimary
              }
            }
          }
          comments
          contactTypeId
          customFields
          email1
          email2
          email3
          email4
          email5
          firstName
          id
          lastName
          middleName
          ownerId
          phone1
          phone2
          phone3
          phone4
          phone5
          title
          unitId
        }
      }
    }
  }
`;

export const gqlCreateContactMutation = gql`
  mutation createContact($input: CreateContactInput!, $validationLevel: [ValidationLevel!]) {
    createContact(input: $input, validationLevel: $validationLevel) {
      contact {
        id
      }
    }
  }
`;

export const gqlUpdateContactMutation = gql`
  mutation updateContact($input: UpdateContactInput!, $validationLevel: [ValidationLevel!]) {
    updateContact(input: $input, validationLevel: $validationLevel) {
      clientMutationId
    }
  }
`;
