import { Injectable } from '@angular/core';
import { getUserSettings } from '@ppl/auth';
import { ActionsUtilsService } from '@ppl/core';
import { TaskFieldsEnum } from '@ppl/domain';
import { FirebaseEventsEnum, FirebaseService } from '@ppl/firebase';
import type { CreateTaskSharingClientRelationNoTaskBackrefInput, TaskEntity } from '@ppl/graphql-space-api';
import { EntityNameEnum, ReminderStatusEnum, SharingRoleEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { getSpaceTaskTypes } from '@ppl/space';
import type { PplSelectOption } from '@ppl/ui/select';
import { sortByKey } from '@ppl/utils';
import moment from 'moment';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { RecentRecordsService } from '../../../services/recent-records.service';
import { CoreStore } from '../../../store/core.store';
import { EmailService } from '../../shared/email/services/email.service';
import type { EntityMultipleSelectItem } from '../../shared/entity-control/components/entity-multiple-select/entity-multiple-select.component';
import { EntityFormApiService } from '../../shared/entity-form-api/services/entity-form-api.service';
import type { EntityFormControl} from '../../shared/entity-form/domain/controls';
import { EntityFormControlKind } from '../../shared/entity-form/domain/controls';
import { EntityFormValidatorKind } from '../../shared/entity-form/domain/validators';
import { SuccessToastComponent } from '../../shared/entity/components/success-toast/success-toast.component';
import { EntityListAdapters } from '../../shared/entity/domain/entity-list';
import type { SuccessToastData} from '../../shared/entity/domain/success-toast';
import { SuccessToastOperation } from '../../shared/entity/domain/success-toast';
import { EntityService } from '../../shared/entity/services/entity.service';
import { OutlookService } from '../../shared/outlook/services/outlook.service';
import { ToastService } from '../../shared/toast/services/toast.service';
import type { TaskFormData, TaskFormDataOutlookItem, TaskFormValue } from '../domain/task-form';
import { gqlCreateTaskMutation, gqlFetchTaskFormData, gqlUpdateTaskMutation } from './task-form.graphql';

@Injectable()
export class TaskFormService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private emailService: EmailService,
    private entityService: EntityService,
    private entityFormApiService: EntityFormApiService,
    private firebaseService: FirebaseService,
    private i18nService: I18nService,
    private outlookService: OutlookService,
    private recentRecordsService: RecentRecordsService,
    private store: CoreStore,
    private toastService: ToastService
  ) {}

  fetchData(options: FetchDataOptions) {
    return combineLatest([
      this.entityService.fetchEntity<TaskEntity>({
        entityType: EntityNameEnum.Task,
        id: options.id,
        query: gqlFetchTaskFormData
      }),
      this.entityFormApiService.fetchContext(EntityNameEnum.Task, {
        entities: [EntityNameEnum.TaskType, EntityNameEnum.SalesUnit]
      }),
      this.fetchOutlookItem()
    ]).pipe(
      map(([ entity, context, outlookItem ]) => {
        if (options.id && !entity) {
          return null;
        }

        const result: TaskFormData = {
          entity,
          context,
          outlookItem,
          relatedAccountId: options.relatedAccountId,
          relatedContactId: options.relatedContactId,
          relatedLeadId: options.relatedLeadId,
          relatedOpportunityId: options.relatedOpportunityId
        };

        return result;
      })
    );
  }

  getControls(data: TaskFormData, formId: string) {
    const taskTypes = this.getTaskTypes();

    const createForm = !data.entity;
    const outlookHasAttachments = this.outlookService.hasAttachments();

    const controls: EntityFormControl[] = [
      {
        id: 'activityTypeId',
        label: this.i18nService.translate('Activity_type'),
        options: taskTypes.map<PplSelectOption>(taskType => ({
          deleted: taskType.isDeleted || taskType.isReadonly,
          label: taskType.name,
          value: taskType.id
        })).sort(sortByKey('label')),
        kind: EntityFormControlKind.Dropdown,
        validators: [{
          kind: EntityFormValidatorKind.Required
        }]
      },
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: TaskFieldsEnum.SubjectField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: TaskFieldsEnum.DescriptionField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: TaskFieldsEnum.DueDateField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: TaskFieldsEnum.PriorityField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: TaskFieldsEnum.StatusField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: TaskFieldsEnum.UnitIdField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: TaskFieldsEnum.OwnerIdField,
        forceOnForm: true,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: TaskFieldsEnum.LinkedItemsField,
        formId
      }),
      createForm && {
        id: 'watchers',
        label: this.i18nService.translate('Watchers'),
        entityAdapters: EntityListAdapters,
        entityTypes: [
          EntityNameEnum.Client
        ],
        kind: EntityFormControlKind.MultipleEntity
      },
      createForm && {
        id: 'reminderDate',
        label: this.i18nService.translate('Reminder'),
        kind: EntityFormControlKind.DateTime
      },
      createForm && outlookHasAttachments && {
        id: 'saveAttachments',
        label: this.i18nService.translate('Save_email_attachments_to_new_task'),
        inlineLabel: true,
        kind: EntityFormControlKind.Checkbox
      },
      ...this.entityFormApiService.getCustomFieldIds({
        context: data.context,
        formId
      }).map(fieldId => ({
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId,
          formId
        }),
        data: {
          customField: true
        }
      }))
    ];

    return controls.filter(control => control);
  }

  getValue(data: TaskFormData, formId: string): TaskFormValue {
    const value = this.entityFormApiService.getValue({
      context: data.context,
      entity: data.entity,
      fieldIds: [
        TaskFieldsEnum.SubjectField,
        TaskFieldsEnum.DescriptionField,
        TaskFieldsEnum.DueDateField,
        TaskFieldsEnum.PriorityField,
        TaskFieldsEnum.StatusField,
        TaskFieldsEnum.UnitIdField,
        { fieldId: TaskFieldsEnum.OwnerIdField, forceOnForm: true },
        TaskFieldsEnum.LinkedItemsField,
        ...this.entityFormApiService.getCustomFieldIds({
          context: data.context,
          formId
        })
      ],
      formId
    });

    if (data.entity) {
      return {
        ...value,
        activityTypeId: formId
      };
    } else {
      return {
        ...value,
        [TaskFieldsEnum.SubjectField]: data.outlookItem.subject,
        [TaskFieldsEnum.DescriptionField]: this.getDescriptionFromOutlookItem(data),
        [TaskFieldsEnum.LinkedItemsField]: this.getDefaultLinkedItems(data),
        activityTypeId: formId,
        reminderDate: this.getDefaultReminderDate(value[TaskFieldsEnum.DueDateField]),
        saveAttachments: false,
        watchers: []
      };
    }
  }

  createEntity(value: TaskFormValue, data: TaskFormData) {
    return this.actionsUtilsService.doAction(this.entityFormApiService.saveEntity({
      input: {
        ...this.entityFormApiService.getInput({
          context: data.context,
          value
        }),
        activityTypeId: value.activityTypeId,
        reminder: value.reminderDate ? {
          ownerId: data.context.userClient.id,
          setDate: value.reminderDate,
          status: ReminderStatusEnum.Scheduled
        } : {
          ownerId: data.context.userClient.id,
          setDate: moment().toISOString(),
          status: ReminderStatusEnum.Dismissed
        },
        sharingClients: value.watchers.map<CreateTaskSharingClientRelationNoTaskBackrefInput>(item => ({
          clientId: item.entityId,
          role: SharingRoleEnum.Watcher
        }))
      },
      mutation: gqlCreateTaskMutation
    }).pipe(
      switchMap(result => {
        const taskId = result.createTask.task.id;

        return of(null).pipe(
          switchMap(() => {
            if (value.saveAttachments) {
              return this.emailService.saveAttachments({
                relations: [{
                  entityId: taskId,
                  entityType: EntityNameEnum.Task
                }],
                silent: true
              });
            }

            return of(null);
          }),
          tap(() => {
            this.toastService.open<SuccessToastData>({
              content: {
                component: SuccessToastComponent,
                data: {
                  entityType: EntityNameEnum.Task,
                  id: taskId,
                  operation: SuccessToastOperation.Create
                }
              }
            });

            this.recentRecordsService.add(taskId);

            const hasRelation = data.relatedAccountId || data.relatedContactId || data.relatedLeadId || data.relatedOpportunityId;

            this.firebaseService.logEvent(hasRelation ? FirebaseEventsEnum.Create : FirebaseEventsEnum.CreateFromEmail, {
              entity: 'task'
            });
          })
        );
      })
    ));
  }

  updateEntity(value: TaskFormValue, data: TaskFormData) {
    return this.actionsUtilsService.doAction(this.entityFormApiService.saveEntity({
      input: {
        ...this.entityFormApiService.getInput({
          entity: data.entity,
          context: data.context,
          value
        }),
        activityTypeId: value.activityTypeId,
        id: data.entity.id
      },
      mutation: gqlUpdateTaskMutation
    }).pipe(
      tap(() => {
        this.toastService.open<SuccessToastData>({
          content: {
            component: SuccessToastComponent,
            data: {
              entityType: EntityNameEnum.Task,
              id: data.entity.id,
              operation: SuccessToastOperation.Update
            }
          }
        });

        this.firebaseService.logEvent(FirebaseEventsEnum.Update, {
          entity: 'task'
        });
      })
    ));
  }

  getDefaultReminderDate(dueDate: string | null) {
    const defaultReminderDayOffset = this.store.get(getUserSettings).defaultTaskReminderDayOffset;
    const defaultReminderTimeOffset = this.store.get(getUserSettings).defaultTaskReminderTimeOffset;

    if (dueDate && defaultReminderDayOffset !== null && defaultReminderTimeOffset !== null) {
      return moment(dueDate).subtract(defaultReminderDayOffset, 'days').add(defaultReminderTimeOffset, 'seconds').toISOString();
    }

    return null;
  }

  getDefaultFormId() {
    return this.getTaskTypes().filter(taskType => !taskType.isDeleted && !taskType.isReadonly)[0]?.id || null;
  }

  private fetchOutlookItem() {
    return combineLatest([
      this.outlookService.getSender(),
      this.outlookService.getToRecipients(),
      this.outlookService.getSubject(),
      this.outlookService.getTextBody()
    ]).pipe(
      map(([ sender, toRecipients, subject, body ]) => {
        const result: TaskFormDataOutlookItem = {
          sender,
          toRecipients,
          subject,
          body
        };

        return result;
      })
    );
  }

  private getTaskTypes() {
    return this.store.get(getSpaceTaskTypes).filter(taskType => taskType.isPublished).sort(sortByKey('name'));
  }

  private getDescriptionFromOutlookItem(data: TaskFormData) {
    return `From: ${data.outlookItem.sender.email}\nTo: ${data.outlookItem.toRecipients.map(recipient => recipient.email).join(', ')}\n\n${data.outlookItem.body}`;
  }

  private getDefaultLinkedItems(data: TaskFormData): EntityMultipleSelectItem[] {
    if (data.relatedAccountId) {
      return [{
        entityId: data.relatedAccountId,
        entityType: EntityNameEnum.Account
      }];
    } else if (data.relatedContactId) {
      return [{
        entityId: data.relatedContactId,
        entityType: EntityNameEnum.Contact
      }];
    } else if (data.relatedLeadId) {
      return [{
        entityId: data.relatedLeadId,
        entityType: EntityNameEnum.Lead
      }];
    } else if (data.relatedOpportunityId) {
      return [{
        entityId: data.relatedOpportunityId,
        entityType: EntityNameEnum.Opportunity
      }];
    }

    return [];
  }

}

export interface FetchDataOptions {
  id: string | null;
  relatedAccountId: string | null;
  relatedContactId: string | null;
  relatedLeadId: string | null;
  relatedOpportunityId: string | null;
}
