import { AppointmentFieldsEnum } from '@ppl/domain';
import type { ClientEntity, CurrencyEntity, FieldDescriptor} from '@ppl/graphql-space-api';
import { PrimitiveType } from '@ppl/graphql-space-api';
import type { UserSettings } from '@ppl/graphql-user-api';
import { getTodayDate, momentToIsoDateString, momentToIsoDateTimeString } from '@ppl/utils';
import moment from 'moment';

export function getFieldDefaultValue(options: GetFieldDefaultValueOptions) {
  const defaultValue = (options.fieldDescriptor.defaultValue !== null) ? options.fieldDescriptor.defaultValue : options.fieldDescriptor.type.defaultValue;
  const validator = options.fieldDescriptor.type.validator ? JSON.parse(options.fieldDescriptor.type.validator) : null;

  let value: any = defaultValue;

  // 1. Get value from field descriptor default value
  if (value !== null) {
    // Cast types
    switch (options.fieldDescriptor.type.primitiveType) {
      case PrimitiveType.BOOL:
        value = (value === 'true' || value === '1');
        break;
      case PrimitiveType.DOUBLE:
      case PrimitiveType.DECIMAL:
        value = parseFloat(value);
        break;
      case PrimitiveType.ENUM:
        if (validator?.int_enum) {
          Object.keys(validator.int_enum).forEach(id => {
            if (value === validator.int_enum[id].toString()) {
              value = id;
            }
          });
        } else if (validator?.flag_enum) {
          value = parseInt(value, 10);
        }
        break;
      case PrimitiveType.INT:
        value = parseInt(value, 10);
        break;
    }

    // Special values
    switch (value) {
      case 'CUSTOM_SET_SALES_UNIT_ID_FOR_CLIENT':
        value = options.userClient.defaultUnitId;
        break;
      case 'DATE_NOW':
        value = getTodayDate();
        break;
      case 'DATETIME_NOW':
        if (options.fieldDescriptor.id === AppointmentFieldsEnum.StartDateField) {
          value = getNextHalfHourMoment().toISOString();
        } else {
          value = moment().toISOString();
        }
        break;
      case 'END_OF_THIS_MONTH':
        value = formatMomentValue(moment().endOf('month'), options.fieldDescriptor);
        break;
      case 'END_OF_THIS_QUARTER':
        value = formatMomentValue(moment().endOf('quarter'), options.fieldDescriptor);
        break;
      case 'END_OF_THIS_YEAR':
        value = formatMomentValue(moment().endOf('year'), options.fieldDescriptor);
        break;
      case 'RELATIVE_USER_DEF_DATE':
        if (options.userSettings.defaultTaskDueDate !== null) {
          value = momentToIsoDateString(moment().add(options.userSettings.defaultTaskDueDate, 'seconds'));
        } else {
          value = null;
        }
        break;
    }
  }

  // 2. Get value by field descriptor type
  switch (options.fieldDescriptor.typeId) {
    case 'currency_foreign':
      value = {
        currencyId: options.entities.baseCurrency.id,
        valueForeign: value || 0
      };
      break;
    case 'linked_items':
    case 'multiselect_checkbox':
      value = [];
      break;
    case 'owner':
    case 'owner_or_unassigned':
      value = options.userClient.id;
      break;
  }

  // 3. Get value by field descriptor ID
  switch (options.fieldDescriptor.id) {
    case AppointmentFieldsEnum.EndDateField:
      value = getNextHalfHourMoment().add(options.userSettings.defaultAppointmentDuration, 'seconds').toISOString();
      break;
  }

  return value;
}

function formatMomentValue(value: moment.Moment, fieldDescriptor: FieldDescriptor) {
  return (fieldDescriptor.typeId === 'datetime')
    ? momentToIsoDateTimeString(value)
    : momentToIsoDateString(value);
}

function getNextHalfHourMoment() {
  const startDate = moment();
  const remainder = 30 - (startDate.minute() % 30);

  return moment().add(remainder, 'minutes').set({ seconds: 0, milliseconds: 0 });
}

export interface GetFieldDefaultValueOptions {
  entities: {
    baseCurrency: CurrencyEntity;
  };
  fieldDescriptor: FieldDescriptor;
  userClient: ClientEntity;
  userSettings: UserSettings;
}
