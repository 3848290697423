import { gql } from '@ppl/utils';

export const gqlFetchContactRecipients = gql`
  query fetchContactRecipients($entityIds: [ID!]!) {
    entities {
      account {
        getByIds(entityIds: $entityIds) {
          email1
          email2
          email3
          email4
          email5
          id
          name
        }
      }
      contact {
        getByIds(entityIds: $entityIds) {
          email1
          email2
          email3
          email4
          email5
          firstName
          id
          lastName
          middleName
          title
        }
      }
    }
  }
`;

export const gqlFetchSalesTeamRecipients = gql`
  query fetchSalesTeamRecipients($entityIds: [ID!]!) {
    entities {
      account {
        getByIds(entityIds: $entityIds) {
          id
          name
          sharingClients {
            edges {
              node {
                id
                client {
                  email
                  id
                  name
                }
              }
            }
          }
        }
      }
      contact {
        getByIds(entityIds: $entityIds) {
          firstName
          id
          lastName
          middleName
          sharingClients {
            edges {
              node {
                id
                client {
                  email
                  id
                  name
                }
              }
            }
          }
          title
        }
      }
      lead {
        getByIds(entityIds: $entityIds) {
          id
          name
          sharingClients {
            edges {
              node {
                id
                client {
                  email
                  id
                  name
                }
              }
            }
          }
        }
      }
      opportunity {
        getByIds(entityIds: $entityIds) {
          id
          name
          sharingClients {
            edges {
              node {
                id
                client {
                  email
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
