import type { AfterViewInit} from '@angular/core';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, NgZone, Output, ViewChild } from '@angular/core';
import type { LocationData } from '../../domain/location';

declare const google: any;

@Component({
  selector: 'ppl-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationPickerComponent implements AfterViewInit {

  @Output() placeSelect = new EventEmitter<LocationData>();

  @ViewChild('input') input: ElementRef;

  private autocomplete: any;

  constructor(
    private ngZone: NgZone
  ) {}

  ngAfterViewInit() {
    if ('google' in window) {
      this.autocomplete = new google.maps.places.Autocomplete(this.input.nativeElement, { types: ['geocode'] });

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      this.autocomplete.setFields(['address_component']);

      this.autocomplete.addListener('place_changed', this.onSelect.bind(this));
    }
  }

  onSelect() {
    const result = {
      streetNumber: '',
      streetName: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    };

    const place = this.autocomplete.getPlace();

    if (!place.address_components) {
      return;
    }

    place.address_components.forEach(c => {
      switch (c.types[0]) {
        case 'street_number':
          result.streetNumber = c.long_name;
          break;
        case 'route':
          result.streetName = c.long_name;
          break;
        case 'neighborhood':
        case 'locality':
        case 'sublocality_level_1':
          result.city = c.long_name;
          break;
        case 'administrative_area_level_1':
          result.state = c.long_name;
          break;
        case 'postal_code':
          result.zip = c.long_name;
          break;
        case 'country':
          result.country = c.long_name;
          break;
      }
    });

    this.ngZone.run(() => {
      this.placeSelect.emit({
        address: [result.streetName, result.streetNumber].filter(Boolean).join(' '),
        city: result.city,
        country: result.country,
        stateProvince: result.state
      });
    });
  }

}
