import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthModule } from '@ppl/auth';
import { CoreModule } from '@ppl/core';
import { I18nModule } from '@ppl/i18n';
import { CardModule } from '../shared/card/card.module';
import { EntityCardModule } from '../shared/entity-card/entity-card.module';
import { EntityFormApiModule } from '../shared/entity-form-api/entity-form-api.module';
import { EntityFormModule } from '../shared/entity-form/entity-form.module';
import { EntityModule } from '../shared/entity/entity.module';
import { PageModule } from '../shared/page/page.module';
import { ToastModule } from '../shared/toast/toast.module';
import { AccountFormComponent } from './components/account-form/account-form.component';
import { AccountComponent } from './components/account/account.component';
import { AccountFormService } from './services/account-form.service';
import { AccountService } from './services/account.service';

@NgModule({
  imports: [
    AuthModule,
    CardModule,
    CommonModule,
    CoreModule,
    EntityModule,
    EntityCardModule,
    EntityFormModule,
    EntityFormApiModule,
    I18nModule,
    PageModule,
    ToastModule
  ],
  declarations: [
    AccountComponent,
    AccountFormComponent
  ],
  exports: [
    AccountComponent,
    AccountFormComponent
  ],
  providers: [
    AccountService,
    AccountFormService
  ]
})
export class AccountModule {}
