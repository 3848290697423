<ppl-card-large [disabled]="true">
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="EntityNameEnum.Client"
                [kind]="AvatarKind.Large"
                [pictureUrl]="entity.pictureUrl"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    {{entity | pplName}}
  </ng-template>

  <ng-template #descriptionTemplate>
    <ng-container i18n="@@Colleague">Colleague</ng-container>

    <br />

    {{entity.email}}
  </ng-template>
</ppl-card-large>