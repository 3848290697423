<ppl-page>
  <ng-template #headerTemplate
               let-globalSearch="globalSearch">
    <ppl-page-detail-header [label]="'Lead' | t"
                            [globalSearch]="globalSearch"></ppl-page-detail-header>
  </ng-template>

  <ng-container *ngIf="data$ | async; let data">
    <div *ngIf="data.state === AsyncState.FETCHED"
         class="container">
      <ppl-lead-card-large [entity]="data.value.entity"
                           [disabled]="true"></ppl-lead-card-large>

      <ppl-actions-card [entity]="data.value.entity"></ppl-actions-card>

      <ppl-lead-fields-card [entity]="data.value.entity"></ppl-lead-fields-card>

      <ppl-card-tabs>
        <ppl-card-tab label="Related"
                      i18n-label="@@Related">
          <ppl-accounts-cards [entities]="data.value.relatedAccounts"
                              [parentEntity]="data.value.entity"></ppl-accounts-cards>

          <ppl-contacts-cards [entities]="data.value.relatedContacts"
                              [parentEntity]="data.value.entity"></ppl-contacts-cards>
        </ppl-card-tab>

        <ppl-card-tab label="Activity"
                      i18n-label="@@Activity">
          <ppl-activities-cards [entities]="data.value.relatedActivities"
                                [parentEntity]="data.value.entity"></ppl-activities-cards>
        </ppl-card-tab>
      </ppl-card-tabs>

      <ppl-date-card [entity]="data.value.entity"></ppl-date-card>
    </div>

    <ppl-page-loading *ngIf="data.state === AsyncState.FETCHING"></ppl-page-loading>

    <ppl-entity-error *ngIf="data.state === AsyncState.ERROR"
                      [error]="data.error"></ppl-entity-error>
  </ng-container>
</ppl-page>
