import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { EntityNameEnum, TaskEntity, TaskStatusEnum } from '@ppl/graphql-space-api';
import { LocationService } from '../../../../../services/location.service';
import { AvatarKind } from '../../../avatar/domain/avatar';

@Component({
  selector: 'ppl-task-card-large',
  templateUrl: './task-card-large.component.html',
  styleUrls: ['./task-card-large.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskCardLargeComponent {

  @Input() entity: TaskEntity;
  @Input() disabled?: boolean;

  AvatarKind = AvatarKind;
  EntityNameEnum = EntityNameEnum;
  TaskStatusEnum = TaskStatusEnum;

  constructor(
    private locationService: LocationService
  ) {}

  @HostListener('click')
  onClick() {
    if (!this.disabled) {
      this.locationService.openTask(this.entity.id);
    }
  }

}
