<ng-container *ngFor="let control of systemFieldControls; trackBy: trackById">
  <ng-container *ngTemplateOutlet="controlRenderer; context: { $implicit: control }"></ng-container>
</ng-container>

<div *ngIf="customFieldControls.length > 0"
     class="header"
     (click)="onHeaderClick()">
  <ng-container i18n="@@Other_fields">Other fields</ng-container>

  <svg [class.open]="customFieldsVisible"
       width="12px"
       height="8px">
    <path d="m 6 5.5 l 5.25 -4.7 l 0.75 0.7 l -6 5.5 l -6 -5.5 l 0.75 -0.7"
          fill="#323130"></path>
  </svg>
</div>

<ng-container *ngIf="customFieldsVisible">
  <ng-container *ngFor="let control of customFieldControls; trackBy: trackById">
    <ng-container *ngTemplateOutlet="controlRenderer; context: { $implicit: control }"></ng-container>
  </ng-container>
</ng-container>