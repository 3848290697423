import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MemoizeLast } from '@ppl/utils';
import type { DashboardItem} from '../../domain/dashboard';
import { DashboardItemKind } from '../../domain/dashboard';

@Component({
  selector: 'ppl-dashboard-recipients',
  templateUrl: './dashboard-recipients.component.html',
  styleUrls: ['./dashboard-recipients.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardRecipientsComponent {

  @Input() items: DashboardItem[];

  @MemoizeLast<DashboardRecipientsComponent>(['items'])
  get accountItems() {
    return this.items.filter(item => item.kind === DashboardItemKind.Account);
  }

  @MemoizeLast<DashboardRecipientsComponent>(['items'])
  get clientItems() {
    return this.items.filter(item => item.kind === DashboardItemKind.Client);
  }

  @MemoizeLast<DashboardRecipientsComponent>(['items'])
  get contactItems() {
    return this.items.filter(item => item.kind === DashboardItemKind.Contact);
  }

  @MemoizeLast<DashboardRecipientsComponent>(['items'])
  get otherItems() {
    return this.items.filter(item => item.kind === DashboardItemKind.Other);
  }

  @MemoizeLast<DashboardRecipientsComponent>(['items'])
  get userItems() {
    return this.items.filter(item => item.kind === DashboardItemKind.User);
  }

  @MemoizeLast<DashboardRecipientsComponent>(['items'])
  get userClientItems() {
    return [
      ...this.items.filter(item => item.kind === DashboardItemKind.User),
      ...this.items.filter(item => item.kind === DashboardItemKind.Client)
    ];
  }

  @MemoizeLast<DashboardRecipientsComponent>(['items'])
  get accountContactOtherItemsLargeCard() {
    return this.accountItems.length + this.contactItems.length + this.otherItems.length === 1;
  }

  @MemoizeLast<DashboardRecipientsComponent>(['items'])
  get clientItemsLargeCard() {
    return this.accountItems.length + this.contactItems.length + this.otherItems.length + this.userItems.length === 0 && this.clientItems.length === 1;
  }

}
