import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import type { PplMenuItem } from '@ppl/ui/menu-items';

@Component({
  selector: 'ppl-dashboard-menu-action',
  templateUrl: './dashboard-menu-action.component.html',
  styleUrls: ['./dashboard-menu-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardMenuActionComponent {

  @Input() description: string;
  @Input() icon: string;
  @Input() iconName: string;
  @Input() name: string;

  @Input() menuItems?: PplMenuItem[];

  @Output() actionClick = new EventEmitter();
  @Output() menuItemClick = new EventEmitter<string>();

}
