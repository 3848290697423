<ppl-page [flexContent]="true">
  <ng-template #headerTemplate>
    <ppl-page-form-header *ngIf="kind === EmailKind.Message"
                          icon="entity-white-new-email"
                          label="Save email"
                          i18n-label="@@Save_email"></ppl-page-form-header>

    <ppl-page-form-header *ngIf="kind === EmailKind.Document"
                          icon="entity-white-new-email"
                          label="Save email as document"
                          i18n-label="@@Save_email_as_document"></ppl-page-form-header>

    <ppl-page-form-header *ngIf="kind === EmailKind.Attachments"
                          icon="entity-white-new-email"
                          label="Save email attachments"
                          i18n-label="@@Save_email_attachments"></ppl-page-form-header>
  </ng-template>

  <ng-container *ngIf="availableEntityTypes.length">
    <div class="container"
         [formGroup]="formGroup">
      <!-- Message -->
      <ng-container *ngIf="kind === EmailKind.Message">
        <ppl-input-container label="Select entity where do you want to save email:"
                             i18n-label="@@Select_entity_where_do_you_want_to_save_email:"
                             class="list-container"
                             [pplFormControl]="formGroup.get('entityItems')">
          <ppl-entity-list-type [entityAdapters]="EntityListAdapters"
                                [entityTypes]="availableEntityTypes"
                                [multipleSelection]="true"
                                [optionTemplate]="entityOptionTemplate"
                                [optionTemplateRowHeight]="40"
                                formControlName="entityItems"></ppl-entity-list-type>
        </ppl-input-container>

        <ppl-input-container *ngIf="attachments.length"
                             [pplFormControl]="formGroup.get('saveAttachments')">
          <ppl-checkbox formControlName="saveAttachments"
                        i18n="@@Save_including_attachments">Save including attachments</ppl-checkbox>
        </ppl-input-container>
      </ng-container>

      <!-- Document -->
      <ng-container *ngIf="kind === EmailKind.Document">
        <ppl-input-container label="Select entity where do you want to save email to documents:"
                             i18n-label="@@Select_entity_where_do_you_want_to_save_email_to_documents:"
                             class="list-container"
                             [pplFormControl]="formGroup.get('entityItems')">
          <ppl-entity-list-type [entityAdapters]="EntityListAdapters"
                                [entityTypes]="availableEntityTypes"
                                [multipleSelection]="true"
                                [optionTemplate]="entityOptionTemplate"
                                [optionTemplateRowHeight]="40"
                                formControlName="entityItems"></ppl-entity-list-type>
        </ppl-input-container>

        <ppl-input-container label="Document Name"
                             i18n-label="@@Document_Name"
                             [pplFormControl]="formGroup.get('fileName')">
          <input pplInput
                 autocomplete="off"
                 formControlName="fileName" />
        </ppl-input-container>
      </ng-container>

      <!-- Attachments -->
      <ng-container *ngIf="kind === EmailKind.Attachments">
        <ppl-input-container label="Choose which attachments you would like to save:"
                             i18n-label="@@Choose_which_attachments_you_would_like_to_save:"
                             class="list-container"
                             [pplFormControl]="formGroup.get('attachments')">
          <ppl-save-email-attachments formControlName="attachments"></ppl-save-email-attachments>
        </ppl-input-container>

        <ppl-input-container label="Select entity for saving attachments to documents:"
                             i18n-label="@@Select_entity_for_saving_attachments_to_documents:"
                             class="list-container"
                             [pplFormControl]="formGroup.get('entityItems')">
          <ppl-entity-list-type [entityAdapters]="EntityListAdapters"
                                [entityTypes]="availableEntityTypes"
                                [multipleSelection]="true"
                                [optionTemplate]="entityOptionTemplate"
                                [optionTemplateRowHeight]="40"
                                formControlName="entityItems"></ppl-entity-list-type>
        </ppl-input-container>
      </ng-container>
    </div>

    <ppl-page-actions (cancelClick)="onCancelClick()"
                      (saveClick)="onSaveClick()"></ppl-page-actions>
  </ng-container>

  <ppl-page-error *ngIf="!availableEntityTypes.length"
                  text="You do not have access to any entity"
                  i18n-text="@@You_do_not_have_access_to_any_entity"></ppl-page-error>
</ppl-page>

<ng-template #entityOptionTemplate
             let-option>
  <ng-container [ngSwitch]="option.data.entity.__typename">
    <ppl-entity-control-item *ngSwitchCase="'AccountEntity'"
                             [avatarKind]="AvatarKind.Small"
                             [entityType]="EntityNameEnum.Account"
                             [height]="40"
                             [name]="option.data.entity.name"
                             [description]="option.data.entity | pplLocation"
                             [pictureUrl]="option.data.entity.picture?.url"></ppl-entity-control-item>

    <ppl-entity-control-item *ngSwitchCase="'ContactEntity'"
                             [avatarKind]="AvatarKind.Small"
                             [entityType]="EntityNameEnum.Contact"
                             [height]="40"
                             [name]="option.data.entity | pplName"
                             [description]="option.data.entity.primaryAccount?.name"
                             [pictureUrl]="option.data.entity.picture?.url"></ppl-entity-control-item>

    <ppl-entity-control-item *ngSwitchCase="'LeadEntity'"
                             [height]="40"
                             [name]="option.data.entity.name"
                             [description]="option.data.entity.primaryAccount?.name"></ppl-entity-control-item>

    <ppl-entity-control-item *ngSwitchCase="'OpportunityEntity'"
                             [height]="40"
                             [name]="option.data.entity.name"
                             [description]="option.data.entity.primaryAccount?.name"></ppl-entity-control-item>
  </ng-container>
</ng-template>