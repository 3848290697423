import type { AppointmentEntity, AppointmentFilterInput, AppointmentOrderByInput} from '@ppl/graphql-space-api';
import { OrderByEnum } from '@ppl/graphql-space-api';
import { gql } from '@ppl/utils';
import type { EntityAdapter } from './entity.adapter';

export const AppointmentAdapter: EntityAdapter = {
  entityFields: gql`
    fragment AppointmentEntityListFields on AppointmentEntity {
      id
      subject
    }
  `,
  getFilterInput(filter, excludeIds, includeIds) {
    const filterInput: AppointmentFilterInput = {
      OR: [
        { subject: { icontains: [filter] } }
      ],
      NOT: {
        id: { eq: excludeIds }
      }
    };

    if (includeIds.length !== 0) {
      filterInput.id = { eq: includeIds };
    }

    return filterInput;
  },
  getName(entity: AppointmentEntity) {
    return entity.subject;
  },
  getOrderByInput(): AppointmentOrderByInput[] {
    return [
      { subject: OrderByEnum.Asc }
    ];
  }
};
