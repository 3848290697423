import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MemoizeLast, trackById } from '@ppl/utils';
import type { EntityFormControl } from '../../../entity-form/domain/controls';

@Component({
  selector: 'ppl-entity-form-layout',
  templateUrl: './entity-form-layout.component.html',
  styleUrls: ['./entity-form-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityFormLayoutComponent {

  @Input() controls: EntityFormControl[];
  @Input() controlRenderer: TemplateRef<any>;
  @Input() customFieldsVisible: boolean;

  @Output() customFieldsVisibleChange = new EventEmitter<boolean>();

  trackById = trackById;

  @MemoizeLast<EntityFormLayoutComponent>(['controls'])
  get systemFieldControls() {
    return this.controls.filter(control => !control.data?.customField);
  }

  @MemoizeLast<EntityFormLayoutComponent>(['controls'])
  get customFieldControls() {
    return this.controls.filter(control => control.data?.customField);
  }

  onHeaderClick() {
    this.customFieldsVisibleChange.emit(!this.customFieldsVisible);
  }

}
