<ng-container *ngIf="item.kind === DashboardItemKind.Contact">
  <ppl-contact-card-large [entity]="item.entity"></ppl-contact-card-large>

  <ppl-actions-card [entity]="item.entity"></ppl-actions-card>
</ng-container>

<ng-container *ngIf="item.kind === DashboardItemKind.Account">
  <ppl-account-card-large [entity]="item.entity"></ppl-account-card-large>

  <ppl-actions-card [entity]="item.entity"></ppl-actions-card>
</ng-container>

<ppl-user-card-small *ngIf="item.kind === DashboardItemKind.User"
                     [direction]="OutlookItemDirection.Sent"></ppl-user-card-small>

<ppl-client-card-large *ngIf="item.kind === DashboardItemKind.Client"
                       [entity]="item.entity"></ppl-client-card-large>

<ng-container *ngIf="item.kind === DashboardItemKind.Other">
  <ppl-other-card-large [person]="item.person"></ppl-other-card-large>

  <ppl-actions-card [entity]="null"></ppl-actions-card>
</ng-container>