<ppl-dialog title="Move task to stage"
            i18n-title="@@Move_task_to_stage"
            actionLabel="Move"
            i18n-actionLabel="@@Move"
            [formGroup]="formGroup"
            [formGroupNoPadding]="true"
            width="80vh"
            (action)="onSave()">
  <ppl-input-container label="Status"
                       i18n-label="@@Status"
                       [pplFormControl]="formGroup.get('status')">
    <ppl-select [options]="statusOptions"
                [displaySearch]="false"
                formControlName="status"></ppl-select>
  </ppl-input-container>
</ppl-dialog>