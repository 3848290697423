import type { OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import type { UntypedFormGroup, ValidatorFn} from '@angular/forms';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { filterObject, findById, isFormValid, notFirstChange, PplValidators, trackById, unsubscribe, Unsubscribe } from '@ppl/utils';
import type { Subscription } from 'rxjs';
import { AvatarKind } from '../../../avatar/domain/avatar';
import type { EntityFormControl} from '../../domain/controls';
import { EntityFormControlKind } from '../../domain/controls';
import { EntityFormValidatorKind } from '../../domain/validators';

@Component({
  selector: 'ppl-entity-form',
  templateUrl: './entity-form.component.html',
  styleUrls: ['./entity-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Unsubscribe()
export class EntityFormComponent implements OnChanges, OnInit {

  @Input() controls: EntityFormControl[];
  @Input() value: { [id: string]: any };

  @Output() valueChange = new EventEmitter<{ [id: string]: any }>();

  @ContentChild('controlsLayout') controlsLayout: TemplateRef<any>;

  formGroup: UntypedFormGroup;

  statusChangesSubscription: Subscription;
  valueChangesSubscription: Subscription;

  Array = Array;
  AvatarKind = AvatarKind;
  EntityNameEnum = EntityNameEnum;
  EntityFormControlKind = EntityFormControlKind;
  Number = Number;
  trackById = trackById;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (notFirstChange(changes.controls)) {
      this.updateFormGroup();
    }

    if (notFirstChange(changes.value) && !changes.controls) {
      this.formGroup.setValue(this.getControlsValues(), { emitEvent: false });
    }
  }

  ngOnInit() {
    this.updateFormGroup();
  }

  getControlsValues() {
    // Filter out fields that does not have control
    return filterObject(this.value, (value, id) => !!findById(this.controls, id));
  }

  updateFormGroup() {
    const controlsConfig: {} = {};

    this.controls.forEach(control => {
      const validators: ValidatorFn[] = [];

      if (control.validators) {
        control.validators.forEach(validator => {
          switch (validator.kind) {
            case EntityFormValidatorKind.Email:
              validators.push(PplValidators.email);
              break;
            case EntityFormValidatorKind.MinDate:
              validators.push(PplValidators.minDateValue(validator.date));
              break;
            case EntityFormValidatorKind.MinItems:
              validators.push(PplValidators.requiredMinItems(validator.items));
              break;
            case EntityFormValidatorKind.MinValue:
              validators.push(Validators.min(validator.value));
              break;
            case EntityFormValidatorKind.MaxDate:
              validators.push(PplValidators.maxDateValue(validator.date));
              break;
            case EntityFormValidatorKind.MaxLength:
              validators.push(Validators.maxLength(validator.length));
              break;
            case EntityFormValidatorKind.MaxValue:
              validators.push(Validators.max(validator.value));
              break;
            case EntityFormValidatorKind.Required:
              if (control.kind === EntityFormControlKind.Checkbox) {
                validators.push(Validators.requiredTrue);
              } else {
                validators.push(Validators.required);
              }
              break;
            case EntityFormValidatorKind.RequiredOneOf:
              validators.push(formControl => {
                if (validator.controlIds.every(controlId => formControl.parent && !formControl.parent.get(controlId).value)) {
                  return validator.error;
                }

                return null;
              });
              break;
          }
        });
      }

      controlsConfig[control.id] = [{
        value: null,
        disabled: control.disabled
      }, validators];
    });

    const formGroup = this.formBuilder.group(controlsConfig);

    unsubscribe(this.statusChangesSubscription);
    unsubscribe(this.valueChangesSubscription);

    formGroup.reset(this.getControlsValues());

    this.statusChangesSubscription = formGroup.statusChanges.subscribe(status => {

    });

    this.valueChangesSubscription = formGroup.valueChanges.subscribe(() => {
      // Note: getRawValue() call includes disabled fields
      this.valueChange.emit({
        ...this.value,
        ...formGroup.getRawValue()
      });
    });

    this.formGroup = formGroup;
  }

  isControlRequired(control: EntityFormControl) {
    return control.validators ? control.validators.some(validator => validator.kind === EntityFormValidatorKind.Required) : false;
  }

  isValid() {
    const result = isFormValid(this.formGroup);

    this.changeDetectorRef.detectChanges();

    return result;
  }

}
