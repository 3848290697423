import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { AccountSocialRelationEntity, ContactSocialRelationEntity } from '@ppl/graphql-space-api';
import { formatURL, MemoizeLast, trackById } from '@ppl/utils';

@Component({
  selector: 'ppl-card-social',
  templateUrl: './card-social.component.html',
  styleUrls: ['./card-social.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardSocialComponent {

  @Input() entity: AccountSocialRelationEntity | ContactSocialRelationEntity;

  trackById = trackById;

  @MemoizeLast<CardSocialComponent>(['entity'])
  get links(): SocialLink[] {
    return [{
      id: 'facebook-social',
      url: this.entity.facebookUrl ? formatURL(this.entity.facebookUrl) : null
    }, {
      id: 'twitter-social',
      url: this.entity.twitterUrl ? formatURL(this.entity.twitterUrl) : null
    }, {
      id: 'linkedin-social',
      url: this.entity.linkedinUrl ? formatURL(this.entity.linkedinUrl) : null
    }, {
      id: 'youtube-social',
      url: this.entity.youtubeUrl ? formatURL(this.entity.youtubeUrl) : null
    }].filter(link => link.url);
  }

}

interface SocialLink {
  id: string;
  url: string;
}
