<ppl-card-medium>
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="EntityNameEnum.Opportunity"
                [kind]="AvatarKind.Medium"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    {{entity.name}}
  </ng-template>

  <ng-template #descriptionTemplate>
    <ppl-icon svgIcon="entity-phantom-contact-16"
              width="12px"
              height="12px"></ppl-icon>

    {{entity.owner?.name}}
  </ng-template>

  <ng-template #actionTemplate>
    <div *ngIf="entity.status === OpptyStatusEnum.Open"
         class="status"
         i18n="@@EnumOpptyStatusEnum_Open">Open</div>

    <div *ngIf="entity.status === OpptyStatusEnum.Won"
         class="status won"
         i18n="@@EnumOpptyStatusEnum_Won">Won</div>

    <div *ngIf="entity.status === OpptyStatusEnum.Lost"
         class="status lost"
         i18n="@@EnumOpptyStatusEnum_Lost">Lost</div>

    <div class="value">{{entity.value.valueForeign | pplCurrency:valueCurrencyCode}}</div>
  </ng-template>
</ppl-card-medium>