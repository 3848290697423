import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthModule } from '@ppl/auth';
import { CoreModule } from '@ppl/core';
import { I18nModule } from '@ppl/i18n';
import { PplIconModule } from '@ppl/ui/icon';
import { PplSlideToggleModule } from '@ppl/ui/slide-toggle';
import { OutlookModule } from '../outlook/outlook.module';
import { TrackingComposeComponent } from './components/tracking-compose/tracking-compose.component';
import { TrackingReadComponent } from './components/tracking-read/tracking-read.component';
import { TrackingService } from './services/tracking.service';

@NgModule({
  imports: [
    AuthModule,
    CoreModule,
    CommonModule,
    HttpClientModule,
    I18nModule,
    OutlookModule,
    PplIconModule,
    PplSlideToggleModule
  ],
  declarations: [
    TrackingComposeComponent,
    TrackingReadComponent
  ],
  exports: [
    TrackingComposeComponent,
    TrackingReadComponent
  ],
  providers: [
    TrackingService
  ]
})
export class TrackingModule {}
