import { sortByKey } from '@ppl/utils';
import { EntityFormControlKind } from '../../../entity-form/domain/controls';
import type { EntityFormApiFieldTypeAdapter } from '../entity-form-api';

export const SalesUnitFieldTypeAdapter: EntityFormApiFieldTypeAdapter = {
  id: 'sales_unit',
  getControl({ entities, fieldDescriptor, formField, userClient }) {
    return {
      id: fieldDescriptor.id,
      label: formField?.label ?? fieldDescriptor.name,
      options: entities.salesUnits.map(salesUnit => ({
        deleted: salesUnit.isDeleted || ![...userClient.unitManagerIds, ...userClient.unitMemberIds].includes(salesUnit.id),
        label: salesUnit.name,
        value: salesUnit.id
      })).sort(sortByKey('label')),
      kind: EntityFormControlKind.Dropdown
    };
  }
};
