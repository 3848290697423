import { Injectable } from '@angular/core';
import { ActionsUtilsService } from '@ppl/core';
import { AccountFieldsEnum } from '@ppl/domain';
import { FirebaseEventsEnum, FirebaseService } from '@ppl/firebase';
import type { AccountEntity} from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { getSpaceAccountTypes } from '@ppl/space';
import type { PplSelectOption } from '@ppl/ui/select';
import { sortByKey } from '@ppl/utils';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { RecentRecordsService } from '../../../services/recent-records.service';
import { CoreStore } from '../../../store/core.store';
import { DashboardItemKind } from '../../dashboard/domain/dashboard';
import { getDashboardData } from '../../dashboard/store/dashboard.selectors';
import { BodyParserService } from '../../shared/body-parser/services/body-parser.service';
import { EmailService } from '../../shared/email/services/email.service';
import { EntityFormApiService } from '../../shared/entity-form-api/services/entity-form-api.service';
import type { EntityFormControl, EntityFormEntityControl } from '../../shared/entity-form/domain/controls';
import { EntityFormControlKind } from '../../shared/entity-form/domain/controls';
import { EntityFormValidatorKind } from '../../shared/entity-form/domain/validators';
import { SuccessToastComponent } from '../../shared/entity/components/success-toast/success-toast.component';
import type { SuccessToastData} from '../../shared/entity/domain/success-toast';
import { SuccessToastOperation } from '../../shared/entity/domain/success-toast';
import { EntityService } from '../../shared/entity/services/entity.service';
import { OutlookService } from '../../shared/outlook/services/outlook.service';
import { ToastService } from '../../shared/toast/services/toast.service';
import type { AccountFormData, AccountFormDataOutlookItem, AccountFormValue } from '../domain/account-form';
import { gqlCreateAccountMutation, gqlFetchAccountFormData, gqlUpdateAccountMutation } from './account-form.graphql';

@Injectable()
export class AccountFormService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private bodyParserService: BodyParserService,
    private emailService: EmailService,
    private entityService: EntityService,
    private entityFormApiService: EntityFormApiService,
    private firebaseService: FirebaseService,
    private i18nService: I18nService,
    private outlookService: OutlookService,
    private recentRecordsService: RecentRecordsService,
    private store: CoreStore,
    private toastService: ToastService
  ) {}

  fetchData(options: FetchDataOptions) {
    return combineLatest([
      this.entityService.fetchEntity<AccountEntity>({
        entityType: EntityNameEnum.Account,
        id: options.id,
        query: gqlFetchAccountFormData
      }),
      this.entityService.fetchContactName(options.relatedContactId),
      this.entityService.fetchLeadName(options.relatedLeadId),
      this.entityService.fetchOpportunityName(options.relatedOpportunityId),
      this.entityFormApiService.fetchContext(EntityNameEnum.Account, {
        entities: [EntityNameEnum.AccountType, EntityNameEnum.SalesUnit]
      }),
      this.fetchOutlookItem()
    ]).pipe(
      map(([ entity, relatedContact, relatedLead, relatedOpportunity, context, outlookItem ]) => {
        if (options.id && !entity) {
          return null;
        }

        const result: AccountFormData = {
          entity,
          context,
          outlookItem,
          relatedContact,
          relatedEmail: options.relatedEmail,
          relatedLead,
          relatedOpportunity
        };

        return result;
      })
    );
  }

  getControls(data: AccountFormData, formId: string) {
    const accountTypes = this.getAccountTypes();

    const createForm = !data.entity;
    const multipleTypes = accountTypes.length > 1;
    const outlookParsedBody = data.outlookItem.parsedBody;
    const outlookHasAttachments = this.outlookService.hasAttachments();

    const controls: EntityFormControl[] = [
      multipleTypes && {
        id: 'accountTypeId',
        label: this.i18nService.translate('Account_type'),
        options: accountTypes.map<PplSelectOption>(accountType => ({
          deleted: accountType.isDeleted || accountType.isReadonly,
          label: accountType.name,
          value: accountType.id
        })).sort(sortByKey('label')),
        kind: EntityFormControlKind.Dropdown,
        validators: [{
          kind: EntityFormValidatorKind.Required
        }]
      },
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.NameField,
        forceOnForm: true,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.UnitIdField,
        formId
      }),
      {
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId: AccountFieldsEnum.OwnerIdField,
          forceOnForm: true,
          formId
        }) as EntityFormEntityControl,
        disabled: true
      },
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.AccountClassField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.HomePageField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.Email1Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.Email2Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.Email3Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.Email4Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.Email5Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.Phone1Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.Phone2Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.Phone3Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.Phone4Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.Phone5Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.IndustriesIdField,
        formId
      }),
      createForm && outlookParsedBody.facebookUrl && {
        id: 'facebookUrl',
        label: this.i18nService.translate('Facebook_profile'),
        kind: EntityFormControlKind.Input
      },
      createForm && outlookParsedBody.linkedInUrl && {
        id: 'linkedInUrl',
        label: this.i18nService.translate('LinkedIn_profile'),
        kind: EntityFormControlKind.Input
      },
      createForm && outlookParsedBody.twitterUrl && {
        id: 'twitterUrl',
        label: this.i18nService.translate('Twitter_profile'),
        kind: EntityFormControlKind.Input
      },
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AccountFieldsEnum.CommentsField,
        formId
      }),
      createForm && outlookHasAttachments && {
        id: 'saveAttachments',
        label: this.i18nService.translate('Save_email_attachments_to_new_account'),
        inlineLabel: true,
        kind: EntityFormControlKind.Checkbox
      },
      ...this.entityFormApiService.getCustomFieldIds({
        context: data.context,
        formId
      }).map(fieldId => ({
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId,
          formId
        }),
        data: {
          customField: true
        }
      }))
    ];

    return controls.filter(control => control);
  }

  getValue(data: AccountFormData, formId: string): AccountFormValue {
    const value = this.entityFormApiService.getValue({
      context: data.context,
      entity: data.entity,
      fieldIds: [
        { fieldId: AccountFieldsEnum.NameField, forceOnForm: true },
        AccountFieldsEnum.UnitIdField,
        { fieldId: AccountFieldsEnum.OwnerIdField, forceOnForm: true },
        AccountFieldsEnum.AccountClassField,
        AccountFieldsEnum.HomePageField,
        AccountFieldsEnum.Email1Field,
        AccountFieldsEnum.Email2Field,
        AccountFieldsEnum.Email3Field,
        AccountFieldsEnum.Email4Field,
        AccountFieldsEnum.Email5Field,
        AccountFieldsEnum.Phone1Field,
        AccountFieldsEnum.Phone2Field,
        AccountFieldsEnum.Phone3Field,
        AccountFieldsEnum.Phone4Field,
        AccountFieldsEnum.Phone5Field,
        AccountFieldsEnum.IndustriesIdField,
        AccountFieldsEnum.CommentsField,
        ...this.entityFormApiService.getCustomFieldIds({
          context: data.context,
          formId
        })
      ],
      formId
    });

    if (data.entity) {
      return {
        ...value,
        accountTypeId: formId
      };
    } else {
      return {
        ...value,
        ...this.getPersonValue(data),
        [AccountFieldsEnum.HomePageField]: data.outlookItem.parsedBody.homePage,
        [AccountFieldsEnum.Phone1Field]: data.outlookItem.parsedBody.phone,
        accountTypeId: formId,
        facebookUrl: data.outlookItem.parsedBody.facebookUrl,
        linkedInUrl: data.outlookItem.parsedBody.linkedInUrl,
        twitterUrl: data.outlookItem.parsedBody.twitterUrl,
        saveAttachments: false
      };
    }
  }

  createEntity(value: AccountFormValue, data: AccountFormData) {
    return this.actionsUtilsService.doAction(this.entityFormApiService.saveEntity({
      input: {
        ...this.entityFormApiService.getInput({
          context: data.context,
          value
        }),
        accountTypeId: value.accountTypeId,
        socialMedia: (value.facebookUrl || value.linkedInUrl || value.twitterUrl) ? {
          facebookUrl: value.facebookUrl,
          linkedinUrl: value.linkedInUrl,
          twitterUrl: value.twitterUrl
        } : null
      },
      mutation: gqlCreateAccountMutation
    }).pipe(
      switchMap(result => {
        const accountId = result.createAccount.account.id;

        return of(null).pipe(
          switchMap(() => {
            if (data.relatedContact) {
              return this.entityService.createContactAccountRelation({
                accountId,
                contactId: data.relatedContact.id
              });
            } else if (data.relatedLead || data.relatedOpportunity) {
              return this.entityService.createLeadOpptyAccountRelation({
                accountId,
                leadOpptyId: data.relatedLead?.id || data.relatedOpportunity?.id
              });
            }

            return of(null);
          }),
          switchMap(() => {
            if (value.saveAttachments) {
              return this.emailService.saveAttachments({
                relations: [{
                  entityId: accountId,
                  entityType: EntityNameEnum.Account
                }],
                silent: true
              });
            }

            return of(null);
          }),
          tap(() => {
            this.toastService.open<SuccessToastData>({
              content: {
                component: SuccessToastComponent,
                data: {
                  entityType: EntityNameEnum.Account,
                  id: accountId,
                  operation: SuccessToastOperation.Create
                }
              }
            });

            this.recentRecordsService.add(accountId);

            const hasRelation = data.relatedContact || data.relatedLead || data.relatedOpportunity;

            this.firebaseService.logEvent(hasRelation ? FirebaseEventsEnum.Create : FirebaseEventsEnum.CreateFromEmail, {
              entity: 'account'
            });
          })
        );
      })
    ));
  }

  updateEntity(value: AccountFormValue, data: AccountFormData) {
    return this.actionsUtilsService.doAction(this.entityFormApiService.saveEntity({
      input: {
        ...this.entityFormApiService.getInput({
          entity: data.entity,
          context: data.context,
          value
        }),
        accountTypeId: value.accountTypeId,
        id: data.entity.id
      },
      mutation: gqlUpdateAccountMutation
    }).pipe(
      tap(() => {
        this.toastService.open<SuccessToastData>({
          content: {
            component: SuccessToastComponent,
            data: {
              entityType: EntityNameEnum.Account,
              id: data.entity.id,
              operation: SuccessToastOperation.Update
            }
          }
        });

        this.firebaseService.logEvent(FirebaseEventsEnum.Update, {
          entity: 'account'
        });
      })
    ));
  }

  getDefaultFormId() {
    return this.getAccountTypes().filter(accountType => !accountType.isDeleted && !accountType.isReadonly)[0]?.id || null;
  }

  private fetchOutlookItem() {
    return combineLatest([
      this.outlookService.getHtmlBody().pipe(
        map(htmlBody => this.bodyParserService.parse(htmlBody))
      )
    ]).pipe(
      map(([ parsedBody ]) => {
        const result: AccountFormDataOutlookItem = {
          parsedBody
        };

        return result;
      })
    );
  }

  private getAccountTypes() {
    return this.store.get(getSpaceAccountTypes).filter(accountType => accountType.isPublished).sort(sortByKey('name'));
  }

  private getPersonValue(data: AccountFormData) {
    const dashboardData = this.store.get(getDashboardData).value;

    let personName: null | string = null;
    let personEmail: null | string = null;

    if (dashboardData) {
      if (dashboardData.sender.kind === DashboardItemKind.Other && !data.relatedEmail) {
        personName = dashboardData.sender.person.name;
        personEmail = dashboardData.sender.person.email;
      } else {
        for (let i = 0; i < dashboardData.recipients.length; i++) {
          if (dashboardData.recipients[i].kind === DashboardItemKind.Other) {
            if (!data.relatedEmail || dashboardData.recipients[i].person.email === data.relatedEmail) {
              personName = dashboardData.recipients[i].person.name;
              personEmail = dashboardData.recipients[i].person.email;
              break;
            }
          }
        }
      }
    }

    if (personName) {
      return {
        [AccountFieldsEnum.NameField]: personName,
        [AccountFieldsEnum.Email1Field]: personEmail || null
      };
    } else if (personEmail) {
      return {
        [AccountFieldsEnum.Email1Field]: personEmail
      };
    }

    return null;
  }

}

export interface FetchDataOptions {
  id: string | null;
  relatedContactId: string | null;
  relatedEmail: string | null;
  relatedLeadId: string | null;
  relatedOpportunityId: string | null;
}
