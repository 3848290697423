import type { OnInit} from '@angular/core';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fetchAsyncValue } from '@ppl/core';
import type { AsyncValue } from '@ppl/store';
import { AsyncState } from '@ppl/store';
import type { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocationService } from '../../../../services/location.service';
import { mergeFormValue } from '../../../shared/entity-form-api/domain/merge-form-value';
import { EntityFormComponent } from '../../../shared/entity-form/components/entity-form/entity-form.component';
import type { EntityFormControl } from '../../../shared/entity-form/domain/controls';
import type { CanLeaveGuardComponent } from '../../../shared/entity-form/router/can-leave-entity-form.service';
import type { OpportunityFormData, OpportunityFormValue } from '../../domain/opportunity-form';
import { OpportunityFormService } from '../../services/opportunity-form.service';

@Component({
  selector: 'ppl-opportunity-form',
  templateUrl: './opportunity-form.component.html',
  styleUrls: ['./opportunity-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunityFormComponent implements OnInit, CanLeaveGuardComponent {

  @ViewChild(EntityFormComponent) entityForm: EntityFormComponent;

  data$: Observable<AsyncValue<OpportunityFormData>>;

  AsyncState = AsyncState;

  controls: EntityFormControl[];
  valid = true;

  value: OpportunityFormValue;
  valueChanged = false;

  id = this.route.snapshot.paramMap.get('id');

  customFieldsVisible = true;

  accountId = this.route.snapshot.queryParamMap.get('accountId');
  contactId = this.route.snapshot.queryParamMap.get('contactId');

  constructor(
    private locationService: LocationService,
    private opportunityFormService: OpportunityFormService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.data$ = fetchAsyncValue(this.opportunityFormService.fetchData({
      id: this.id,
      relatedAccountId: this.accountId,
      relatedContactId: this.contactId
    }).pipe(
      tap(data => {
        if (data) {
          this.value = this.opportunityFormService.getValue(data, data.entity ?
            this.opportunityFormService.getFormIdByPipelineId(data.entity.step.pipelineId)
            : this.opportunityFormService.getDefaultFormId());
          this.controls = this.opportunityFormService.getControls(data, this.opportunityFormService.getFormIdByPipelineId(this.value.pipelineId));
        }
      })
    ));
  }

  save(data: OpportunityFormData) {
    if (data.entity) {
      return this.opportunityFormService.updateEntity(this.value, data);
    } else {
      return this.opportunityFormService.createEntity(this.value, data);
    }
  }

  onValueChange(data: OpportunityFormData, value: OpportunityFormValue) {
    if (value.pipelineId !== this.value.pipelineId) {
      const prevFormInitValue = this.opportunityFormService.getValue(data, this.opportunityFormService.getFormIdByPipelineId(this.value.pipelineId));

      this.value = mergeFormValue(prevFormInitValue, this.value, this.opportunityFormService.getValue(data, this.opportunityFormService.getFormIdByPipelineId(value.pipelineId)));
      this.controls = this.opportunityFormService.getControls(data, this.opportunityFormService.getFormIdByPipelineId(value.pipelineId));
    } else {
      this.value = value;
    }

    this.valueChanged = true;
  }

  onSaveClick(data: OpportunityFormData) {
    this.valid = this.entityForm.isValid();

    if (!this.valid) {
      this.customFieldsVisible = true;

      return;
    }

    this.save(data).subscribe(() => {
      this.valueChanged = false;
      this.locationService.goBack();
    });
  }

  onCancelClick() {
    this.locationService.goBack();
  }

}
