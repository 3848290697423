<ppl-dashboard-card-list icon="menu-contact"
                         [items]="items"
                         [name]="(items.length === 2) ? 'Me & colleague' : ('Me & ' + (items.length - 1) + ' colleagues')">
  <ng-template #cardTemplate
               let-item
               let-active="active">
    <ppl-user-card-small *ngIf="item.kind === DashboardItemKind.User"
                         [direction]="OutlookItemDirection.Received"
                         [active]="active"></ppl-user-card-small>

    <ppl-client-card-small *ngIf="item.kind === DashboardItemKind.Client"
                           [entity]="item.entity"
                           [active]="active"></ppl-client-card-small>
  </ng-template>
</ppl-dashboard-card-list>