import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { AccountEntity, ContactEntity, LeadEntity} from '@ppl/graphql-space-api';
import { EntityNameEnum, EntityPermsEnum, LeadStatusEnum } from '@ppl/graphql-space-api';
import { hasSpaceEntityPermission } from '@ppl/space';
import { MemoizeLast } from '@ppl/utils';
import { LocationService } from '../../../../../services/location.service';
import { CoreStore } from '../../../../../store/core.store';
import { TypeToRelationOption } from '../../domain/entity-card';

@Component({
  selector: 'ppl-leads-cards',
  templateUrl: './leads-cards.component.html',
  styleUrls: ['./leads-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeadsCardsComponent {

  @Input() entities: LeadEntity[];
  @Input() parentEntity: AccountEntity | ContactEntity;

  filterValue: LeadStatusEnum | null = null;

  LeadStatusEnum = LeadStatusEnum;

  @MemoizeLast<LeadsCardsComponent>(['entities'])
  get openEntities() {
    return this.entities.filter(entity => entity.status === LeadStatusEnum.Open);
  }

  @MemoizeLast<LeadsCardsComponent>(['entities'])
  get lostEntities() {
    return this.entities.filter(entity => entity.status === LeadStatusEnum.Lost);
  }

  @MemoizeLast<LeadsCardsComponent>(['entities', 'filterValue'])
  get filteredEntities() {
    if (this.filterValue === LeadStatusEnum.Open) {
      return this.openEntities;
    } else if (this.filterValue === LeadStatusEnum.Lost) {
      return this.lostEntities;
    }

    return this.entities;
  }

  @MemoizeLast<LeadsCardsComponent>(['parentEntity'])
  get displayCreate() {
    return this.store.get(hasSpaceEntityPermission(EntityNameEnum.Lead, EntityPermsEnum.Create));
  }

  constructor(
    private locationService: LocationService,
    private store: CoreStore
  ) {}

  onCreateClick() {
    this.locationService.createLead({
      [TypeToRelationOption[this.parentEntity.__typename]]: this.parentEntity.id
    });
  }

  onFilterValueChange(value: LeadStatusEnum) {
    this.filterValue = value;
  }

  onCardClick(entity: LeadEntity) {
    this.locationService.openLead(entity.id);
  }

}
