import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { fetchAsyncValue } from '@ppl/core';
import { I18nService } from '@ppl/i18n';
import { getSpaceUserId } from '@ppl/space';
import type { AsyncValue } from '@ppl/store';
import { AsyncState } from '@ppl/store';
import type { TFormGroup } from '@ppl/ui/form';
import { TFormBuilder } from '@ppl/ui/form';
import type { PplSelectOption } from '@ppl/ui/select';
import { isFormValid, MemoizeLast } from '@ppl/utils';
import type { Observable } from 'rxjs';
import { LocationService } from '../../../../services/location.service';
import { CoreStore } from '../../../../store/core.store';
import type { EntityTreeItem } from '../../../shared/entity-tree/domain/entity-tree';
import { UseTemplateData } from '../../domain/use-template';
import { UseTemplateService } from '../../services/use-template.service';

@Component({
  selector: 'ppl-use-template',
  templateUrl: './use-template.component.html',
  styleUrls: ['./use-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UseTemplateComponent implements OnInit {

  formGroup: TFormGroup<UseTemplateFormValue>;

  data$: Observable<AsyncValue<UseTemplateData>>;

  ownerFilterOptions: PplSelectOption[] = [{
    label: this.i18nService.translate('My'),
    value: OwnerFilter.My
  }, {
    label: this.i18nService.translate('All'),
    value: OwnerFilter.All
  }];

  ownerFilter = OwnerFilter.All;
  nameFilter = '';

  AsyncState = AsyncState;

  constructor(
    private formBuilder: TFormBuilder,
    private i18nService: I18nService,
    private locationService: LocationService,
    private store: CoreStore,
    private useTemplateService: UseTemplateService
  ) {
    this.formGroup = this.formBuilder.group<UseTemplateFormValue>({
      templateId: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.data$ = fetchAsyncValue(this.useTemplateService.fetchData());
  }

  @MemoizeLast<UseTemplateComponent>()
  getTreeItems(data: UseTemplateData, ownerFilter: OwnerFilter, nameFilter: string) {
    const emailTemplates = data.emailTemplates.filter(emailTemplate => {
      return (!nameFilter || emailTemplate.name.toLowerCase().includes(nameFilter.toLowerCase()))
        && (ownerFilter === OwnerFilter.All || (ownerFilter === OwnerFilter.My && emailTemplate.ownerId === this.store.get(getSpaceUserId)));
    });

    function getItems(parentId: string) {
      return [
        ...data.emailTemplateFolders,
        ...emailTemplates
      ].filter(entity => {
        return (entity.__typename === 'EmailTemplateEntity' && entity.emailTemplateFolderId === parentId)
          || (entity.__typename === 'EmailTemplateFolderEntity' && entity.parentEmailTemplateFolderId === parentId);
      }).map<EntityTreeItem>(entity => ({
        id: entity.id,
        name: entity.name,
        children: (entity.__typename === 'EmailTemplateFolderEntity') ? getItems(entity.id) : null
      })).filter(treeItem => {
        if (nameFilter) {
          if (treeItem.children?.length === 0) {
            return false;
          }
        }

        return true;
      });
    }

    return getItems(null);
  }

  onOwnerFilterChange(ownerFilter: OwnerFilter) {
    this.ownerFilter = ownerFilter;
  }

  onNameFilterChange(nameFilter: string) {
    this.nameFilter = nameFilter;
  }

  onUseClick() {
    if (!isFormValid(this.formGroup)) {
      return;
    }

    const templateId = this.formGroup.get('templateId').value;

    this.useTemplateService.useTemplate(templateId).subscribe(() => {
      this.locationService.goBack();
    });
  }

  onCancelClick() {
    this.locationService.goBack();
  }

}

enum OwnerFilter {
  My = 'My',
  All = 'All'
}

interface UseTemplateFormValue {
  templateId: string | null;
}
