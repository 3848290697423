import type { PplMultipleSelectOption } from '@ppl/ui/multiple-select';
import { map } from 'rxjs/operators';
import { EntityFormControlKind } from '../../../entity-form/domain/controls';
import type { EntityFormApiFieldTypeAdapter } from '../entity-form-api';

export const MultiselectCheckboxFieldTypeAdapter: EntityFormApiFieldTypeAdapter = {
  id: 'multiselect_checkbox',
  getControl({ entityType, fetchValueOptions, fieldDescriptor, formField }) {
    return {
      id: fieldDescriptor.id,
      label: formField?.label ?? fieldDescriptor.name,
      options: fetchValueOptions({
        entityType,
        fieldDescriptor
      }).pipe(
        map(options => {
          return options.map<PplMultipleSelectOption>(option => ({
            deleted: option.isDeleted,
            label: option.label,
            value: option.value
          }));
        })
      ),
      kind: EntityFormControlKind.MultipleDropdown
    };
  }
};
