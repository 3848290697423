import { gql } from '@ppl/utils';

export const gqlFetchAccountFormData = gql`
  query fetchAccountFormData($id: ID!) {
    entities {
      account {
        getById(entityId: $id) {
          accountClass
          accountTypeId
          comments
          customFields
          email1
          email2
          email3
          email4
          email5
          homePage
          id
          industryId
          name
          ownerId
          phone1
          phone2
          phone3
          phone4
          phone5
          unitId
        }
      }
    }
  }
`;

export const gqlCreateAccountMutation = gql`
  mutation createAccount($input: CreateAccountInput!, $validationLevel: [ValidationLevel!]) {
    createAccount(input: $input, validationLevel: $validationLevel) {
      account {
        id
      }
    }
  }
`;

export const gqlUpdateAccountMutation = gql`
  mutation updateAccount($input: UpdateAccountInput!, $validationLevel: [ValidationLevel!]) {
    updateAccount(input: $input, validationLevel: $validationLevel) {
      clientMutationId
    }
  }
`;
