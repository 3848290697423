import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fetchAsyncValue } from '@ppl/core';
import type { UpdateAppointmentInput } from '@ppl/graphql-space-api';
import type { AsyncValue } from '@ppl/store';
import { AsyncState } from '@ppl/store';
import type { Observable} from 'rxjs';
import { merge, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import type { AppointmentData } from '../../domain/appointment';
import { AppointmentService } from '../../services/appointment.service';

@Component({
  selector: 'ppl-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppointmentComponent implements OnInit {

  data$: Observable<AsyncValue<AppointmentData>>;

  updateData$ = new Subject<AppointmentData>();

  AsyncState = AsyncState;

  constructor(
    private appointmentService: AppointmentService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.data$ = this.route.params.pipe(
      switchMap(params => {
        return fetchAsyncValue(merge(
          this.appointmentService.fetchData(params.id),
          this.updateData$.asObservable()
        ));
      })
    );
  }

  onFieldChange(data: AppointmentData, value: Pick<UpdateAppointmentInput, 'location'>) {
    this.updateData$.next({
      ...data,
      entity: {
        ...data.entity,
        ...value
      }
    });

    this.appointmentService.updateField(data.entity.id, value).subscribe({
      error: () => {
        this.updateData$.next(data);
      }
    });
  }

}
