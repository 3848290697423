import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@ppl/core';
import { PplDialogModule } from '@ppl/ui/dialog';
import { PplInputModule } from '@ppl/ui/input';
import { PplSelectModule } from '@ppl/ui/select';
import { DateTimeModule } from '../date-time/date-time.module';
import { ToastModule } from '../toast/toast.module';
import { MoveTaskComponent } from './components/move-task/move-task.component';
import { SetDueDateComponent } from './components/set-due-date/set-due-date.component';
import { SetReminderComponent } from './components/set-reminder/set-reminder.component';
import { ActivityService } from './services/activity.service';
import { ReminderService } from './services/reminder.service';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    DateTimeModule,
    PplDialogModule,
    PplInputModule,
    PplSelectModule,
    ReactiveFormsModule,
    ToastModule
  ],
  declarations: [
    MoveTaskComponent,
    SetDueDateComponent,
    SetReminderComponent
  ],
  providers: [
    ActivityService,
    ReminderService
  ]
})
export class ActivityModule {}
