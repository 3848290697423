<ppl-card-small [disabled]="true">
  <ng-template #iconTemplate>
    <ppl-icon class="icon"
              svgIcon="add-recipient"
              width="16px"
              height="16px"></ppl-icon>
  </ng-template>

  <ng-template #nameTemplate>
    <ng-container i18n="@@Add_contacts_to_recipients">Add contacts to recipients</ng-container>
  </ng-template>

  <ng-template #actionTemplate>
    <div class="action"
         pplPopover
         pplPopoverAlignEnd
         [pplPopoverMenu]="addRecipientMenuItems"
         (pplPopoverMenuItemClick)="onAddRecipientMenuItemClick($event)">
      <ppl-icon svgIcon="entity-action-add-blue"
                width="20px"
                height="20px"></ppl-icon>
    </div>
  </ng-template>
</ppl-card-small>
