import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthModule } from '@ppl/auth';
import { I18nModule } from '@ppl/i18n';
import { PplSelectModule } from '@ppl/ui/select';
import { PplTimeInputModule } from '@ppl/ui/time-input';
import { CurrencyInputComponent } from './components/currency-input/currency-input.component';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    I18nModule,
    PplSelectModule,
    PplTimeInputModule
  ],
  declarations: [
    CurrencyInputComponent
  ],
  exports: [
    CurrencyInputComponent
  ]
})
export class CurrencyModule {}
