export enum OutlookItemMode {
  Read = 'Read',
  Compose = 'Compose'
}

export enum OutlookItemType {
  Appointment = 'Appointment',
  Message = 'Message'
}

export enum OutlookItemDirection {
  Received = 'Received',
  Sent = 'Sent'
}

export interface OutlookPerson {
  email: string;
  name: string;
}

export interface OutlookAttachment {
  content: string | null;
  fileName: string;
  id: string;
  size: number;
}
