import { Injectable } from '@angular/core';
import { ActionsUtilsService } from '@ppl/core';
import { FirebaseEventsEnum, FirebaseService } from '@ppl/firebase';
import type { Query, UpdateAccountInput } from '@ppl/graphql-space-api';
import { SpaceService } from '@ppl/space';
import { map, tap } from 'rxjs/operators';
import { CacheService } from '../../../services/cache.service';
import type { AccountData } from '../domain/account';
import { gqlUpdateAccountMutation } from './account-form.graphql';
import { gqlFetchAccountData } from './account.graphql';

@Injectable()
export class AccountService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private cacheService: CacheService,
    private firebaseService: FirebaseService,
    private spaceService: SpaceService
  ) {}

  fetchData(id: string) {
    return this.spaceService.gqlClient.query<Query>({
      query: gqlFetchAccountData,
      fetchPolicy: 'no-cache',
      variables: {
        entityId: id
      }
    }).pipe(map(({ data }) => {
      const entityData = data.screens.extractViews.account;

      if (!entityData) {
        return null;
      }

      const result: AccountData = {
        entity: entityData.entity,
        relatedActivities: [
          ...entityData.plannedActivities.edges.map(edge => edge.node.appointment || edge.node.task).filter(entity => !!entity),
          ...entityData.unplannedTasks.edges.map(edge => edge.node.task).filter(entity => !!entity)
        ],
        relatedContacts: entityData.contacts.edges.map(edge => edge.node.contact).filter(entity => !!entity),
        relatedLeads: entityData.leads.edges.map(edge => edge.node.lead).filter(entity => !!entity),
        relatedOpportunities: entityData.opportunities.edges.map(edge => edge.node.oppty).filter(entity => !!entity)
      };

      return result;
    }));
  }

  updateField(id: string, value: Omit<UpdateAccountInput, 'id'>) {
    const input: UpdateAccountInput = {
      id,
      ...value
    };

    return this.actionsUtilsService.doAction(this.spaceService.gqlClient.mutate({
      mutation: gqlUpdateAccountMutation,
      variables: {
        input
      }
    }).pipe(
      tap(() => {
        this.cacheService.reset();

        this.firebaseService.logEvent(FirebaseEventsEnum.Update, {
          entity: 'account'
        });
      })
    ));
  }

}
