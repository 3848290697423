import { EntityFormControlKind } from '../../../entity-form/domain/controls';
import type { EntityFormApiFieldTypeAdapter } from '../entity-form-api';

export const CheckboxFieldTypeAdapter: EntityFormApiFieldTypeAdapter = {
  id: 'checkbox',
  getControl({ fieldDescriptor, formField }) {
    return {
      id: fieldDescriptor.id,
      label: formField?.label ?? fieldDescriptor.name,
      inlineLabel: true,
      kind: EntityFormControlKind.Checkbox
    };
  }
};
