import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@ppl/auth';
import { CoreModule } from '@ppl/core';
import { PplInputModule } from '@ppl/ui/input';
import { EntityControlItemModule } from '../shared/entity-control-item/entity-control-item.module';
import { EntityModule } from '../shared/entity/entity.module';
import { OutlookModule } from '../shared/outlook/outlook.module';
import { PageModule } from '../shared/page/page.module';
import { ToastModule } from '../shared/toast/toast.module';
import { AddRecipientComponent } from './components/add-recipient/add-recipient.component';
import { AddRecipientService } from './services/add-recipient.service';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    CoreModule,
    EntityModule,
    EntityControlItemModule,
    OutlookModule,
    PageModule,
    PplInputModule,
    ReactiveFormsModule,
    ToastModule
  ],
  declarations: [
    AddRecipientComponent
  ],
  exports: [
    AddRecipientComponent
  ],
  providers: [
    AddRecipientService
  ]
})
export class AddRecipientModule {}
