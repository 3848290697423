<ppl-card-fields>
  <ppl-card-field label="Due Date"
                  i18n-label="@@Due_Date">
    <span *ngIf="entity.dueDate"
          [class.overdue]="isOverdue">{{entity.dueDate | pplDate}}</span>

    <ng-container *ngIf="!entity.dueDate">
      <div *ngIf="entity.instancePermissions.includes(EntityPermsEnum.Write); else noDueDate"
           class="set"
           i18n="@@Set"
           (click)="onSetClick()">Set</div>

      <ng-template #noDueDate>
        <ng-container i18n="@@None">None</ng-container>
      </ng-template>
    </ng-container>
  </ppl-card-field>

  <ppl-card-field label="Type"
                  i18n-label="@@Type">
    {{entity.activityType.name}}
  </ppl-card-field>

  <ppl-card-field label="Priority"
                  i18n-label="@@Priority">
    {{entity.priority | tEnum:'PriorityEnum'}}
  </ppl-card-field>
</ppl-card-fields>