import { EntityFormControlKind } from '../../../entity-form/domain/controls';
import type { EntityFormApiFieldTypeAdapter } from '../entity-form-api';

export const FloatFieldTypeAdapter: EntityFormApiFieldTypeAdapter = {
  id: 'float',
  getControl({ fieldDescriptor, formField }) {
    return {
      id: fieldDescriptor.id,
      label: formField?.label ?? fieldDescriptor.name,
      step: 0.000000001,
      kind: EntityFormControlKind.Number
    };
  }
};
