import { ApiService } from './api.service';
import type { SpaceConfig } from '../store/space.reducer';
import {
  getSpace,
  getSpaceUserId,
  shouldLoadSpaceEntities,
  shouldLoadSpaceFieldDescriptors,
  shouldLoadSpaceForms,
  shouldLoadSpaceUser
} from '../store/space.selectors';
import { SpaceStore } from '../store/space.store';
import { Injectable } from '@angular/core';
import { AuthService, getSpaceById, isExpired } from '@ppl/auth';
import { entityNamesToMarkers } from '@ppl/domain';
import type {
  ClientRepository,
  EntityPermsEnum,
  FeaturePermissions,
  FieldDescriptor,
  Form,
  Query
} from '@ppl/graphql-space-api';
import {
  EntityNameEnum
} from '@ppl/graphql-space-api';
import type { Space} from '@ppl/graphql-user-api';
import { SpaceAccessStatus } from '@ppl/graphql-user-api';
import { I18nService } from '@ppl/i18n';
import { AsyncState } from '@ppl/store';
import { PplDialogService } from '@ppl/ui/dialog';
import { gql, pascalCaseToCamelCase } from '@ppl/utils';
import type { DocumentNode } from 'graphql';
import type { Observable} from 'rxjs';
import { of, throwError } from 'rxjs';
import {
  catchError,
  map,
  mapTo,
  switchMap,
  tap
} from 'rxjs/operators';

@Injectable()
export class SpaceService {

  get gqlClient() {
    return this.apiService.getGqlClient(this.store.get(getSpace));
  }

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private dialogService: PplDialogService,
    private i18nService: I18nService,
    private store: SpaceStore
  ) { }

  canAccess(spaceId: string | null, options: CanAccessOptions = {}) {
    const signIn = (signInOptions: { queryForSpace?: boolean } = {}) => {
      return this.authService.signIn({
        checkOnly: options.authCheckOnly,
        queryForSpace: signInOptions.queryForSpace
      });
    };

    if (!spaceId) {
      return signIn({ queryForSpace: true });
    }

    return signIn({ queryForSpace: false }).pipe(switchMap(signInResult => {
      if (signInResult) {
        return this.authService.init().pipe(switchMap(userResult => {
          if (!userResult) {
            return of(false);
          }

          const space = this.store.get(getSpaceById(spaceId));

          if (!space) {
            return this.dialogService.alert({ text: `You don't have access to space [${spaceId}].` })
              .pipe(
                switchMap(() => signIn({ queryForSpace: true }))
              );
          }

          if ([SpaceAccessStatus.IdpLoginRequired, SpaceAccessStatus.TwoFARequired].includes(space.accessStatus)) {
            return signIn({ queryForSpace: true });
          }

          if (space.isSuspended || isExpired(space.subscription)) {
            return this.dialogService.alert({ text: `Access to space [${spaceId}] forbidden.` })
              .pipe(
                switchMap(() => signIn({ queryForSpace: true }))
              );
          }

          this.store.dispatch('Space_SetSpace', space);

          return this.fetchBaseConfig(space, options);
        }));
      } else {
        return of(false);
      }
    }));
  }

  fetchConfig(options: FetchLazyConfigOptions) {
    const filteredOptions: FetchLazyConfigOptions = {};

    if (options.entities) {
      options.entities.forEach(entityType => {
        if (this.store.get(shouldLoadSpaceEntities(entityType))) {
          filteredOptions.entities = [...(filteredOptions.entities || []), entityType];
        }
      });
    }

    if (options.fieldDescriptors) {
      options.fieldDescriptors.entityTypes.forEach(entityType => {
        if (this.store.get(shouldLoadSpaceFieldDescriptors(entityType))) {
          filteredOptions.fieldDescriptors = {
            ...options.fieldDescriptors,
            entityTypes: [...(filteredOptions.fieldDescriptors?.entityTypes || []), entityType]
          };
        }
      });
    }

    if (options.forms) {
      options.forms.entityTypes.forEach(entityType => {
        if (this.store.get(shouldLoadSpaceForms(entityType))) {
          filteredOptions.forms = {
            ...options.forms,
            entityTypes: [...(filteredOptions.forms?.entityTypes || []), entityType]
          };
        }
      });
    }

    if (options.user) {
      if (this.store.get(shouldLoadSpaceUser)) {
        filteredOptions.user = options.user;
      }
    }

    if (!Object.keys(filteredOptions).length) {
      return of(true);
    }

    return this.fetchConfigInternal(filteredOptions).pipe(mapTo(true));
  }

  private fetchBaseConfig(space: Space, options: FetchBaseConfigOptions & FetchLazyConfigOptions): Observable<boolean> {
    return this.fetchConfigInternal(options).pipe(
      map(() => {
        return true;
      }),
      catchError(() => {
        return this.dialogService.alert({ text: `Cannot access space [${space.id}], please try again later.` })
          .pipe(switchMap(() => this.fetchBaseConfig(space, options)));
      })
    );
  }

  private fetchConfigInternal(options: FetchBaseConfigOptions & FetchLazyConfigOptions) {
    const value: SpaceConfig = {};

    if (options.accessControl) {
      value.accessControl = { state: AsyncState.FETCHING };
    }

    if (options.entities) {
      value.entities = {};

      options.entities.forEach(entityType => {
        value.entities[entityType] = { state: AsyncState.FETCHING };

        if (entityType === EntityNameEnum.Pipeline) {
          value.allowedPipelineIds = { state: AsyncState.FETCHING };
        }
      });
    }

    if (options.fieldDescriptors) {
      value.fieldDescriptors = {};

      options.fieldDescriptors.entityTypes.forEach(entityType => {
        value.fieldDescriptors[entityType] = { state: AsyncState.FETCHING };
      });
    }

    if (options.forms) {
      value.forms = {};

      options.forms.entityTypes.forEach(entityType => {
        value.forms[entityType] = { state: AsyncState.FETCHING };
      });
    }

    if (options.user) {
      value.user = { state: AsyncState.FETCHING };
    }

    this.store.dispatch('Space_UpdateConfig', value);

    return this.gqlClient.query<Query>({
      query: gql`
        query spaceConfig {
          ${options.entityCustomNames ? `
            admin {
              spaceSettings {
                entityCustomNames {
                  entityName
                  plural {
                    current
                    default
                    isDefault
                  }
                  singular {
                    current
                    default
                    isDefault
                  }
                }
              }
            }
          ` : ''}
          ${options.fieldDescriptors ? `
            collections {
              ${((options.fieldDescriptors && options.fieldDescriptors.entityTypes) || []).map(entityType => `
                fieldDescriptors${entityType}: fieldDescriptors(entity: ${entityType}) {
                  ...FieldDescriptorFragment
                  entity
                  id
                  isDeleted
                  name
                  useLang
                  ${(options.fieldDescriptors.fetchValueOptions === true || (Array.isArray(options.fieldDescriptors.fetchValueOptions) && options.fieldDescriptors.fetchValueOptions.includes(entityType))) ? `
                    valueOptions(entity: ${entityType}) {
                      isDeleted
                      label
                      useLang
                      value
                    }
                  ` : ''}
                }
              `).join('\n')}
            }
          ` : ''}
          ${(options.entities || options.forms || options.user) ? `
            entities {
              ${options.entities?.includes(EntityNameEnum.AccountType) ? `
                accountType {
                  getAll(includeDeleted: true) {
                    id
                    isDeleted
                    isPublished
                    isReadonly
                    name
                  }
                }
              ` : ''}
              ${options.entities?.includes(EntityNameEnum.AppointmentType) ? `
                appointmentType {
                  getAll(includeDeleted: true) {
                    id
                    isDeleted
                    isPublished
                    isReadonly
                    name
                  }
                }
              ` : ''}
              ${options.entities?.includes(EntityNameEnum.Client) ? `
                client {
                  getAll(includeDeleted: true) {
                    defaultUnitId
                    email
                    id
                    isDeleted
                    name
                    unitManagerIds
                    unitMemberIds
                  }
                }
              ` : ''}
              ${options.entities?.includes(EntityNameEnum.ContactType) ? `
                contactType {
                  getAll(includeDeleted: true) {
                    id
                    isDeleted
                    isPublished
                    isReadonly
                    name
                  }
                }
              ` : ''}
              ${options.entities?.includes(EntityNameEnum.Currency) ? `
                currency {
                  getAll(includeDeleted: true) {
                    code
                    id
                    isBase
                    isDeleted
                    symbol
                  }
                }
              ` : ''}
              ${options.entities?.includes(EntityNameEnum.LeadProcess) ? `
                leadProcess {
                  getAll(includeDeleted: true) {
                    id
                    isDeleted
                    name
                    leadTypes {
                      id
                    }
                    steps {
                      id
                      name
                      sortOrder
                    }
                  }
                }
              ` : ''}
              ${options.entities?.includes(EntityNameEnum.LeadType) ? `
                leadType {
                  getAll(includeDeleted: true) {
                    id
                    isDeleted
                    isPublished
                    isReadonly
                    name
                  }
                }
              ` : ''}
              ${options.entities?.includes(EntityNameEnum.Pipeline) ? `
                pipeline {
                  getAll(includeDeleted: true) {
                    id
                    isDeleted
                    name
                    steps {
                      id
                      name
                      sortOrder
                    }
                  }
                }
              ` : ''}
              ${options.entities?.includes(EntityNameEnum.SalesUnit) ? `
                salesUnit {
                  getAll(includeDeleted: true) {
                    id
                    isDeleted
                    name
                  }
                }
              ` : ''}
              ${options.entities?.includes(EntityNameEnum.TaskType) ? `
                taskType {
                  getAll(includeDeleted: true) {
                    id
                    isDeleted
                    isPublished
                    isReadonly
                    name
                  }
                }
              ` : ''}
              ${options.forms ? `
                ${options.forms.entityTypes.map(entityType => `
                  forms${entityType}: ${entityType.toLowerCase()}Type {
                    getAll(includeDeleted: true) {
                      id
                      formEditApi {
                        items {
                          id
                          ...on FormContainer {
                            columns {
                              id
                              items {
                                ...on FormField {
                                  id
                                  label
                                  readonly
                                  useLang
                                  dropdownAutoselect
                                  inAddin
                                  validators {
                                    isEmail
                                    minValue
                                    maxValue
                                    required
                                    requiredMinItems
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      ${(entityType === EntityNameEnum.Opportunity) ? `
                        pipelineId
                      ` : ''}
                    }
                  }
                `).join('\n')}
              ` : ''}
              ${options.user ? `
                userClient: client {
                  getById(entityId: "${this.store.get(getSpaceUserId)}") {
                    defaultUnitId
                    email
                    id
                    isDeleted
                    name
                    unitManagerIds
                    unitMemberIds
                  }
                }
              ` : ''}
            }
          ` : ''}
          viewer {
            ${(options.accessControl || options.entities?.includes(EntityNameEnum.Pipeline)) ? `
              accessControl {
                ${options.accessControl?.entities ? `
                  entities {
                    ${options.accessControl.entities.map(entityType => `
                      ${pascalCaseToCamelCase(entityType)} {
                        allowedPermissions
                      }
                    `).join('\n')}
                  }
                ` : ''}
                ${options.accessControl?.features ? `
                  features {
                    ${options.accessControl.features.join('\n')}
                  }
                ` : ''}
                ${options.entities?.includes(EntityNameEnum.Pipeline) ? `
                  allowedPipelines {
                    pipeline {
                      id
                    }
                  }
                ` : ''}
              }
            ` : ''}
            id
          }
        }
        ${(options.fieldDescriptors && options.fieldDescriptors.fieldFragment) || ''}
      `,
      fetchPolicy: 'no-cache'
    }).pipe(
      tap(({ data }) => {
        value.userId = data.viewer.id;

        if (options.accessControl) {
          value.accessControl = {
            state: AsyncState.FETCHED,
            value: {}
          };

          if (options.accessControl.entities) {
            const entities: { [entityType in EntityNameEnum]?: EntityPermsEnum[] } = {};

            options.accessControl.entities.forEach(entityType => {
              entities[entityType] = data.viewer.accessControl.entities[pascalCaseToCamelCase(entityType)].allowedPermissions;
            });

            value.accessControl.value.entities = entities;
          }

          if (options.accessControl.features) {
            value.accessControl.value.features = data.viewer.accessControl.features;
          }
        }

        if (options.entities) {
          if (options.entities.includes(EntityNameEnum.AccountType)) {
            value.entities[EntityNameEnum.AccountType] = {
              state: AsyncState.FETCHED,
              value: data.entities.accountType.getAll
            };
          }

          if (options.entities.includes(EntityNameEnum.AppointmentType)) {
            value.entities[EntityNameEnum.AppointmentType] = {
              state: AsyncState.FETCHED,
              value: data.entities.appointmentType.getAll
            };
          }

          if (options.entities.includes(EntityNameEnum.Client)) {
            value.entities[EntityNameEnum.Client] = {
              state: AsyncState.FETCHED,
              value: data.entities.client.getAll
            };
          }

          if (options.entities.includes(EntityNameEnum.ContactType)) {
            value.entities[EntityNameEnum.ContactType] = {
              state: AsyncState.FETCHED,
              value: data.entities.contactType.getAll
            };
          }

          if (options.entities.includes(EntityNameEnum.Currency)) {
            value.entities[EntityNameEnum.Currency] = {
              state: AsyncState.FETCHED,
              value: data.entities.currency.getAll
            };
          }

          if (options.entities.includes(EntityNameEnum.LeadProcess)) {
            value.entities[EntityNameEnum.LeadProcess] = {
              state: AsyncState.FETCHED,
              value: data.entities.leadProcess.getAll.map(leadProcess => ({
                ...leadProcess,
                steps: leadProcess.steps.sort((a, b) => a.sortOrder - b.sortOrder)
              }))
            };
          }

          if (options.entities.includes(EntityNameEnum.LeadType)) {
            value.entities[EntityNameEnum.LeadType] = {
              state: AsyncState.FETCHED,
              value: data.entities.leadType.getAll
            };
          }

          if (options.entities.includes(EntityNameEnum.Pipeline)) {
            value.entities[EntityNameEnum.Pipeline] = {
              state: AsyncState.FETCHED,
              value: data.entities.pipeline.getAll.map(pipeline => ({
                ...pipeline,
                steps: pipeline.steps.sort((a, b) => a.sortOrder - b.sortOrder)
              }))
            };

            value.allowedPipelineIds = {
              state: AsyncState.FETCHED,
              value: data.viewer.accessControl.allowedPipelines.map(allowedPipeline => allowedPipeline.pipeline.id)
            };
          }

          if (options.entities.includes(EntityNameEnum.SalesUnit)) {
            value.entities[EntityNameEnum.SalesUnit] = {
              state: AsyncState.FETCHED,
              value: data.entities.salesUnit.getAll
            };
          }

          if (options.entities.includes(EntityNameEnum.TaskType)) {
            value.entities[EntityNameEnum.TaskType] = {
              state: AsyncState.FETCHED,
              value: data.entities.taskType.getAll
            };
          }
        }

        if (options.entityCustomNames) {
          this.i18nService.registerExtraMarkers(entityNamesToMarkers(data.admin.spaceSettings.entityCustomNames));
        }

        if (options.fieldDescriptors) {
          options.fieldDescriptors.entityTypes.forEach(entityType => {
            const fieldDescriptors = (data.collections[`fieldDescriptors${entityType}`] as FieldDescriptor[]).map(field => {
              if (field.useLang) {
                const entity = [EntityNameEnum.Appointment, EntityNameEnum.Task].includes(field.entity) ? EntityNameEnum.Activity : field.entity;

                return { ...field, name: this.i18nService.translate(`${entity}_${field.name}`) };
              }

              return field;
            });

            value.fieldDescriptors[entityType] = {
              state: AsyncState.FETCHED,
              value: fieldDescriptors
            };
          });
        }

        if (options.forms) {
          options.forms.entityTypes.forEach(entityType => {
            const forms = data.entities[`forms${entityType}`].getAll as { id: string, formEditApi: Form }[];

            value.forms[entityType] = {
              state: AsyncState.FETCHED,
              value: forms.map(form => ({
                ...form,
                formEditApi: {
                  ...form.formEditApi,
                  items: form.formEditApi.items.map(formItem => {
                    if (formItem.__typename !== 'FormContainer') {
                      return formItem;
                    }

                    return {
                      ...formItem,
                      columns: formItem.columns.map(column => ({
                        ...column,
                        items: column.items.map(item => {
                          if (item.__typename !== 'FormField') {
                            return item;
                          }

                          const entity = [EntityNameEnum.Appointment, EntityNameEnum.Task].includes(entityType) ? EntityNameEnum.Activity : entityType;

                          return {
                            ...item,
                            label: item.useLang ? this.i18nService.translate(`${entity}_${item.label}`) : item.label
                          };
                        })
                      }))
                    };
                  })
                }
              }))
            };
          });
        }

        if (options.user) {
          value.user = {
            state: AsyncState.FETCHED,
            value: (data.entities['userClient'] as ClientRepository).getById
          };
        }

        this.store.dispatch('Space_UpdateConfig', value);
      }),
      catchError(error => {
        this.store.dispatch('Space_UpdateConfigError', null);

        return throwError(error);
      })
    );
  }

}

export interface CanAccessOptions extends FetchBaseConfigOptions, Omit<FetchLazyConfigOptions, 'user'> {
  authCheckOnly?: boolean;
}

export interface FetchBaseConfigOptions {
  accessControl?: {
    entities?: EntityNameEnum[];
    features?: (keyof FeaturePermissions)[];
  };
  entityCustomNames?: boolean;
}

export interface FetchLazyConfigOptions {
  entities?: EntityNameEnum[];
  fieldDescriptors?: {
    entityTypes: EntityNameEnum[];
    fetchValueOptions?: boolean | EntityNameEnum[];
    fieldFragment: () => DocumentNode;
  };
  forms?: {
    entityTypes: EntityNameEnum[];
  };
  user?: boolean;
}
