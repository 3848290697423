import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OutlookItemDirection } from '../../../../shared/outlook/domain/outlook';
import type { DashboardClientItem, DashboardUserItem } from '../../../domain/dashboard';
import { DashboardItemKind } from '../../../domain/dashboard';

@Component({
  selector: 'ppl-dashboard-recipients-user-client',
  templateUrl: './dashboard-recipients-user-client.component.html',
  styleUrls: ['./dashboard-recipients-user-client.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardRecipientsUserClientComponent {

  @Input() items: (DashboardUserItem | DashboardClientItem)[];

  OutlookItemDirection = OutlookItemDirection;
  DashboardItemKind = DashboardItemKind;

}
