import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { AccountEntity, ActivityRelationEntity, AppointmentEntity, ContactEntity, LeadEntity, OpportunityEntity, TaskEntity } from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { MemoizeLast } from '@ppl/utils';
import { LocationService } from '../../../../../services/location.service';

@Component({
  selector: 'ppl-linked-items-cards',
  templateUrl: './linked-items-cards.component.html',
  styleUrls: ['./linked-items-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkedItemsCardsComponent {

  @Input() accounts: ActivityRelationEntity[];
  @Input() contacts: ActivityRelationEntity[];
  @Input() leads: ActivityRelationEntity[];
  @Input() opportunities: ActivityRelationEntity[];

  @Input() parentEntity: AppointmentEntity | TaskEntity;

  EntityNameEnum = EntityNameEnum;

  @MemoizeLast<LinkedItemsCardsComponent>(['accounts', 'contacts', 'leads', 'opportunities'])
  get entities() {
    return [
      ...this.accounts,
      ...this.contacts,
      ...this.leads,
      ...this.opportunities
    ];
  }

  constructor(
    private locationService: LocationService
  ) {}

  onAccountCardClick(entity: AccountEntity) {
    this.locationService.openAccount(entity.id);
  }

  onContactCardClick(entity: ContactEntity) {
    this.locationService.openContact(entity.id);
  }

  onLeadCardClick(entity: LeadEntity) {
    this.locationService.openLead(entity.id);
  }

  onOpportunityCardClick(entity: OpportunityEntity) {
    this.locationService.openOpportunity(entity.id);
  }

}
