import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { LocationService } from '../../../../../../services/location.service';

@Component({
  selector: 'ppl-page-detail-header',
  templateUrl: './page-detail-header.component.html',
  styleUrls: ['./page-detail-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageDetailHeaderComponent {

  @Input() label: string;
  @Input() globalSearch: TemplateRef<any>;

  constructor(
    private locationService: LocationService
  ) {}

  onBackClick() {
    this.locationService.goBack();
  }

  onCloseClick() {
    this.locationService.reset();
  }

}
