import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ClientEntity, EntityNameEnum } from '@ppl/graphql-space-api';
import { AvatarKind } from '../../../avatar/domain/avatar';

@Component({
  selector: 'ppl-client-card-large',
  templateUrl: './client-card-large.component.html',
  styleUrls: ['./client-card-large.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientCardLargeComponent {

  @Input() entity: ClientEntity;

  AvatarKind = AvatarKind;
  EntityNameEnum = EntityNameEnum;

}
