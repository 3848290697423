import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UserProfileEntity } from '@ppl/graphql-user-api';

@Component({
  selector: 'ppl-top-navigation-user-button',
  templateUrl: './top-navigation-user-button.component.html',
  styleUrls: ['./top-navigation-user-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopNavigationUserButtonComponent implements OnInit {

  @Input() userProfile: UserProfileEntity;
  @Input() userSettingsUrl: string;

  constructor() { }

  ngOnInit() {
  }

}
