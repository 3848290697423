import { Injectable } from '@angular/core';
import { ActionsUtilsService } from '@ppl/core';
import type { Query, UpdateAppointmentInput } from '@ppl/graphql-space-api';
import { SpaceService } from '@ppl/space';
import { map, tap } from 'rxjs/operators';
import { CacheService } from '../../../services/cache.service';
import type { AppointmentData } from '../domain/appointment';
import { gqlUpdateAppointmentMutation } from './appointment-form.graphql';
import { gqlFetchAppointmentData } from './appointment.graphql';

@Injectable()
export class AppointmentService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private cacheService: CacheService,
    private spaceService: SpaceService
  ) {}

  fetchData(id: string) {
    return this.spaceService.gqlClient.query<Query>({
      query: gqlFetchAppointmentData,
      fetchPolicy: 'no-cache',
      variables: {
        entityId: id
      }
    }).pipe(map(({ data }) => {
      const entityData = data.screens.extractViews.appointment;

      if (!entityData) {
        return null;
      }

      const result: AppointmentData = {
        entity: entityData.entity,
        attendees: [
          ...entityData.entity.inviteesClients.edges.map(edge => edge.node.client)
        ],
        relatedAccounts: entityData.entity.accountRelations.edges.map(edge => edge.node),
        relatedContacts: entityData.entity.contactRelations.edges.map(edge => edge.node),
        relatedLeads: entityData.entity.leadRelations.edges.map(edge => edge.node),
        relatedOpportunities: entityData.entity.opportunityRelations.edges.map(edge => edge.node)
      };

      return result;
    }));
  }

  updateField(id: string, value: Omit<UpdateAppointmentInput, 'id'>) {
    const input: UpdateAppointmentInput = {
      id,
      ...value
    };

    return this.actionsUtilsService.doAction(this.spaceService.gqlClient.mutate({
      mutation: gqlUpdateAppointmentMutation,
      variables: {
        input
      }
    }).pipe(
      tap(() => {
        this.cacheService.reset();
      })
    ));
  }

}
