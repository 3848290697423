import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'ppl-card-large',
  templateUrl: './card-large.component.html',
  styleUrls: ['./card-large.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardLargeComponent {

  @Input() @HostBinding('class.disabled') disabled?: boolean;

  @ContentChild('actionTemplate', { static: false }) actionTemplate: TemplateRef<any>;
  @ContentChild('iconTemplate', { static: false }) iconTemplate: TemplateRef<any>;
  @ContentChild('nameTemplate', { static: false }) nameTemplate: TemplateRef<any>;
  @ContentChild('descriptionTemplate', { static: false }) descriptionTemplate: TemplateRef<any>;

}
