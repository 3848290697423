import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ppl-entity-error',
  templateUrl: './entity-error.component.html',
  styleUrls: ['./entity-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityErrorComponent {

  @Input() error: any | null;

}
