import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { PplFileSizePipe } from '@ppl/auth';
import { I18nService } from '@ppl/i18n';
import { PplDialogService } from '@ppl/ui/dialog';
import { findById, FormValueControl, getFormControlProvider, MemoizeLast } from '@ppl/utils';
import type { EntityGridColumn, EntityGridItem } from '../../../shared/entity-grid/domain/entity-grid';
import type { OutlookAttachment } from '../../../shared/outlook/domain/outlook';
import type { AttachmentNameData } from '../attachment-name/attachment-name.component';
import { AttachmentNameComponent } from '../attachment-name/attachment-name.component';

@Component({
  selector: 'ppl-save-email-attachments',
  templateUrl: './save-email-attachments.component.html',
  styleUrls: ['./save-email-attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    getFormControlProvider(() => SaveEmailAttachmentsComponent)
  ]
})
@FormValueControl()
export class SaveEmailAttachmentsComponent {

  @Input() value: SaveEmailAttachment[] = [];

  @Output() valueChange = new EventEmitter<SaveEmailAttachment[]>();

  gridColumns: EntityGridColumn[] = [{
    id: 'fileName',
    inlineEdit: true,
    name: this.i18nService.translate('Attachment'),
    width: 160
  }, {
    id: 'size',
    name: this.i18nService.translate('Size')
  }];

  @MemoizeLast<SaveEmailAttachmentsComponent>(['value'])
  get gridItems() {
    return this.value.map(attachment => ({
      id: attachment.id,
      fileName: attachment.fileName,
      size: this.fileSizePipe.transform(attachment.size)
    }));
  }

  @MemoizeLast<SaveEmailAttachmentsComponent>(['value'])
  get gridSelection() {
    return this.value.filter(attachment => attachment.selected).map(attachment => attachment.id);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: PplDialogService,
    private i18nService: I18nService,
    private fileSizePipe: PplFileSizePipe
  ) {}

  onGridInlineEdit(item: EntityGridItem) {
    this.dialogService.open<AttachmentNameComponent, AttachmentNameData>(AttachmentNameComponent, {
      data: {
        fileName: findById(this.value, item.id).fileName
      }
    }).afterClosed().subscribe(fileName => {
      if (fileName) {
        this.valueChange.emit(this.value.map(attachment => {
          if (attachment.id !== item.id) {
            return attachment;
          }

          return {
            ...attachment,
            fileName
          };
        }));
      }
    });
  }

  onGridSelectionChange(selection: string[]) {
    this.valueChange.emit(this.value.map(attachment => ({
      ...attachment,
      selected: selection.includes(attachment.id)
    })));
  }

}

export interface SaveEmailAttachment extends OutlookAttachment {
  selected: boolean;
}
