const defaultLanguage = () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/en');

/**
 * These locales are listed manually, we want to minimize number of locales bundled.
 * Also by putting same webpackChunkName to each import, webpack creates one chunk for all of them
 */
export const dataRegionToAngularLocaleMap: { [K in DataRegion]?: () => any } = {
  'et-EE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/et'),
  'ms-MY': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ms'),
  'is-IS': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/is'),
  'nl-NL': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/nl'),
  'tn-ZA': defaultLanguage, // unsupported (!)
  'en-US': defaultLanguage, // default angular locale
  'sq-AL': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/sq'),
  'uz-Cyrl-UZ': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/uz-Cyrl'),
  'fo-FO': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/fo'),
  'gbz-AF': defaultLanguage, // unsupported (!)
  'iu-Latn-CA': defaultLanguage, // unsupported (!)
  'en-NZ': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/en-NZ'),
  'sr-Cyrl-CS': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/sr-Cyrl'),
  'sw-KE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/sw-KE'),
  'bo-BT': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/bo'),
  'fr-CA': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/fr-CA'),
  'quz-EC': defaultLanguage, // unsupported (!)
  'mn-Mong-CN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/mn'),
  'ar-LB': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-LB'),
  'zh-CN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/zh'),
  'eu-ES': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/eu'),
  'tk-TM': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/tk'),
  'ko-KR': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ko'),
  'zh-SG': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/zh-Hans-SG'),
  'de-LU': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/de-LU'),
  'fi-FI': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/fi'),
  'kk-KZ': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/kk'),
  'yo-NG': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/yo'),
  'sr-Latn-BA': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/sr-Latn-BA'),
  'zh-MO': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/zh-Hans-MO'),
  'ar-LY': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-LY'),
  'ba-RU': defaultLanguage, // unsupported (!)
  'ns-ZA': defaultLanguage, // unsupported (!)
  'ky-KG': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ky'),
  'sr-Cyrl-BA': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/sr-Cyrl-BA'),
  'ar-YE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-YE'),
  'sma-SE': defaultLanguage, // unsupported (!)
  'es-PY': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-PY'),
  'zu-ZA': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/zu'),
  'ar-TN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-TN'),
  'ru-RU': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ru'),
  'uz-Latn-UZ': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/uz-Latn'),
  'en-CA': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/en-CA'),
  'se-SE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/se-SE'),
  'es-HN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-HN'),
  'es-PR': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-PR'),
  'am-ET': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/am'),
  'kok-IN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/kok'),
  'en-PH': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/en-PH'),
  'tr-TR': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/tr'),
  'sl-SI': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/si'),
  'pl-PL': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/pl'),
  'nn-NO': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/nn'),
  'ka-GE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ka'),
  'sv-SE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/sv'),
  'ar-QA': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-QA'),
  'en-ZA': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/en-ZA'),
  'sah-RU': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/sah'),
  'nl-BE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/nl-BE'),
  'bs-Cyrl-BA': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/bs-Cyrl'),
  'mi-NZ': defaultLanguage, // unsupported (!)
  'el-GR': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/el'),
  'vi-VN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/vi'),
  'rw-RW': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/rw'),
  'ar-IQ': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-IQ'),
  'es-GT': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-GT'),
  'es-DO': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-DO'),
  'cy-GB': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/cy'),
  'br-FR': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/br'),
  'de-AT': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/de-AT'),
  'iu-Cans-CA': defaultLanguage, // unsupported (!)
  'fr-MC': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/fr-MC'),
  'lt-LT': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/lt'),
  'da-DK': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/da'),
  'hi-IN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/hi'),
  'sa-IN': defaultLanguage, // unsupported (!)
  'quz-PE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/qu'),
  'ar-JO': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-JO'),
  'it-CH': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/it-CH'),
  'syr-SY': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-SY'),
  'tmz-Latn-DZ': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/dz'),
  'es-SV': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-SV'),
  'en-MY': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/en-MY'),
  'se-NO': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/se'),
  'smn-FI': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/smn'),
  'arn-CL': defaultLanguage, // unsupported (!)
  'he-IL': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/he'),
  'ms-BN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ms-BN'),
  'tg-Cyrl-TJ': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/tg'),
  'es-EC': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-EC'),
  'ha-Latn-NG': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ha'),
  'pt-BR': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/br'),
  'fr-FR': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/fr'),
  'ur-PK': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ur'),
  'es-ES': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es'),
  'tt-RU': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/tt'),
  'gsw-FR': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/gsw-FR'),
  'ca-ES': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es'),
  'ar-AE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-AE'),
  'es-CR': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-CR'),
  'quz-BO': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/qu-BO'),
  'gu-IN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/gu'),
  'ar-BH': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-BH'),
  'be-BY': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/be'),
  'sr-Latn-CS': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/sr-Latn-BA'),
  'es-AR': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-AR'),
  'wo-SN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/wo'),
  'id-ID': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/id'),
  'tr-IN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ur'),
  'es-CL': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-CL'),
  'es-UY': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-UY'),
  'ml-IN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ml'),
  'sk-SK': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/sk'),
  'ne-NP': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ne'),
  'pt-PT': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/pt-PT'),
  'ro-RO': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ro'),
  'pa-IN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/pa'),
  'en-IN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/en-IN'),
  'gl-ES': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/gl'),
  'si-LK': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/si'),
  'mt-MT': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/mt'),
  'ar-MA': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-MA'),
  'sv-FI': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/sv-FI'),
  'sma-NO': defaultLanguage, // unsupported (!)
  'mk-MK': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/mk'),
  'wen-DE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/hsb'),
  'ar-SY': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-SY'),
  'ar-OM': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-OM'),
  'hr-HR': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/hr'),
  'smj-NO': defaultLanguage, // unsupported (!)
  'hu-HU': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/hu'),
  'zh-HK': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/zh'),
  'es-US': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-US'),
  'ar-DZ': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-DZ'),
  'as-IN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/as'),
  'en-BZ': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/en-BZ'),
  'mr-IN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/mr'),
  'af-ZA': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/af'),
  'hy-AM': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/hy'),
  'kh-KH': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/km'),
  'es-CO': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-CO'),
  'qut-GT': defaultLanguage,
  'ta-IN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ta'),
  'cs-CZ': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/cs'),
  'rm-CH': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/rm'),
  'fr-CH': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/fr-CH'),
  'fil-PH': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/fil'),
  'es-MX': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-MX'),
  'ne-IN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ne-IN'),
  'ar-KW': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-KW'),
  'bn-IN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/bn-IN'),
  'xh-ZA': defaultLanguage, // unsupported (!)
  'fy-NL': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/fy'),
  'nb-NO': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/nb'),
  'es-PA': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-PA'),
  'en-GB': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/en-GB'),
  'de-LI': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/de-LI'),
  'lv-LV': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/lv'),
  'en-TT': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/en-TT'),
  'en-AU': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/en-AU'),
  'es-BO': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-BO'),
  'sms-FI': defaultLanguage, // unsupported (!)
  'hr-BA': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/hr-BA'),
  'it-IT': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/it'),
  'de-DE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/de'),
  'lb-LU': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/lb'),
  'kl-GL': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/kl'),
  'ig-NG': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ig'),
  'es-VE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-VE'),
  'ga-IE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ga'),
  'en-ZW': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/en-ZW'),
  'en-IE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/en-IE'),
  'bo-CN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/bo'),
  'az-Latn-AZ': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/az-Latn'),
  'fr-LU': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/fr-LU'),
  'oc-FR': defaultLanguage, // unsupported (!)
  'ar-EG': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ar-EG'),
  'ug-CN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ug'),
  'es-NI': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-NI'),
  'mn-Cyrl-MN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/mn'),
  'or-IN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/or'),
  'es-ES_tradnl': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es'),
  'zh-TW': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/zh'),
  'se-FI': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/se-FI'),
  'moh-CA': defaultLanguage, // unsupported (!)
  'en-029': defaultLanguage, // unsupported (!)
  'co-FR': defaultLanguage, // unsupported (!)
  'ja-JP': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/ja'),
  'te-IN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/te'),
  'kn-IN': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/kn'),
  'smj-SE': defaultLanguage, // unsupported (!)
  'bg-BG': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/bg'),
  'en-JM': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/en-JM'),
  'fr-BE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/fr-BE'),
  'en-SG': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/en-SG'),
  'uk-UA': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/uk'),
  'de-CH': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/de-CH'),
  'bs-Latn-BA': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/bs-Latn'),
  'az-Cyrl-AZ': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/az-Cyrl'),
  'es-PE': () => import(/* webpackChunkName: 'locale' */ '@angular/common/locales/es-PE'),
};

export type DataRegion = 'et-EE' | 'ms-MY' | 'is-IS' | 'nl-NL' | 'tn-ZA' | 'en-US' | 'sq-AL' | 'uz-Cyrl-UZ' | 'fo-FO' | 'gbz-AF' | 'iu-Latn-CA' | 'en-NZ' | 'sr-Cyrl-CS' | 'sw-KE' | 'bo-BT' | 'fr-CA'
  | 'quz-EC' | 'mn-Mong-CN' | 'ar-LB' | 'zh-CN' | 'eu-ES' | 'tk-TM' | 'ko-KR' | 'zh-SG' | 'de-LU' | 'fi-FI' | 'kk-KZ' | 'yo-NG' | 'sr-Latn-BA' | 'zh-MO' | 'ar-LY' | 'ba-RU'
  | 'ns-ZA' | 'ky-KG' | 'sr-Cyrl-BA' | 'ar-YE' | 'sma-SE' | 'es-PY' | 'zu-ZA' | 'ar-TN' | 'ru-RU' | 'uz-Latn-UZ' | 'en-CA' | 'se-SE' | 'es-HN' | 'es-PR' | 'am-ET' | 'kok-IN' | 'en-PH'
  | 'tr-TR' | 'sl-SI' | 'pl-PL' | 'nn-NO' | 'ka-GE' | 'sv-SE' | 'ar-QA' | 'en-ZA' | 'sah-RU' | 'nl-BE' | 'bs-Cyrl-BA' | 'mi-NZ' | 'el-GR' | 'vi-VN' | 'rw-RW' | 'ar-IQ' | 'es-GT'
  | 'es-DO' | 'cy-GB' | 'br-FR' | 'de-AT' | 'iu-Cans-CA' | 'fr-MC' | 'lt-LT' | 'da-DK' | 'hi-IN' | 'sa-IN' | 'quz-PE' | 'ar-JO' | 'it-CH' | 'syr-SY' | 'tmz-Latn-DZ' | 'es-SV' | 'en-MY'
  | 'se-NO' | 'smn-FI' | 'arn-CL' | 'he-IL' | 'ms-BN' | 'tg-Cyrl-TJ' | 'es-EC' | 'ha-Latn-NG' | 'pt-BR' | 'fr-FR' | 'ur-PK' | 'es-ES' | 'tt-RU' | 'gsw-FR' | 'ca-ES' | 'ar-AE' | 'es-CR'
  | 'quz-BO' | 'gu-IN' | 'ar-BH' | 'be-BY' | 'sr-Latn-CS' | 'es-AR' | 'wo-SN' | 'id-ID' | 'tr-IN' | 'es-CL' | 'es-UY' | 'ml-IN' | 'sk-SK' | 'ne-NP' | 'pt-PT' | 'ro-RO' | 'pa-IN'
  | 'en-IN' | 'gl-ES' | 'si-LK' | 'mt-MT' | 'ar-MA' | 'sv-FI' | 'sma-NO' | 'mk-MK' | 'wen-DE' | 'ar-SY' | 'ar-OM' | 'hr-HR' | 'smj-NO' | 'hu-HU' | 'zh-HK' | 'es-US' | 'ar-DZ'
  | 'as-IN' | 'en-BZ' | 'mr-IN' | 'af-ZA' | 'hy-AM' | 'kh-KH' | 'es-CO' | 'qut-GT' | 'ta-IN' | 'cs-CZ' | 'rm-CH' | 'fr-CH' | 'fil-PH' | 'es-MX' | 'ne-IN' | 'ar-KW' | 'bn-IN'
  | 'xh-ZA' | 'fy-NL' | 'nb-NO' | 'es-PA' | 'en-GB' | 'de-LI' | 'lv-LV' | 'en-TT' | 'en-AU' | 'es-BO' | 'sms-FI' | 'hr-BA' | 'it-IT' | 'de-DE' | 'lb-LU' | 'kl-GL' | 'ig-NG'
  | 'es-VE' | 'ga-IE' | 'en-ZW' | 'en-IE' | 'bo-CN' | 'az-Latn-AZ' | 'fr-LU' | 'oc-FR' | 'ar-EG' | 'ug-CN' | 'es-NI' | 'mn-Cyrl-MN' | 'or-IN' | 'es-ES_tradnl' | 'zh-TW' | 'se-FI' | 'moh-CA'
  | 'en-029' | 'co-FR' | 'ja-JP' | 'te-IN' | 'kn-IN' | 'smj-SE' | 'bg-BG' | 'en-JM' | 'fr-BE' | 'en-SG' | 'uk-UA' | 'de-CH' | 'bs-Latn-BA' | 'az-Cyrl-AZ' | 'es-PE';

export const dataRegions: { [K in DataRegion]: string } = {
  'et-EE': 'Estonian (Estonia)',
  'ms-MY': 'Malay (Malaysia)',
  'is-IS': 'Icelandic (Iceland)',
  'nl-NL': 'Dutch (Netherlands)',
  'tn-ZA': 'Setswana/Tswana (South Africa)',
  'en-US': 'English (United States)',
  'sq-AL': 'Albanian (Albania)',
  'uz-Cyrl-UZ': 'Uzbek (Uzbekistan, Cyrillic)',
  'fo-FO': 'Faroese (Faroe Islands)',
  'gbz-AF': 'Dari (Afghanistan)',
  'iu-Latn-CA': 'Inuktitut (Canada, Latin)',
  'en-NZ': 'English (New Zealand)',
  'sr-Cyrl-CS': 'Serbian (Serbia, Cyrillic)',
  'sw-KE': 'Swahili (Kenya)',
  'bo-BT': 'Tibetan (Bhutan)',
  'fr-CA': 'French (Canada)',
  'quz-EC': 'Quechua (Ecuador)',
  'mn-Mong-CN': 'Mongolian (PRC)',
  'ar-LB': 'Arabic (Lebanon)',
  'zh-CN': 'Chinese (PRC)',
  'eu-ES': 'Basque (Basque)',
  'tk-TM': 'Turkmen (Turkmenistan)',
  'ko-KR': 'Korean (Korea)',
  'zh-SG': 'Chinese (Singapore)',
  'de-LU': 'German (Luxembourg)',
  'fi-FI': 'Finnish (Finland)',
  'kk-KZ': 'Kazakh (Kazakhstan)',
  'yo-NG': 'Yoruba (Nigeria)',
  'sr-Latn-BA': 'Serbian (Bosnia and Herzegovina, Latin)',
  'zh-MO': 'Chinese (Macao SAR)',
  'ar-LY': 'Arabic (Libya)',
  'ba-RU': 'Bashkir (Russia)',
  'ns-ZA': 'Sesotho sa Leboa/Northern Sotho (South Africa)',
  'ky-KG': 'Kyrgyz (Kyrgyzstan)',
  'sr-Cyrl-BA': 'Serbian (Bosnia and Herzegovina, Cyrillic)',
  'ar-YE': 'Arabic (Yemen)',
  'sma-SE': 'Sami (Southern, Sweden)',
  'es-PY': 'Spanish (Paraguay)',
  'zu-ZA': 'Zulu/isiZulu (South Africa)',
  'ar-TN': 'Arabic (Tunisia)',
  'ru-RU': 'Russian (Russia)',
  'uz-Latn-UZ': 'Uzbek (Uzbekistan, Latin)',
  'en-CA': 'English (Canada)',
  'se-SE': 'Sami (Northern, Sweden)',
  'es-HN': 'Spanish (Honduras)',
  'es-PR': 'Spanish (Puerto Rico)',
  'am-ET': 'Amharic (Ethiopia)',
  'kok-IN': 'Konkani (India)',
  'en-PH': 'English (Philippines)',
  'tr-TR': 'Turkish (Turkey)',
  'sl-SI': 'Slovenian (Slovenia)',
  'pl-PL': 'Polish (Poland)',
  'nn-NO': 'Norwegian (Nynorsk, Norway)',
  'ka-GE': 'Georgian (Georgia)',
  'sv-SE': 'Swedish (Sweden)',
  'ar-QA': 'Arabic (Qatar)',
  'en-ZA': 'English (South Africa)',
  'sah-RU': 'Yakut (Russia)',
  'nl-BE': 'Dutch (Belgium)',
  'bs-Cyrl-BA': 'Bosnian (Bosnia and Herzegovina, Cyrillic)',
  'mi-NZ': 'Maori (New Zealand)',
  'el-GR': 'Greek (Greece)',
  'vi-VN': 'Vietnamese (Vietnam)',
  'rw-RW': 'Kinyarwanda (Rwanda)',
  'ar-IQ': 'Arabic (Iraq)',
  'es-GT': 'Spanish (Guatemala)',
  'es-DO': 'Spanish (Dominican Republic)',
  'cy-GB': 'Welsh (United Kingdom)',
  'br-FR': 'Breton (France)',
  'de-AT': 'German (Austria)',
  'iu-Cans-CA': 'Inuktitut (Canada, Syllabics)',
  'fr-MC': 'French (Monaco)',
  'lt-LT': 'Lithuanian (Lithuania)',
  'da-DK': 'Danish (Denmark)',
  'hi-IN': 'Hindi (India)',
  'sa-IN': 'Sanskrit (India)',
  'quz-PE': 'Quechua (Peru)',
  'ar-JO': 'Arabic (Jordan)',
  'it-CH': 'Italian (Switzerland)',
  'syr-SY': 'Syriac (Syria)',
  'tmz-Latn-DZ': 'Tamazight (Algeria, Latin)',
  'es-SV': 'Spanish (El Salvador)',
  'en-MY': 'English (Malaysia)',
  'se-NO': 'Sami (Northern, Norway)',
  'smn-FI': 'Sami (Inari, Finland)',
  'arn-CL': 'Mapudungun (Chile)',
  'he-IL': 'Hebrew (Israel)',
  'ms-BN': 'Malay (Brunei Darussalam)',
  'tg-Cyrl-TJ': 'Tajik (Tajikistan)',
  'es-EC': 'Spanish (Ecuador)',
  'ha-Latn-NG': 'Hausa (Nigeria, Latin)',
  'pt-BR': 'Portuguese (Brazil)',
  'fr-FR': 'French (France)',
  'ur-PK': 'Urdu (Pakistan)',
  'es-ES': 'Spanish (Spain)',
  'tt-RU': 'Tatar (Russia)',
  'gsw-FR': 'Alsatian (France)',
  'ca-ES': 'Catalan (Catalan)',
  'ar-AE': 'Arabic (U.A.E.)',
  'es-CR': 'Spanish (Costa Rica)',
  'quz-BO': 'Quechua (Bolivia)',
  'gu-IN': 'Gujarati (India)',
  'ar-BH': 'Arabic (Bahrain)',
  'be-BY': 'Belarusian (Belarus)',
  'sr-Latn-CS': 'Serbian (Serbia, Latin)',
  'es-AR': 'Spanish (Argentina)',
  'wo-SN': 'Wolof (Senegal)',
  'id-ID': 'Indonesian (Indonesia)',
  'tr-IN': 'Urdu (India)',
  'es-CL': 'Spanish (Chile)',
  'es-UY': 'Spanish (Uruguay)',
  'ml-IN': 'Malayalam (India)',
  'sk-SK': 'Slovak (Slovakia)',
  'ne-NP': 'Nepali (Nepal)',
  'pt-PT': 'Portuguese (Portugal)',
  'ro-RO': 'Romanian (Romania)',
  'pa-IN': 'Punjabi (India)',
  'en-IN': 'English (India)',
  'gl-ES': 'Galician (Spain)',
  'si-LK': 'Sinhala (Sri Lanka)',
  'mt-MT': 'Maltese (Malta)',
  'ar-MA': 'Arabic (Morocco)',
  'sv-FI': 'Swedish (Finland)',
  'sma-NO': 'Sami (Southern, Norway)',
  'mk-MK': 'Macedonian (Macedonia, FYROM)',
  'wen-DE': 'Upper Sorbian (Germany)',
  'ar-SY': 'Arabic (Syria)',
  'ar-OM': 'Arabic (Oman)',
  'hr-HR': 'Croatian (Croatia)',
  'smj-NO': 'Sami (Lule, Norway)',
  'hu-HU': 'Hungarian (Hungary)',
  'zh-HK': 'Chinese (Hong Kong SAR, PRC)',
  'es-US': 'Spanish (United States)',
  'ar-DZ': 'Arabic (Algeria)',
  'as-IN': 'Assamese (India)',
  'en-BZ': 'English (Belize)', 'mr-IN': 'Marathi (India)',
  'af-ZA': 'Afrikaans (South Africa)',
  'hy-AM': 'Armenian (Armenia)',
  'kh-KH': 'Khmer (Cambodia)',
  'es-CO': 'Spanish (Colombia)',
  'qut-GT': 'K\'iche (Guatemala)',
  'ta-IN': 'Tamil (India)',
  'cs-CZ': 'Czech (Czech Republic)',
  'rm-CH': 'Romansh (Switzerland)',
  'fr-CH': 'French (Switzerland)',
  'fil-PH': 'Filipino (Philippines)',
  'es-MX': 'Spanish (Mexico)',
  'ne-IN': 'Nepali (India)',
  'ar-KW': 'Arabic (Kuwait)',
  'bn-IN': 'Bengali (India)',
  'xh-ZA': 'Xhosa/isiXhosa (South Africa)',
  'fy-NL': 'Frisian (Netherlands)',
  'nb-NO': 'Norwegian (Bokm?l, Norway)',
  'es-PA': 'Spanish (Panama)',
  'en-GB': 'English (United Kingdom)',
  'de-LI': 'German (Liechtenstein)',
  'lv-LV': 'Latvian (Latvia)',
  'en-TT': 'English (Trinidad and Tobago)',
  'en-AU': 'English (Australia)',
  'es-BO': 'Spanish (Bolivia)',
  'sms-FI': 'Sami (Skolt, Finland)',
  'hr-BA': 'Croatian (Bosnia and Herzegovina, Latin)',
  'it-IT': 'Italian (Italy)',
  'de-DE': 'German (Germany)',
  'lb-LU': 'Luxembourgish (Luxembourg)',
  'kl-GL': 'Greenlandic (Greenland)',
  'ig-NG': 'Igbo (Nigeria)',
  'es-VE': 'Spanish (Venezuela)',
  'ga-IE': 'Irish (Ireland)',
  'en-ZW': 'English (Zimbabwe)',
  'en-IE': 'English (Ireland)',
  'bo-CN': 'Tibetan (PRC)',
  'az-Latn-AZ': 'Azeri (Azerbaijan, Latin)',
  'fr-LU': 'French (Luxembourg)',
  'oc-FR': 'Occitan (France)',
  'ar-EG': 'Arabic (Egypt)',
  'ug-CN': 'Uighur (PRC)',
  'es-NI': 'Spanish (Nicaragua)',
  'mn-Cyrl-MN': 'Mongolian (Mongolia)',
  'or-IN': 'Oriya (India)',
  'es-ES_tradnl': 'Spanish (Spain, Traditional Sort)',
  'zh-TW': 'Chinese (Taiwan)',
  'se-FI': 'Sami (Northern, Finland)',
  'moh-CA': 'Mohawk (Canada)',
  'en-029': 'English (Caribbean)',
  'co-FR': 'Corsican (France)',
  'ja-JP': 'Japanese (Japan)',
  'te-IN': 'Telugu (India)',
  'kn-IN': 'Kannada (India)',
  'smj-SE': 'Sami (Lule, Sweden)',
  'bg-BG': 'Bulgarian (Bulgaria)',
  'en-JM': 'English (Jamaica)',
  'fr-BE': 'French (Belgium)',
  'en-SG': 'English (Singapore)',
  'uk-UA': 'Ukrainian (Ukraine)',
  'de-CH': 'German (Switzerland)',
  'bs-Latn-BA': 'Bosnian (Bosnia and Herzegovina, Latin)',
  'az-Cyrl-AZ': 'Azeri (Azerbaijan, Cyrillic)',
  'es-PE': 'Spanish (Peru)'
};

export function getLocalesAsOptions(): { value: string; label: string; }[] {
  const regions: { value: string, label: string }[] = [];
  Object.keys(dataRegions).forEach(key => regions.push({ value: key, label: dataRegions[key] }));
  regions.sort((a: { label: string; }, b: { label: any; }) => a.label.localeCompare(b.label));
  return regions;
}
