import type { PplNamePipe } from '@ppl/auth';
import type { EntityAdapters } from '@ppl/components/entity-list';
import type { AccountEntity, ContactEntity, LeadEntity, OpportunityEntity } from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import type { Coalesce} from '@ppl/utils';
import { gql } from '@ppl/utils';

export enum AddRecipientKind {
  Contact = 'Contact',
  SalesTeam = 'SalesTeam'
}

export function getAddRecipientEntityData(entity: AccountEntity | ContactEntity | LeadEntity | OpportunityEntity, kind: AddRecipientKind, namePipe: PplNamePipe): AddRecipientEntityData[] {
  switch (kind) {
    case AddRecipientKind.Contact:
      if (entity.__typename !== 'AccountEntity' && entity.__typename !== 'ContactEntity') {
        return [];
      }

      const entityEmail = entity.email1 || entity.email2 || entity.email3 || entity.email4 || entity.email5;

      return entityEmail ? [{
        email: entityEmail,
        name: (entity.__typename === 'ContactEntity') ? namePipe.transform(entity) : entity.name
      }] : [];
    case AddRecipientKind.SalesTeam:
      return (entity.sharingClients.edges as Coalesce<typeof entity.sharingClients.edges>).map(edge => edge.node.client).map(client => ({
        email: client.email,
        name: client.name
      }));
  }
}

export interface AddRecipientEntityData {
  email: string;
  name: string;
}

export const AddRecipientContactEntityListAdapters: EntityAdapters = {
  [EntityNameEnum.Account]: {
    entityFields: gql`
      fragment AccountEntityListFields on AccountEntity {
        email1
        email2
        email3
        email4
        email5
        id
        name
      }
    `
  },
  [EntityNameEnum.Contact]: {
    entityFields: gql`
      fragment ContactEntityListFields on ContactEntity {
        email1
        email2
        email3
        email4
        email5
        id
        firstName
        lastName
        middleName
        title
      }
    `
  }
};

export const AddRecipientSalesTeamEntityListAdapters: EntityAdapters = {
  [EntityNameEnum.Account]: {
    entityFields: gql`
      fragment AccountEntityListFields on AccountEntity {
        id
        name
        sharingClients {
          edges {
            node {
              id
              client {
                email
                id
                name
              }
            }
          }
        }
      }
    `
  },
  [EntityNameEnum.Contact]: {
    entityFields: gql`
      fragment ContactEntityListFields on ContactEntity {
        id
        firstName
        lastName
        middleName
        sharingClients {
          edges {
            node {
              id
              client {
                email
                id
                name
              }
            }
          }
        }
        title
      }
    `
  },
  [EntityNameEnum.Lead]: {
    entityFields: gql`
      fragment LeadEntityListFields on LeadEntity {
        id
        name
        sharingClients {
          edges {
            node {
              id
              client {
                email
                id
                name
              }
            }
          }
        }
      }
    `
  },
  [EntityNameEnum.Opportunity]: {
    entityFields: gql`
      fragment OpportunityEntityListFields on OpportunityEntity {
        id
        name
        sharingClients {
          edges {
            node {
              id
              client {
                email
                id
                name
              }
            }
          }
        }
      }
    `
  }
};
