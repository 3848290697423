import type { AccountTypeEntity, AppointmentTypeEntity, ClientEntity, ContactTypeEntity, CurrencyEntity, EntityPermsEnum, FeaturePermissions, FieldDescriptor, Form, LeadProcessEntity, LeadTypeEntity, PipelineEntity, SalesUnitEntity, TaskTypeEntity } from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import type { Space } from '@ppl/graphql-user-api';
import type { AsyncValue } from '@ppl/store';
import { findById } from '@ppl/utils';
import type { SpaceStateInCoreState } from './space.store';

export function getSpace(state: SpaceStateInCoreState): Space {
  return state.space.space;
}

export function shouldLoadSpaceFieldDescriptors(entityType: EntityNameEnum) {
  return (state: SpaceStateInCoreState): boolean => {
    return !state.space.config.fieldDescriptors || !state.space.config.fieldDescriptors[entityType];
  };
}

export function getSpaceFieldDescriptors(entityType?: EntityNameEnum) {
  return (state: SpaceStateInCoreState): FieldDescriptor[] => {
    return entityType
      ? state.space.config.fieldDescriptors?.[entityType]?.value || []
      : Object.values(state.space.config.fieldDescriptors || {}).reduce((result, fields) => [...result, ...(fields.value || [])], []);
  };
}

export function getSpaceFieldDescriptorById(id: string) {
  return (state: SpaceStateInCoreState): FieldDescriptor => {
    if (state.space.config.fieldDescriptors) {
      for (const entityType in state.space.config.fieldDescriptors) {
        if (state.space.config.fieldDescriptors.hasOwnProperty(entityType)) {
          const fields = state.space.config.fieldDescriptors[entityType] as AsyncValue<FieldDescriptor[]>;

          if (fields.value) {
            for (let i = 0; i < fields.value.length; i++) {
              if (fields.value[i].id === id) {
                return fields.value[i];
              }
            }
          }
        }
      }
    }

    return null;
  };
}

export function shouldLoadSpaceForms(entityType: EntityNameEnum) {
  return (state: SpaceStateInCoreState): boolean => {
    return !state.space.config.forms || !state.space.config.forms[entityType];
  };
}

export function getSpaceForms(entityType: EntityNameEnum) {
  return (state: SpaceStateInCoreState): { id: string, formEditApi: Form, pipelineId?: string }[] => {
    return state.space.config.forms?.[entityType]?.value || [];
  };
}

export function getSpaceFormById(id: string) {
  return (state: SpaceStateInCoreState): { id: string, formEditApi: Form, pipelineId?: string } => {
    if (state.space.config.forms) {
      for (const entityType in state.space.config.forms) {
        if (state.space.config.forms.hasOwnProperty(entityType)) {
          const forms = state.space.config.forms[entityType] as AsyncValue<{ id: string, formEditApi: Form, pipelineId?: string }[]>;

          if (forms.value) {
            for (let i = 0; i < forms.value.length; i++) {
              if (forms.value[i].id === id) {
                return forms.value[i];
              }
            }
          }
        }
      }
    }

    return null;
  };
}

export function hasSpaceFeaturePermission(id: keyof FeaturePermissions) {
  return (state: SpaceStateInCoreState): boolean => {
    return !!state.space.config.accessControl?.value?.features?.[id];
  };
}

export function hasSpaceEntityPermission(entityType: EntityNameEnum, id: EntityPermsEnum) {
  return (state: SpaceStateInCoreState): boolean => {
    return !!state.space.config.accessControl?.value?.entities?.[entityType]?.includes(id);
  };
}

export function shouldLoadSpaceUser(state: SpaceStateInCoreState): boolean {
  return !state.space.config.user;
}

export function getSpaceUser(state: SpaceStateInCoreState): ClientEntity {
  return state.space.config.user?.value || state.space.config.entities?.[EntityNameEnum.Client]?.value?.find(client => client.id === state.space.config.userId) || null;
}

export function getSpaceUserId(state: SpaceStateInCoreState): string {
  return state.space.config.userId;
}

export function shouldLoadSpaceEntities(entityType: EntityNameEnum) {
  return (state: SpaceStateInCoreState): boolean => {
    return !state.space.config.entities || !state.space.config.entities[entityType];
  };
}

export function getSpaceClients(state: SpaceStateInCoreState): ClientEntity[] {
  return state.space.config.entities?.[EntityNameEnum.Client]?.value || [];
}

export function getSpaceClientById(clientId: string) {
  return (state: SpaceStateInCoreState): ClientEntity => {
    return findById(getSpaceClients(state), clientId) || null;
  };
}

export function getSpaceSalesUnits(state: SpaceStateInCoreState): SalesUnitEntity[] {
  return state.space.config.entities?.[EntityNameEnum.SalesUnit]?.value || [];
}

export function getSpaceSalesUnitById(salesUnitId: string) {
  return (state: SpaceStateInCoreState): SalesUnitEntity => {
    return findById(getSpaceSalesUnits(state), salesUnitId) || null;
  };
}

export function getSpaceCurrencies(state: SpaceStateInCoreState): CurrencyEntity[] {
  return state.space.config.entities?.[EntityNameEnum.Currency]?.value || [];
}

export function getSpaceCurrencyById(currencyId: string) {
  return (state: SpaceStateInCoreState): CurrencyEntity => {
    return findById(getSpaceCurrencies(state), currencyId) || null;
  };
}

export function getSpaceBaseCurrency(state: SpaceStateInCoreState): CurrencyEntity {
  return getSpaceCurrencies(state).find(currency => currency.isBase) || null;
}

export function getSpaceLeadProcesses(state: SpaceStateInCoreState): LeadProcessEntity[] {
  return state.space.config.entities?.[EntityNameEnum.LeadProcess]?.value || [];
}

export function getSpaceLeadProcessById(leadProcessId: string) {
  return (state: SpaceStateInCoreState): LeadProcessEntity => {
    return findById(getSpaceLeadProcesses(state), leadProcessId) || null;
  };
}

export function getSpacePipelines(state: SpaceStateInCoreState): PipelineEntity[] {
  return state.space.config.entities?.[EntityNameEnum.Pipeline]?.value || [];
}

export function getSpacePipelineById(pipelineId: string) {
  return (state: SpaceStateInCoreState): PipelineEntity => {
    return findById(getSpacePipelines(state), pipelineId) || null;
  };
}

export function getSpaceAllowedPipelineIds(state: SpaceStateInCoreState): string[] {
  return state.space.config.allowedPipelineIds?.value || [];
}

export function getSpaceAccountTypes(state: SpaceStateInCoreState): AccountTypeEntity[] {
  return state.space.config.entities?.[EntityNameEnum.AccountType]?.value || [];
}

export function getSpaceAppointmentTypes(state: SpaceStateInCoreState): AppointmentTypeEntity[] {
  return state.space.config.entities?.[EntityNameEnum.AppointmentType]?.value || [];
}

export function getSpaceContactTypes(state: SpaceStateInCoreState): ContactTypeEntity[] {
  return state.space.config.entities?.[EntityNameEnum.ContactType]?.value || [];
}

export function getSpaceLeadTypes(state: SpaceStateInCoreState): LeadTypeEntity[] {
  return state.space.config.entities?.[EntityNameEnum.LeadType]?.value || [];
}

export function getSpaceTaskTypes(state: SpaceStateInCoreState): TaskTypeEntity[] {
  return state.space.config.entities?.[EntityNameEnum.TaskType]?.value || [];
}
