import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { DashboardClientItem } from '../../../domain/dashboard';

@Component({
  selector: 'ppl-dashboard-recipients-client',
  templateUrl: './dashboard-recipients-client.component.html',
  styleUrls: ['./dashboard-recipients-client.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardRecipientsClientComponent {

  @Input() items: DashboardClientItem[];
  @Input() largeCard: boolean;

}
