<ppl-input-number-clamp [minValue]="Number.MIN_SAFE_INTEGER"
                        [maxValue]="Number.MAX_SAFE_INTEGER"
                        [step]="0.01"
                        [value]="inputValue"
                        [disabled]="disabled"
                        [wheelDisabled]="true"
                        [class.base]="base"
                        (valueChange)="onForeignValueChange($event)"></ppl-input-number-clamp>

<ppl-select *ngIf="!base"
            [options]="currencyOptions"
            [value]="value.currencyId"
            [disabled]="disabled"
            (valueChange)="onCurrencyValueChange($event)"></ppl-select>

<div *ngIf="base"
     class="currency"
     [class.disabled]="disabled">
  {{baseCurrency.symbol}}
</div>