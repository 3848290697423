<ng-container *ngIf="itemMode === OutlookItemMode.Read">
  <ng-container *ngIf="itemType === OutlookItemType.Message">
    <div class="header"
         i18n="@@Saving_email">Saving email</div>

    <div class="description"
         i18n="@@You_can_select_what_you_would_like_to_save_into_Pipeliner_and_where_you_would_like_to_save_it.">
      You can select what you would like to save into Pipeliner and where you would like to save it.
    </div>

    <ppl-dashboard-menu-action description="Email will be stored as standard email message in Pipeliner and you can find it in a feed."
                               i18n-description="@@Email_will_be_stored_as_standard_email_message_in_Pipeliner_and_you_can_find_it_in_a_feed."
                               iconName="Save email"
                               i18n-iconName="@@Save_email"
                               name="Save email"
                               i18n-name="@@Save_email"
                               icon="dashboard-menu-save-email"
                               (actionClick)="onSaveEmailClick(EmailKind.Message)"></ppl-dashboard-menu-action>

    <ppl-dashboard-menu-action description="Email will be stored as email file including attachments in Pipeliner."
                               i18n-description="@@Email_will_be_stored_as_email_file_including_attachments_in_Pipeliner."
                               iconName="Save email as document"
                               i18n-iconName="@@Save_email_as_document"
                               name="Save email as document"
                               i18n-name="@@Save_email_as_document"
                               icon="dashboard-menu-save-email-as-document"
                               (actionClick)="onSaveEmailClick(EmailKind.Document)"></ppl-dashboard-menu-action>

    <ppl-dashboard-menu-action *ngIf="displaySaveAttachments"
                               description="Email attachments will be stored in Pipeliner."
                               i18n-description="@@Email_attachments_will_be_stored_in_Pipeliner."
                               iconName="Save email attachments"
                               i18n-iconName="@@Save_email_attachments"
                               name="Save email attachments"
                               i18n-name="@@Save_email_attachments"
                               icon="dashboard-menu-save-email-attachments"
                               (actionClick)="onSaveEmailClick(EmailKind.Attachments)"></ppl-dashboard-menu-action>
  </ng-container>

  <div class="header"
       i18n="@@Create_record_from_email">Create record from email</div>

  <div class="description"
       i18n="@@You can create new Pipeliner records with fields predefined by email data.">You can create new Pipeliner records with fields predefined by email data.</div>

  <ppl-dashboard-menu-action *ngIf="displayCreateLead"
                             [description]="'Create_a_new_lead_based_on_current_email.' | t"
                             [iconName]="'Create_lead' | t"
                             [name]="'New_lead' | t"
                             icon="dashboard-menu-create-lead-from-emal"
                             (actionClick)="onCreateLeadClick()"></ppl-dashboard-menu-action>

  <ppl-dashboard-menu-action *ngIf="displayCreateOpportunity"
                             [description]="'Create_a_new_opportunity_based_on_current_email.' | t"
                             [iconName]="'Create_opportunity' | t"
                             [name]="'New_opportunity' | t"
                             icon="dashboard-menu-create-opportunity-from-emal"
                             (actionClick)="onCreateOpportunityClick()"></ppl-dashboard-menu-action>

  <ppl-dashboard-menu-action *ngIf="displayCreateAccount"
                             [description]="'Create_a_new_account_based_on_current_email.' | t"
                             [iconName]="'Create_account' | t"
                             [name]="'New_account' | t"
                             icon="dashboard-menu-create-account-from-emal"
                             (actionClick)="onCreateAccountClick()"></ppl-dashboard-menu-action>

  <ppl-dashboard-menu-action *ngIf="displayCreateContact"
                             [description]="'Create_a_new_contact_based_on_current_email.' | t"
                             [iconName]="'Create_contact' | t"
                             [name]="'New_contact' | t"
                             icon="dashboard-menu-create-contact-from-emal"
                             (actionClick)="onCreateContactClick()"></ppl-dashboard-menu-action>

  <ppl-dashboard-menu-action *ngIf="displayCreateAppointment || displayCreateTask"
                             description="Create a new activity based on current email."
                             i18n-description="@@Create_a_new_activity_based_on_current_email."
                             iconName="Create activity"
                             i18n-iconName="@@Create_activity"
                             name="New activity"
                             i18n-name="@@New_activity"
                             icon="dashboard-menu-create-task-from-emal"
                             [menuItems]="createActivityMenuItems"
                             (menuItemClick)="onCreateActivityMenuItemClick($event)"></ppl-dashboard-menu-action>
</ng-container>

<ng-container *ngIf="itemMode === OutlookItemMode.Compose">
  <div class="header"
       i18n="@@Enrich_your_email">Enrich your email</div>

  <div class="description"
       i18n="@@You_can_add_contacts_from_Pipeliner_CRM_as_email_recipients_or_attach_Pipeliner_documents_directly_to_your_email.">
    You can add contacts from Pipeliner CRM as email recipients or attach Pipeliner documents directly to your email.
  </div>

  <ppl-dashboard-menu-action description="You can add contacts or accounts individually or whole sales teams as email recipients."
                             i18n-description="@@You_can_add_contacts_or_accounts_individually_or_whole_sales_teams_as_email_recipients."
                             iconName="Add contacts"
                             i18n-iconName="@@Add_contacts"
                             name="Add contacts to recipients"
                             i18n-name="@@Add_contacts_to_recipients"
                             icon="dashboard-menu-create-contact-from-emal"
                             [menuItems]="addRecipientMenuItems"
                             (menuItemClick)="onAddRecipientMenuItemClick($event)"></ppl-dashboard-menu-action>

  <ppl-dashboard-menu-action description="Find and attach your stored documents from Pipeliner directly to email."
                             i18n-description="@@Find_and_attach_your_stored_documents_from_Pipeliner_directly_to_email."
                             iconName="Attach documents"
                             i18n-iconName="@@Attach_documents"
                             name="Attach documents"
                             i18n-name="@@Attach_documents"
                             icon="dashboard-menu-save-email-attachments"
                             (actionClick)="onAttachDocumentClick()"></ppl-dashboard-menu-action>

  <ppl-dashboard-menu-action description="Create your email quickly by using an email template from Pipeliner."
                             i18n-description="@@Create_your_email_quickly_by_using_an_email_template_from_Pipeliner."
                             iconName="Use template"
                             i18n-iconName="@@Use_template"
                             name="Use email template"
                             i18n-name="@@Use_email_template"
                             icon="dashboard-menu-use-email-template"
                             (actionClick)="onUseTemplateClick()"></ppl-dashboard-menu-action>
</ng-container>
