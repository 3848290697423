import type { CreateOpportunityInput, OpportunityEntity } from '@ppl/graphql-space-api';
import { apolloClean } from '@ppl/utils';
import { EntityFormControlKind } from '../../../entity-form/domain/controls';
import type { EntityFormApiFieldTypeAdapter } from '../entity-form-api';

export const CurrencyForeignFieldTypeAdapter: EntityFormApiFieldTypeAdapter<OpportunityEntity, string | null, CreateOpportunityInput> = {
  id: 'currency_foreign',
  getControl({ entities, fieldDescriptor, formField }) {
    return {
      id: fieldDescriptor.id,
      label: formField?.label ?? fieldDescriptor.name,
      currencies: entities.currencies,
      kind: EntityFormControlKind.Currency
    };
  },
  getValue({ value }) {
    return apolloClean(value);
  }
};
