import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { OfficeTokenService } from '@ppl/auth';
import { combineLatest, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import type { EmailAttachment } from '../../email/domain/email';

@Injectable()
export class OutlookSoapService {

  constructor(
    private httpClient: HttpClient,
    private ngZone: NgZone,
    private officeTokenService: OfficeTokenService
  ) {}

  fetchContentsForAttachments(attachments: EmailAttachment[]) {
    return this.getEwsToken().pipe(
      switchMap(ewsToken => {
        return combineLatest(attachments.map(attachment => {
          return this.fetchSoapProxy(ewsToken, {
            ewsAttachmentID: attachment.id
          });
        }));
      }),
      catchError(error => {
        if (Office.context.requirements.isSetSupported('MailBox', '1.8')) {
          // Try to get attachments using Office.js API in supported clients as a last resort
          return combineLatest(attachments.map(attachment => {
            return this.getAttachmentContent(attachment.id);
          }));
        } else {
          return throwError(error);
        }
      }),
      map(contents => {
        return attachments.map((attachment, index) => ({
          ...attachment,
          content: contents[index]
        }));
      })
    );
  }

  fetchEmlContent() {
    return this.getEwsToken().pipe(
      switchMap(ewsToken => {
        return this.fetchSoapProxy(ewsToken, {
          ewsItemID: Office.context.mailbox.item.itemId
        });
      })
    );
  }

  private getAttachmentContent(id: string) {
    const result$ = new Subject<string>();

    Office.context.mailbox.item.getAttachmentContentAsync(id, result => {
      this.ngZone.run(() => {
        if (result.status === Office.AsyncResultStatus.Failed) {
          result$.error(new Error(JSON.stringify(result.error)));
        } else {
          result$.next(result.value.content);
        }
      });
    });

    return result$.asObservable();
  }

  private fetchSoapProxy(ewsToken: string, data: object) {
    return this.officeTokenService.getToken().pipe(
      switchMap(authToken => {
        const params = {
          ewsURL: Office.context.mailbox.ewsUrl,
          ewsToken,
          ...data
        };

        // Note: We create URL params manually, because although HttpClient has params option, it behaves weirdly (replaces "+" with space)
        return this.httpClient.get(`${environment.soapProxyUrl}?${Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&')}`, {
          headers: {
            Authorization: `PipelinerToken ${authToken}`
          },
          responseType: 'text'
        });
      })
    );
  }

  private getEwsToken() {
    const result$ = new Subject<string>();

    Office.context.mailbox.getCallbackTokenAsync(result => {
      this.ngZone.run(() => {
        if (result.status === Office.AsyncResultStatus.Failed) {
          result$.error(new EwsTokenError(JSON.stringify({ error: result.error, diagnostics: result.diagnostics })));
        } else {
          result$.next(result.value);
        }
      });
    });

    return result$.asObservable();
  }

}

export class EwsTokenError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'EwsTokenError';
    Object.setPrototypeOf(this, EwsTokenError.prototype);
  }
}

export class SoapProxyError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'SoapProxyError';
    Object.setPrototypeOf(this, SoapProxyError.prototype);
  }
}
