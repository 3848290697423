import { prod } from '../../../../libs/environment/src';
import { VERSION } from './config';
import type { Environment } from './environment.interface';

const S3Prefix = 'outlook-add-in/production/cloud/latest';

export const environment: Environment = {
  apiUserGqlUrl: prod.apiUserGqlUrl,
  appUrl: 'https://outlook-add-in.pipelinersales.com',
  baseUrl: '',
  crmUrl: prod.crmUrl,
  deployUrl: `https://static-webapps.pipelinersales.com/${S3Prefix}`,
  firebase: {
    enabled: true,
    configData: {
      apiKey: 'AIzaSyClaqJctWRbnVSVusbYAmWSnTP2bNDLTVw',
      authDomain: 'pipeliner-mobile-app.firebaseapp.com',
      databaseURL: 'https://pipeliner-mobile-app.firebaseio.com',
      projectId: 'pipeliner-mobile-app',
      storageBucket: 'pipeliner-mobile-app.appspot.com',
      messagingSenderId: '1064196264335',
      appId: '1:1064196264335:web:6bfc730c8a4f52f0523bfa',
      measurementId: 'G-YVQ9F7GWG8'
    }
  },
  name: 'production',
  production: true,
  sentryEnabled: true,
  signUpUrl: prod.signUpUrl,
  soapProxyUrl: 'https://api.pipelinersales.com/api/proxy/outlook',
  ssoUrl: prod.ssoUrl,
  S3Prefix,
  trackingUrl: 'https://api-global.pipeliner-redirect.com',
  version: VERSION
};
