<ppl-card-inline-field icon="entity-detail-email"
                       [value]="entity.email1"
                       emptyLabel="Add Email"
                       i18n-emptyLabel="@@Add_Email"
                       [disabled]="!entity.instancePermissions.includes(EntityPermsEnum.Write)"
                       (valueChange)="emailChange.emit($event)">
  <a [href]="'mailto:' + entity.email1">{{entity.email1}}</a>
</ppl-card-inline-field>

<ppl-card-inline-field icon="entity-detail-call"
                       [value]="entity.phone1"
                       emptyLabel="Add Phone"
                       i18n-emptyLabel="@@Add_Phone"
                       [disabled]="!entity.instancePermissions.includes(EntityPermsEnum.Write)"
                       (valueChange)="phoneChange.emit($event)">
  <span class="phone"
        (click)="onPhoneClick()">{{entity.phone1}}</span>
</ppl-card-inline-field>

<ppl-card-inline-field icon="entity-detail-address"
                       [value]="entity | pplLocation"
                       emptyLabel="Add Location"
                       i18n-emptyLabel="@@Add_Location"
                       [disabled]="!entity.instancePermissions.includes(EntityPermsEnum.Write)"
                       (valueChange)="locationChange.emit($event)">
  <ng-template #editorTemplate
               let-valueChange="valueChange">
    <ppl-location-picker (placeSelect)="valueChange($event)"></ppl-location-picker>
  </ng-template>

  <a [href]="locationUrl"
     target="_blank">{{entity | pplLocation}}</a>
</ppl-card-inline-field>