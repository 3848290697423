import type { Environment } from './interface';

export const prod: Environment = {
  apiBusinessGqlUrl: 'https://business-center.pipelinersales.com/api/business/graphql',
  apiCustomerGqlOrigin: 'https://customer-portal.pipelinersales.com',
  apiUserGqlUrl: 'https://api.pipelinersales.com/api/user/graphql',
  apiSchedulerGqlUrl: 'https://api.pipelinersales.com/api/scheduler/public/graphql',
  businessCenterUrl: 'https://business-center.pipelinersales.com',
  customerPortalUrl: 'https://customer-portal.pipelinersales.com',
  crmUrl: 'https://crm.pipelinersales.com',
  schedulerUrl: 'https://scheduler.pipelinersales.com',
  signUpUrl: 'https://pipelinersales.com/crm/free-trial/',
  ssoUrl: 'https://accounts.pipelinersales.com'
};
