import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@ppl/auth';
import { PplAccountClassModule } from '@ppl/ui/account-class';
import { PplCheckboxModule } from '@ppl/ui/checkbox';
import { PplInputModule } from '@ppl/ui/input';
import { PplLoadingModule } from '@ppl/ui/loading';
import { PplMultipleSelectModule } from '@ppl/ui/multiple-select';
import { PplRankingModule } from '@ppl/ui/ranking';
import { PplSelectModule } from '@ppl/ui/select';
import { PplTimeInputModule } from '@ppl/ui/time-input';
import { CurrencyModule } from '../currency/currency.module';
import { DateTimeModule } from '../date-time/date-time.module';
import { EntityControlItemModule } from '../entity-control-item/entity-control-item.module';
import { EntityControlModule } from '../entity-control/entity-control.module';
import { EntityFormComponent } from './components/entity-form/entity-form.component';
import { CanLeaveEntityFormGuard } from './router/can-leave-entity-form.service';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    CurrencyModule,
    DateTimeModule,
    EntityControlModule,
    EntityControlItemModule,
    PplAccountClassModule,
    PplCheckboxModule,
    PplInputModule,
    PplLoadingModule,
    PplMultipleSelectModule,
    PplRankingModule,
    PplSelectModule,
    PplTimeInputModule,
    ReactiveFormsModule
  ],
  declarations: [
    EntityFormComponent
  ],
  exports: [
    EntityFormComponent
  ],
  providers: [
    CanLeaveEntityFormGuard
  ]
})
export class EntityFormModule {}
