import { Injectable } from '@angular/core';
import { PplNamePipe } from '@ppl/auth';
import { ActionsUtilsService } from '@ppl/core';
import { getEntityName } from '@ppl/domain';
import { FirebaseEventsEnum, FirebaseService } from '@ppl/firebase';
import type { AccountEntity, AppointmentEntity, ContactEntity, CreateTaskInput, CreateTaskReminderInput, LeadEntity, OpportunityEntity, TaskEntity} from '@ppl/graphql-space-api';
import { ValidationLevel } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { getSpaceUser, SpaceService } from '@ppl/space';
import { PplDialogService } from '@ppl/ui/dialog';
import moment from 'moment';
import { of, throwError } from 'rxjs';
import { switchMap, takeWhile, tap } from 'rxjs/operators';
import { CoreStore } from '../../../../store/core.store';
import { ToastService } from '../../toast/services/toast.service';
import { SetReminderComponent } from '../components/set-reminder/set-reminder.component';
import { ReminderKind } from '../domain/reminder';
import { gqlCreateTaskMutation, gqlCreateTaskReminderMutation } from './activity.graphql';

@Injectable()
export class ReminderService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private dialogService: PplDialogService,
    private firebaseService: FirebaseService,
    private i18nService: I18nService,
    private namePipe: PplNamePipe,
    private spaceService: SpaceService,
    private store: CoreStore,
    private toastService: ToastService
  ) {}

  setReminder(kind: ReminderKind, entity: AccountEntity | AppointmentEntity | ContactEntity | LeadEntity | OpportunityEntity | TaskEntity) {
    return this.actionsUtilsService.doAction(of(null).pipe(
      switchMap(() => {
        let setDate = moment();

        switch (kind) {
          case ReminderKind.InMinutes:
            setDate.add(20, 'minutes');
            break;
          case ReminderKind.InHour:
            setDate.add(1, 'hour');
            break;
          case ReminderKind.Tomorrow:
            setDate.add(1, 'day').set({ hours: DefaultHour, minutes: 0, seconds: 0, milliseconds: 0 });
            break;
          case ReminderKind.NextWeek:
            setDate.add(1, 'week').startOf('isoWeek').set({ hours: DefaultHour, minutes: 0, seconds: 0, milliseconds: 0 });
            break;
          case ReminderKind.Custom:
            return this.dialogService.open<SetReminderComponent, never, string>(SetReminderComponent, {
              autoFocus: false
            }).afterClosed().pipe(takeWhile(dateTime => !!dateTime));
        }

        return of(setDate.toISOString());
      }),
      switchMap(setDate => {
        if (entity.__typename === 'AppointmentEntity') {
          return throwError(new Error('Unsupported'));
        } else if (entity.__typename === 'TaskEntity') {
          return this.createTaskReminder(entity, setDate);
        }

        return this.createTaskWithReminder(entity, setDate);
      }),
      tap(() => {
        this.toastService.open({
          text: this.i18nService.translate('Reminder_was_set')
        });

        this.firebaseService.logEvent(FirebaseEventsEnum.SetReminder);
      })
    ));
  }

  private createTaskWithReminder(entity: AccountEntity | ContactEntity | LeadEntity | OpportunityEntity, setDate: string) {
    return this.spaceService.fetchConfig({
      user: true
    }).pipe(
      switchMap(() => {
        const entityName = getEntityName(entity, this.namePipe);
        const userClient = this.store.get(getSpaceUser);

        const input: CreateTaskInput = {
          subject: `${this.i18nService.translate('Reminder')}: ${entityName}`,
          ownerId: userClient.id,
          unitId: userClient.defaultUnitId,
          description: `${this.i18nService.translate('This_task_has_been_created_with_reminder_in_Outlook_add-in_for')} ${entityName}`,
          reminder: {
            ownerId: userClient.id,
            setDate
          }
        };

        switch (entity.__typename) {
          case 'AccountEntity':
            input.accountRelations = [{
              accountId: entity.id
            }];
            break;
          case 'ContactEntity':
            input.contactRelations = [{
              contactId: entity.id
            }];
            break;
          case 'LeadEntity':
            input.leadRelations = [{
              leadOpptyId: entity.id
            }];
            break;
          case 'OpportunityEntity':
            input.opportunityRelations = [{
              leadOpptyId: entity.id
            }];
            break;
        }

        return this.spaceService.gqlClient.mutate({
          mutation: gqlCreateTaskMutation,
          variables: {
            input,
            validationLevel: [ValidationLevel.SKIP_ALL]
          }
        });
      })
    );
  }

  private createTaskReminder(entity: TaskEntity, setDate: string) {
    return this.spaceService.fetchConfig({
      user: true
    }).pipe(
      switchMap(() => {
        const userClient = this.store.get(getSpaceUser);

        const input: CreateTaskReminderInput = {
          ownerId: userClient.id,
          setDate,
          taskId: entity.id
        };

        return this.spaceService.gqlClient.mutate({
          mutation: gqlCreateTaskReminderMutation,
          variables: {
            input,
            validationLevel: [ValidationLevel.SKIP_ALL]
          }
        });
      })
    );
  }

}

const DefaultHour = 9;
