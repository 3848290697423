import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddinsSharedModule } from '@ppl/addins-shared';
import { I18nModule } from '@ppl/i18n';
import { PplIconModule } from '@ppl/ui/icon';
import { PplPopoverModule } from '@ppl/ui/popover';
import { PplPopoverMenuModule } from '@ppl/ui/popover-menu';
import { CardModule } from '../shared/card/card.module';
import { EntityCardModule } from '../shared/entity-card/entity-card.module';
import { OutlookModule } from '../shared/outlook/outlook.module';
import { PageModule } from '../shared/page/page.module';
import { ToastModule } from '../shared/toast/toast.module';
import { TrackingModule } from '../shared/tracking/tracking.module';
import { DashboardAddCardComponent } from './components/dashboard-add-card/dashboard-add-card.component';
import { DashboardGroupComponent } from './components/dashboard-group/dashboard-group.component';
import { DashboardMenuActionComponent } from './components/dashboard-menu/dashboard-menu-action/dashboard-menu-action.component';
import { DashboardMenuComponent } from './components/dashboard-menu/dashboard-menu.component';
import { DashboardCardListComponent } from './components/dashboard-recipients/dashboard-card-list/dashboard-card-list.component';
import { DashboardRecipientsAccountComponent } from './components/dashboard-recipients/dashboard-recipients-account/dashboard-recipients-account.component';
import { DashboardRecipientsClientComponent } from './components/dashboard-recipients/dashboard-recipients-client/dashboard-recipients-client.component';
import { DashboardRecipientsContactComponent } from './components/dashboard-recipients/dashboard-recipients-contact/dashboard-recipients-contact.component';
import { DashboardRecipientsOtherComponent } from './components/dashboard-recipients/dashboard-recipients-other/dashboard-recipients-other.component';
import { DashboardRecipientsUserClientComponent } from './components/dashboard-recipients/dashboard-recipients-user-client/dashboard-recipients-user-client.component';
import { DashboardRecipientsUserComponent } from './components/dashboard-recipients/dashboard-recipients-user/dashboard-recipients-user.component';
import { DashboardRecipientsComponent } from './components/dashboard-recipients/dashboard-recipients.component';
import { DashboardSenderComponent } from './components/dashboard-sender/dashboard-sender.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardService } from './services/dashboard.service';

@NgModule({
  imports: [
    AddinsSharedModule,
    CardModule,
    CommonModule,
    EntityCardModule,
    I18nModule,
    OutlookModule,
    PageModule,
    PplIconModule,
    PplPopoverModule,
    PplPopoverMenuModule,
    ToastModule,
    TrackingModule
  ],
  declarations: [
    DashboardComponent,
    DashboardAddCardComponent,
    DashboardCardListComponent,
    DashboardGroupComponent,
    DashboardMenuComponent,
    DashboardMenuActionComponent,
    DashboardRecipientsComponent,
    DashboardRecipientsAccountComponent,
    DashboardRecipientsClientComponent,
    DashboardRecipientsContactComponent,
    DashboardRecipientsOtherComponent,
    DashboardRecipientsUserComponent,
    DashboardRecipientsUserClientComponent,
    DashboardSenderComponent
  ],
  exports: [
    DashboardComponent
  ],
  providers: [
    DashboardService
  ]
})
export class DashboardModule {}
