import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'ppl-card-filter-tab',
  templateUrl: './card-filter-tab.component.html',
  styleUrls: ['./card-filter-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardFilterTabComponent {

  @Input() color: string;
  @Input() count: number;
  @Input() description?: string;
  @Input() label: string;
  @Input() @HostBinding('class.selected') selected?: boolean;
  @Input() value: string;

  @Output() tabClick = new EventEmitter();

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  setSelected(selected: boolean) {
    if (selected !== this.selected) {
      this.selected = selected;
      this.changeDetectorRef.detectChanges();
    }
  }

  @HostListener('click')
  onClick() {
    this.tabClick.emit();
  }

}
