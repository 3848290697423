import type { ComponentType } from '@angular/cdk/portal';
import type { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { findById, MemoizeLast, trackById } from '@ppl/utils';
import { PplPopoverControl } from '../popover';
import type { PplPopoverDirective  } from '../popover';
import { PPL_TOOLBAR_BUTTON_DATA } from '../toolbar';

@Component({
  selector: 'ppl-menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplMenuItemsComponent implements OnInit, OnDestroy {

  @Input() items: (PplMenuItem | null)[];
  @Input() value?: string;
  @Input() parentPopover: PplPopoverControl;

  // Inputs for internal use only
  @Input() openPopovers = new Set<PplPopoverControl>(); // Created on root level and shared across all nested menu items

  @Output() itemClick = new EventEmitter<string>();

  openItemId: string | null = null;
  openItemPopoverDirective: PplPopoverDirective | null = null;

  trackById = trackById;

  @MemoizeLast<PplMenuItemsComponent>(['items'])
  get hasIcons() {
    return this.items.some(item => item?.icon);
  }

  @MemoizeLast<PplMenuItemsComponent>(['items'])
  get hasChildren() {
    return this.items.some(item => item?.children);
  }

  constructor(
    private injector: Injector
  ) {}

  ngOnInit() {
    this.openPopovers.add(this.parentPopover);
  }

  ngOnDestroy() {
    this.openPopovers.delete(this.parentPopover);
  }

  onLeafItemClick(id: string) {
    const item = findById(this.items.filter(Boolean), id);

    if (!item.children) {
      this.itemClick.emit(id);
    }
  }

  onItemClick(id: string) {
    this.itemClick.emit(id);
  }

  onItemMouseEnter(item: PplMenuItem, popoverDirective: PplPopoverDirective) {
    if (this.openItemPopoverDirective) {
      this.openItemPopoverDirective.hide();
    }

    if (item.children) {
      this.openItemId = item.id;
      this.openItemPopoverDirective = popoverDirective;

      popoverDirective.show();
    } else {
      this.openItemId = null;
    }
  }

  @MemoizeLast<PplMenuItemsComponent>([])
  getInjector(item: PplMenuItem) {
    return Injector.create({
      providers: [{
        provide: PPL_TOOLBAR_BUTTON_DATA,
        useValue: {
          close: () => {
            Array.from(this.openPopovers).forEach(openPopover => openPopover.close());
          },
          value: item.data
        }
      }],
      parent: this.injector
    });
  }

  trackByItem(index: number, item: PplMenuItem | null) {
    return item ? item.id : index;
  }

}

export interface PplMenuItem {
  children?: {
    component: ComponentType<any>;
  } | {
    items: PplMenuItem[];
    emptyLabel?: string;
  };
  data?: any;
  disabled?: boolean;
  disableOnly?: boolean;
  icon?: string;
  id: string;
  label?: string;
  template?: TemplateRef<any>;
  title?: string;
}
