import type { ContactFilterInput } from '@ppl/graphql-space-api';

export function createContactNameFilterInput(filter: string): ContactFilterInput {
  const filterTokens = filter.trim().split(' ');

  if (filterTokens.length === 1) {
    return {
      OR: [
        { firstName: { icontains: [filterTokens[0]] } },
        { middleName: { icontains: [filterTokens[0]] } },
        { lastName: { icontains: [filterTokens[0]] } }
      ]
    };
  } else if (filterTokens.length === 2) {
    return {
      OR: [{
        AND: [
          { firstName: { icontains: [filterTokens[0]] } },
          { firstName: { icontains: [filterTokens[1]] } }
        ]
      }, {
        AND: [
          { middleName: { icontains: [filterTokens[0]] } },
          { middleName: { icontains: [filterTokens[1]] } }
        ]
      }, {
        AND: [
          { lastName: { icontains: [filterTokens[0]] } },
          { lastName: { icontains: [filterTokens[1]] } }
        ]
      }, {
        AND: [
          { firstName: { icontains: [filterTokens[0]] } },
          { middleName: { icontains: [filterTokens[1]] } }
        ]
      }, {
        AND: [
          { firstName: { icontains: [filterTokens[1]] } },
          { middleName: { icontains: [filterTokens[0]] } }
        ]
      }, {
        AND: [
          { firstName: { icontains: [filterTokens[0]] } },
          { lastName: { icontains: [filterTokens[1]] } }
        ]
      }, {
        AND: [
          { firstName: { icontains: [filterTokens[1]] } },
          { lastName: { icontains: [filterTokens[0]] } }
        ]
      }, {
        AND: [
          { middleName: { icontains: [filterTokens[0]] } },
          { lastName: { icontains: [filterTokens[1]] } }
        ]
      }, {
        AND: [
          { middleName: { icontains: [filterTokens[1]] } },
          { lastName: { icontains: [filterTokens[0]] } }
        ]
      }]
    };
  } else {
    return {
      OR: [{
        AND: [
          { firstName: { icontains: filterTokens } },
          { middleName: { icontains: filterTokens } },
          { lastName: { icontains: filterTokens } }
        ]
      }]
    };
  }
}
