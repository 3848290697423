import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { AccountEntity, AppointmentEntity, ContactEntity, LeadEntity, OpportunityEntity, TaskEntity } from '@ppl/graphql-space-api';

@Component({
  selector: 'ppl-date-card',
  templateUrl: './date-card.component.html',
  styleUrls: ['./date-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateCardComponent {

  @Input() entity: AccountEntity | AppointmentEntity | ContactEntity | LeadEntity | OpportunityEntity | TaskEntity;

}
