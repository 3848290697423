import { AsyncState, createReducer } from '@ppl/store';
import type { AuthActions } from './auth.actions';
import type { AuthState } from './auth.state';
import type { UserProfileEntity, UserSettings } from '@ppl/graphql-user-api';

const defaultState: AuthState = {
  user: { state: AsyncState.IDLE },
  angularLocaleId: 'en-US',
  ssoToken: null,
  ssoSpaceId: null
};

export const authReducer = createReducer<AuthState, AuthActions>({
  Auth_FetchUser(state, { payload }) {
    return { ...state, user: payload };
  },
  Auth_UpdateSpace(state: AuthState, { payload }) {
    const spaces = state.user.value.spaces.map((space) => space.id === payload.id ? payload : space);
    const value = { ...state.user.value, spaces };
    const user = { ...state.user, value };
    return { ...state, user };
  },
  Auth_UpdateSpaceSettings(state: AuthState, { payload }) {
    const { settings, spaceId } = payload;
    return {
      ...state, user: {
        ...state.user,
        value: {
          ...state.user.value,
          spaces: state.user.value.spaces.map(
            space => space.id !== spaceId
              ? space
              : {
                ...space,
                settings
              }
          )
        }
      }
    };
  },
  Auth_UpdateProfile(state: AuthState, { payload }) {
    const profile = payload as UserProfileEntity;
    return {
      ...state,
      user: {
        ...state.user,
        value: {
          ...state.user.value,
          profile: {
            ...state.user.value.profile,
            ...profile // simply overwrite with incoming data
          }
        }
      }
    };
  },
  Auth_UpdateSettings(state: AuthState, { payload }) {
    const settings = payload as UserSettings;
    return {
      ...state,
      user: {
        ...state.user,
        value: {
          ...state.user.value,
          settings: {
            ...state.user.value.settings,
            ...settings // simply overwrite with incoming data
          }
        }
      }
    };
  },
  Auth_UpdateSsoResult(state, { payload }) {
    return {
      ...state,
      ssoToken: payload.token,
      ssoSpaceId: payload.spaceId
    };
  },
  Auth_UpdateSsoToken(state, { payload }) {
    return {
      ...state,
      ssoToken: payload
    };
  },
  Auth_UpdateAngularLocaleId(state: AuthState, { payload }) {
    return {
      ...state,
      angularLocaleId: payload
    };
  }
}, defaultState);
