import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EntityNameEnum, OpportunityEntity, OpptyStatusEnum } from '@ppl/graphql-space-api';
import { getSpaceCurrencyById } from '@ppl/space';
import { MemoizeLast } from '@ppl/utils';
import { CoreStore } from '../../../../../store/core.store';
import { AvatarKind } from '../../../avatar/domain/avatar';

@Component({
  selector: 'ppl-opportunity-card-medium',
  templateUrl: './opportunity-card-medium.component.html',
  styleUrls: ['./opportunity-card-medium.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunityCardMediumComponent {

  @Input() entity: OpportunityEntity;

  AvatarKind = AvatarKind;
  EntityNameEnum = EntityNameEnum;
  OpptyStatusEnum = OpptyStatusEnum;

  @MemoizeLast<OpportunityCardMediumComponent>(['entity'])
  get valueCurrencyCode() {
    return this.store.get(getSpaceCurrencyById(this.entity.value.currencyId)).code;
  }

  constructor(
    private store: CoreStore
  ) {}

}
