import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fetchAsyncValue } from '@ppl/core';
import { EntityPermsEnum } from '@ppl/graphql-space-api';
import type { AsyncValue } from '@ppl/store';
import { AsyncState } from '@ppl/store';
import type { Observable} from 'rxjs';
import { merge, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActivityService } from '../../../shared/activity/services/activity.service';
import type { TaskData } from '../../domain/task';
import { TaskService } from '../../services/task.service';

@Component({
  selector: 'ppl-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskComponent implements OnInit {

  data$: Observable<AsyncValue<TaskData>>;

  updateData$ = new Subject<TaskData>();

  AsyncState = AsyncState;
  EntityPermsEnum = EntityPermsEnum;

  constructor(
    private activityService: ActivityService,
    private route: ActivatedRoute,
    private taskService: TaskService
  ) {}

  ngOnInit() {
    this.data$ = this.route.params.pipe(
      switchMap(params => {
        return fetchAsyncValue(merge(
          this.taskService.fetchData(params.id),
          this.updateData$.asObservable()
        ));
      })
    );
  }

  onDueDateChange(data: TaskData, dueDate: string) {
    this.updateData$.next({
      ...data,
      entity: {
        ...data.entity,
        dueDate
      }
    });
  }

  onMoveTaskClick(data: TaskData) {
    this.activityService.moveTask({
      entity: data.entity
    }).subscribe(status => {
      this.updateData$.next({
        ...data,
        entity: {
          ...data.entity,
          status
        }
      });
    });
  }

}
