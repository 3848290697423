<ppl-card-group [label]="'Opportunities' | t"
                [count]="entities.length"
                [displayCreate]="displayCreate"
                (createClick)="onCreateClick()">
  <ppl-card-filter [value]="filterValue"
                   (valueChange)="onFilterValueChange($event)">
    <ppl-card-filter-tab color="#004578"
                         label="Open"
                         i18n-label="@@EnumOpptyStatusEnum_Open"
                         [count]="openEntities.length"
                         [description]="openEntitiesValue | pplCurrency:baseCurrencyCode"
                         [value]="OpptyStatusEnum.Open"></ppl-card-filter-tab>

    <ppl-card-filter-tab color="#107b10"
                         label="Won"
                         i18n-label="@@EnumOpptyStatusEnum_Won"
                         [count]="wonEntities.length"
                         [description]="wonEntitiesValue | pplCurrency:baseCurrencyCode"
                         [value]="OpptyStatusEnum.Won"></ppl-card-filter-tab>

    <ppl-card-filter-tab color="#e81123"
                         label="Lost"
                         i18n-label="@@EnumOpptyStatusEnum_Lost"
                         [count]="lostEntities.length"
                         [description]="lostEntitiesValue | pplCurrency:baseCurrencyCode"
                         [value]="OpptyStatusEnum.Lost"></ppl-card-filter-tab>
  </ppl-card-filter>

  <ppl-card-list *ngIf="filteredEntities.length !== 0"
                 [entities]="filteredEntities">
    <ng-template #cardTemplate
                 let-entity>
      <ppl-opportunity-card-medium [entity]="entity"
                                   (click)="onCardClick(entity)"></ppl-opportunity-card-medium>
    </ng-template>
  </ppl-card-list>

  <ng-container *ngIf="filteredEntities.length === 0">
    <ng-container *ngIf="parentEntity.__typename === 'AccountEntity'">
      <ppl-card-message *ngIf="!filterValue"
                        i18n="@@Account_has_no_related_opportunities">{{ 'Account' | t }} has no related {{ 'Opportunities' | t }}</ppl-card-message>

      <ppl-card-message *ngIf="filterValue === OpptyStatusEnum.Open"
                        i18n="@@Account_has_no_related_open_opportunities">{{ 'Account' | t }} has no related open {{ 'Opportunities' | t }}</ppl-card-message>

      <ppl-card-message *ngIf="filterValue === OpptyStatusEnum.Won"
                        i18n="@@Account_has_no_related_won_opportunities">{{ 'Account' | t }} has no related won {{ 'Opportunities' | t }}</ppl-card-message>

      <ppl-card-message *ngIf="filterValue === OpptyStatusEnum.Lost"
                        i18n="@@Account_has_no_related_lost_opportunities">{{ 'Account' | t }} has no related lost {{ 'Opportunities' | t }}</ppl-card-message>
    </ng-container>

    <ng-container *ngIf="parentEntity.__typename === 'ContactEntity'">
      <ppl-card-message *ngIf="!filterValue"
                        i18n="@@Contact_has_no_related_opportunities">{{ 'Contact' | t }} has no related {{ 'Opportunities' | t }}</ppl-card-message>

      <ppl-card-message *ngIf="filterValue === OpptyStatusEnum.Open"
                        i18n="@@Contact_has_no_related_open_opportunities">{{ 'Contact' | t }} has no related open {{ 'Opportunities' | t }}</ppl-card-message>

      <ppl-card-message *ngIf="filterValue === OpptyStatusEnum.Won"
                        i18n="@@Contact_has_no_related_won_opportunities">{{ 'Contact' | t }} has no related won {{ 'Opportunities' | t }}</ppl-card-message>

      <ppl-card-message *ngIf="filterValue === OpptyStatusEnum.Lost"
                        i18n="@@Contact_has_no_related_lost_opportunities">{{ 'Contact' | t }} has no related lost {{ 'Opportunities' | t }}</ppl-card-message>
    </ng-container>
  </ng-container>
</ppl-card-group>
