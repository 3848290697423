import type { EntityNameEnum } from '@ppl/graphql-space-api';

export enum EmailKind {
  Message = 'Message',
  Document = 'Document',
  Attachments = 'Attachments'
}

export interface EmailRelation {
  entityId: string;
  entityType: EntityNameEnum;
}

export interface EmailAttachment {
  content: string | null;
  id: string;
  fileName: string;
}

export const MaxDocumentNameLength = 255;
