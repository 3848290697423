<ppl-dashboard-card-list icon="unknown-blue"
                         [items]="items"
                         [name]="items.length + ' unknown contacts'">
  <ng-template #cardTemplate
               let-item
               let-active="active">
    <ng-container *ngIf="largeCard">
      <ppl-other-card-large [person]="item.person"></ppl-other-card-large>

      <ppl-actions-card [entity]="null"></ppl-actions-card>
    </ng-container>

    <ppl-other-card-small *ngIf="!largeCard"
                          [person]="item.person"
                          [active]="active"></ppl-other-card-small>
  </ng-template>
</ppl-dashboard-card-list>