<ppl-page>
  <ng-template #headerTemplate
               let-globalSearch="globalSearch">
    <div class="header">
      <ppl-page-header-button class="button-menu"
                              icon="toolbar-white-menu"
                              [selected]="menuVisible"
                              (click)="menuVisible = !menuVisible"></ppl-page-header-button>

      <ng-template [ngTemplateOutlet]="globalSearch"></ng-template>

      <ng-container i18n="@@Overview">Overview</ng-container>

      <ppl-page-header-button class="button-profile"
                              icon="toolbar-white-profile"
                              [pplPopover]="profile"
                              [pplPopoverExactCalculations]="true"></ppl-page-header-button>
    </div>
  </ng-template>

  <ng-container *ngIf="!menuVisible">
    <ng-container *ngIf="data$ | async; let data">
      <ng-container *ngIf="data.state === AsyncState.FETCHED">
        <!-- Tracking -->
        <ppl-tracking-read *ngIf="data.value.visits"
                           [recipients]="data.value.recipients"
                           [visits]="data.value.visits"></ppl-tracking-read>

        <ppl-tracking-compose *ngIf="itemMode === OutlookItemMode.Compose"></ppl-tracking-compose>

        <!-- Templates -->
        <div *ngIf="itemMode === OutlookItemMode.Compose"
             class="templates-link"
             (click)="onTemplatesClick()">
          <ppl-icon class="templates-link-icon"
                    svgIcon="email-templates-gray"
                    width="16px"
                    height="16px"></ppl-icon>

          <ng-container i18n="@@Email_templates">Email templates</ng-container>

          <ppl-icon class="templates-link-chevron"
                    svgIcon="arrow-gray"
                    width="16px"
                    height="16px"></ppl-icon>
        </div>

        <!-- Warnings -->
        <ppl-toast *ngIf="hasAttachmentsWithoutId"
                   text="Some email attachments are not yet available for saving"
                   i18n="@@Some_email_attachments_are_not_yet_available_for_saving"></ppl-toast>

        <!-- From -->
        <ppl-dashboard-group *ngIf="itemMode === OutlookItemMode.Read"
                             icon="dashboard-from-gray-icon"
                             [name]="senderGroupName">
          <ppl-dashboard-sender [item]="data.value.sender"></ppl-dashboard-sender>
        </ppl-dashboard-group>

        <!-- To -->
        <ppl-dashboard-group icon="dashboard-to-gray-icon"
                             [name]="recipientsGroupName">
          <ppl-dashboard-recipients [items]="data.value.recipients"></ppl-dashboard-recipients>

          <ppl-dashboard-add-card *ngIf="itemMode === OutlookItemMode.Compose"></ppl-dashboard-add-card>
        </ppl-dashboard-group>
      </ng-container>

      <ppl-page-loading *ngIf="data.state === AsyncState.FETCHING"></ppl-page-loading>

      <ppl-page-error *ngIf="data.state === AsyncState.ERROR"
                      text="Unable to load overview"
                      i18n-text="@@Unable_to_load_overview"></ppl-page-error>
    </ng-container>
  </ng-container>

  <ppl-dashboard-menu *ngIf="menuVisible"></ppl-dashboard-menu>
</ppl-page>

<ng-template #profile>
  <ppl-user-profile [space]="space"
                    [version]="version"
                    [userProfile]="userProfile"></ppl-user-profile>
</ng-template>