import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { AccountEntity } from '@ppl/graphql-space-api';
import { LocationService } from '../../../../../services/location.service';
import type { DashboardAccountItem } from '../../../domain/dashboard';

@Component({
  selector: 'ppl-dashboard-recipients-account',
  templateUrl: './dashboard-recipients-account.component.html',
  styleUrls: ['./dashboard-recipients-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardRecipientsAccountComponent {

  @Input() items: DashboardAccountItem[];
  @Input() largeCard: boolean;

  constructor(
    private locationService: LocationService
  ) {}

  onAccountClick(entity: AccountEntity) {
    this.locationService.openAccount(entity.id);
  }

}
