<ppl-icon *ngIf="!kind"
          svgIcon="notification-info-message-black"
          width="12px"
          height="12px"></ppl-icon>

<ppl-icon *ngIf="kind === ToastKind.Success"
          svgIcon="notification-success-message"
          width="12px"
          height="12px"></ppl-icon>

<ppl-icon *ngIf="kind === ToastKind.Error"
          svgIcon="notification-error-message"
          width="12px"
          height="12px"></ppl-icon>

<ng-container *ngIf="text">
  {{text}}
</ng-container>

<ng-container *ngIf="content">
  <ng-container *ngComponentOutlet="content.component; injector: contentDataInjector"></ng-container>
</ng-container>