import { Injectable } from '@angular/core';
import { ActionsUtilsService } from '@ppl/core';
import { FirebaseEventsEnum, FirebaseService } from '@ppl/firebase';
import type { Query, UpdateContactInput } from '@ppl/graphql-space-api';
import { SpaceService } from '@ppl/space';
import { map, tap } from 'rxjs/operators';
import { CacheService } from '../../../services/cache.service';
import type { ContactData } from '../domain/contact';
import { gqlUpdateContactMutation } from './contact-form.graphql';
import { gqlFetchContactData } from './contact.graphql';

@Injectable()
export class ContactService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private cacheService: CacheService,
    private firebaseService: FirebaseService,
    private spaceService: SpaceService
  ) {}

  fetchData(id: string) {
    return this.spaceService.gqlClient.query<Query>({
      query: gqlFetchContactData,
      fetchPolicy: 'no-cache',
      variables: {
        entityId: id
      }
    }).pipe(map(({ data }) => {
      const entityData = data.screens.extractViews.contact;

      if (!entityData) {
        return null;
      }

      const result: ContactData = {
        entity: entityData.entity,
        relatedAccounts: entityData.entity.accountRelations.edges.map(edge => edge.node),
        relatedActivities: [
          ...entityData.plannedActivities.edges.map(edge => edge.node.appointment || edge.node.task).filter(entity => !!entity),
          ...entityData.unplannedTasks.edges.map(edge => edge.node.task).filter(entity => !!entity)
        ],
        relatedLeads: entityData.leads.edges.map(edge => edge.node.lead).filter(entity => !!entity),
        relatedOpportunities: entityData.opportunities.edges.map(edge => edge.node.oppty).filter(entity => !!entity)
      };

      return result;
    }));
  }

  updateField(id: string, value: Omit<UpdateContactInput, 'id'>) {
    const input: UpdateContactInput = {
      id,
      ...value
    };

    return this.actionsUtilsService.doAction(this.spaceService.gqlClient.mutate({
      mutation: gqlUpdateContactMutation,
      variables: {
        input
      }
    }).pipe(
      tap(() => {
        this.cacheService.reset();

        this.firebaseService.logEvent(FirebaseEventsEnum.Update, {
          entity: 'contact'
        });
      })
    ));
  }

}
