import { Injectable } from '@angular/core';
import { ActionsUtilsService } from '@ppl/core';
import { ContactFieldsEnum } from '@ppl/domain';
import { FirebaseEventsEnum, FirebaseService } from '@ppl/firebase';
import type { ContactEntity} from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { getSpaceContactTypes } from '@ppl/space';
import type { PplSelectOption } from '@ppl/ui/select';
import { NameParser, sortByKey } from '@ppl/utils';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { RecentRecordsService } from '../../../services/recent-records.service';
import { CoreStore } from '../../../store/core.store';
import { DashboardItemKind } from '../../dashboard/domain/dashboard';
import { getDashboardData } from '../../dashboard/store/dashboard.selectors';
import { BodyParserService } from '../../shared/body-parser/services/body-parser.service';
import { EmailService } from '../../shared/email/services/email.service';
import { EntityFormApiService } from '../../shared/entity-form-api/services/entity-form-api.service';
import type { EntityFormControl, EntityFormEntityControl } from '../../shared/entity-form/domain/controls';
import { EntityFormControlKind } from '../../shared/entity-form/domain/controls';
import type { EntityFormRequiredOneOfValidator} from '../../shared/entity-form/domain/validators';
import { EntityFormValidatorKind } from '../../shared/entity-form/domain/validators';
import { SuccessToastComponent } from '../../shared/entity/components/success-toast/success-toast.component';
import type { SuccessToastData} from '../../shared/entity/domain/success-toast';
import { SuccessToastOperation } from '../../shared/entity/domain/success-toast';
import { EntityService } from '../../shared/entity/services/entity.service';
import { OutlookService } from '../../shared/outlook/services/outlook.service';
import { ToastService } from '../../shared/toast/services/toast.service';
import type { ContactFormData, ContactFormDataOutlookItem, ContactFormValue } from '../domain/contact-form';
import { gqlCreateContactMutation, gqlFetchContactFormData, gqlUpdateContactMutation } from './contact-form.graphql';

@Injectable()
export class ContactFormService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private bodyParserService: BodyParserService,
    private emailService: EmailService,
    private entityService: EntityService,
    private entityFormApiService: EntityFormApiService,
    private firebaseService: FirebaseService,
    private i18nService: I18nService,
    private outlookService: OutlookService,
    private recentRecordsService: RecentRecordsService,
    private store: CoreStore,
    private toastService: ToastService
  ) {}

  fetchData(options: FetchDataOptions) {
    return combineLatest([
      this.entityService.fetchEntity<ContactEntity>({
        entityType: EntityNameEnum.Contact,
        id: options.id,
        query: gqlFetchContactFormData
      }),
      this.entityService.fetchLeadName(options.relatedLeadId),
      this.entityService.fetchOpportunityName(options.relatedOpportunityId),
      this.entityFormApiService.fetchContext(EntityNameEnum.Contact, {
        entities: [EntityNameEnum.ContactType, EntityNameEnum.SalesUnit]
      }),
      this.fetchOutlookItem(),
    ]).pipe(
      map(([ entity, relatedLead, relatedOpportunity, context, outlookItem ]) => {
        if (options.id && !entity) {
          return null;
        }

        const result: ContactFormData = {
          entity,
          context,
          outlookItem,
          relatedAccountId: options.relatedAccountId,
          relatedEmail: options.relatedEmail,
          relatedLead,
          relatedOpportunity
        };

        return result;
      })
    );
  }

  getControls(data: ContactFormData, formId: string) {
    const contactTypes = this.getContactTypes();

    const firstOrLastNameValidator: EntityFormRequiredOneOfValidator = {
      controlIds: [ContactFieldsEnum.FirstNameField, ContactFieldsEnum.LastNameField],
      error: { firstOrLastNameRequired: true },
      kind: EntityFormValidatorKind.RequiredOneOf
    };

    const createForm = !data.entity;
    const multipleTypes = contactTypes.length > 1;
    const outlookParsedBody = data.outlookItem.parsedBody;
    const outlookHasAttachments = this.outlookService.hasAttachments();

    const controls: EntityFormControl[] = [
      multipleTypes && {
        id: 'contactTypeId',
        label: this.i18nService.translate('Contact_type'),
        options: contactTypes.map<PplSelectOption>(contactType => ({
          deleted: contactType.isDeleted || contactType.isReadonly,
          label: contactType.name,
          value: contactType.id
        })).sort(sortByKey('label')),
        kind: EntityFormControlKind.Dropdown,
        validators: [{
          kind: EntityFormValidatorKind.Required
        }]
      },
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: ContactFieldsEnum.TitleField,
        forceOnForm: true,
        formId
      }),
      {
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId: ContactFieldsEnum.FirstNameField,
          forceOnForm: true,
          formId
        }),
        validators: [firstOrLastNameValidator]
      },
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: ContactFieldsEnum.MiddleNameField,
        forceOnForm: true,
        formId
      }),
      {
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId: ContactFieldsEnum.LastNameField,
          forceOnForm: true,
          formId
        }),
        validators: [firstOrLastNameValidator]
      },
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: ContactFieldsEnum.UnitIdField,
        formId
      }),
      {
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId: ContactFieldsEnum.OwnerIdField,
          forceOnForm: true,
          formId
        }) as EntityFormEntityControl,
        disabled: true
      },
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: ContactFieldsEnum.Email1Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: ContactFieldsEnum.Email2Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: ContactFieldsEnum.Email3Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: ContactFieldsEnum.Email4Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: ContactFieldsEnum.Email5Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: ContactFieldsEnum.Phone1Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: ContactFieldsEnum.Phone2Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: ContactFieldsEnum.Phone3Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: ContactFieldsEnum.Phone4Field,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: ContactFieldsEnum.Phone5Field,
        formId
      }),
      createForm && outlookParsedBody.facebookUrl && {
        id: 'facebookUrl',
        label: this.i18nService.translate('Facebook_profile'),
        kind: EntityFormControlKind.Input
      },
      createForm && outlookParsedBody.linkedInUrl && {
        id: 'linkedInUrl',
        label: this.i18nService.translate('LinkedIn_profile'),
        kind: EntityFormControlKind.Input
      },
      createForm && outlookParsedBody.twitterUrl && {
        id: 'twitterUrl',
        label: this.i18nService.translate('Twitter_profile'),
        kind: EntityFormControlKind.Input
      },
      {
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId: ContactFieldsEnum.RelatedAccountField,
          forceOnForm: !!data.relatedAccountId,
          formId
        }) as EntityFormEntityControl,
        nullDisabled: !!data.entity || !!data.relatedAccountId
      },
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: ContactFieldsEnum.CommentsField,
        formId
      }),
      createForm && outlookHasAttachments && {
        id: 'saveAttachments',
        label: this.i18nService.translate('Save_email_attachments_to_new_contact'),
        inlineLabel: true,
        kind: EntityFormControlKind.Checkbox
      },
      ...this.entityFormApiService.getCustomFieldIds({
        context: data.context,
        formId
      }).map(fieldId => ({
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId,
          formId
        }),
        data: {
          customField: true
        }
      }))
    ];

    return controls.filter(control => control);
  }

  getValue(data: ContactFormData, formId: string): ContactFormValue {
    const value = this.entityFormApiService.getValue({
      context: data.context,
      entity: data.entity,
      fieldIds: [
        { fieldId: ContactFieldsEnum.TitleField, forceOnForm: true },
        { fieldId: ContactFieldsEnum.FirstNameField, forceOnForm: true },
        { fieldId: ContactFieldsEnum.MiddleNameField, forceOnForm: true },
        { fieldId: ContactFieldsEnum.LastNameField, forceOnForm: true },
        ContactFieldsEnum.UnitIdField,
        { fieldId: ContactFieldsEnum.OwnerIdField, forceOnForm: true },
        ContactFieldsEnum.Email1Field,
        ContactFieldsEnum.Email2Field,
        ContactFieldsEnum.Email3Field,
        ContactFieldsEnum.Email4Field,
        ContactFieldsEnum.Email5Field,
        ContactFieldsEnum.Phone1Field,
        ContactFieldsEnum.Phone2Field,
        ContactFieldsEnum.Phone3Field,
        ContactFieldsEnum.Phone4Field,
        ContactFieldsEnum.Phone5Field,
        ContactFieldsEnum.RelatedAccountField,
        ContactFieldsEnum.CommentsField,
        ...this.entityFormApiService.getCustomFieldIds({
          context: data.context,
          formId
        })
      ],
      formId
    });

    if (data.entity) {
      return {
        ...value,
        contactTypeId: formId
      };
    } else {
      return {
        ...value,
        ...this.getPersonValue(data),
        [ContactFieldsEnum.Phone1Field]: data.outlookItem.parsedBody.phone,
        [ContactFieldsEnum.RelatedAccountField]: data.relatedAccountId,
        contactTypeId: formId,
        facebookUrl: data.outlookItem.parsedBody.facebookUrl,
        linkedInUrl: data.outlookItem.parsedBody.linkedInUrl,
        twitterUrl: data.outlookItem.parsedBody.twitterUrl,
        saveAttachments: false
      };
    }
  }

  createEntity(value: ContactFormValue, data: ContactFormData) {
    return this.actionsUtilsService.doAction(this.entityFormApiService.saveEntity({
      input: {
        ...this.entityFormApiService.getInput({
          context: data.context,
          value
        }),
        contactTypeId: value.contactTypeId,
        socialMedia: (value.facebookUrl || value.linkedInUrl || value.twitterUrl) ? {
          facebookUrl: value.facebookUrl,
          linkedinUrl: value.linkedInUrl,
          twitterUrl: value.twitterUrl
        } : null
      },
      mutation: gqlCreateContactMutation
    }).pipe(
      switchMap(result => {
        const contactId = result.createContact.contact.id;

        return of(null).pipe(
          switchMap(() => {
            if (data.relatedLead || data.relatedOpportunity) {
              return this.entityService.createLeadOpptyContactRelation({
                contactId,
                leadOpptyId: data.relatedLead?.id || data.relatedOpportunity?.id
              });
            }

            return of(null);
          }),
          switchMap(() => {
            if (value.saveAttachments) {
              return this.emailService.saveAttachments({
                relations: [{
                  entityId: contactId,
                  entityType: EntityNameEnum.Contact
                }],
                silent: true
              });
            }

            return of(null);
          }),
          tap(() => {
            this.toastService.open<SuccessToastData>({
              content: {
                component: SuccessToastComponent,
                data: {
                  entityType: EntityNameEnum.Contact,
                  id: contactId,
                  operation: SuccessToastOperation.Create
                }
              }
            });

            this.recentRecordsService.add(contactId);

            const hasRelation = data.relatedAccountId || data.relatedLead || data.relatedOpportunity;

            this.firebaseService.logEvent(hasRelation ? FirebaseEventsEnum.Create : FirebaseEventsEnum.CreateFromEmail, {
              entity: 'contact'
            });
          })
        );
      })
    ));
  }

  updateEntity(value: ContactFormValue, data: ContactFormData) {
    return this.actionsUtilsService.doAction(this.entityFormApiService.saveEntity({
      input: {
        ...this.entityFormApiService.getInput({
          entity: data.entity,
          context: data.context,
          value
        }),
        contactTypeId: value.contactTypeId,
        id: data.entity.id
      },
      mutation: gqlUpdateContactMutation
    }).pipe(
      tap(() => {
        this.toastService.open<SuccessToastData>({
          content: {
            component: SuccessToastComponent,
            data: {
              entityType: EntityNameEnum.Contact,
              id: data.entity.id,
              operation: SuccessToastOperation.Update
            }
          }
        });

        this.firebaseService.logEvent(FirebaseEventsEnum.Update, {
          entity: 'contact'
        });
      })
    ));
  }

  getDefaultFormId() {
    return this.getContactTypes().filter(contactType => !contactType.isDeleted && !contactType.isReadonly)[0]?.id || null;
  }

  private fetchOutlookItem() {
    return combineLatest([
      this.outlookService.getHtmlBody().pipe(
        map(htmlBody => this.bodyParserService.parse(htmlBody))
      )
    ]).pipe(
      map(([ parsedBody ]) => {
        const result: ContactFormDataOutlookItem = {
          parsedBody
        };

        return result;
      })
    );
  }

  private getContactTypes() {
    return this.store.get(getSpaceContactTypes).filter(contactType => contactType.isPublished).sort(sortByKey('name'));
  }

  private getPersonValue(data: ContactFormData) {
    const dashboardData = this.store.get(getDashboardData).value;

    let personName: null | string = null;
    let personEmail: null | string = null;

    if (dashboardData) {
      if (dashboardData.sender.kind === DashboardItemKind.Other && !data.relatedEmail) {
        personName = dashboardData.sender.person.name;
        personEmail = dashboardData.sender.person.email;
      } else {
        for (let i = 0; i < dashboardData.recipients.length; i++) {
          if (dashboardData.recipients[i].kind === DashboardItemKind.Other) {
            if (!data.relatedEmail || dashboardData.recipients[i].person.email === data.relatedEmail) {
              personName = dashboardData.recipients[i].person.name;
              personEmail = dashboardData.recipients[i].person.email;
              break;
            }
          }
        }
      }
    }

    if (personName) {
      const parsedPersonName = NameParser.parse(personName);

      return {
        [ContactFieldsEnum.TitleField]: parsedPersonName.leadingInit || null,
        [ContactFieldsEnum.FirstNameField]: parsedPersonName.first || null,
        [ContactFieldsEnum.MiddleNameField]: parsedPersonName.middle || null,
        [ContactFieldsEnum.LastNameField]: parsedPersonName.last || null,
        [ContactFieldsEnum.Email1Field]: personEmail || null
      };
    } else if (personEmail) {
      return {
        [ContactFieldsEnum.Email1Field]: personEmail
      };
    }

    return null;
  }

}

export interface FetchDataOptions {
  id: string | null;
  relatedAccountId: string | null;
  relatedEmail: string | null;
  relatedLeadId: string | null;
  relatedOpportunityId: string | null;
}
