<ppl-card-group label="Upcoming Activities"
                i18n-label="@@Upcoming_Activities"
                [count]="entities.length"
                [displayCreate]="displayCreate"
                [menuItems]="createMenuItems"
                (menuItemClick)="onCreateMenuItemClick($event)">
  <ppl-card-list *ngIf="entities.length !== 0"
                 [entities]="entities">
    <ng-template #cardTemplate
                 let-entity>
      <ppl-appointment-card-medium *ngIf="entity.__typename === 'AppointmentEntity'"
                                   [entity]="entity"
                                   (click)="onAppointmentCardClick(entity)"></ppl-appointment-card-medium>

      <ppl-task-card-medium *ngIf="entity.__typename === 'TaskEntity'"
                            [entity]="entity"
                            (click)="onTaskCardClick(entity)"></ppl-task-card-medium>
    </ng-template>
  </ppl-card-list>

  <ng-container *ngIf="entities.length === 0">
    <ppl-card-message *ngIf="parentEntity.__typename === 'AccountEntity'"
                      i18n="@@Account_has_no_related_upcoming_activities">{{ 'Account' | t }} has no related upcoming activities</ppl-card-message>

    <ppl-card-message *ngIf="parentEntity.__typename === 'ContactEntity'"
                      i18n="@@Contact_has_no_related_upcoming_activities">{{ 'Contact' | t }} has no related upcoming activities</ppl-card-message>

    <ppl-card-message *ngIf="parentEntity.__typename === 'LeadEntity'"
                      i18n="@@Lead_has_no_related_upcoming_activities">{{ 'Lead' | t }} has no related upcoming activities</ppl-card-message>

    <ppl-card-message *ngIf="parentEntity.__typename === 'OpportunityEntity'"
                      i18n="@@Opportunity_has_no_related_upcoming_activities">{{ 'Opportunity' | t }} has no related upcoming activities</ppl-card-message>
  </ng-container>
</ppl-card-group>
