import { gql } from '@ppl/utils';

export const gqlCreateTaskMutation = gql`
  mutation createTask($input: CreateTaskInput!, $validationLevel: [ValidationLevel!]) {
    createTask(input: $input, validationLevel: $validationLevel) {
      clientMutationId
    }
  }
`;

export const gqlCreateTaskReminderMutation = gql`
  mutation createTaskReminder($input: CreateTaskReminderInput!, $validationLevel: [ValidationLevel!]) {
    createTaskReminder(input: $input, validationLevel: $validationLevel) {
      clientMutationId
    }
  }
`;

export const gqlUpdateTaskMutation = gql`
  mutation updateTask($input: UpdateTaskInput!, $validationLevel: [ValidationLevel!]) {
    updateTask(input: $input, validationLevel: $validationLevel) {
      clientMutationId
    }
  }
`;
