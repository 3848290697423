import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { PplMenuItem } from '../menu-items';
import { PplPopoverControl } from '../popover';

@Component({
  selector: 'ppl-popover-menu',
  templateUrl: './popover-menu.component.html',
  styleUrls: ['./popover-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplPopoverMenuComponent {

  @Input() items: PplMenuItem[];
  @Input() parentPopover: PplPopoverControl;
  @Input() onItemClick: (id: string) => void;
  @Input() value?: string;

}
