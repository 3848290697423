import { Injectable } from '@angular/core';
import type { Observable} from 'rxjs';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OutlookUtilsService } from './outlook-utils.service';

@Injectable()
export class OutlookNotificationsService {

  constructor(
    private outlookUtilsService: OutlookUtilsService
  ) {}

  addNotification(options: AddReplaceNotificationOptions): Observable<void> {
    return this.outlookUtilsService.wrapAsync<Office.NotificationMessageDetails[]>(callback => {
      Office.context.mailbox.item.notificationMessages.getAllAsync(callback);
    }).pipe(
      switchMap(notifications => {
        const existingNotification = notifications.find(notification => notification.key === options.id);

        if (existingNotification) {
          return this.removeNotificationNoCheck(options.id);
        }

        return of(null);
      }),
      switchMap(() => {
        return this.outlookUtilsService.wrapAsync<void>(callback => {
          const messageOptions: Office.NotificationMessageDetails = {
            message: options.message,
            type: options.type
          };

          Office.context.mailbox.item.notificationMessages.addAsync(options.id, messageOptions, callback);
        });
      })
    );
  }

  replaceNotification(options: AddReplaceNotificationOptions): Observable<void> {
    return this.outlookUtilsService.wrapAsync<void>(callback => {
      let messageOptions: Office.NotificationMessageDetails = {
        message: options.message,
        type: options.type
      };

      if (options.type === Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage) {
        messageOptions = {
          ...messageOptions,
          icon: 'track-32',
          persistent: true
        };
      }

      Office.context.mailbox.item.notificationMessages.replaceAsync(options.id, messageOptions, callback);
    });
  }

  removeNotification(id: string): Observable<void> {
    return this.outlookUtilsService.wrapAsync<Office.NotificationMessageDetails[]>(callback => {
      Office.context.mailbox.item.notificationMessages.getAllAsync(callback);
    }).pipe(
      switchMap(notifications => {
        const existingNotification = notifications.find(notification => notification.key === id);

        if (existingNotification) {
          return this.removeNotificationNoCheck(id);
        }

        return of(null);
      })
    );
  }

  private removeNotificationNoCheck(id: string): Observable<void> {
    return this.outlookUtilsService.wrapAsync<void>(callback => {
      Office.context.mailbox.item.notificationMessages.removeAsync(id, callback);
    });
  }

}

export interface AddReplaceNotificationOptions {
  id: string;
  message: string;
  type: Office.MailboxEnums.ItemNotificationMessageType;
}
