import { Injectable } from '@angular/core';
import { getUserSettings } from '@ppl/auth';
import { ActionsUtilsService } from '@ppl/core';
import { AppointmentFieldsEnum, REMINDER_OFFSETS } from '@ppl/domain';
import { FirebaseEventsEnum, FirebaseService } from '@ppl/firebase';
import type { AppointmentEntity, CreateAppointmentClientInviteesRelationNoAppointmentBackrefInput} from '@ppl/graphql-space-api';
import { EntityNameEnum, ReminderStatusEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { getSpaceAppointmentTypes } from '@ppl/space';
import type { PplSelectOption } from '@ppl/ui/select';
import { sortByKey } from '@ppl/utils';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { RecentRecordsService } from '../../../services/recent-records.service';
import { CoreStore } from '../../../store/core.store';
import { EmailService } from '../../shared/email/services/email.service';
import type { EntityMultipleSelectItem } from '../../shared/entity-control/components/entity-multiple-select/entity-multiple-select.component';
import { EntityFormApiService } from '../../shared/entity-form-api/services/entity-form-api.service';
import type { EntityFormControl} from '../../shared/entity-form/domain/controls';
import { EntityFormControlKind } from '../../shared/entity-form/domain/controls';
import { EntityFormValidatorKind } from '../../shared/entity-form/domain/validators';
import { SuccessToastComponent } from '../../shared/entity/components/success-toast/success-toast.component';
import { EntityListAdapters } from '../../shared/entity/domain/entity-list';
import type { SuccessToastData} from '../../shared/entity/domain/success-toast';
import { SuccessToastOperation } from '../../shared/entity/domain/success-toast';
import { EntityService } from '../../shared/entity/services/entity.service';
import { OutlookService } from '../../shared/outlook/services/outlook.service';
import { ToastService } from '../../shared/toast/services/toast.service';
import type { AppointmentFormData, AppointmentFormDataOutlookItem, AppointmentFormValue } from '../domain/appointment-form';
import { gqlCreateAppointmentMutation, gqlFetchAppointmentFormData, gqlUpdateAppointmentMutation } from './appointment-form.graphql';

@Injectable()
export class AppointmentFormService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private emailService: EmailService,
    private entityService: EntityService,
    private entityFormApiService: EntityFormApiService,
    private firebaseService: FirebaseService,
    private i18nService: I18nService,
    private outlookService: OutlookService,
    private recentRecordsService: RecentRecordsService,
    private store: CoreStore,
    private toastService: ToastService
  ) {}

  fetchData(options: FetchDataOptions) {
    return combineLatest([
      this.entityService.fetchEntity<AppointmentEntity>({
        entityType: EntityNameEnum.Appointment,
        id: options.id,
        query: gqlFetchAppointmentFormData
      }),
      this.entityFormApiService.fetchContext(EntityNameEnum.Appointment, {
        entities: [EntityNameEnum.AppointmentType, EntityNameEnum.SalesUnit]
      }),
      this.fetchOutlookItem()
    ]).pipe(
      map(([ entity, context, outlookItem ]) => {
        if (options.id && !entity) {
          return null;
        }

        const result: AppointmentFormData = {
          entity,
          context,
          outlookItem,
          relatedAccountId: options.relatedAccountId,
          relatedContactId: options.relatedContactId,
          relatedLeadId: options.relatedLeadId,
          relatedOpportunityId: options.relatedOpportunityId
        };

        return result;
      })
    );
  }

  getControls(data: AppointmentFormData, formId: string) {
    const appointmentTypes = this.getAppointmentTypes();

    const createForm = !data.entity;
    const outlookHasAttachments = this.outlookService.hasAttachments();

    const controls: EntityFormControl[] = [
      {
        id: 'activityTypeId',
        label: this.i18nService.translate('Activity_type'),
        options: appointmentTypes.map<PplSelectOption>(taskType => ({
          deleted: taskType.isDeleted || taskType.isReadonly,
          label: taskType.name,
          value: taskType.id
        })).sort(sortByKey('label')),
        kind: EntityFormControlKind.Dropdown,
        validators: [{
          kind: EntityFormValidatorKind.Required
        }]
      },
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AppointmentFieldsEnum.SubjectField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AppointmentFieldsEnum.DescriptionField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AppointmentFieldsEnum.StartDateField,
        forceOnForm: true,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AppointmentFieldsEnum.EndDateField,
        forceOnForm: true,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AppointmentFieldsEnum.LocationField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AppointmentFieldsEnum.UnitIdField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AppointmentFieldsEnum.OwnerIdField,
        forceOnForm: true,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: AppointmentFieldsEnum.LinkedItemsField,
        formId
      }),
      createForm && {
        id: 'attendees',
        label: this.i18nService.translate('Attendees'),
        entityAdapters: EntityListAdapters,
        entityTypes: [
          EntityNameEnum.Client
        ],
        kind: EntityFormControlKind.MultipleEntity
      },
      createForm && {
        id: 'reminderOffset',
        label: this.i18nService.translate('Reminder'),
        options: REMINDER_OFFSETS.map<PplSelectOption>(record => ({
          label: this.i18nService.translate(record.label),
          value: record.value.toString()
        })),
        kind: EntityFormControlKind.Dropdown
      },
      createForm && outlookHasAttachments && {
        id: 'saveAttachments',
        label: this.i18nService.translate('Save_email_attachments_to_new_appointment'),
        inlineLabel: true,
        kind: EntityFormControlKind.Checkbox
      },
      ...this.entityFormApiService.getCustomFieldIds({
        context: data.context,
        formId
      }).map(fieldId => ({
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId,
          formId
        }),
        data: {
          customField: true
        }
      }))
    ];

    return controls.filter(control => control);
  }

  getValue(data: AppointmentFormData, formId: string): AppointmentFormValue {
    const value = this.entityFormApiService.getValue({
      context: data.context,
      entity: data.entity,
      fieldIds: [
        AppointmentFieldsEnum.SubjectField,
        AppointmentFieldsEnum.DescriptionField,
        { fieldId: AppointmentFieldsEnum.StartDateField, forceOnForm: true },
        { fieldId: AppointmentFieldsEnum.EndDateField, forceOnForm: true },
        AppointmentFieldsEnum.LocationField,
        AppointmentFieldsEnum.UnitIdField,
        { fieldId: AppointmentFieldsEnum.OwnerIdField, forceOnForm: true },
        AppointmentFieldsEnum.LinkedItemsField,
        ...this.entityFormApiService.getCustomFieldIds({
          context: data.context,
          formId
        })
      ],
      formId
    });

    if (data.entity) {
      return {
        ...value,
        activityTypeId: formId
      };
    } else {
      const defaultReminder = this.store.get(getUserSettings).defaultAppointmentReminder;

      return {
        ...value,
        [AppointmentFieldsEnum.SubjectField]: data.outlookItem.subject,
        [AppointmentFieldsEnum.DescriptionField]: this.getDescriptionFromOutlookItem(data),
        [AppointmentFieldsEnum.LinkedItemsField]: this.getDefaultLinkedItems(data),
        activityTypeId: formId,
        attendees: [],
        reminderOffset: (defaultReminder !== null) ? defaultReminder.toString() : '',
        saveAttachments: false
      };
    }
  }

  createEntity(value: AppointmentFormValue, data: AppointmentFormData) {
    return this.actionsUtilsService.doAction(this.entityFormApiService.saveEntity({
      input: {
        ...this.entityFormApiService.getInput({
          context: data.context,
          value
        }),
        activityTypeId: value.activityTypeId,
        inviteesClients: value.attendees.map<CreateAppointmentClientInviteesRelationNoAppointmentBackrefInput>(item => ({
          clientId: item.entityId
        })),
        reminder: value.reminderOffset ? {
          ownerId: data.context.userClient.id,
          endDateOffset: parseInt(value.reminderOffset, 10),
          status: ReminderStatusEnum.Scheduled
        } : {
          ownerId: data.context.userClient.id,
          endDateOffset: 0,
          status: ReminderStatusEnum.Dismissed
        }
      },
      mutation: gqlCreateAppointmentMutation
    }).pipe(
      switchMap(result => {
        const appointmentId = result.createAppointment.appointment.id;

        return of(null).pipe(
          switchMap(() => {
            if (value.saveAttachments) {
              return this.emailService.saveAttachments({
                relations: [{
                  entityId: appointmentId,
                  entityType: EntityNameEnum.Appointment
                }],
                silent: true
              });
            }

            return of(null);
          }),
          tap(() => {
            this.toastService.open<SuccessToastData>({
              content: {
                component: SuccessToastComponent,
                data: {
                  entityType: EntityNameEnum.Appointment,
                  id: appointmentId,
                  operation: SuccessToastOperation.Create
                }
              }
            });

            this.recentRecordsService.add(appointmentId);

            const hasRelation = data.relatedAccountId || data.relatedContactId || data.relatedLeadId || data.relatedOpportunityId;

            this.firebaseService.logEvent(hasRelation ? FirebaseEventsEnum.Create : FirebaseEventsEnum.CreateFromEmail, {
              entity: 'appointment'
            });
          })
        );
      })
    ));
  }

  updateEntity(value: AppointmentFormValue, data: AppointmentFormData) {
    return this.actionsUtilsService.doAction(this.entityFormApiService.saveEntity({
      input: {
        ...this.entityFormApiService.getInput({
          entity: data.entity,
          context: data.context,
          value
        }),
        activityTypeId: value.activityTypeId,
        id: data.entity.id
      },
      mutation: gqlUpdateAppointmentMutation
    }).pipe(
      tap(() => {
        this.toastService.open<SuccessToastData>({
          content: {
            component: SuccessToastComponent,
            data: {
              entityType: EntityNameEnum.Appointment,
              id: data.entity.id,
              operation: SuccessToastOperation.Update
            }
          }
        });

        this.firebaseService.logEvent(FirebaseEventsEnum.Update, {
          entity: 'appointment'
        });
      })
    ));
  }

  private fetchOutlookItem() {
    return combineLatest([
      this.outlookService.getSender(),
      this.outlookService.getToRecipients(),
      this.outlookService.getSubject(),
      this.outlookService.getTextBody()
    ]).pipe(
      map(([ sender, toRecipients, subject, body ]) => {
        const result: AppointmentFormDataOutlookItem = {
          sender,
          toRecipients,
          subject,
          body
        };

        return result;
      })
    );
  }

  getDefaultFormId() {
    return this.getAppointmentTypes().filter(appointmentType => !appointmentType.isDeleted && !appointmentType.isReadonly)[0]?.id || null;
  }

  private getAppointmentTypes() {
    return this.store.get(getSpaceAppointmentTypes).filter(appointmentType => appointmentType.isPublished).sort(sortByKey('name'));
  }

  private getDescriptionFromOutlookItem(data: AppointmentFormData) {
    return `From: ${data.outlookItem.sender.email}\nTo: ${data.outlookItem.toRecipients.map(recipient => recipient.email).join(', ')}\n\n${data.outlookItem.body}`;
  }

  private getDefaultLinkedItems(data: AppointmentFormData): EntityMultipleSelectItem[] {
    if (data.relatedAccountId) {
      return [{
        entityId: data.relatedAccountId,
        entityType: EntityNameEnum.Account
      }];
    } else if (data.relatedContactId) {
      return [{
        entityId: data.relatedContactId,
        entityType: EntityNameEnum.Contact
      }];
    } else if (data.relatedLeadId) {
      return [{
        entityId: data.relatedLeadId,
        entityType: EntityNameEnum.Lead
      }];
    } else if (data.relatedOpportunityId) {
      return [{
        entityId: data.relatedOpportunityId,
        entityType: EntityNameEnum.Opportunity
      }];
    }

    return [];
  }

}

export interface FetchDataOptions {
  id: string | null;
  relatedAccountId: string | null;
  relatedContactId: string | null;
  relatedLeadId: string | null;
  relatedOpportunityId: string | null;
}
