import { TaskStatusEnum } from '@ppl/graphql-space-api';
import { EntityFormControlKind } from '../../../entity-form/domain/controls';
import type { EntityFormApiFieldTypeAdapter } from '../entity-form-api';

export const TaskStatusFieldTypeAdapter: EntityFormApiFieldTypeAdapter = {
  id: 'task_status',
  getControl({ fieldDescriptor, formField, i18nService }) {
    return {
      id: fieldDescriptor.id,
      label: formField?.label ?? fieldDescriptor.name,
      options: [{
        label: i18nService.translate('EnumTaskStatusEnum_NotStarted'),
        value: TaskStatusEnum.NotStarted
      }, {
        label: i18nService.translate('EnumTaskStatusEnum_InProgress'),
        value: TaskStatusEnum.InProgress
      }, {
        label: i18nService.translate('EnumTaskStatusEnum_Waiting'),
        value: TaskStatusEnum.Waiting
      }, {
        label: i18nService.translate('EnumTaskStatusEnum_Completed'),
        value: TaskStatusEnum.Completed
      }, {
        label: i18nService.translate('EnumTaskStatusEnum_Deferred'),
        value: TaskStatusEnum.Deferred
      }],
      kind: EntityFormControlKind.Dropdown
    };
  }
};
