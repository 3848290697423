import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LeadEntity } from '@ppl/graphql-space-api';

@Component({
  selector: 'ppl-lead-fields-card',
  templateUrl: './lead-fields-card.component.html',
  styleUrls: ['./lead-fields-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeadFieldsCardComponent {

  @Input() entity: LeadEntity;

}
