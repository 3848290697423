import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { AccountEntity, ContactAccountRelationEntity, ContactEntity, LeadEntity, LeadOpptyAccountRelationEntity, OpportunityEntity } from '@ppl/graphql-space-api';
import { EntityNameEnum, EntityPermsEnum } from '@ppl/graphql-space-api';
import { hasSpaceEntityPermission } from '@ppl/space';
import { MemoizeLast } from '@ppl/utils';
import { LocationService } from '../../../../../services/location.service';
import { CoreStore } from '../../../../../store/core.store';
import { TypeToRelationOption } from '../../domain/entity-card';

@Component({
  selector: 'ppl-accounts-cards',
  templateUrl: './accounts-cards.component.html',
  styleUrls: ['./accounts-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountsCardsComponent {

  @Input() entities: (ContactAccountRelationEntity | LeadOpptyAccountRelationEntity)[];
  @Input() parentEntity: ContactEntity | LeadEntity | OpportunityEntity;

  EntityNameEnum = EntityNameEnum;

  @MemoizeLast<AccountsCardsComponent>(['parentEntity'])
  get displayCreate() {
    return this.store.get(hasSpaceEntityPermission(EntityNameEnum.Account, EntityPermsEnum.Create))
      && this.parentEntity.instancePermissions.includes(EntityPermsEnum.Write);
  }

  constructor(
    private locationService: LocationService,
    private store: CoreStore
  ) {}

  onCreateClick() {
    this.locationService.createAccount({
      [TypeToRelationOption[this.parentEntity.__typename]]: this.parentEntity.id
    });
  }

  onCardClick(entity: AccountEntity) {
    this.locationService.openAccount(entity.id);
  }

}
