<ng-container [formGroup]="formGroup">
  <ng-container *ngIf="!controlsLayout">
    <ng-container *ngFor="let control of controls; trackBy: trackById">
      <ng-container *ngTemplateOutlet="controlRenderer; context: { $implicit: control }"></ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="controlsLayout">
    <ng-container *ngTemplateOutlet="controlsLayout; context: { $implicit: controls, controlRenderer: controlRenderer }"></ng-container>
  </ng-container>

  <ng-template #controlRenderer
               let-control>
    <ppl-input-container [label]="!control.inlineLabel ? control.label : undefined"
                         [pplFormControl]="formGroup.get(control.id)">
      <!-- AccountClass -->
      <ppl-account-class *ngIf="control.kind === EntityFormControlKind.AccountClass"
                         [formControlName]="control.id"></ppl-account-class>

      <!-- Checkbox -->
      <ppl-checkbox *ngIf="control.kind === EntityFormControlKind.Checkbox"
                    [formControlName]="control.id">{{control.inlineLabel ? control.label : undefined}}{{isControlRequired(control) ? ' *' : ''}}</ppl-checkbox>

      <!-- Currency -->
      <ppl-currency-input *ngIf="control.kind === EntityFormControlKind.Currency"
                          [currencies]="control.currencies"
                          [base]="control.base"
                          [formControlName]="control.id"></ppl-currency-input>

      <!-- Date -->
      <ppl-date-input *ngIf="control.kind === EntityFormControlKind.Date"
                      [displayRemove]="!control.nullDisabled && !isControlRequired(control)"
                      [formControlName]="control.id"></ppl-date-input>

      <!-- DateTime -->
      <ppl-date-time-input *ngIf="control.kind === EntityFormControlKind.DateTime"
                           [displayRemove]="!control.nullDisabled && !isControlRequired(control)"
                           [formControlName]="control.id"></ppl-date-time-input>

      <!-- Dropdown -->
      <ng-container *ngIf="control.kind === EntityFormControlKind.Dropdown">
        <ppl-select *ngIf="Array.isArray(control.options)"
                    [options]="control.options"
                    [displaySearch]="false"
                    [hasEmptyOption]="!isControlRequired(control)"
                    [formControlName]="control.id"></ppl-select>

        <ng-container *ngIf="!Array.isArray(control.options)">
          <ppl-select *ngIf="control.options | async; let options; else optionsLoading"
                      [options]="options"
                      [hasEmptyOption]="!isControlRequired(control)"
                      [formControlName]="control.id"></ppl-select>
        </ng-container>
      </ng-container>

      <!-- Entity -->
      <ppl-entity-select *ngIf="control.kind === EntityFormControlKind.Entity"
                         [entityAdapters]="control.entityAdapters"
                         [entityTypes]="control.entityTypes"
                         [forceDisplayClearValue]="!control.nullDisabled && !isControlRequired(control)"
                         [optionTemplate]="entityOptionTemplate"
                         [optionTemplateRowHeight]="50"
                         [maxContainerHeight]="320"
                         [formControlName]="control.id"></ppl-entity-select>

      <!-- Input -->
      <input *ngIf="control.kind === EntityFormControlKind.Input"
             pplInput
             autocomplete="off"
             [formControlName]="control.id" />

      <!-- MultipleDropdown -->
      <ng-container *ngIf="control.kind === EntityFormControlKind.MultipleDropdown">
        <ppl-multiple-select *ngIf="Array.isArray(control.options)"
                             [options]="control.options"
                             placeholder=""
                             [formControlName]="control.id"></ppl-multiple-select>

        <ng-container *ngIf="!Array.isArray(control.options)">
          <ppl-multiple-select *ngIf="control.options | async; let options; else optionsLoading"
                               [options]="options"
                               placeholder=""
                               [formControlName]="control.id"></ppl-multiple-select>
        </ng-container>
      </ng-container>

      <!-- MultipleEntity -->
      <ppl-entity-multiple-select *ngIf="control.kind === EntityFormControlKind.MultipleEntity"
                                  [entityAdapters]="control.entityAdapters"
                                  [entityTypes]="control.entityTypes"
                                  [optionTemplate]="entityOptionTemplate"
                                  [optionTemplateRowHeight]="50"
                                  [maxContainerHeight]="320"
                                  [maxValues]="control.maxValues"
                                  [selectedOptionTemplate]="entitySelectedOptionTemplate"
                                  [formControlName]="control.id"></ppl-entity-multiple-select>

      <!-- Number -->
      <ppl-input-number-clamp *ngIf="control.kind === EntityFormControlKind.Number"
                              [controls]="true"
                              [emitNull]="!isControlRequired(control)"
                              [minValue]="Number.MIN_SAFE_INTEGER"
                              [maxValue]="Number.MAX_SAFE_INTEGER"
                              [step]="control.step"
                              [wheelDisabled]="true"
                              [formControlName]="control.id"></ppl-input-number-clamp>

      <!-- Ranking -->
      <ppl-ranking *ngIf="control.kind === EntityFormControlKind.Ranking"
                   [percentValue]="true"
                   [formControlName]="control.id">Ranking</ppl-ranking>

      <!-- Textarea -->
      <textarea *ngIf="control.kind === EntityFormControlKind.Textarea"
                pplInput
                [formControlName]="control.id"></textarea>
    </ppl-input-container>
  </ng-template>
</ng-container>

<ng-template #optionsLoading>
  <div class="options-loading">
    <ppl-loading></ppl-loading>
  </div>
</ng-template>

<!-- Entity/MultipleEntity options -->
<ng-template #entityOptionTemplate
             let-option>
  <ng-container *ngTemplateOutlet="sharedEntityOptionTemplate; context: { $implicit: option, height: 50 }"></ng-container>
</ng-template>

<ng-template #entitySelectedOptionTemplate
             let-option>
  <ng-container *ngTemplateOutlet="sharedEntityOptionTemplate; context: { $implicit: option, height: 40 }"></ng-container>
</ng-template>

<ng-template #sharedEntityOptionTemplate
             let-option
             let-height="height">
  <ng-container *ngIf="option">
    <ng-container [ngSwitch]="option.data.entity.__typename">
      <ppl-entity-control-item *ngSwitchCase="'AccountEntity'"
                               [avatarKind]="AvatarKind.Medium"
                               [entityType]="EntityNameEnum.Account"
                               [height]="height"
                               [name]="option.data.entity.name"
                               [description]="option.data.entity | pplLocation"
                               [pictureUrl]="option.data.entity.picture?.url"></ppl-entity-control-item>

      <ppl-entity-control-item *ngSwitchCase="'ClientEntity'"
                               [avatarKind]="AvatarKind.Medium"
                               [entityType]="EntityNameEnum.Client"
                               [height]="height"
                               [name]="option.data.entity.name"
                               [pictureUrl]="option.data.entity.pictureUrl"></ppl-entity-control-item>

      <ppl-entity-control-item *ngSwitchCase="'ContactEntity'"
                               [avatarKind]="AvatarKind.Medium"
                               [entityType]="EntityNameEnum.Contact"
                               [height]="height"
                               [name]="option.data.entity | pplName"
                               [description]="option.data.entity.primaryAccount?.name"
                               [pictureUrl]="option.data.entity.picture?.url"></ppl-entity-control-item>

      <ppl-entity-control-item *ngSwitchCase="'LeadEntity'"
                               [avatarKind]="AvatarKind.Medium"
                               [entityType]="EntityNameEnum.Lead"
                               [height]="height"
                               [name]="option.data.entity.name"
                               [description]="option.data.entity.primaryAccount?.name"></ppl-entity-control-item>

      <ppl-entity-control-item *ngSwitchCase="'OpportunityEntity'"
                               [avatarKind]="AvatarKind.Medium"
                               [entityType]="EntityNameEnum.Opportunity"
                               [height]="height"
                               [name]="option.data.entity.name"
                               [description]="option.data.entity.primaryAccount?.name"></ppl-entity-control-item>
    </ng-container>
  </ng-container>

  <ppl-entity-control-item *ngIf="!option"
                           [avatarKind]="AvatarKind.Medium"
                           [height]="height"
                           name="Unknown Entity"
                           i18n-name="@@Unknown_Entity"
                           description="Access rights not granted on this item"
                           i18n-description="@@Access_rights_not_granted_on_this_item"></ppl-entity-control-item>
</ng-template>