import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { ContactEntity } from '@ppl/graphql-space-api';
import { LocationService } from '../../../../../services/location.service';
import type { DashboardContactItem } from '../../../domain/dashboard';

@Component({
  selector: 'ppl-dashboard-recipients-contact',
  templateUrl: './dashboard-recipients-contact.component.html',
  styleUrls: ['./dashboard-recipients-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardRecipientsContactComponent {

  @Input() items: DashboardContactItem[];
  @Input() largeCard: boolean;

  constructor(
    private locationService: LocationService
  ) {}

  onContactClick(entity: ContactEntity) {
    this.locationService.openContact(entity.id);
  }

}
