import type { OpportunityEntity, OpportunityFilterInput, OpportunityOrderByInput} from '@ppl/graphql-space-api';
import { OrderByEnum } from '@ppl/graphql-space-api';
import { gql } from '@ppl/utils';
import type { EntityAdapter } from './entity.adapter';

export const OpportunityAdapter: EntityAdapter = {
  entityFields: gql`
    fragment OpportunityEntityListFields on OpportunityEntity {
      id
      name
    }
  `,
  getFilterInput(filter, excludeIds, includeIds) {
    const filterInput: OpportunityFilterInput = {
      OR: [
        { name: { icontains: [filter] } }
      ],
      NOT: {
        id: { eq: excludeIds }
      }
    };

    if (includeIds.length !== 0) {
      filterInput.id = { eq: includeIds };
    }

    return filterInput;
  },
  getName(entity: OpportunityEntity) {
    return entity.name;
  },
  getOrderByInput(): OpportunityOrderByInput[] {
    return [
      { name: OrderByEnum.Asc }
    ];
  }
};
