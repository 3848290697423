import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import type { TaskEntity } from '@ppl/graphql-space-api';
import { PplDialogRef, PPL_DIALOG_DATA } from '@ppl/ui/dialog';
import type { TFormGroup } from '@ppl/ui/form';
import { TFormBuilder } from '@ppl/ui/form';

@Component({
  selector: 'ppl-set-due-date',
  templateUrl: './set-due-date.component.html',
  styleUrls: ['./set-due-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetDueDateComponent {

  formGroup: TFormGroup<SetDueDateFormValue>;

  constructor(
    private dialogRef: PplDialogRef<SetDueDateComponent, string>,
    @Inject(PPL_DIALOG_DATA) public data: SetDueDateData,
    private formBuilder: TFormBuilder
  ) {
    this.formGroup = this.formBuilder.group<SetDueDateFormValue>({
      dueDate: [this.data.entity.dueDate, Validators.required]
    });
  }

  onSave() {
    this.dialogRef.close(this.formGroup.get('dueDate').value);
  }

}

export interface SetDueDateData {
  entity: TaskEntity;
}

export interface SetDueDateFormValue {
  dueDate: string;
}
