import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Unsubscribe } from '@ppl/utils';
import type { Subscription } from 'rxjs';
import { VERSION } from '../../../environments/config';
import { environment } from '../../../environments/environment';
import type { SpaceGuardData } from '../../router/space-guard.service';
import { LocationService } from '../../services/location.service';

@Component({
  selector: 'ppl-bootstrap',
  templateUrl: './bootstrap.component.html',
  styleUrls: ['./bootstrap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Unsubscribe()
export class BootstrapComponent implements OnInit {

  signUpUrl = environment.signUpUrl;
  version = VERSION;

  signInQueryForSpace = false;

  dataSubscription: Subscription;

  constructor(
    private locationService: LocationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.dataSubscription = this.route.parent.data.subscribe((routeData: SpaceGuardData) => {
      if (routeData.signInNeeded) {
        this.signInQueryForSpace = routeData.signInQueryForSpace;
      } else {
        this.locationService.reset();
      }
    });
  }

  onAuthSuccess() {
    location.reload();
  }

}
