import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplMenuItemsModule } from '../menu-items';
import { PplPopoverModule } from '../popover';
import { PplPopoverMenuComponent } from './popover-menu.component';
import { PplPopoverMenuDirective } from './popover-menu.directive';

@NgModule({
    imports: [
        CommonModule,
        PplMenuItemsModule,
        PplPopoverModule
    ],
    declarations: [
        PplPopoverMenuComponent,
        PplPopoverMenuDirective
    ],
    exports: [
        PplPopoverMenuComponent,
        PplPopoverMenuDirective
    ]
})
export class PplPopoverMenuModule {}

export * from './popover-menu.component';
export * from './popover-menu.directive';

