import { Injectable, NgZone } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Subject } from 'rxjs';
import { OutlookItemMode } from '../modules/shared/outlook/domain/outlook';
import { OutlookService } from '../modules/shared/outlook/services/outlook.service';
import { CacheService } from './cache.service';

// WARNING: This service is also run in SSO dialog where "mailbox" is not available at all, do not forget to add checks!
@Injectable()
export class HostService {

  private itemChange$ = new Subject();
  private recipientsChange$ = new Subject();

  get itemChange() {
    return this.itemChange$.asObservable();
  }

  get recipientsChange() {
    return this.recipientsChange$.asObservable();
  }

  constructor(
    private cacheService: CacheService,
    private ngZone: NgZone,
    private outlookService: OutlookService
  ) {
    if (Office.context.mailbox?.addHandlerAsync) {
      Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, () => {
        console.log('Item changed', this.outlookService.isItemAvailable());

        this.ngZone.run(() => {
          this.cacheService.reset();
          this.itemChange$.next();

          this.updateSentryItem();
        });
      });

      if (this.outlookService.isItemAvailable()) {
        const itemMode = this.outlookService.getItemMode();

        if (itemMode === OutlookItemMode.Compose) {
          if (Office.context.requirements.isSetSupported('MailBox', '1.7')) {
            Office.context.mailbox.item.addHandlerAsync(Office.EventType.RecipientsChanged, () => {
              this.ngZone.run(() => {
                this.cacheService.reset();
                this.recipientsChange$.next();
              });
            });
          }
        }
      }
    }
  }

  initSentry() {
    Sentry.configureScope(scope => {
      const diagnostics = Office.context.diagnostics;

      scope.setExtra('Host', diagnostics ? {
        host: diagnostics.host,
        platform: diagnostics.platform,
        version: diagnostics.version
      } : 'Unavailable');

      scope.setExtra('accountType', Office.context.mailbox?.userProfile.accountType);

      scope.setExtra('Pipeliner_Space', localStorage.getItem('Pipeliner_Space'));
    });

    this.updateSentryItem();
  }

  updateSentryItem() {
    Sentry.configureScope(scope => {
      if (Office.context.mailbox?.item) {
        scope.setExtra('itemMode', this.outlookService.getItemMode());
        scope.setExtra('itemType', this.outlookService.getItemType());
      }
    });
  }

}
