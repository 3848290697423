import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fetchAsyncValue } from '@ppl/core';
import type { UpdateContactInput } from '@ppl/graphql-space-api';
import type { AsyncValue } from '@ppl/store';
import { AsyncState } from '@ppl/store';
import type { Observable} from 'rxjs';
import { merge, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import type { ContactData } from '../../domain/contact';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'ppl-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactComponent implements OnInit {

  data$: Observable<AsyncValue<ContactData>>;

  updateData$ = new Subject<ContactData>();

  AsyncState = AsyncState;

  constructor(
    private contactService: ContactService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.data$ = this.route.params.pipe(
      switchMap(params => {
        return fetchAsyncValue(merge(
          this.contactService.fetchData(params.id),
          this.updateData$.asObservable()
        ));
      })
    );
  }

  onFieldChange(data: ContactData, value: Pick<UpdateContactInput, 'address' | 'city' | 'country' | 'email1' | 'phone1' | 'stateProvince'>) {
    this.updateData$.next({
      ...data,
      entity: {
        ...data.entity,
        ...value
      }
    });

    this.contactService.updateField(data.entity.id, value).subscribe({
      error: () => {
        this.updateData$.next(data);
      }
    });
  }

}
