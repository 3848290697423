import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PplNamePipe } from '@ppl/auth';
import type { AccountEntity, ContactEntity, LeadEntity, OpportunityEntity } from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import type { PplAutocompleteOption } from '@ppl/ui/autocomplete';
import type { TFormGroup } from '@ppl/ui/form';
import { TFormBuilder } from '@ppl/ui/form';
import { isFormValid, PplValidators } from '@ppl/utils';
import { LocationService } from '../../../../services/location.service';
import type { EntityListTypeItem } from '../../../shared/entity/components/entity-list-type/entity-list-type.component';
import { EntityService } from '../../../shared/entity/services/entity.service';
import { AddRecipientContactEntityListAdapters, AddRecipientKind, AddRecipientSalesTeamEntityListAdapters, getAddRecipientEntityData } from '../../domain/add-recipient';
import { AddRecipientService } from '../../services/add-recipient.service';

@Component({
  selector: 'ppl-add-recipient',
  templateUrl: './add-recipient.component.html',
  styleUrls: ['./add-recipient.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddRecipientComponent {

  formGroup: TFormGroup<AddRecipientFormValue>;

  kind = this.route.snapshot.queryParamMap.get('kind') as AddRecipientKind;

  availableEntityTypes = this.entityService.filterEntityTypes((this.kind === AddRecipientKind.SalesTeam) ? [
    EntityNameEnum.Account,
    EntityNameEnum.Contact,
    EntityNameEnum.Opportunity,
    EntityNameEnum.Lead
  ]: [
    EntityNameEnum.Contact,
    EntityNameEnum.Account
  ]);

  AddRecipientKind = AddRecipientKind;
  AddRecipientSalesTeamEntityListAdapters = AddRecipientSalesTeamEntityListAdapters;
  AddRecipientContactEntityListAdapters = AddRecipientContactEntityListAdapters;

  constructor(
    private addRecipientService: AddRecipientService,
    private entityService: EntityService,
    private formBuilder: TFormBuilder,
    private i18nService: I18nService,
    private locationService: LocationService,
    private namePipe: PplNamePipe,
    private route: ActivatedRoute
  ) {
    this.formGroup = this.formBuilder.group<AddRecipientFormValue>({
      entityItems: [[], PplValidators.nonEmptyOptionArray]
    });

    this.isOptionDisabled = this.isOptionDisabled.bind(this);
  }

  getEntityName(entity: AccountEntity | ContactEntity | LeadEntity | OpportunityEntity) {
    const entityName = (entity.__typename === 'ContactEntity') ? this.namePipe.transform(entity) : entity.name;
    const entityData = getAddRecipientEntityData(entity, this.kind, this.namePipe);

    return `${entityName} ${entityData.length ? '' : `(${this.i18nService.translate((this.kind === AddRecipientKind.SalesTeam) ? 'No_sales_team' : 'No_email_contact')})`}`;
  }

  isOptionDisabled(option: PplAutocompleteOption) {
    return !getAddRecipientEntityData(option.data.entity, this.kind, this.namePipe).length;
  }

  onCancelClick() {
    this.locationService.goBack();
  }

  onAddClick() {
    if (!isFormValid(this.formGroup)) {
      return;
    }

    const entityItems = this.formGroup.get('entityItems').value;

    this.addRecipientService.addRecipients({
      entityIds: entityItems.map(item => item.entityId),
      kind: this.kind
    }).subscribe(() => {
      this.locationService.goBack();
    });
  }

}

interface AddRecipientFormValue {
  entityItems: EntityListTypeItem[];
}
