<ppl-card-medium [disabled]="true">
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="EntityNameEnum.Client"
                [kind]="AvatarKind.Medium"
                [pictureUrl]="entity.pictureUrl"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    {{entity | pplName}}
  </ng-template>
</ppl-card-medium>