// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import type { SpaceModuleOptions } from '../space.module';
import { SPACE_MODULE_OPTIONS } from '../space.tokens';
import { SpaceStore } from '../store/space.store';
import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ErrorHandlerService, getApolloLink, getAuthSsoToken } from '@ppl/auth';
import type { Space } from '@ppl/graphql-user-api';
import type {
  ApiPatchedClient,
  RecalculationError
} from '@ppl/utils';
import {
  getRecalculationError,
  gql,
  hasRecalculationError,
  patchClient
} from '@ppl/utils';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { Subject, throwError } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  switchMap,
  tap
} from 'rxjs/operators';

@Injectable()
export class ApiService {

  recalculation$: Subject<true> | null = null;

  constructor(
    @Inject(SPACE_MODULE_OPTIONS) private options: SpaceModuleOptions,
    private apollo: Apollo,
    private errorHandlerService: ErrorHandlerService,
    private httpLink: HttpLink,
    private store: SpaceStore
  ) { }

  getGqlClient(space: Space) {
    if (!this.apollo.use(space.id)) {
      const pipelinerToken = this.store.get(getAuthSsoToken);

      this.apollo.create({
        link: getApolloLink(this.httpLink, space.graphqlUrl, this.errorHandlerService, pipelinerToken, 'PipelinerToken', this.options.appId),
        cache: this.getCache()
      }, space.id);
    }

    const patchedClient = patchClient(this.apollo.use(space.id));

    // Patch again for recalculation error handler
    const patchedClientQuery = patchedClient.query;

    patchedClient.query = (...options: any[]) => {
      const queryContext = options[0].context || {};

      return patchedClientQuery.apply(patchedClient, options).pipe(
        catchError(error => {
          if (hasRecalculationError(error)) {
            this.processRecalculationError(patchedClient, getRecalculationError(error), queryContext.retryRecalculatonQuery);
            // after recalculation is done, resume query
            return this.recalculation$.pipe(
              catchError(recalculationError => throwError(recalculationError)),
              // we want to make sure recalculation is finished with success
              filter(Boolean),
              first(),
              // resume the query now
              switchMap(() => {
                return patchedClient.query.apply(patchedClient, options);
              })
            );
          } else {
            return throwError(error);
          }
        })
      );
    };

    return patchedClient;
  }

  private processRecalculationError(client: ApiPatchedClient, error: RecalculationError | null, retryQuery = false) {
    const retry = () => {
      setTimeout(() => {
        client.query({
          query: apiConnectQuery,
          fetchPolicy: 'network-only',
          context: {
            headers: new HttpHeaders().set('x-short-wait-locks', 'True'),
            retryRecalculatonQuery: true
          }
        }).pipe(
          tap(() => {
            // indicate finalization of recalculation and remove reference, thus finishing up the recalculation
            if (this.recalculation$) {
              this.recalculation$.next(true);
              this.recalculation$ = null;
            }
            console.log('Recalculation finished');
          }),
          map(() => true)
        ).subscribe();
      }, 2000);
    };

    // if recalculation is in progress, Subject reference should be defined
    if (this.recalculation$) {
      // first update recalculation progress
      console.log('Recalculation...');

      // if the query was the one for retry-ing, let's retry one more time
      if (retryQuery) {
        retry();
      }

      return this.recalculation$.asObservable();
    } else {
      // otherwise define it and start chain of progress requesting
      this.recalculation$ = new Subject();

      console.log('Recalculation started');

      retry();

      return this.recalculation$.asObservable();
    }
  }

  private getCache() {
    return new InMemoryCache({
      // TODO
    });
  }

}

const apiConnectQuery = gql`
  query apiConnectQuery {
    viewer {
      id
    }
  }
`;
