import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@ppl/core';
import { I18nModule } from '@ppl/i18n';
import { CardModule } from '../shared/card/card.module';
import { EntityCardModule } from '../shared/entity-card/entity-card.module';
import { EntityFormApiModule } from '../shared/entity-form-api/entity-form-api.module';
import { EntityFormModule } from '../shared/entity-form/entity-form.module';
import { EntityModule } from '../shared/entity/entity.module';
import { PageModule } from '../shared/page/page.module';
import { ToastModule } from '../shared/toast/toast.module';
import { LeadFormComponent } from './components/lead-form/lead-form.component';
import { LeadComponent } from './components/lead/lead.component';
import { LeadFormService } from './services/lead-form.service';
import { LeadService } from './services/lead.service';

@NgModule({
  imports: [
    CardModule,
    CommonModule,
    CoreModule,
    EntityModule,
    EntityCardModule,
    EntityFormModule,
    EntityFormApiModule,
    I18nModule,
    PageModule,
    ToastModule
  ],
  declarations: [
    LeadComponent,
    LeadFormComponent
  ],
  exports: [
    LeadComponent,
    LeadFormComponent
  ],
  providers: [
    LeadService,
    LeadFormService
  ]
})
export class LeadModule {}
