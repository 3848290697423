import { Injectable } from '@angular/core';
import { ActionsUtilsService } from '@ppl/core';
import type { CloudObjectEntity, CloudObjectRelationFilterInput, Query } from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { SpaceService } from '@ppl/space';
import { sortByKey } from '@ppl/utils';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OutlookItemType } from '../../shared/outlook/domain/outlook';
import { OutlookService } from '../../shared/outlook/services/outlook.service';
import { ToastService } from '../../shared/toast/services/toast.service';
import { gqlFetchCloudObjectRelations } from './attach-document.graphql';

@Injectable()
export class AttachDocumentService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private i18nService: I18nService,
    private outlookService: OutlookService,
    private spaceService: SpaceService,
    private toastService: ToastService
  ) {}

  fetchCloudObjects(options: FetchCloudObjectsOptions) {
    const filter: CloudObjectRelationFilterInput = {};

    switch (options.entityType) {
      case EntityNameEnum.Account:
        filter.accountId = { eq: [options.entityId] };
        break;
      case EntityNameEnum.Contact:
        filter.contactId = { eq: [options.entityId] };
        break;
      case EntityNameEnum.Lead:
      case EntityNameEnum.Opportunity:
        filter.leadOpptyId = { eq: [options.entityId] };
        break;
    }

    return this.spaceService.gqlClient.query<Query>({
      query: gqlFetchCloudObjectRelations,
      fetchPolicy: 'no-cache',
      variables: {
        filter
      }
    }).pipe(
      map(({ data }) => {
        return data.entities.cloudObjectRelation.getAll.map(relation => relation.cloudObject).sort(sortByKey('modified')).reverse();
      })
    );
  }

  attachFiles(cloudObjects: CloudObjectEntity[]) {
    const itemType = this.outlookService.getItemType();

    return this.actionsUtilsService.doAction(combineLatest(cloudObjects.map(cloudObject => {
      return this.outlookService.attachFile({
        fileName: cloudObject.filename,
        url: cloudObject.url
      });
    })).pipe(
      tap(() => {
        this.toastService.open({
          text: ({
            [OutlookItemType.Message]: this.i18nService.translate('Documents_were_attached_to_email'),
            [OutlookItemType.Appointment]: this.i18nService.translate('Documents_were_attached_to_appointment')
          })[itemType]
        });
      })
    ));
  }

}

export interface FetchCloudObjectsOptions {
  entityType: EntityNameEnum;
  entityId: string;
}
