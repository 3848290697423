<ppl-search [value]="searchText"
            (valueChange)="onSearchTextChange($event)"></ppl-search>

<div *ngIf="fetchEntitiesSession.options$ | async; let options"
     #container
     class="container"
     [style.background-image]="backgroundImage"
     (scroll)="onScroll()">
  <div *ngFor="let option of options; trackBy: trackById"
       class="entity"
       [class.selected]="!multipleSelection && isOptionSelected(option)"
       [class.disabled]="optionDisabled ? optionDisabled(option) : false"
       [style.height.px]="optionTemplateRowHeight"
       (click)="onEntityClick(option)">
    <ppl-checkbox *ngIf="multipleSelection"
                  [disabled]="optionDisabled ? optionDisabled(option) : false"
                  [value]="isOptionSelected(option)"></ppl-checkbox>

    <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option }"></ng-container>
  </div>

  <div *ngIf="fetchEntitiesSession.loading"
       class="label"
       [style.height.px]="optionTemplateRowHeight">
    <ng-container i18n="@@Loading_ellipsis">Loading…</ng-container>
  </div>

  <div *ngIf="!fetchEntitiesSession.loading && !options.length"
       class="label"
       [style.height.px]="optionTemplateRowHeight">
    {{noResultsLabel}}
  </div>
</div>