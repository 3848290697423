import { EntityFormControlKind } from '../../../entity-form/domain/controls';
import type { EntityFormApiFieldTypeAdapter } from '../entity-form-api';

export const CurrencyFieldTypeAdapter: EntityFormApiFieldTypeAdapter = {
  id: 'currency',
  getControl({ entities, fieldDescriptor, formField }) {
    return {
      id: fieldDescriptor.id,
      label: formField?.label ?? fieldDescriptor.name,
      currencies: entities.currencies,
      base: true,
      kind: EntityFormControlKind.Currency
    };
  }
};
