import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthModule } from '@ppl/auth';
import { I18nModule } from '@ppl/i18n';
import { PplIconModule } from '@ppl/ui/icon';
import { PplPopoverModule } from '@ppl/ui/popover';
import { PplSelectModule } from '@ppl/ui/select';
import { DateInputComponent } from './components/date-input/date-input.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateTimeInputComponent } from './components/date-time-input/date-time-input.component';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    I18nModule,
    PplIconModule,
    PplPopoverModule,
    PplSelectModule
  ],
  declarations: [
    DateInputComponent,
    DatePickerComponent,
    DateTimeInputComponent
  ],
  exports: [
    DateInputComponent,
    DateTimeInputComponent
  ]
})
export class DateTimeModule {}
