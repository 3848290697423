<button *ngIf="saveLabel"
        pplButton
        pplButton2
        (click)="onSaveClick()">{{saveLabel}}</button>

<button *ngIf="!saveLabel"
        pplButton
        pplButton2
        i18n="@@Save"
        (click)="onSaveClick()">Save</button>

<button pplButton
        pplButton3
        i18n="@@Cancel"
        (click)="onCancelClick()">Cancel</button>