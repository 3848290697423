import type { EntityAdapter } from './entity.adapter';
import type {
  TargetEntity,
  TargetFilterInput,
  TargetOrderByInput
} from '@ppl/graphql-space-api';
import {
  OrderByEnum
} from '@ppl/graphql-space-api';
import { gql } from '@ppl/utils';


export const TargetAdapter: EntityAdapter = {
  entityFields: gql`
    fragment TargetEntityListFields on TargetEntity {
      id
      name
      status
      targetClient {
        id
        name
      }
    }
  `,
  getFilterInput(filter, excludeIds, includeIds) {
    const filterInput: TargetFilterInput = {
      OR: [
        { name: { icontains: [filter] } }
      ],
      NOT: {
        id: { eq: excludeIds }
      }
    };

    if (includeIds.length !== 0) {
      filterInput.id = { eq: includeIds };
    }

    return filterInput;
  },
  getName(entity: TargetEntity) {
    return entity.name;
  },
  getOrderByInput(): TargetOrderByInput[] {
    return [
      { name: OrderByEnum.Asc }
    ];
  }
};
