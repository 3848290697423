import { gql } from '@ppl/utils';
import { AccountCardLargeFields, ClientCardLargeFields, ContactCardLargeFields } from '../../shared/entity-card/services/entity-card.graphql';

export const gqlFetchDashboardData = gql`
  query fetchDashboardData($accountFilter: AccountFilterInput, $contactFilter: ContactFilterInput, $clientFilter: ClientFilterInput) {
    entities {
      account {
        getAll(filter: $accountFilter) {
          ...AccountCardLargeFields
        }
      }
      contact {
        getAll(filter: $contactFilter) {
          ...ContactCardLargeFields
        }
      }
      client {
        getAll(filter: $clientFilter) {
          ...ClientCardLargeFields
        }
      }
    }
  }
  ${AccountCardLargeFields}
  ${ClientCardLargeFields}
  ${ContactCardLargeFields}
`;
