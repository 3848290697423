import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ppl-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageErrorComponent {

  @Input() text: string;
  @Input() description: string;

}
