import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ppl-relation-toast',
  templateUrl: './relation-toast.component.html',
  styleUrls: ['./relation-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelationToastComponent {

  @Input() text: string;
  @Input() entityName: string;

}
