<!-- {Entity} was created -->
<ng-container *ngIf="data.operation === SuccessToastOperation.Create">
  <ng-container *ngIf="data.entityType === EntityNameEnum.Account"
                i18n="@@Account_was_created__link__">
    <span class="link"
          (click)="onLinkClick()">{{ 'Account' | t }}</span> was created
  </ng-container>

  <ng-container *ngIf="data.entityType === EntityNameEnum.Appointment"
                i18n="@@Appointment_was_created__link__">
    <span class="link"
          (click)="onLinkClick()">Appointment</span> was created
  </ng-container>

  <ng-container *ngIf="data.entityType === EntityNameEnum.Contact"
                i18n="@@Contact_was_created__link__">
    <span class="link"
          (click)="onLinkClick()">{{ 'Contact' | t }}</span> was created
  </ng-container>

  <ng-container *ngIf="data.entityType === EntityNameEnum.Lead"
                i18n="@@Lead_was_created__link__">
    <span class="link"
          (click)="onLinkClick()">{{ 'Lead' | t }}</span> was created
  </ng-container>

  <ng-container *ngIf="data.entityType === EntityNameEnum.Opportunity"
                i18n="@@Opportunity_was_created__link__">
    <span class="link"
          (click)="onLinkClick()">{{ 'Opportunity' | t }}</span> was created
  </ng-container>

  <ng-container *ngIf="data.entityType === EntityNameEnum.Task"
                i18n="@@Task_was_created__link__">
    <span class="link"
          (click)="onLinkClick()">Task</span> was created
  </ng-container>
</ng-container>

<!-- {Entity} was updated -->
<ng-container *ngIf="data.operation === SuccessToastOperation.Update">
  <ng-container *ngIf="data.entityType === EntityNameEnum.Account"
                i18n="@@Account_was_updated__link__">
    <span class="link"
          (click)="onLinkClick()">{{ 'Account' | t }}</span> was updated
  </ng-container>

  <ng-container *ngIf="data.entityType === EntityNameEnum.Appointment"
                i18n="@@Appointment_was_updated__link__">
    <span class="link"
          (click)="onLinkClick()">Appointment</span> was updated
  </ng-container>

  <ng-container *ngIf="data.entityType === EntityNameEnum.Contact"
                i18n="@@Contact_was_updated__link__">
    <span class="link"
          (click)="onLinkClick()">{{ 'Contact' | t }}</span> was updated
  </ng-container>

  <ng-container *ngIf="data.entityType === EntityNameEnum.Lead"
                i18n="@@Lead_was_updated__link__">
    <span class="link"
          (click)="onLinkClick()">{{ 'Lead' | t }}</span> was updated
  </ng-container>

  <ng-container *ngIf="data.entityType === EntityNameEnum.Opportunity"
                i18n="@@Opportunity_was_updated__link__">
    <span class="link"
          (click)="onLinkClick()">{{ 'Opportunity' | t }}</span> was updated
  </ng-container>

  <ng-container *ngIf="data.entityType === EntityNameEnum.Task"
                i18n="@@Task_was_updated__link__">
    <span class="link"
          (click)="onLinkClick()">Task</span> was updated
  </ng-container>
</ng-container>
