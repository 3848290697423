import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorCode } from '@ppl/auth';
import { ActionsUtilsService } from '@ppl/core';
import { FirebaseEventsEnum, FirebaseService } from '@ppl/firebase';
import { EmailDirectionEnum, EntityNameEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { PplDialogService } from '@ppl/ui/dialog';
import * as CryptoJS from 'crypto-js';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, switchMap, takeWhile, tap } from 'rxjs/operators';
import { OutlookItemDirection } from '../../outlook/domain/outlook';
import { EwsTokenError, OutlookSoapService, SoapProxyError } from '../../outlook/services/outlook-soap.service';
import { OutlookService } from '../../outlook/services/outlook.service';
import { ToastService } from '../../toast/services/toast.service';
import type { DocumentNameData } from '../components/document-name/document-name.component';
import { DocumentNameComponent } from '../components/document-name/document-name.component';
import type { SelectAttachmentsData } from '../components/select-attachments/select-attachments.component';
import { SelectAttachmentsComponent } from '../components/select-attachments/select-attachments.component';
import type { EmailAttachment, EmailRelation} from '../domain/email';
import { MaxDocumentNameLength } from '../domain/email';
import { EmailEntityService } from './email-entity.service';

@Injectable()
export class EmailService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private dialogService: PplDialogService,
    private emailEntityService: EmailEntityService,
    private firebaseService: FirebaseService,
    private i18nService: I18nService,
    private outlookService: OutlookService,
    private outlookSoapService: OutlookSoapService,
    private toastService: ToastService
  ) {}

  saveAsMessage(options: SaveAsMessageOptions) {
    const uid = CryptoJS.SHA1(this.outlookService.getId()).toString();

    return this.actionsUtilsService.doAction(of(null).pipe(
      switchMap(() => {
        return combineLatest([
          this.emailEntityService.fetchEmail({
            uid
          }),
          this.outlookService.getSender(),
          this.outlookService.getCcRecipients(),
          this.outlookService.getToRecipients(),
          this.outlookService.getSubject(),
          this.outlookService.getItemDirection()
        ]);
      }),
      switchMap(([ existingEmail, sender, ccRecipients, toRecipients, subject, itemDirection ]) => {
        if (!existingEmail) {
          const created = this.outlookService.getDateTimeCreated();

          return combineLatest([
            this.outlookService.getHtmlBody(),
            this.outlookService.getTextBody()
          ]).pipe(
            switchMap(([ htmlBody, textBody ]) => {
              return this.emailEntityService.createEmail({
                body: htmlBody,
                ccRecipients: ccRecipients.map(recipient => recipient.email),
                created,
                direction: ItemDirectionMap[itemDirection],
                relations: options.relations,
                sender: sender.email,
                shortBody: textBody.slice(0, ShortBodyThreshold),
                subject,
                toRecipients: toRecipients.map(recipient => recipient.email),
                uid
              });
            }),
            switchMap(messageId => {
              if (!options.withAttachments) {
                return of(null);
              }

              const attachments = this.outlookService.getAttachments().map<EmailAttachment>(attachment => ({
                content: null,
                fileName: attachment.fileName,
                id: attachment.id
              }));

              if (attachments.length === 0) {
                return of(null);
              }

              return this.outlookSoapService.fetchContentsForAttachments(attachments).pipe(
                catchError(error => {
                  return this.handleSoapError(error);
                }),
                switchMap(attachmentsWithContents => {
                  return this.emailEntityService.createCloudObjects({
                    files: attachmentsWithContents.map(attachment => ({
                      content: attachment.content,
                      name: attachment.fileName
                    })),
                    relations: [{
                      entityId: messageId,
                      entityType: EntityNameEnum.Message
                    }]
                  });
                })
              );
            }),
            tap(() => {
              this.toastService.open({
                text: this.i18nService.translate('Email_was_added_to_feed')
              });

              this.firebaseService.logEvent(FirebaseEventsEnum.SaveEmail, {
                entities: getRelationsEntities(options.relations)
              });
            })
          );
        } else {
          const existingRelationEntityIds = [
            ...existingEmail.accountRelations.edges.map(edge => edge.node.accountId),
            ...existingEmail.contactRelations.edges.map(edge => edge.node.contactId),
            ...existingEmail.leadRelations.edges.map(edge => edge.node.leadOpptyId),
            ...existingEmail.opportunityRelations.edges.map(edge => edge.node.leadOpptyId)
          ];

          const relations = options.relations.filter(relation => !existingRelationEntityIds.includes(relation.entityId));

          if (!relations.length) {
            return of(null).pipe(
              tap(() => {
                this.toastService.open({
                  text: this.i18nService.translate('Email_already_exists_in_feed')
                });

                this.firebaseService.logEvent(FirebaseEventsEnum.SaveEmail, {
                  entities: getRelationsEntities(options.relations)
                });
              })
            );
          }

          return this.emailEntityService.createEmailRelations({
            id: existingEmail.id,
            relations
          }).pipe(
            tap(() => {
              this.toastService.open({
                text: this.i18nService.translate('Email_in_feed_was_updated')
              });

              this.firebaseService.logEvent(FirebaseEventsEnum.SaveEmail, {
                entities: getRelationsEntities(options.relations)
              });
            })
          );
        }
      })
    ));
  }

  saveAsDocument(options: SaveAsDocumentOptions) {
    return this.actionsUtilsService.doAction(of(null).pipe(
      switchMap(() => {
        if (!options.fileName) {
          return this.outlookService.getSubject().pipe(
            switchMap(subject => {
              return this.dialogService.open<DocumentNameComponent, DocumentNameData, string>(DocumentNameComponent, {
                data: {
                  fileName: subject.slice(0, MaxDocumentNameLength)
                }
              }).afterClosed().pipe(takeWhile(fileName => !!fileName));
            })
          );
        }

        return of(options.fileName);
      }),
      switchMap(fileName => {
        return this.outlookSoapService.fetchEmlContent().pipe(
          catchError(error => {
            return this.handleSoapError(error);
          }),
          switchMap(content => {
            return this.emailEntityService.createCloudObjects({
              files: [{
                content,
                name: `${fileName}.eml`,
              }],
              relations: options.relations
            });
          }),
          tap(() => {
            this.toastService.open({
              text: this.i18nService.translate('Email_was_saved')
            });

            this.firebaseService.logEvent(FirebaseEventsEnum.SaveEmailAsDocument, {
              entities: getRelationsEntities(options.relations)
            });
          })
        );
      })
    ));
  }

  saveAttachments(options: SaveAttachmentsOptions) {
    return this.actionsUtilsService.doAction(of(null).pipe(
      switchMap(() => {
        if (!options.attachments) {
          return this.dialogService.open<SelectAttachmentsComponent, SelectAttachmentsData, EmailAttachment[]>(SelectAttachmentsComponent, {
            autoFocus: false,
            data: {
              attachments: this.outlookService.getAttachments()
            }
          }).afterClosed().pipe(takeWhile(attachments => !!attachments));
        }

        return of(options.attachments);
      }),
      switchMap(attachments => {
        return this.outlookSoapService.fetchContentsForAttachments(attachments).pipe(
          catchError(error => {
            return this.handleSoapError(error);
          }),
          switchMap(attachmentsWithContents => {
            return this.emailEntityService.createCloudObjects({
              files: attachmentsWithContents.map(attachment => ({
                content: attachment.content,
                name: attachment.fileName
              })),
              relations: options.relations
            });
          }),
          tap(() => {
            if (!options.silent) {
              this.toastService.open({
                text: this.i18nService.translate('Attachments_were_saved')
              });

              this.firebaseService.logEvent(FirebaseEventsEnum.SaveEmailAttachments, {
                entities: getRelationsEntities(options.relations)
              });
            }
          })
        );
      })
    ), {
      silent: options.silent
    });
  }

  private handleSoapError(error: any) {
    let errorMessage = this.i18nService.translate('Unable_to_retrieve_the_email_contents_from_the_server.');

    if (error instanceof HttpErrorResponse) {
      try {
        const value = JSON.parse(error.error);

        if (value?.code === ErrorCode.ERROR_3RD_PARTY_API_ERROR) {
          errorMessage = this.i18nService.translate('Unable_to_retrieve_the_email_contents_from_the_Exchange_server.');
        } else if (value?.code === ErrorCode.ERROR_3RD_PARTY_AUTH_FAILED) {
          errorMessage = this.i18nService.translate('Unable_to_authenticate_with_the_Exchange_server.');
        }
      } catch (ex) {}

      error = new SoapProxyError(error.error);
    } else if (error instanceof EwsTokenError) {
      errorMessage = this.i18nService.translate('Unable_to_obtain_an_authentication_token_for_the_Exchange_server.');
    }

    this.dialogService.alert({
      text: errorMessage
    });

    return throwError(error);
  }

}

const ItemDirectionMap = {
  [OutlookItemDirection.Received]: EmailDirectionEnum.IncomingEmail,
  [OutlookItemDirection.Sent]: EmailDirectionEnum.OutgoingEmail
};

const ShortBodyThreshold = 10000;

function getRelationsEntities(relations: EmailRelation[]) {
  return Array.from(new Set(relations.map(relation => relation.entityType))).join(',');
}

export interface SaveAsMessageOptions {
  relations: EmailRelation[];
  withAttachments?: boolean;
}

export interface SaveAsDocumentOptions {
  fileName?: string;
  relations: EmailRelation[];
}

export interface SaveAttachmentsOptions {
  attachments?: EmailAttachment[];
  relations: EmailRelation[];
  silent?: true;
}
