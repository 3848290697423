import { EntityNameEnum } from '@ppl/graphql-space-api';

export const CreateEntityMap: { [id in EntityNameEnum]?: string[] } = {
  [EntityNameEnum.Account]: [
    'ContactEntity',
    'LeadEntity',
    'OpportunityEntity'
  ],
  [EntityNameEnum.Appointment]: [
    'AccountEntity',
    'LeadEntity',
    'ContactEntity',
    'OpportunityEntity'
  ],
  [EntityNameEnum.Contact]: [
    'AccountEntity',
    'LeadEntity',
    'OpportunityEntity'
  ],
  [EntityNameEnum.Lead]: [
    'AccountEntity',
    'ContactEntity'
  ],
  [EntityNameEnum.Opportunity]: [
    'AccountEntity',
    'ContactEntity'
  ],
  [EntityNameEnum.Task]: [
    'AccountEntity',
    'LeadEntity',
    'ContactEntity',
    'OpportunityEntity'
  ]
};

export const TypeToEntityType: { [id: string]: EntityNameEnum } = {
  'AccountEntity': EntityNameEnum.Account,
  'AppointmentEntity': EntityNameEnum.Appointment,
  'ContactEntity': EntityNameEnum.Contact,
  'LeadEntity': EntityNameEnum.Lead,
  'OpportunityEntity': EntityNameEnum.Opportunity,
  'TaskEntity': EntityNameEnum.Task
};

export const TypeToRelationOption: { [id: string]: string } = {
  'AccountEntity': 'accountId',
  'ContactEntity': 'contactId',
  'LeadEntity': 'leadId',
  'OpportunityEntity': 'opportunityId'
};
