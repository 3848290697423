<ng-container *ngFor="let entity of visibleEntities; trackBy: trackById">
  <ng-template *ngTemplateOutlet="cardTemplate; context: { $implicit: entity }"></ng-template>
</ng-container>

<div *ngIf="entities.length > VisibleCardsThreshold"
     class="chevron"
     (click)="allVisible = !allVisible">
  <ng-container *ngIf="allVisible"
                i18n="@@Show_Less">Show Less</ng-container>

  <ng-container *ngIf="!allVisible"
                i18n="@@Show_All">Show All</ng-container>
</div>