import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { PplDatePickerIntl } from '@ppl/ui/date-picker';
import type { PplSelectOption } from '@ppl/ui/select';
import { FormValueControl, getFormControlProvider, getTodayDate, MemoizeLast, momentToIsoDateString } from '@ppl/utils';
import moment from 'moment';

@Component({
  selector: 'ppl-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    getFormControlProvider(() => DateTimeInputComponent)
  ]
})
@FormValueControl()
export class DateTimeInputComponent {

  @Input() displayRemove = true;
  @Input() value: string | null;
  @Input() disabled?: boolean;

  @Output() valueChange = new EventEmitter<string | null>();

  is24HourSystem = !this.datePickerIntl.dateTimeFormat.toLowerCase().endsWith('a');

  @MemoizeLast<DateTimeInputComponent>(['timeValue'])
  get timeOptions() {
    const options: PplSelectOption[] = [];

    Array.from({ length: TimeOptionsCount }).forEach((value, index) => {
      options.push({
        label: this.formatTimeValue(index * TimeOptionsMinutesStep),
        value: (index * TimeOptionsMinutesStep).toString()
      });
    });

    if (this.timeValue && !options.find(option => option.value === this.timeValue)) {
      options.push({
        deleted: true,
        label: this.formatTimeValue(parseInt(this.timeValue, 10)),
        value: this.timeValue
      });
    }

    return options;
  }

  @MemoizeLast<DateTimeInputComponent>(['value'])
  get dateValue() {
    if (!this.value) {
      return null;
    }

    return momentToIsoDateString(moment(this.value));
  }

  @MemoizeLast<DateTimeInputComponent>(['value'])
  get timeValue() {
    if (!this.value) {
      return null;
    }

    return moment(this.value).diff(moment(this.value).startOf('day'), 'minutes').toString();
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private datePickerIntl: PplDatePickerIntl
  ) {}

  onDateValueChange(value: string | null) {
    if (!value) {
      this.valueChange.emit(null);
    } else {
      if (!this.value) {
        this.valueChange.emit(moment(value).hours(DefaultHour).toISOString());
      } else {
        const { hours, minutes } = this.parseTimeValue(this.timeValue);

        this.valueChange.emit(moment(value).hours(hours).minutes(minutes).toISOString());
      }
    }
  }

  onTimeValueChange(value: string) {
    const { hours, minutes } = this.parseTimeValue(value);

    this.valueChange.emit(moment(this.dateValue || getTodayDate()).hours(hours).minutes(minutes).toISOString());
  }

  private parseTimeValue(value: string) {
    return {
      hours: Math.floor(parseInt(value, 10) / 60),
      minutes: parseInt(value, 10) % 60
    };
  }

  private formatTimeValue(value: number) {
    return moment(value * 60 * 1000).utc().format(this.is24HourSystem ? 'HH:mm' : 'hh:mm A');
  }

}

const DefaultHour = 9;
const TimeOptionsCount = 24 * 2;
const TimeOptionsMinutesStep = 30;
