import type { OnDestroy } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { I18nService } from '@ppl/i18n';
import { PplDialogRef } from '@ppl/ui/dialog';

@Component({
  selector: 'ppl-phone-call',
  templateUrl: './phone-call.component.html',
  styleUrls: ['./phone-call.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneCallComponent implements OnDestroy {

  cancelLabel: string;
  timeLeft = TimeLeft;

  updateHandle: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: PplDialogRef<PhoneCallComponent>,
    private i18nService: I18nService
  ) {
    this.updateHandle = setInterval(() => {
      this.timeLeft -= 1;
      this.updateCancelLabel();
      this.changeDetectorRef.detectChanges();

      if (this.timeLeft === 0) {
        this.dialogRef.close();
      }
    }, 1000);

    this.updateCancelLabel();
  }

  ngOnDestroy() {
    clearInterval(this.updateHandle);
  }

  updateCancelLabel() {
    this.cancelLabel = `${this.i18nService.translate('Close')} (${this.timeLeft})`;
  }

}

const TimeLeft = 10;
