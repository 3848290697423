import type { OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { PplNamePipe } from '@ppl/auth';
import type { FetchEntitiesSession } from '@ppl/components/entity-list';
import { EntityAdapters, EntityListService } from '@ppl/components/entity-list';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import type { PplAutocompleteOption, PplAutocompleteOptionsRequest } from '@ppl/ui/autocomplete';
import { SpaceService } from '@ppl/space';
import { MemoizeLast, trackById, unsubscribe } from '@ppl/utils';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ppl-entity-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityListComponent implements OnChanges, OnDestroy {

  @Input() entityType: EntityNameEnum;
  @Input() noResultsLabel: string;
  @Input() value: string | string[] | null;

  @Input() entityAdapters?: EntityAdapters;
  @Input() multipleSelection?: boolean;
  @Input() optionDisabled?: (option: PplAutocompleteOption) => boolean;
  @Input() optionTemplate?: TemplateRef<any>;
  @Input() optionTemplateRowHeight?: number;

  @Output() valueChange = new EventEmitter<string | string[]>();

  @ViewChild('container') container: ElementRef;

  fetchEntitiesSession: FetchEntitiesSession = {
    loading: false,
    options$: new BehaviorSubject<PplAutocompleteOption[]>([])
  };

  searchText = '';

  trackById = trackById;

  @MemoizeLast<EntityListComponent>(['optionTemplateRowHeight'])
  get backgroundImage() {
    return `repeating-linear-gradient(180deg, #f4f4f4, #f4f4f4 ${this.optionTemplateRowHeight}px,
        transparent ${this.optionTemplateRowHeight}px, transparent ${this.optionTemplateRowHeight * 2}px`;
  }

  constructor(
    private entityListService: EntityListService,
    private namePipe: PplNamePipe,
    private spaceService: SpaceService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entityType) {
      this.onSearchTextChange('');
    }
  }

  ngOnDestroy() {
    unsubscribe(this.fetchEntitiesSession.subscription);
  }

  isOptionSelected(option: PplAutocompleteOption) {
    if (Array.isArray(this.value)) {
      return this.value.includes(option.value);
    } else {
      return this.value === option.value;
    }
  }

  onOptionsRequest(event: PplAutocompleteOptionsRequest) {
    this.entityListService.fetchEntities(this.fetchEntitiesSession, event, {
      entityAdapters: this.entityAdapters,
      entityType: this.entityType,
      entityTypes: [this.entityType],
      namePipe: this.namePipe,
      perPage: EntitiesPerPage,
      spaceService: this.spaceService
    });
  }

  onSearchTextChange(searchText: string) {
    this.searchText = searchText;

    this.onOptionsRequest({
      filter: this.searchText,
      lastOptionValue: null,
      lastOptionData: null
    });
  }

  onEntityClick(option: PplAutocompleteOption) {
    if (Array.isArray(this.value)) {
      if (this.value.includes(option.value)) {
        this.valueChange.emit(this.value.filter(id => id !== option.value));
      } else {
        this.valueChange.emit([
          ...this.value,
          option.value
        ]);
      }
    } else {
      if (option.value !== this.value) {
        this.valueChange.emit(option.value);
      }
    }
  }

  onScroll() {
    const clientHeight = this.container.nativeElement.clientHeight;
    const scrollHeight = this.container.nativeElement.scrollHeight;
    const scrollTop = this.container.nativeElement.scrollTop;

    if (scrollTop === scrollHeight - clientHeight) {
      const options = this.fetchEntitiesSession.options$.getValue();
      const lastOption = options[options.length - 1];

      if (lastOption) {
        this.onOptionsRequest({
          filter: this.searchText,
          lastOptionValue: lastOption.value,
          lastOptionData: lastOption.data
        });
      }
    }
  }

}

const EntitiesPerPage = 50;
