<ppl-page>
  <ng-template #headerTemplate
               let-globalSearch="globalSearch">
    <ppl-page-detail-header label="Appointment"
                            i18n-label="@@Appointment"
                            [globalSearch]="globalSearch"></ppl-page-detail-header>
  </ng-template>

  <ng-container *ngIf="data$ | async; let data">
    <div *ngIf="data.state === AsyncState.FETCHED"
         class="container">
      <ppl-appointment-card-large [entity]="data.value.entity"
                                  [disabled]="true"></ppl-appointment-card-large>

      <ppl-actions-card [entity]="data.value.entity"></ppl-actions-card>

      <ppl-appointment-fields-card [entity]="data.value.entity"
                                   (locationChange)="onFieldChange(data.value, { location: $event })"></ppl-appointment-fields-card>

      <ppl-description-card *ngIf="data.value.entity.description"
                            [entity]="data.value.entity"></ppl-description-card>

      <ppl-attendees-cards *ngIf="data.value.attendees.length"
                           [entities]="data.value.attendees"></ppl-attendees-cards>

      <ppl-linked-items-cards [accounts]="data.value.relatedAccounts"
                              [contacts]="data.value.relatedContacts"
                              [leads]="data.value.relatedLeads"
                              [opportunities]="data.value.relatedOpportunities"
                              [parentEntity]="data.value.entity"></ppl-linked-items-cards>

      <ppl-date-card [entity]="data.value.entity"></ppl-date-card>
    </div>

    <ppl-page-loading *ngIf="data.state === AsyncState.FETCHING"></ppl-page-loading>

    <ppl-entity-error *ngIf="data.state === AsyncState.ERROR"
                      [error]="data.error"></ppl-entity-error>
  </ng-container>
</ppl-page>