import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { PplDialogRef, PPL_DIALOG_DATA } from '@ppl/ui/dialog';
import type { TFormGroup } from '@ppl/ui/form';
import { TFormBuilder } from '@ppl/ui/form';
import { MaxDocumentNameLength } from '../../domain/email';

@Component({
  selector: 'ppl-document-name',
  templateUrl: './document-name.component.html',
  styleUrls: ['./document-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentNameComponent {

  formGroup: TFormGroup<DocumentNameFormValue>;

  constructor(
    private dialogRef: PplDialogRef<DocumentNameComponent, string>,
    @Inject(PPL_DIALOG_DATA) public data: DocumentNameData,
    private formBuilder: TFormBuilder
  ) {
    this.formGroup = this.formBuilder.group<DocumentNameFormValue>({
      fileName: [this.data.fileName, [
        Validators.required,
        Validators.maxLength(MaxDocumentNameLength)
      ]]
    });
  }

  onSave() {
    this.dialogRef.close(this.formGroup.get('fileName').value);
  }

}

interface DocumentNameFormValue {
  fileName: string;
}

export interface DocumentNameData {
  fileName: string;
}
