import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PplNamePipe } from '@ppl/auth';
import { MemoizeLast } from '@ppl/utils';
import type { DashboardItem} from '../../../../dashboard/domain/dashboard';
import { DashboardItemKind } from '../../../../dashboard/domain/dashboard';
import { TrackingVisits } from '../../domain/tracking';

@Component({
  selector: 'ppl-tracking-read',
  templateUrl: './tracking-read.component.html',
  styleUrls: ['./tracking-read.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackingReadComponent {

  @Input() recipients: DashboardItem[];
  @Input() visits: TrackingVisits;

  @MemoizeLast<TrackingReadComponent>(['recipients', 'visits'])
  get displayName() {
    const notUserRecipients = this.recipients.filter(recipient => recipient.kind !== DashboardItemKind.User);

    if (notUserRecipients.length === 1) {
      switch (notUserRecipients[0].kind) {
        case DashboardItemKind.Account:
          return notUserRecipients[0].entity.name;
        case DashboardItemKind.Contact:
        case DashboardItemKind.Client:
          return this.namePipe.transform(notUserRecipients[0].entity);
        case DashboardItemKind.Other:
          return notUserRecipients[0].person.name;
      }
    }

    return null;
  }

  constructor(
    private namePipe: PplNamePipe
  ) {}

}
