import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EntityNameEnum, EntityPermsEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import type { PplMenuItem } from '@ppl/ui/menu-items';
import { hasSpaceEntityPermission } from '@ppl/space';
import { LocationService } from '../../../../../services/location.service';
import { CoreStore } from '../../../../../store/core.store';
import { AvatarKind } from '../../../avatar/domain/avatar';
import { OutlookPerson } from '../../../outlook/domain/outlook';

@Component({
  selector: 'ppl-other-card-small',
  templateUrl: './other-card-small.component.html',
  styleUrls: ['./other-card-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OtherCardSmallComponent {

  @Input() person: OutlookPerson;
  @Input() active?: boolean;

  createMenuItems: PplMenuItem[] = [{
    disabled: !this.store.get(hasSpaceEntityPermission(EntityNameEnum.Contact, EntityPermsEnum.Create)),
    icon: 'menu-contact',
    id: EntityNameEnum.Contact,
    label: this.i18nService.translate('Save_contact_to_Pipeliner_as_new_contact')
  }, {
    disabled: !this.store.get(hasSpaceEntityPermission(EntityNameEnum.Account, EntityPermsEnum.Create)),
    icon: 'menu-account',
    id: EntityNameEnum.Account,
    label: this.i18nService.translate('Save_contact_to_Pipeliner_as_new_account')
  }];

  createDisabled = this.createMenuItems.every(menuItem => menuItem.disabled);

  AvatarKind = AvatarKind;

  constructor(
    private i18nService: I18nService,
    private locationService: LocationService,
    private store: CoreStore
  ) {}

  onCreateMenuItemClick(entityType: EntityNameEnum) {
    switch (entityType) {
      case EntityNameEnum.Account:
        this.locationService.createAccount({
          email: this.person.email
        });
        break;
      case EntityNameEnum.Contact:
        this.locationService.createContact({
          email: this.person.email
        });
        break;
    }
  }

}
