<ppl-multiple-autocomplete [options]="fetchEntitiesSession.options$ | async"
                           [categories]="entitiesCategories"
                           [value]="entityIds"
                           [displayOptionsLoading]="fetchEntitiesSession.loading"
                           [displayValueLoading]="valueLoading"
                           [placeholder]="''"
                           [selectedOptionTemplate]="cacheSelectedOptionTemplate"
                           [optionTemplate]="optionTemplate"
                           [optionTemplateRowHeight]="optionTemplateRowHeight"
                           [maxOptionListContainerHeight]="maxContainerHeight"
                           [maxValues]="maxValues"
                           [displayAll]="true"
                           (optionsRequest)="onOptionsRequest($event)"
                           (valueChange)="onValueChange($event)"></ppl-multiple-autocomplete>

<ng-template #cacheSelectedOptionTemplate
             let-option>
  <ng-container *ngTemplateOutlet="selectedOptionTemplate; context: { $implicit: optionValueCache[option.value] }"></ng-container>
</ng-template>