import { Injectable } from '@angular/core';
import { ActionsUtilsService } from '@ppl/core';
import { OpportunityFieldsEnum } from '@ppl/domain';
import { FirebaseEventsEnum, FirebaseService } from '@ppl/firebase';
import type { OpportunityEntity, PipelineEntity } from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { getSpaceAllowedPipelineIds, getSpaceFormById, getSpaceForms, getSpacePipelineById, getSpacePipelines } from '@ppl/space';
import type { PplSelectOption } from '@ppl/ui/select';
import { sortByKey } from '@ppl/utils';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { RecentRecordsService } from '../../../services/recent-records.service';
import { CoreStore } from '../../../store/core.store';
import { EmailService } from '../../shared/email/services/email.service';
import { EntityFormApiService } from '../../shared/entity-form-api/services/entity-form-api.service';
import type { EntityFormControl, EntityFormEntityControl } from '../../shared/entity-form/domain/controls';
import { EntityFormControlKind } from '../../shared/entity-form/domain/controls';
import { EntityFormValidatorKind } from '../../shared/entity-form/domain/validators';
import { SuccessToastComponent } from '../../shared/entity/components/success-toast/success-toast.component';
import type { SuccessToastData} from '../../shared/entity/domain/success-toast';
import { SuccessToastOperation } from '../../shared/entity/domain/success-toast';
import { EntityService } from '../../shared/entity/services/entity.service';
import { OutlookService } from '../../shared/outlook/services/outlook.service';
import { ToastService } from '../../shared/toast/services/toast.service';
import type { OpportunityFormData, OpportunityFormDataOutlookItem, OpportunityFormValue } from '../domain/opportunity-form';
import { gqlCreateOpportunityMutation, gqlFetchOpportunityFormData, gqlUpdateOpportunityMutation } from './opportunity-form.graphql';

@Injectable()
export class OpportunityFormService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private emailService: EmailService,
    private entityService: EntityService,
    private entityFormApiService: EntityFormApiService,
    private firebaseService: FirebaseService,
    private i18nService: I18nService,
    private outlookService: OutlookService,
    private recentRecordsService: RecentRecordsService,
    private store: CoreStore,
    private toastService: ToastService
  ) {}

  fetchData(options: FetchDataOptions) {
    return combineLatest([
      this.entityService.fetchEntity<OpportunityEntity>({
        entityType: EntityNameEnum.Opportunity,
        id: options.id,
        query: gqlFetchOpportunityFormData
      }),
      this.entityFormApiService.fetchContext(EntityNameEnum.Opportunity, {
        entities: [EntityNameEnum.Pipeline, EntityNameEnum.SalesUnit]
      }),
      this.fetchOutlookItem()
    ]).pipe(
      map(([ entity, context, outlookItem ]) => {
        if (options.id && !entity) {
          return null;
        }

        const result: OpportunityFormData = {
          entity,
          context,
          outlookItem,
          relatedAccountId: options.relatedAccountId,
          relatedContactId: options.relatedContactId
        };

        return result;
      })
    );
  }

  getControls(data: OpportunityFormData, formId: string) {
    const pipelines = this.store.get(getSpacePipelines);
    const allowedPipelineIds = this.store.get(getSpaceAllowedPipelineIds);

    const createForm = !data.entity;
    const outlookHasAttachments = this.outlookService.hasAttachments();

    const controls: EntityFormControl[] = [
      {
        id: 'pipelineId',
        label: this.i18nService.translate('Pipeline'),
        options: pipelines.map<PplSelectOption>(pipeline => ({
          deleted: pipeline.isDeleted || !allowedPipelineIds.includes(pipeline.id),
          label: pipeline.name,
          value: pipeline.id
        })).sort(sortByKey('label')),
        kind: EntityFormControlKind.Dropdown,
        validators: [{
          kind: EntityFormValidatorKind.Required
        }]
      },
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: OpportunityFieldsEnum.NameField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: OpportunityFieldsEnum.ValueField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: OpportunityFieldsEnum.ClosingDateField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: OpportunityFieldsEnum.UnitIdField,
        formId
      }),
      {
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId: OpportunityFieldsEnum.OwnerIdField,
          forceOnForm: true,
          formId
        }) as EntityFormEntityControl,
        disabled: true
      },
      {
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId: OpportunityFieldsEnum.AccountCardField,
          forceOnForm: !!data.relatedAccountId,
          formId
        }) as EntityFormEntityControl,
        nullDisabled: !!data.entity || !!data.relatedAccountId
      },
      {
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId: OpportunityFieldsEnum.ContactCardField,
          forceOnForm: !!data.relatedContactId,
          formId
        }) as EntityFormEntityControl,
        nullDisabled: !!data.entity || !!data.relatedContactId
      },
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: OpportunityFieldsEnum.RankingField,
        formId
      }),
      this.entityFormApiService.getControl({
        context: data.context,
        fieldId: OpportunityFieldsEnum.DescriptionField,
        formId
      }),
      createForm && outlookHasAttachments && {
        id: 'saveAttachments',
        label: this.i18nService.translate('Save_email_attachments_to_new_opportunity'),
        inlineLabel: true,
        kind: EntityFormControlKind.Checkbox
      },
      ...this.entityFormApiService.getCustomFieldIds({
        context: data.context,
        formId
      }).map(fieldId => ({
        ...this.entityFormApiService.getControl({
          context: data.context,
          fieldId,
          formId
        }),
        data: {
          customField: true
        }
      }))
    ];

    return controls.filter(control => control);
  }

  getValue(data: OpportunityFormData, formId: string): OpportunityFormValue {
    const value = this.entityFormApiService.getValue({
      context: data.context,
      entity: data.entity,
      fieldIds: [
        OpportunityFieldsEnum.NameField,
        OpportunityFieldsEnum.ValueField,
        OpportunityFieldsEnum.ClosingDateField,
        OpportunityFieldsEnum.UnitIdField,
        { fieldId: OpportunityFieldsEnum.OwnerIdField, forceOnForm: true },
        OpportunityFieldsEnum.AccountCardField,
        OpportunityFieldsEnum.ContactCardField,
        OpportunityFieldsEnum.RankingField,
        OpportunityFieldsEnum.DescriptionField,
        ...this.entityFormApiService.getCustomFieldIds({
          context: data.context,
          formId
        })
      ],
      formId
    });

    if (data.entity) {
      return {
        ...value,
        pipelineId: this.getPipelineIdByFormId(formId)
      };
    } else {
      return {
        ...value,
        [OpportunityFieldsEnum.NameField]: data.outlookItem.subject,
        [OpportunityFieldsEnum.AccountCardField]: data.relatedAccountId,
        [OpportunityFieldsEnum.ContactCardField]: data.relatedContactId,
        pipelineId: this.getPipelineIdByFormId(formId),
        saveAttachments: false
      };
    }
  }

  createEntity(value: OpportunityFormValue, data: OpportunityFormData) {
    const pipeline = this.store.get(getSpacePipelineById(value.pipelineId));
    const stepId = this.getPipelineDefaultStepId(pipeline);

    return this.actionsUtilsService.doAction(this.entityFormApiService.saveEntity({
      input: {
        ...this.entityFormApiService.getInput({
          context: data.context,
          value
        }),
        stepId
      },
      mutation: gqlCreateOpportunityMutation
    }).pipe(
      switchMap(result => {
        const opportunityId = result.createOpportunity.opportunity.id;

        return of(null).pipe(
          switchMap(() => {
            if (value.saveAttachments) {
              return this.emailService.saveAttachments({
                relations: [{
                  entityId: opportunityId,
                  entityType: EntityNameEnum.Opportunity
                }],
                silent: true
              });
            }

            return of(null);
          }),
          tap(() => {
            this.toastService.open<SuccessToastData>({
              content: {
                component: SuccessToastComponent,
                data: {
                  entityType: EntityNameEnum.Opportunity,
                  id: opportunityId,
                  operation: SuccessToastOperation.Create
                }
              }
            });

            this.recentRecordsService.add(opportunityId);

            const hasRelation = data.relatedAccountId || data.relatedContactId;

            this.firebaseService.logEvent(hasRelation ? FirebaseEventsEnum.Create : FirebaseEventsEnum.CreateFromEmail, {
              entity: 'opportunity'
            });
          })
        );
      })
    ));
  }

  updateEntity(value: OpportunityFormValue, data: OpportunityFormData) {
    const pipeline = this.store.get(getSpacePipelineById(value.pipelineId));
    const stepId = (value.pipelineId !== data.entity.step.pipelineId) ? this.getPipelineDefaultStepId(pipeline) : data.entity.step.id;

    return this.actionsUtilsService.doAction(this.entityFormApiService.saveEntity({
      input: {
        ...this.entityFormApiService.getInput({
          entity: data.entity,
          context: data.context,
          value
        }),
        stepId,
        id: data.entity.id
      },
      mutation: gqlUpdateOpportunityMutation
    }).pipe(
      tap(() => {
        this.toastService.open<SuccessToastData>({
          content: {
            component: SuccessToastComponent,
            data: {
              entityType: EntityNameEnum.Opportunity,
              id: data.entity.id,
              operation: SuccessToastOperation.Update
            }
          }
        });

        this.firebaseService.logEvent(FirebaseEventsEnum.Update, {
          entity: 'opportunity'
        });
      })
    ));
  }

  getDefaultFormId() {
    const pipelines = this.store.get(getSpacePipelines).sort(sortByKey('name'));
    const allowedPipelineIds = this.store.get(getSpaceAllowedPipelineIds);

    return this.getFormIdByPipelineId(pipelines.filter(pipeline => allowedPipelineIds.includes(pipeline.id) && !pipeline.isDeleted)[0].id);
  }

  getFormIdByPipelineId(pipelineId: string) {
    return this.store.get(getSpaceForms(EntityNameEnum.Opportunity)).find(opportunityType => opportunityType.pipelineId === pipelineId).id;
  }

  getPipelineIdByFormId(formId: string) {
    return this.store.get(getSpaceFormById(formId)).pipelineId;
  }

  private fetchOutlookItem() {
    return combineLatest([
      this.outlookService.getSubject()
    ]).pipe(
      map(([ subject ]) => {
        const result: OpportunityFormDataOutlookItem = {
          subject
        };

        return result;
      })
    );
  }

  private getPipelineDefaultStepId(pipeline: PipelineEntity | null) {
    return pipeline?.steps[0].id || null;
  }

}

export interface FetchDataOptions {
  id: string | null;
  relatedAccountId: string | null;
  relatedContactId: string | null;
}
