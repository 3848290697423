<ng-container *ngIf="items.length === 1">
  <ng-template *ngTemplateOutlet="cardTemplate; context: { $implicit: items[0] }"></ng-template>
</ng-container>

<ng-container *ngIf="items.length > 1">
  <ppl-card-small [active]="allVisible"
                  (click)="allVisible = !allVisible">
    <ng-template #iconTemplate>
      <ppl-icon class="icon"
                [svgIcon]="icon"
                width="18px"
                height="18px"></ppl-icon>
    </ng-template>

    <ng-template #nameTemplate>
      {{name}}
    </ng-template>

    <ng-template #actionTemplate>
      <ppl-icon class="chevron"
                [class.closed]="!allVisible"
                svgIcon="arrow-gray"
                width="16px"
                height="16px"></ppl-icon>
    </ng-template>
  </ppl-card-small>

  <ng-container *ngIf="allVisible">
    <ng-container *ngFor="let item of items">
      <ng-template *ngTemplateOutlet="cardTemplate; context: { $implicit: item, active: allVisible }"></ng-template>
    </ng-container>
  </ng-container>
</ng-container>