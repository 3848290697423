<ppl-card-inline-field icon="entity-detail-due-date"
                       [value]="entity.startDate | pplDateTimeRange:entity.endDate"
                       [disabled]="true">
  {{entity.startDate | pplDateTimeRange:entity.endDate}}
</ppl-card-inline-field>

<ppl-card-inline-field icon="entity-detail-address"
                       [value]="entity.location"
                       emptyLabel="Add Location"
                       i18n-emptyLabel="@@Add_Location"
                       [disabled]="!entity.instancePermissions.includes(EntityPermsEnum.Write)"
                       (valueChange)="onLocationChange($event)">
  <ng-template #editorTemplate
               let-valueChange="valueChange">
    <ppl-location-picker (placeSelect)="valueChange($event)"></ppl-location-picker>
  </ng-template>

  <a [href]="locationUrl">{{entity.location}}</a>
</ppl-card-inline-field>