import { CoreStore } from './store/core.store';
import { environment } from '../environments/environment';
import { Translations } from '../translations';
import { Component } from '@angular/core';
import { getLoggedInUserTimezoneName } from '@ppl/auth';
import { FirebaseService } from '@ppl/firebase';
import { I18nService } from '@ppl/i18n';
import { PplIconService } from '@ppl/ui/icon';
import { setMomentDefaultTimezone, unsubscribe } from '@ppl/utils';
import type { OnDestroy, OnInit } from '@angular/core';
import type { Subscription } from 'rxjs';

@Component({
  selector: 'ppl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  userTimezoneChangeSubscription: Subscription;

  constructor(
    private store: CoreStore,
    private iconService: PplIconService,
    private i18nService: I18nService,
    private firebaseService: FirebaseService
  ) {
    this.iconService.registerPplIcons(environment.deployUrl);
    this.i18nService.registerTranslations(Translations);
    this.firebaseService.init();
  }

  ngOnInit() {
    this.userTimezoneChangeSubscription = this.store.select(getLoggedInUserTimezoneName).subscribe(timezoneName => {
      setMomentDefaultTimezone(timezoneName);
    });
  }

  ngOnDestroy() {
    unsubscribe(this.userTimezoneChangeSubscription);
  }
}
