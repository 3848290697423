import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { ContactEntity, EntityNameEnum } from '@ppl/graphql-space-api';
import { LocationService } from '../../../../../services/location.service';
import { AvatarKind } from '../../../avatar/domain/avatar';

@Component({
  selector: 'ppl-contact-card-large',
  templateUrl: './contact-card-large.component.html',
  styleUrls: ['./contact-card-large.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactCardLargeComponent {

  @Input() entity: ContactEntity;
  @Input() disabled?: boolean;

  AvatarKind = AvatarKind;
  EntityNameEnum = EntityNameEnum;

  constructor(
    private locationService: LocationService
  ) {}

  @HostListener('click')
  onClick() {
    if (!this.disabled) {
      this.locationService.openContact(this.entity.id);
    }
  }

  onAccountClick(event: MouseEvent) {
    this.locationService.openAccount(this.entity.primaryAccount.id);

    event.stopPropagation();
  }

}
