import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OpportunityEntity, OpptyStatusEnum } from '@ppl/graphql-space-api';
import { getSpaceCurrencyById } from '@ppl/space';
import { getDaysOverdue, isOverdue, MemoizeLast } from '@ppl/utils';
import { CoreStore } from '../../../../../store/core.store';

@Component({
  selector: 'ppl-opportunity-fields-card',
  templateUrl: './opportunity-fields-card.component.html',
  styleUrls: ['./opportunity-fields-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunityFieldsCardComponent {

  @Input() entity: OpportunityEntity;

  OpptyStatusEnum = OpptyStatusEnum;

  @MemoizeLast<OpportunityFieldsCardComponent>(['entity'])
  get isOverdue() {
    return isOverdue(this.entity.closingDate);
  }

  @MemoizeLast<OpportunityFieldsCardComponent>(['entity'])
  get daysOverdueAbs() {
    return Math.abs(getDaysOverdue(this.entity.closingDate));
  }

  @MemoizeLast<OpportunityFieldsCardComponent>(['entity'])
  get valueCurrencyCode() {
    return this.store.get(getSpaceCurrencyById(this.entity.value.currencyId)).code;
  }

  constructor(
    private store: CoreStore
  ) {}

}
