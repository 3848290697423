import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { AccountEntity, ContactEntity, LeadEntity, LeadOpptyContactRelationEntity, OpportunityEntity } from '@ppl/graphql-space-api';
import { EntityNameEnum, EntityPermsEnum } from '@ppl/graphql-space-api';
import { hasSpaceEntityPermission } from '@ppl/space';
import { MemoizeLast } from '@ppl/utils';
import { LocationService } from '../../../../../services/location.service';
import { CoreStore } from '../../../../../store/core.store';
import { TypeToRelationOption } from '../../domain/entity-card';

@Component({
  selector: 'ppl-contacts-cards',
  templateUrl: './contacts-cards.component.html',
  styleUrls: ['./contacts-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsCardsComponent {

  @Input() entities: (ContactEntity | LeadOpptyContactRelationEntity)[];
  @Input() parentEntity: AccountEntity | LeadEntity | OpportunityEntity;

  EntityNameEnum = EntityNameEnum;

  @MemoizeLast<ContactsCardsComponent>(['parentEntity'])
  get displayCreate() {
    return this.store.get(hasSpaceEntityPermission(EntityNameEnum.Contact, EntityPermsEnum.Create)) && (
      this.parentEntity.__typename === 'AccountEntity' || this.parentEntity.instancePermissions.includes(EntityPermsEnum.Write)
    );
  }

  constructor(
    private locationService: LocationService,
    private store: CoreStore
  ) {}

  onCreateClick() {
    this.locationService.createContact({
      [TypeToRelationOption[this.parentEntity.__typename]]: this.parentEntity.id
    });
  }

  onCardClick(entity: ContactEntity) {
    this.locationService.openContact(entity.id);
  }

}
