import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LocationService } from '../../../../../../services/location.service';

@Component({
  selector: 'ppl-page-form-header',
  templateUrl: './page-form-header.component.html',
  styleUrls: ['./page-form-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageFormHeaderComponent {

  @Input() icon: string;
  @Input() label: string;

  @Output() closeClick = new EventEmitter();

  constructor(
    private locationService: LocationService
  ) {}

  onCloseClick() {
    if (this.closeClick.observers.length) {
      this.closeClick.emit();
    } else {
      this.locationService.reset();
    }
  }

}
