<ppl-page [flexContent]="true">
  <ng-template #headerTemplate>
    <ppl-page-form-header icon="entity-white-new-email"
                          label="Attach documents to email"
                          i18n-label="@@Attach_documents_to_email"></ppl-page-form-header>
  </ng-template>

  <ng-container *ngIf="availableEntityTypes.length">
    <div class="container"
         [formGroup]="formGroup">
      <ppl-input-container label="Select entity with required documents:"
                           i18n-label="@@Select_entity_with_required_documents:">
        <ppl-entity-list-type [entityTypes]="availableEntityTypes"
                              [value]="entityItem"
                              [optionTemplate]="entityOptionTemplate"
                              [optionTemplateRowHeight]="30"
                              (valueChange)="onEntityItemChange($event)"></ppl-entity-list-type>
      </ppl-input-container>

      <ppl-input-container label="Select documents to attach:"
                           i18n-label="@@Select_documents_to_attach:"
                           [pplFormControl]="formGroup.get('fileIds')">
        <ppl-attach-document-files [files]="cloudObjects.value || []"
                                   [message]="filesMessage"
                                   formControlName="fileIds"></ppl-attach-document-files>
      </ppl-input-container>
    </div>

    <ppl-page-actions saveLabel="Attach"
                      i18n-saveLabel="@@Attach"
                      (cancelClick)="onCancelClick()"
                      (saveClick)="onAttachClick()"></ppl-page-actions>
  </ng-container>

  <ppl-page-error *ngIf="!availableEntityTypes.length"
                  text="You do not have access to any entity"
                  i18n-text="@@You_do_not_have_access_to_any_entity"></ppl-page-error>
</ppl-page>

<ng-template #entityOptionTemplate
             let-option>
  <ng-container [ngSwitch]="option.data.entity.__typename">
    <ppl-entity-control-item *ngSwitchCase="'AccountEntity'"
                             [height]="30"
                             [name]="option.data.entity.name"></ppl-entity-control-item>

    <ppl-entity-control-item *ngSwitchCase="'ContactEntity'"
                             [height]="30"
                             [name]="option.data.entity | pplName"></ppl-entity-control-item>

    <ppl-entity-control-item *ngSwitchCase="'LeadEntity'"
                             [height]="30"
                             [name]="option.data.entity.name"></ppl-entity-control-item>

    <ppl-entity-control-item *ngSwitchCase="'OpportunityEntity'"
                             [height]="30"
                             [name]="option.data.entity.name"></ppl-entity-control-item>
  </ng-container>
</ng-template>