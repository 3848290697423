<div class="session">
  <img *ngIf="userProfile.pictureUrl"
       class="user-picture"
       [src]="userProfile.pictureUrl"
       alt="" />
  <div *ngIf="!userProfile.pictureUrl"
       class="user-picture placeholder">?</div>
  <div class="identity">
    <div class="user">{{userProfile | pplName}}</div>
    <div class="space">{{space.name}}</div>
  </div>
</div>

<div class="button"
     pplPopoverClose
     (click)="onSwitchSpaceClick()">
  <ppl-icon class="fabric-icon"
            svgIcon="switch-space-sm"
            width="14px"
            height="14px"></ppl-icon>
  <ppl-icon class="material-icon"
            svgIcon="swap-horiz-24px"
            width="24px"
            height="24px"></ppl-icon>
  <ng-container i18n="@@Switch_Space">Switch Space</ng-container>
</div>
<div class="button"
     pplPopoverClose
     (click)="onAboutClick()">
  <ppl-icon class="fabric-icon"
            svgIcon="about-info-blue-sm"
            width="14px"
            height="14px"></ppl-icon>
  <ppl-icon class="material-icon"
            svgIcon="info-circle-filled-24px"
            width="24px"
            height="24px"></ppl-icon>
  <ng-container i18n="@@About">About</ng-container>
</div>
<div class="button"
     pplPopoverClose
     (click)="onSignOutClick()">
  <ppl-icon class="fabric-icon"
            svgIcon="sign-out-blue-sm"
            width="14px"
            height="14px"></ppl-icon>
  <ppl-icon class="material-icon"
            svgIcon="power-settings-new-24px"
            width="24px"
            height="24px"></ppl-icon>
  <ng-container i18n="@@Sign_Out">Sign Out</ng-container>
</div>