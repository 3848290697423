import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { notFirstChange, Unsubscribe } from '@ppl/utils';
import type { Subscription } from 'rxjs';
import type { PplMenuItem } from '../menu-items';
import { PplPopoverDirective } from '../popover/popover.directive';
import { PplPopoverMenuComponent } from './popover-menu.component';

@Directive({
  selector: '[pplPopover][pplPopoverMenu]'
})
@Unsubscribe()
export class PplPopoverMenuDirective implements OnChanges, OnInit {

  @Input() pplPopoverMenu: PplMenuItem[];
  @Input() pplPopoverMenuValue: string;

  @Output() pplPopoverMenuItemClick = new EventEmitter<string>();

  closeRequestSubscription: Subscription;

  constructor(
    private popoverDirective: PplPopoverDirective
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (notFirstChange(changes.pplPopoverMenu) || notFirstChange(changes.pplPopoverMenuValue)) {
      if (this.pplPopoverMenu) {
        this.updatePopoverComponentData();
      }
    }
  }

  ngOnInit() {
    if (this.pplPopoverMenu) {
      this.popoverDirective.pplPopoverComponent = PplPopoverMenuComponent;

      this.closeRequestSubscription = this.popoverDirective.pplPopoverCloseRequest.subscribe(eventTarget => {
        // Do not close when clicking on menu items with children or custom children component
        if (!eventTarget.closest('*[ppl-menu-items-prevent-close=true]')) {
          // Use setTimeout to allow processing of click events from nested menus
          setTimeout(() => {
            this.popoverDirective.hide();
          });
        }
      });

      this.updatePopoverComponentData();
    }
  }

  updatePopoverComponentData() {
    this.popoverDirective.pplPopoverComponentData = {
      items: this.pplPopoverMenu,
      parentPopover: {
        close: () => {
          this.popoverDirective.getInstance().popoverControl.close();
        }
      },
      onItemClick: (id: string) => {
        this.pplPopoverMenuItemClick.emit(id);
      },
      value: this.pplPopoverMenuValue
    };
  }

}
