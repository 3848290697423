import { EntityFormControlKind } from '../../../entity-form/domain/controls';
import type { EntityFormApiFieldTypeAdapter } from '../entity-form-api';

export const EntityNameFieldTypeAdapter: EntityFormApiFieldTypeAdapter = {
  id: 'entity_name',
  getControl({ fieldDescriptor, formField }) {
    return {
      id: fieldDescriptor.id,
      label: formField?.label ?? fieldDescriptor.name,
      kind: EntityFormControlKind.Input
    };
  }
};
