import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { I18nService } from './i18n.service';

@Pipe({
  name: 'tEnum'
})
export class TEnumPipe implements PipeTransform {
  constructor(private i18nService: I18nService) { }

  transform(value: any, enumName: string): string {
    return this.i18nService.translateEnumValue(enumName, value);
  }
}
