import type { EntityAdapters } from '@ppl/components/entity-list';
import type { CurrencyEntity, EntityNameEnum } from '@ppl/graphql-space-api';
import type { PplMultipleSelectOption } from '@ppl/ui/multiple-select';
import type { PplSelectOption } from '@ppl/ui/select';
import type { Observable } from 'rxjs';
import type { EntityFormValidator } from './validators';

interface EntityFormBaseControl {
  data?: any;
  disabled?: boolean;
  id: string;
  label: string;
  validators?: EntityFormValidator[];
}

export interface EntityFormAccountClassControl extends EntityFormBaseControl {
  kind: EntityFormControlKind.AccountClass;
}

export interface EntityFormCheckboxControl extends EntityFormBaseControl {
  inlineLabel?: boolean;
  kind: EntityFormControlKind.Checkbox;
}

export interface EntityFormCurrencyControl extends EntityFormBaseControl {
  base?: boolean;
  currencies: CurrencyEntity[];
  kind: EntityFormControlKind.Currency;
}

export interface EntityFormDateControl extends EntityFormBaseControl {
  nullDisabled?: boolean;
  kind: EntityFormControlKind.Date;
}

export interface EntityFormDateTimeControl extends EntityFormBaseControl {
  nullDisabled?: boolean;
  kind: EntityFormControlKind.DateTime;
}

export interface EntityFormDropdownControl extends EntityFormBaseControl {
  options: PplSelectOption[] | Observable<PplSelectOption[]>;
  kind: EntityFormControlKind.Dropdown;
}

export interface EntityFormEntityControl extends EntityFormBaseControl {
  entityAdapters?: EntityAdapters;
  entityTypes: EntityNameEnum[];
  nullDisabled?: boolean;
  kind: EntityFormControlKind.Entity;
}

export interface EntityFormInputControl extends EntityFormBaseControl {
  kind: EntityFormControlKind.Input;
}

export interface EntityFormMultipleDropdownControl extends EntityFormBaseControl {
  options: PplMultipleSelectOption[] | Observable<PplMultipleSelectOption[]>;
  kind: EntityFormControlKind.MultipleDropdown;
}

export interface EntityFormMultipleEntityControl extends EntityFormBaseControl {
  entityAdapters?: EntityAdapters;
  entityTypes: EntityNameEnum[];
  maxValues?: number;
  kind: EntityFormControlKind.MultipleEntity;
}

export interface EntityFormNumberControl extends EntityFormBaseControl {
  step: number;
  kind: EntityFormControlKind.Number;
}

export interface EntityFormRankingControl extends EntityFormBaseControl {
  kind: EntityFormControlKind.Ranking;
}

export interface EntityFormTextareaControl extends EntityFormBaseControl {
  kind: EntityFormControlKind.Textarea;
}

export enum EntityFormControlKind {
  AccountClass = 'AccountClass',
  Checkbox = 'Checkbox',
  Currency = 'Currency',
  Date = 'Date',
  DateTime = 'DateTime',
  Dropdown = 'Dropdown',
  Entity = 'Entity',
  Input = 'Input',
  MultipleDropdown = 'MultipleDropdown',
  MultipleEntity = 'MultipleEntity',
  Number = 'Number',
  Ranking = 'Ranking',
  Textarea = 'Textarea'
}

export type EntityFormControl =
  EntityFormAccountClassControl
  | EntityFormCheckboxControl
  | EntityFormCurrencyControl
  | EntityFormDateControl
  | EntityFormDateTimeControl
  | EntityFormDropdownControl
  | EntityFormEntityControl
  | EntityFormInputControl
  | EntityFormMultipleDropdownControl
  | EntityFormMultipleEntityControl
  | EntityFormNumberControl
  | EntityFormRankingControl
  | EntityFormTextareaControl;
