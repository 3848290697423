import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { AccountEntity, ContactEntity, OpportunityEntity} from '@ppl/graphql-space-api';
import { EntityNameEnum, EntityPermsEnum, OpptyStatusEnum } from '@ppl/graphql-space-api';
import { getSpaceBaseCurrency, hasSpaceEntityPermission } from '@ppl/space';
import { MemoizeLast } from '@ppl/utils';
import { LocationService } from '../../../../../services/location.service';
import { CoreStore } from '../../../../../store/core.store';
import { TypeToRelationOption } from '../../domain/entity-card';

@Component({
  selector: 'ppl-opportunities-cards',
  templateUrl: './opportunities-cards.component.html',
  styleUrls: ['./opportunities-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunitiesCardsComponent {

  @Input() entities: OpportunityEntity[];
  @Input() parentEntity: AccountEntity | ContactEntity;

  filterValue: OpptyStatusEnum | null = null;

  baseCurrencyCode = this.store.get(getSpaceBaseCurrency).code;

  OpptyStatusEnum = OpptyStatusEnum;

  @MemoizeLast<OpportunitiesCardsComponent>(['entities'])
  get openEntities() {
    return this.entities.filter(entity => entity.status === OpptyStatusEnum.Open);
  }

  @MemoizeLast<OpportunitiesCardsComponent>(['openEntities'])
  get openEntitiesValue() {
    return this.openEntities.reduce((value, entity) => value + entity.value.baseValue, 0);
  }

  @MemoizeLast<OpportunitiesCardsComponent>(['entities'])
  get wonEntities() {
    return this.entities.filter(entity => entity.status === OpptyStatusEnum.Won);
  }

  @MemoizeLast<OpportunitiesCardsComponent>(['wonEntities'])
  get wonEntitiesValue() {
    return this.wonEntities.reduce((value, entity) => value + entity.value.baseValue, 0);
  }

  @MemoizeLast<OpportunitiesCardsComponent>(['entities'])
  get lostEntities() {
    return this.entities.filter(entity => entity.status === OpptyStatusEnum.Lost);
  }

  @MemoizeLast<OpportunitiesCardsComponent>(['lostEntities'])
  get lostEntitiesValue() {
    return this.lostEntities.reduce((value, entity) => value + entity.value.baseValue, 0);
  }

  @MemoizeLast<OpportunitiesCardsComponent>(['entities', 'filterValue'])
  get filteredEntities() {
    if (this.filterValue === OpptyStatusEnum.Open) {
      return this.openEntities;
    } else if (this.filterValue === OpptyStatusEnum.Won) {
      return this.wonEntities;
    } else if (this.filterValue === OpptyStatusEnum.Lost) {
      return this.lostEntities;
    }

    return this.entities;
  }

  @MemoizeLast<OpportunitiesCardsComponent>(['parentEntity'])
  get displayCreate() {
    return this.store.get(hasSpaceEntityPermission(EntityNameEnum.Opportunity, EntityPermsEnum.Create));
  }

  constructor(
    private locationService: LocationService,
    private store: CoreStore
  ) {}

  onCreateClick() {
    this.locationService.createOpportunity({
      [TypeToRelationOption[this.parentEntity.__typename]]: this.parentEntity.id
    });
  }

  onFilterValueChange(value: OpptyStatusEnum) {
    this.filterValue = value;
  }

  onCardClick(entity: OpportunityEntity) {
    this.locationService.openOpportunity(entity.id);
  }

}
