import { Injectable } from '@angular/core';
import type { ParsedBody } from '../domain/body-parser';

@Injectable()
export class BodyParserService {

  // Parse message body to extract phone, homepage and social links
  // If the message contains more than one match, it uses the last one,
  // because it has higher probability of being a part of a signature.
  parse(htmlBody: string) {
    const result: ParsedBody = {
      facebookUrl: null,
      homePage: null,
      linkedInUrl: null,
      phone: null,
      twitterUrl: null
    };

    htmlBody.replace(UsPhoneRe, phone => {
      result.phone = phone;

      return '';
    });

    htmlBody.replace(FacebookRe, facebookUrl => {
      result.facebookUrl = facebookUrl;

      return '';
    });

    htmlBody.replace(LinkedInRe, linkedInUrl => {
      result.linkedInUrl = linkedInUrl;

      return '';
    });

    htmlBody.replace(TwitterRe, twitterUrl => {
      result.twitterUrl = twitterUrl;

      return '';
    });

    htmlBody.replace(UrlRe, homePage => {
      // Should not match social accounts
      if (SocialHosts.every(socialHost => !homePage.includes(socialHost))) {
        result.homePage = homePage;
      }

      return '';
    });

    return result;
  }

}

const SocialHosts = ['linkedin.com', 'twitter.com', 'facebook.com', 'fb.me', 'plus.google.com'];

const FacebookRe = /http(?:s)?:\/\/(?:www\.)?facebook\.com\/(?:[a-zA-Z0-9\-_\.]+)/g; // modified Twitter regex
const LinkedInRe = /http(?:s)?:\/\/(?:www\.)?linkedin\.com\/in\/(?:[a-zA-Z0-9\-_]+)/g; // modified Twitter regex
const TwitterRe = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/(?:[a-zA-Z0-9_]+)/g; // http://stackoverflow.com/a/6024856/1716747
const UrlRe = /(?:(?:https?):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gi; // http://stackoverflow.com/a/29288898/1716747
const UsPhoneRe = /(?:\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}/g; // http://stackoverflow.com/a/16699507/1716747
