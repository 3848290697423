<ppl-dialog title="Set due date"
            i18n-title="@@Set_due_date"
            actionLabel="Set"
            i18n-actionLabel="@@Set"
            [formGroup]="formGroup"
            [formGroupNoPadding]="true"
            width="80vh"
            (action)="onSave()">
  <ppl-input-container label="Due Date"
                       i18n-label="@@Due_Date"
                       [pplFormControl]="formGroup.get('dueDate')">
    <ppl-date-input [displayRemove]="false"
                    formControlName="dueDate"></ppl-date-input>
  </ppl-input-container>
</ppl-dialog>