import { Injectable } from '@angular/core';
import type { AccountEntity, AccountFilterInput, ClientEntity, ClientFilterInput, ContactEntity, ContactFilterInput, Query } from '@ppl/graphql-space-api';
import { SpaceService } from '@ppl/space';
import { AsyncState } from '@ppl/store';
import { unsubscribe } from '@ppl/utils';
import type { Subscription} from 'rxjs';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CacheService } from '../../../services/cache.service';
import { CoreStore } from '../../../store/core.store';
import type { OutlookPerson } from '../../shared/outlook/domain/outlook';
import { OutlookItemMode, OutlookItemType } from '../../shared/outlook/domain/outlook';
import { OutlookService } from '../../shared/outlook/services/outlook.service';
import { TrackingService } from '../../shared/tracking/services/tracking.service';
import type { DashboardItem} from '../domain/dashboard';
import { DashboardItemKind } from '../domain/dashboard';
import { getDashboardDataRevision, shouldDashboardFetchData } from '../store/dashboard.selectors';
import { gqlFetchDashboardData } from './dashboard.graphql';

@Injectable()
export class DashboardService {

  fetchDataSubscription: Subscription;

  constructor(
    private cacheService: CacheService,
    private outlookService: OutlookService,
    private spaceService: SpaceService,
    private store: CoreStore,
    private trackingService: TrackingService
  ) {}

  fetchData() {
    const dataRevision = this.cacheService.dataRevision;

    if (!this.store.get(shouldDashboardFetchData) && dataRevision === this.store.get(getDashboardDataRevision)) {
      return;
    }

    unsubscribe(this.fetchDataSubscription);

    if (!this.outlookService.isItemAvailable()) {
      // When add-in is pinned and user clicks on an email that does not have add-in available
      // (like "Undeliverable" notifications), do not load dashboard data because "mailbox.item" is null
      this.store.dispatch('Dashboard_FetchData', {
        state: AsyncState.ERROR,
        dataRevision
      });

      return;
    }

    this.store.dispatch('Dashboard_FetchData', {
      state: AsyncState.FETCHING,
      dataRevision
    });

    const itemMode = this.outlookService.getItemMode();
    const itemType = this.outlookService.getItemType();

    this.fetchDataSubscription = combineLatest([
      this.outlookService.getSender(),
      this.outlookService.getToRecipients(),
      this.outlookService.getCcRecipients(),
      this.outlookService.isSenderUser().pipe(
        switchMap(isSenderUser => {
          return (itemMode === OutlookItemMode.Read && isSenderUser)
            ? this.trackingService.getTrackingId()
            : of(null);
        })
      )
    ]).pipe(
      switchMap(([ sender, toRecipients, ccRecipients, trackingId ]) => {
        const recipients = this.removeDuplicates([...toRecipients, ...ccRecipients]);
        const user = this.outlookService.getUser();

        const personEmails = [sender, ...recipients].map(person => person.email);

        const accountContactFilter: AccountFilterInput & ContactFilterInput = {
          OR: [
            { email1: { icontains: personEmails } },
            { email2: { icontains: personEmails } },
            { email3: { icontains: personEmails } },
            { email4: { icontains: personEmails } },
            { email5: { icontains: personEmails } }
          ]
        };

        const clientFilter: ClientFilterInput = {
          email: { icontains: personEmails }
        };

        return combineLatest([
          this.spaceService.gqlClient.query<Query>({
            query: gqlFetchDashboardData,
            fetchPolicy: 'no-cache',
            variables: {
              accountFilter: accountContactFilter,
              contactFilter: accountContactFilter,
              clientFilter
            }
          }),
          trackingId
            ? this.trackingService.getVisits(trackingId)
            : of(null)
        ]).pipe(
          catchError(error => {
            this.store.dispatch('Dashboard_FetchData', {
              state: AsyncState.ERROR,
              dataRevision
            });

            return throwError(error);
          }),
          tap(([ { data }, visits ]) => {
            const entities = {
              account: data.entities.account.getAll,
              contact: data.entities.contact.getAll,
              client: data.entities.client.getAll
            };

            this.store.dispatch('Dashboard_FetchData', {
              state: AsyncState.FETCHED,
              value: {
                sender: this.createItem(entities, sender, user),
                recipients: recipients.map(recipient => this.createItem(entities, recipient, user)).filter(recipient => {
                  if (itemMode === OutlookItemMode.Compose && itemType === OutlookItemType.Appointment) {
                    return recipient.kind !== DashboardItemKind.User;
                  }

                  return true;
                }),
                visits
              },
              dataRevision
            });
          })
        );
      })
    ).subscribe();
  }

  private createItem(entities: { account: AccountEntity[], contact: ContactEntity[], client: ClientEntity[] }, person: OutlookPerson, user: OutlookPerson): DashboardItem {
    // 1. Contacts
    for (const contact of entities.contact) {
      if ([contact.email1, contact.email2, contact.email3, contact.email4, contact.email5].some(email => email?.toLowerCase() === person.email.toLowerCase())) {
        return {
          person,
          entity: contact,
          kind: DashboardItemKind.Contact
        };
      }
    }

    // 2. Accounts
    for (const account of entities.account) {
      if ([account.email1, account.email2, account.email3, account.email4, account.email5].some(email => email?.toLowerCase() === person.email.toLowerCase())) {
        return {
          person,
          entity: account,
          kind: DashboardItemKind.Account
        };
      }
    }

    // 3. User
    // Check if user.email is defined - sometimes Outlook.js returns undefined
    if (user.email?.toLowerCase() === person.email.toLowerCase()) {
      return {
        person,
        kind: DashboardItemKind.User
      };
    }

    // 4. Client
    for (const client of entities.client) {
      if (client.email.toLowerCase() === person.email.toLowerCase()) {
        return {
          person,
          entity: client,
          kind: DashboardItemKind.Client
        };
      }
    }

    // 5. Other
    return {
      person,
      kind: DashboardItemKind.Other
    };
  }

  private removeDuplicates(persons: OutlookPerson[]) {
    const result: OutlookPerson[] = [];

    persons.forEach(person => {
      if (!result.find(resultPerson => resultPerson.email.toLowerCase() === person.email.toLowerCase())) {
        result.push(person);
      }
    });

    return result;
  }

}
