import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplIconModule } from '../icon';
import { PplInputModule } from '../input';
import { PplToggleGroupModule } from '../toggle-group';
import { PplInputNumberClampComponent } from './input-number-clamp/input-number-clamp.component';
import { PplTimeInputComponent } from './time-input.component';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    PplInputModule,
    PplToggleGroupModule
  ],
  declarations: [
    PplTimeInputComponent,
    PplInputNumberClampComponent
  ],
  exports: [
    PplTimeInputComponent,
    PplInputNumberClampComponent
  ],
  providers: []
})
export class PplTimeInputModule {}

export * from './time-input.component';

