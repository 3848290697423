<div class="header">
  <ng-container *ngIf="!globalSearchVisible">
    <ng-template *ngTemplateOutlet="headerTemplate; context: { globalSearch: globalSearch }"></ng-template>
  </ng-container>

  <ppl-global-search *ngIf="globalSearchVisible"
                     (listClose)="onGlobalSearchListClose()"></ppl-global-search>
</div>

<div class="content"
     [class.flex]="flexContent">
  <ng-content></ng-content>
</div>

<div class="footer">
  <ppl-icon svgIcon="logo-color"
            width="100px"
            height="23px"></ppl-icon>
</div>

<ng-template #globalSearch>
  <ppl-page-header-button class="button-search"
                          icon="toolbar-white-search"
                          (click)="onGlobalSearchClick()"></ppl-page-header-button>
</ng-template>