import { Injectable } from '@angular/core';
import type { Query} from '@ppl/graphql-space-api';
import { SharingRoleEnum } from '@ppl/graphql-space-api';
import { SpaceService } from '@ppl/space';
import { map } from 'rxjs/operators';
import type { TaskData } from '../domain/task';
import { gqlFetchTaskData } from './task.graphql';

@Injectable()
export class TaskService {

  constructor(
    private spaceService: SpaceService
  ) {}

  fetchData(id: string) {
    return this.spaceService.gqlClient.query<Query>({
      query: gqlFetchTaskData,
      fetchPolicy: 'no-cache',
      variables: {
        entityId: id
      }
    }).pipe(map(({ data }) => {
      const entityData = data.screens.extractViews.task;

      if (!entityData) {
        return null;
      }

      const result: TaskData = {
        entity: entityData.entity,
        watchers: entityData.entity.sharingClients.edges.filter(edge => edge.node.role === SharingRoleEnum.Watcher).map(edge => edge.node.client),
        relatedAccounts: entityData.entity.accountRelations.edges.map(edge => edge.node),
        relatedContacts: entityData.entity.contactRelations.edges.map(edge => edge.node),
        relatedLeads: entityData.entity.leadRelations.edges.map(edge => edge.node),
        relatedOpportunities: entityData.entity.opportunityRelations.edges.map(edge => edge.node)
      };

      return result;
    }));
  }

}
