import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ppl-page-actions',
  templateUrl: './page-actions.component.html',
  styleUrls: ['./page-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageActionsComponent {

  @Input() saveLabel?: string;

  @Output() cancelClick = new EventEmitter();
  @Output() saveClick = new EventEmitter();

  onCancelClick() {
    this.cancelClick.emit();
  }

  onSaveClick() {
    this.saveClick.emit();
  }

}
