import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ppl-card-message',
  templateUrl: './card-message.component.html',
  styleUrls: ['./card-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardMessageComponent {

}
