import { Injectable } from '@angular/core';
import { ActionsUtilsService } from '@ppl/core';
import type { PersonalizeBodyEmailTemplatePayload, Query } from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { SpaceService } from '@ppl/space';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CoreStore } from '../../../store/core.store';
import type { DashboardAccountItem, DashboardContactItem} from '../../dashboard/domain/dashboard';
import { DashboardItemKind } from '../../dashboard/domain/dashboard';
import { getDashboardData } from '../../dashboard/store/dashboard.selectors';
import { OutlookService } from '../../shared/outlook/services/outlook.service';
import { ToastService } from '../../shared/toast/services/toast.service';
import { gqlFetchEmailTemplateContent, gqlFetchEmailTemplates, gqlPersonalizeBodyEmailTemplate } from './use-template.graphql';

@Injectable()
export class UseTemplateService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private i18nService: I18nService,
    private outlookService: OutlookService,
    private spaceService: SpaceService,
    private store: CoreStore,
    private toastService: ToastService
  ) {}

  fetchData() {
    return this.spaceService.gqlClient.query<Query>({
      query: gqlFetchEmailTemplates,
      fetchPolicy: 'no-cache'
    }).pipe(
      map(({ data }) => {
        return {
          emailTemplates: data.entities.emailTemplate.getAll,
          emailTemplateFolders: data.entities.emailTemplateFolder.getAll
        };
      })
    );
  }

  useTemplate(id: string) {
    const dashboardData = this.store.get(getDashboardData);

    let personalizationEntityId: string | null = null;
    let personalizationEntityType: EntityNameEnum | null = null;

    if (dashboardData.value) {
      const firstAccountRecipient = dashboardData.value.recipients.find(recipient => recipient.kind === DashboardItemKind.Account) as DashboardAccountItem;
      const firstContactRecipient = dashboardData.value.recipients.find(recipient => recipient.kind === DashboardItemKind.Contact) as DashboardContactItem;

      if (firstContactRecipient) {
        personalizationEntityId = firstContactRecipient.entity.id;
        personalizationEntityType = EntityNameEnum.Contact;
      } else if (firstAccountRecipient) {
        personalizationEntityId = firstAccountRecipient.entity.id;
        personalizationEntityType = EntityNameEnum.Account;
      }
    }

    return this.actionsUtilsService.doAction(this.spaceService.gqlClient.query<Query>({
      query: gqlFetchEmailTemplateContent,
      fetchPolicy: 'no-cache',
      variables: {
        entityId: id
      }
    }).pipe(
      switchMap(({ data }) => {
        const emailTemplate = data.entities.emailTemplate.getById;

        return this.spaceService.gqlClient.mutate<{ personalizeBodyEmailTemplate: PersonalizeBodyEmailTemplatePayload }>({
          mutation: gqlPersonalizeBodyEmailTemplate,
          variables: {
            input: {
              entityId: personalizationEntityId,
              entityType: personalizationEntityType,
              id
            }
          }
        }).pipe(
          switchMap(({ data: bodyData }) => {
            return this.outlookService.prependHtmlBody(bodyData.personalizeBodyEmailTemplate.result).pipe(
              switchMap(() => {
                return this.outlookService.removeAllAttachments();
              }),
              switchMap(() => {
                const cloudObjects = emailTemplate.documents.edges.map(edge => edge.node.cloudObject).filter(cloudObject => cloudObject);

                if (!cloudObjects.length) {
                  return of([]);
                }

                return combineLatest(cloudObjects.map(cloudObject => {
                  return this.outlookService.attachFile({
                    fileName: cloudObject.filename,
                    url: cloudObject.url
                  });
                }));
              })
            );
          }),
          tap(() => {
            this.toastService.open({
              text: this.i18nService.translate('Email_template_was_set')
            });
          })
        );
      })
    ));
  }

}
