import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { ClientEntity } from '@ppl/graphql-space-api';

@Component({
  selector: 'ppl-watchers-cards',
  templateUrl: './watchers-cards.component.html',
  styleUrls: ['./watchers-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WatchersCardsComponent {

  @Input() entities: ClientEntity[];

}
