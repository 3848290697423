import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { PPL_DIALOG_DATA } from '@ppl/ui/dialog';

@Component({
  selector: 'ppl-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingDialogComponent {

  constructor(
    @Inject(PPL_DIALOG_DATA) public data: { hideLoading?: boolean }
  ) {}

}
