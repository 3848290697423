import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@ppl/core';
import { I18nModule } from '@ppl/i18n';
import { CardModule } from '../shared/card/card.module';
import { EntityCardModule } from '../shared/entity-card/entity-card.module';
import { EntityFormModule } from '../shared/entity-form/entity-form.module';
import { EntityModule } from '../shared/entity/entity.module';
import { PageModule } from '../shared/page/page.module';
import { OpportunityFormComponent } from './components/opportunity-form/opportunity-form.component';
import { OpportunityComponent } from './components/opportunity/opportunity.component';
import { OpportunityFormService } from './services/opportunity-form.service';
import { OpportunityService } from './services/opportunity.service';

@NgModule({
  imports: [
    CardModule,
    CommonModule,
    CoreModule,
    EntityModule,
    EntityCardModule,
    EntityFormModule,
    I18nModule,
    PageModule
  ],
  declarations: [
    OpportunityComponent,
    OpportunityFormComponent
  ],
  exports: [
    OpportunityComponent,
    OpportunityFormComponent
  ],
  providers: [
    OpportunityService,
    OpportunityFormService
  ]
})
export class OpportunityModule {}
