import { NgZone , ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AuthService } from '@ppl/auth';
import { ActionsUtilsService } from '@ppl/core';
import { Space, UserProfileEntity } from '@ppl/graphql-user-api';
import { PplDialogService } from '@ppl/ui/dialog';
import { Observable, of } from 'rxjs';
import { UserProfileService } from '../../services/user-profile.service';
import type { AboutData } from '../about/about.component';
import { AboutComponent } from '../about/about.component';

@Component({
  selector: 'ppl-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent {

  @Input() space: Space;
  @Input() switchSpaceGuard$?: Observable<boolean>;
  @Input() version: string;
  @Input() userProfile: UserProfileEntity;

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private authService: AuthService,
    private dialogService: PplDialogService,
    private ngZone: NgZone,
    private userProfileService: UserProfileService
  ) {}

  onSwitchSpaceClick() {
    (this.switchSpaceGuard$ || of(true)).subscribe(result => {
      if (result) {
        const overlayRef = this.actionsUtilsService.openOverlay();

        this.authService.signIn({
          queryForSpace: true
        }).subscribe(success => {
          this.ngZone.run(() => {
            if (success) {
              this.userProfileService.authSuccess$.next();
            } else {
              overlayRef.close();
            }
          });
        });
      }
    });
  }

  onAboutClick() {
    this.dialogService.open<AboutComponent, AboutData>(AboutComponent, {
      data: {
        version: this.version
      }
    });
  }

  onSignOutClick() {
    const overlayRef = this.actionsUtilsService.openOverlay();

    this.authService.signOut().subscribe(result => {
      this.ngZone.run(() => {
        if (result) {
          this.userProfileService.authSuccess$.next();
        } else {
          overlayRef.close();
        }
      });
    });
  }

}
