import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { AccountEntity, AppointmentEntity, ContactEntity, LeadEntity, OpportunityEntity, TaskEntity } from '@ppl/graphql-space-api';
import { EntityNameEnum, EntityPermsEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import type { PplMenuItem } from '@ppl/ui/menu-items';
import { hasSpaceEntityPermission } from '@ppl/space';
import { MemoizeLast } from '@ppl/utils';
import { LocationService } from '../../../../../services/location.service';
import { CoreStore } from '../../../../../store/core.store';
import { TypeToRelationOption } from '../../domain/entity-card';

@Component({
  selector: 'ppl-activities-cards',
  templateUrl: './activities-cards.component.html',
  styleUrls: ['./activities-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivitiesCardsComponent {

  @Input() entities: (AppointmentEntity | TaskEntity)[];
  @Input() parentEntity: AccountEntity | ContactEntity | LeadEntity | OpportunityEntity;

  createMenuItems: PplMenuItem[] = [{
    disabled: !this.store.get(hasSpaceEntityPermission(EntityNameEnum.Task, EntityPermsEnum.Create)),
    icon: 'menu-task',
    id: EntityNameEnum.Task,
    label: this.i18nService.translate('Add_Task')
  }, {
    disabled: !this.store.get(hasSpaceEntityPermission(EntityNameEnum.Appointment, EntityPermsEnum.Create)),
    icon: 'menu-appointment',
    id: EntityNameEnum.Appointment,
    label: this.i18nService.translate('Add_Appointment')
  }];

  @MemoizeLast<ActivitiesCardsComponent>(['parentEntity'])
  get displayCreate() {
    return this.store.get(hasSpaceEntityPermission(EntityNameEnum.Appointment, EntityPermsEnum.Create))
      || this.store.get(hasSpaceEntityPermission(EntityNameEnum.Task, EntityPermsEnum.Create));
  }

  constructor(
    private i18nService: I18nService,
    private locationService: LocationService,
    private store: CoreStore
  ) {}

  onCreateMenuItemClick(entityType: EntityNameEnum) {
    if (entityType === EntityNameEnum.Appointment) {
      this.locationService.createAppointment({
        [TypeToRelationOption[this.parentEntity.__typename]]: this.parentEntity.id
      });
    } else if (entityType === EntityNameEnum.Task) {
      this.locationService.createTask({
        [TypeToRelationOption[this.parentEntity.__typename]]: this.parentEntity.id
      });
    }
  }

  onAppointmentCardClick(entity: AppointmentEntity) {
    this.locationService.openAppointment(entity.id);
  }

  onTaskCardClick(entity: TaskEntity) {
    this.locationService.openTask(entity.id);
  }

}
