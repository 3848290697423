<div class="header">
  {{entity.step.pipeline.name}} › <span class="header-step">{{stepIndex + 1}}. {{entity.step.name}}</span>
</div>

<div class="steps">
  <div *ngFor="let step of entity.step.pipeline.steps; trackBy: trackById"
       class="step"
       [class.current]="step.id === entity.step.id"
       [class.elapsed]="step.sortOrder < entity.step.sortOrder">
  </div>
</div>