import { Injectable } from '@angular/core';
import { PplNamePipe } from '@ppl/auth';
import { ActionsUtilsService } from '@ppl/core';
import { FirebaseEventsEnum, FirebaseService } from '@ppl/firebase';
import type { Query } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { SpaceService } from '@ppl/space';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CacheService } from '../../../services/cache.service';
import { OutlookItemType } from '../../shared/outlook/domain/outlook';
import { OutlookService } from '../../shared/outlook/services/outlook.service';
import { ToastService } from '../../shared/toast/services/toast.service';
import type { AddRecipientEntityData} from '../domain/add-recipient';
import { AddRecipientKind, getAddRecipientEntityData } from '../domain/add-recipient';
import { gqlFetchContactRecipients, gqlFetchSalesTeamRecipients } from './add-recipient.graphql';

@Injectable()
export class AddRecipientService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private cacheService: CacheService,
    private firebaseService: FirebaseService,
    private i18nService: I18nService,
    private namePipe: PplNamePipe,
    private outlookService: OutlookService,
    private spaceService: SpaceService,
    private toastService: ToastService
  ) {}

  addRecipients(options: AddRecipientsOptions) {
    const itemType = this.outlookService.getItemType();

    return this.actionsUtilsService.doAction(of(null).pipe(
      switchMap(() => {
        return this.spaceService.gqlClient.query<Query>({
          query: ({
            [AddRecipientKind.Contact]: gqlFetchContactRecipients,
            [AddRecipientKind.SalesTeam]: gqlFetchSalesTeamRecipients
          })[options.kind],
          variables: {
            entityIds: options.entityIds
          }
        });
      }),
      switchMap(({ data }) => {
        const entities = [
          ...data.entities.account.getByIds,
          ...data.entities.contact.getByIds,
          ...(data.entities.lead?.getByIds ? data.entities.lead.getByIds : []),
          ...(data.entities.opportunity?.getByIds ? data.entities.opportunity.getByIds : [])
        ];

        const recipients: AddRecipientEntityData[] = [];

        entities.forEach(entity => {
          recipients.push(...getAddRecipientEntityData(entity, options.kind, this.namePipe));
        });

        return this.outlookService.addRecipients({
          recipients
        });
      }),
      tap(() => {
        this.cacheService.reset();

        this.firebaseService.logEvent({
          [AddRecipientKind.Contact]: FirebaseEventsEnum.AddContactsRecipients,
          [AddRecipientKind.SalesTeam]: FirebaseEventsEnum.AddSalesTeamRecipients
        }[options.kind]);

        this.toastService.open({
          text: ({
            [OutlookItemType.Message]: this.i18nService.translate('Contacts_were_added_to_email'),
            [OutlookItemType.Appointment]: this.i18nService.translate('Contacts_were_added_to_appointment')
          })[itemType]
        });
      })
    ));
  }

}

export interface AddRecipientsOptions {
  entityIds: string[];
  kind: AddRecipientKind;
}
