import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EntityNameEnum, TaskEntity } from '@ppl/graphql-space-api';
import { isOverdue, MemoizeLast } from '@ppl/utils';
import { AvatarKind } from '../../../avatar/domain/avatar';

@Component({
  selector: 'ppl-task-card-medium',
  templateUrl: './task-card-medium.component.html',
  styleUrls: ['./task-card-medium.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskCardMediumComponent {

  @Input() entity: TaskEntity;

  AvatarKind = AvatarKind;
  EntityNameEnum = EntityNameEnum;

  @MemoizeLast<TaskCardMediumComponent>(['entity'])
  get isOverdue() {
    return isOverdue(this.entity.dueDate);
  }

}
