import { Injectable } from '@angular/core';
import { ActionsUtilsService } from '@ppl/core';
import { FirebaseEventsEnum, FirebaseService } from '@ppl/firebase';
import type { TaskEntity, TaskStatusEnum, UpdateTaskInput} from '@ppl/graphql-space-api';
import { ValidationLevel } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { SpaceService } from '@ppl/space';
import { PplDialogService } from '@ppl/ui/dialog';
import { of } from 'rxjs';
import { mapTo, switchMap, takeWhile, tap } from 'rxjs/operators';
import { ToastService } from '../../toast/services/toast.service';
import type { MoveTaskData } from '../components/move-task/move-task.component';
import { MoveTaskComponent } from '../components/move-task/move-task.component';
import type { SetDueDateData } from '../components/set-due-date/set-due-date.component';
import { SetDueDateComponent } from '../components/set-due-date/set-due-date.component';
import { gqlUpdateTaskMutation } from './activity.graphql';

@Injectable()
export class ActivityService {

  constructor(
    private actionsUtilsService: ActionsUtilsService,
    private dialogService: PplDialogService,
    private firebaseService: FirebaseService,
    private i18nService: I18nService,
    private spaceService: SpaceService,
    private toastService: ToastService
  ) {}

  moveTask(options: MoveTaskOptions) {
    return this.actionsUtilsService.doAction(of(null).pipe(
      switchMap(() => {
        return this.dialogService.open<MoveTaskComponent, MoveTaskData, TaskStatusEnum>(MoveTaskComponent, {
          data: {
            entity: options.entity
          }
        }).afterClosed().pipe(takeWhile(status => !!status));
      }),
      switchMap(status => {
        const input: UpdateTaskInput = {
          id: options.entity.id,
          status
        };

        return this.spaceService.gqlClient.mutate({
          mutation: gqlUpdateTaskMutation,
          variables: {
            input,
            validationLevel: [ValidationLevel.SKIP_ALL]
          }
        }).pipe(mapTo(status));
      }),
      tap(() => {
        this.toastService.open({
          text: this.i18nService.translate('Task_was_updated')
        });

        this.firebaseService.logEvent(FirebaseEventsEnum.Update, {
          entity: 'task'
        });
      })
    ));
  }

  setTaskDueDate(options: SetTaskDueDateOptions) {
    return this.actionsUtilsService.doAction(of(null).pipe(
      switchMap(() => {
        return this.dialogService.open<SetDueDateComponent, SetDueDateData, string>(SetDueDateComponent, {
          data: {
            entity: options.entity
          }
        }).afterClosed().pipe(takeWhile(dueDate => !!dueDate));
      }),
      switchMap(dueDate => {
        const input: UpdateTaskInput = {
          id: options.entity.id,
          dueDate
        };

        return this.spaceService.gqlClient.mutate({
          mutation: gqlUpdateTaskMutation,
          variables: {
            input,
            validationLevel: [ValidationLevel.SKIP_ALL]
          }
        }).pipe(mapTo(dueDate));
      }),
      tap(() => {
        this.toastService.open({
          text: this.i18nService.translate('Task_was_updated')
        });

        this.firebaseService.logEvent(FirebaseEventsEnum.Update, {
          entity: 'task'
        });
      })
    ));
  }

}

export interface MoveTaskOptions {
  entity: TaskEntity;
}

export interface SetTaskDueDateOptions {
  entity: TaskEntity;
}
