import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AvatarKind } from '../../../avatar/domain/avatar';
import { OutlookPerson } from '../../../outlook/domain/outlook';

@Component({
  selector: 'ppl-other-card-large',
  templateUrl: './other-card-large.component.html',
  styleUrls: ['./other-card-large.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OtherCardLargeComponent {

  @Input() person: OutlookPerson;

  AvatarKind = AvatarKind;

}
