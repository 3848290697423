import { gql } from '@ppl/utils';

export const gqlFetchEmails = gql`
  query fetchEmails($filter: EmailFilterInput) {
    entities {
      email {
        getAll(filter: $filter) {
          id
          accountRelations {
            edges {
              node {
                accountId
                id
              }
            }
          }
          contactRelations {
            edges {
              node {
                contactId
                id
              }
            }
          }
          leadRelations {
            edges {
              node {
                leadOpptyId
                id
              }
            }
          }
          opportunityRelations {
            edges {
              node {
                leadOpptyId
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const gqlCreateEmailMutation = gql`
  mutation createEmail($input: CreateEmailInput!) {
    createEmail(input: $input) {
      email {
        id
      }
    }
  }
`;

export const gqlCreateCloudObjectRelationMutation = gql`
  mutation createCloudObjectRelation($input: CreateCloudObjectRelationInput!) {
    createCloudObjectRelation(input: $input) {
      clientMutationId
    }
  }
`;
