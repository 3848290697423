import type { PplSelectOption } from '@ppl/ui/select';
import { map } from 'rxjs/operators';
import { EntityFormControlKind } from '../../../entity-form/domain/controls';
import type { EntityFormApiFieldTypeAdapter } from '../entity-form-api';

export const RadioFieldTypeAdapter: EntityFormApiFieldTypeAdapter = {
  id: 'radio',
  getControl({ entityType, fetchValueOptions, fieldDescriptor, formField }) {
    return {
      id: fieldDescriptor.id,
      label: formField?.label ?? fieldDescriptor.name,
      options: fetchValueOptions({
        entityType,
        fieldDescriptor
      }).pipe(
        map(options => {
          return options.map<PplSelectOption>(option => ({
            deleted: option.isDeleted,
            label: option.label,
            value: option.value
          }));
        })
      ),
      kind: EntityFormControlKind.Dropdown
    };
  }
};
