<ppl-dialog title="Save email as document"
            i18n-title="@@Save_email_as_document"
            actionLabel="Save"
            i18n-actionLabel="@@Save"
            [formGroup]="formGroup"
            [formGroupNoPadding]="true"
            width="80vh"
            (action)="onSave()">
  <ppl-input-container label="Document Name"
                       i18n-label="@@Document_Name"
                       [pplFormControl]="formGroup.get('fileName')">
    <input pplInput
           autocomplete="off"
           formControlName="fileName" />
  </ppl-input-container>
</ppl-dialog>