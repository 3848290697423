import { Injectable } from '@angular/core';
import type { EntityAdapters } from '@ppl/components/entity-list';
import { getSpace } from '@ppl/space';
import { LocalStorageService } from '@ppl/utils';
import { CoreStore } from '../store/core.store';

@Injectable()
export class RecentRecordsService {

  constructor(
    private localStorageService: LocalStorageService,
    private store: CoreStore
  ) {}

  get() {
    const recentRecordsKey = this.getRecentRecordsKey();

    return this.localStorageService.getItem<string[]>(recentRecordsKey, []);
  }

  add(id: string) {
    const recentRecordsKey = this.getRecentRecordsKey();

    const recentRecords = this.localStorageService.getItem<string[]>(recentRecordsKey, []);

    if (recentRecords.includes(id)) {
      recentRecords.splice(recentRecords.indexOf(id), 1);
    }

    recentRecords.unshift(id);

    this.localStorageService.setItem(recentRecordsKey, recentRecords.slice(0, MaxRecentRecords));
  }

  private getRecentRecordsKey() {
    const space = this.store.get(getSpace);

    return `${RecentRecordsKey}_${space.id}`;
  }

}

export interface FetchEntitiesOptions {
  entityAdapters: EntityAdapters;
}

const RecentRecordsKey = 'Pipeliner_RecentRecords';
const MaxRecentRecords = 12;
