import { EntityNameEnum } from '@ppl/graphql-space-api';
import { EntityFormControlKind } from '../../../entity-form/domain/controls';
import { EntityListAdapters } from '../../../entity/domain/entity-list';
import type { EntityFormApiFieldTypeAdapter } from '../entity-form-api';

export const OwnerFieldTypeAdapter: EntityFormApiFieldTypeAdapter = {
  id: 'owner',
  getControl({ fieldDescriptor, formField }) {
    return {
      id: fieldDescriptor.id,
      label: formField?.label ?? fieldDescriptor.name,
      entityAdapters: EntityListAdapters,
      entityTypes: [
        EntityNameEnum.Client
      ],
      kind: EntityFormControlKind.Entity
    };
  }
};
