import sanitizeHtml from 'sanitize-html';

const commonStyleProperties = ['style', 'class'];

export function sanitizeHTML(dirty: string) {
  return sanitizeHtml(
    dirty,
    {
      allowedTags: [
        ...sanitizeHtml.defaults.allowedTags,
        ...otherAllowedTags,
        'img',
        'span',
        'small',
        'abbr',
        'body',
        'html',
        'style',
        'ppl-tag',
        'ppl-unsubscribe-link'
      ],
      allowVulnerableTags: true, // We had <style> tag enabled before, but upgrade to sanitize-html started spamming the console with warnings
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        'span': commonStyleProperties,
        'th': [...commonStyleProperties, 'align'],
        'td': [...commonStyleProperties, 'align', 'colspan', 'rowspan', 'width', 'height'],
        'tr': commonStyleProperties,
        'thead': commonStyleProperties,
        'table': [...commonStyleProperties, 'align', 'width', 'height'],
        'tbody': commonStyleProperties,
        'div': commonStyleProperties,
        'img': [...commonStyleProperties, 'width', 'height', 'src', 'srcset'],
        'a': [...commonStyleProperties, 'href', 'name', 'target'],
        'small': commonStyleProperties,
        'body': commonStyleProperties,
        'html': commonStyleProperties,
        'p': commonStyleProperties,
        'strong': commonStyleProperties,
        'em': commonStyleProperties,
        'u': commonStyleProperties,
        'h1': commonStyleProperties,
        'h2': commonStyleProperties,
        'li': commonStyleProperties,
        'style': ['type'],
        'ppl-tag': ['class', 'data-id', 'data-type', 'data-offset', 'data-offset-unit', 'data-offset-seconds', 'data-relation-type']
      },
    }
  );
}

// other cosmetic elements, which should be preserved for the sake of visual feeling
const otherAllowedTags = [
  'abbr',
  'acronym',
  'address',
  'area',
  'article',
  'aside',
  'b',
  'base',
  'basefont',
  'bdi',
  'bdo',
  'big',
  'blockquote',
  'br',
  'button',
  'caption',
  'center',
  'cite',
  'code',
  'col',
  'colgroup',
  'data',
  'datalist',
  'dd',
  'del',
  'details',
  'dfn',
  'dir',
  'div',
  'dl',
  'dt',
  'em',
  'fieldset',
  'figcaption',
  'figure',
  'font',
  'footer',
  'head',
  'h1',
  'h2',
  'header',
  'hr',
  'html',
  'i',
  'ins',
  'kbd',
  'label',
  'legend',
  'li',
  'link',
  'main',
  'map',
  'mark',
  'meta',
  'meter',
  'nav',
  'ol',
  'optgroup',
  'option',
  'output',
  'p',
  'param',
  'picture',
  'pre',
  'progress',
  'q',
  'rp',
  'rt',
  'ruby',
  's',
  'samp',
  'section',
  'small',
  'span',
  'strike',
  'strong',
  'sub',
  'summary',
  'sup',
  'svg',
  'template',
  'textarea',
  'tfoot',
  'th',
  'thead',
  'time',
  'title',
  'tr',
  'track',
  'tt',
  'u',
  'ul',
  'var',
  'wbr'
];
