import type { AppointmentEntity, CreateAppointmentInput, CreateTaskInput, TaskEntity } from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import type { EntityMultipleSelectItem } from '../../../entity-control/components/entity-multiple-select/entity-multiple-select.component';
import { EntityFormControlKind } from '../../../entity-form/domain/controls';
import { EntityListAdapters } from '../../../entity/domain/entity-list';
import type { EntityFormApiFieldTypeAdapter } from '../entity-form-api';

export const LinkedItemsFieldTypeAdapter: EntityFormApiFieldTypeAdapter<AppointmentEntity | TaskEntity, EntityMultipleSelectItem[], CreateAppointmentInput | CreateTaskInput> = {
  id: 'linked_items',
  getControl({ featurePermissions, fieldDescriptor, formField }) {
    return {
      id: fieldDescriptor.id,
      label: formField?.label ?? fieldDescriptor.name,
      entityAdapters: EntityListAdapters,
      entityTypes: [
        EntityNameEnum.Account,
        EntityNameEnum.Contact,
        EntityNameEnum.Lead,
        EntityNameEnum.Opportunity
      ],
      maxValues: featurePermissions.activitiesMultiLink ? undefined : 1,
      kind: EntityFormControlKind.MultipleEntity
    };
  },
  getValue({ entity }) {
    return [
      ...entity.accountRelations.edges.map(edge => ({ entityId: edge.node.accountId, entityType: EntityNameEnum.Account })),
      ...entity.contactRelations.edges.map(edge => ({ entityId: edge.node.contactId, entityType: EntityNameEnum.Contact })),
      ...entity.leadRelations.edges.map(edge => ({ entityId: edge.node.leadOpptyId, entityType: EntityNameEnum.Lead })),
      ...entity.opportunityRelations.edges.map(edge => ({ entityId: edge.node.leadOpptyId, entityType: EntityNameEnum.Opportunity }))
    ];
  },
  getInput({ entity, value }) {
    return {
      accountRelations: value.filter(item => item.entityType === EntityNameEnum.Account).map(item => ({
        accountId: item.entityId
      })),
      contactRelations: value.filter(item => item.entityType === EntityNameEnum.Contact).map(item => ({
        contactId: item.entityId
      })),
      leadRelations: value.filter(item => item.entityType === EntityNameEnum.Lead).map(item => ({
        leadOpptyId: item.entityId
      })),
      opportunityRelations: value.filter(item => item.entityType === EntityNameEnum.Opportunity).map(item => ({
        leadOpptyId: item.entityId
      }))
    };
  }
};
