import { gql } from '@ppl/utils';

export const gqlInitializeMobileCallAccountMutation = gql`
  mutation initializeMobileCallAccount($input: InitializeMobileCallAccountInput!) {
    initializeMobileCallAccount(input: $input) {
      clientMutationId
    }
  }
`;

export const gqlInitializeMobileCallContactMutation = gql`
  mutation initializeMobileCallContact($input: InitializeMobileCallContactInput!) {
    initializeMobileCallContact(input: $input) {
      clientMutationId
    }
  }
`;
