import { gql } from '@ppl/utils';

export const gqlFetchOpportunityFormData = gql`
  query fetchOpportunityFormData($id: ID!) {
    entities {
      opportunity {
        getById(entityId: $id) {
          accountRelations {
            edges {
              node {
                id
                accountId
                isPrimary
              }
            }
          }
          closingDate
          contactRelations {
            edges {
              node {
                id
                contactId
                isPrimary
              }
            }
          }
          customFields
          description
          id
          name
          ownerId
          ranking
          step {
            id
            pipelineId
          }
          unitId
          value {
            currencyId
            valueForeign
          }
        }
      }
    }
  }
`;

export const gqlCreateOpportunityMutation = gql`
  mutation createOpportunity($input: CreateOpportunityInput!, $validationLevel: [ValidationLevel!]) {
    createOpportunity(input: $input, validationLevel: $validationLevel) {
      opportunity {
        id
      }
    }
  }
`;

export const gqlUpdateOpportunityMutation = gql`
  mutation updateOpportunity($input: UpdateOpportunityInput!, $validationLevel: [ValidationLevel!]) {
    updateOpportunity(input: $input, validationLevel: $validationLevel) {
      clientMutationId
    }
  }
`;
