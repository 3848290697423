import { mapObjectValues } from '@ppl/utils';

export function mergeFormValue<T extends { [id: string]: string }>(prevFormInitValue: T, prevFormCurrentValue: T, nextFormInitValue: T): T {
  return mapObjectValues(nextFormInitValue, (value, key) => {
    if (Object.prototype.hasOwnProperty.call(prevFormCurrentValue, key) && prevFormCurrentValue[key] !== prevFormInitValue[key]) {
      return prevFormCurrentValue[key];
    }

    return value;
  }) as T;
}
