import type { PplNamePipe } from '@ppl/auth';
import type { ContactEntity, ContactFilterInput, ContactOrderByInput} from '@ppl/graphql-space-api';
import { OrderByEnum } from '@ppl/graphql-space-api';
import { createContactNameFilterInput } from '@ppl/domain';
import { gql } from '@ppl/utils';
import type { EntityAdapter } from './entity.adapter';

export const ContactAdapter: EntityAdapter = {
  entityFields: gql`
    fragment ContactEntityListFields on ContactEntity {
      id
      firstName
      lastName
      middleName
      title
    }
  `,
  getFilterInput(filter, excludeIds, includeIds) {
    const filterInput: ContactFilterInput = {
      ...createContactNameFilterInput(filter),
      NOT: {
        id: { eq: excludeIds }
      }
    };

    if (includeIds.length !== 0) {
      filterInput.id = { eq: includeIds };
    }

    return filterInput;
  },
  getName(entity: ContactEntity, namePipe: PplNamePipe) {
    return namePipe.transform(entity);
  },
  getOrderByInput(): ContactOrderByInput[] {
    return [
      { lastName: OrderByEnum.Asc },
      { firstName: OrderByEnum.Asc }
    ];
  }
};
