<ppl-card-large [disabled]="disabled">
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="EntityNameEnum.Contact"
                [kind]="AvatarKind.Large"
                [pictureUrl]="entity.picture?.url"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    {{entity | pplName}}
  </ng-template>

  <ng-template #descriptionTemplate>
    <span *ngIf="entity.primaryAccountPosition"
         class="position">
      {{entity.primaryAccountPosition}} <ng-container i18n="@@of">of</ng-container>
    </span>

    <br *ngIf="entity.primaryAccountPosition" />

    <span *ngIf="entity.primaryAccount"
         class="account"
         (click)="onAccountClick($event)">{{entity.primaryAccount.name}}</span>

    <ppl-card-social *ngIf="entity.socialMedia"
                     [entity]="entity.socialMedia"></ppl-card-social>
  </ng-template>

  <ng-template #actionTemplate>
    <ppl-external-link [entity]="entity"></ppl-external-link>
  </ng-template>
</ppl-card-large>