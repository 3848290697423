import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@ppl/i18n';
import { PplIconModule } from '@ppl/ui/icon';
import { PplSelectModule } from '@ppl/ui/select';
import { AvatarModule } from '../avatar/avatar.module';
import { EntityControlModule } from '../entity-control/entity-control.module';
import { PageModule } from '../page/page.module';
import { ToastModule } from '../toast/toast.module';
import { EntityErrorComponent } from './components/entity-error/entity-error.component';
import { EntityFormLayoutComponent } from './components/entity-form-layout/entity-form-layout.component';
import { EntityListTypeComponent } from './components/entity-list-type/entity-list-type.component';
import { ErrorToastComponent } from './components/error-toast/error-toast.component';
import { RelationToastComponent } from './components/relation-toast/relation-toast.component';
import { SuccessToastComponent } from './components/success-toast/success-toast.component';
import { EntityService } from './services/entity.service';

@NgModule({
  imports: [
    AvatarModule,
    CommonModule,
    EntityControlModule,
    I18nModule,
    PageModule,
    PplIconModule,
    PplSelectModule,
    ToastModule
  ],
  declarations: [
    EntityErrorComponent,
    EntityFormLayoutComponent,
    EntityListTypeComponent,
    ErrorToastComponent,
    RelationToastComponent,
    SuccessToastComponent
  ],
  exports: [
    EntityErrorComponent,
    EntityFormLayoutComponent,
    EntityListTypeComponent,
    ErrorToastComponent,
    RelationToastComponent,
    SuccessToastComponent
  ],
  providers: [
    EntityService
  ]
})
export class EntityModule {}
