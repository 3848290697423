import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'ppl-card-tab',
  templateUrl: './card-tab.component.html',
  styleUrls: ['./card-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardTabComponent {

  @Input() label: string;
  @Input() selected?: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  setSelected(selected: boolean) {
    if (selected !== this.selected) {
      this.selected = selected;
      this.changeDetectorRef.detectChanges();
    }
  }

}
