import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fetchAsyncValue } from '@ppl/core';
import type { AsyncValue } from '@ppl/store';
import { AsyncState } from '@ppl/store';
import type { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import type { OpportunityData } from '../../domain/opportunity';
import { OpportunityService } from '../../services/opportunity.service';

@Component({
  selector: 'ppl-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunityComponent implements OnInit {

  data$: Observable<AsyncValue<OpportunityData>>;

  AsyncState = AsyncState;

  constructor(
    private opportunityService: OpportunityService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.data$ = this.route.params.pipe(
      switchMap(params => {
        return fetchAsyncValue(this.opportunityService.fetchData(params.id));
      })
    );
  }

}
