import { gql } from '@ppl/utils';

export const gqlFetchContactName = gql`
  query fetchContactName($id: ID!) {
    entities {
      contact {
        getById(entityId: $id) {
          firstName
          id
          lastName
          middleName
          title
        }
      }
    }
  }
`;


export const gqlFetchLeadName = gql`
  query fetchLeadName($id: ID!) {
    entities {
      lead {
        getById(entityId: $id) {
          id
          name
        }
      }
    }
  }
`;

export const gqlFetchOpportunityName = gql`
  query fetchOpportunityName($id: ID!) {
    entities {
      opportunity {
        getById(entityId: $id) {
          id
          name
        }
      }
    }
  }
`;

export const gqlCreateContactAccountRelationMutation = gql`
  mutation createContactAccountRelation($input: CreateContactAccountRelationInput!) {
    createContactAccountRelation(input: $input) {
      clientMutationId
    }
  }
`;

export const gqlCreateLeadOpptyAccountRelationMutation = gql`
  mutation createLeadOpptyAccountRelation($input: CreateLeadOpptyAccountRelationInput!) {
    createLeadOpptyAccountRelation(input: $input) {
      clientMutationId
    }
  }
`;

export const gqlCreateLeadOpptyContactRelationMutation = gql`
  mutation createLeadOpptyContactRelation($input: CreateLeadOpptyContactRelationInput!) {
    createLeadOpptyContactRelation(input: $input) {
      clientMutationId
    }
  }
`;
