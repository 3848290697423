import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PplCheckboxModule } from '@ppl/ui/checkbox';
import { PplDialogModule } from '@ppl/ui/dialog';
import { PplInputModule } from '@ppl/ui/input';
import { OutlookModule } from '../outlook/outlook.module';
import { ToastModule } from '../toast/toast.module';
import { DocumentNameComponent } from './components/document-name/document-name.component';
import { SelectAttachmentsComponent } from './components/select-attachments/select-attachments.component';
import { EmailEntityService } from './services/email-entity.service';
import { EmailService } from './services/email.service';

@NgModule({
  imports: [
    CommonModule,
    OutlookModule,
    PplCheckboxModule,
    PplDialogModule,
    PplInputModule,
    ReactiveFormsModule,
    ToastModule
  ],
  declarations: [
    DocumentNameComponent,
    SelectAttachmentsComponent
  ],
  providers: [
    EmailEntityService,
    EmailService
  ]
})
export class EmailModule {}
