<ppl-input-number-clamp [controls]="true"
                        [minValue]="is24HourSystem ? 0 : 1"
                        [maxValue]="is24HourSystem ? 23 : 12"
                        [value]="hoursValue"
                        (valueChange)="onHoursChange($event)"></ppl-input-number-clamp>

<ppl-input-number-clamp [controls]="true"
                        [minValue]="0"
                        [maxValue]="59"
                        [padValue]="{ maxLength: 2, fillString: '0' }"
                        [value]="minutesValue"
                        (valueChange)="onMinutesChange($event)"></ppl-input-number-clamp>

<ppl-toggle-group *ngIf="!is24HourSystem"
                  [options]="TimePeriods"
                  selection="one"
                  [value]="timePeriod"
                  (valueChange)="onTimePeriodChange($event)"></ppl-toggle-group>