<ppl-avatar *ngIf="avatarKind"
            [entityType]="entityType"
            [kind]="avatarKind"
            [pictureUrl]="pictureUrl"></ppl-avatar>

<div class="content">
  <div class="name">
    {{name}}
  </div>

  <div *ngIf="description"
       class="description">
    <ppl-icon *ngIf="descriptionIcon"
              [svgIcon]="descriptionIcon"
              width="12px"
              height="12px"></ppl-icon>
    {{description}}
  </div>
</div>