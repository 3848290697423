<ppl-dialog title="Select attachments"
            i18n-title="@@Select_attachments"
            actionLabel="Save"
            i18n-actionLabel="@@Save"
            [formGroup]="formGroup"
            [formGroupNoPadding]="true"
            width="80vh"
            (action)="onSave()">
  <ppl-input-container [pplFormControl]="formGroup.get('attachments')">
    <ppl-input-container *ngFor="let formSubGroup of formGroupAttachmentsControls"
                         [formGroup]="formSubGroup">
      <ppl-checkbox formControlName="enabled">{{formSubGroup.get('originalFileName').value}}</ppl-checkbox>

      <input *ngIf="formSubGroup.get('enabled').value"
             pplInput
             autocomplete="off"
             formControlName="fileName" />
    </ppl-input-container>
  </ppl-input-container>
</ppl-dialog>