import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplIconModule } from '@ppl/ui/icon';
import { ToastScreenComponent } from './components/toast-screen/toast-screen.component';
import { ToastComponent } from './components/toast/toast.component';
import { ToastService } from './services/toast.service';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule
  ],
  declarations: [
    ToastComponent,
    ToastScreenComponent
  ],
  exports: [
    ToastComponent
  ],
  providers: [
    ToastService
  ]
})
export class ToastModule {}
