import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { AppointmentEntity, TaskEntity } from '@ppl/graphql-space-api';
import { MemoizeLast, normalizeTextToPlain } from '@ppl/utils';

@Component({
  selector: 'ppl-description-card',
  templateUrl: './description-card.component.html',
  styleUrls: ['./description-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DescriptionCardComponent {

  @Input() entity: AppointmentEntity | TaskEntity;

  @MemoizeLast<DescriptionCardComponent>(['entity'])
  get description() {
    return normalizeTextToPlain(this.entity.description);
  }

}
