import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import type { PplPopoverControl } from '@ppl/ui/popover';
import { FormValueControl, getFormControlProvider } from '@ppl/utils';

@Component({
  selector: 'ppl-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    getFormControlProvider(() => DateInputComponent)
  ]
})
@FormValueControl()
export class DateInputComponent {

  @Input() displayRemove = true;
  @Input() value: string | null;
  @Input() disabled?: boolean;

  @Output() valueChange = new EventEmitter<string | null>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  onValueChange(popover: PplPopoverControl, value: string | null) {
    popover.close();

    this.valueChange.emit(value);
  }

}
