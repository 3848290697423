<div *ngFor="let item of flatItems; trackBy: trackById"
     class="item"
     [class.selected]="item.id === value"
     [style.padding-left.px]="ItemPadding + item.depth * FolderOffset"
     (mousedown)="$event.preventDefault()"
     (click)="onItemClick(item)">
  <ng-container *ngIf="item.folder">
    <svg class="item-chevron"
         [class.open]="item.folder.open"
         width="12px"
         height="8px">
      <path d="m 6 5.5 l 5.25 -4.7 l 0.75 0.7 l -6 5.5 l -6 -5.5 l 0.75 -0.7"
            fill="#323130"></path>
    </svg>

    <ppl-icon *ngIf="!item.folder.open"
              svgIcon="folder-full-gray"
              width="17px"
              height="14px"></ppl-icon>

    <ppl-icon *ngIf="item.folder.open"
              svgIcon="folder-open-full-gray"
              width="17px"
              height="14px"></ppl-icon>
  </ng-container>

  <div class="item-name">
    {{item.name}}
  </div>
</div>

<div *ngIf="flatItems.length === 0"
     class="message">
  {{noItemsMessage}}
</div>