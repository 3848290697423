import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import type { ComponentRef} from '@angular/core';
import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { ToastScreenComponent, ToastScreenData } from '../components/toast-screen/toast-screen.component';
import type { ToastContent} from '../domain/toast';
import { ToastCloseTimeout, ToastKind, TOAST_CONTROL } from '../domain/toast';

@Injectable()
export class ToastService {

  private appRef: ApplicationRef;
  private bodyPortalHost: DomPortalHost;

  private prevToastRef: ComponentRef<ToastScreenComponent> | null = null;
  private toastRefHandle: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) {}

  open<T = any>(options: ToastOpenOptions<T>) {
    if (!this.bodyPortalHost) {
      if (!this.appRef) {
        this.appRef = this.injector.get<ApplicationRef>(ApplicationRef);
      }

      this.bodyPortalHost = new DomPortalHost(
        document.body,
        this.componentFactoryResolver,
        this.appRef,
        this.injector
      );
    }

    const toastData: ToastScreenData = {
      content: options.content,
      kind: ToastKind.Success,
      text: options.text
    };

    const containerInjector = Injector.create([{
      provide: ToastScreenData,
      useValue: toastData
    }, {
      provide: TOAST_CONTROL,
      useValue: {
        close: () => {
          toastRef.destroy();

          this.prevToastRef = null;
        }
      }
    }], this.injector);

    if (this.prevToastRef) {
      clearTimeout(this.toastRefHandle);

      this.prevToastRef.destroy();
    }

    const toastComponent = new ComponentPortal(ToastScreenComponent, null, containerInjector);
    const toastRef = this.bodyPortalHost.attachComponentPortal(toastComponent);

    this.toastRefHandle = setTimeout(() => {
      toastRef.destroy();

      this.prevToastRef = null;
    }, ToastCloseTimeout);

    this.prevToastRef = toastRef;

    return toastRef;
  }

}

export interface ToastOpenOptions<T = any> {
  content?: ToastContent<T>;
  text?: string;
}
