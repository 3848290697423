<ppl-page [flexContent]="true">
  <ng-template #headerTemplate>
    <ppl-page-form-header icon="toolbar-white-email-templates"
                          label="Email templates"
                          i18n-label="@@Email_templates"></ppl-page-form-header>
  </ng-template>

  <ng-container *ngIf="data$ | async; let data">
    <ng-container *ngIf="data.state === AsyncState.FETCHED">
      <div class="container"
           [formGroup]="formGroup">
        <div class="header">
          <ppl-input-container label="Select an email template:"
                               i18n-label="@@Select_an_email_template:">
            <ppl-select [options]="ownerFilterOptions"
                        [value]="ownerFilter"
                        (valueChange)="onOwnerFilterChange($event)"></ppl-select>
          </ppl-input-container>

          <ppl-input-container>
            <ppl-search [value]="nameFilter"
                        (valueChange)="onNameFilterChange($event)"></ppl-search>
          </ppl-input-container>
        </div>

        <ppl-input-container [pplFormControl]="formGroup.get('templateId')"
                             class="tree">
          <ppl-entity-tree [items]="getTreeItems(data.value, ownerFilter, nameFilter)"
                           [forceOpenFolders]="!!nameFilter"
                           noItemsMessage="No templates found"
                           i18n-noItemsMessage="@@No_templates_found"
                           formControlName="templateId"></ppl-entity-tree>
        </ppl-input-container>
      </div>

      <ppl-page-actions saveLabel="Use template"
                        i18n-saveLabel="@@Use_template"
                        (cancelClick)="onCancelClick()"
                        (saveClick)="onUseClick()"></ppl-page-actions>
    </ng-container>

    <ppl-page-loading *ngIf="data.state === AsyncState.FETCHING"></ppl-page-loading>

    <ppl-page-error *ngIf="data.state === AsyncState.ERROR"
                    text="Unable to load email templates"
                    i18n-text="@@Unable_to_load_email_templates"></ppl-page-error>
  </ng-container>
</ppl-page>