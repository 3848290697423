import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import type { PplMenuItem } from '@ppl/ui/menu-items';

@Component({
  selector: 'ppl-card-group',
  templateUrl: './card-group.component.html',
  styleUrls: ['./card-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardGroupComponent {

  @Input() count: number | null = null;
  @Input() displayCreate = true;
  @Input() label: string;
  @Input() menuItems?: PplMenuItem[];

  @Output() createClick = new EventEmitter();
  @Output() menuItemClick = new EventEmitter<string>();

}
