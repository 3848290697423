<div #input
     class="input"
     [class.disabled]="disabled"
     [pplPopover]="datePickerPopover"
     [pplPopoverDisabled]="disabled"
     [pplPopoverExactCalculations]="true"
     pplPopoverAlignStart>
  <div *ngIf="value"
       class="value">{{value | pplDate}}</div>

  <div *ngIf="!value && !disabled"
       class="placeholder"
       i18n="Select_a_date_ellipsis">Select a date…</div>

  <ppl-icon svgIcon="entity-detail-due-date"
            width="16px"
            height="16px"></ppl-icon>
</div>

<ng-template #datePickerPopover
             let-popover="popover">
  <ppl-date-picker [displayRemove]="displayRemove"
                   [value]="value"
                   (valueChange)="onValueChange(popover, $event)"></ppl-date-picker>
</ng-template>