import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OutlookItemDirection } from '../../../../shared/outlook/domain/outlook';
import type { DashboardUserItem } from '../../../domain/dashboard';

@Component({
  selector: 'ppl-dashboard-recipients-user',
  templateUrl: './dashboard-recipients-user.component.html',
  styleUrls: ['./dashboard-recipients-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardRecipientsUserComponent {

  @Input() items: DashboardUserItem[];

  OutlookItemDirection = OutlookItemDirection;

}
