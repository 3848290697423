import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '@ppl/auth';
import { PplDialogService } from '@ppl/ui/dialog';
import { Unsubscribe } from '@ppl/utils';
import type { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import type { AboutData } from '../about/about.component';
import { AboutComponent } from '../about/about.component';

@Component({
  selector: 'ppl-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Unsubscribe()
export class SignInComponent {

  @Input() description: string;
  @Input() signInQueryForSpace: boolean;
  @Input() signUpUrl: string;
  @Input() version: string;

  @Output() authSuccess = new EventEmitter();

  currentYear = new Date().getFullYear();

  signInProgress$ = new BehaviorSubject(false);

  signInSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private dialogService: PplDialogService
  ) {}

  onSignInClick() {
    if (this.signInProgress$.getValue()) {
      return;
    }

    this.signInProgress$.next(true);

    this.signInSubscription = this.authService.signIn({
      queryForSpace: this.signInQueryForSpace
    }).subscribe(success => {
      if (success) {
        this.authSuccess.emit();
      } else {
        this.signInProgress$.next(false);
      }
    }, () => {
      this.signInProgress$.next(false);
    });
  }

  onVersionClick(event: MouseEvent) {
    if (event.ctrlKey || event.metaKey) {
      this.authService.signOut().subscribe(result => {
        if (result) {
          this.authSuccess.emit();
        }
      });
    } else {
      this.dialogService.open<AboutComponent, AboutData>(AboutComponent, {
        data: {
          version: this.version
        }
      });
    }
  }

}
