import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplCheckboxModule } from '@ppl/ui/checkbox';
import { PplIconModule } from '@ppl/ui/icon';
import { EntityGridComponent } from './components/entity-grid.component';

@NgModule({
  imports: [
    CommonModule,
    PplCheckboxModule,
    PplIconModule
  ],
  declarations: [
    EntityGridComponent
  ],
  exports: [
    EntityGridComponent
  ]
})
export class EntityGridModule {}
