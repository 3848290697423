<ppl-card-medium>
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="EntityNameEnum.Task"
                [kind]="AvatarKind.Medium"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    <span [class.overdue]="isOverdue">{{entity.subject}}</span>
  </ng-template>

  <ng-template #descriptionTemplate>
    <ng-container *ngIf="entity.dueDate">
      <ng-container i18n="@@Due">Due</ng-container> {{entity.dueDate | pplDate}}
    </ng-container>
  </ng-template>
</ppl-card-medium>