import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { EntityAdapters } from '@ppl/components/entity-list';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import type { PplAutocompleteOption } from '@ppl/ui/autocomplete';
import type { PplSelectOption } from '@ppl/ui/select';
import { FormValueControl, getFormControlProvider, MemoizeLast } from '@ppl/utils';

@Component({
  selector: 'ppl-entity-list-type',
  templateUrl: './entity-list-type.component.html',
  styleUrls: ['./entity-list-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    getFormControlProvider(() => EntityListTypeComponent)
  ]
})
@FormValueControl()
export class EntityListTypeComponent {

  @Input() entityTypes: EntityNameEnum[] = [];
  @Input() value: EntityListTypeItem | EntityListTypeItem[] | null;

  @Input() entityAdapters?: EntityAdapters;
  @Input() multipleSelection?: boolean;
  @Input() optionDisabled?: (option: PplAutocompleteOption) => boolean;
  @Input() optionTemplate?: TemplateRef<any>;
  @Input() optionTemplateRowHeight?: number;

  @Output() valueChange = new EventEmitter<EntityListTypeItem | EntityListTypeItem[]>();

  entityTypeValue: EntityNameEnum | null = null;

  Array = Array;

  @MemoizeLast<EntityListTypeComponent>(['entityTypes'])
  get entityTypeOptions() {
    return this.entityTypes.map<PplSelectOption<EntityNameEnum>>(entityType => ({
      label: this.i18nService.translateEnumValue('EntityNameEnum', entityType),
      value: entityType
    }));
  }

  @MemoizeLast<EntityListTypeComponent>(['entityTypes', 'entityTypeValue'])
  get entityType() {
    return this.entityTypeValue || this.entityTypeOptions[0].value;
  }

  @MemoizeLast<EntityListTypeComponent>(['value'])
  get entityListValue() {
    if (Array.isArray(this.value)) {
      return this.value.map(item => item.entityId);
    } else if (this.value) {
      return this.value.entityId;
    }

    return null;
  }

  @MemoizeLast<EntityListTypeComponent>(['entityType'])
  get noResultsLabel() {
    return this.i18nService.translate(({
      [EntityNameEnum.Account]: 'No_accounts_found',
      [EntityNameEnum.Contact]: 'No_contacts_found',
      [EntityNameEnum.Lead]: 'No_leads_found',
      [EntityNameEnum.Opportunity]: 'No_opportunities_found'
    })[this.entityType]);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private i18nService: I18nService
  ) {}

  onEntityTypeChange(entityType: EntityNameEnum) {
    this.entityTypeValue = entityType;
  }

  onEntityListValueChange(value: string | string[]) {
    if (Array.isArray(value)) {
      this.valueChange.emit(value.map(id => {
        return (this.value as EntityListTypeItem[]).find(item => item.entityId === id) || {
          entityId: id,
          entityType: this.entityType
        };
      }));
    } else {
      this.valueChange.emit({
        entityId: value,
        entityType: this.entityType
      });
    }
  }

}

export interface EntityListTypeItem {
  entityId: string;
  entityType: EntityNameEnum;
}
