<ppl-card-large [disabled]="disabled">
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="EntityNameEnum.Appointment"
                [kind]="AvatarKind.Large"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    {{entity.subject}}
  </ng-template>

  <ng-template #descriptionTemplate>
    {{entity.status | tEnum:'ActivityStatusEnum'}}
    <br />
    {{entity.owner | pplName}}
  </ng-template>

  <ng-template #actionTemplate>
    <ppl-external-link [entity]="entity"></ppl-external-link>
  </ng-template>
</ppl-card-large>