<ppl-dashboard-card-list icon="menu-contact"
                         [items]="items"
                         [name]="items.length + ' colleagues'">
  <ng-template #cardTemplate
               let-item
               let-active="active">
    <ppl-client-card-large *ngIf="largeCard"
                           [entity]="item.entity"></ppl-client-card-large>

    <ppl-client-card-small *ngIf="!largeCard"
                           [entity]="item.entity"
                           [active]="active"></ppl-client-card-small>
  </ng-template>
</ppl-dashboard-card-list>