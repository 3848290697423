import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { ClientEntity } from '@ppl/graphql-space-api';

@Component({
  selector: 'ppl-attendees-cards',
  templateUrl: './attendees-cards.component.html',
  styleUrls: ['./attendees-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttendeesCardsComponent {

  @Input() entities: ClientEntity[];

}
