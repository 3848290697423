import { Injectable, isDevMode } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivate, Data } from '@angular/router';
import { dayNameMap, getAuthSsoSpaceId, getLoggedInUser, getUserLocale, LocaleService } from '@ppl/auth';
import { FirebaseService } from '@ppl/firebase';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { PplDatePickerIntl } from '@ppl/ui/date-picker';
import { getSpace, getSpaceUserId, SpaceService } from '@ppl/space';
import { of } from 'rxjs';
import { first, mapTo, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LocationService } from '../services/location.service';
import { CoreStore } from '../store/core.store';

@Injectable()
export class SpaceGuardService implements CanActivate {

  constructor(
    private datePickerIntl: PplDatePickerIntl,
    private firebaseService: FirebaseService,
    private localeService: LocaleService,
    private locationService: LocationService,
    private spaceService: SpaceService,
    private store: CoreStore
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    let spaceId = localStorage.getItem('Pipeliner_Space');

    if (isDevMode()) {
      const ssoSpaceId = this.store.get(getAuthSsoSpaceId);

      if (ssoSpaceId) {
        spaceId = ssoSpaceId;
        localStorage.setItem('Pipeliner_Space', ssoSpaceId);
      }
    }

    const hasAuthToken = !!localStorage.getItem('Pipeliner_Token');

    console.log('Host Auth Token', hasAuthToken);
    console.log('Host Space ID', spaceId);

    if (!hasAuthToken) {
      const data: SpaceGuardData = {
        signInNeeded: true,
        signInQueryForSpace: !spaceId
      };

      route.data = {
        ...route.data,
        ...data
      };

      document.body.removeChild(document.getElementById('init'));

      return of(true).pipe(tap(() => {
        this.locationService.setSpaceGuardSuccess(false);
      }));
    }

    return this.spaceService.canAccess(spaceId, {
      accessControl: {
        entities: [EntityNameEnum.Account, EntityNameEnum.Appointment, EntityNameEnum.Contact, EntityNameEnum.Lead, EntityNameEnum.Opportunity, EntityNameEnum.Task],
        features: ['accounts', 'activitiesMultiLink', 'contacts', 'leads', 'opportunities']
      },
      authCheckOnly: true,
      entities: [
        EntityNameEnum.Currency
      ],
      entityCustomNames: true
    }).pipe(switchMap(success => {
      this.locationService.setSpaceGuardSuccess(success);

      if (!success) {
        const data: SpaceGuardData = {
          signInNeeded: true,
          signInQueryForSpace: true
        };

        route.data = {
          ...route.data,
          ...data
        };
      } else {
        const space = this.store.get(getSpace);
        const locale = this.store.get(getUserLocale);

        this.localeService.registerLocale(locale).pipe(
          switchMap(angularLocaleId => {
            return this.localeService.loadBootstrapDatepickerLocale(locale).pipe(
              mapTo(angularLocaleId)
            );
          })
        ).subscribe();

        this.datePickerIntl.firstWeekDay = dayNameMap[space.settings.firstDayOfWeek];

        this.setFirebaseUserProperties();
      }

      return of(true);
    })).pipe(tap(() => {
      this.store.select(getSpaceUserId).pipe(first()).subscribe(() => {
        document.body.removeChild(document.getElementById('init'));
      });
    }));
  }

  private setFirebaseUserProperties() {
    const user = this.store.get(getLoggedInUser);
    const space = this.store.get(getSpace);

    this.firebaseService.setUserProperties({
      environment: EnvMap[environment.name],
      user_id: user.profile.email.toLowerCase(),
      subscription_id: space.subscription.id,
      subscription_name: space.subscription.name,
      subscription_tier: space.subscription.productTier,
      subscription_licences: space.subscription.licensesPurchased,
      space_name: space.id,
      classification: space.subscription.classification
    });
  }

}

const EnvMap = {
  devel: 'dev',
  staging: 'stg',
  staging2: 'stg2',
  production: 'prod',
  dev: 'dev'
};

export interface SpaceGuardData extends Data {
  signInNeeded: boolean;
  signInQueryForSpace: boolean;
}
