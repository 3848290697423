<ppl-card-group label="Attendees"
                i18n-label="@@Attendees"
                [count]="entities.length"
                [displayCreate]="false">
  <ppl-card-list [entities]="entities">
    <ng-template #cardTemplate
                 let-entity>
      <ppl-client-card-medium [entity]="entity"></ppl-client-card-medium>
    </ng-template>
  </ppl-card-list>
</ppl-card-group>