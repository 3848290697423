<div class="icon"
     pplPopover
     pplPopoverAlignStart
     [pplPopoverDisabled]="!menuItems"
     [pplPopoverMenu]="menuItems"
     (pplPopoverMenuItemClick)="menuItemClick.emit($event)"
     (click)="actionClick.emit()">
  <ppl-icon [svgIcon]="icon"
            width="30px"
            height="30px"></ppl-icon>
  {{iconName}}
</div>

<div class="identity">
  <div class="name">
    {{name}}
  </div>

  <div class="description">
    {{description}}
  </div>
</div>