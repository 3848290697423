import { Injectable } from '@angular/core';
import { I18nService } from '@ppl/i18n';
import type { PplDialogRef} from '@ppl/ui/dialog';
import { PplYesNoCancelResult , PplDialogService} from '@ppl/ui/dialog';
import type { ButtonType } from '@ppl/ui/button';
import type { AsyncValue } from '@ppl/store';
import { AsyncState } from '@ppl/store';
import type { Observable} from 'rxjs';
import { concat, of, throwError } from 'rxjs';
import { catchError, finalize, map, share, switchMap, tap } from 'rxjs/operators';
import { LoadingDialogComponent } from '../components/loading-dialog/loading-dialog.component';

@Injectable()
export class ActionsUtilsService {

  constructor(
    private dialogService: PplDialogService,
    private i18nService: I18nService
  ) { }

  doAction<T>(observable: Observable<T>, options: DoActionOptions = {}) {
    let loadingDialogRef: PplDialogRef;

    return doAction(of(null).pipe(
      switchMap(() => {
        if (options.confirmMessage) {
          return this.dialogService.confirm({
            text: options.confirmMessage,
            description: options.confirmMessageDescription,
            actionButtonType: options.confirmMessageActionButtonType,
            actionLabel: options.confirmMessageActionLabel
          });
        }

        return of(null);
      }),
      tap(() => {
        if (!options.silent) {
          loadingDialogRef = this.dialogService.open(LoadingDialogComponent);
        }
      }),
      switchMap(() => {
        return observable;
      }),
      catchError(error => {
        if (loadingDialogRef) {
          loadingDialogRef.close();
        }

        return throwError(error);
      }),
      tap(() => {
        if (loadingDialogRef) {
          loadingDialogRef.close();
        }
      }),
      finalize(() => {
        if (loadingDialogRef) {
          loadingDialogRef.close();
        }
      }),
      share()
    ));
  }

  saveChangesAction<T>(options: SaveChangesActionOptions<T>) {
    if (options.changes) {
      this.dialogService.yesNoCancel({
        text: options.text || this.i18nService.translate('Do_you_want_to_save_your_changes')
      }).subscribe(result => {
        switch (result) {
          case PplYesNoCancelResult.Yes:
            this.doAction(options.onSave(), {
              silent: options.silent
            }).subscribe(result => {
              options.onAction(result);
            });
            break;
          case PplYesNoCancelResult.No:
            options.onAction();
            break;
          case PplYesNoCancelResult.Cancel:
            break;
        }
      });
    } else {
      options.onAction();
    }
  }

  openOverlay() {
    return this.dialogService.open(LoadingDialogComponent, {
      data: {
        hideLoading: true
      }
    });
  }

}

export function fetchAsyncValue<T>(observable: Observable<T>): Observable<AsyncValue<T>> {
  return concat(
    of({
      state: AsyncState.FETCHING
    }),
    observable.pipe(
      map(data => {
        if (!data) {
          return {
            state: AsyncState.ERROR,
            error: null
          };
        }

        return {
          state: AsyncState.FETCHED,
          value: data
        };
      }),
      catchError(error => {
        return concat(
          of({
            state: AsyncState.ERROR,
            error
          }),
          throwError(error)
        );
      })
    )
  );
}

function doAction<T>(action$: Observable<T>) {
  action$.subscribe();

  return action$;
}

export interface DoActionOptions {
  confirmMessage?: string;
  confirmMessageDescription?: string;
  confirmMessageActionButtonType?: ButtonType;
  confirmMessageActionLabel?: string;
  silent?: boolean;
}

export interface SaveChangesActionOptions<T = any> {
  changes: boolean;
  onAction: (save?: T) => void;
  onSave: () => Observable<T>;
  silent?: boolean;
  text?: string;
}
