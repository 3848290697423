import type { Action } from './action';

export function createReducer<S, A, B = A | { __OtherAction__: any }>(actionHandlers: { [U in keyof B]?: (state: S, action: Action<U, B[U]>) => S }, initialState: S): Reducer<S> {
  return (state: S = initialState, action: Action<keyof B, any>) => {
    if (actionHandlers[action.type]) {
      return (<any>actionHandlers[action.type])(state, action);
    } else if (actionHandlers['__OtherAction__']) {
      return (<any>actionHandlers['__OtherAction__'])(state, action);
    }

    return state;
  };
}

export function combineReducers<S>(reducerMap: { [key: string]: Reducer<any> }): Reducer<S> {
  return (state: S, action: Action<any, any>) => {
    const keys = Object.keys(reducerMap);

    if (!state) {
      state = {} as S;
    }

    let hasChanged = false;
    const nextState: any = {};

    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const reducer = reducerMap[key];

      const prevStateForKey = (state as any)[key];
      const nextStateForKey = reducer(prevStateForKey, action);

      nextState[key] = nextStateForKey;
      hasChanged = hasChanged || nextStateForKey !== prevStateForKey;
    }

    return hasChanged ? nextState : state;
  };
}

export type Reducer<S> = (state: S | undefined, action: Action<any, any>) => S;
