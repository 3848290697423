import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Unsubscribe } from '@ppl/utils';
import type { Subscription } from 'rxjs';
import { TrackingService } from '../../services/tracking.service';

@Component({
  selector: 'ppl-tracking-compose',
  templateUrl: './tracking-compose.component.html',
  styleUrls: ['./tracking-compose.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Unsubscribe()
export class TrackingComposeComponent implements OnInit {

  trackingEnabled = false;

  initSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private trackingService: TrackingService
  ) {}

  ngOnInit() {
    this.initSubscription = this.trackingService.hasTracking().subscribe(trackingEnabled => {
      this.trackingEnabled = trackingEnabled;

      this.changeDetectorRef.detectChanges();
    });
  }

  onTrackingEnabledToggle(trackingEnabled: boolean) {
    this.trackingEnabled = trackingEnabled;

    if (trackingEnabled) {
      this.trackingService.startTracking().subscribe({
        error: () => {
          this.trackingEnabled = false;

          this.changeDetectorRef.detectChanges();
        }
      });
    } else {
      this.trackingService.stopTracking().subscribe({
        error: () => {
          this.trackingEnabled = true;

          this.changeDetectorRef.detectChanges();
        }
      });
    }
  }

}

