import type { CreateLeadInput, CreateOpportunityInput, LeadEntity, OpportunityEntity } from '@ppl/graphql-space-api';
import { EntityNameEnum } from '@ppl/graphql-space-api';
import { EntityFormControlKind } from '../../../entity-form/domain/controls';
import { EntityListAdapters } from '../../../entity/domain/entity-list';
import type { EntityFormApiFieldTypeAdapter } from '../entity-form-api';

export const ContactCardFieldTypeAdapter: EntityFormApiFieldTypeAdapter<LeadEntity | OpportunityEntity, string | null, CreateLeadInput | CreateOpportunityInput> = {
  id: 'contact_card',
  getControl({ fieldDescriptor, formField }) {
    return {
      id: fieldDescriptor.id,
      label: formField?.label ?? fieldDescriptor.name,
      entityAdapters: EntityListAdapters,
      entityTypes: [
        EntityNameEnum.Contact
      ],
      kind: EntityFormControlKind.Entity
    };
  },
  getValue({ entity }) {
    return entity.contactRelations.edges.find(edge => edge.node.isPrimary)?.node.contactId || null;
  },
  getInput({ entity, value }) {
    if (entity) {
      const otherContactRelations = entity.contactRelations.edges.filter(edge => edge.node.contactId !== value).map(edge => ({
        contactId: edge.node.contactId,
        isPrimary: false
      }));

      return {
        contactRelations: value ? [{
          contactId: value,
          isPrimary: true
        }, ...otherContactRelations] : otherContactRelations
      };
    } else {
      return {
        contactRelations: value ? [{
          contactId: value,
          isPrimary: true
        }] : []
      };
    }
  }
};
