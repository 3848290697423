<ppl-card-large [disabled]="disabled">
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="EntityNameEnum.Lead"
                [kind]="AvatarKind.Large"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    {{entity.name}}
  </ng-template>

  <ng-template #descriptionTemplate>
    {{entity.status | tEnum:'LeadStatusEnum'}}
    <br />
    {{entity.owner ? (entity.owner | pplName) : ''}}
  </ng-template>

  <ng-template #actionTemplate>
    <ppl-external-link [entity]="entity"></ppl-external-link>
  </ng-template>
</ppl-card-large>