import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import type { TaskEntity} from '@ppl/graphql-space-api';
import { TaskStatusEnum } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { PplDialogRef, PPL_DIALOG_DATA } from '@ppl/ui/dialog';
import type { TFormGroup } from '@ppl/ui/form';
import { TFormBuilder } from '@ppl/ui/form';
import type { PplSelectOption } from '@ppl/ui/select';

@Component({
  selector: 'ppl-move-task',
  templateUrl: './move-task.component.html',
  styleUrls: ['./move-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoveTaskComponent {

  formGroup: TFormGroup<MoveTaskFormValue>;

  statusOptions: PplSelectOption[] = [{
    label: this.i18nService.translateEnumValue('TaskStatusEnum', TaskStatusEnum.NotStarted),
    value: TaskStatusEnum.NotStarted
  }, {
    label: this.i18nService.translateEnumValue('TaskStatusEnum', TaskStatusEnum.InProgress),
    value: TaskStatusEnum.InProgress
  }, {
    label: this.i18nService.translateEnumValue('TaskStatusEnum', TaskStatusEnum.Waiting),
    value: TaskStatusEnum.Waiting
  }, {
    label: this.i18nService.translateEnumValue('TaskStatusEnum', TaskStatusEnum.Completed),
    value: TaskStatusEnum.Completed
  }, {
    label: this.i18nService.translateEnumValue('TaskStatusEnum', TaskStatusEnum.Deferred),
    value: TaskStatusEnum.Deferred
  }];

  constructor(
    private dialogRef: PplDialogRef<MoveTaskComponent, TaskStatusEnum>,
    @Inject(PPL_DIALOG_DATA) public data: MoveTaskData,
    private i18nService: I18nService,
    private formBuilder: TFormBuilder
  ) {
    this.formGroup = this.formBuilder.group<MoveTaskFormValue>({
      status: [this.data.entity.status, Validators.required]
    });
  }

  onSave() {
    this.dialogRef.close(this.formGroup.get('status').value);
  }

}

export interface MoveTaskData {
  entity: TaskEntity;
}

export interface MoveTaskFormValue {
  status: TaskStatusEnum;
}
