<ppl-card-small [active]="active">
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="EntityNameEnum.Account"
                [kind]="AvatarKind.Small"
                [pictureUrl]="entity.picture?.url"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    {{entity.name}}
  </ng-template>
</ppl-card-small>