import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthModule } from '@ppl/auth';
import { PplButtonModule } from '@ppl/ui/button';
import { PplDialogModule } from '@ppl/ui/dialog';
import { PplIconModule } from '@ppl/ui/icon';
import { PplPopoverModule } from '@ppl/ui/popover';
import { GlobalSearchModule } from '../global-search/global-search.module';
import { PageActionsComponent } from './components/page-actions/page-actions.component';
import { PageErrorComponent } from './components/page-error/page-error.component';
import { PageDetailHeaderComponent } from './components/page-header/page-detail-header/page-detail-header.component';
import { PageFormHeaderComponent } from './components/page-header/page-form-header/page-form-header.component';
import { PageHeaderButtonComponent } from './components/page-header/page-header-button/page-header-button.component';
import { PageLoadingComponent } from './components/page-loading/page-loading.component';
import { PageComponent } from './components/page/page.component';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    GlobalSearchModule,
    PplButtonModule,
    PplDialogModule,
    PplIconModule,
    PplPopoverModule
  ],
  declarations: [
    PageComponent,
    PageActionsComponent,
    PageDetailHeaderComponent,
    PageFormHeaderComponent,
    PageHeaderButtonComponent,
    PageErrorComponent,
    PageLoadingComponent
  ],
  exports: [
    PageComponent,
    PageActionsComponent,
    PageDetailHeaderComponent,
    PageFormHeaderComponent,
    PageHeaderButtonComponent,
    PageErrorComponent,
    PageLoadingComponent
  ]
})
export class PageModule {}
