import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { arrayToggle, FormValueControl, getFormControlProvider, MemoizeLast, trackById } from '@ppl/utils';
import type { EntityTreeItem } from '../../domain/entity-tree';

@Component({
  selector: 'ppl-entity-tree',
  templateUrl: './entity-tree.component.html',
  styleUrls: ['./entity-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    getFormControlProvider(() => EntityTreeComponent)
  ]
})
@FormValueControl()
export class EntityTreeComponent {

  @Input() items: EntityTreeItem[];
  @Input() forceOpenFolders?: boolean;
  @Input() noItemsMessage?: string;
  @Input() value: string | null;

  @Output() valueChange = new EventEmitter<string>();

  openIds: string[] = [];

  ItemPadding = 12;
  FolderOffset = 20;

  trackById = trackById;

  @MemoizeLast<EntityTreeComponent>(['items', 'openIds'])
  get flatItems() {
    const forceOpenFolders = this.forceOpenFolders;
    const openIds = this.openIds;

    function getFlatItems(items: EntityTreeItem[], depth = 0) {
      const flatItems: EntityTreeFlatItem[] = [];

      items.forEach(item => {
        const folderOpen = !!item.children && (openIds.includes(item.id) || forceOpenFolders);

        flatItems.push({
          id: item.id,
          name: item.name,
          depth,
          folder: item.children ? {
            open: folderOpen
          } : null
        });

        if (folderOpen) {
          flatItems.push(...getFlatItems(item.children, depth + 1));
        }
      });

      return flatItems;
    }

    return getFlatItems(this.items);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  onItemClick(item: EntityTreeFlatItem) {
    if (item.folder) {
      if (!this.forceOpenFolders) {
        this.openIds = arrayToggle(this.openIds, item.id);
      }
    } else {
      this.valueChange.emit(item.id);
    }
  }

}

interface EntityTreeFlatItem {
  id: string;
  name: string;
  depth: number;
  folder: { open: boolean } | null;
}
