<ppl-page-error *ngIf="!error"
                text="Record is not available"
                i18n-text="@@Record_is_not_available"
                description="The requested record doesn't exist or you don't have enough permissions"
                i18n-description="@@The_requested_record_doesn't_exist_or_you_don't_have_enough_permissions"></ppl-page-error>

<ppl-page-error *ngIf="error"
                text="An error has occurred"
                i18n-text="@@An_error_has_occurred"
                description="Please try again later"
                i18n-description="@@Please_try_again_later"></ppl-page-error>