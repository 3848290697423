import { gql } from '@ppl/utils';
import { AccountCardMediumFields, AppointmentCardMediumFields, ContactCardMediumFields, OpportunityCardLargeFields, TaskCardMediumFields } from '../../shared/entity-card/services/entity-card.graphql';

export const gqlFetchOpportunityData = gql`
  query fetchOpportunityData($entityId: UUID!) {
    screens {
      extractViews {
        opportunity(entityId: $entityId) {
          entity {
            ...on OpportunityEntity {
              ...OpportunityCardLargeFields
              accountRelations {
                edges {
                  node {
                    id
                    account {
                      ...AccountCardMediumFields
                    }
                    isPrimary
                  }
                }
              }
              contactRelations {
                edges {
                  node {
                    id
                    contact {
                      ...ContactCardMediumFields
                    }
                    isPrimary
                  }
                }
              }
            }
          }
          plannedActivities {
            edges {
              node {
                id
                appointment {
                  ...AppointmentCardMediumFields
                }
                task {
                  ...TaskCardMediumFields
                }
              }
            }
          }
          unplannedTasks {
            edges {
              node {
                id
                task {
                  ...TaskCardMediumFields
                }
              }
            }
          }
        }
      }
    }
  }
  ${OpportunityCardLargeFields}
  ${AccountCardMediumFields}
  ${AppointmentCardMediumFields}
  ${ContactCardMediumFields}
  ${TaskCardMediumFields}
`;
