import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplInputModule } from '@ppl/ui/input';
import { LocationPickerComponent } from './components/location-picker/location-picker.component';

@NgModule({
  imports: [
    CommonModule,
    PplInputModule
  ],
  declarations: [
    LocationPickerComponent
  ],
  exports: [
    LocationPickerComponent
  ]
})
export class LocationModule {}
