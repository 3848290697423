import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OpportunityEntity } from '@ppl/graphql-space-api';
import { MemoizeLast, trackById } from '@ppl/utils';

@Component({
  selector: 'ppl-pipeline-card',
  templateUrl: './pipeline-card.component.html',
  styleUrls: ['./pipeline-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PipelineCardComponent {

  @Input() entity: OpportunityEntity;

  trackById = trackById;

  @MemoizeLast<PipelineCardComponent>(['entity'])
  get steps() {
    return this.entity.step.pipeline.steps.sort((a, b) => a.sortOrder - b.sortOrder);
  }

  @MemoizeLast<PipelineCardComponent>(['entity', 'steps'])
  get stepIndex() {
    return this.steps.findIndex(step => step.id === this.entity.step.id);
  }

}
