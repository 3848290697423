import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthModule } from '@ppl/auth';
import { I18nModule } from '@ppl/i18n';
import { PplIconModule } from '@ppl/ui/icon';
import { PplInputModule } from '@ppl/ui/input';
import { PplPopoverModule } from '@ppl/ui/popover';
import { PplPopoverMenuModule } from '@ppl/ui/popover-menu';
import { CardFieldComponent } from './components/card-fields/card-field/card-field.component';
import { CardFieldsComponent } from './components/card-fields/card-fields.component';
import { CardFilterTabComponent } from './components/card-filter/card-filter-tab/card-filter-tab.component';
import { CardFilterComponent } from './components/card-filter/card-filter.component';
import { CardGroupComponent } from './components/card-group/card-group.component';
import { CardInlineFieldComponent } from './components/card-inline-field/card-inline-field.component';
import { CardLargeComponent } from './components/card-large/card-large.component';
import { CardListComponent } from './components/card-list/card-list.component';
import { CardMediumComponent } from './components/card-medium/card-medium.component';
import { CardMessageComponent } from './components/card-message/card-message.component';
import { CardSmallComponent } from './components/card-small/card-small.component';
import { CardSocialComponent } from './components/card-social/card-social.component';
import { CardTabComponent } from './components/card-tabs/card-tab/card-tab.component';
import { CardTabsComponent } from './components/card-tabs/card-tabs.component';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    I18nModule,
    PplIconModule,
    PplInputModule,
    PplPopoverModule,
    PplPopoverMenuModule
  ],
  declarations: [
    CardFieldComponent,
    CardFieldsComponent,
    CardFilterComponent,
    CardFilterTabComponent,
    CardGroupComponent,
    CardInlineFieldComponent,
    CardLargeComponent,
    CardListComponent,
    CardMediumComponent,
    CardMessageComponent,
    CardSmallComponent,
    CardSocialComponent,
    CardTabComponent,
    CardTabsComponent
  ],
  exports: [
    CardFieldComponent,
    CardFieldsComponent,
    CardFilterComponent,
    CardFilterTabComponent,
    CardGroupComponent,
    CardInlineFieldComponent,
    CardLargeComponent,
    CardListComponent,
    CardMediumComponent,
    CardMessageComponent,
    CardSmallComponent,
    CardSocialComponent,
    CardTabComponent,
    CardTabsComponent
  ]
})
export class CardModule {}
