import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppointmentEntity, EntityNameEnum } from '@ppl/graphql-space-api';
import { AvatarKind } from '../../../avatar/domain/avatar';

@Component({
  selector: 'ppl-appointment-card-medium',
  templateUrl: './appointment-card-medium.component.html',
  styleUrls: ['./appointment-card-medium.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppointmentCardMediumComponent {

  @Input() entity: AppointmentEntity;

  AvatarKind = AvatarKind;
  EntityNameEnum = EntityNameEnum;

}
