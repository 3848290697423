<ppl-dialog title="About Pipeliner"
            i18n-title="@@About_Pipeliner"
            [actionsVisible]="false">
  <div class="icon">
    <ppl-icon svgIcon="logo-color"
              width="180px"
              height="41px"></ppl-icon>
  </div>

  <div class="text"
       i18n="We_believe_that_the_best_tools_are_always_more_than_mere_features_They_are_vehicles_of_happiness_in_your_work_life_They_inform_They_make_life_easier_Welcome_to_our_app">
    We believe that the best tools are always more than mere features. They are vehicles of happiness in your work life. They inform. They make life easier. Welcome to our app.
  </div>

  <div class="version">
    <ng-container i18n="@@Version">Version</ng-container> {{data.version}}
  </div>

  <div class="diagnostics">
    <div *ngIf="diagnostics">Host: {{diagnostics.host}}, {{diagnostics.platform}}, {{diagnostics.version}}</div>
    <div>Browser: {{userAgent}}</div>
  </div>
</ppl-dialog>