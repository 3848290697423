import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fetchAsyncValue } from '@ppl/core';
import type { AsyncValue } from '@ppl/store';
import { AsyncState } from '@ppl/store';
import type { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import type { LeadData } from '../../domain/lead';
import { LeadService } from '../../services/lead.service';

@Component({
  selector: 'ppl-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeadComponent implements OnInit {

  data$: Observable<AsyncValue<LeadData>>;

  AsyncState = AsyncState;

  constructor(
    private leadService: LeadService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.data$ = this.route.params.pipe(
      switchMap(params => {
        return fetchAsyncValue(this.leadService.fetchData(params.id));
      })
    );
  }

}
