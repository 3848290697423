import { normalizeTextToPlain } from '@ppl/utils';
import { EntityFormControlKind } from '../../../entity-form/domain/controls';
import type { EntityFormApiFieldTypeAdapter } from '../entity-form-api';

export const TextAreaFieldTypeAdapter: EntityFormApiFieldTypeAdapter = {
  id: 'text_area',
  getControl({ fieldDescriptor, formField }) {
    return {
      id: fieldDescriptor.id,
      label: formField?.label ?? fieldDescriptor.name,
      kind: EntityFormControlKind.Textarea
    };
  },
  getValue({ value }) {
    return normalizeTextToPlain(value);
  }
};
