import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { PplDialogRef, PPL_DIALOG_DATA } from '@ppl/ui/dialog';
import type { TFormGroup } from '@ppl/ui/form';
import { TFormBuilder } from '@ppl/ui/form';

@Component({
  selector: 'ppl-attachment-name',
  templateUrl: './attachment-name.component.html',
  styleUrls: ['./attachment-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentNameComponent {

  formGroup: TFormGroup<AttachmentNameFormValue>;

  constructor(
    private dialogRef: PplDialogRef<AttachmentNameComponent, string>,
    @Inject(PPL_DIALOG_DATA) public data: AttachmentNameData,
    private formBuilder: TFormBuilder
  ) {
    this.formGroup = this.formBuilder.group<AttachmentNameFormValue>({
      fileName: [this.data.fileName, Validators.required]
    });
  }

  onSave() {
    this.dialogRef.close(this.formGroup.get('fileName').value);
  }

}

interface AttachmentNameFormValue {
  fileName: string;
}

export interface AttachmentNameData {
  fileName: string;
}
