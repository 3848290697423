<ppl-card-medium [disabled]="true">
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="entityType"
                [kind]="AvatarKind.Medium"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    {{name}}

    <ppl-badge *ngIf="isPrimary"
               color="#0078d7"
               i18n="@@Primary">Primary</ppl-badge>
  </ng-template>

  <ng-template #descriptionTemplate>
    <ng-container i18n="@@Access_rights_not_granted_on_this_item">Access rights not granted on this item</ng-container>
  </ng-template>
</ppl-card-medium>