import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthModule } from '@ppl/auth';
import { I18nModule } from '@ppl/i18n';
import { PplBadgeModule } from '@ppl/ui/badge';
import { PplIconModule } from '@ppl/ui/icon';
import { PplPopoverModule } from '@ppl/ui/popover';
import { PplPopoverMenuModule } from '@ppl/ui/popover-menu';
import { ActivityModule } from '../activity/activity.module';
import { AvatarModule } from '../avatar/avatar.module';
import { CardModule } from '../card/card.module';
import { EmailModule } from '../email/email.module';
import { LocationModule } from '../location/location.module';
import { PhoneModule } from '../phone/phone.module';
import { AccountCardLargeComponent } from './components/account-card-large/account-card-large.component';
import { AccountCardMediumComponent } from './components/account-card-medium/account-card-medium.component';
import { AccountCardSmallComponent } from './components/account-card-small/account-card-small.component';
import { AccountFieldsCardComponent } from './components/account-fields-card/account-fields-card.component';
import { AccountsCardsComponent } from './components/accounts-cards/accounts-cards.component';
import { ActionsCardComponent } from './components/actions-card/actions-card.component';
import { ActivitiesCardsComponent } from './components/activities-cards/activities-cards.component';
import { AppointmentCardLargeComponent } from './components/appointment-card-large/appointment-card-large.component';
import { AppointmentCardMediumComponent } from './components/appointment-card-medium/appointment-card-medium.component';
import { AppointmentFieldsCardComponent } from './components/appointment-fields-card/appointment-fields-card.component';
import { AttendeesCardsComponent } from './components/attendees-cards/attendees-cards.component';
import { ButtonCardComponent } from './components/button-card/button-card.component';
import { ClientCardLargeComponent } from './components/client-card-large/client-card-large.component';
import { ClientCardMediumComponent } from './components/client-card-medium/client-card-medium.component';
import { ClientCardSmallComponent } from './components/client-card-small/client-card-small.component';
import { ContactCardLargeComponent } from './components/contact-card-large/contact-card-large.component';
import { ContactCardMediumComponent } from './components/contact-card-medium/contact-card-medium.component';
import { ContactCardSmallComponent } from './components/contact-card-small/contact-card-small.component';
import { ContactFieldsCardComponent } from './components/contact-fields-card/contact-fields-card.component';
import { ContactsCardsComponent } from './components/contacts-cards/contacts-cards.component';
import { DateCardComponent } from './components/date-card/date-card.component';
import { DescriptionCardComponent } from './components/description-card/description-card.component';
import { ExternalLinkComponent } from './components/external-link/external-link.component';
import { LeadCardLargeComponent } from './components/lead-card-large/lead-card-large.component';
import { LeadCardMediumComponent } from './components/lead-card-medium/lead-card-medium.component';
import { LeadFieldsCardComponent } from './components/lead-fields-card/lead-fields-card.component';
import { LeadsCardsComponent } from './components/leads-cards/leads-cards.component';
import { LinkedItemsCardsComponent } from './components/linked-items-cards/linked-items-cards.component';
import { OpportunitiesCardsComponent } from './components/opportunities-cards/opportunities-cards.component';
import { OpportunityCardLargeComponent } from './components/opportunity-card-large/opportunity-card-large.component';
import { OpportunityCardMediumComponent } from './components/opportunity-card-medium/opportunity-card-medium.component';
import { OpportunityFieldsCardComponent } from './components/opportunity-fields-card/opportunity-fields-card.component';
import { OtherCardLargeComponent } from './components/other-card-large/other-card-large.component';
import { OtherCardSmallComponent } from './components/other-card-small/other-card-small.component';
import { PipelineCardComponent } from './components/pipeline-card/pipeline-card.component';
import { TaskCardLargeComponent } from './components/task-card-large/task-card-large.component';
import { TaskCardMediumComponent } from './components/task-card-medium/task-card-medium.component';
import { TaskFieldsCardComponent } from './components/task-fields-card/task-fields-card.component';
import { UnavailableCardMediumComponent } from './components/unavailable-card-medium/unavailable-card-medium.component';
import { UserCardSmallComponent } from './components/user-card-small/user-card-small.component';
import { WatchersCardsComponent } from './components/watchers-cards/watchers-cards.component';

@NgModule({
  imports: [
    ActivityModule,
    AuthModule,
    AvatarModule,
    CardModule,
    CommonModule,
    EmailModule,
    I18nModule,
    LocationModule,
    PhoneModule,
    PplBadgeModule,
    PplIconModule,
    PplPopoverModule,
    PplPopoverMenuModule
  ],
  declarations: [
    AccountCardLargeComponent,
    AccountCardMediumComponent,
    AccountCardSmallComponent,
    AccountFieldsCardComponent,
    AccountsCardsComponent,
    ActionsCardComponent,
    ActivitiesCardsComponent,
    AppointmentCardLargeComponent,
    AppointmentCardMediumComponent,
    AppointmentFieldsCardComponent,
    AttendeesCardsComponent,
    ButtonCardComponent,
    ClientCardLargeComponent,
    ClientCardMediumComponent,
    ClientCardSmallComponent,
    ContactCardLargeComponent,
    ContactCardMediumComponent,
    ContactCardSmallComponent,
    ContactFieldsCardComponent,
    ContactsCardsComponent,
    DateCardComponent,
    DescriptionCardComponent,
    ExternalLinkComponent,
    LeadCardLargeComponent,
    LeadCardMediumComponent,
    LeadFieldsCardComponent,
    LeadsCardsComponent,
    LinkedItemsCardsComponent,
    OpportunitiesCardsComponent,
    OpportunityCardLargeComponent,
    OpportunityCardMediumComponent,
    OpportunityFieldsCardComponent,
    OtherCardLargeComponent,
    OtherCardSmallComponent,
    PipelineCardComponent,
    TaskCardLargeComponent,
    TaskCardMediumComponent,
    TaskFieldsCardComponent,
    UnavailableCardMediumComponent,
    UserCardSmallComponent,
    WatchersCardsComponent
  ],
  exports: [
    AccountCardLargeComponent,
    AccountCardSmallComponent,
    AccountFieldsCardComponent,
    AccountsCardsComponent,
    ActionsCardComponent,
    ActivitiesCardsComponent,
    AppointmentCardLargeComponent,
    AppointmentFieldsCardComponent,
    AttendeesCardsComponent,
    ButtonCardComponent,
    ClientCardLargeComponent,
    ClientCardSmallComponent,
    ContactCardLargeComponent,
    ContactCardSmallComponent,
    ContactFieldsCardComponent,
    ContactsCardsComponent,
    DateCardComponent,
    DescriptionCardComponent,
    LeadCardLargeComponent,
    LeadFieldsCardComponent,
    LeadsCardsComponent,
    LinkedItemsCardsComponent,
    OpportunityCardLargeComponent,
    OpportunityFieldsCardComponent,
    OpportunitiesCardsComponent,
    OtherCardLargeComponent,
    OtherCardSmallComponent,
    PipelineCardComponent,
    TaskCardLargeComponent,
    TaskFieldsCardComponent,
    UserCardSmallComponent,
    WatchersCardsComponent
  ]
})
export class EntityCardModule {}
