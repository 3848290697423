import { gql } from '@ppl/utils';

export const gqlFetchEmailTemplates = gql`
  query fetchEmailTemplates {
    entities {
      emailTemplate {
        getAll(filter: { type: { eq: TextTemplate } }, orderBy: { name: Asc }) {
          id
          name
          emailTemplateFolderId
          ownerId
        }
      }
      emailTemplateFolder {
        getAll(orderBy: { name: Asc }) {
          id
          name
          parentEmailTemplateFolderId
        }
      }
    }
  }
`;

export const gqlFetchEmailTemplateContent = gql`
  query fetchEmailTemplateContent($entityId: ID!) {
    entities {
      emailTemplate {
        getById(entityId: $entityId) {
          id
          body
          documents {
            edges {
              node {
                id
                cloudObject {
                  filename
                  id
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const gqlPersonalizeBodyEmailTemplate = gql`
  mutation personalizeBodyEmailTemplate($input: PersonalizeBodyEmailTemplateInput!) {
    personalizeBodyEmailTemplate(input: $input) {
      result
    }
  }
`;
