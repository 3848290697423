import { gql } from '@ppl/utils';

export const FieldDescriptorFields = gql`
  fragment FieldDescriptorFragment on FieldDescriptor {
    calcFormula
    defaultValue
    graphqlName
    permissionLevel
    systemType
    typeId
    type {
      id
      defaultValue
      primitiveType
      validator
    }
  }
`;

export const gqlFetchValueOptions = gql`
  query fetchValueOptions($fieldId: ID!, $entity: EntityNameEnum!) {
    collections {
      fieldDescriptor(fieldId: $fieldId) {
        id
        valueOptions(entity: $entity) {
          isDeleted
          label
          value
        }
      }
    }
  }
`;
