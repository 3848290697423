import type { AfterContentInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { notFirstChange, unsubscribe } from '@ppl/utils';
import type { Subscription } from 'rxjs';
import { CardFilterTabComponent } from './card-filter-tab/card-filter-tab.component';

@Component({
  selector: 'ppl-card-filter',
  templateUrl: './card-filter.component.html',
  styleUrls: ['./card-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardFilterComponent implements OnChanges, OnDestroy, AfterContentInit {

  @Input() value: string | null;

  @Output() valueChange = new EventEmitter<string | null>();

  @ContentChildren(CardFilterTabComponent) tabs: QueryList<CardFilterTabComponent>;

  tabClickSubscriptions: Subscription[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (notFirstChange(changes.value)) {
      this.selectTab(this.tabs.toArray().find(tab => tab.value === this.value));
    }
  }

  ngOnDestroy() {
    unsubscribe(this.tabClickSubscriptions);
  }

  ngAfterContentInit() {
    this.tabs.toArray().map(tab => {
      return tab.tabClick.subscribe(() => {
        this.valueChange.emit((tab.value !== this.value) ? tab.value : null);
      });
    });
  }

  selectTab(tab: CardFilterTabComponent | null) {
    this.tabs.toArray().forEach(otherTab => {
      otherTab.setSelected(false);
    });

    if (tab) {
      tab.setSelected(true);
    }
  }

}
