<ppl-autocomplete-select #autocomplete
                         [options]="fetchEntitiesSession.options$ | async"
                         [categories]="entitiesCategories"
                         [displayOptionsLoading]="fetchEntitiesSession.loading"
                         [displayValueLoading]="valueLoading"
                         [disabled]="disabled"
                         [forceDisplayClearValue]="forceDisplayClearValue"
                         [placeholder]="''"
                         [optionTemplate]="optionTemplate"
                         [optionTemplateRowHeight]="optionTemplateRowHeight"
                         [maxContainerHeight]="maxContainerHeight"
                         [value]="value"
                         (optionsRequest)="onOptionsRequest($event)"
                         (clearValueClick)="onClearValueClick()"
                         (valueChange)="onValueChange($event)"></ppl-autocomplete-select>