<ppl-card-small [active]="active">
  <ng-template #iconTemplate>
    <ppl-avatar [entityType]="EntityNameEnum.Contact"
                [kind]="AvatarKind.Small"
                [pictureUrl]="entity.picture?.url"></ppl-avatar>
  </ng-template>

  <ng-template #nameTemplate>
    {{entity | pplName}}
  </ng-template>
</ppl-card-small>