import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { MemoizeLast, trackById } from '@ppl/utils';

@Component({
  selector: 'ppl-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardListComponent {

  @Input() entities: { id: string }[];

  @ContentChild('cardTemplate', { static: false }) cardTemplate: TemplateRef<any>;

  allVisible = false;

  VisibleCardsThreshold = VisibleCardsThreshold;
  trackById = trackById;

  @MemoizeLast<CardListComponent>(['entities', 'allVisible'])
  get visibleEntities() {
    return this.allVisible ? this.entities : this.entities.slice(0, VisibleCardsThreshold);
  }

}

const VisibleCardsThreshold = 2;
