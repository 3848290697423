<ppl-card-inline-field icon="entity-detail-email"
                       [value]="entity.email1"
                       emptyLabel="Add Email"
                       i18n-emptyLabel="@@Add_Email"
                       [disabled]="!entity.instancePermissions.includes(EntityPermsEnum.Write)"
                       (valueChange)="emailChange.emit($event)">
  <a [href]="'mailto:' + entity.email1">{{entity.email1}}</a>
</ppl-card-inline-field>

<ppl-card-inline-field icon="entity-detail-call"
                       [value]="entity.phone1"
                       emptyLabel="Add Phone"
                       i18n-emptyLabel="@@Add_Phone"
                       [disabled]="!entity.instancePermissions.includes(EntityPermsEnum.Write)"
                       (valueChange)="phoneChange.emit($event)">
  <span class="phone"
        (click)="onPhoneClick()">{{entity.phone1}}</span>
</ppl-card-inline-field>

<ppl-card-inline-field icon="entity-detail-home-site"
                       [value]="entity.homePage"
                       emptyLabel="Add Homepage"
                       i18n-emptyLabel="@@Add_Homepage"
                       [disabled]="!entity.instancePermissions.includes(EntityPermsEnum.Write)"
                       (valueChange)="homePageChange.emit($event)">
  <a [href]="homePageUrl"
     target="_blank">{{entity.homePage}}</a>
</ppl-card-inline-field>