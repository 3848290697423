import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ToastKind } from '../../../toast/domain/toast';

@Component({
  selector: 'ppl-error-toast',
  templateUrl: './error-toast.component.html',
  styleUrls: ['./error-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorToastComponent {

  ToastKind = ToastKind;

}
